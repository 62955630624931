import { Textarea } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";

import { useToast } from "../../../components";
import ShareableUserSelect, {
  ShareableUser,
} from "../../../components/ShareableUserSelect/ShareableUserSelect";
import Modal from "../../components/Modal/Modal";
import {
  useAnalyticsReportShareableUsersLazyQuery,
  useShareAnalyticsReportMutation,
} from "../../graphql";

export interface AnalyticsReportShareModalProps {
  isOpen: boolean;
  onClose(): void;
}

const AnalyticsReportShareModal: React.FC<AnalyticsReportShareModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [selectedUsers, setSelectedUsers] = useState<ShareableUser[]>([]);
  const [message, setMessage] = useState<string>("");
  const toast = useToast();
  const [getShareData, { loading, data }] =
    useAnalyticsReportShareableUsersLazyQuery();

  const [shareReport] = useShareAnalyticsReportMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
    onCompleted(data) {
      if (data.shareAnalyticsReport?.success) {
        toast({
          title: `Report shared`,
          description:
            "Recipients will receive an email with a link to view the report.",
          status: "success",
        });
        onClose();
      }
    },
  });

  const shareHandler = useCallback(() => {
    shareReport({
      variables: {
        shareToUserIds: selectedUsers.map((u) => u.id),
        message,
        url: window.location.href,
      },
    });
  }, [message, selectedUsers]);

  const closeHandler = (): void => {
    setSelectedUsers([]);
    setMessage("");
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      getShareData();
    }
  }, [isOpen]);

  const shareableUsers = (data?.currentUser?.organization.users || []).map(
    (user) => ({
      id: user.id,
      fullName: user.fullName,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      profilePicUrl: user.profilePicUrl,
    })
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeHandler}
      isLoading={loading}
      headerText="Share report"
      completeButtonText="Share"
      onComplete={shareHandler}
      completeButtonDisabled={selectedUsers.length === 0}
      hasHeaderBorder
      bodyProps={{ px: "8", pb: "10" }}
    >
      <ShareableUserSelect
        shareableUsers={shareableUsers}
        selectedUsers={selectedUsers}
        onSelection={(users: ShareableUser[]) => setSelectedUsers(users)}
      />
      {selectedUsers.length > 0 && (
        <Textarea
          data-testid="share-message"
          mt="5"
          height="150px"
          placeholder="Add a message (optional)"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      )}
    </Modal>
  );
};

export default AnalyticsReportShareModal;
