import { Box, useDisclosure, useTheme } from "@chakra-ui/react";
import Color from "color";
import React from "react";
import { MdCallEnd } from "react-icons/md";

import { Button, ConfirmModal, useToast } from "../../../components";
import { callTypeLabel, isVideo } from "../../../utils/call";
import useInterviewAssistant from "../../../utils/popup";
import {
  InterviewAssistantEntryPoint,
  useAddInterviewAssistantOpensMutation,
  useCallInProgressQuery,
} from "../../graphql";
import { useEndCall } from "../../graphql/hooks/useEndCall";
import useCurrentUser from "../../hooks/useCurrentUser";

const CallInProgressBanner: React.FC = () => {
  const { isPopupOpen, openInterviewAssistant } = useInterviewAssistant();
  const {
    isOpen: shouldShowConfirmModal,
    onOpen: showConfirmModal,
    onClose: hideConfirmModal,
  } = useDisclosure();

  const {
    isOpen: shouldShowBanner,
    onOpen: showBanner,
    onClose: hideBanner,
  } = useDisclosure();

  const { data } = useCallInProgressQuery({
    onCompleted(data_) {
      if (data_?.callInProgress) {
        showBanner();
      }
    },
  });
  const currentUser = useCurrentUser();
  const callInProgress = data?.callInProgress;
  const { colors } = useTheme();
  const toast = useToast();
  const { endCall, loading } = useEndCall({
    call: callInProgress,
    onError: () => {
      toast({ title: "Error ending interview.", status: "error" });
    },
    onCompleted(data) {
      if (data?.call.id) {
        hideBanner();
      }
    },
  });
  const [addUsage] = useAddInterviewAssistantOpensMutation({
    onError: () => {
      toast({
        title: "Error saving Interview Assistant open.",
        status: "error",
      });
    },
  });

  if (!shouldShowBanner || !callInProgress) {
    return null;
  }

  // Don't do anything for browser calls in progress without a popup window
  // open since it means that the popup is open in another tab or browser.
  const canShowPopup =
    isPopupOpen("call") ||
    callInProgress.type !== "BROWSER" ||
    currentUser?.browserExtensionVersion;

  const onClick = (): void => {
    openInterviewAssistant({ callId: callInProgress.id });
    if (isVideo(callInProgress.type)) {
      addUsage({
        variables: {
          callId: callInProgress.id,
          entryPoint: InterviewAssistantEntryPoint.Banner,
        },
      });
    }
  };

  let background = colors.blue[600];

  if (!canShowPopup) {
    background = Color(background).alpha(0.8).string();
  }

  return (
    <Box backgroundColor="white">
      <Box
        px={8}
        py={2}
        textAlign="center"
        width="100%"
        color="white"
        backgroundColor={background}
        cursor={canShowPopup ? "pointer" : "inherit"}
        onClick={canShowPopup ? onClick : undefined}
      >
        <Box position="relative">
          {isVideo(callInProgress.type) ? (
            callInProgress.candidate?.fullName ? (
              <>
                {`In ${callTypeLabel(callInProgress.type)} meeting with `}
                <Box as="span" fontWeight="bold">
                  {callInProgress.candidate?.fullName}
                </Box>
                .
              </>
            ) : (
              `In ${callTypeLabel(callInProgress.type)} meeting.`
            )
          ) : (
            <>
              {"In interview with "}
              <Box as="span" fontWeight="bold">
                {callInProgress.candidate?.fullName ||
                  callInProgress.phoneNumber ||
                  "Candidate"}
              </Box>
              .
            </>
          )}
          <Box as="span" pr="110px">
            {canShowPopup
              ? " Click here to show the Interview Assistant."
              : " To show the Interview Assistant, click this banner in the tab or window where you originally started the interview."}
          </Box>
          {!isVideo(callInProgress.type) && (
            <>
              <ConfirmModal
                modalTitleText="End Interview"
                modalBodyText="Are you sure?"
                isOpen={shouldShowConfirmModal}
                onCancel={hideConfirmModal}
                onConfirm={() => {
                  endCall();
                  hideConfirmModal();
                }}
              />
              <Button
                variant="dangerOutline"
                position="absolute"
                right="10px"
                leftIcon={<MdCallEnd />}
                size="xs"
                isLoading={loading}
                onClick={(e) => {
                  showConfirmModal();
                  e.stopPropagation();
                }}
              >
                End interview
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CallInProgressBanner;
