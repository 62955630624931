import { Box, BoxProps, Flex, Icon, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons";
import { IoMdClose } from "react-icons/io";
import { MdCheckCircle, MdError, MdInfo, MdWarning } from "react-icons/md";

import { Button, IconButton } from "../Buttons";

export type AlertStatus = "info" | "success" | "warning" | "error";

export const AlertStatusIcons = {
  info: MdInfo,
  success: MdCheckCircle,
  warning: MdWarning,
  error: MdError,
};

export type AlertProps = {
  status: AlertStatus;
  title?: string;
  description: string | React.ReactNode;
  showIcon?: boolean;
  canHide?: boolean;
  reload?: boolean;
  icon?: IconType;
} & BoxProps;

const Alert: React.FC<AlertProps> = ({
  status,
  title,
  description,
  showIcon = true,
  canHide = false,
  reload = false,
  icon = undefined,
  children,
  ...boxProps
}) => {
  const { onClose, isOpen } = useDisclosure({ defaultIsOpen: true });

  if (!isOpen) {
    return null;
  }

  return (
    <Box {...boxProps}>
      <Flex
        flexFlow={{ base: "column nowrap", sm: "row nowrap" }}
        role="alert"
        position="relative"
        justifyContent="space-between"
        alignItems={{ base: "flex-start", sm: "center" }}
        overflow="hidden"
        px={4}
        py={3}
        backgroundColor={`alert.${status}.background`}
        borderRadius="4px"
      >
        <Flex
          flexFlow="row nowrap"
          alignItems="center"
          fontWeight="normal"
          lineHeight="normal"
          fontSize="sm"
          width={{ base: "100%", sm: "auto" }}
          mb={{ base: 1, sm: 0 }}
        >
          {showIcon && (
            <Icon
              w="6"
              h="6"
              mr={6}
              as={icon ?? AlertStatusIcons[status]}
              color={`alert.${status}.icon`}
            />
          )}
          {title && (
            <Box mr={2} fontWeight="bold" lineHeight="normal" fontSize="sm">
              {title}
            </Box>
          )}
          <Box>{description}</Box>
        </Flex>
        <Box width="auto" ml={{ base: "36px", sm: 0 }}>
          {reload && (
            <Button
              m="2"
              variant="outline"
              onClick={() => window.location.reload()}
            >
              Reload
            </Button>
          )}
          {canHide && (
            <IconButton
              ml="auto"
              size="sm"
              aria-label="close"
              variant="none"
              icon={<IoMdClose />}
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
            />
          )}
          {children}
        </Box>
      </Flex>
    </Box>
  );
};

export default Alert;
