import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { HiOutlineChevronRight, HiStar } from "react-icons/hi2";

import useIsOverflowing from "../../../../../hooks/useIsOverflowing";
import {
  CallBetaFragment,
  InterviewerScorecardFragment,
  ScorecardItemFragment,
} from "../../../../graphql";
import CopyScoresButton from "./CopyScoresButton";

type OtherUsersScoreProps = {
  call: Pick<CallBetaFragment, "id" | "candidate" | "position" | "startTime">;
  scorecard: Pick<
    InterviewerScorecardFragment,
    "overallScore" | "overallScoringEnabled" | "interviewer" | "overallNotes"
  > & {
    competencies: Pick<
      ScorecardItemFragment,
      "id" | "itemText" | "score" | "notes" | "scoringEnabled"
    >[];
  };
};

/** Collapsible list item for an interviewer's scorecard */
const OtherUsersScore: React.FC<OtherUsersScoreProps> = ({
  call,
  scorecard,
}) => {
  const {
    ref: interviewerNameRef,
    isOverflowing: interviewerNameIsOverflowing,
  } = useIsOverflowing<HTMLDivElement>({ width: true });
  const interviewerName = scorecard.interviewer.fullName;
  const { overallScore, overallScoringEnabled, competencies } = scorecard;

  return (
    <Accordion
      allowToggle
      p="4"
      borderRadius="lg"
      borderWidth="1px"
      borderColor="gray.200"
    >
      <AccordionItem border="none">
        {({ isExpanded }) => (
          <>
            <Flex gap="2" alignItems="center">
              <AccordionButton w="auto" p="0" _hover={{ bg: "none" }}>
                <Icon
                  as={HiOutlineChevronRight}
                  boxSize="5"
                  p="1px"
                  transition="transform 200ms"
                  transform={isExpanded ? "rotate(90deg)" : undefined}
                  color="gray.400"
                />
              </AccordionButton>
              <Tooltip
                label={
                  interviewerNameIsOverflowing ? interviewerName : undefined
                }
              >
                <Box
                  ref={interviewerNameRef}
                  flex="1"
                  fontSize="sm"
                  fontWeight="semibold"
                  color="gray.800"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  textAlign="start"
                >
                  {interviewerName}
                </Box>
              </Tooltip>

              {overallScoringEnabled && (
                <Flex color="gray.500" fontSize="sm" gap="2">
                  {!overallScore && "Pending score"}
                  {(overallScore || 0) > 0 && (
                    <>
                      <AnimatePresence>
                        {isExpanded && (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.2 }}
                          >
                            Interview score:
                          </motion.div>
                        )}
                      </AnimatePresence>
                      <Flex
                        color="gray.800"
                        fontWeight="semibold"
                        alignItems="center"
                      >
                        {overallScore}
                        <Icon as={HiStar} color="blue.500" boxSize="4" ml="1" />
                      </Flex>
                    </>
                  )}
                </Flex>
              )}

              <CopyScoresButton
                call={call}
                scorecard={scorecard}
                hideIfDisabled
                boxSize="6"
                p="1"
              />
            </Flex>

            <AccordionPanel
              p="0"
              mt="3"
              display="flex"
              flexDirection="column"
              gap="3"
            >
              {competencies.map(
                (competency) =>
                  competency.scoringEnabled && (
                    <ChildItem
                      key={competency.id}
                      heading={competency.itemText}
                      score={competency.score}
                      notes={competency.notes}
                    />
                  )
              )}

              <ChildItem
                heading="Overall notes"
                hideScore
                notes={scorecard.overallNotes}
              />
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default OtherUsersScore;

const ChildItem: React.FC<{
  heading: string;
  score?: number | null;
  hideScore?: boolean;
  notes?: string | null;
}> = ({ heading, score, hideScore, notes }) => (
  <Flex
    direction="column"
    gap="2"
    fontSize="sm"
    borderRadius="lg"
    bg="gray.50"
    px="4"
    py="3"
  >
    <Flex color="gray.800" fontWeight="medium" alignItems="start" gap="2">
      {heading}
      {!hideScore && (
        <Flex alignItems="center" flex="0" ml="auto">
          {(score || 0) > 0 && (
            <>
              {score}
              <Icon as={HiStar} color="blue.500" boxSize="4" ml="1" />
            </>
          )}
          {!score && (
            <Text color="gray.500" fontWeight="normal" whiteSpace="nowrap">
              Pending score
            </Text>
          )}
        </Flex>
      )}
    </Flex>
    <Flex color={notes ? "gray.700" : "gray.500"}>
      {notes}
      {!notes && <i>No notes</i>}
    </Flex>
  </Flex>
);
