import React from "react";

import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";
import AnalyticsOverviewWrapper from "./AnalyticsOverviewWrapper";
import Overview from "./Overview";

const AnalyticsOverviewVersionSwitch: React.FC = () => {
  const newOverviewEnabled = useFeatureFlag("analytics:overview");

  return newOverviewEnabled ? <Overview /> : <AnalyticsOverviewWrapper />;
};

export default AnalyticsOverviewVersionSwitch;
