import colors from "../../../../theme/colors";
import { dataVizColorRangesBright } from "../chart/segmentPresentation";

interface Color {
  r: number;
  g: number;
  b: number;
}

const hexToRgb = (hex: string): Color | null => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const colorGradient = (
  fadeFraction: number,
  rgbColor1: Color,
  rgbColor2: Color,
  rgbColor3: Color
): string => {
  let color1 = rgbColor1;
  let color2 = rgbColor2;
  let fade = fadeFraction;

  // Do we have 3 colors for the gradient? Need to adjust the params.
  if (rgbColor3) {
    fade *= 2;

    // Find which interval to use and adjust the fade percentage
    if (fade >= 1) {
      fade -= 1;
      color1 = rgbColor2;
      color2 = rgbColor3;
    }
  }

  const diffRed = color2.r - color1.r;
  const diffGreen = color2.g - color1.g;
  const diffBlue = color2.b - color1.b;

  const gradient: Color = {
    r: Math.floor(color1.r + diffRed * fade),
    g: Math.floor(color1.g + diffGreen * fade),
    b: Math.floor(color1.b + diffBlue * fade),
  };

  return `rgb(${gradient.r},${gradient.g},${gradient.b})`;
};

export const getFillColor = (v: number, dataMax = 100): string => {
  return colorGradient(
    v / dataMax,
    hexToRgb(dataVizColorRangesBright.blueBolt[100]) ?? { r: 100, g: 0, b: 0 },
    hexToRgb(dataVizColorRangesBright.blueBolt[300]) ?? {
      r: 0,
      g: 100,
      b: 100,
    },
    hexToRgb(dataVizColorRangesBright.blueBolt[500]) ?? { r: 0, g: 100, b: 0 }
  );
};

export const getFillColorMulticolor = (v: number): string => {
  return colorGradient(
    v / 100,
    hexToRgb(colors.red[400]) ?? { r: 100, g: 0, b: 0 },
    hexToRgb(colors.yellow[400]) ?? { r: 0, g: 100, b: 100 },
    hexToRgb(colors.green[400]) ?? { r: 0, g: 100, b: 0 }
  );
};
