import { IconButtonProps, Tooltip } from "@chakra-ui/react";
import React from "react";
import { HiOutlineClipboardCopy } from "react-icons/hi";

import { IconButton } from "../../../../components";

const COPY_LABEL = "Copy to clipboard";
type SummaryCopyNotesButtonProps = {
  copyAll: () => void;
} & Omit<IconButtonProps, "aria-label">;

const SummaryCopyNotesButton: React.FC<SummaryCopyNotesButtonProps> = ({
  copyAll,
  ...iconButtonProps
}) => {
  return (
    <Tooltip label={COPY_LABEL}>
      <IconButton
        aria-label={COPY_LABEL}
        variant="icon"
        onClick={() => {
          copyAll();
        }}
        color="gray.900"
        icon={
          <HiOutlineClipboardCopy
            style={{ transform: "scaleX(-1)" }}
            strokeWidth="1.5px"
            size={20}
          />
        }
        {...iconButtonProps}
      />
    </Tooltip>
  );
};

export default SummaryCopyNotesButton;
