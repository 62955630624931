import { defineStyleConfig } from "@chakra-ui/react";

export default defineStyleConfig({
  baseStyle: {
    borderRadius: "lg",
  },
  variants: {
    white: {
      backgroundColor: "white",
      boxShadow: "elevate",
    },
    gray: {
      backgroundColor: "gray.100",
      boxShadow: "none",
    },
    transparent: {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    blue: {
      borderColor: "blue.200",
      borderWidth: "1px",
      boxShadow: "base",
      bg: "white",
    },
  },
  defaultProps: {
    variant: "white",
  },
});
