import {
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Button } from "../../../components";
import { useDuplicateCallGuidesToPositionMutation } from "../../graphql";
import PositionSelect from "../PositionSelect";

export interface FormData {
  callGuideIds: string[];
  fromPositionId: string;
  positionId: string;
}

interface DuplicateCallGuideModalProps {
  callGuideIds: string[];
  fromPositionId: string;
  isOpen: boolean;
  onClose: () => void;
}

const DuplicateCallGuidesModal: React.FC<DuplicateCallGuideModalProps> = ({
  callGuideIds,
  fromPositionId,
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate();
  const { control, setValue, handleSubmit } = useForm<FormData>({
    defaultValues: {
      positionId: "",
    },
  });

  const [duplicateCallGuidesToPositionMutation, { error, loading }] =
    useDuplicateCallGuidesToPositionMutation({
      onCompleted: (x) => {
        const positionId = x.duplicateCallGuidesToPosition?.position.id;
        if (positionId) navigate(`/position/${positionId}/guides`);
      },
    });

  const onSubmit = handleSubmit(({ positionId }): void => {
    duplicateCallGuidesToPositionMutation({
      variables: {
        toPositionId: positionId,
        fromPositionId,
        callGuideIds,
      },
    });
    onClose();
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay>
        <form onSubmit={onSubmit}>
          <ModalContent>
            <ModalHeader>Copy guides to new position</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing="6">
                <FormControl>
                  <FormLabel color="gray.600" htmlFor="positionId">
                    Select a position to copy guides to
                  </FormLabel>
                  <Controller
                    name="positionId"
                    control={control}
                    render={({ field }) => (
                      <PositionSelect
                        {...field}
                        positionId={field.value}
                        currentPositionId={fromPositionId}
                        onSelect={(position) => {
                          if (position?.id !== field.value) {
                            setValue("positionId", position?.id || "");
                          }
                        }}
                      />
                    )}
                  />
                  {error !== undefined && (
                    <FormErrorMessage>{error.message}</FormErrorMessage>
                  )}
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter
              borderColor="gray.100 !important"
              borderTop="1px solid"
            >
              <ButtonGroup
                spacing={4}
                alignSelf="end"
                width="100%"
                justifyContent="space-between"
              >
                <Button fontWeight="500" variant="ghost">
                  Cancel
                </Button>
                <Button
                  fontWeight="500"
                  type="submit"
                  isLoading={loading}
                  borderRadius="lg"
                >
                  Save
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </form>
      </ModalOverlay>
    </Modal>
  );
};

export default DuplicateCallGuidesModal;
