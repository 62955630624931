import React from "react";
import { useParams } from "react-router-dom";

import { Alert } from "../../../../components";
import AnalyticsSkillsReportResults from "./AnalyticsSkillsReportResults";
import SkillsReportLoading from "./SkillsReportLoading";
import useSkillsReport from "./useSkillsReport";

const AnalyticsSkillsReport: React.FC = () => {
  // The router handles the case where the reportId is not present
  const { reportId } = useParams() as { reportId: string };

  const {
    loading,
    skillReport,
    statusLoading,
    status,
    regenerateReport,
    error,
  } = useSkillsReport(reportId);

  if (loading || statusLoading) {
    return <SkillsReportLoading />;
  }

  if (error) {
    return <Alert status="error" description="Error loading report" reload />;
  }

  if (!skillReport) {
    return <Alert status="error" description="Report not found" reload />;
  }

  return (
    <AnalyticsSkillsReportResults
      skillReport={skillReport}
      regenerateReport={regenerateReport}
      status={status}
    />
  );
};

export default AnalyticsSkillsReport;
