import React, { forwardRef, useMemo, useState } from "react";
import CreatableSelect from "react-select/creatable";

import { Alert, Select, useToast } from "../../../components";
import useCurrentBreakpoint from "../../../hooks/useCurrentBreakpoint";
import useSelectTheme from "../../../hooks/useSelectTheme";
import {
  InterviewStageListItemFragment,
  useOrgInterviewStagesQuery,
} from "../../graphql";
import useAddStage from "../../graphql/hooks/useAddStage";
import useCurrentUser from "../../hooks/useCurrentUser";

const formatOption = (
  stage: InterviewStageListItemFragment
): { label: string; value: InterviewStageListItemFragment } => ({
  label: stage.title,
  value: stage,
});

const smallStyles: Partial<Record<string, any>> = {
  control: (provided: Record<string, any>) => ({
    ...provided,
    fontSize: "12px",
    minHeight: "30px",
  }),
  valueContainer: (provided: Record<string, any>) => ({
    ...provided,
    padding: "2px",
  }),
  dropdownIndicator: (provided: Record<string, any>) => ({
    ...provided,
    padding: "4px",
  }),
  clearIndicator: (provided: Record<string, any>) => ({
    ...provided,
    padding: "2px",
  }),
  input: (provided: Record<string, any>) => ({
    ...provided,
    marginLeft: "8px",
  }),
  placeholder: (provided: Record<string, any>) => ({
    ...provided,
    marginLeft: "8px",
  }),
};

type StageSelectProps = {
  interviewStageId?: string;
  name?: string;
  disabled?: boolean;
  onSelect: (stage: InterviewStageListItemFragment | undefined) => void;
  size?: "sm" | "md";
  isClearable?: boolean;
  customStyles?: Record<string, any>;
};

const StageSelect = forwardRef<any, StageSelectProps>(
  (
    {
      name,
      interviewStageId,
      disabled,
      onSelect,
      size = "md",
      isClearable = true,
      customStyles,
    },
    ref
  ) => {
    const currentBreakpoint = useCurrentBreakpoint();
    const currentUser = useCurrentUser();
    const [theme, styles] = useSelectTheme(
      size === "sm" ? smallStyles : customStyles
    );
    const [stage, setStage] = useState<InterviewStageListItemFragment>();
    const { loading, error, data } = useOrgInterviewStagesQuery();
    const stages = data?.currentUser?.organization.interviewStages.results;
    useMemo(() => {
      const stage = stages?.find((stage) => stage.id === interviewStageId);
      setStage(stage);
    }, [interviewStageId, stages]);

    const toast = useToast();

    const [addStage] = useAddStage({
      onError: (error?: Error) => {
        toast({
          title: "Error",
          status: "error",
          description: error?.message || "Error adding stage",
        });
      },
    });

    const props = {
      ref,
      name,
      theme,
      styles,
      isClearable,
      menuShouldBlockScroll: true,
      menuShouldScrollIntoView: true,
      menuPlacement: "auto" as const,
      isDisabled: disabled,
      isLoading: loading,
      value: stage ? formatOption(stage) : undefined,
      options: stages?.map(formatOption),
      onCreateOption: async (title: string) => {
        const x = await addStage({
          variables: {
            title,
          },
        });
        onSelect(x.data?.addInterviewStage?.interviewStage);
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange: (newValue) => {
        onSelect(newValue?.value);
      },
      menuPortalTarget: document.getElementById("root"),
    };
    return (
      <>
        {error ? (
          <Alert status="error" description="Error loading stages" />
        ) : currentUser.userRole?.canCreatePosition ? (
          <CreatableSelect
            aria-label="stage-select"
            placeholder={
              currentBreakpoint === "base"
                ? "Select or create stage..."
                : "Select or create new stage..."
            }
            {...props}
          />
        ) : (
          <Select
            aria-label="stage-select"
            placeholder="Select Stage..."
            {...props}
          />
        )}
      </>
    );
  }
);

export default StageSelect;
