import React from "react";

import { usePageTracker } from "../../../utils/googleAnalytics";
import { CandidateView } from "../../components/Candidate";
import CandidateViewV2 from "../../components/Candidate/CandidateViewV2";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import DefaultLayout from "../../layouts/DefaultLayout";

const CandidatePage: React.FC = () => {
  usePageTracker("candidate_page_redesign");
  const useCandidatePageV2 = useFeatureFlag("candidate_page:v2");

  return (
    <DefaultLayout>
      {useCandidatePageV2 ? <CandidateViewV2 /> : <CandidateView />}
    </DefaultLayout>
  );
};

export default CandidatePage;
