import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";

import { Button, useToast } from "../../../../components";
import {
  CallBetaFragment,
  SupportedTranscriptLanguage,
  useReTranscribeCallMutation,
} from "../../../graphql";

type SetLanguageModalProps = {
  call: Pick<CallBetaFragment, "id">;
  onClose(): void;
};

const SetLanguageModal: React.FC<SetLanguageModalProps> = ({
  call,
  onClose,
}) => {
  const toast = useToast();
  const supportedLanguages = Object.keys(SupportedTranscriptLanguage).map(
    (k) => {
      return {
        label: k,
        value:
          SupportedTranscriptLanguage[
            k as keyof typeof SupportedTranscriptLanguage
          ],
      };
    }
  );
  const [overrideLanguage, setOverrideLanguage] = useState<
    string | undefined
  >();
  const [retranscribeCall, retranscribeCallData] = useReTranscribeCallMutation({
    onError: (err) => {
      toast({
        title: "Transcription Error",
        description: err.message,
        status: "error",
      });
    },
  });
  const setLanguage = useCallback(() => {
    retranscribeCall({
      variables: {
        id: call.id,
        language: overrideLanguage,
      },
    }).then(() => {
      onClose();
    });
  }, [overrideLanguage]);

  const modal = useDisclosure({ isOpen: true, onClose });

  return (
    <Modal {...modal} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="semibold" fontSize="22px">
          Change transcript language
        </ModalHeader>
        <ModalCloseButton color="gray.600" />

        <ModalBody>
          <Text mb={4}>
            Set the correct the transcript language by choosing a different
            language below.
          </Text>
          <Text mb={4}>
            This does <b>not</b> translate the transcript. It transcribes the
            audio in the selected language.
          </Text>
          <Text mb={4}>
            AI notes will be re-generated and the content may change.
          </Text>
          <Select
            aria-label="Select transcript language"
            placeholder="Select a language..."
            size="sm"
            mb={6}
            onChange={(e: React.SyntheticEvent<HTMLSelectElement, Event>) => {
              if (e.currentTarget.value) {
                setOverrideLanguage(e.currentTarget.value);
              }
            }}
          >
            {supportedLanguages
              .filter(
                (l) => l.value !== SupportedTranscriptLanguage.EnglishSpanishMix
              )
              .map((language) => (
                <option key={language.value} value={language.value}>
                  {language.label}
                </option>
              ))}
          </Select>
        </ModalBody>

        <ModalFooter px="6" py="4" borderTopWidth="1px">
          <Button
            aria-label="Cancel language change"
            variant="white"
            mr="2"
            onClick={modal.onClose}
            fontWeight="medium"
            fontSize="sm"
            type="button"
          >
            Cancel
          </Button>

          <Button
            aria-label="Confirm language change"
            fontWeight="medium"
            fontSize="sm"
            type="submit"
            isLoading={retranscribeCallData.loading}
            isDisabled={overrideLanguage == null}
            data-testid="confirmation-modal-submit"
            onClick={setLanguage}
          >
            Change language
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SetLanguageModal;
