import { BoxProps, Flex, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";

import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { canViewIQReport } from "../../../utils/permissions";
import { MetricName, UserRoleName } from "../../graphql";
import { useFeatureFlagForCurrentOrImpersonatedUser } from "../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../hooks/useCurrentUser";
import AnalyticsDateSelect from "./AnalyticsDateSelect";
import AnalyticsFilters from "./AnalyticsFilters";
import AnalyticsInfoModal from "./AnalyticsInfoModal";
import AnalyticsReportMenu from "./AnalyticsReportMenu";
import AnalyticsReportSectionChart from "./AnalyticsReportSectionChart";
import AnalyticsReportSectionTable from "./AnalyticsReportSectionTable";
import LabeledChartSelect from "./LabeledChartSelect";
import ToggleFiltersButton from "./ToggleFiltersButton";
import ToggleFiltersPanel from "./ToggleFiltersPanel";
import useAnalyticsConfig from "./useAnalyticsConfig";
import useReportData from "./useReportData";

const AnalyticsReport: React.FC = () => {
  const sendGAEvent = useSendGAEvent();
  const [showFilters, setShowFilters] = useState(true);
  const [filterHeights, setFilterHeights] = useState<{ [key: string]: number }>(
    {
      defaultHeight: 50,
    }
  );

  const trendsFlagEnabled = useFeatureFlagForCurrentOrImpersonatedUser(
    "analytics:quality-report-trends"
  );
  const reportFlagEnabled = useFeatureFlagForCurrentOrImpersonatedUser(
    "analytics:quality-report"
  );
  const myInsightsFlagEnabled = useFeatureFlagForCurrentOrImpersonatedUser(
    "analytics:my-insights"
  );
  const queryConfig = useAnalyticsConfig(MetricName.Report);
  const reportData = useReportData(queryConfig);
  const currentUser = useCurrentUser();
  const isAdmin = currentUser.userRole?.name === UserRoleName.SiteAdmin;

  if (!canViewIQReport(currentUser) || !reportFlagEnabled) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <Flex
        flexDir="row"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Flex flexDir="row" alignItems="baseline">
          <Text fontSize="24px" fontWeight="700" color="gray.700" pr="2">
            Interview Quality
          </Text>
          <AnalyticsInfoModal metric={MetricName.Report} />
        </Flex>
        <Flex ml="auto">
          <ToggleFiltersButton
            open={showFilters}
            toggleFilters={() => {
              sendGAEvent(
                `filters_${!showFilters ? "open" : "closed"}`,
                "analytics"
              );
              setShowFilters((state) => !state);
            }}
          />
          <Flex minW="148px">
            <AnalyticsDateSelect
              state={queryConfig.dateRange.value}
              onSelect={queryConfig.dateRange.setValue}
            />
            <AnalyticsReportMenu
              canEditReport={!!currentUser.userRole?.canManageIQReport}
              refetchAllData={reportData.refetchAllData}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex my="4">
        <ToggleFiltersPanel
          showFilters={showFilters}
          filterHeights={filterHeights}
          flex="1"
          mt="4"
          mb="0"
        >
          <AnalyticsFilters
            queryConfig={queryConfig}
            queryVariables={reportData.commonQueryVariables}
            filterHeights={filterHeights}
            setFilterHeights={setFilterHeights}
          />
        </ToggleFiltersPanel>
      </Flex>
      {trendsFlagEnabled && (
        <ReportContainer mb="6">
          <ResultHeader
            headerText="Average Overall Interview Quality Score"
            captionText="A composite score across all qualifying interviews over the selected time period."
          />
          <AnalyticsReportSectionChart
            chart={reportData.chart}
            queryConfig={queryConfig}
          />
        </ReportContainer>
      )}
      <ReportContainer>
        <ResultHeader
          headerText="Overall Interview Quality Score"
          captionText="A composite score with supporting metrics for all qualifying interviews over the selected time period."
        >
          <Text
            color="gray.600"
            fontWeight="400"
            fontSize="sm"
            whiteSpace="nowrap"
          >
            {queryConfig.dateRange.displayValue}
          </Text>
        </ResultHeader>
        <ResultControls>
          <LabeledChartSelect
            testid="analytics--group-menu"
            label="Group by"
            flexDir="column"
            alignItems="flex-start"
            singleSelect={queryConfig.primaryDimension}
          />
          <Flex>
            <LabeledChartSelect
              data-testid="analytics--rows-menu"
              flexDir="column"
              alignItems="flex-start"
              label="Rows"
              singleSelect={queryConfig.chartLimit}
              customSelectStyles={{
                container: (provided: any) => ({ ...provided, width: "96px" }),
              }}
            />
          </Flex>
        </ResultControls>
        <AnalyticsReportSectionTable
          reportData={reportData}
          queryConfig={queryConfig}
          linkToMyInsights={isAdmin && myInsightsFlagEnabled}
        />
      </ReportContainer>
    </>
  );
};

export const ResultHeader: React.FC<{
  headerText: string;
  captionText?: string;
  children?: React.ReactNode;
}> = ({ headerText, captionText, children }) => (
  <Flex flexDir="row" justifyContent="space-between" alignItems="start">
    <Flex flexDir="column" mr="4">
      <Text fontSize="lg" fontWeight="600" color="gray.800" mb={1}>
        {headerText}
      </Text>
      {captionText && (
        <Text color="gray.600" fontWeight="400" fontSize="sm">
          {captionText}
        </Text>
      )}
    </Flex>
    {children}
  </Flex>
);

export const ReportContainer: React.FC<
  {
    children: React.ReactNode;
  } & BoxProps
> = ({ children, ...props }) => (
  <VStack
    spacing="6"
    alignItems="stretch"
    px="6"
    py="5"
    bg="white"
    border="1px solid"
    borderColor="gray.200"
    borderRadius="8px"
    {...props}
  >
    {children}
  </VStack>
);

export const ResultControls: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <Flex flexDir="row" justifyContent="space-between" alignItems="center">
    {children}
  </Flex>
);

export default AnalyticsReport;
