import {
  Box,
  Flex,
  Icon,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import {
  HiInformationCircle,
  HiOutlineInformationCircle,
} from "react-icons/hi2";

import { IconButton, Link } from "../../../../components";
import { copy } from "../../../../utils/clipboard";
import { formatDuration } from "../../../../utils/datetime";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  CandidateSummaryNoteAccuracyFeedback,
  useUpdateSummaryNoteFeedbackMutation,
} from "../../../graphql";
import FeedbackMenu from "../../FeedbackMenu/FeedbackMenu";
import MonospacedText from "../../MonospacedText";
import ColumnHeader from "./ColumnHeader";
import { SUBSECTION_NO_CONTENT } from "./messages";
import { Note, Topic } from "./types";
import { encodeToAnchor, formatTopic } from "./utils";

/**
 * Derived from the Notes.tsx for "other" AI notes formats on the call page.
 */
const Notes: React.FC<{
  topics: Topic[];
  citationMap: { [key: string]: number };
  onClickTimestamp(callId: string, t: number): void;
}> = ({ onClickTimestamp, topics, citationMap }) => {
  // Create a time-ordered mapping to footnote numbers
  // TODO: also order by call.start_time
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();

  const copyTopic = (topic: Topic): void => {
    const result = formatTopic(topic, citationMap);
    if (!result) {
      return;
    }
    copy({ plain: result.plain, html: result.html });
    toast({
      title: "Section copied to clipboard",
      status: "success",
      position: "top",
    });
    sendGAEvent("candidate_summary_copy_section", "candidate");
  };

  const renderedHeaders = topics.map((topic) => {
    return (
      <Box mb={4} fontSize="sm" key={topic.name} pb="12px">
        <Flex
          pb="6px"
          flexDir="row"
          alignItems="center"
          position="relative"
          role="group"
          gap="2"
        >
          <Flex
            fontWeight="600"
            fontSize="sm"
            bg="gray.50"
            px={4}
            py="10px"
            id={encodeToAnchor("topic", topic.name)}
            width="100%"
            borderRadius="lg"
            mb={2}
            flexDir="row"
            alignItems="center"
            color="gray.800"
          >
            {topic.name}
            <Tooltip
              label="Copy section notes"
              placement="bottom-start"
              marginLeft="auto"
            >
              <IconButton
                size="xs"
                aria-label="Copy section notes"
                height={4}
                fontSize="sm"
                fontWeight="medium"
                variant="ghost"
                colorScheme="blue"
                ml="auto"
                icon={
                  <HiOutlineClipboardCopy
                    style={{ transform: "scaleX(-1)" }}
                    strokeWidth="1.5px"
                    size={20}
                  />
                }
                onClick={() => copyTopic(topic)}
                visibility="hidden"
                _hover={{ bg: "transparent" }}
                _groupHover={{ visibility: "visible" }}
              />
            </Tooltip>
          </Flex>
        </Flex>
        <Box color="gray.700" px={4}>
          {topic.subTopics?.length === 0 || !topic.subTopics ? (
            <EmptyStateNote errorText="No results found for this topic." />
          ) : undefined}
          {topic.subTopics
            .filter(
              (subTopic) =>
                subTopic.name !== "Other" ||
                (subTopic.notes.length > 0 && subTopic.notes[0].text !== "")
            )
            .map((subTopic) => {
              if (subTopic.notes.length === 0) {
                return "";
              }
              return (
                <Box key={subTopic.name} mb={5}>
                  <Text
                    fontWeight="medium"
                    color="gray.800"
                    mb={2}
                    id={encodeToAnchor("subtopic", subTopic.name)}
                  >
                    {subTopic.name}
                  </Text>
                  {subTopic.notes[0].text === "" ? (
                    <EmptyStateNote errorText={SUBSECTION_NO_CONTENT} />
                  ) : undefined}
                  {subTopic.notes
                    .filter((n) => n.text.length > 0)
                    .map((note) => {
                      return (
                        <SummaryNote
                          key={note.id}
                          note={note}
                          citationMap={citationMap}
                          onClickTimestamp={onClickTimestamp}
                        />
                      );
                    })}
                </Box>
              );
            })}
        </Box>
      </Box>
    );
  });
  return (
    <Box
      fontSize="sm"
      overflow="auto"
      px={6}
      borderLeft="1px solid"
      borderColor="gray.200"
    >
      <Flex flexDir="row" alignItems="normal">
        <ColumnHeader mb={2}>Summary</ColumnHeader>
        <Tooltip
          bg="white"
          p="2"
          ml={2}
          borderRadius="md"
          border="1px solid"
          borderColor="gray.200"
          color="gray.800"
          boxShadow="none"
          fontSize="sm"
          shouldWrapChildren
          fontWeight="normal"
          placement="top"
          mb={2}
          label={
            <>
              This summary was generated by AI across all interviews. Topics are
              derived from the job description, when available.
            </>
          }
        >
          <Icon
            boxSize={4}
            color="gray.700"
            as={HiOutlineInformationCircle}
            ml={1}
          />
        </Tooltip>
      </Flex>
      <Flex
        direction="row"
        alignItems="center"
        gap="2"
        p="3"
        mb="4"
        mt="1"
        backgroundColor="blue.100"
      >
        <Box>
          <Icon color="blue.600" boxSize={6} as={HiInformationCircle} />
        </Box>
        <Box>
          While utilizing AI-powered tools can unlock significant efficiencies,
          they have limitations. Always check any content generated by AI for
          accuracy.
        </Box>
      </Flex>
      {renderedHeaders}
    </Box>
  );
};

type NoteProps = {
  note: Note;
  citationMap: { [key: string]: number };
  onClickTimestamp(callId: string, t: number): void;
};

const SummaryNote: React.FC<NoteProps> = ({
  note,
  onClickTimestamp,
  citationMap,
}) => {
  const { isOpen: isHovering, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [updateFeedback] = useUpdateSummaryNoteFeedbackMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: "Unable to save feedback at this time",
        status: "error",
      });
    },
  });
  const sendGAEvent = useSendGAEvent();
  const savePositiveFeedback = (): void => {
    sendGAEvent(
      "candidate_summary_note_accuracy_feedback_positive",
      "candidate"
    );
    updateFeedback({
      variables: {
        noteId: note.id,
        feedback: CandidateSummaryNoteAccuracyFeedback.Good,
      },
    });
  };
  const saveNegativeFeedback = (): void => {
    sendGAEvent(
      "candidate_summary_note_accuracy_feedback_negative",
      "candidate"
    );
    updateFeedback({
      variables: {
        noteId: note.id,
        feedback: CandidateSummaryNoteAccuracyFeedback.Bad,
      },
    });
  };
  return (
    <Box
      key={note.id}
      _hover={{
        bg: "blue.50",
      }}
      cursor="pointer"
      onMouseEnter={onOpen}
      onMouseLeave={onClose}
    >
      {isHovering && (
        <FeedbackMenu
          savePositiveFeedback={savePositiveFeedback}
          saveNegativeFeedback={saveNegativeFeedback}
        />
      )}
      <Text as="span" pr={1} color="gray.800">
        {note.text}
      </Text>
      <Text as="span" verticalAlign="top" fontSize="xs">
        {note.citations.map((citation, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={citation.id}>
            {idx > 0 && " "}
            <Tooltip label={formatDuration(Math.round(citation.time))}>
              <Link
                fontWeight="normal"
                href={`/interview/${citation.callId}?t=${citation.time}`}
                onClick={(e) => {
                  e.preventDefault();
                  sendGAEvent(
                    "candidate_summary_note_citation_click",
                    "candidate"
                  );
                  onClickTimestamp(citation.callId, citation.time);
                }}
                pt={2}
              >
                <MonospacedText
                  display="inline-block"
                  text={citationMap[citation.id].toString()}
                />
              </Link>
            </Tooltip>
          </React.Fragment>
        ))}
      </Text>
    </Box>
  );
};

const EmptyStateNote: React.FC<{ errorText: string }> = ({ errorText }) => {
  return (
    <Box>
      <Flex direction="row" align="baseline">
        <Text as="span">
          <Text as="span" pr={1} color="gray.500">
            {errorText}
          </Text>
        </Text>
      </Flex>
    </Box>
  );
};

export default Notes;
