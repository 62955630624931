import { BoxProps, ButtonGroup, Icon, Tooltip } from "@chakra-ui/react";
import React from "react";
import { MdFullscreen, MdPictureInPicture } from "react-icons/md";

import { IconButton } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { VideoStateControl } from "./useVideoState";

type VideoFullscreenControlsProps = BoxProps & {
  fullScreenControls?: VideoStateControl;
  pipControls?: VideoStateControl;
};

const VideoFullscreenControls: React.FC<VideoFullscreenControlsProps> = ({
  pipControls = {
    isSupported: false,
    toggle: () => undefined,
  },
  fullScreenControls = {
    isSupported: false,
    isActive: false,
    toggle: () => undefined,
  },
  ...styles
}) => {
  const { isSupported: pipSupported, toggle: togglePictureInPicture } =
    pipControls;
  const sendGAEvent = useSendGAEvent();

  const {
    isSupported: fullscreenSupported,
    isActive: fullscreenActive,
    toggle: toggleFullscreen,
  } = fullScreenControls;

  const onFullScreen = (): void => {
    if (toggleFullscreen) {
      sendGAEvent("toggle_fullscreen", "call_review");
      toggleFullscreen();
    }
  };

  const onPipToggle = (): void => {
    if (togglePictureInPicture) {
      sendGAEvent("toggle_pip", "call_review");
      togglePictureInPicture();
    }
  };

  return (
    <ButtonGroup
      variant="unstyled"
      size="sm"
      color="white"
      opacity={0.8}
      pointerEvents="none"
      {...styles}
    >
      {pipSupported && !fullscreenActive && (
        <Tooltip label="Picture-in-picture">
          <IconButton
            bg="transparent"
            color="white"
            aria-label="Picture in picture"
            display="flex"
            pointerEvents="all"
            _hover={{ transform: "scale(1.1)" }}
            icon={<Icon as={MdPictureInPicture} color="white" boxSize="7" />}
            onClick={onPipToggle}
          />
        </Tooltip>
      )}

      {fullscreenSupported && (
        <Tooltip label="Fullscreen">
          <IconButton
            bg="transparent"
            color="white"
            aria-label="Toggle fullscreen"
            display="flex"
            pointerEvents="all"
            _hover={{ transform: "scale(1.1)" }}
            icon={<Icon as={MdFullscreen} boxSize="7" color="white" />}
            onClick={onFullScreen}
          />
        </Tooltip>
      )}
    </ButtonGroup>
  );
};

export default VideoFullscreenControls;
