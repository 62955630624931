import { useControllableState } from "@chakra-ui/react";
import React from "react";

import { CandidateAlertFeedFilter } from "../../graphql";
import { CandidateAlertFilter } from "./CandidateAlertFilter";

export enum TypeFilter {
  Interviews = "Interviews",
  Status = "Status",
  Type = "Type",
}

type CandidateAlertFiltersProps = {
  filters?: TypeFilter[];
  filterState?: CandidateAlertFeedFilter[];
  spacer?: React.ReactNode;
  onChange?(filterState: CandidateAlertFeedFilter[]): void;
};

export const CandidateAlertFilters: React.FC<CandidateAlertFiltersProps> = ({
  filters = [TypeFilter.Interviews, TypeFilter.Status, TypeFilter.Type],
  filterState,
  spacer,
  onChange,
}) => {
  const [currentFilters, setCurrentFilters] = useControllableState({
    value: filterState,
    onChange,
  });

  const showInterviews = filters.includes(TypeFilter.Interviews);
  const showStatus = filters.includes(TypeFilter.Status);
  const showType = filters.includes(TypeFilter.Type);

  return (
    <>
      {showInterviews && (
        <CandidateAlertFilter
          title="Interviews"
          options={["All", "My"]}
          defaultOptions={["All"]}
          onChange={(option) => {
            const resultingFilters = currentFilters.filter(
              (f) =>
                f !== CandidateAlertFeedFilter.All &&
                f !== CandidateAlertFeedFilter.MyInterviews
            );
            if (option === "All") {
              resultingFilters.push(CandidateAlertFeedFilter.All);
            }
            if (option === "My") {
              resultingFilters.push(CandidateAlertFeedFilter.MyInterviews);
            }
            setCurrentFilters(resultingFilters);
          }}
        />
      )}
      {showInterviews && showStatus && spacer}
      {showStatus && (
        <CandidateAlertFilter
          title="Status"
          options={["Unread", "Read"]}
          allowEmpty
          onChange={(option) => {
            const resultingFilters = currentFilters.filter(
              (f) =>
                f !== CandidateAlertFeedFilter.Read &&
                f !== CandidateAlertFeedFilter.Unread
            );
            if (option === "Unread") {
              resultingFilters.push(CandidateAlertFeedFilter.Unread);
            }
            if (option === "Read") {
              resultingFilters.push(CandidateAlertFeedFilter.Read);
            }
            setCurrentFilters(resultingFilters);
          }}
        />
      )}
      {(showInterviews || showStatus) && showType && spacer}
      {showType && (
        <CandidateAlertFilter
          title="Type"
          options={["Closing risks", "Action items"]}
          allowEmpty
          allowMultiple
          onChange={(options) => {
            const resultingFilters = currentFilters.filter(
              (f) =>
                f !== CandidateAlertFeedFilter.ClosingRisk &&
                f !== CandidateAlertFeedFilter.ActionItem
            );
            if (options.includes("Closing risks")) {
              resultingFilters.push(CandidateAlertFeedFilter.ClosingRisk);
            }
            if (options.includes("Action items")) {
              resultingFilters.push(CandidateAlertFeedFilter.ActionItem);
            }
            setCurrentFilters(resultingFilters);
          }}
        />
      )}
    </>
  );
};
