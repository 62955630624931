import { useEffect } from "react";

import {
  SkillReportProcessingStatus,
  SkillsReportWithResultsFragment,
  useRunSkillReportMutation,
  useSkillReportQuery,
  useSkillReportStatusQuery,
} from "../../../graphql";

type SkillReport = {
  loading: boolean;
  regenerateReport: () => void;
  skillReport?: SkillsReportWithResultsFragment | null;
  statusLoading: boolean;
  status?: SkillReportProcessingStatus | null;
  error?: Error;
};

const useSkillsReport = (id: string): SkillReport => {
  const {
    loading,
    error,
    data,
    refetch: refetchData,
  } = useSkillReportQuery({
    variables: { id },
  });

  const {
    loading: statusLoading,
    error: statusError,
    data: statusData,
    startPolling: startPollingStatus,
    stopPolling: stopPollingStatus,
  } = useSkillReportStatusQuery({
    variables: { id },
  });
  const currentStatus = statusData?.skillReport?.processingStatus;

  const terminalStatuses: SkillReportProcessingStatus[] = [
    SkillReportProcessingStatus.Failed,
    SkillReportProcessingStatus.Completed,
  ];

  const [runSkillsReport] = useRunSkillReportMutation();

  useEffect(() => {
    if (currentStatus && terminalStatuses.includes(currentStatus)) {
      stopPollingStatus();
      refetchData();
    } else {
      startPollingStatus(1500);
    }
    return () => {
      stopPollingStatus();
    };
  }, [currentStatus, startPollingStatus, stopPollingStatus, refetchData]);

  const skillReport = data?.skillReport;

  return {
    loading,
    regenerateReport: () => runSkillsReport({ variables: { reportId: id } }),
    skillReport,
    statusLoading,
    status: statusData?.skillReport?.processingStatus,
    error: error || statusError,
  };
};

export default useSkillsReport;
