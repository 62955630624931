import {
  ButtonProps,
  Flex,
  Icon,
  Menu,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import { HiOutlineChevronDown, HiOutlineSparkles } from "react-icons/hi";
import { HiArrowTopRightOnSquare } from "react-icons/hi2";
import { IoCopyOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import { Button, IconButton, MenuButton } from "../../../components";
import { AiNotesProcessingStatus } from "../../graphql";
import useAiNotes from "../Recording/SidebarTabs/Ai/Notes/NotesQA/useAiNotes";

type CopyAiNotesButtonProps = {
  call: {
    id: string;
    aiNotesProcessingStatus?: AiNotesProcessingStatus | null;
  };
  variant?: "icon" | "full" | "menuItem";
  onCopy?(): void;
  onOpen?(): void;
} & ButtonProps;

const CopyAiNotesButton: React.FC<CopyAiNotesButtonProps> = ({
  call,
  variant = "icon",
  onCopy,
  onOpen,
  iconSpacing = "1",
  ...styles
}) => {
  const {
    copyAiNotes,
    data: { aiNotes, loading, error },
  } = useAiNotes({ call, shouldCreateNotes: false, shouldPoll: false });
  const navigate = useNavigate();

  if (variant === "menuItem") {
    return (
      <Flex
        flex="1"
        fontSize="sm"
        onClick={() => {
          copyAiNotes();
          onCopy?.();
        }}
      >
        Copy AI Notes
      </Flex>
    );
  }

  return variant === "icon" ? (
    <Tooltip label="Copy All AI Notes">
      <IconButton
        variant="icon"
        aria-label="Copy All AI Notes"
        hidden={loading || !!error}
        ml={2}
        icon={<IoCopyOutline strokeWidth="1.5px" size={20} />}
        onClick={() => {
          copyAiNotes();
          onCopy?.();
        }}
      />
    </Tooltip>
  ) : (
    <Menu placement="bottom-end">
      <MenuButton
        as={Button}
        variant="outline"
        size="xs"
        pr="2"
        pl="2.5"
        lineHeight="4"
        fontWeight="medium"
        aria-label="AI Notes"
        hidden={loading || !!error || aiNotes.length === 0}
        {...styles}
      >
        <Flex alignItems="center" justifyContent="center" gap={iconSpacing}>
          <Icon as={HiOutlineSparkles} boxSize="4" strokeWidth="1.5px" />
          AI notes
          <Icon as={HiOutlineChevronDown} boxSize="4" />
        </Flex>
      </MenuButton>
      <MenuList>
        <MenuItem
          onClick={() => {
            navigate(`/interview/${call.id}?tab=ai_notes`);
            onOpen?.();
          }}
          mb="1"
        >
          <Icon as={HiArrowTopRightOnSquare} size={20} mr="2.5" />
          Open AI notes
        </MenuItem>
        <MenuItem
          onClick={() => {
            copyAiNotes();
            onCopy?.();
          }}
        >
          <Icon as={IoCopyOutline} strokeWidth="1.5px" size={20} mr="2.5" />
          Copy AI notes
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default CopyAiNotesButton;
