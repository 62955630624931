import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const BhLogo = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 25 24" fill="currentColor" {...props}>
    <path d="M13.252 2.28862C13.252 2.66806 12.9429 2.97567 12.5616 2.97567C12.1803 2.97567 11.8712 2.66806 11.8712 2.28862C11.8712 1.90917 12.1803 1.60156 12.5616 1.60156C12.9429 1.60156 13.252 1.90917 13.252 2.28862Z" />
    <path d="M11.8532 3.97071C11.8532 3.59164 12.1627 3.28365 12.5436 3.28365C12.9185 3.28365 13.234 3.59164 13.234 3.97071C13.234 4.34977 12.9245 4.65776 12.5436 4.65776C12.1627 4.65776 11.8532 4.34977 11.8532 3.97071Z" />
    <path d="M13.0139 5.15529C12.752 4.89468 12.2997 4.89468 12.0378 5.15529C11.9069 5.28559 11.8354 5.45735 11.8354 5.63504L11.8116 8.12265V8.1878C11.8295 8.55501 12.1271 8.84524 12.502 8.84524C12.877 8.84524 13.1746 8.55502 13.1924 8.17595L13.2222 5.63504C13.2103 5.45735 13.1389 5.28559 13.0139 5.15529Z" />
    <path d="M11.7104 8.14634L10.8891 5.81865C10.8296 5.64688 10.7046 5.50473 10.5379 5.42181C10.3773 5.34482 10.1868 5.33297 10.0142 5.3922C9.8416 5.45143 9.69876 5.57581 9.61544 5.74165C9.53211 5.90749 9.52616 6.0911 9.58568 6.26878L10.4189 8.60832C10.5082 8.89854 10.776 9.09992 11.0795 9.09992C11.4605 9.09992 11.7699 8.79193 11.7699 8.41286C11.764 8.30625 11.7402 8.19964 11.7104 8.14634Z" />
    <path d="M17.2812 6.71893C17.3288 6.76039 17.3883 6.83146 17.43 6.91438C17.4776 6.9973 17.5014 7.08615 17.5133 7.18684C17.5193 7.27568 17.5073 7.37044 17.4776 7.45929C17.4478 7.54221 17.4002 7.62513 17.3407 7.6962L15.6802 9.62706C15.5492 9.77513 15.3588 9.86397 15.1564 9.86397C14.7755 9.86397 14.466 9.55598 14.466 9.17692C14.466 8.98739 14.5375 8.81562 14.6744 8.68532L16.2813 6.78408C16.3349 6.71301 16.4063 6.65378 16.4896 6.61232C16.5789 6.56493 16.6682 6.54124 16.7634 6.53532C16.8586 6.52347 16.9538 6.54124 17.0431 6.57086C17.1324 6.60639 17.2098 6.65378 17.2812 6.71893Z" />
    <path d="M15.4303 5.77719C15.3529 5.61135 15.216 5.48105 15.0434 5.4159C14.8708 5.35075 14.6863 5.35667 14.5197 5.43367C14.353 5.51066 14.2221 5.64689 14.1566 5.81865L13.2877 8.12265C13.246 8.21742 13.2222 8.31218 13.2222 8.41879C13.2222 8.79786 13.5317 9.10585 13.9126 9.10585C14.1685 9.10585 14.4066 8.9637 14.5256 8.73863C14.5333 8.72716 14.5385 8.71569 14.5445 8.70262C14.5477 8.69545 14.5512 8.68779 14.5554 8.6794L15.4541 6.30433C15.5136 6.13256 15.5077 5.94303 15.4303 5.77719Z" />
    <path d="M8.74666 6.73669L10.3477 8.66755C10.4548 8.78009 10.5203 8.94593 10.5203 9.11769C10.5203 9.49675 10.2108 9.80474 9.82987 9.80474C9.59775 9.80474 9.38944 9.68629 9.25851 9.49675L7.68131 7.60736C7.62775 7.54221 7.58013 7.45928 7.55633 7.37636C7.52657 7.28752 7.52062 7.19868 7.52657 7.10984C7.53847 7.01507 7.56228 6.93215 7.60394 6.84923C7.6456 6.77223 7.70512 6.70116 7.77654 6.64193C7.84796 6.58862 7.92533 6.54124 8.01461 6.51755C8.10388 6.49386 8.19316 6.48793 8.28243 6.49386C8.37171 6.49978 8.46098 6.52347 8.53835 6.56493C8.61573 6.61231 8.68714 6.66562 8.74666 6.73669Z" />
    <path d="M9.27019 9.63298L7.08593 8.33587C6.93118 8.24702 6.74668 8.21741 6.56813 8.26479C6.38958 8.31217 6.24079 8.41879 6.14556 8.5787C6.09795 8.6557 6.06819 8.74454 6.05629 8.83339C6.04438 8.92223 6.05033 9.01107 6.06819 9.09992C6.092 9.18876 6.12771 9.27168 6.18127 9.34275C6.23484 9.41383 6.30626 9.47306 6.38363 9.52044L8.53813 10.7939C8.65122 10.8649 8.7762 10.9005 8.90714 10.9005C9.28804 10.9005 9.59753 10.5925 9.59753 10.2134C9.59158 9.98242 9.4785 9.7692 9.27019 9.63298Z" />
    <path d="M8.51432 10.8827C8.52027 10.8886 8.57978 10.9005 8.58574 10.9005C8.89522 10.9952 9.09163 11.2558 9.09758 11.5579C9.09758 11.937 8.78809 12.245 8.40719 12.245C8.40125 12.245 8.38642 12.2435 8.37157 12.242C8.35674 12.2405 8.3417 12.239 8.33577 12.239C8.31881 12.2369 8.30488 12.2356 8.29315 12.2344C8.27202 12.2324 8.25797 12.231 8.24649 12.2272L5.78845 11.7652C5.6099 11.7297 5.4492 11.629 5.34803 11.475C5.24685 11.321 5.20519 11.1433 5.2409 10.9597C5.27661 10.782 5.38374 10.6221 5.53253 10.5214C5.68727 10.4207 5.86582 10.3793 6.05032 10.4148L8.51432 10.8827Z" />
    <path d="M8.34797 12.3101C8.32417 12.3101 8.30037 12.316 8.27656 12.322L5.81256 12.7306C5.72328 12.7425 5.63401 12.7662 5.55069 12.8136C5.46736 12.855 5.39594 12.9142 5.34238 12.9912C5.28286 13.0623 5.2412 13.1512 5.22334 13.24C5.19954 13.3288 5.19954 13.4236 5.21144 13.5125C5.2293 13.6072 5.25905 13.6961 5.31262 13.7731C5.36023 13.8501 5.43165 13.9211 5.50902 13.9685C5.5864 14.0159 5.66972 14.0574 5.76495 14.0751C5.81256 14.087 5.85422 14.087 5.90183 14.087C5.94945 14.087 5.99111 14.0811 6.02682 14.0751L8.55033 13.6605C8.58009 13.6546 8.60389 13.6487 8.6277 13.6368C8.90743 13.5421 9.09788 13.2815 9.09788 12.9853C9.09193 12.6122 8.78244 12.322 8.34797 12.3101Z" />
    <path d="M8.51438 13.7671C8.59175 13.702 8.73459 13.6546 8.88933 13.6546C9.27024 13.6546 9.57972 13.9567 9.56782 14.3357C9.56782 14.5608 9.45474 14.774 9.26429 14.9043C9.26131 14.9073 9.25089 14.9132 9.24048 14.9191C9.23007 14.9251 9.21965 14.931 9.21667 14.9339L7.01455 16.1778C6.91338 16.237 6.79434 16.2666 6.67531 16.2666C6.61579 16.2666 6.55032 16.2607 6.49081 16.2429C6.31821 16.1955 6.16942 16.083 6.08014 15.9231C5.99087 15.7632 5.96706 15.5795 6.02062 15.4019C6.06824 15.2301 6.18727 15.0761 6.34201 14.9873L8.51438 13.7671Z" />
    <path d="M9.80569 14.7563C9.63904 14.7563 9.47239 14.8214 9.34741 14.934C9.32955 14.9458 9.3117 14.9636 9.29979 14.9813L7.63928 16.8826C7.57381 16.9537 7.52619 17.0307 7.49048 17.1195C7.46073 17.2083 7.44287 17.3031 7.45477 17.3979C7.46073 17.4926 7.48453 17.5815 7.53215 17.6703C7.57381 17.7532 7.63928 17.8302 7.69284 17.8717C7.76426 17.9368 7.84163 17.9842 7.93091 18.0079C8.00233 18.0316 8.0797 18.0435 8.15707 18.0435H8.20468C8.29991 18.0375 8.38919 18.0138 8.47251 17.9724C8.55583 17.9309 8.62725 17.8717 8.68677 17.8065L10.2759 15.9586C10.4187 15.8283 10.502 15.6447 10.502 15.4492C10.4961 15.0583 10.1866 14.7563 9.80569 14.7563Z" />
    <path d="M10.3951 15.9408C10.4785 15.6743 10.7463 15.4729 11.0498 15.4729C11.4307 15.4729 11.7402 15.7809 11.7283 16.1659C11.7283 16.2547 11.7105 16.3436 11.6748 16.4265L10.7939 18.7779C10.7582 18.8608 10.7106 18.9378 10.6511 19.003C10.5916 19.0681 10.5201 19.1214 10.4368 19.157C10.3475 19.1984 10.2583 19.2221 10.169 19.2221H10.1511C10.0678 19.2221 9.98448 19.2043 9.90711 19.1747C9.82378 19.1392 9.74641 19.0918 9.68094 19.0326C9.61548 18.9674 9.56191 18.8963 9.5262 18.8134C9.49049 18.7246 9.46668 18.6357 9.46668 18.5469C9.46668 18.4581 9.47859 18.3692 9.5143 18.2863L10.3951 15.9408Z" />
    <path d="M13.1566 16.3614C13.1328 16.006 12.8293 15.7276 12.4722 15.7276C12.1151 15.7276 11.8116 16.006 11.7818 16.3851L11.752 18.926C11.752 19.1037 11.8235 19.2754 11.9484 19.4116C12.0734 19.5419 12.246 19.6189 12.4365 19.6189C12.621 19.6189 12.7936 19.5479 12.9245 19.4235C13.0555 19.2991 13.1269 19.1273 13.1328 18.9437L13.1626 16.3969C13.1566 16.3851 13.1566 16.3673 13.1566 16.3614Z" />
    <path d="M14.5319 15.929L15.371 18.3159C15.4306 18.4995 15.4127 18.6831 15.3294 18.8312C15.2461 18.9911 15.1092 19.1096 14.9425 19.1688C14.8711 19.1984 14.7937 19.2103 14.7164 19.2103C14.6211 19.2103 14.5199 19.1925 14.4307 19.151C14.264 19.08 14.139 18.9497 14.0736 18.7838L13.2284 16.391C13.2046 16.3199 13.1927 16.2429 13.1927 16.1659C13.1927 15.7868 13.5022 15.4789 13.8831 15.4789C14.1509 15.4789 14.389 15.6328 14.5021 15.8698C14.514 15.8875 14.5259 15.9053 14.5319 15.929Z" />
    <path d="M17.4004 17.2024C17.3706 17.1136 17.329 17.0306 17.2695 16.9596L15.6863 15.0761C15.5554 14.8866 15.3471 14.78 15.1209 14.78C14.74 14.78 14.4305 15.0879 14.4305 15.467C14.4305 15.6625 14.5138 15.8461 14.621 15.9349L16.1863 17.8362C16.2458 17.9072 16.3172 17.9724 16.3946 18.0198C16.4779 18.0671 16.5672 18.0968 16.6624 18.1027C16.6743 18.1027 16.6862 18.1042 16.6981 18.1056C16.71 18.1071 16.7219 18.1086 16.7338 18.1086C16.8052 18.1086 16.8767 18.0968 16.9421 18.079C17.0314 18.0494 17.1147 18.002 17.1683 17.9546C17.2397 17.8954 17.3052 17.8243 17.3468 17.7414C17.3885 17.6585 17.4183 17.5755 17.4302 17.4749C17.4421 17.3801 17.4302 17.2912 17.4004 17.2024Z" />
    <path d="M18.7929 15.2301C18.8524 15.3071 18.8941 15.3959 18.9179 15.4907C18.9417 15.5795 18.9477 15.6743 18.9298 15.7691C18.912 15.8638 18.8762 15.9586 18.8227 16.0356C18.787 16.0948 18.7215 16.1659 18.6501 16.2192C18.5787 16.2666 18.4953 16.3081 18.4061 16.3317C18.3465 16.3495 18.293 16.3554 18.2335 16.3554C18.1978 16.3554 18.168 16.3554 18.1323 16.3495C18.043 16.3377 17.9597 16.3081 17.8823 16.2607L15.6802 14.9576C15.6713 14.9517 15.6623 14.9443 15.6534 14.9369C15.6445 14.9295 15.6356 14.9221 15.6266 14.9162C15.454 14.7859 15.3529 14.5904 15.3529 14.3713C15.3529 13.9922 15.6623 13.6842 16.0432 13.6842C16.1861 13.6842 16.323 13.7257 16.442 13.8086L18.5846 15.0524C18.662 15.0939 18.7334 15.1531 18.7929 15.2301Z" />
    <path d="M19.632 13.0919C19.5308 12.9439 19.3701 12.8432 19.1916 12.8076L16.6859 12.3457C16.6681 12.3397 16.6443 12.3397 16.6264 12.3397C16.6082 12.3397 16.59 12.3375 16.5758 12.3358C16.5669 12.3347 16.5596 12.3338 16.555 12.3338C16.1741 12.3338 15.8646 12.6418 15.8646 13.0209C15.8646 13.3525 16.1027 13.6368 16.43 13.6961L18.9357 14.1581C18.9773 14.164 19.019 14.1699 19.0606 14.1699C19.1975 14.1699 19.3344 14.1284 19.4534 14.0455C19.6022 13.9389 19.7094 13.7849 19.7391 13.6072C19.7748 13.4295 19.7391 13.24 19.632 13.0919Z" />
    <path d="M19.4596 10.6103C19.531 10.6576 19.5965 10.7228 19.6441 10.7998C19.6977 10.8827 19.7274 10.9656 19.7572 11.0545C19.7691 11.1433 19.7691 11.2322 19.7512 11.321C19.7334 11.4039 19.6977 11.4868 19.6441 11.5638C19.5846 11.6408 19.5251 11.706 19.4477 11.7534C19.3703 11.8067 19.287 11.8363 19.1977 11.8541L16.6683 12.2627C16.6504 12.2687 16.6207 12.2687 16.6028 12.2687H16.573C16.1921 12.2687 15.8826 11.9607 15.8826 11.5816C15.8826 11.2558 16.1029 10.9834 16.4183 10.9123L18.9537 10.4977C19.037 10.4859 19.1263 10.4859 19.2156 10.5036C19.2989 10.5214 19.3822 10.5569 19.4596 10.6103Z" />
    <path d="M18.912 8.66755C18.8644 8.58463 18.8049 8.51355 18.7275 8.45432C18.6502 8.39509 18.5668 8.35363 18.4716 8.32994C18.3764 8.30625 18.2812 8.30033 18.1859 8.3181C18.0907 8.33586 18.0074 8.36548 17.93 8.41286L15.7398 9.67443C15.7359 9.677 15.7318 9.67957 15.7275 9.68221C15.7122 9.6917 15.6956 9.70198 15.6862 9.71589C15.5136 9.8462 15.4125 10.0476 15.4125 10.2608C15.4125 10.6399 15.7219 10.9479 16.1028 10.9479C16.1862 10.9479 16.2695 10.936 16.3469 10.9064C16.3707 10.9005 16.3945 10.8886 16.4183 10.8768L18.6323 9.63297C18.7156 9.58559 18.793 9.52636 18.8466 9.45529C18.9061 9.37829 18.9477 9.29537 18.9716 9.2006C18.9954 9.10584 19.0013 9.01699 18.9894 8.92223C18.9835 8.82154 18.9537 8.7327 18.912 8.66755Z" />
    <path d="M9.68086 5.1316C10.0622 5.1316 10.3713 4.82399 10.3713 4.44454C10.3713 4.0651 10.0622 3.75749 9.68086 3.75749C9.29957 3.75749 8.99047 4.0651 8.99047 4.44454C8.99047 4.82399 9.29957 5.1316 9.68086 5.1316Z" />
    <path d="M7.84208 5.87195C7.84208 6.2514 7.53298 6.559 7.15168 6.559C6.77039 6.559 6.46129 6.2514 6.46129 5.87195C6.46129 5.4925 6.77039 5.1849 7.15168 5.1849C7.53298 5.1849 7.84208 5.4925 7.84208 5.87195Z" />
    <path d="M5.2709 8.76232C5.6522 8.76232 5.9613 8.45472 5.9613 8.07527C5.9613 7.69582 5.6522 7.38821 5.2709 7.38821C4.88961 7.38821 4.58051 7.69582 4.58051 8.07527C4.58051 8.45472 4.88961 8.76232 5.2709 8.76232Z" />
    <path d="M3.29485 10.474C3.29485 10.8535 2.98575 11.1611 2.60446 11.1611C2.22316 11.1611 1.91406 10.8535 1.91406 10.474C1.91406 10.0946 2.22316 9.78698 2.60446 9.78698C2.98575 9.78698 3.29485 10.0946 3.29485 10.474Z" />
    <path d="M4.25887 10.1009C3.87796 10.1009 3.56847 10.4089 3.56847 10.7879C3.56847 11.167 3.87796 11.475 4.25887 11.475C4.63978 11.475 4.94926 11.167 4.94926 10.7879C4.94926 10.4089 4.63978 10.1009 4.25887 10.1009Z" />
    <path d="M3.545 13.6724C3.545 13.2933 3.85448 12.9853 4.23539 12.9853C4.6163 12.9853 4.92578 13.2933 4.92578 13.6724C4.92578 14.0514 4.6163 14.3594 4.23539 14.3594C3.85448 14.3594 3.545 14.0514 3.545 13.6724Z" />
    <path d="M5.20528 15.7098C4.82437 15.7098 4.51489 16.0178 4.51489 16.3969C4.51489 16.776 4.82437 17.084 5.20528 17.084C5.58619 17.084 5.89567 16.776 5.89567 16.3969C5.89567 16.0178 5.58619 15.7098 5.20528 15.7098Z" />
    <path d="M7.74695 18.6239C7.74695 19.0033 7.43785 19.311 7.05656 19.311C6.67527 19.311 6.36617 19.0033 6.36617 18.6239C6.36617 18.2444 6.67527 17.9368 7.05656 17.9368C7.43785 17.9368 7.74695 18.2444 7.74695 18.6239Z" />
    <path d="M9.56226 20.7739C9.94355 20.7739 10.2527 20.4663 10.2527 20.0869C10.2527 19.7074 9.94355 19.3998 9.56226 19.3998C9.18097 19.3998 8.87187 19.7074 8.87187 20.0869C8.87187 20.4663 9.18097 20.7739 9.56226 20.7739Z" />
    <path d="M11.7286 20.6081C11.7286 20.229 12.038 19.921 12.419 19.921C12.7999 19.921 13.1093 20.229 13.1093 20.6081C13.1093 20.9871 12.7999 21.2951 12.419 21.2951C12.038 21.2951 11.7286 20.9871 11.7286 20.6081Z" />
    <path d="M15.2877 20.8154C15.669 20.8154 15.9781 20.5078 15.9781 20.1283C15.9781 19.7489 15.669 19.4413 15.2877 19.4413C14.9064 19.4413 14.5973 19.7489 14.5973 20.1283C14.5973 20.5078 14.9064 20.8154 15.2877 20.8154Z" />
    <path d="M18.4952 18.7009C18.4952 19.0803 18.1861 19.388 17.8048 19.388C17.4235 19.388 17.1144 19.0803 17.1144 18.7009C17.1144 18.3214 17.4235 18.0138 17.8048 18.0138C18.1861 18.0138 18.4952 18.3214 18.4952 18.7009Z" />
    <path d="M21.1498 16.6753C20.7689 16.6753 20.4594 16.9833 20.4594 17.3623C20.4594 17.7414 20.7689 18.0494 21.1498 18.0494C21.5307 18.0494 21.8402 17.7414 21.8402 17.3623C21.8402 16.9833 21.5247 16.6753 21.1498 16.6753Z" />
    <path d="M21.3941 13.7908C21.3941 14.1703 21.085 14.4779 20.7037 14.4779C20.3224 14.4779 20.0133 14.1703 20.0133 13.7908C20.0133 13.4114 20.3224 13.1038 20.7037 13.1038C21.085 13.1038 21.3941 13.4114 21.3941 13.7908Z" />
    <path d="M20.7271 11.5875C21.1084 11.5875 21.4175 11.2799 21.4175 10.9005C21.4175 10.521 21.1084 10.2134 20.7271 10.2134C20.3458 10.2134 20.0367 10.521 20.0367 10.9005C20.0367 11.2799 20.3458 11.5875 20.7271 11.5875Z" />
    <path d="M23.084 10.628C23.084 11.0075 22.7749 11.3151 22.3936 11.3151C22.0123 11.3151 21.7032 11.0075 21.7032 10.628C21.7032 10.2486 22.0123 9.94097 22.3936 9.94097C22.7749 9.94097 23.084 10.2486 23.084 10.628Z" />
    <path d="M19.7512 8.86893C20.1325 8.86893 20.4416 8.56132 20.4416 8.18187C20.4416 7.80243 20.1325 7.49482 19.7512 7.49482C19.3699 7.49482 19.0608 7.80243 19.0608 8.18187C19.0608 8.56132 19.3699 8.86893 19.7512 8.86893Z" />
    <path d="M18.5964 5.94895C18.5964 6.3284 18.2873 6.636 17.906 6.636C17.5247 6.636 17.2156 6.3284 17.2156 5.94895C17.2156 5.5695 17.5247 5.2619 17.906 5.2619C18.2873 5.2619 18.5964 5.5695 18.5964 5.94895Z" />
    <path d="M19.0011 3.9944C18.6202 3.9944 18.3107 4.30239 18.3107 4.68145C18.3107 5.06052 18.6202 5.36851 19.0011 5.36851C19.382 5.36851 19.6915 5.06052 19.6915 4.68145C19.6915 4.30239 19.382 3.9944 19.0011 3.9944Z" />
    <path d="M16.0852 4.49192C16.0852 4.87137 15.7761 5.17898 15.3948 5.17898C15.0136 5.17898 14.7045 4.87137 14.7045 4.49192C14.7045 4.11247 15.0136 3.80487 15.3948 3.80487C15.7761 3.80487 16.0852 4.11247 16.0852 4.49192Z" />
    <path d="M19.6916 15.8165C19.3107 15.8165 19.0012 16.1245 19.0012 16.5035C19.0012 16.8826 19.3107 17.1906 19.6916 17.1906C20.0725 17.1906 20.382 16.8826 20.382 16.5035C20.382 16.1245 20.0725 15.8165 19.6916 15.8165Z" />
    <path d="M16.5374 21.7156C16.5374 22.0951 16.2283 22.4027 15.847 22.4027C15.4657 22.4027 15.1566 22.0951 15.1566 21.7156C15.1566 21.3362 15.4657 21.0286 15.847 21.0286C16.2283 21.0286 16.5374 21.3362 16.5374 21.7156Z" />
    <path d="M8.96684 20.9753C8.58593 20.9753 8.27645 21.2833 8.27645 21.6623C8.27645 22.0414 8.58593 22.3494 8.96684 22.3494C9.34775 22.3494 9.65724 22.0414 9.65724 21.6623C9.65724 21.2833 9.34775 20.9753 8.96684 20.9753Z" />
    <path d="M3.05072 17.2202C3.05072 16.8411 3.36021 16.5331 3.74111 16.5331C4.12202 16.5331 4.43151 16.8411 4.43151 17.2202C4.43151 17.5992 4.12202 17.9072 3.74111 17.9072C3.36021 17.9072 3.05072 17.5992 3.05072 17.2202Z" />
    <path d="M6.08607 5.2619C6.46736 5.2619 6.77646 4.95429 6.77646 4.57485C6.77646 4.1954 6.46736 3.88779 6.08607 3.88779C5.70477 3.88779 5.39567 4.1954 5.39567 4.57485C5.39567 4.95429 5.70477 5.2619 6.08607 5.2619Z" />
  </Icon>
);

export default BhLogo;
