import { ApolloError } from "@apollo/client";
import React from "react";
import { SortingRule } from "react-table";

import { Alert } from "../../../components";
import { PageOptions } from "../../../utils/types";
import { PositionPageListItemFragment } from "../../graphql";
import PositionList from "./PositionList";

interface TeamPositionsProps {
  positions: Array<PositionPageListItemFragment>;
  pageOptions: PageOptions;
  loading?: boolean;
  sortBy: SortingRule<string>;
  error?: ApolloError;
}

const TeamPositions: React.FC<TeamPositionsProps> = ({
  positions,
  pageOptions,
  loading,
  sortBy,
  error,
}) => {
  if (error)
    return (
      <Alert
        status="error"
        title="Error loading positions"
        description={error.message}
      />
    );

  return (
    <PositionList
      positions={positions}
      pageOptions={pageOptions}
      loading={loading}
      sortBy={sortBy}
    />
  );
};

export default TeamPositions;
