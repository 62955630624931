import {
  Box,
  ButtonGroup,
  Flex,
  Grid,
  GridItem,
  Icon,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { IoArrowRedoSharp, IoCut } from "react-icons/io5";

import { Alert, Button, LoadingIndicator } from "../../../../components";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { CallClipFragment } from "../../../graphql";
import ClipListItem from "../../Clips/beta/ClipListItem";
import { SharePlaylistModal } from "../../SharePlaylistModal";

export type ClipsTabProps = {
  clips: CallClipFragment[];
  callId: string;
  positionId?: string;
  readOnly?: boolean;
  onCreateClipClick?(): void;
  canShare?: boolean;
  loading: boolean;
  error?: string;
};

const ClipsTab: React.FC<ClipsTabProps> = ({
  clips = [],
  callId,
  positionId,
  canShare,
  readOnly = false,
  onCreateClipClick,
  loading,
  error,
}) => {
  const [isShareModeEnabled, setIsShareModeEnabled] = useState(false);

  const [clipIds, setClipIds] = useState<Array<string>>([]);
  const updateClipId = (clipId: string): void => {
    if (!clipIds.includes(clipId)) {
      setClipIds([...clipIds, clipId]);
    } else {
      setClipIds(clipIds.filter((id) => id !== clipId));
    }
  };

  const reset = (): void => {
    setIsShareModeEnabled(false);
    setClipIds([]);
  };

  const noClips = !clips || clips.length === 0;

  return (
    <Grid
      templateRows="auto 1fr"
      templateAreas={`"header" "main"`}
      height="100%"
    >
      <GridItem
        area="header"
        borderBottom="1px solid"
        borderBottomColor="gray.100"
        display={error ? "none" : undefined}
      >
        <ButtonGroup
          spacing={4}
          mt={4}
          mb={2}
          pl={4}
          pr={4}
          display="flex"
          justifyContent="space-between"
        >
          <Flex>
            {canShare && !isShareModeEnabled && (
              <Button
                id="select-share-clips-button"
                size="sm"
                fontWeight="500"
                variant="ghost"
                mr="4"
                onClick={() => {
                  setIsShareModeEnabled(true);
                }}
                disabled={noClips}
                _disabled={{
                  bg: "transparent",
                  color: "gray.400",
                }}
                data-testid="select-playlist-clips"
              >
                Select & share
              </Button>
            )}
            {isShareModeEnabled && (
              <>
                <Button
                  size="sm"
                  variant="ghost"
                  fontWeight="500"
                  onClick={() => {
                    setIsShareModeEnabled(false);
                    setClipIds([]);
                  }}
                >
                  Cancel
                </Button>
                <Flex
                  alignItems="center"
                  fontSize="sm"
                  color="gray.600"
                  fontWeight="400"
                  ml={3}
                >
                  {clipIds.length} selected
                </Flex>
              </>
            )}
          </Flex>
          <Flex>
            {isShareModeEnabled && (
              <SharePlaylistModal
                trigger={
                  <Button
                    size="sm"
                    marginLeft="auto"
                    variant="ghost"
                    fontWeight="500"
                    leftIcon={
                      <IoArrowRedoSharp fill="transparent" strokeWidth="3rem" />
                    }
                    disabled={!clipIds.length}
                    _disabled={{
                      bg: "transparent",
                      color: "gray.400",
                    }}
                    data-testid="create-playlist"
                  >
                    Create playlist &amp; share
                  </Button>
                }
                callId={callId}
                clipIds={clipIds}
                onShare={reset}
              />
            )}
            {!isShareModeEnabled && (
              <Button
                size="sm"
                marginLeft="auto"
                variant="ghost"
                fontWeight="500"
                onClick={() => {
                  onCreateClipClick?.();
                }}
              >
                Create clip
              </Button>
            )}
          </Flex>
        </ButtonGroup>
      </GridItem>

      <GridItem area="main" overflowY="auto">
        {loading ? (
          <LoadingIndicator delay={0} h="auto" mt="24" />
        ) : error ? (
          <Alert
            status="error"
            title="Error loading clips"
            description={error}
          />
        ) : noClips ? (
          <Box
            color="gray.600"
            pb="3"
            mt="12"
            textAlign="center"
            data-testid="empty-clips-list-message"
          >
            <Text fontSize="sm" mb="4">
              No clips have been created in this interview yet.
            </Text>
            <ClipButton onClick={onCreateClipClick} />
          </Box>
        ) : (
          clips.map((clip, i) => (
            <ClipListItem
              key={clip.id}
              callId={callId}
              positionId={positionId}
              clip={clip}
              listLength={clips?.length}
              listPosition={i}
              isLastItem={clips && i === clips.length - 1}
              readOnly={readOnly}
              isShareModeEnabled={isShareModeEnabled}
              updateClipId={updateClipId}
              clipShareOrder={
                clipIds.indexOf(clip.id) !== -1
                  ? clipIds.indexOf(clip.id) + 1
                  : undefined
              }
            />
          ))
        )}
      </GridItem>
    </Grid>
  );
};

const ClipButton: React.FC<{ onClick?(): void }> = ({ onClick }) => {
  const sendGAEvent = useSendGAEvent();
  return (
    <Button
      variant="outline"
      size="sm"
      colorScheme="blue"
      onClick={() => {
        onClick?.();
        sendGAEvent(
          "clip_creation_start",
          "call_review",
          "clip_from_media_controls"
        );
      }}
    >
      <Icon as={IoCut} aria-label="Play" mr="2" />
      <Text>Create clip</Text>
    </Button>
  );
};

export default ClipsTab;
