import { Flex, FlexProps } from "@chakra-ui/react";
import React from "react";

type ToggleFiltersPanelProps = {
  showFilters: boolean;
  filterHeights: { [key: string]: number };
  children: React.ReactNode;
} & FlexProps;

/* Control filter visibility with display CSS so filter queries run on load */
const ToggleFiltersPanel: React.FC<ToggleFiltersPanelProps> = ({
  showFilters,
  filterHeights,
  children,
  ...flexProps
}) => {
  return (
    <Flex
      flexDir="row"
      mt="6"
      overflow={showFilters ? "visible" : "hidden"}
      opacity={showFilters ? 1 : 0}
      height={
        showFilters ? `${Math.max(...Object.values(filterHeights))}px` : "0"
      }
      transition="height 0.25s ease-in-out, opacity 0.5s ease-in-out"
      {...flexProps}
    >
      {children}
    </Flex>
  );
};

export default ToggleFiltersPanel;
