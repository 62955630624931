import { useMemo } from "react";

import {
  dateRange90DaysAgo,
  SelectDateRangeState,
} from "../../../../components/SelectDateRange/SelectDateRange";
import { useSelectDateRangeParams } from "../../../../hooks/useSearchParam";
import { formatDateRange } from "../../../../utils/datetime";

export type MyAnalyticsConfig = {
  dateRange: {
    value: SelectDateRangeState;
    displayValue: string;
    setValue(value: SelectDateRangeState | undefined): void;
  };
  filters: {
    interviewers: string[];
  };
};
/**
 * Manages the state of user-selectable configurations for My Analytics.
 *
 * Only manages the config, but not the query execution.
 */
const useMyAnalyticsConfig = (
  interviewer: {
    email: string;
    fullName: string;
  },
  defaultDateRange = dateRange90DaysAgo
): MyAnalyticsConfig => {
  const [dateRange, setDateRange] = useSelectDateRangeParams(
    "dateRange",
    defaultDateRange()
  );

  const displayDateRangeValue = useMemo(() => {
    if (dateRange) {
      return formatDateRange(dateRange.start, dateRange.end);
    }
    return "";
  }, [dateRange]);

  return {
    dateRange: {
      value: dateRange,
      displayValue: displayDateRangeValue,
      setValue: setDateRange,
    },
    filters: {
      interviewers: [interviewer.email],
    },
  };
};

export default useMyAnalyticsConfig;
