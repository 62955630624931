import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

export const UpgradeRequestImage: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 400 240" fill="none" {...props}>
    <path
      d="M275.127 38.6182H126.634C116.579 38.6182 108.449 46.7482 108.449 56.8032V196.316C108.449 206.373 116.579 214.503 126.634 214.503H275.127C285.182 214.503 293.312 206.373 293.312 196.316V56.8032C293.312 46.7482 285.149 38.6182 275.127 38.6182Z"
      fill="white"
    />
    <path
      d="M128.333 70.9076C132.463 70.9076 135.808 67.5626 135.808 63.4301C135.808 59.3001 132.463 55.9551 128.333 55.9551C124.2 55.9551 120.855 59.3001 120.855 63.4301C120.855 67.5626 124.2 70.9076 128.333 70.9076Z"
      fill="#E9F0F3"
    />
    <path
      d="M150.043 70.9076C154.173 70.9076 157.521 67.5626 157.521 63.4301C157.521 59.3001 154.173 55.9551 150.043 55.9551C145.913 55.9551 142.568 59.3001 142.568 63.4301C142.568 67.5626 145.913 70.9076 150.043 70.9076Z"
      fill="#E9F0F3"
    />
    <path
      d="M171.789 70.9076C175.919 70.9076 179.267 67.5626 179.267 63.4301C179.267 59.3001 175.919 55.9551 171.789 55.9551C167.659 55.9551 164.314 59.3001 164.314 63.4301C164.314 67.5626 167.659 70.9076 171.789 70.9076Z"
      fill="#E9F0F3"
    />
    <path
      d="M155.613 167.588L173.606 156.368H221.446C227.793 156.36 233.878 153.838 238.366 149.35C242.856 144.86 245.376 138.775 245.383 132.428C245.376 126.083 242.856 119.995 238.366 115.508C233.878 111.02 227.793 108.495 221.446 108.49H179.551C173.203 108.495 167.118 111.02 162.631 115.508C158.141 119.995 155.621 126.083 155.613 132.428V167.588Z"
      fill="#2398FB"
    />
    <path
      d="M182.43 136.842C184.87 136.842 186.845 134.865 186.845 132.427C186.845 129.99 184.87 128.015 182.43 128.015C179.993 128.015 178.018 129.99 178.018 132.427C178.018 134.865 179.993 136.842 182.43 136.842Z"
      fill="#0772D0"
    />
    <path
      d="M200.459 136.842C202.899 136.842 204.874 134.865 204.874 132.427C204.874 129.99 202.899 128.015 200.459 128.015C198.022 128.015 196.047 129.99 196.047 132.427C196.047 134.865 198.022 136.842 200.459 136.842Z"
      fill="#0772D0"
    />
    <path
      d="M218.565 136.842C221.003 136.842 222.978 134.865 222.978 132.427C222.978 129.99 221.003 128.015 218.565 128.015C216.125 128.015 214.15 129.99 214.15 132.427C214.15 134.865 216.125 136.842 218.565 136.842Z"
      fill="#0772D0"
    />
    <path
      d="M309.418 214.613L280.77 214.653C276.518 214.646 273.105 211.236 273.143 207.043L273.158 149.923C273.165 145.671 276.578 142.258 280.77 142.296L309.418 142.256C313.67 142.263 317.083 145.676 317.043 149.868L317.03 206.988C317.05 211.148 313.638 214.563 309.418 214.613Z"
      fill="#7FC6FE"
    />
    <path
      d="M306.552 176.401L283.65 176.409C281.225 176.414 279.257 174.429 279.252 172.004L279.242 153.504C279.237 151.079 281.222 149.111 283.647 149.106L306.547 149.099C308.972 149.094 310.94 151.079 310.945 153.504L310.957 172.004C310.96 174.429 308.977 176.396 306.552 176.401Z"
      fill="#DDF5FF"
    />
    <path
      d="M306.527 196.968L283.627 196.976C281.202 196.981 279.232 194.996 279.23 192.571L279.207 187.273C279.205 184.848 281.187 182.881 283.612 182.876L306.515 182.868C308.94 182.863 310.907 184.848 310.912 187.273L310.932 192.571C310.937 194.996 309.012 196.933 306.527 196.968Z"
      fill="#2398FB"
    />
    <path
      d="M82.4867 85.7091L127.857 71.1416L115.604 117.109L109.134 105.694L96.7167 118.784L81.8242 104.662L94.2417 91.5716L82.4867 85.7091Z"
      fill="#AEDEFF"
    />
    <path
      d="M91.8552 178.839L67.9477 186.851L92.8877 192.046L66.8027 219.189L98.7902 212.691L100.33 237.314L113.533 221.729"
      stroke="#7FC6FE"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M286 28.951L286.585 2.66602L303.48 26.141L333.03 10.3385L316.67 38.116L343.138 48.0485L314.127 57.1685L329.492 70.841"
      stroke="#7FC6FE"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M69.5818 158.681C74.2343 158.681 78.0068 162.453 78.0068 167.106C78.0068 171.761 74.2343 175.533 69.5818 175.533C64.9268 175.533 61.1543 171.761 61.1543 167.106C61.1543 162.453 64.9268 158.681 69.5818 158.681Z"
      fill="#DDF5FF"
    />
    <path
      d="M320.932 97.98C325.319 97.98 328.877 94.4225 328.877 90.0325C328.877 85.6425 325.319 82.085 320.932 82.085C316.542 82.085 312.984 85.6425 312.984 90.0325C312.984 94.4225 316.542 97.98 320.932 97.98Z"
      fill="#0263B0"
    />
    <path
      d="M339.503 114.247C342.758 114.247 345.393 111.609 345.393 108.357C345.393 105.104 342.758 102.467 339.503 102.467C336.251 102.467 333.613 105.104 333.613 108.357C333.613 111.609 336.251 114.247 339.503 114.247Z"
      fill="white"
    />
    <path
      d="M146.291 27.413C149.838 27.413 152.716 24.5355 152.716 20.988C152.716 17.438 149.838 14.5605 146.291 14.5605C142.741 14.5605 139.863 17.438 139.863 20.988C139.863 24.5355 142.741 27.413 146.291 27.413Z"
      stroke="#7FC6FE"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
