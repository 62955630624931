import {
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
} from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { isMacOs } from "react-device-detect";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import { RiSearchLine } from "react-icons/ri";

import { IconButton, useToast } from "../../../../components";
import useCurrentBreakpoint from "../../../../hooks/useCurrentBreakpoint";

interface TranscriptSearchBetaProps {
  value: string;
  numMatches: number;
  activeMatchIndex: number;
  onChange: (value: string) => void;
  onPrevious: () => void;
  onNext: () => void;
  onClear: () => void;
}

const TranscriptSearchBeta: React.FC<TranscriptSearchBetaProps> = ({
  value,
  numMatches,
  activeMatchIndex,
  onChange,
  onPrevious,
  onNext,
  onClear,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const hasFocusedInput = useRef(false);
  const hasToastMessageBeenShown = useRef(false);
  const toast = useToast();
  const breakpoint = useCurrentBreakpoint();

  const handleKeyDown = (event: React.KeyboardEvent): void => {
    switch (event.key) {
      case "Enter":
        event.preventDefault();
        if (event.shiftKey) {
          onPrevious();
        } else {
          onNext();
        }
        break;
      case "Esc":
      case "Escape":
        event.preventDefault();
        inputRef.current?.blur();
        onClear();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleSearchKeys = (event: KeyboardEvent): void => {
      const searchKeyPressed = isMacOs ? event.metaKey : event.ctrlKey;
      if (event.shiftKey) return;
      if (searchKeyPressed && event.key === "f") {
        if (!hasToastMessageBeenShown.current) {
          toast({
            title: `To use browser search, press ${
              isMacOs ? "cmd" : "ctrl"
            } + f again. (Limited
                transcript results will be shown.)`,
            status: "info",
            duration: 5000,
            isClosable: true,
          });
          hasToastMessageBeenShown.current = true;
        }
        if (!hasFocusedInput.current) {
          inputRef.current?.focus();
          hasFocusedInput.current = true;
          event.preventDefault();
        } else {
          hasFocusedInput.current = false;
        }
      }
    };
    document.addEventListener("keydown", handleSearchKeys);
    return () => {
      document.removeEventListener("keydown", handleSearchKeys);
    };
  }, []);

  const resultText = numMatches
    ? `${activeMatchIndex} of ${numMatches}`
    : "No results";
  const showResults = breakpoint === "base" || breakpoint === "2xl";
  return (
    <InputGroup
      minW="200px"
      maxW={{ base: "none", md: "315px" }}
      mr={{ base: "2", xl: "4" }}
      data-tour-id="ip2-search"
    >
      <InputLeftElement pointerEvents="none" pl="4" pr="2">
        <Icon boxSize="4" color="gray.500" as={RiSearchLine} />
      </InputLeftElement>
      <Input
        ref={inputRef}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        borderColor={value.length ? "blue.600" : "gray.200"}
        borderRightWidth={value.length ? "0" : undefined}
        borderTopRightRadius={value.length ? "0" : undefined}
        borderBottomRightRadius={value.length ? "0" : undefined}
        color="gray.900"
        placeholder="Search Interview"
        fontSize="sm"
        _hover={{ borderColor: "gray.300" }}
        _focus={{ borderColor: "blue.600" }}
        onKeyDown={handleKeyDown}
      />
      {value.length && (
        <>
          {showResults && (
            <InputRightAddon
              borderColor="blue.600"
              backgroundColor="transparent"
              borderLeftWidth="0"
              borderRightWidth="0"
              borderRadius="0"
              color="gray.900"
              fontSize="sm"
            >
              {resultText}
            </InputRightAddon>
          )}
          <InputRightAddon
            backgroundColor="gray.50"
            py="3"
            px="2"
            borderColor="blue.600"
          >
            <IconButton
              aria-label="Go to previous search result"
              icon={<Icon boxSize="4" color="gray.500" as={IoChevronUp} />}
              variant="ghost"
              size="xs"
              mr="1"
              onClick={onPrevious}
              disabled={numMatches < 2}
            />
            <IconButton
              aria-label="Go to next search result"
              icon={<Icon boxSize="4" color="gray.500" as={IoChevronDown} />}
              variant="ghost"
              size="xs"
              mr="1"
              onClick={onNext}
              disabled={numMatches < 2}
            />
            <IconButton
              aria-label="Reset search box"
              icon={<Icon boxSize="4" color="gray.500" as={MdClose} />}
              variant="ghost"
              size="xs"
              onClick={onClear}
            />
          </InputRightAddon>
        </>
      )}
    </InputGroup>
  );
};

export default TranscriptSearchBeta;
