import { Box, BoxProps, Flex } from "@chakra-ui/react";
import React from "react";

import { useTheme } from "../../components";
import useWindowDimensions from "../../hooks/useWindowDimensions";

interface ViewportPageContentProps extends BoxProps {
  children: React.ReactNode;
}
const ViewportPageContent: React.FC<ViewportPageContentProps> = ({
  children,
  maxW = "1400px",
  padding,
  bg = "white",
  ...props
}) => {
  const theme = useTheme();
  const { pagePadding } = theme.sizes;
  const { layoutHeight } = useWindowDimensions();

  // White bg and min height hack to simulate white background
  // Will be unnecessary once the rest of the pages have white backgrounds
  return (
    <Box bg={bg} {...props}>
      <Flex
        padding={padding || pagePadding}
        minH={layoutHeight}
        h={layoutHeight} // for child 100% height
        maxH={layoutHeight}
        maxW={maxW}
        marginX="auto"
        flexDir="column"
      >
        {children}
      </Flex>
    </Box>
  );
};

export default ViewportPageContent;
