import { ApolloError, MutationTuple } from "@apollo/client";

import {
  AddInterviewStageMutation,
  AddInterviewStageMutationVariables,
  OrgInterviewStagesDocument,
  OrgInterviewStagesQuery,
  useAddInterviewStageMutation,
} from "..";

export default function useAddStage({
  onCompleted,
  onError,
}: {
  onCompleted?: (data: AddInterviewStageMutation) => void;
  onError?: (error?: Error) => void;
}): [
  MutationTuple<
    AddInterviewStageMutation,
    AddInterviewStageMutationVariables
  >[0],
  { loading: boolean; error: ApolloError | undefined }
] {
  const [addInterviewStage, { error, loading }] = useAddInterviewStageMutation({
    update(cache, { data: addInterviewStageData }) {
      const interviewStage =
        addInterviewStageData?.addInterviewStage?.interviewStage;
      if (interviewStage) {
        const cachedQuery = cache.readQuery<OrgInterviewStagesQuery>({
          query: OrgInterviewStagesDocument,
        });
        const cachedUser = cachedQuery?.currentUser;
        const cachedInterviewStages =
          cachedQuery?.currentUser?.organization.interviewStages.results;
        if (cachedInterviewStages && cachedUser) {
          cache.writeQuery<OrgInterviewStagesQuery>({
            query: OrgInterviewStagesDocument,
            data: {
              currentUser: {
                ...cachedUser,
                organization: {
                  ...cachedUser.organization,
                  interviewStages: {
                    results: [interviewStage, ...cachedInterviewStages],
                  },
                },
              },
            },
          });
        }
      }
    },
    onCompleted,
    onError,
  });
  return [addInterviewStage, { loading, error }];
}
