import invariant from "invariant";

const brighthireConfig =
  process.env.NODE_ENV === "test" // NODE_ENV is only set when running jest or storybook
    ? {
        appEnv: "test",
        appVersion: "test",
        urlPrefix: "http://localhost:3001",
        sentryEnv: "",
        algoliaApplicationId: "",
        algoliaIndex: "",
        zoomClientId: "",
        zoomBotClientId: "",
        intercomEnabled: false,
        intercomAppId: "",
        webpackManifestHash: "",
        sentrySessionSampleRate: 0.0,
        sentryErrorSampleRate: 0.0,
      }
    : window.brighthireConfig;
invariant(brighthireConfig, "window.brighthireConfig is not defined");

const parseString = (config: Record<string, any>, name: string): string => {
  const value = config[name];
  invariant(
    typeof value === "string",
    `window.brighthireConfig.${name} is not a string`
  );
  return value;
};

const parseOptionalString = (
  config: Record<string, any>,
  name: string
): string | undefined => {
  const value = config[name];
  invariant(
    typeof value === "string",
    `window.brighthireConfig.${name} is not a string`
  );
  return value === "" ? undefined : value;
};

const parseBoolean = (config: Record<string, any>, name: string): boolean => {
  const value = config[name];
  invariant(
    typeof value === "boolean",
    `window.brighthireConfig.${name} is not a boolean`
  );
  return value;
};

const parseOptionalFloat = (
  config: Record<string, any>,
  name: string,
  defaultValue: number
): number => {
  const value = config[name];
  if (value == null) return defaultValue;
  invariant(
    typeof value === "number",
    `window.brighthireConfig.${name} is not a number`
  );
  return value;
};

const appEnv = parseString(brighthireConfig, "appEnv");
invariant(
  ["production", "staging", "test", "development"].includes(appEnv),
  `invalid appEnv: ${appEnv}`
);

export default {
  appEnv: appEnv as "production" | "staging" | "test" | "development",
  appVersion: parseString(brighthireConfig, "appVersion"),
  urlPrefix: parseString(brighthireConfig, "urlPrefix"),
  sentryEnv: parseOptionalString(brighthireConfig, "sentryEnv"),
  algoliaApplicationId: parseOptionalString(
    brighthireConfig,
    "algoliaApplicationId"
  ),
  algoliaIndex: parseOptionalString(brighthireConfig, "algoliaIndex"),
  zoomClientId: parseOptionalString(brighthireConfig, "zoomClientId"),
  zoomBotClientId: parseOptionalString(brighthireConfig, "zoomBotClientId"),
  intercomEnabled: parseBoolean(brighthireConfig, "intercomEnabled"),
  intercomAppId: parseOptionalString(brighthireConfig, "intercomAppId"),
  webpackManifestHash: parseOptionalString(
    brighthireConfig,
    "webpackManifestHash"
  ),
  sentrySessionSampleRate: parseOptionalFloat(
    brighthireConfig,
    "sentrySessionSampleRate",
    0.0
  ),
  sentryErrorSampleRate: parseOptionalFloat(
    brighthireConfig,
    "sentrySessionSampleRate",
    0.0
  ),
};
