import { Box, ButtonGroup, Flex, Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {} from "react-icons/io";
import { MdList } from "react-icons/md";

import {
  Button,
  ConfirmModal,
  LoadingIndicator,
  RouterLink,
  SortableTable,
  useToast,
} from "../../../../components";
import { EmptyStateWithIcon } from "../../../../components/EmptyStates";
import { formatRelativeDate } from "../../../../utils/datetime";
import {
  Ats,
  RedactionCallListItemFragment,
  useOrganizationRedactionCallsQuery,
  useRedactScheduledCallsMutation,
} from "../../../graphql";

interface DataRetentionInterviewsProps {
  organization: {
    ats?: Ats | null;
    enableRetentionSinceCandidateHired: boolean;
    retentionDaysSinceCandidateHired: number;
    enableRetentionSinceCandidateRejected: boolean;
    retentionDaysSinceCandidateRejected: number;
    enableRetentionSinceLastInterview: boolean;
    retentionDaysSinceLastInterview: number;
    enableRetentionSinceInterview: boolean;
    retentionDaysSinceInterview: number;
    enableRedactionForGreenhouse: boolean;
    enableRedactionForLever: boolean;
    retentionNotificationDays?: (string | null)[] | null;
    organizationRetentionUser: {
      userId: string;
    }[];
    retentionEmailSendTime: string;
    retentionEmailSendTimezone: string;
  };
}

const DataRetentionInterviewsPage: React.FC<DataRetentionInterviewsProps> = ({
  organization,
}) => {
  const toast = useToast();
  const [countValue, setCountValue] = useState(50);
  const [isOpen, setIsOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const { data, loading, error } = useOrganizationRedactionCallsQuery({
    fetchPolicy: "cache-and-network",
    variables: { count: countValue },
  });

  const callsTmp = data?.callsToRedact?.calls || []; // eslint-disable-line
  const calls = callsTmp as RedactionCallListItemFragment[];
  const count = data?.callsToRedact?.count; // eslint-disable-line

  const [redactScheduledCalls, { loading: redactScheduledLoading }] =
    useRedactScheduledCallsMutation({
      onCompleted: (data) => {
        toast({
          title: "Success",
          description:
            "Request is being processed, please reload the page in a few minutes.",
          status: "success",
        });
      },
      onError: (err) => {
        toast({
          title: "Error",
          description: err.message,
          status: "error",
        });
      },
    });

  const handleClick = (
    keep: boolean,
    all: boolean,
    modalText: string
  ): void => {
    setIsOpen(true);
    setModalText(modalText);
  };

  useEffect(() => {
    if (error) {
      toast({
        title: "Error loading interviews",
        description: error.message,
        status: "error",
      });
    }
  }, [error]);

  if (error) {
    return null;
  }

  if (organization.enableRetentionSinceInterview === false) {
    return (
      <EmptyStateWithIcon
        heading="Interviews section disabled"
        icon={MdList}
        Text={() => (
          <Box>
            You have not enabled any interview deletion settings. The list of
            interviews to be deleted will be shown here after enabling and
            setting interview data retention timelines.{" "}
          </Box>
        )}
        padded={false}
      />
    );
  }

  if (!loading && calls.length === 0) {
    return (
      <EmptyStateWithIcon
        heading="No interviews found"
        icon={MdList}
        Text={() => <Box>No interviews were found. </Box>}
        padded={false}
      />
    );
  }

  return (
    <Box my={4}>
      {loading && <LoadingIndicator />}
      {calls && (
        <SortableTable
          width="100%"
          disableSort
          checkboxColumnWidth="10px"
          disableCheckboxes
          renderSelectedRowActions={(rows) => {
            return (
              <Flex alignItems="center" justifyContent="flex-end" w="100%">
                <ButtonGroup my="2">
                  <Select
                    size="sm"
                    name="showX"
                    m="1"
                    mt={4}
                    width="200px"
                    defaultValue={countValue}
                    onChange={(option) => {
                      setCountValue(parseInt(option.target.value));
                    }}
                  >
                    <option key="10" value="10">
                      Show 10 interviews
                    </option>
                    <option key="50" value="50">
                      Show 50 interviews
                    </option>
                    <option key="100" value="100">
                      Show 100 interviews
                    </option>
                  </Select>
                  <ConfirmModal
                    modalBodyText={modalText}
                    isOpen={isOpen}
                    onCancel={() => {
                      setIsOpen(false);
                    }}
                    onConfirm={() => {
                      redactScheduledCalls({});
                      setIsOpen(false);
                    }}
                  />
                  <Button
                    mt={4}
                    size="sm"
                    type="submit"
                    variant="danger"
                    isLoading={loading || redactScheduledLoading}
                    onClick={() =>
                      handleClick(
                        false,
                        true,
                        ` Are you sure you want to delete ${
                          count || 0
                        } interviews?`
                      )
                    }
                  >
                    Delete all {count || 0} interviews
                  </Button>
                </ButtonGroup>
              </Flex>
            );
          }}
          columns={[
            {
              Header: "Interview",
              width: "500px",
              Cell: ({
                row: { original: call },
              }: {
                row: { original: RedactionCallListItemFragment };
              }) => (
                <Box>
                  <RouterLink to={`/interview/${call.id}`}>
                    {call.name}
                  </RouterLink>
                </Box>
              ),
            },
            {
              Header: "Interview Date",
              Cell: ({
                row: { original: call },
              }: {
                row: { original: RedactionCallListItemFragment };
              }) => <>{formatRelativeDate(call?.createdAt)}</>,
            },
          ]}
          data={calls}
          initialSort={{ id: "id", desc: false }}
        />
      )}
    </Box>
  );
};

export default DataRetentionInterviewsPage;
