import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

export const AshbyIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 250 250" fill="#504ad0" {...props}>
    <g>
      <path d="M207.666 199.458C210.041 205.338 212.588 209.755 215.329 212.7C218.244 215.46 221.066 216.922 223.797 217.118V222.637C207.222 222.026 190.636 221.751 174.05 221.813C153.283 221.813 137.978 222.081 128.134 222.637V217.118C135.064 216.747 139.975 215.923 142.889 214.626C145.804 213.164 147.271 210.672 147.271 207.181C147.271 203.865 146.171 199.458 143.98 193.928L127.859 148.661H72.6506L68.0041 161.09C61.992 176.721 58.9758 188.687 58.9758 196.966C58.9758 204.514 61.0749 209.663 65.263 212.422C69.4612 215.182 75.6566 216.747 83.8493 217.118V222.637C71.9201 222.126 59.982 221.851 48.0421 221.813C39.4826 221.813 32.4618 222.081 27 222.637V217.118C31.5549 216.376 35.8346 213.524 39.8392 208.561C43.854 203.587 47.9504 195.679 52.1486 184.825L112.829 27.2238C117.65 27.5494 122.478 27.7314 127.309 27.7696C130.957 27.7696 135.695 27.5842 141.524 27.2238L207.666 199.448V199.458ZM125.944 143.131L101.08 74.1387L74.8313 143.131H125.944Z" />
    </g>
  </Icon>
);
