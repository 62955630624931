import {
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select as ChakraSelect,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import Select from "react-select";

import { Button, LoadingIndicator, useToast } from "../../../components";
import useSelectTheme from "../../../hooks/useSelectTheme";
import {
  useOrgUsersQuery,
  useSetClientMembershipsMutation,
} from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";

interface AddUserToClientMembershipButtonProps {
  client: {
    id: string;
  };
  positionIds: string[];
  filterUserIds?: string[];
}

const AddUserToClientMembershipButton: React.FC<
  AddUserToClientMembershipButtonProps
> = ({ client, positionIds, filterUserIds }) => {
  const currentUser = useCurrentUser();
  const toast = useToast();
  const [theme, styles] = useSelectTheme({
    container: (provided: Record<string, any>) => {
      return { ...provided, flex: 1 };
    },
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [roleId, setRoleId] = useState<string>();
  const [userId, setUserId] = useState<string>();
  const { data, loading } = useOrgUsersQuery({
    variables: {
      signUpCompleted: false,
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
  });

  const enabled = currentUser.userRole?.canManageHiringTeam;
  const { positionRoles } = currentUser.organization;
  const [setClientMemberships, { loading: setMembershipsLoading }] =
    useSetClientMembershipsMutation({
      onCompleted: (data) => {
        if (data.setClientMemberships) {
          resetOnClose();
          toast({
            title: "Success",
            description: "User added",
            status: "success",
          });
        }
      },
      onError: (err) => {
        toast({
          title: "Error",
          description: err.message,
          status: "error",
        });
      },
      refetchQueries: ["OrgHiringTeamGroup"],
    });

  const onSubmit = (): void => {
    if (!roleId) return;
    if (!userId) return;
    setClientMemberships({
      variables: {
        users: [
          {
            userId,
            clientId: client.id,
            positionRoleId: roleId,
          },
        ],
      },
    });
  };

  useEffect(() => {
    if (positionRoles.length) {
      setRoleId(positionRoles[0].id);
    }
  }, [positionRoles]);

  if (loading) return <LoadingIndicator />;

  const users =
    data?.currentUser?.organization.users.filter(
      (u) => !filterUserIds?.includes(u.id)
    ) || [];

  const resetOnClose = (): void => {
    setUserId("");
    setRoleId("");
    onClose();
  };

  return (
    <>
      <Button
        size="xs"
        variant="outline"
        leftIcon={<MdAddCircleOutline />}
        onClick={onOpen}
        disabled={positionIds.length < 1 || !enabled}
      >
        Add user
      </Button>
      <Modal isOpen={isOpen} onClose={resetOnClose}>
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Add User</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing="8">
                <FormControl>
                  <FormLabel>User</FormLabel>
                  <Select
                    theme={theme}
                    styles={styles}
                    isClearable
                    placeholder="Select a User"
                    isLoading={loading}
                    options={users.map((u) => {
                      if (u.fullName === "") {
                        return {
                          label: `${u.email}`,
                          value: u.id,
                        };
                      }
                      return {
                        label: `${u.fullName} - ${u.email}`,
                        value: u.id,
                      };
                    })}
                    onChange={(option) =>
                      setUserId(
                        (option as { value: string } | undefined)?.value
                      )
                    }
                  />
                </FormControl>
                <FormControl mt="2">
                  <FormLabel>Role</FormLabel>
                  <ChakraSelect
                    value={roleId}
                    isDisabled={!userId}
                    onChange={(e) => setRoleId(e.target.value)}
                  >
                    {positionRoles.map((r) => (
                      <option key={r.id} value={r.id}>
                        {r.formattedName}
                      </option>
                    ))}
                  </ChakraSelect>
                  <FormHelperText>
                    {
                      positionRoles.find((r) => r.id === roleId)
                        ?.formattedPermissions
                    }
                  </FormHelperText>
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button variant="outline" mr={3} onClick={resetOnClose}>
                Cancel
              </Button>
              <Button
                onClick={onSubmit}
                isDisabled={!roleId || !userId}
                isLoading={setMembershipsLoading}
              >
                Submit
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default AddUserToClientMembershipButton;
