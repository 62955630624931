import { BoxProps, ButtonProps, Flex, Portal } from "@chakra-ui/react";
import React from "react";
import { IoCopyOutline, IoCut, IoLinkSharp } from "react-icons/io5";

import { Button } from "../../../../../components";
import { formatDuration } from "../../../../../utils/datetime";
import { useSendGAEvent } from "../../../../../utils/googleAnalytics";
import { useCopyScorecardItemNotes } from "../../../../hooks/useCopyNotes";
import useCopyLink from "../../../CallNotes/useCopyLink";
import { ClipRange } from "../../../Interview/Clip/types";
import { clipDefaultEndTime } from "../../../Interview/Clip/utils";
import { NoteItem } from "./types";

type ScorecardNoteQuestionMenuProps = BoxProps & {
  callId: string;
  callDuration?: number | null;
  item: NoteItem;
  popperRef: any;
  hidden: boolean;
  setClipRange?(clipRange: ClipRange | null, adjustByWordTime: boolean): void;
};

const ScorecardNoteQuestionMenu: React.FC<ScorecardNoteQuestionMenuProps> = ({
  callId,
  item,
  callDuration,
  popperRef,
  hidden,
  setClipRange,
  ...rest
}) => {
  const hoverButtonStyles: ButtonProps = {
    variant: "outline",
    size: "sm",
    height: "8",
    fontSize: "12px",
    colorScheme: "gray",
    borderRadius: "md",
    mr: "1",
    px: "2",
  };
  const validTime = item.markedTime ?? 0;

  const { handleCopyLink } = useCopyLink({ callId, time: validTime });

  const handleCopyNotes = useCopyScorecardItemNotes(item, callId);
  const sendGAEvent = useSendGAEvent();

  return (
    <Portal>
      <Flex alignItems="center" ref={popperRef} hidden={hidden} {...rest}>
        <Button
          {...hoverButtonStyles}
          leftIcon={<IoLinkSharp size="14" />}
          aria-label="Copy Link"
          onClick={handleCopyLink}
        >
          Link to {formatDuration(validTime)}
        </Button>
        {setClipRange && (
          <Button
            {...hoverButtonStyles}
            leftIcon={<IoCut size="14" />}
            aria-label="Create clip"
            onClick={() => {
              if (setClipRange) {
                setClipRange(
                  {
                    start: validTime > 15 ? validTime - 15 : validTime,
                    end: clipDefaultEndTime(callDuration, validTime),
                  },
                  true
                );
                sendGAEvent(
                  "clip_creation_start",
                  "call_review",
                  "clip_from_note"
                );
              }
            }}
          >
            Create clip
          </Button>
        )}
        <Button
          {...hoverButtonStyles}
          leftIcon={<IoCopyOutline size="14" />}
          aria-label="Copy Notes"
          onClick={handleCopyNotes}
        >
          Copy notes
        </Button>
      </Flex>
    </Portal>
  );
};

export default ScorecardNoteQuestionMenu;
