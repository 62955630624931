import {
  MenuDivider,
  MenuItem,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FiEdit2 } from "react-icons/fi";
import { IoArrowRedoOutline, IoTrashBin } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";

import {
  errorToast,
  IconButton,
  successToast,
  useToast,
} from "../../../../../components";
import { useIsSmallScreen } from "../../../../../hooks/useIsSmallScreen";
import { canManageTraining } from "../../../../../utils/permissions";
import {
  ClipBetaFragment,
  TranscriptionStatus,
  useViewClipMutation,
} from "../../../../graphql";
import { usePlaylist } from "../../../../graphql/hooks/usePlaylist";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import useMouseHasMoved from "../../../../hooks/useMouseHasMoved";
import { AddToTrainingProgramIcon } from "../../../AddToTrainingProgramButton/AddToTrainingProgramButton";
import AddToTrainingProgramModal from "../../../AddToTrainingProgramButton/AddToTrainingProgramModal";
import EditClipModal from "../../../Clips/beta/EditClipModal";
import ShareClipButton from "../../../Clips/beta/ShareClipButton";
import useDeleteClip from "../../../Clips/beta/useDeleteClip";
import { PlaylistHeader } from "../../../PlaylistHeader";
import { DetailsHeader } from "../../../Recording";
import { menuItemProps } from "../../beta/useCallMenuOptions";
import useVisitorAnalytics from "../../useVisitorAnalytics";
import ClipPill from "../ClipPill";

type ClipDetailsHeaderProps = {
  clip: Pick<
    ClipBetaFragment,
    | "id"
    | "name"
    | "displayName"
    | "callStartTime"
    | "candidate"
    | "position"
    | "interviewers"
    | "viewers"
    | "externalViewers"
    | "canEdit"
    | "canDelete"
    | "canShare"
    | "canShareExternal"
    | "visibility"
    | "visibleVisibilityLevels"
    | "startTime"
    | "endTime"
    | "interviewerIds"
  >;
  callId: string;
};

const ClipDetailsHeader: React.FC<ClipDetailsHeaderProps> = ({
  clip,
  callId,
}) => {
  const isSmallScreen = useIsSmallScreen();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const playlistData = usePlaylist();

  const [viewClip, { called: clipViewLogged }] = useViewClipMutation();
  const mouseHasMoved = useMouseHasMoved();
  useEffect(() => {
    if (clip && mouseHasMoved && !clipViewLogged) {
      viewClip({
        variables: { clipId: clip.id, playlistId: playlistData?.playlist?.id },
      });
    }
  }, [clip, mouseHasMoved, clipViewLogged]);

  useVisitorAnalytics(
    clipViewLogged,
    "app",
    TranscriptionStatus.Completed,
    clip?.interviewerIds,
    callId,
    clip.id
  );

  const setPath = (path: string): void =>
    navigate(
      {
        pathname: `/interview/${callId}/clip/${clip.id}${path}`,
        search: window.location.search,
      },
      { replace: true }
    );

  const shareModal = useDisclosure({
    isOpen: location.pathname.endsWith("/share"),
    onOpen: () => setPath("/share"),
    onClose: () => setPath(""),
  });

  const editTitle = useDisclosure();
  const addToTraining = useDisclosure();

  const { openConfirmationDialog, confirmDeleteClipDialog } = useDeleteClip({
    clipId: clip.id,
    onSuccess: () => {
      successToast(toast, "Clip deleted");
      navigate({ pathname: `/interview/${callId}` }, { replace: true });
    },
    onError: (errorMsg) => errorToast(toast, errorMsg),
  });

  const menuOptions = [
    canManageTraining(currentUser) ? (
      <MenuItem
        {...menuItemProps()}
        icon={<AddToTrainingProgramIcon boxSize="5" />}
        onClick={() => addToTraining.onOpen()}
      >
        Add to Training Program
        {addToTraining.isOpen && (
          <AddToTrainingProgramModal
            callId={callId}
            clipIds={[clip.id]}
            onClose={addToTraining.onClose}
          />
        )}
      </MenuItem>
    ) : null,

    clip.canEdit ? (
      <MenuItem
        {...menuItemProps()}
        icon={<FiEdit2 size="18" />}
        onClick={editTitle.onOpen}
      >
        Edit Title
      </MenuItem>
    ) : null,

    <MenuDivider />,

    <Tooltip
      openDelay={500}
      label={
        clip.canDelete
          ? undefined
          : "Please contact clip creator to request deletion of this clip"
      }
    >
      <MenuItem
        {...menuItemProps({ dangerous: true })}
        icon={<IoTrashBin size="20" />}
        onClick={clip.canDelete ? openConfirmationDialog : undefined}
        disabled={!clip.canDelete}
        cursor={clip.canDelete ? undefined : "not-allowed"}
        opacity={clip.canDelete ? undefined : 0.6}
      >
        Delete Clip
        {confirmDeleteClipDialog}
      </MenuItem>
    </Tooltip>,
  ].filter((o) => o !== null) as JSX.Element[];

  return (
    <>
      {playlistData !== null && (
        <PlaylistHeader {...playlistData} px="pagePaddingMobile" />
      )}
      <DetailsHeader
        candidate={clip.candidate}
        position={clip.position}
        titleSlot={
          <ClipPill
            size={isSmallScreen ? "xs" : "sm"}
            highContrast
            display="flex"
          />
        }
        title={clip.displayName}
        interviewers={clip.interviewers}
        date={clip.callStartTime}
        viewers={clip.viewers}
        canEdit={false}
        onEdit={editTitle.onOpen}
        externalViewers={clip.externalViewers}
        menuOptions={menuOptions}
        share={
          clip.canShare || clip.canShareExternal ? (
            <ShareClipButton
              clip={clip}
              callId={callId}
              positionId={clip.position?.id}
              isModalOpen={shareModal.isOpen}
              onOpenModal={shareModal.onOpen}
              onCloseModal={shareModal.onClose}
            >
              <Tooltip label="Share Clip" openDelay={1000}>
                <IconButton
                  icon={<IoArrowRedoOutline size="20" />}
                  aria-label="Share Clip"
                  data-testid="share-button"
                  color="blue.600"
                  variant="white"
                  minW="8"
                  h="8"
                  ml="2"
                />
              </Tooltip>
            </ShareClipButton>
          ) : undefined
        }
      />

      <EditClipModal clip={clip} {...editTitle} />
    </>
  );
};

export default ClipDetailsHeader;
