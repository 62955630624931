import {
  Box,
  Divider,
  Flex,
  Icon,
  Menu,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { HiOutlineBriefcase, HiOutlineChevronDown } from "react-icons/hi";

import {
  Button,
  IconButton,
  MenuButton,
  NO_VALUE_SELECTED,
  PillSelectOptions,
  RouterLink,
} from "../../../../components";
import { useIsExtension } from "../../../hooks/useAppEnvironmentContext";

type CandidatePositionSelectProps = {
  options: PillSelectOptions;
  onSelect?(option: PillSelectOptions[0]): void;
  positionId?: string;
};

const DisabledPositionIndicator: React.FC<{
  text: string;
}> = ({ text }) => (
  <Flex fontSize="sm" color="gray.600" alignItems="center">
    <HiOutlineBriefcase size={18} />
    <Box as="span" ml={1.5}>
      {text}
    </Box>
  </Flex>
);

const SinglePositionLink: React.FC<{
  option: PillSelectOptions[0];
  disabled?: boolean;
}> = ({ option, disabled }) => {
  const isExtension = useIsExtension();

  if (disabled) {
    return <DisabledPositionIndicator text={option.displayTitle} />;
  }

  const Wrapper = isExtension ? Flex : RouterLink;

  return (
    <Wrapper
      display="flex"
      alignItems="center"
      to={`/position/${option.key}`}
      variant="primary"
      color={isExtension ? "gray.800" : undefined}
    >
      <Icon as={HiOutlineBriefcase} boxSize="18px" flexShrink="0" />
      <Box as="span" ml={1.5}>
        {option.displayTitle}
      </Box>
    </Wrapper>
  );
};

const PositionSelectMenu: React.FC<
  CandidatePositionSelectProps & { button: ReactNode }
> = ({ button, options, onSelect }) => (
  <Menu variant="new" autoSelect={false}>
    {button}
    <MenuList minWidth="220px" maxW="90vw">
      {options.map((option) => (
        <MenuItem key={option.key} onClick={() => onSelect?.(option)}>
          {option.displayTitle} ({option.count})
        </MenuItem>
      ))}
    </MenuList>
  </Menu>
);

const PositionComboMenu: React.FC<
  CandidatePositionSelectProps & { positionLink: ReactNode }
> = ({ positionLink, options, onSelect }) => (
  <Flex alignItems="center" height="24px">
    {positionLink}
    <Divider
      ml={3}
      mr={1}
      opacity="1"
      orientation="vertical"
      color="gray.200"
    />
    <PositionSelectMenu
      options={options}
      onSelect={onSelect}
      button={
        <MenuButton
          as={IconButton}
          variant="icon"
          size="sm"
          icon={<HiOutlineChevronDown size={18} />}
          onClick={(e) => e.stopPropagation()}
          color="blue.600"
          data-testid="candidate-position-select"
        />
      }
    />
  </Flex>
);

const CandidatePositionSelect: React.FC<CandidatePositionSelectProps> = ({
  options,
  onSelect,
  positionId,
}) => {
  // No position options available, meaning all calls with this candidate did not have a position attached.
  if (!options.length) {
    return <DisabledPositionIndicator text="Interviews with no position" />;
  }
  // If there's only one option, assume that option was selected and only show a link to the position page.
  const hasInterviewsWithNoPosition = options.find(
    (option) => option.key === NO_VALUE_SELECTED
  );
  if (options.length === 1) {
    if (hasInterviewsWithNoPosition) {
      return <DisabledPositionIndicator text="Interviews with no position" />;
    }
    return <SinglePositionLink option={options[0]} />;
  }
  // If the user selected a position (or we selected one for them), render the combo menu with position link and dropdown menu.
  if (positionId) {
    const option = options.find((option) => option.key === positionId);
    if (!option) {
      return <DisabledPositionIndicator text="Interviews with no position" />;
    }
    return (
      <PositionComboMenu
        options={options}
        onSelect={onSelect}
        positionLink={<SinglePositionLink option={option} />}
      />
    );
  }
  // If the user selected "interviews with no position", because of react-router-dom's URL parsing, positionId will be none.
  if (hasInterviewsWithNoPosition) {
    return (
      <PositionComboMenu
        options={options}
        onSelect={onSelect}
        positionLink={
          <SinglePositionLink option={hasInterviewsWithNoPosition} />
        }
      />
    );
  }
  return (
    <Box mt={1}>
      <PositionSelectMenu
        options={options}
        onSelect={onSelect}
        button={
          <MenuButton
            as={Button}
            aria-label="Select position"
            iconSpacing="1.5"
            leftIcon={<HiOutlineBriefcase size={16} />}
            rightIcon={<HiOutlineChevronDown size={14} />}
            variant="outline"
            size="sm"
            textTransform="unset"
            fontWeight="500"
            borderRadius="6px"
            data-testid="candidate-position-select"
          >
            Select position
          </MenuButton>
        }
      />
    </Box>
  );
};

export default CandidatePositionSelect;
