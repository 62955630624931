import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import config from "../../../config";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import useCurrentUser from "../../hooks/useCurrentUser";

/*
  This hook logs a google analytics event when a user views a call page

  Google Analytics Structure
  Catgory: "call_review"
  Action: FOR TRACKING BY CALL ID "owner_view" OR "visitor_view"
  + Label: {call_id}
*/

const useVisitorAnalytics = (
  /**
   * Whether the call is eligible to be logged yet based on page requirements
   */
  canLogCallView: boolean,
  application: "app" | "extension" | "highlights",
  transcriptionStatus?: string,
  interviewerIds?: Array<string>,
  callId?: string,
  clipId?: string
): void => {
  const location = useLocation();
  const sendGAEvent = useSendGAEvent();
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (config.appEnv === "test" || !interviewerIds) {
      return;
    }

    if (
      interviewerIds.includes(currentUser.id) &&
      transcriptionStatus === "COMPLETED" &&
      canLogCallView
    ) {
      sendGAEvent(
        "owner_view",
        "call_review",
        currentUser.organization?.name,
        undefined,
        {
          application,
          callId,
          clipId,
        }
      );
    }
    if (!interviewerIds.includes(currentUser.id) && canLogCallView) {
      sendGAEvent(
        "visitor_view",
        "call_review",
        currentUser.organization?.name,
        undefined,
        {
          application,
          callId,
          clipId,
        }
      );
    }
  }, [location.pathname, interviewerIds, currentUser.id, canLogCallView]);
};

export default useVisitorAnalytics;
