import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Input,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { Button, useToast } from "../../../components";
import {
  SenseSetupDocument,
  useSenseSetupQuery,
  useSetupSenseMutation,
} from "../../graphql";

interface FormValues {
  enabled: boolean;
  apiKey: string;
}

const SenseSettings: React.FC = () => {
  const toast = useToast();
  const { data, loading } = useSenseSetupQuery();
  const [setupSense, { loading: setupSenseLoading }] = useSetupSenseMutation({
    update: (cache, { data }) => {
      const settings = data?.setupSense?.setup;
      if (settings) {
        cache.writeQuery({
          query: SenseSetupDocument,
          data: {
            senseSetup: settings,
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data?.setupSense?.setup) {
        toast({
          status: "success",
          title: "Organization Settings",
          description: "Success!",
        });
      }
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: `Failed to setup Sense: ${err.message}`,
        status: "error",
      });
    },
  });
  const { register, handleSubmit, setValue, watch } = useForm<FormValues>({});
  const watchValues = watch();

  const onSubmit = handleSubmit((formValues) => {
    let apiKey;

    if (!formValues.apiKey.includes("xxxx")) {
      apiKey = formValues.apiKey;
    }
    setupSense({
      variables: {
        enabled: formValues.enabled,
        apiKey,
      },
    });
  });

  useEffect(() => {
    setValue("enabled", data?.senseSetup?.enabled || false);
    setValue("apiKey", data?.senseSetup?.apiKey || "");
  }, [data]);

  const isLoading = loading || setupSenseLoading;

  return (
    <Box w="1000px">
      <Heading as="h1" size="md">
        Sense Setup
      </Heading>
      <form onSubmit={onSubmit}>
        <VStack spacing="8" align="start">
          <FormControl id="enabled" display="flex" alignItems="center" mt={2}>
            <FormLabel my="3">Enabled</FormLabel>
            <Checkbox
              {...register("enabled")}
              isChecked={watchValues.enabled}
              onChange={(e) => {
                setValue("enabled", e.target.checked);
              }}
            />
          </FormControl>
          <FormControl id="apiKey" isRequired mt={2}>
            <FormLabel>API Key</FormLabel>
            <Input {...register("apiKey")} width="300px" type="password" />
          </FormControl>
          <Button type="submit" disabled={isLoading}>
            Save
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default SenseSettings;
