import React, { SVGAttributes } from "react";

type IconProps = Pick<
  SVGAttributes<SVGSVGElement>,
  "width" | "height" | "fill"
> & {
  marginRight?: string;
};

const LeverIcon: React.FC<IconProps> = ({
  width,
  height,
  fill,
  marginRight = "0.4rem",
}) => {
  return (
    <svg
      width={width || "18px"}
      height={height || "18px"}
      viewBox="-8 -8 112 112"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ display: "inline", marginRight }}
    >
      <g fill={fill || "#9f9f9f"} fillOpacity="0.7">
        <path d="M95.7,26L90,9.2c-0.3-0.9-0.7-1.5-1.2-2.1L0.7,95.3C1.2,95.8,1.9,96,2.6,96h25c1.4,0,2.7-0.5,3.8-1.5l63.1-63.1C95.9,30,96.4,27.9,95.7,26z" />
      </g>
      <g fill={fill || "#9f9f9f"} fillRule="nonzero">
        <path d="M86.8,6L70,0.3c-1.9-0.7-3.9-0.2-5.3,1.2L1.5,64.6c-1,1-1.5,2.4-1.5,3.8v25c0,0.7,0.2,1.4,0.7,1.9L88.8,7.2C88.3,6.6,87.6,6.3,86.8,6z" />
      </g>
      <g fill={fill || "#9f9f9f"}>
        <path d="M95.1,90.9L77,72.9c-1.2-1.2-3.1-1.2-4.3,0L54.7,90.9c-1.9,1.9-0.6,5.1,2.1,5.1h36.1C95.7,96,97,92.7,95.1,90.9z" />
      </g>
    </svg>
  );
};

export default LeverIcon;
