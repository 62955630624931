import { createIcon, Tooltip, useDisclosure, useTheme } from "@chakra-ui/react";
import React from "react";

import { Button } from "../../../components";
import { canManageTraining } from "../../../utils/permissions";
import useCurrentUser from "../../hooks/useCurrentUser";
import AddToTrainingProgramModal from "./AddToTrainingProgramModal";

interface AddToTrainingProgramButtonProps {
  callId: string;
  clipIds?: Array<string>;
  size: "md" | "sm";
  dataTestId?: string;
  variant?: "light";
  buttonText?: string;
  hideTooltip?: boolean;
}

const AddToTrainingProgramButton: React.FC<AddToTrainingProgramButtonProps> = ({
  callId,
  size,
  dataTestId,
  clipIds,
  variant,
  buttonText = "Add to",
  hideTooltip = false,
}) => {
  const addToTrainingProgramModal = useDisclosure();
  const { colors } = useTheme();
  const currentUser = useCurrentUser();

  if (!canManageTraining(currentUser)) {
    return null;
  }

  return (
    <>
      {size === "md" && (
        <Tooltip label="Add to a training program" isDisabled={hideTooltip}>
          <Button
            data-testid={dataTestId || "add-to-training-program-button"}
            onClick={addToTrainingProgramModal.onOpen}
            cursor="pointer"
            color="blue.600"
            fontWeight="500"
            px="3"
            py="1.5"
            alignItems="center"
            variant="chalk"
            size="sm"
            onFocus={(e) => e.preventDefault()}
            _hover={{ bg: variant === "light" ? "gray.50" : "gray.100" }}
            textTransform="none"
          >
            <AddToTrainingProgramIcon
              mr="2"
              color={colors.blue[600]}
              w="18px"
              h="18px"
            />
            {buttonText}
          </Button>
        </Tooltip>
      )}
      {size === "sm" && (
        <Tooltip
          label="Add to a training program"
          placement="bottom-start"
          isDisabled={hideTooltip}
        >
          <Button
            data-testid={dataTestId} // small buttons occur in lists and need external position
            onClick={addToTrainingProgramModal.onOpen}
            onFocus={(e) => e.preventDefault()}
            cursor="pointer"
            variant="chalk"
            size="xs"
            p={0}
            _hover={{ bg: "gray.50" }}
          >
            <AddToTrainingProgramIcon
              color={colors.gray[500]}
              w="18px"
              h="18px"
            />
          </Button>
        </Tooltip>
      )}
      {addToTrainingProgramModal.isOpen && (
        <AddToTrainingProgramModal
          onClose={addToTrainingProgramModal.onClose}
          callId={callId}
          clipIds={clipIds}
        />
      )}
    </>
  );
};

export const AddToTrainingProgramIcon = createIcon({
  displayName: "AddToTrainingProgramIcon",
  viewBox: "0 0 18 18",

  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.625 3.3125C5.21079 3.3125 4.875 3.64829 4.875 4.0625C4.875 4.47671 5.21079 4.8125 5.625 4.8125H15.75C16.1642 4.8125 16.5 4.47671 16.5 4.0625C16.5 3.64829 16.1642 3.3125 15.75 3.3125H5.625ZM5.625 7.25C5.21079 7.25 4.875 7.58579 4.875 8C4.875 8.41421 5.21079 8.75 5.625 8.75H12.093C12.9542 8.27213 13.9453 8 15 8H16.5C16.5 7.58579 16.1642 7.25 15.75 7.25H5.625ZM9.69866 11.1875C9.45052 11.6543 9.26222 12.1577 9.14399 12.6875H5.625C5.21079 12.6875 4.875 12.3517 4.875 11.9375C4.875 11.5233 5.21079 11.1875 5.625 11.1875H9.69866Z"
      fill="currentColor"
    />,
    <path
      d="M2.8125 4.625C3.12316 4.625 3.375 4.37316 3.375 4.0625C3.375 3.75184 3.12316 3.5 2.8125 3.5C2.50184 3.5 2.25 3.75184 2.25 4.0625C2.25 4.37316 2.50184 4.625 2.8125 4.625Z"
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M2.8125 8.5625C3.12316 8.5625 3.375 8.31066 3.375 8C3.375 7.68934 3.12316 7.4375 2.8125 7.4375C2.50184 7.4375 2.25 7.68934 2.25 8C2.25 8.31066 2.50184 8.5625 2.8125 8.5625Z"
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M2.8125 12.5C3.12316 12.5 3.375 12.2482 3.375 11.9375C3.375 11.6268 3.12316 11.375 2.8125 11.375C2.50184 11.375 2.25 11.6268 2.25 11.9375C2.25 12.2482 2.50184 12.5 2.8125 12.5Z"
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M17.1 13.4002H11.7M14.4 10.7002V16.1002V10.7002Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});

export default AddToTrainingProgramButton;
