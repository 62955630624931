import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

const GeneralErrorIcon = (props: BoxProps): JSX.Element => (
  <Box {...props}>
    <svg
      width="190"
      height="167"
      viewBox="0 0 190 167"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M162.955 49.1359C166.689 50.1365 168.905 53.9748 167.904 57.7091L141.767 155.257C140.654 159.41 136.365 161.868 132.186 160.749L8.46862 127.599C4.28981 126.479 1.80484 122.205 2.91768 118.052L29.0555 20.5047C30.056 16.7704 33.8944 14.5543 37.6287 15.5549L162.955 49.1359Z"
        fill="#FFF8DB"
      />
      <path
        d="M38.4486 15.7108L162.166 48.8608C166.345 49.9805 168.823 54.2795 167.702 58.4639L166.07 64.5544L27.2209 27.3499L28.8529 21.2595C29.9741 17.0751 34.2697 14.5911 38.4486 15.7108Z"
        fill="#FFEAAF"
      />
      <path
        d="M39.0404 25.9071C40.3435 26.2562 41.6831 25.48 42.0322 24.177C42.3819 22.8722 41.6093 21.532 40.3062 21.1829C39.005 20.8342 37.664 21.608 37.3143 22.9129C36.9652 24.2159 37.7391 25.5584 39.0404 25.9071Z"
        fill="#FFFCEE"
      />
      <path
        d="M46.7083 27.9608C48.0096 28.3094 49.3511 27.5337 49.7002 26.2307C50.0498 24.9259 49.2754 23.5852 47.9742 23.2366C46.6711 22.8874 45.3319 23.6618 44.9823 24.9666C44.6332 26.2696 45.4052 27.6116 46.7083 27.9608Z"
        fill="#FFFCEE"
      />
      <path
        d="M54.3744 30.0155C55.6774 30.3646 57.0171 29.5884 57.3662 28.2854C57.7159 26.9806 56.9433 25.6404 55.6402 25.2913C54.3371 24.9421 52.998 25.7164 52.6483 27.0213C52.2992 28.3243 53.0713 29.6663 54.3744 30.0155Z"
        fill="#FFFCEE"
      />
      <path
        d="M81.0215 34.5674L115.885 43.9091L81.0215 34.5674Z"
        fill="#F5F6F7"
      />
      <path
        d="M81.0215 34.5674L115.885 43.9091"
        stroke="#FFFCEE"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="49.5508"
        y="59.9248"
        width="36"
        height="24"
        rx="4"
        transform="rotate(15 49.5508 59.9248)"
        fill="#FFDC7F"
      />
      <rect
        x="41.2695"
        y="90.834"
        width="36"
        height="4"
        rx="2"
        transform="rotate(15 41.2695 90.834)"
        fill="#FFEAAF"
      />
      <rect
        x="39.1973"
        y="98.5615"
        width="36"
        height="4"
        rx="2"
        transform="rotate(15 39.1973 98.5615)"
        fill="#FFEAAF"
      />
      <rect
        x="37.127"
        y="106.289"
        width="36"
        height="4"
        rx="2"
        transform="rotate(15 37.127 106.289)"
        fill="#FFEAAF"
      />
      <rect
        x="95.916"
        y="72.3477"
        width="36"
        height="24"
        rx="4"
        transform="rotate(15 95.916 72.3477)"
        fill="#FFDC7F"
      />
      <rect
        x="87.6348"
        y="103.257"
        width="36"
        height="4"
        rx="2"
        transform="rotate(15 87.6348 103.257)"
        fill="#FFEAAF"
      />
      <rect
        x="85.5625"
        y="110.984"
        width="36"
        height="4"
        rx="2"
        transform="rotate(15 85.5625 110.984)"
        fill="#FFEAAF"
      />
      <rect
        x="83.4922"
        y="118.712"
        width="36"
        height="4"
        rx="2"
        transform="rotate(15 83.4922 118.712)"
        fill="#FFEAAF"
      />
      <g style={{ mixBlendMode: "multiply", opacity: 0.8 }}>
        <path
          d="M153.473 79.6189C164.161 84.0231 176.398 78.9167 180.804 68.213C185.21 57.5092 180.118 45.2618 169.429 40.8577C158.741 36.4535 146.504 41.5599 142.098 52.2637C137.692 62.9675 142.784 75.2148 153.473 79.6189Z"
          fill="#FFDC7F"
        />
      </g>
      <path
        d="M155.473 75.6209C166.161 80.025 178.398 74.9187 182.804 64.2149C187.21 53.5111 182.118 41.2638 171.429 36.8596C160.741 32.4555 148.504 37.5618 144.098 48.2656C139.692 58.9694 144.784 71.2168 155.473 75.6209Z"
        fill="#FFEAAF"
      />
      <path
        d="M167.437 45.5318L167.407 45.5177C165.325 44.7206 160.86 44.4819 158.873 49.312C158.497 50.2228 158.928 51.2619 159.84 51.6378C160.749 52.0132 161.788 51.5793 162.163 50.6685C163.247 48.0362 165.447 48.6016 166.112 48.8397C168.208 49.7166 169.047 51.3818 168.293 53.2165C167.736 54.5637 166.978 54.8214 165.12 55.1595C163.46 55.4569 160.944 55.924 159.842 58.8626L159.646 59.3408C159.271 60.2516 159.702 61.2908 160.613 61.6666C161.514 62.0377 162.556 61.6188 162.954 60.7158L163.193 60.1355C163.559 59.1581 164.117 58.9766 165.766 58.6743L165.792 58.6733C167.559 58.3468 170.238 57.8376 171.583 54.573C173.101 50.8841 171.4 47.1644 167.437 45.5318Z"
        fill="#FFFCEE"
      />
      <path
        d="M159.982 63.4907C158.978 63.0782 157.823 63.5588 157.408 64.5648C156.996 65.5695 157.477 66.7258 158.48 67.1401C159.498 67.5468 160.649 67.075 161.053 66.066C161.466 65.0595 160.986 63.9055 159.982 63.4907Z"
        fill="#FFFCEE"
      />
    </svg>
  </Box>
);

export default GeneralErrorIcon;
