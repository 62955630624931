import { Box, BoxProps } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { Button } from "../../../components";
import { LoadingIndicator } from "../../../components/LoadingIndicator";
import {
  CandidateAlertFeedFilter,
  CandidateAlertFeedListItemFragment,
} from "../../graphql";
import { CandidateAlert } from "./CandidateAlert";
import { NoCandidateAlerts } from "./NoCandidateAlerts";
import { NoCandidateAlertsForFilters } from "./NoCandidateAlertsForFilters";

const noAlertHelperTextMap = {
  [CandidateAlertFeedFilter.All]:
    "Candidate Alerts notify you of potential closing risks and action items related to your candidates",
  [CandidateAlertFeedFilter.Unread]:
    "There are no unread alerts for your candidates.",
  [CandidateAlertFeedFilter.Read]:
    "You have not read any alerts for your candidates.",
  [CandidateAlertFeedFilter.MyInterviews]:
    "There are no alerts for your interviews",
  [CandidateAlertFeedFilter.ClosingRisk]:
    "There are no alerts for closing risks",
  [CandidateAlertFeedFilter.ActionItem]: "There are no alerts for action items",
};

export const CandidateAlertList: React.FC<{
  alerts: CandidateAlertFeedListItemFragment[];
  alertFilters: CandidateAlertFeedFilter[];
  loading: boolean;
  showEmptyState: boolean | undefined;
  showLoadMore: boolean;
  onLoadMore: () => void;
  handleAlertRead: (ids: string[]) => void;
  itemStyles?: BoxProps;
}> = ({
  alerts,
  alertFilters,
  loading,
  showEmptyState,
  showLoadMore,
  onLoadMore,
  handleAlertRead,
  itemStyles,
}) => {
  const [noAlertHelperText, setNoAlertHelperText] = useState(
    noAlertHelperTextMap[alertFilters[0]]
  );

  useEffect(() => {
    if (alertFilters.length === 1) {
      setNoAlertHelperText(noAlertHelperTextMap[alertFilters[0]]);
    } else {
      setNoAlertHelperText("No alerts match the selected filters");
    }
  }, [alertFilters]);

  if (loading) {
    return <LoadingIndicator delay={0} pt="6" pb="4" />;
  }

  if (showEmptyState) {
    return <NoCandidateAlerts />;
  }

  if (alerts.length === 0) {
    return <NoCandidateAlertsForFilters helperText={noAlertHelperText} />;
  }

  return (
    <Box>
      {alerts.map((alertItem) => (
        <CandidateAlert
          key={alertItem.id}
          handleAlertRead={handleAlertRead}
          styles={itemStyles}
          {...alertItem}
        />
      ))}
      {showLoadMore && (
        <Button
          variant="ghost"
          colorScheme="gray"
          size="sm"
          fontWeight="medium"
          marginTop="2"
          marginLeft="2"
          onClick={onLoadMore}
        >
          Load more
        </Button>
      )}
    </Box>
  );
};
