import { Box, Flex, Menu, MenuList, Text, useTheme } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { TiDelete } from "react-icons/ti";
import Select from "react-select";

import { Avatar, Button, MenuButton, SearchInput } from "../../../components";
import {
  TableNewer,
  TableNewerColumn,
  TableNewerRow,
} from "../../../components/TableNew/TableNewer";
import useSelectTheme from "../../../hooks/useSelectTheme";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { formatDate } from "../../../utils/datetime";
import { buildAllTraineeNestedRecordingRows } from "./AllTraineesNestedRow";
import { GreenCheckmarkIcon } from "./icons";
import { TrainingMenuItem } from "./TrainingMenuItem";
import { TraineeStatus } from "./types";
import { TrainingApi } from "./useTrainingApi";

type AllTraineesManagementProps = {
  trainees: TraineeStatus[];
  trainingApi: TrainingApi;
};

type TraineeCompletionState = "All Trainees" | "In Progress" | "Completed";

type AllTraineesRowData = {
  traineeId: string;
  firstRowForUser: boolean;
  programName: string;
  user: {
    profilePicUrl?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    fullName: string | null;
  };
  createdAt?: string | null;
  markedCompletedAt?: string | null;
  numItemsCompleted: number;
  numItemsTotal: number;
};

type AllTraineesRow = {
  key: string;
  data: AllTraineesRowData;
  children?: React.ReactNode[];
};

const AllTraineesManagement: React.FC<AllTraineesManagementProps> = ({
  trainees,
  trainingApi,
}) => {
  const [query, setQuery] = useState("");
  const [stateFilter, setStateFilter] =
    useState<TraineeCompletionState>("In Progress");
  const { colors } = useTheme();
  const { percentHeight } = useWindowDimensions();
  const [openRowIDs, setOpenRowIDs] = useState<string[]>([]);

  const customSelectStyle = useMemo(() => {
    return {
      control: (provided: Record<string, any>) => ({
        ...provided,
        fontSize: "14px",
        borderColor: colors.gray["200"],
      }),
      option: (provided: Record<string, any>) => ({
        ...provided,
        fontSize: "14px",
      }),
      singleValue: (provided: Record<string, any>) => ({
        ...provided,
        marginLeft: "8px",
        color: colors.gray["900"],
      }),
      dropdownIndicator: (provided: Record<string, any>) => ({
        ...provided,
        color: colors.gray["500"],
      }),
    };
  }, [colors]);

  const [selectTheme, selectStyles] = useSelectTheme(customSelectStyle);

  const toggleRow = (row: TableNewerRow): void => {
    const rowData = row.data as AllTraineesRowData;
    if (openRowIDs.includes(rowData.traineeId)) {
      setOpenRowIDs(openRowIDs.filter((id) => id !== rowData.traineeId));
    } else {
      setOpenRowIDs([...openRowIDs, rowData.traineeId]);
    }
  };

  const columns: Array<TableNewerColumn> = [
    {
      header: "Name",
      id: "traineeName",
      cell: (row: TableNewerRow) => {
        const rowData = row.data as AllTraineesRowData;
        if (!rowData.firstRowForUser) {
          return "";
        }
        return (
          <Flex direction="row" alignItems="center">
            <Avatar
              name={rowData.user?.fullName || ""}
              user={rowData.user}
              borderRadius="100%"
              mr="3"
            />
            <Text color="gray.900" fontWeight="500">
              {rowData.user?.fullName}
            </Text>
          </Flex>
        );
      },
    },
    {
      header: "Program",
      id: "programName",
      cell: (row: TableNewerRow) => {
        const rowData = row.data as AllTraineesRowData;
        return (
          <Text color="gray.800" fontWeight="500">
            {rowData.programName}
          </Text>
        );
      },
    },
    {
      header: "Date Added",
      id: "traineeDateAdded",
      cell: (row: TableNewerRow) => {
        const rowData = row.data as AllTraineesRowData;
        return (
          <Text color="gray.800" fontWeight="500" whiteSpace="nowrap">
            {formatDate(rowData.createdAt, {
              dateStyle: "medium",
            })}
          </Text>
        );
      },
    },
    {
      header: "Completed",
      id: "traineeDateCompleted",
      cell: (row: TableNewerRow) => {
        const rowData = row.data as AllTraineesRowData;
        return (
          <Text color="gray.800" fontWeight="500" whiteSpace="nowrap">
            {formatDate(rowData.markedCompletedAt, {
              dateStyle: "medium",
            })}
          </Text>
        );
      },
    },
    {
      header: "Progress",
      id: "traineeAssessment",
      cell: (row: TableNewerRow) => {
        const rowData = row.data as AllTraineesRowData;
        const numCompleted = rowData.numItemsCompleted;
        const numGoal = rowData.numItemsTotal;
        return (
          <Flex
            direction="row"
            alignItems="center"
            fontWeight="500"
            color="gray.800"
          >
            {numCompleted}/{numGoal}
            {numCompleted === numGoal && (
              <Box as="span" ml="4">
                <GreenCheckmarkIcon />
              </Box>
            )}
          </Flex>
        );
      },
    },
    {
      header: "State",
      accessor: "markedCompletedAt",
      cell: (row: TableNewerRow, idx: number) => {
        const rowData = row.data as AllTraineesRowData;
        const completionButtonConfig = rowData.markedCompletedAt
          ? {
              label: "Mark incomplete",
              onClick: trainingApi.trainee.markIncomplete,
              backgroundColor: "gray.50",
              color: "gray.600",
              hoverBackgroundColor: "gray.100",
            }
          : {
              label: "Mark complete",
              onClick: trainingApi.trainee.markComplete,
              backgroundColor: "green.50",
              color: "green.600",
              hoverBackgroundColor: "green.100",
            };
        const completionButton = (
          <Button
            fontSize="sm"
            onClick={(e) => {
              e.stopPropagation();
              completionButtonConfig.onClick(rowData.traineeId);
            }}
            data-testid={`trainee-row-mark-complete-${idx}`}
            variant="ghost"
            backgroundColor={completionButtonConfig.backgroundColor}
            color={completionButtonConfig.color}
            px="2"
            py="1"
            height="auto"
            fontWeight="500"
            mr="4"
            _hover={{
              backgroundColor: completionButtonConfig.hoverBackgroundColor,
            }}
            _active={{
              backgroundColor: completionButtonConfig.hoverBackgroundColor,
            }}
          >
            {completionButtonConfig.label}
          </Button>
        );
        return (
          <Flex flexDir="row" justifyContent="space-between">
            {completionButton}
            <Flex flexDir="row">
              <Menu>
                <MenuButton
                  data-testid={`trainee-row-menu-${idx}`}
                  mr="8"
                  p="0"
                  height="auto"
                  minW="auto"
                  color="gray.500"
                  _hover={{
                    bg: "gray.100",
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <BsThreeDotsVertical size="16px" />
                </MenuButton>
                <MenuList>
                  <TrainingMenuItem
                    icon={<TiDelete size="24px" color={colors.gray["600"]} />}
                    data-testid={`trainee-row-menu-delete-${idx}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      trainingApi.trainee.delete(rowData.traineeId, {
                        toast: true,
                      });
                    }}
                  >
                    Remove Trainee
                  </TrainingMenuItem>
                </MenuList>
              </Menu>
              <Button
                mr="2"
                p="0"
                height="auto"
                minW="auto"
                variant="ghost"
                color="gray.500"
                _hover={{
                  bg: "gray.50",
                }}
                onClick={() => toggleRow(row)}
              >
                {row.children ? <IoChevronUp /> : <IoChevronDown />}
              </Button>
            </Flex>
          </Flex>
        );
      },
    },
  ];

  const tableData: AllTraineesRow[] = useMemo(() => {
    if (trainees.length === 0) {
      return [];
    }

    let traineesInState = trainees;
    if (stateFilter === "In Progress") {
      traineesInState = trainees.filter((t) => !t.markedCompletedAt);
    } else if (stateFilter === "Completed") {
      traineesInState = trainees.filter((t) => t.markedCompletedAt);
    }
    const lowerQuery = query.toLowerCase();
    const filteredTrainees =
      query.length > 0
        ? traineesInState.filter((trainee) =>
            trainee.user.fullName.toLowerCase().includes(lowerQuery)
          )
        : traineesInState;

    const seenTrainees = new Set<string>();
    const mappedTrainees = filteredTrainees.map((trainee) => {
      const result: AllTraineesRow = {
        key: trainee.id,
        data: {
          traineeId: trainee.id,
          programName: trainee.trainingProgram.name,
          firstRowForUser: !seenTrainees.has(trainee.user.id),
          numItemsCompleted: trainee.trainingProgram.assessmentEnabled
            ? trainee.itemStatuses.filter((t) =>
                t.questionsAndAnswers.some(
                  (qna) => qna.answer && qna.answer !== ""
                )
              ).length
            : trainee.itemStatuses.filter((t) => t.viewed).length,
          createdAt: trainee.createdAt,
          numItemsTotal: trainee.itemStatuses.length,
          markedCompletedAt: trainee.markedCompletedAt,
          user: trainee.user,
        },
        children: openRowIDs.includes(trainee.id)
          ? buildAllTraineeNestedRecordingRows(
              trainee.trainingProgram.assessmentEnabled,
              trainee.itemStatuses,
              trainee.id
            )
          : undefined,
      };

      seenTrainees.add(trainee.user.id);
      return result;
    });

    return mappedTrainees;
  }, [trainees, openRowIDs, query, stateFilter]);

  const options: TraineeCompletionState[] = [
    "In Progress",
    "Completed",
    "All Trainees",
  ];

  return (
    <Box pb={8} mt="6" px="10">
      <Flex flexDir="row" alignItems="center">
        <SearchInput
          defaultValue={query}
          onSearch={setQuery}
          minWidth="100%"
          height="2.375rem"
          inputHeight="2.375rem"
          fontSize="sm"
          placeholder="Search"
          borderRadius="4px"
          pr={3}
        />
        <Box minWidth="184px" ml="4">
          <Select
            id="trainee-completion-state"
            isSearchable={false}
            theme={selectTheme}
            styles={selectStyles}
            value={{ value: stateFilter, label: stateFilter }}
            options={options.map((label: TraineeCompletionState) => ({
              label,
              value: label,
            }))}
            onChange={(selectedOption) => {
              if (selectedOption?.label) {
                setStateFilter(selectedOption?.label);
                setOpenRowIDs([]);
              }
            }}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </Box>
      </Flex>
      <Box mt="6" borderRadius="1">
        {tableData.length ? (
          <TableNewer
            columns={columns}
            data={tableData}
            columnWidths={["20%", "40%", "15%", "25%"]}
            onClickRow={(row) => toggleRow(row)}
          />
        ) : (
          <Flex
            height={percentHeight(60)}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Text fontSize="sm" fontWeight="400" color="gray.700">
              There are no{" "}
              {stateFilter === "All Trainees" ? "" : stateFilter.toLowerCase()}{" "}
              trainees in any program
            </Text>
            {stateFilter !== "All Trainees" && (
              <Button
                onClick={() => {
                  setStateFilter("All Trainees");
                }}
                variant="link"
                color="blue.600"
                fontSize="sm"
                fontWeight="500"
                mt="4"
              >
                View all trainees
              </Button>
            )}
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default AllTraineesManagement;
