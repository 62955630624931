import {
  Box,
  Flex,
  Icon,
  ListItem,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { HiOutlineX } from "react-icons/hi";

import { Button, IconButton, LoadingIndicator } from "../../../components";
import { formatDuration, formatRelativeDate } from "../../../utils/datetime";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import {
  CandidateInterviewFragment,
  Chapter,
  useCallChaptersQuery,
} from "../../graphql";
import InterviewChapter from "./InterviewChapter";

type InterviewChaptersProps = {
  call: CandidateInterviewFragment;
  isOpen: boolean;
  onClose: () => void;
  thumbnailUrl?: any; // remove this when we have highlight thumbnails backfilled
};

const CHAPTER_INSET = 10;

const InterviewChaptersV2: React.FC<InterviewChaptersProps> = ({
  call,
  isOpen,
  onClose,
  thumbnailUrl,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const sendGAEvent = useSendGAEvent();

  const handleTabsChange = (index: number): void => {
    setTabIndex(index);
    sendGAEvent("view_interview_chapter", "candidate");
  };

  const { loading, error, data } = useCallChaptersQuery({
    variables: { callId: call.id },
  });

  const numChapters = data?.callChapters?.chapters?.length ?? 0;

  return (
    <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount size="full">
      <ModalOverlay />
      <ModalContent
        overflowY="scroll"
        margin="80px"
        width="100%"
        minHeight={0}
        maxHeight="calc(100% - 160px)"
        maxWidth="1140px"
        borderRadius="2xl"
        pb={6}
      >
        <Header onClose={onClose} />
        <Flex flexDir="column" overflow="hidden" px={6}>
          {loading && (
            <Box bg="gray.50" rounded="md" w="100%" p="4">
              <LoadingIndicator />
            </Box>
          )}
          {error && (
            <Box bg="gray.50" rounded="md" w="100%" p="4">
              <Text>
                Oops! There was an error fetching topics for this interview.
              </Text>
            </Box>
          )}
          {!loading && !error && data && numChapters === 0 && (
            <Box bg="gray.50" rounded="md" w="100%" p="4">
              <Text>No topics detected.</Text>
            </Box>
          )}
          {!loading &&
            !error &&
            data?.callChapters?.chapters &&
            numChapters > 0 && (
              <>
                <Flex
                  flexDir="row"
                  gap={6}
                  bg="gray.50"
                  py={2}
                  px={4}
                  mb={6}
                  borderRadius="md"
                >
                  <Text fontSize="sm" fontWeight="600">
                    {call.name}
                  </Text>
                  <Text
                    fontSize="sm"
                    fontWeight="400"
                    color="gray.600"
                    bg="gray.100"
                    px={1.5}
                    borderRadius="4px"
                  >
                    {call.duration && formatDuration(call.duration)}
                  </Text>
                  <Text
                    fontSize="sm"
                    fontWeight="400"
                    color="gray.600"
                    ml="auto"
                  >
                    {formatRelativeDate(call.startTime ?? call.createdAt)}
                  </Text>
                </Flex>
                <Flex flexDir="row" overflow="hidden">
                  <Tabs
                    orientation="vertical"
                    variant="unstyled"
                    index={tabIndex}
                    onChange={handleTabsChange}
                    isLazy
                    width="100%"
                  >
                    <Box
                      minW="40%"
                      overflow="auto"
                      borderRight="1px solid"
                      borderColor="gray.200"
                    >
                      <TabList
                        as={OrderedList}
                        maxHeight="500px"
                        overflow="auto"
                        ml="0"
                        pl="0"
                        pb="4"
                        sx={{
                          "li::marker": {
                            color: "gray.500",
                            fontWeight: "400",
                          },
                        }}
                      >
                        {data?.callChapters?.chapters.map(
                          (chapter: Chapter, idx: number) => (
                            <Tab
                              key={chapter.id}
                              as={ListItem}
                              display="list-item"
                              position="relative"
                              color="gray.900"
                              fontWeight="400"
                              _selected={{
                                fontWeight: "600",
                                color: "blue.500",
                              }}
                              _active={{
                                bg: "inherit",
                              }}
                              _hover={{ bg: "inherit", color: "blue.500" }}
                              cursor="pointer"
                              ml={CHAPTER_INSET}
                              pl={0}
                              pt={0}
                              pb={0}
                              mb={2}
                              data-tour-id={`candidate-page-interview-summary-chapter-${idx}`}
                            >
                              <ChapterListItem
                                key={chapter.id}
                                chapter={chapter}
                                isActive={idx === tabIndex}
                              />
                            </Tab>
                          )
                        )}
                      </TabList>
                    </Box>
                    <TabPanels overflow="auto">
                      {data?.callChapters?.chapters.map(
                        (chapter: Chapter, index: number) => (
                          <InterviewChapter
                            key={chapter.id}
                            chapter={chapter}
                            call={call}
                            index={index}
                            thumbnailUrl={thumbnailUrl}
                            modal
                          />
                        )
                      )}
                    </TabPanels>
                  </Tabs>
                </Flex>
              </>
            )}
        </Flex>
        <Flex
          pt={6}
          mt={10}
          borderTop="1px solid"
          borderColor="gray.100"
          flexDirection="row"
          justifyContent="center"
        >
          <Button minWidth="120px" onClick={onClose}>
            Close
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

type ChapterListItemProps = {
  chapter: Chapter;
  isActive?: boolean;
};

const ChapterListItem: React.FC<ChapterListItemProps> = ({
  chapter,
  isActive,
}) => {
  return (
    <Flex flexDir="row">
      {isActive && (
        <Box
          w="2px"
          bg="blue.400"
          position="absolute"
          height="100%"
          left={CHAPTER_INSET * -1}
          top={0}
        />
      )}
      <span style={{ paddingRight: "12px" }}>{chapter.text}</span>
    </Flex>
  );
};

const Header: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  return (
    <ModalHeader px={6}>
      <Flex px="4" justifyContent="space-between" alignItems="center">
        <Text
          fontSize="2xl"
          fontWeight="600"
          verticalAlign="center"
          color="gray.900"
        >
          Interview Summary
        </Text>
        <IconButton
          onClick={onClose}
          variant="ghost"
          aria-label="Close"
          icon={<Icon as={HiOutlineX} boxSize="6" color="gray.500" />}
          size="sm"
        />
      </Flex>
    </ModalHeader>
  );
};

export default InterviewChaptersV2;
