import { Center, Flex, Image, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useCallback } from "react";

import { Alert as ErrorAlert, LoadingIndicator } from "../../../../components";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  Alert,
  AtsDataState,
  useAddAlertViewMutation,
  useAnalyticsAlertsQuery,
} from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useAnalyticsContext from "../useAnalyticsContext";
import { drillDownToMetricURL } from "../utils";
import OverviewSection from "./OverviewSection";
import OverviewTip from "./OverviewTip";
import { OverviewConfig } from "./types";

type OverviewSectionTipsProps = {
  /* The organization ID */
  // Organization ID is set by analytics context to allow for internal org switching
  selectedOrgId: string;
  overviewConfig: OverviewConfig;
};

const OverviewSectionTips: React.FC<OverviewSectionTipsProps> = ({
  selectedOrgId,
  overviewConfig,
}) => {
  const sendGAEvent = useSendGAEvent();
  const alertsQuery = useAnalyticsAlertsQuery({
    variables: { requestedOrganizationId: selectedOrgId },
  });
  let alerts = alertsQuery.data?.alerts?.alerts || [];
  const { atsDataState } = useAnalyticsContext();
  if (atsDataState === AtsDataState.AtsNotSupported) {
    alerts = alerts.filter(
      (alert) =>
        ![
          "submission_delay",
          "submission_rate",
          "submission_is_positive",
        ].includes(alert.alertType)
    );
  }

  const {
    organization: { id: currentUserOrgId },
  } = useCurrentUser();
  const [addAlertView] = useAddAlertViewMutation();
  const handleAlertClick = useCallback(
    (alert: Alert) => {
      // Prevent BH admin user from logging alert views for other orgs
      if (currentUserOrgId !== alert.organizationId) return;

      sendGAEvent(
        "alert_click",
        "analytics",
        alert.alertType,
        undefined,
        alert
      );
      addAlertView({
        variables: {
          organizationId: alert.organizationId,
          type: alert.alertType,
          aggregation: alert.aggregation,
          aggregationIds: alert.aggregationIds,
          valence: alert.alertValence,
          category: alert.category,
          segment: alert.segment,
        },
        update(cache) {
          cache.modify({
            id: cache.identify({ __typename: "Alert", id: alert.id }),
            fields: {
              isNew: () => false,
            },
          });
        },
      });
    },
    [addAlertView, currentUserOrgId]
  );

  return (
    <OverviewSection title="Ways to Improve">
      <SimpleGrid columns={1} spacing="4">
        {alertsQuery.loading && (
          <Center>
            <LoadingIndicator />
          </Center>
        )}
        {alertsQuery.error && (
          <ErrorAlert
            status="error"
            description="There was an error fetching your alerts."
          />
        )}
        {!alertsQuery.loading && !alertsQuery.error && alerts.length === 0 && (
          <AlertsEmptyState />
        )}
        {!alertsQuery.loading &&
          !alertsQuery.error &&
          alerts?.length > 0 &&
          alerts?.map((alert) => (
            <OverviewTip
              key={alert.id}
              alert={alert}
              linkTo={drillDownToMetricURL(alert, overviewConfig.urlDateParam)}
              onCardClick={handleAlertClick}
            />
          ))}
      </SimpleGrid>
    </OverviewSection>
  );
};

const AlertsEmptyState: React.FC = () => (
  <Flex
    direction="column"
    align="center"
    borderRadius="12px"
    bg="white"
    border="1px solid"
    borderColor="gray.200"
    overflow="hidden"
  >
    <Flex
      p="6"
      align="center"
      justify="center"
      background="linear-gradient(0deg, #EEE 0%, #EEE 100%), linear-gradient(180deg, rgba(119, 119, 119, 0.60) 100.29%, rgba(170, 170, 170, 0.60) 200.29%)"
    >
      <Image w="50%" src="/static/images/analytics_alerts_empty_state.png" />
    </Flex>
    <Flex direction="column" align="stretch" textAlign="center" p="6">
      <Text fontWeight="600" fontSize="xl" mb="2">
        No interview coaching tips
      </Text>
      <Text color="gray.600" fontSize="sm">
        As we identify ways to help your team improve their interviewing
        performance, we’ll display them here.
      </Text>
    </Flex>
  </Flex>
);

export default OverviewSectionTips;
