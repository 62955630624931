import {
  Box,
  Center,
  Flex,
  Icon,
  Menu,
  MenuItem,
  MenuList,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { IoTrashBin } from "react-icons/io5";
import { MdAddCircleOutline, MdMoreVert } from "react-icons/md";
import { useParams } from "react-router-dom";

import {
  Alert,
  Button,
  LoadingIndicator,
  MenuButton,
} from "../../../components";
import AddVideoModal, { VideoFormData } from "../../components/AddVideoModal";
import LoomEmbed from "../../components/LoomEmbed";
import { usePositionQuery, useUpdatePositionMutation } from "../../graphql";

const PositionFaqs: React.FC = () => {
  const { positionId } = useParams() as { positionId: string };
  const { loading, error, data } = usePositionQuery({
    variables: { id: positionId },
  });
  const [addingVideo, setAddingVideo] = useState(false);

  const {
    isOpen: addVideoIsOpen,
    onOpen: addVideoOnOpen,
    onClose: addVideoOnClose,
  } = useDisclosure();

  const position = data?.position;

  const [updatePosition, { loading: updateLoading, error: updateError }] =
    useUpdatePositionMutation({
      onCompleted: () => setAddingVideo(false),
    });

  const handleAddVideo = useCallback(
    (formData: VideoFormData): void => {
      if (!position) return;
      setAddingVideo(true);
      const videos = [...position.videos].map(
        ({ __typename, ...video }) => video
      );
      updatePosition({
        variables: { id: position.id, videos: [...videos, formData] },
      });
      addVideoOnClose();
    },
    [position]
  );

  const handleRemoveVideo = useCallback(
    (i: number): void => {
      if (!position) return;
      const videos = [...position.videos].map(
        ({ __typename, ...video }) => video
      );
      videos.splice(i, 1);
      updatePosition({
        variables: { id: position.id, videos },
      });
    },
    [position]
  );

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return <Alert status="error" description="Error loading FAQs" reload />;
  }

  return (
    <Box maxW="700px">
      <AddVideoModal
        isOpen={addVideoIsOpen}
        onClose={addVideoOnClose}
        onSave={handleAddVideo}
      />
      {updateError && (
        <Alert status="error" description="Error updating position" />
      )}
      <Flex alignItems="center" my={8}>
        <Text fontSize="sm" color="gray.800">
          Answer candidate&apos;s frequently asked questions about the company,
          the role, or even BrightHire. FAQ videos are shared with candidates
          via email, ahead of their first BrightHire interview (Candidate
          opt-out email must be enabled).
        </Text>
      </Flex>
      <Flex alignItems="baseline" mb={4} mt={8}>
        <Button
          size="xs"
          variant="outline"
          leftIcon={<MdAddCircleOutline />}
          onClick={() => addVideoOnOpen()}
          disabled={updateLoading}
        >
          Add video
        </Button>
      </Flex>
      <SimpleGrid minChildWidth="300px" spacingX={2} spacingY={2}>
        {position?.videos.map((video, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={i}>
            <Flex justifyContent="space-between">
              <Box>&nbsp;</Box>
              <Menu>
                {() => (
                  <>
                    <MenuButton data-testid="video-menu">
                      <Icon as={MdMoreVert} />
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        data-testid="menu-delete"
                        icon={<IoTrashBin />}
                        onClick={() => {
                          handleRemoveVideo(i);
                        }}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </>
                )}
              </Menu>
            </Flex>
            <LoomEmbed src={video.url.replace("share", "embed")} />
          </Box>
        ))}
        {addingVideo && (
          <Center w="400px">
            <LoadingIndicator />
          </Center>
        )}
      </SimpleGrid>
    </Box>
  );
};

export default PositionFaqs;
