import React, { useMemo } from "react";

import { TopicCounts } from "../../../graphql";
import AnalyticsReportHero from "../AnalyticsReportHero";
import AnalyticsLineChart from "../line-chart/AnalyticsLineChart";
import { TopicLineChartDataPoint } from "../types";
import { formatAnalyticsDateRange, getUnixFromISO } from "../utils";
import { TopicTrendsConfig } from "./useTopicTrendsConfig";

type AnalyticsTopicTrendsChartProps = {
  data: Omit<TopicCounts, "organizationId">[];
  bucketSize: number;
  bucketInterval: string;
  queryConfig: TopicTrendsConfig;
};

const AnalyticsTopicTrendsChart: React.FC<AnalyticsTopicTrendsChartProps> = ({
  data,
  queryConfig,
  bucketInterval,
  bucketSize,
}) => {
  const chartLineData = useMemo(() => {
    const lineChartData: TopicLineChartDataPoint[] = [];
    data.forEach((d, i) => {
      if (d.xLabel) {
        lineChartData.push({
          x: getUnixFromISO(d.xLabel),
          datum: d,
        });
      }
    });
    return lineChartData.sort((a, b) => a.x - b.x);
  }, [data]);

  const noData =
    chartLineData.filter((d) => typeof d.datum.topicDiscussed === "number")
      .length === 0;

  if (noData) {
    return <AnalyticsReportHero image />;
  }

  return (
    <AnalyticsLineChart
      data={chartLineData}
      dataKey="datum.topicDiscussed"
      rangeDisplayValues={formatAnalyticsDateRange(queryConfig.dateRange.value)}
      bucketInterval={bucketInterval}
      bucketSize={bucketSize}
      yAxis={{
        formatter: (value) => `${value.toFixed(0)}%`,
        domain: [0, 100],
        ticks: [0, 50, 100],
      }}
      drawDot={chartLineData.length === 1}
      tooltipFooterFormatter={(datum) =>
        `Discussed in ${Math.round(
          datum.totalCalls * (datum.topicDiscussed / 100)
        )}/${datum.totalCalls} of interviews`
      }
    />
  );
};

export default AnalyticsTopicTrendsChart;
