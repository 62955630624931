import { useEffect, useMemo, useState } from "react";

import { MediaPlayerInterface } from "../useMediaPlayer";
import { ClipPlayRange, ClipRange, NarrowMediaPlayer } from "./types";

const useClipMaker = (
  initialClipRange: ClipRange,
  player: MediaPlayerInterface
): {
  playRange: ClipPlayRange;
  setPlayRange: (fn: (prev: ClipPlayRange) => ClipPlayRange) => void;
  narrowMediaPlayer: NarrowMediaPlayer;
  onSliderChange: (values: number[]) => void;
  setIsDraggingSlider: React.Dispatch<React.SetStateAction<boolean>>;
} => {
  const [playRange, setPlayRange] = useState<ClipPlayRange>({
    start: initialClipRange.start,
    play: initialClipRange.start,
    end: initialClipRange.end,
  });

  /**
   * Wraps the functional form of setPlayRange. Callers will pass their own function
   * into deduplicatingSetPlayRange, and the result of that function will be checked
   * against the previous playRange to avoid create an equivalent but not identical object,
   * which results in an infinite loop of state updates between the below useEffects.
   */
  const deduplicatingSetPlayRange = useMemo(
    () =>
      (fn: (prev: ClipPlayRange) => ClipPlayRange): void => {
        setPlayRange((prev) => {
          const next = fn(prev);
          const isDifferent =
            next.end !== prev.end ||
            next.play !== prev.play ||
            next.start !== prev.start;
          return isDifferent ? next : prev;
        });
      },
    [setPlayRange]
  );

  // Seek once the player is loaded
  useEffect(() => {
    if (player.duration !== undefined) {
      player.seek(playRange.start);
    }
  }, [player.duration, playRange.start]);

  // narrowMediaPlayer results in fewer rerenders because it is
  // not dependent on player.time
  const narrowMediaPlayer: NarrowMediaPlayer = useMemo(
    () => ({
      duration: player.duration,
      elementReady: player.elementReady,
      play: player.play,
      seek: player.seek,
      playing: player.playing,
      pause: player.pause,
    }),
    [
      player.duration,
      player.elementReady,
      player.play,
      player.seek,
      player.playing,
      player.pause,
    ]
  );

  const [isDraggingSlider, setIsDraggingSlider] = useState(false);

  // Handle updates from video as it plays
  useEffect(() => {
    if (
      player.elementReady &&
      playRange.play !== player.time &&
      !isDraggingSlider &&
      player.playing
    ) {
      deduplicatingSetPlayRange(() => ({
        start: playRange.start,
        // Constrain play to between start and end. This handles when a slider
        // drag results in a pending seek for the video, but the video updates
        // its time before the seek occurs.
        play: Math.min(Math.max(player.time, playRange.start), playRange.end),
        end: playRange.end,
      }));
    }
  }, [player.time, playRange, isDraggingSlider, player.playing]);

  // Handle drag events on the slider
  const onSliderChange = (values: number[]): void => {
    const start = values[0];
    const end = values[1];
    // Jump the player head to whichever edge is moving, if one is moving
    let play = start;
    if (start !== playRange.start) {
      play = start;
    } else if (end !== playRange.end) {
      play = end;
    }
    deduplicatingSetPlayRange(() => ({ start, play, end }));
    if (start !== playRange.start) {
      player.seek(start);
    } else if (end !== playRange.end) {
      player.seek(end);
    } else {
      player.seek(play);
    }
  };

  // Stop player from going past the beginning or end
  useEffect(() => {
    if (player.time > playRange.end) {
      player.pause();
      player.seek(playRange.end);
    }
    if (player.time < playRange.start) {
      player.seek(playRange.start);
    }
  }, [player.time, playRange]);

  return {
    playRange,
    setPlayRange: deduplicatingSetPlayRange,
    narrowMediaPlayer,
    onSliderChange,
    setIsDraggingSlider,
  };
};

export default useClipMaker;
