import { Box, Flex, Heading } from "@chakra-ui/react";
import React, { useState } from "react";
import { MdEmail } from "react-icons/md";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import {
  Button,
  CenteredCardLayout,
  RouterLink,
  useToast,
} from "../../../components";
import { usePageTracker, useSendGAEvent } from "../../../utils/googleAnalytics";
import GoogleAuthButton from "../../components/GoogleAuthButton";
import MsftAuthButton from "../../components/MsftAuthButton";
import OktaAuthButton from "../../components/OktaAuthButton";
import SignInByEmailForm from "../sign-in/SignInByEmailForm";
import VerifySignInByEmailForm from "../sign-in/VerifySignInByEmailForm";

const SignUpPage: React.FC = () => {
  usePageTracker("sign_up");
  const location = useLocation();
  const sendGAEvent = useSendGAEvent();
  const navigate = useNavigate();
  const state = location?.state as { from?: string } | undefined;
  const [searchParams] = useSearchParams();
  const from = searchParams.get("from") ?? state?.from;
  const [displayState, setDisplayState] = useState("default");
  let redirectTo = from ?? "/";
  if (!redirectTo.startsWith("/")) {
    redirectTo = "/";
  }
  const toast = useToast();

  const [verificationByEmailInfo, setVerificationByEmailInfo] = useState<{
    email: string;
  } | null>(null);

  const onVerificationByEmailCodeSent = (info: { email: string }): void => {
    setVerificationByEmailInfo(info);
    setDisplayState("verify-email");
  };

  const onResendVerificationByEmail = (): void => {
    setDisplayState("input-email");
  };

  const onSignIn = (): void => {
    sendGAEvent("sign_in", "auth");
    navigate(redirectTo);
  };

  const email = verificationByEmailInfo?.email;

  return (
    <CenteredCardLayout containerProps={{ alignItems: "center" }}>
      <Flex
        p={8}
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        width={["100%", "400px"]}
      >
        <>
          {displayState === "default" && (
            <>
              <Heading as="h3" mb={2} fontSize="2xl" fontWeight="normal">
                First time and returning users
              </Heading>
              <Box mb={4} textAlign="center" fontSize="sm">
                Continue with one of the authentication options below
              </Box>
              <GoogleAuthButton
                onError={({ message }) => {
                  toast({
                    title: "Error",
                    description: message,
                    status: "error",
                    duration: 30000,
                  });
                }}
                label="Sign Up with Google"
                onAuth={onSignIn}
                feature="auth"
              />
              <Box width="100%" mt={4}>
                <MsftAuthButton
                  label="Sign Up with Microsoft"
                  onError={({ message }) => {
                    toast({
                      title: "Error",
                      description: message,
                      status: "error",
                      duration: 30000,
                    });
                  }}
                  onAuth={onSignIn}
                  feature="auth"
                />
              </Box>
              <Box width="100%" mt={4}>
                <OktaAuthButton label="Sign Up with SSO" />
              </Box>
              <Box width="100%" mt={4}>
                <Button
                  leftIcon={<MdEmail />}
                  width="100%"
                  onClick={() => {
                    setDisplayState("input-email");
                  }}
                  data-testid="email-auth-button"
                >
                  Sign up with email
                </Button>
              </Box>
            </>
          )}
          {displayState === "input-email" && (
            <>
              <Heading as="h3" mb={4} fontSize="2xl" fontWeight="normal">
                Sign up by email
              </Heading>
              <SignInByEmailForm
                email={email}
                onVerificationCodeSent={onVerificationByEmailCodeSent}
              />
            </>
          )}
          {displayState === "verify-email" && verificationByEmailInfo && (
            <>
              <Heading as="h3" mb={4} fontSize="2xl" fontWeight="normal">
                Sign up by email
              </Heading>
              <Box px={6} width="342px">
                <VerifySignInByEmailForm
                  {...verificationByEmailInfo}
                  onSignIn={onSignIn}
                  onResendVerification={onResendVerificationByEmail}
                />
              </Box>
            </>
          )}
          {displayState === "default" && (
            <Box mt="6" mb="0" fontSize="sm" color="placeholder">
              {"Already have a BrightHire account? "}
              <RouterLink to="/sign-in">Sign in</RouterLink>
            </Box>
          )}
        </>
      </Flex>
    </CenteredCardLayout>
  );
};

export default SignUpPage;
