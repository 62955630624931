import {
  Box,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";

import { TdWhite, ThGray } from "./AnalyticsReportTable";

const LoadingCell: React.FC<{
  type?: "header" | undefined;
  minW?: string;
}> = ({ type, minW = "38px" }) => {
  const cellContents = (
    <Box minW={minW}>
      <Skeleton height="20px" />
    </Box>
  );

  return type === "header" ? (
    <ThGray>{cellContents}</ThGray>
  ) : (
    <TdWhite>{cellContents}</TdWhite>
  );
};

const AnalyticsReportTableSkeleton: React.FC = () => {
  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            {Array.from({ length: 8 }).map((_, i) => (
              <LoadingCell
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                type="header"
                minW={i === 0 ? "80px" : "56px"}
              />
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {Array.from({ length: 10 }).map((_, i) => (
            <Tr
              // eslint-disable-next-line react/no-array-index-key
              key={i}
            >
              {Array.from({ length: 8 }).map((_, j) => (
                <LoadingCell
                  // eslint-disable-next-line react/no-array-index-key
                  key={j}
                  minW={j === 0 ? "160px" : undefined}
                />
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default AnalyticsReportTableSkeleton;
