import {
  Box,
  Flex,
  Menu,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import React, { useMemo, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { TiDelete } from "react-icons/ti";
import Select from "react-select";

import { Avatar, Button, MenuButton, SearchInput } from "../../../components";
import {
  TableNewer,
  TableNewerColumn,
  TableNewerRow,
} from "../../../components/TableNew/TableNewer";
import useSelectTheme from "../../../hooks/useSelectTheme";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { formatDate } from "../../../utils/datetime";
import AddButton from "./AddButton";
import AddTraineesSelect, { TraineeSelectUser } from "./AddTraineesSelect";
import { GreenCheckmarkIcon } from "./icons";
import Indicator from "./Indicator";
import { buildTraineeNestedRecordingRows } from "./ManageTrainingPageTraineesRow";
import { TrainingMenuItem } from "./TrainingMenuItem";
import { TrainingProgram, TrainingProgramTrainee } from "./types";
import { TrainingApi } from "./useTrainingApi";

type ManageTrainingPageTableProps = {
  trainingProgram: TrainingProgram;
  trainees: TrainingProgramTrainee[];
  trainingApi: TrainingApi;
};

type TraineeCompletionState = "All Trainees" | "In Progress" | "Completed";

const ManageTrainingPageTable: React.FC<ManageTrainingPageTableProps> = ({
  trainingProgram,
  trainees,
  trainingApi,
}) => {
  const { percentHeight } = useWindowDimensions();
  const [query, setQuery] = useState("");
  const selectRef = useRef(null);
  const [stateFilter, setStateFilter] =
    useState<TraineeCompletionState>("In Progress");
  const { colors } = useTheme();
  const [openRowIDs, setOpenRowIDs] = useState<string[]>([]);
  const [pendingTraineeList, setPendingTraineeList] = useState<
    TraineeSelectUser[]
  >([]);
  const {
    isOpen: addTraineesIsOpen,
    onOpen: addTraineesOnOpen,
    onClose: addTraineesOnClose,
  } = useDisclosure();

  const customSelectStyle = useMemo(() => {
    return {
      control: (provided: Record<string, any>) => ({
        ...provided,
        fontSize: "14px",
        borderColor: colors.gray["200"],
      }),
      option: (provided: Record<string, any>) => ({
        ...provided,
        fontSize: "14px",
      }),
      singleValue: (provided: Record<string, any>) => ({
        ...provided,
        marginLeft: "8px",
        color: colors.gray["900"],
      }),
      dropdownIndicator: (provided: Record<string, any>) => ({
        ...provided,
        color: colors.gray["500"],
      }),
    };
  }, [colors]);

  const [selectTheme, selectStyles] = useSelectTheme(customSelectStyle);

  const toggleRow = (row: TableNewerRow): void => {
    if (openRowIDs.includes(row.data.id)) {
      setOpenRowIDs(openRowIDs.filter((id) => id !== row.data.id));
    } else {
      setOpenRowIDs([...openRowIDs, row.data.id]);
    }
  };

  const columns: Array<TableNewerColumn> = [
    {
      header: "Name",
      id: "traineeName",
      cell: (row: TableNewerRow) => {
        return (
          <Flex direction="row" alignItems="center">
            <Avatar
              name={row.data.user?.fullName}
              user={row.data.user}
              borderRadius="100%"
              mr="3"
            />
            <Text color="gray.900" fontWeight="500">
              {row.data.user?.fullName}
            </Text>
          </Flex>
        );
      },
    },
    {
      header: "Program",
      id: "programName",
      cell: () => {
        return (
          <Text color="gray.800" fontWeight="500">
            {trainingProgram.name}
          </Text>
        );
      },
    },
    {
      header: "Date Added",
      id: "traineeDateAdded",
      cell: (row: TableNewerRow) => {
        return (
          <Text color="gray.800" fontWeight="500" whiteSpace="nowrap">
            {formatDate(row.data.createdAt, {
              dateStyle: "medium",
            })}
          </Text>
        );
      },
    },
    {
      header: "Completed",
      id: "traineeDateCompleted",
      cell: (row: TableNewerRow) => {
        return (
          <Text color="gray.800" fontWeight="500" whiteSpace="nowrap">
            {formatDate(row.data.markedCompletedAt, {
              dateStyle: "medium",
            })}
          </Text>
        );
      },
    },
    {
      header: trainingProgram.assessmentEnabled ? "Assessments" : "Viewed",
      id: "traineeAssessment",
      cell: (row: TableNewerRow) => {
        const trainee = row.data as TrainingProgramTrainee;
        const numCompleted = trainingProgram.assessmentEnabled
          ? // This relies on the current behavior that we don't allows a user to submit
            // a partial list of trainingProgram answers, but will break when we allow fork
            // adding questions after launch if a user had already submitted some answers.
            new Set(
              trainee.trainingProgramAnswers.map(
                (pa) => pa.trainingProgramItemId
              )
            ).size
          : trainee.user.trainingProgramItemViews.length;
        const numGoal = trainingProgram.trainingProgramItems.length;

        // Logic to determine if an item is new. If the user has some answers
        // or views, and there isn't an answer or view that's more recent
        // than the item, then the item is new.
        const traineeHasNewItems = trainingProgram.trainingProgramItems.find(
          (trainingProgramItem) => {
            if (trainingProgram.assessmentEnabled) {
              return (
                trainee.trainingProgramAnswers.length > 0 &&
                !trainee.trainingProgramAnswers.find(
                  (trainingProgramAnswer) =>
                    trainingProgramAnswer.createdAt >
                    trainingProgramItem.createdAt
                )
              );
            }
            return (
              trainee.user.trainingProgramItemViews.length > 0 &&
              !trainee.user.trainingProgramItemViews.find(
                (trainingProgramItemView) =>
                  trainingProgramItemView.createdAt >
                  trainingProgramItem.createdAt
              )
            );
          }
        );

        return (
          <Flex
            direction="row"
            alignItems="center"
            fontWeight="500"
            color="gray.800"
          >
            {numCompleted}/{numGoal}
            {numCompleted === numGoal ? (
              <Box as="span" ml="4">
                <GreenCheckmarkIcon />
              </Box>
            ) : (
              traineeHasNewItems && (
                <Indicator
                  data-testid="new-recordings-indicator"
                  tooltipText="Recordings were added after this trainee started the program"
                />
              )
            )}
          </Flex>
        );
      },
    },
    {
      header: "State",
      accessor: "markedCompletedAt",
      cell: (row: TableNewerRow, idx: number) => {
        const completionButtonConfig = row.data.markedCompletedAt
          ? {
              label: "Mark incomplete",
              onClick: trainingApi.trainee.markIncomplete,
              backgroundColor: "gray.50",
              color: "gray.600",
              hoverBackgroundColor: "gray.100",
            }
          : {
              label: "Mark complete",
              onClick: trainingApi.trainee.markComplete,
              backgroundColor: "green.50",
              color: "green.600",
              hoverBackgroundColor: "green.100",
            };
        const completionButton = (
          <Button
            fontSize="sm"
            onClick={(e) => {
              e.stopPropagation();
              completionButtonConfig.onClick(row.data.id);
            }}
            variant="ghost"
            backgroundColor={completionButtonConfig.backgroundColor}
            color={completionButtonConfig.color}
            px="2"
            py="1"
            height="auto"
            fontWeight="500"
            mr="4"
            _hover={{
              backgroundColor: completionButtonConfig.hoverBackgroundColor,
            }}
            _active={{
              backgroundColor: completionButtonConfig.hoverBackgroundColor,
            }}
          >
            {completionButtonConfig.label}
          </Button>
        );
        return (
          <Flex flexDir="row" justifyContent="space-between">
            {completionButton}
            <Flex flexDir="row">
              <Menu>
                <MenuButton
                  mr="8"
                  p="0"
                  height="auto"
                  minW="auto"
                  color="gray.500"
                  _hover={{
                    bg: "gray.100",
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <BsThreeDotsVertical size="16px" />
                </MenuButton>
                <MenuList>
                  <TrainingMenuItem
                    icon={<TiDelete size="24px" color={colors.gray["600"]} />}
                    data-testid={`trainee-row-menu-delete-${idx}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      trainingApi.trainee.delete(row.data.id, {
                        toast: true,
                      });
                    }}
                  >
                    Remove Trainee
                  </TrainingMenuItem>
                </MenuList>
              </Menu>
              <Button
                mr="2"
                p="0"
                height="auto"
                minW="auto"
                variant="ghost"
                color="gray.500"
                _hover={{
                  bg: "gray.50",
                }}
                onClick={() => toggleRow(row)}
              >
                {row.children ? <IoChevronUp /> : <IoChevronDown />}
              </Button>
            </Flex>
          </Flex>
        );
      },
    },
  ];

  const tableData: TableNewerRow[] = useMemo(() => {
    if (trainees?.length) {
      let traineesInState = trainees;
      if (stateFilter === "In Progress") {
        traineesInState = trainees.filter((t) => !t.markedCompletedAt);
      } else if (stateFilter === "Completed") {
        traineesInState = trainees.filter((t) => t.markedCompletedAt);
      }
      const lowerQuery = query.toLowerCase();
      const filteredTrainees =
        query.length > 0
          ? traineesInState.filter((trainee) =>
              trainee.user.fullName.toLowerCase().includes(lowerQuery)
            )
          : traineesInState;
      const mappedTrainees = filteredTrainees.map((trainee) => {
        if (openRowIDs.includes(trainee.id)) {
          return {
            data: trainee,
            key: trainee.id,
            children: buildTraineeNestedRecordingRows(trainingProgram, trainee),
          };
        }
        return { data: trainee, key: trainee.id };
      });
      return mappedTrainees.sort((a, b) => {
        const aLastName = a.data.user.lastName || "";
        const bLastName = b.data.user.lastName || "";
        if (bLastName === "") return -1;
        if (aLastName === "") return 1;
        if (aLastName === bLastName) {
          return a.data.user.fullName.localeCompare(b.data.user.fullName);
        }
        return aLastName.localeCompare(bLastName);
      });
    }
    return [];
  }, [trainingProgram, trainees, openRowIDs, query, stateFilter]);

  const options: TraineeCompletionState[] = [
    "In Progress",
    "Completed",
    "All Trainees",
  ];

  return (
    <Box pb={8} mt="6" px="10">
      <Flex flexDir="row" alignItems="center">
        <SearchInput
          defaultValue={query}
          onSearch={setQuery}
          minWidth="100%"
          height="2.375rem"
          inputHeight="2.375rem"
          fontSize="sm"
          placeholder="Search"
          borderRadius="4px"
          pr={3}
        />
        <AddButton onClick={addTraineesOnOpen}>Add Trainees</AddButton>
        <Box minWidth="184px">
          <Select
            id="trainee-completion-state"
            isSearchable={false}
            theme={selectTheme}
            styles={selectStyles}
            value={{ value: stateFilter, label: stateFilter }}
            options={options.map((label: TraineeCompletionState) => ({
              label,
              value: label,
            }))}
            onChange={(selectedOption) => {
              if (selectedOption?.label) {
                setStateFilter(selectedOption?.label);
                setOpenRowIDs([]);
              }
            }}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </Box>
      </Flex>
      <Box mt="6" borderRadius="1">
        {tableData.length ? (
          <TableNewer
            columns={columns}
            data={tableData}
            columnWidths={["20%", "40%", "15%", "25%"]}
            onClickRow={(row) => toggleRow(row)}
          />
        ) : (
          <Flex
            height={percentHeight(60)}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Text fontSize="sm" fontWeight="400" color="gray.700">
              There are no{" "}
              {stateFilter === "All Trainees" ? "" : stateFilter.toLowerCase()}{" "}
              trainees currently in this program
            </Text>
            {stateFilter !== "All Trainees" && (
              <Button
                onClick={() => {
                  setStateFilter("All Trainees");
                }}
                variant="link"
                color="blue.600"
                fontSize="sm"
                fontWeight="500"
                mt="4"
              >
                View all trainees
              </Button>
            )}
          </Flex>
        )}
      </Box>
      <Modal
        initialFocusRef={selectRef}
        isOpen={addTraineesIsOpen}
        onClose={addTraineesOnClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent minWidth="520px">
          <ModalHeader
            display="flex"
            flexDir="row"
            alignItems="center"
            justifyContent="space-between"
            px={6}
            borderBottom="1px"
            borderBottomColor="gray.100"
            fontWeight="500"
          >
            <Text fontWeight="500">Add More Trainees</Text>
            <ModalCloseButton position="relative" top="0" right="0" />
          </ModalHeader>
          <ModalBody pl={8} pr={8} pt={8} pb={5}>
            <AddTraineesSelect
              trainingProgramId={trainingProgram.id}
              trainees={pendingTraineeList}
              onSelect={(value) => {
                setPendingTraineeList([...pendingTraineeList, value]);
              }}
              onRemove={(value) => {
                setPendingTraineeList(
                  pendingTraineeList.filter((o) => o.id !== value.id)
                );
              }}
              onClear={() => setPendingTraineeList([])}
              ref={selectRef}
            />
          </ModalBody>
          <ModalFooter pt={0}>
            <Button
              disabled={!pendingTraineeList.length}
              fontWeight="500"
              fontSize="sm"
              onClick={() => {
                trainingApi.trainee.add(
                  trainingProgram.id,
                  pendingTraineeList.map((t) => t.id)
                );
                setPendingTraineeList([]);
                addTraineesOnClose();
              }}
            >
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ManageTrainingPageTable;
