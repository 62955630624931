import React from "react";

import { errorToast, useToast } from "../../../../components";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  AddNewCallNoteMutation,
  CallBetaFragment,
  CallNotesBetaQuery,
  CallNoteType,
  CallNoteVisibility,
  useCallNotesBetaLazyQuery,
} from "../../../graphql";
import useAddCallNote from "../../../graphql/hooks/useAddCallNote";
import useAutofill, { getAts } from "../../../hooks/useAutofill";
import { useCopyNotes } from "../../../hooks/useCopyNotes";
import useCurrentUser from "../../../hooks/useCurrentUser";
import CopyNotesButtons from "../../CallNotes/beta/CopyNotesButtons";
import { NotesTabProps } from "../../Recording/SidebarTabs/NotesTab";

type CallNotesParams = {
  call: Pick<
    CallBetaFragment,
    | "id"
    | "greenhouseScorecardLinkWithId"
    | "leverInterviewLinkWithId"
    | "ashbyFeedbackLinkWithId"
    | "candidate"
    | "position"
    | "interviewerIds"
  >;
  onCallNotesQueryCompleted?: (data: CallNotesBetaQuery) => void;
  onAddCallNoteCompleted?: (data: AddNewCallNoteMutation) => void;
  disableAutofill?: boolean;
  notesForCurrentUserOnly: boolean;
};

type CallNotesReturn = Pick<
  NotesTabProps,
  "questions" | "generalNotes" | "onAddNote" | "headerSlot" | "error"
> & { getCallNotes(): void; loading: boolean };

const useCallNotes = ({
  call,
  onCallNotesQueryCompleted,
  onAddCallNoteCompleted,
  disableAutofill,
  notesForCurrentUserOnly,
}: CallNotesParams): CallNotesReturn => {
  const currentUser = useCurrentUser();
  const sendGAEvent = useSendGAEvent();
  const toast = useToast();
  const callId = call.id;
  const [
    getCallNotes,
    { data: notesData, loading: notesLoading, error: notesError },
  ] = useCallNotesBetaLazyQuery({
    variables: {
      callId,
      notesForCurrentUserOnly,
    },
    onCompleted: onCallNotesQueryCompleted,
  });

  const [addCallNote] = useAddCallNote({
    call: { id: callId, __typename: "Call" },
    onCompleted: onAddCallNoteCompleted,
    onError() {
      errorToast(toast, "There was a problem adding your note");
    },
  });

  const questions = notesData?.call?.questions ?? [];
  const generalNotes = notesData?.call?.generalNotes ?? [];
  const ats = getAts(call);

  const { autofillEnabled, onAutofill } = useAutofill(call);
  const handleCopyNotes = useCopyNotes({ call, generalNotes, questions });

  const isInterviewer = call.interviewerIds.includes(currentUser.id);

  return {
    getCallNotes,
    generalNotes,
    questions,
    loading: notesData === undefined,
    error: notesError,

    headerSlot: notesLoading ? null : (
      <CopyNotesButtons
        ats={ats}
        onCopy={handleCopyNotes}
        onAutofill={onAutofill}
        autofillEnabled={autofillEnabled && !disableAutofill && isInterviewer}
      />
    ),

    onAddNote: async ({ text, type, time, questionId }) => {
      if (
        text.trim() === "" &&
        [CallNoteType.Note, CallNoteType.Comment].includes(type)
      ) {
        return;
      }
      sendGAEvent("call_note_added", "call_review");
      await addCallNote({
        variables: {
          callId,
          text,
          type,
          time,
          questionId,
          visibility: CallNoteVisibility.Public,
        },
      });
    },
  };
};

export default useCallNotes;
