import {
  Box,
  ButtonGroup,
  FormControl,
  Radio,
  RadioGroup,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { MdCancel, MdSend } from "react-icons/md";

import { ContentEditable, IconButton } from "../../../components";
import { CallNoteVisibility } from "../../graphql";
import MentionMenu from "../MentionMenu";
import VisibilityMenu from "./VisibilityMenu";

export interface FormData {
  text: string;
  visibility: CallNoteVisibility;
}

interface ReplyFormProps {
  callId: string;
  isLoading: boolean;
  onSubmit: (formData: FormData) => void;
  onCancel: () => void;
  clipId?: string | null;
}

const ReplyForm: React.FC<ReplyFormProps> = ({
  callId,
  isLoading,
  onSubmit,
  onCancel,
  clipId,
}) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const [isValid, setIsValid] = useState(false);
  const [visibility, setCallNoteVisibility] = useState(
    CallNoteVisibility.Public
  );
  const { handleSubmit, setValue, register } = useForm<FormData>();
  const submitReply = handleSubmit((formData): void => onSubmit(formData));
  return (
    <Box borderColor="border" borderWidth="1px" borderRadius="md" bg="white">
      <form onSubmit={submitReply}>
        <FormControl px="4" py="2">
          <Textarea hidden isRequired {...register("text")} />
          <MentionMenu inputRef={inputRef} callId={callId} clipId={clipId} />
          <ContentEditable
            ref={inputRef}
            autoFocus
            placeholder="Reply or mention others with @"
            fontSize="sm"
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                onCancel();
              }
            }}
            onChange={(value) => {
              setValue("text", value ?? "");
              setIsValid(Boolean(value?.trim()));
            }}
            onSubmit={() => submitReply()}
          />
        </FormControl>
        <RadioGroup hidden name="visibility" value={visibility}>
          <Radio
            value={CallNoteVisibility.Public}
            {...register("visibility")}
          />
          <Radio
            value={CallNoteVisibility.CallOwner}
            {...register("visibility")}
          />
          <Radio
            value={CallNoteVisibility.Private}
            {...register("visibility")}
          />
        </RadioGroup>
        <Box borderTop="1px" borderColor="border" overflow="hidden">
          <ButtonGroup
            size="sm"
            variant="ghost"
            float="right"
            spacing="0"
            p="1px"
          >
            <Tooltip label="Close">
              <IconButton
                aria-label="cancel"
                icon={<MdCancel />}
                onClick={onCancel}
              />
            </Tooltip>
            <VisibilityMenu
              visibility={visibility}
              onChange={(visibility) => {
                setCallNoteVisibility(visibility);
              }}
            />
            <Tooltip label="Send">
              <IconButton
                aria-label="Send"
                type="submit"
                icon={<MdSend />}
                isLoading={isLoading}
                disabled={!isValid}
              />
            </Tooltip>
          </ButtonGroup>
        </Box>
      </form>
    </Box>
  );
};

export default ReplyForm;
