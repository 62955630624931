export default {
  variants: {
    semiFlush: {
      field: {
        border: "1px solid",
        borderColor: "gray.200",
        borderRadius: "6px",
        paddingLeft: "10px",
      },
    },
    editable: {
      field: {
        border: "1px solid",
        borderColor: "gray.200",
        paddingLeft: "8px",
      },
    },
  },
};
