import {
  ButtonGroup,
  Table,
  TableCellProps,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { HiOutlineDuplicate } from "react-icons/hi";
import { MdLink } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import { IconButton } from "../../../../components";
import { copy } from "../../../../utils/clipboard";
import { formatDate } from "../../../../utils/datetime";
import {
  DuplicateSkillReportMutationOptions,
  SkillReport,
  SkillReportSkillsSource,
  useDuplicateSkillReportMutation,
} from "../../../graphql";
import { ThGray } from "../AnalyticsReportTable";

type SkillReportListItem = Pick<
  SkillReport,
  "id" | "title" | "source" | "createdAt" | "dateRangeStart" | "dateRangeEnd"
> & {
  skills: {
    name: string;
    description?: string | null;
  }[];
  positions: {
    id: string;
    displayTitle: string;
  }[];
};

const TABLE_CELL_PROPS: TableCellProps = {
  px: 2,
  minW: "100px",
  textAlign: "left",
};

const TABLE_HEADER_CELL_PROPS = {
  ...TABLE_CELL_PROPS,
  fontSize: "xs",
};
const TABLE_DATA_CELL_PROPS = {
  ...TABLE_CELL_PROPS,
  py: 1,
  fontSize: "sm",
};

const SkillsReportTableRow: React.FC<{
  report: SkillReportListItem;
  duplicateReport(options: DuplicateSkillReportMutationOptions): void;
}> = ({ report, duplicateReport }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [isHovering, setIsHovering] = useBoolean();

  return (
    <Tr
      _hover={{ backgroundColor: "blue.50", cursor: "pointer" }}
      onMouseEnter={setIsHovering.on}
      onMouseLeave={setIsHovering.off}
      onClick={() => navigate(`/insights/skill-report/${report.id}`)}
    >
      <Td {...TABLE_DATA_CELL_PROPS}>{report.title}</Td>
      <Td {...TABLE_DATA_CELL_PROPS}>{formatDate(report.createdAt)}</Td>
      <Td {...TABLE_DATA_CELL_PROPS}>
        {formatDate(report.dateRangeStart, {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}{" "}
        -{" "}
        {formatDate(report.dateRangeEnd, {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </Td>
      <Td {...TABLE_DATA_CELL_PROPS} textAlign="right">
        {report.positions.length}
      </Td>
      <Td {...TABLE_DATA_CELL_PROPS}>
        {report.source === SkillReportSkillsSource.AtsJobDescription
          ? "Job description"
          : "Custom"}
      </Td>
      <Td {...TABLE_DATA_CELL_PROPS} textAlign="right">
        {report.skills.length}
      </Td>
      <Td {...TABLE_DATA_CELL_PROPS} textAlign="right">
        <ButtonGroup spacing={2}>
          <Tooltip label="Share report" placement="bottom-start">
            <IconButton
              aria-label="Share report"
              variant="icon"
              icon={<MdLink size="20px" />}
              backgroundColor="transparent"
              color={isHovering ? "blue.600" : "transparent"}
              onClick={(e) => {
                e.stopPropagation();
                copy(
                  `${window.location.origin}/insights/skill-report/${report.id}`
                );
                toast({
                  title: "Report link copied to clipboard",
                  status: "success",
                });
              }}
            />
          </Tooltip>
          <Tooltip label="Duplicate report" placement="bottom-start">
            <IconButton
              aria-label="Duplicate report"
              variant="icon"
              icon={<HiOutlineDuplicate strokeWidth="1.5" size="20px" />}
              backgroundColor="transparent"
              color={isHovering ? "blue.600" : "transparent"}
              onClick={(e) => {
                e.stopPropagation();
                duplicateReport({
                  variables: {
                    reportId: report?.id,
                  },
                });
              }}
            />
          </Tooltip>
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

type SkillsReportTableProps = {
  reports: SkillReportListItem[];
};

const SkillsReportTable: React.FC<SkillsReportTableProps> = ({ reports }) => {
  const navigate = useNavigate();
  const toast = useToast();

  const [duplicateReport] = useDuplicateSkillReportMutation({
    onCompleted: (data) => {
      const newReportId = data.duplicateSkillReport?.skillReport?.id;
      if (newReportId) {
        toast({
          title: "Report duplicated",
          status: "success",
        });
        navigate(`/insights/skill-report/${newReportId}`);
      }
    },
    onError: () => {
      toast({
        title: "Error duplicating report",
        status: "error",
      });
    },
  });

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <ThGray {...TABLE_HEADER_CELL_PROPS}>Report</ThGray>
            <ThGray {...TABLE_HEADER_CELL_PROPS}>Date created </ThGray>
            <ThGray {...TABLE_HEADER_CELL_PROPS}>Date range</ThGray>
            <ThGray {...TABLE_HEADER_CELL_PROPS} textAlign="right">
              Positions
            </ThGray>
            <ThGray {...TABLE_HEADER_CELL_PROPS}>Skills source</ThGray>
            <ThGray {...TABLE_HEADER_CELL_PROPS} textAlign="right">
              Skills count
            </ThGray>
            <ThGray {...TABLE_HEADER_CELL_PROPS} textAlign="center">
              &nbsp;
            </ThGray>
          </Tr>
        </Thead>
        <Tbody>
          {reports.map((report: SkillReportListItem) => (
            <SkillsReportTableRow
              key={report.id}
              report={report}
              duplicateReport={duplicateReport}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default SkillsReportTable;
