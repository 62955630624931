import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import Select from "react-select";

import { CustomDateTimeInput, DatePicker } from "../../../../../components";
import { formatISODate } from "../../../../../utils/datetime";
import {
  AtsDataState,
  FilterValue,
  useAnalyticsInterviewStageFilterValuesLazyQuery,
  useAtsDataStateQuery,
} from "../../../../graphql";
import { useAnalyticsSelectTheme } from "../../useAnalyticsSelectTheme";
import SkillsReportPositionSelect from "./SkillsReportPositionSelect";
import { DateRangeState } from "./types";
import {
  CandidateFilterType,
  UseSkillsReportBuilderReturn,
} from "./useSkillsReportBuilder";

type ReportBuilderFormControlsProps = Omit<
  UseSkillsReportBuilderReturn,
  "skills" | "setSkills" | "source" | "setSource"
> & {
  isVertical: boolean;
};

const ReportBuilderFormControls: React.FC<ReportBuilderFormControlsProps> = ({
  title,
  setTitle,
  dateRange,
  setDateRange,
  selectedPositions,
  setSelectedPositions,
  candidateFilter,
  setCandidateFilter,
  interviewStages,
  setInterviewStages,
  minInterviewCount,
  setMinInterviewCount,
  isVertical,
}) => {
  const [selectTheme, selectStyles] = useAnalyticsSelectTheme();
  const atsDataState =
    useAtsDataStateQuery().data?.atsDataState || AtsDataState.DataNotAvailable;

  const handleDateRangeChange = (newValue?: DateRangeState): void => {
    if (!newValue) return;
    setDateRange(newValue);
  };

  const { container: containerStyles = {}, ...selectStylesRest } = selectStyles;
  const commonSelectStyles = {
    container: (provided: Record<string, any>) => ({
      ...provided,
      flex: 1,
      minWidth: 340,
      ...containerStyles,
    }),
    ...selectStylesRest,
  };
  const dateInputStyleProps = useMemo(
    () => ({
      fontSize: "14px",
      color: "gray.900",
      borderColor: "gray.100",
      width: "100%",
      boxShadow: "none",
      borderRadius: "base",
    }),
    []
  );

  const candidateFilterOptions: {
    label: string;
    value: CandidateFilterType;
  }[] = [
    { value: "minInterviews", label: "At least this many interviews..." },
    { value: "any", label: "Any number of interviews" },
  ];
  if (atsDataState === AtsDataState.DataAvailable) {
    candidateFilterOptions.unshift({
      value: "interviewStage",
      label: "These specific interview stages...",
    });
  }

  const positionIds = selectedPositions?.map((position) => position.id);
  const hasPosition = !!positionIds?.length;

  const [fetchInterviewStages, { loading: interviewStagesLoading, data }] =
    useAnalyticsInterviewStageFilterValuesLazyQuery();
  const interviewStageOptions = hasPosition
    ? data?.interviewStageFilterValues.values || []
    : [];

  useEffect(() => {
    if (candidateFilter === "interviewStage" && hasPosition) {
      fetchInterviewStages({
        variables: {
          positionIds,
          dateRangeStart: formatISODate(dateRange.start),
          dateRangeEnd: formatISODate(dateRange.end),
        },
      });
    }
  }, [
    candidateFilter,
    hasPosition,
    positionIds.join(","),
    dateRange.start.toISOString(),
    dateRange.end.toISOString(),
  ]);

  const reportTitleControl = (
    <FormControl>
      <FormLabel color="gray.600" mb="1">
        Report title
      </FormLabel>
      <Input
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Enter report title"
        fontSize="sm"
        color="gray.900"
        borderColor="gray.100"
        _focus={{ borderColor: "gray.300" }}
      />
    </FormControl>
  );

  const CandidateFilterWrapper = isVertical ? React.Fragment : VStack;
  return (
    <OuterWrapper isVertical={isVertical}>
      {!isVertical && reportTitleControl}
      <FormControl>
        <FormLabel color="gray.600" mb="1">
          Date range
        </FormLabel>
        <Flex flex="1" alignItems="center">
          <DatePicker
            selected={dateRange.start}
            maxDate={dateRange.end}
            onChange={(date) => {
              if (!date) return;
              handleDateRangeChange({ start: date, end: dateRange.end });
            }}
            dateFormat="EEE, MMM d, yyyy"
            customInput={<CustomDateTimeInput {...dateInputStyleProps} />}
          />
          <Box mx="2" textAlign="center" fontSize="sm">
            to
          </Box>
          <DatePicker
            selected={dateRange.end}
            minDate={dateRange.start}
            maxDate={new Date()}
            onChange={(value) => {
              if (!value) return;
              handleDateRangeChange({
                start: dateRange.start,
                end: value,
              });
            }}
            dateFormat="EEE, MMM d, yyyy"
            customInput={<CustomDateTimeInput {...dateInputStyleProps} />}
          />
        </Flex>
      </FormControl>
      <FormControl>
        <FormLabel color="gray.600" mb="1">
          Position
        </FormLabel>
        <SkillsReportPositionSelect
          dateRange={dateRange}
          selectedPositions={selectedPositions}
          setSelectedPositions={setSelectedPositions}
        />
      </FormControl>
      <CandidateFilterWrapper {...(!isVertical ? { spacing: "4" } : null)}>
        <FormControl>
          <FormLabel color="gray.600" mb="1">
            Include candidates who have completed...
          </FormLabel>
          <Select
            styles={commonSelectStyles}
            theme={selectTheme}
            options={candidateFilterOptions}
            value={candidateFilterOptions.find(
              (o) => o.value === candidateFilter
            )}
            onChange={(option) => {
              if (!option?.value) return;
              setCandidateFilter(option.value);
            }}
          />
        </FormControl>
        {candidateFilter === "interviewStage" && (
          <FormControl>
            <FormLabel color="gray.600" mb="1">
              Interview stages
            </FormLabel>
            <Select
              styles={commonSelectStyles}
              theme={selectTheme}
              options={interviewStageOptions}
              value={interviewStageOptions.filter((option) =>
                interviewStages.find((stage) => stage.value === option.value)
              )}
              onChange={(value) => {
                setInterviewStages(value as FilterValue[]);
              }}
              isMulti
              isLoading={interviewStagesLoading}
            />
          </FormControl>
        )}
        {candidateFilter === "minInterviews" && (
          <FormControl>
            <FormLabel color="gray.600" mb="1">
              Number of interviews
            </FormLabel>
            <Select
              styles={commonSelectStyles}
              theme={selectTheme}
              options={Array.from({ length: 8 }, (_, i) => ({
                value: i + 1,
                label: (i + 1).toString(),
              }))}
              value={{
                value: minInterviewCount,
                label: minInterviewCount.toString(),
              }}
              onChange={(value) => {
                const val = value?.value;
                setMinInterviewCount(val ?? 1);
              }}
            />
          </FormControl>
        )}
      </CandidateFilterWrapper>
      {isVertical && reportTitleControl}
    </OuterWrapper>
  );
};

const OuterWrapper: React.FC<{
  isVertical: boolean;
  children: React.ReactNode;
}> = ({ isVertical, children }) => {
  return isVertical ? (
    <VStack maxWidth="400px" spacing={6}>
      {children}
    </VStack>
  ) : (
    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
      {children}
    </Grid>
  );
};

export default ReportBuilderFormControls;
