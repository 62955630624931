import { formatISODate } from "../../../utils/datetime";
import {
  AnalyticsReportQueryVariables,
  AnalyticsReportRawQueryVariables,
  ReportQueryResult,
  useAnalyticsReportQuery,
  useAnalyticsReportRawQuery,
} from "../../graphql";
import { computePreviousDateRange } from "./utils";

type ReportData = {
  report: {
    report?: ReportQueryResult;
    loading: boolean;
    error?: any;
    refetch: () => void;
  };
  comparisonReport: {
    report?: ReportQueryResult;
    loading: boolean;
    error?: any;
    refetch: () => void;
  };
};

type ReportTableDataCommonQueryVariables =
  | Omit<AnalyticsReportQueryVariables, "dateRangeStart" | "dateRangeEnd">
  | Omit<
      AnalyticsReportRawQueryVariables,
      "dateRangeStart" | "dateRangeEnd" | "includeRawResults"
    >;

type ReportTableDataArgs = {
  dateRangeStart: Date;
  dateRangeEnd: Date;
  includeRawResults?: boolean;
  additionalQueryVariables: ReportTableDataCommonQueryVariables;
};

/**
 * Query results and any error, loading states for quality report table data.
 * dateRangeStart, dateRangeEnd, and includeRawResults are deliberately
 * passed as props instead of as query variables because they
 * are needed for the additional functionality in this hook.
 * @argument dateRangeStart - start date for the report; used to calculate previous date range
 * @argument dateRangeEnd - end date for the report; used to calculate previous date range
 * @argument includeRawResults - whether to include raw data in the query
 * @argument additionalQueryVariables - additional query variables
 * @returns report data and comparison report data, with or without raw values
 */
const useReportTableData = ({
  dateRangeStart,
  dateRangeEnd,
  includeRawResults = false,
  additionalQueryVariables,
}: ReportTableDataArgs): ReportData => {
  // choose query based on whether raw data is needed
  const useQuery = includeRawResults
    ? useAnalyticsReportRawQuery
    : useAnalyticsReportQuery;

  const includeRawVariable = includeRawResults
    ? { includeRawResults: true }
    : {};

  const variables = {
    ...additionalQueryVariables,
    ...includeRawVariable,
  };

  // table data for UI date range
  const reportDataQuery = useQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      dateRangeStart: formatISODate(dateRangeStart),
      dateRangeEnd: formatISODate(dateRangeEnd),
      ...variables,
    },
  });

  // table data for previous date range
  const { previousDateRangeStart, previousDateRangeEnd } =
    computePreviousDateRange(dateRangeStart, dateRangeEnd);

  const previousReportDataQuery = useQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      dateRangeStart: formatISODate(previousDateRangeStart),
      dateRangeEnd: formatISODate(previousDateRangeEnd),
      ...variables,
    },
  });

  return {
    report: {
      report: reportDataQuery.data?.qualityReport,
      loading: reportDataQuery.loading,
      error: reportDataQuery.error,
      refetch: reportDataQuery.refetch,
    },
    comparisonReport: {
      report: previousReportDataQuery.data?.qualityReport,
      loading: previousReportDataQuery.loading,
      error: previousReportDataQuery.error,
      refetch: previousReportDataQuery.refetch,
    },
  };
};

export default useReportTableData;
