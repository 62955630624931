import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useRef } from "react";

import { Button } from "../../../../components";
import { useDeleteClipMutation } from "../../../graphql";

type DeleteClipParams = {
  clipId: string;
  onSuccess?(): void;
  onError?(errorMsg: string): void;
};

type DeleteClipReturn = {
  confirmDeleteClipDialog: JSX.Element;
  openConfirmationDialog(): void;
};

/**
 * Creates a confirmation dialog that will send the delete mutation.
 */
const useDeleteClip = ({
  clipId,
  onSuccess,
  onError,
}: DeleteClipParams): DeleteClipReturn => {
  const confirmationDialog = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement | null>(null);

  const [deleteClip, { loading: isDeleting }] = useDeleteClipMutation({
    variables: { id: clipId },
    onError(error) {
      confirmationDialog.onClose();
      onError?.(
        error.message.length < 100
          ? error.message
          : "This clip could not be deleted. Please try again later."
      );
    },
    update(cache, { data }) {
      const deletedClip = data?.deleteClip?.clip;
      const call = deletedClip?.call;

      if (call) {
        cache.modify({
          id: cache.identify(call),
          fields: {
            clipsCount: (existing: number) => existing - 1,
            callClips: (existing: { id: string }[]) =>
              existing.filter(({ id }) => id !== clipId),
          },
        });
      }

      if (deletedClip) {
        cache.evict({
          id: cache.identify(deletedClip),
        });
        cache.gc();
      }
    },

    onCompleted: (data) => {
      if (data?.deleteClip?.clip) {
        onSuccess?.();
        confirmationDialog.onClose();
      }
    },
  });

  return {
    openConfirmationDialog: confirmationDialog.onOpen,
    confirmDeleteClipDialog: (
      <AlertDialog {...confirmationDialog} leastDestructiveRef={cancelRef}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Are you sure you want to delete this clip?
            </AlertDialogHeader>
            <AlertDialogBody>
              Deleting the clip will also permanently delete the clip recording
              and clip notes, interview data will not be affected.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                variant="outline"
                size="sm"
                ref={cancelRef}
                onClick={confirmationDialog.onClose}
                isDisabled={isDeleting}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                size="sm"
                data-testid="clip-delete-confirm-button"
                isLoading={isDeleting}
                onClick={() => deleteClip()}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    ),
  };
};

export default useDeleteClip;
