import { useEffect, useState } from "react";

import { errorToast, useToast } from "../../../components";
import { useCandidateAlertDisabled } from "../../components/CandidateAlert";
import {
  CandidateAlertFeedFilter,
  CandidateAlertFeedListItemFragment,
  useCandidateAlertFeedLazyQuery,
  useMarkCandidateAlertFeedReadMutation,
} from "..";

const PAGE_LIMIT = 10;

type UseCandidateAlertFeedParams = {
  alertFilters: CandidateAlertFeedFilter[];
  onCompleted?(alerts: CandidateAlertFeedListItemFragment[]): void;
};

type UseCandidateAlertFeedReturn = {
  loading: boolean;
  alerts: CandidateAlertFeedListItemFragment[];
  userHasNoAlerts: boolean | undefined;
  noAlertsMatchFilters: boolean;
  hasNextPage: boolean;
  /** Marks given alerts as read. Omit `candidateAlertFeedIds` to mark all as read */
  markRead(candidateAlertFeedIds?: string[]): Promise<void>;
  loadMore(): Promise<void>;
};

/** Manages queries + mutations for current user's candidate alert feed */
export const useCandidateAlertFeed = ({
  alertFilters,
  onCompleted,
}: UseCandidateAlertFeedParams): UseCandidateAlertFeedReturn => {
  const toast = useToast();
  const [userHasNoAlerts, setUserHasNoAlerts] = useState<boolean>();

  const [getAlertFeed, { data, loading, fetchMore, called }] =
    useCandidateAlertFeedLazyQuery({
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const results = data.candidateAlertFeed.results ?? [];
        onCompleted?.(results);
        if (userHasNoAlerts === undefined) {
          setUserHasNoAlerts(results.length === 0);
        }
      },
      onError: () => {
        errorToast(toast, "Unable to load candidate alerts");
      },
    });

  const [markRead] = useMarkCandidateAlertFeedReadMutation({
    update(cache, { data }) {
      const ids = data?.markCandidateAlertFeedRead?.markedIds;
      const readAt = data?.markCandidateAlertFeedRead?.readAt;
      if (ids && readAt) {
        ids.forEach((id) => {
          cache.modify({
            id: cache.identify({ __typename: "CandidateAlertFeed", id }),
            fields: {
              readAt() {
                return readAt;
              },
            },
          });
        });
      }
    },
  });

  const candidateAlertsDisabled = useCandidateAlertDisabled();
  useEffect(() => {
    if (candidateAlertsDisabled) return;

    getAlertFeed({
      fetchPolicy: "network-only",
      variables: {
        pagination: { limit: PAGE_LIMIT },
        alertFilters,
      },
    });
  }, [alertFilters]);

  const alerts = data?.candidateAlertFeed.results || [];

  return {
    loading: loading || !called,

    alerts,
    userHasNoAlerts,
    noAlertsMatchFilters: alerts.length === 0,
    hasNextPage: !!data?.candidateAlertFeed.pageInfo.hasNextPage,

    markRead: async (candidateAlertFeedIds) => {
      await markRead({
        variables: {
          candidateAlertFeedIds,
          alertFilters,
        },
      });
    },

    loadMore: async () => {
      await fetchMore({
        variables: {
          pagination: { limit: alerts.length + PAGE_LIMIT },
        },
      });
    },
  };
};
