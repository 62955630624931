/** Matches a UUID anywhere in a string */
export const uuidSubStr =
  /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;

/** Matches a UUID exactly (no prefix or suffix allowed) */
export const uuid = new RegExp(`^${uuidSubStr.source}$`);

export const phoneNumber = /^([^\d]*1)?([^\d]*[\d][^\d]*){10}$/;

export const phoneExtension = /^[\d*#]*$/;

export const punctuationPrefix = /^[.,:!?]/;

export const punctuationPostfix = /[.,:!?]$/;

export const email =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const domain = /^((?:https?:\/\/)?[^./@]+(?:\.[^./]+)+(?:\/.*)?)$/;

export const meetURL =
  /(https:\/\/)?meet\.google\.com\/[a-z]{3}-[a-z]{4}-[a-z]{3}/;

export const zoomURL = /^(?:https?:\/\/)?(?:[\w-]+\.)?zoom\.us\/[js]\/(\d+)/;

export const zoomMeetingId =
  /(?:[A-Za-z0-9\-_]{0,61}[A-Za-z0-9]{1,1}\.)?zoom\.[A-Za-z]{2,6}\/[js]\/(\d{8,11})/;

export const teamsUrl = /^(https?:\/\/)?teams\.microsoft\.com\/(.*)$/;

export const areaCode = /^[\d]{3}$/;
