import React from "react";

import colorVars from "../../../../theme/css-color-variables";

const ClickableActiveDot: React.FC<{
  onClick: any;
  payload: {
    datum?: {
      xLabel?: string;
    };
  };
  cx: number;
  cy: number;
}> = ({ onClick, payload, cx, cy }) => {
  return (
    <g>
      <circle
        cx={cx}
        cy={cy}
        r={20}
        fill="transparent"
        onClick={() => {
          onClick(payload);
        }}
        style={{ cursor: "pointer" }}
      />
      <circle
        cx={cx}
        cy={cy}
        r={4}
        fill={colorVars.blue[200]}
        style={{ pointerEvents: "none" }}
      />
    </g>
  );
};

export default ClickableActiveDot;
