import { Box, ButtonProps, Flex, useDisclosure } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { IoArrowRedoSharp } from "react-icons/io5";

import { AsyncModal, Button } from "../../../components";
import { CallVisibility, Maybe } from "../../graphql";
import { ShareModal, useCallShareModal } from "../ShareModal";

type ShareCallButtonProps = {
  callId: string;
  positionId: Maybe<string> | undefined;
  callVisibility: CallVisibility;
  visibilityLevels: CallVisibility[];
  readOnly: boolean;
  buttonStyle?: "button" | "menuItem";
  isModalOpen?: boolean;
  onCloseModal(): void;
  onOpenModal(): void;
  /**
   * If present, `children` will be shown and launch the modal
   * on click. Otherwise, a default UI will be shown, based on
   * the value of the `buttonStyle` prop
   */
  children?: JSX.Element;
} & ButtonProps;

const ShareCallButton: React.FC<ShareCallButtonProps> = ({
  callId,
  positionId,
  callVisibility,
  visibilityLevels,
  readOnly,
  buttonStyle = "button",
  isModalOpen,
  onCloseModal,
  onOpenModal,
  children,
  ...buttonProps
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure({
    isOpen: isModalOpen,
    onClose: onCloseModal,
    onOpen: onOpenModal,
  });

  const { getCallShareData, hasSelection, loading, ...shareModalProps } =
    useCallShareModal({ callId, onClose });

  useEffect(() => {
    if (isOpen) {
      getCallShareData();
    }
  }, [isOpen]);

  const defaultTrigger =
    buttonStyle === "menuItem" ? (
      <Flex flex="1" onClick={onOpen}>
        Share Interview
      </Flex>
    ) : (
      <Button
        data-testid="share-button"
        size="sm"
        leftIcon={<IoArrowRedoSharp />}
        onClick={onOpen}
        {...buttonProps}
      >
        Share
      </Button>
    );

  const trigger = children ? (
    <Box onClick={onOpen} display="contents">
      {children}
    </Box>
  ) : (
    defaultTrigger
  );

  return (
    <>
      {trigger}
      <AsyncModal
        isOpen={isOpen}
        onClose={onClose}
        loading={loading}
        scrollBehavior={hasSelection ? "outside" : "inside"}
      >
        <ShareModal
          callId={callId}
          onClose={onClose}
          visibility={callVisibility}
          visibilityLevels={visibilityLevels}
          positionId={positionId}
          {...shareModalProps}
        />
      </AsyncModal>
    </>
  );
};

export default ShareCallButton;
