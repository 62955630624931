import React from "react";

import Pagination from "../../components/Pagination";
import AnalyticsReportHero from "./AnalyticsReportHero";
import AnalyticsReportTable from "./AnalyticsReportTable";
import AnalyticsReportTableSkeleton from "./AnalyticsReportTableSkeleton";
import { AnalyticsConfig } from "./types";
import { ReportData } from "./useReportData";

type AnalyticsReportSectionTableProps = {
  reportData: ReportData;
  queryConfig: AnalyticsConfig;
  linkToMyInsights?: boolean;
};

const AnalyticsReportSectionTable: React.FC<
  AnalyticsReportSectionTableProps
> = ({ reportData, queryConfig, linkToMyInsights }) => {
  if (reportData.loading) {
    return <AnalyticsReportTableSkeleton />;
  }

  if (reportData.error) {
    return (
      <AnalyticsReportHero>
        Oops! There was an error loading report data.
      </AnalyticsReportHero>
    );
  }

  if (!reportData.report) return null;

  const noData = reportData.report.data.length === 0;

  return (
    <>
      <AnalyticsReportTable
        reportData={reportData}
        reportConfig={queryConfig}
        linkToMyInsights={linkToMyInsights}
      />
      {noData ? (
        <AnalyticsReportHero>
          No data available.
          <br />
          Change your date and filter selections.
        </AnalyticsReportHero>
      ) : (
        <Pagination
          currentPageNumber={queryConfig.report.page.value}
          totalPageCount={reportData.pagination.totalPages}
          onChangePage={queryConfig.report.page.setValue}
        />
      )}
    </>
  );
};

export default AnalyticsReportSectionTable;
