import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useLink from "../../hooks/useLink";
import SettingsPageContainer from "../settings/shared/SettingsPageContainer";
import HiringTeamGroupsPage from "./HiringTeamGroupsPage";
import TeamsPage from "./TeamsPage";

const hoverStyles = {
  _hover: {
    bg: "transparent",
    color: "gray.800",
    borderBottomColor: "gray.100",
  },
  _active: {},
  _focus: {},
};

const HiringTabsPage: React.FC = () => {
  const paths = ["teams", "team_groups"];
  const pathLinks = [
    useLink({ type: "hiringTabsPage", page: "teams" }),
    useLink({ type: "hiringTabsPage", page: "team_groups" }),
  ];

  const [selectedIndex, setSelectedIndex] = useState(0);
  const { tabName } = useParams<"tabName">();
  const navigate = useNavigate();
  useEffect(() => {
    const index = paths.indexOf(tabName ?? "");
    if (index === -1) {
      navigate(pathLinks[0], {
        replace: true,
      });
      return;
    }
    if (selectedIndex !== index) {
      setSelectedIndex(index);
    }
  }, [tabName, selectedIndex]);

  return (
    <SettingsPageContainer maxW="1220" heading="Roles & policies">
      <Tabs
        index={selectedIndex}
        onChange={(index: number) => {
          const link = pathLinks[index];
          setSelectedIndex(index);
          navigate(link, { replace: true });
        }}
      >
        <TabList borderBottomWidth="1px" borderBottomColor="gray.100">
          <Tab px="0" pt="0" mr="5" fontWeight="500" {...hoverStyles}>
            Hiring Teams
          </Tab>
          <Tab px="0" pt="0" mr="5" fontWeight="500" {...hoverStyles}>
            Hiring Team Groups
          </Tab>
        </TabList>
        <TabPanels
          height="100%"
          display="flex"
          flexFlow="column nowrap"
          flex={1}
        >
          <TabPanel
            display="flex"
            flexFlow="column nowrap"
            py={0}
            order={0}
            height="100%"
            flex={1}
            key="interview-sidebar"
            justifyContent="space-between"
          >
            <TeamsPage />
          </TabPanel>
          <TabPanel>
            <HiringTeamGroupsPage />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </SettingsPageContainer>
  );
};

export default HiringTabsPage;
