import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { HiOutlineClock } from "react-icons/hi";
import Select from "react-select";

import { Button } from "../../../../components";
import { KeywordsInput } from "../../../../components/KeywordsInput";
import Modal from "../../../components/Modal/Modal";
import { CustomTopicMatchRule } from "../../../graphql";
import { useAnalyticsSelectTheme } from "../useAnalyticsSelectTheme";
import { TopicOption } from "./types";

interface FormValues {
  name: string;
  keywords: string[];
  matchRule: CustomTopicMatchRule;
}

interface CustomTopicFormProps {
  topic: TopicOption | undefined;
  reportLoading: boolean;
  onCancel: () => void;
  onDelete: (topicId: string) => void;
  onSaveTopic: (topicInfo: TopicOption) => void;
  onGenerateResults: (topicInfo: TopicOption) => void;
}

const CustomTopicForm: React.FC<CustomTopicFormProps> = ({
  topic,
  reportLoading,
  onCancel,
  onDelete,
  onSaveTopic,
  onGenerateResults,
}) => {
  const isNewTopic = topic?.value === "new_topic";
  const defaultName = isNewTopic ? "" : topic?.label || "";
  const defaultValues: FormValues = {
    name: defaultName,
    keywords: topic?.keywords || [],
    matchRule: topic?.matchRule || CustomTopicMatchRule.Any,
  };
  const [showDeleteModal, setShowDeleteModal] = useBoolean(false);

  const {
    register,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues,
  });

  const formControlStyle = {
    color: "gray.600",
    fontSize: "sm",
  };
  const [selectTheme, selectStyles] = useAnalyticsSelectTheme();
  const { container: containerStyles = {}, ...selectStylesRest } = selectStyles;
  const commonSelectStyles = useMemo(() => {
    return {
      container: (provided: Record<string, any>) => ({
        ...provided,
        flex: 1,
        minWidth: 340,
        ...containerStyles,
      }),
      ...selectStylesRest,
    };
  }, []);

  const matchOptions = [
    { label: "ANY of the keywords", value: CustomTopicMatchRule.Any },
    { label: "ALL of the keywords", value: CustomTopicMatchRule.All },
  ];

  const name = watch("name");
  const keywords = watch("keywords");

  const anyEmptyKeywords = keywords.some((k) => !k.length);
  const saveDisabled =
    !name.trim().length || !keywords.length || anyEmptyKeywords;
  const primaryColor = isNewTopic ? "blue" : "gray";
  return (
    <>
      <Modal
        isOpen={showDeleteModal}
        isDestructive
        headerText="Are you sure you want to delete this topic?"
        completeButtonText="Delete topic"
        onComplete={() => {
          if (!topic?.value) return;
          onDelete(topic.value);
        }}
        onClose={setShowDeleteModal.off}
      >
        <Text color="gray.800" fontSize="sm">
          This will permanently delete this topic. This cannot be undone.
        </Text>
      </Modal>
      <Box
        mb={6}
        py={6}
        backgroundColor={`${primaryColor}.50`}
        borderRadius="12px"
        borderColor={`${primaryColor}.200`}
        borderWidth="1px"
      >
        <Text color="gray.900" fontWeight="600" fontSize="xl" px={6}>
          {isNewTopic ? "Create topic" : "Edit topic"}
        </Text>
        <HStack my={6} px={6} spacing={4} alignItems="flex-start">
          <>
            <FormControl isRequired isInvalid={errors.name !== undefined}>
              <FormLabel htmlFor="name" {...formControlStyle}>
                Topic name
              </FormLabel>
              <Input
                {...register("name")}
                placeholder="Be descriptive"
                _placeholder={{
                  color: "gray.400",
                }}
              />
            </FormControl>
            <FormControl isRequired isInvalid={errors.keywords !== undefined}>
              <FormLabel htmlFor="keywords" {...formControlStyle}>
                Keywords
              </FormLabel>
              <Controller
                name="keywords"
                control={control}
                render={({ field }) => (
                  <KeywordsInput
                    {...field}
                    keywords={getValues("keywords")}
                    onChange={(keywords) => {
                      const lowercased = keywords.map((k) => k.toLowerCase());
                      setValue("keywords", lowercased);
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl isRequired isInvalid={errors.matchRule !== undefined}>
              <FormLabel htmlFor="matchRule" {...formControlStyle}>
                Include any interview segment that contains
              </FormLabel>
              <Controller
                name="matchRule"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={commonSelectStyles}
                    theme={selectTheme}
                    options={matchOptions}
                    value={matchOptions.find(
                      (o) => o.value === getValues("matchRule")
                    )}
                    onChange={(option) => {
                      if (!option) {
                        return;
                      }
                      setValue("matchRule", option.value);
                    }}
                  />
                )}
              />
            </FormControl>
          </>
        </HStack>
        <Divider borderColor="gray.200" />
        <Flex justifyContent="space-between" pt={6} px={6}>
          <HStack spacing={5}>
            <Button
              variant="ghost"
              size="sm"
              colorScheme="blue"
              minWidth="120px"
              onClick={onCancel}
            >
              Cancel
            </Button>
            {!isNewTopic && topic?.value && (
              <Button
                variant="ghost"
                colorScheme="red"
                size="sm"
                minWidth="120px"
                onClick={setShowDeleteModal.on}
              >
                Delete
              </Button>
            )}
          </HStack>
          <HStack spacing={5}>
            <Button
              disabled={saveDisabled}
              variant="outline"
              backgroundColor="transparent"
              size="sm"
              onClick={() => {
                if (reportLoading) return;
                const { name, keywords, matchRule } = getValues();
                const topicInfo: TopicOption = {
                  label: name,
                  value: topic?.value || "",
                  type: "custom",
                  keywords,
                  matchRule,
                };
                onGenerateResults(topicInfo);
              }}
              leftIcon={
                reportLoading ? <HiOutlineClock size="20px" /> : undefined
              }
            >
              {reportLoading
                ? "Generating results..."
                : "Preview topic results"}
            </Button>
            <Button
              size="sm"
              variant="solid"
              onClick={() => {
                if (reportLoading) return;
                const { name, keywords, matchRule } = getValues();
                const value =
                  topic?.value === "new_topic" ? "" : topic?.value || "";
                const topicInfo: TopicOption = {
                  label: name,
                  value,
                  type: "custom",
                  keywords,
                  matchRule,
                };
                onSaveTopic(topicInfo);
              }}
              disabled={saveDisabled}
            >
              {isNewTopic ? "Save topic" : "Save changes"}
            </Button>
          </HStack>
        </Flex>
      </Box>
    </>
  );
};

export default CustomTopicForm;
