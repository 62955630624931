import { Box, Flex, Icon, Tag, Text, Tooltip, VStack } from "@chakra-ui/react";
import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { MdChevronRight } from "react-icons/md";

import { Button, RouterLink, useTheme } from "../../../components";
import Avatar from "../../../components/Avatar";
import { formatDate } from "../../../utils/datetime";
import {
  InterviewsListRow,
  LIST_ITEM_FLEX_FULL,
} from "../../components/InterviewsList";
import { Thumbnail } from "../../components/Thumbnail";
import Indicator from "./Indicator";
import { TrainingListItemType } from "./types";

interface ViewTrainingListItemProps {
  trainingItem: TrainingListItemType;
  listLength: number;
  listPosition: number;
  assessmentEnabled?: boolean;
  addedAfterTraineeStart?: boolean;
}

interface ButtonLinkProps {
  children: React.ReactNode;
  href: string;
  type?: "primary" | "secondary";
}

const ButtonLink: React.FC<ButtonLinkProps> = ({
  href,
  type = "primary",
  children,
}) => {
  const primary = type === "primary";

  return (
    <RouterLink
      to={href}
      _hover={{ textDecoration: "none" }}
      data-testid="thumbnail-router-link"
    >
      <Button
        size="sm"
        colorScheme="green"
        tabIndex={-1}
        background={primary ? "blue.50" : "white"}
        color={primary ? "blue.600" : "green.600"}
        _hover={{
          background: primary ? "blue.100" : "white",
        }}
        _active={{
          background: primary ? "blue.100" : "white",
        }}
      >
        {children}
        <Icon as={MdChevronRight} boxSize="5" ml="1" />
      </Button>
    </RouterLink>
  );
};

const ViewTrainingListItem: React.FC<ViewTrainingListItemProps> = ({
  trainingItem,
  listLength,
  listPosition,
  assessmentEnabled = false,
  addedAfterTraineeStart,
}) => {
  const theme = useTheme();
  const { colors } = theme;

  return (
    <VStack align="flex-start" alignItems="flex-start" borderBottom="gray.100">
      <InterviewsListRow
        listLength={listLength}
        listPosition={listPosition}
        showDivider={false}
        showPadding={false}
        fullWidth
      >
        <Flex
          flexFlow="row nowrap"
          flex={LIST_ITEM_FLEX_FULL}
          position="relative"
          pl="10"
        >
          <RouterLink
            mr="6"
            to={trainingItem.url}
            tabIndex={-1}
            onFocus={(e) =>
              e.relatedTarget
                ? (e.relatedTarget as HTMLElement).focus()
                : e.target.blur()
            }
            isDisabled={!!trainingItem.contentRedactedAt}
            _disabled={{ color: "initial" }}
          >
            <Thumbnail
              size="md"
              isPlayable={
                trainingItem.hasRecordings && !trainingItem.contentRedactedAt
              }
              imageUrl={trainingItem.thumbnailImageUrl}
              audioOnly={trainingItem.audioOnly}
              time={trainingItem.duration}
              isClip={trainingItem.isClip}
            />
          </RouterLink>
          <Flex direction="column" minWidth="0">
            {trainingItem.positionDisplayTitle && (
              <Text fontSize="sm" color="gray.700" mb="1">
                {trainingItem.positionDisplayTitle}
              </Text>
            )}
            <Flex alignItems="baseline" flexWrap="wrap">
              <Box mr="3">
                <RouterLink
                  to={trainingItem.url}
                  color={colors.gray[800]}
                  _hover={{ textDecoration: "underline", color: "inherit" }}
                  isDisabled={!!trainingItem.contentRedactedAt}
                >
                  <Text fontSize="xl">
                    {trainingItem.name || "Missing call name"}
                  </Text>
                </RouterLink>
              </Box>
              {addedAfterTraineeStart && (
                <Tooltip label="This recording was added to the program after you started the program">
                  <Tag
                    height="6"
                    fontSize="sm"
                    color="white"
                    bg="gray.300"
                    borderRadius="full"
                  >{`Added ${formatDate(trainingItem.createdAt, {
                    month: "numeric",
                    day: "numeric",
                  })}`}</Tag>
                </Tooltip>
              )}
            </Flex>
            <Flex alignItems="center">
              {trainingItem.interviewers?.map((user) => (
                <Avatar
                  user={user}
                  key={user.id}
                  borderRadius="100px"
                  mr="1"
                  mt="3"
                  showTooltip
                />
              ))}
            </Flex>
            {trainingItem.description && (
              <Text
                fontSize="14"
                color="gray.800"
                backgroundColor="gray.50"
                border="gray.100"
                width="100%"
                px={3}
                py={2}
                mt="10"
                mb={2}
              >
                {trainingItem.description}
              </Text>
            )}
          </Flex>
          <Flex
            flexDir="row"
            ml="auto"
            alignItems="center"
            height="28px"
            shrink={0}
            mr="4"
          >
            {!trainingItem.contentRedactedAt && assessmentEnabled && (
              <>
                {trainingItem.assessmentOutdated && (
                  <Indicator
                    tooltipText="The assessment questions were edited after you submitted these answers."
                    mr="1"
                  />
                )}
                <ButtonLink
                  href={trainingItem.url}
                  type={
                    trainingItem.assessmentCompleted ? "secondary" : "primary"
                  }
                >
                  {trainingItem.assessmentCompleted
                    ? "View submitted assessment"
                    : "Awaiting your assessment"}
                </ButtonLink>
              </>
            )}
            {!trainingItem.contentRedactedAt &&
              !assessmentEnabled &&
              (trainingItem.viewed ? (
                <>
                  <Text color="gray.900" fontWeight="medium" fontSize="md">
                    Recording Viewed
                  </Text>
                  <Icon
                    as={FaCheckCircle}
                    ml="2"
                    fill="green.500"
                    boxSize="4"
                  />
                </>
              ) : (
                <ButtonLink href={trainingItem.url}>View recording</ButtonLink>
              ))}
          </Flex>
        </Flex>
      </InterviewsListRow>
    </VStack>
  );
};

export default ViewTrainingListItem;
