import {
  forwardRef,
  // eslint-disable-next-line bh/banned-chakra-import
  MenuButton as BaseButton,
  MenuButtonProps as BaseButtonProps,
} from "@chakra-ui/react";
import React from "react";

import { useForwardedRef } from "../../hooks/useForwardedRef";
import { GAEventDetails, useSendGAEvent } from "../../utils/googleAnalytics";
import { getEventValues } from "./utils";

type MenuButtonProps = BaseButtonProps & {
  /** Details to be passed to `sendGaEvent`. See component description for default values  */
  bhEvent?: GAEventDetails;
};

/**
 * Wrapper around the chakra `<MenuButton />` that sends ga events on click.
 *
 * You can use the `bhEvent` prop to control the ga event details.
 * The following defaults are applied:
 *  - action defaults `"click"`
 *  - label defaults to `data-testid` attribute (if present), or the button's inner text
 */
export const MenuButton = forwardRef<MenuButtonProps, "button">(
  ({ bhEvent, onClick, ...props }, forwardedRef) => {
    const sendGaEvent = useSendGAEvent();
    const ref = useForwardedRef<HTMLButtonElement>(forwardedRef);

    return (
      <BaseButton
        {...props}
        ref={ref}
        onClick={(e) => {
          const { action, category, label, params, value } = getEventValues({
            el: ref.current,
            componentType: "menu_button",
            providedParams: bhEvent,
          });

          sendGaEvent(action, category, label, value, params);
          onClick?.(e);
        }}
      />
    );
  }
);
