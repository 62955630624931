import { Box, Center, Text, useToast, VStack } from "@chakra-ui/react";
import React from "react";

import { Button, ErrorIcon, Reload } from "../../../components";

type AnalyticsReportErrorProps = {
  onRetry: () => void;
};

const AnalyticsReportError: React.FC<AnalyticsReportErrorProps> = ({
  onRetry,
}) => {
  const toast = useToast();
  return (
    <Box>
      <Center borderColor="gray.200" borderWidth="1px" borderRadius="8px" m="8">
        <VStack spacing={5} py="12" px="6">
          <ErrorIcon />
          <Text color="gray.600" fontWeight="600">
            Report failed to generate. Try again.
          </Text>
          <Button
            leftIcon={<Reload boxSize="6" fontWeight="500" />}
            variant="solid"
            onClick={() => {
              try {
                onRetry();
              } catch (error) {
                toast({
                  title: "Error generating report",
                  status: "error",
                });
              }
            }}
            aria-label="Retry report generation"
          >
            Generate report
          </Button>
        </VStack>
      </Center>
    </Box>
  );
};

export default AnalyticsReportError;
