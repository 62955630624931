import { Box, Divider, Flex, Tag, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
  // eslint-disable-next-line import/no-unresolved
} from "recharts/types/component/DefaultTooltipContent";

import { ReportConfig } from "../../../graphql";
import { REPORT_METRIC_MAP } from "../const";
import { getUnixFromISO } from "../utils";
import TooltipContainer from "./TooltipContainer";
import TooltipDataRow, { TOOLTIP_DATA_ROW_COL_W } from "./TooltipDataRow";
import { xFormatter } from "./xFormatter";

type ReportTooltipProps = TooltipProps<ValueType, NameType> & {
  columnsConfig: ReportConfig[];
  bucketInterval?: string;
  bucketSize?: number;
};

const ReportTooltip: React.FC<ReportTooltipProps> = ({
  active,
  payload = [],
  label,
  columnsConfig,
  bucketInterval,
  bucketSize,
}) => {
  const getDisplayValue = (value: any): string => {
    if (value === undefined || value === null) {
      return "—";
    }
    return `${value.toFixed(0)}`;
  };

  const getScoreDisplayValue = (value: any): string =>
    typeof value === "number" ? value.toFixed(0) : "No data";

  const tagProps = {
    color: "white",
    size: "sm",
  };

  if (active && payload.length) {
    const { x, datum, compareDatum } = payload[0].payload;
    if (!datum || !compareDatum) {
      return null;
    }
    return (
      <TooltipContainer>
        <VStack w="100%">
          <Flex flexDirection="row" justifyContent="space-between" w="100%">
            <Text>Metric</Text>
            <Flex ml="auto">
              {bucketInterval === "WEEK" ? "Week of" : ""}
              <Box textAlign="right" ml="3">
                <Tag {...tagProps} bg="blue.400">
                  {xFormatter(
                    getUnixFromISO(compareDatum.xLabel),
                    bucketInterval,
                    bucketSize
                  )}
                </Tag>
              </Box>
              <Box w={TOOLTIP_DATA_ROW_COL_W} textAlign="right">
                <Tag {...tagProps} bg="blue.700">
                  {xFormatter(x, bucketInterval, bucketSize)}
                </Tag>
              </Box>
            </Flex>
          </Flex>
          <Divider my="3" />
          <TooltipDataRow
            label="Overall Int. Quality Score"
            value={getScoreDisplayValue(datum.score)}
            compareValue={getScoreDisplayValue(compareDatum.score)}
          />
          <Divider my="3" />
          {columnsConfig.map((column) => (
            <TooltipDataRow
              key={column.name}
              label={REPORT_METRIC_MAP[column.name].label}
              value={getDisplayValue(datum[column.name])}
              compareValue={getDisplayValue(compareDatum[column.name])}
            />
          ))}
          <Divider my="3" />
          <TooltipDataRow
            label="Interviews"
            value={datum.totalCalls || "0"}
            compareValue={compareDatum.totalCalls || "0"}
          />
        </VStack>
      </TooltipContainer>
    );
  }

  return null;
};

export default ReportTooltip;
