import React from "react";

const AILoading: React.FC<{
  style?: React.CSSProperties;
}> = ({ style }) => {
  return (
    <img
      src="/static/images/AILoading.gif"
      style={{ width: 210, height: 210, ...style }}
    />
  );
};

export default AILoading;
