import { Flex, Heading, Radio, RadioGroup, Text } from "@chakra-ui/react";
import React, { useState } from "react";

import {
  Button,
  CenteredCardLayout,
  errorToast,
  useToast,
} from "../../../components";
import useUpdateCurrentUser from "../../graphql/hooks/useUpdateCurrentUser";
import useCurrentUser from "../../hooks/useCurrentUser";
import useSignOut from "../../hooks/useSignOut";

/**
 * Registration route for selecting an auto-draft scorecard option
 */
export const SelectScorecardAutoDraftPage: React.FC = () => {
  const currentUser = useCurrentUser();
  const onSignOut = useSignOut({ currentUser });

  const toast = useToast();

  const [selection, setSelection] = useState(
    currentUser.autofillGreenhouseScorecard
  );
  const [updateCurrentUser, { loading }] = useUpdateCurrentUser({
    onError: () => {
      errorToast(toast, "There was a problem saving your selection");
    },
  });

  return (
    <CenteredCardLayout
      onSignOut={onSignOut}
      containerProps={{ alignItems: "center" }}
    >
      <Flex pt="8" flexDirection="column" alignItems="center" maxW="480">
        <Heading as="h1" fontSize="xl" fontWeight="normal" mb={4}>
          Auto-draft your interview scorecard
        </Heading>
        <Text textAlign="center" fontSize="sm" mb="6">
          Want BrightHire to automatically draft your Greenhouse scorecard with
          evidence from your AI-generated interview notes? You&apos;ll always
          have the opportunity to review and edit your scorecards before you
          submit.
        </Text>
        <RadioGroup
          variant="padded"
          value={selection ? "yes" : "no"}
          onChange={(value) => setSelection(value === "yes")}
        >
          <Flex direction="column" gap="2">
            <Radio value="yes">Yes, automatically draft my scorecards</Radio>
            <Radio value="no">
              No, do not automatically draft my scorecards
            </Radio>
          </Flex>
        </RadioGroup>
        <Button
          mt="10"
          minW="120"
          isLoading={loading}
          onClick={() =>
            updateCurrentUser({
              variables: { autofillGreenhouseScorecard: selection },
            })
          }
        >
          Save
        </Button>
      </Flex>
    </CenteredCardLayout>
  );
};
