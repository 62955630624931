import React from "react";

import { AnalyticsDimension } from "../../graphql";
import AnalyticsFilter from "./AnalyticsFilter";
import { CandidateMotivationsConfig } from "./candidate-motivations/useCandidateMotivationsConfig";
import { CandidateQuestionsConfig } from "./candidate-questions/useCandidateQuestionsConfig";
import { TopicTrendsConfig } from "./topic-trends/useTopicTrendsConfig";
import { AnalyticsConfig, CommonQueryVariables } from "./types";

const AnalyticsFilters: React.FC<{
  queryConfig:
    | AnalyticsConfig
    | TopicTrendsConfig
    | CandidateMotivationsConfig
    | CandidateQuestionsConfig;
  queryVariables: CommonQueryVariables;
  filterHeights?: object;
  setFilterHeights?: React.Dispatch<
    React.SetStateAction<{
      [key: string]: number;
    }>
  >;
}> = ({ queryConfig, queryVariables, filterHeights, setFilterHeights }) => {
  const { enabledFilters } = queryConfig;
  const enabled = Object.values(enabledFilters).filter((v) => !!v);
  const filterWidth = (100 / enabled.length).toFixed(2);

  return (
    <>
      {enabledFilters.departments && (
        <AnalyticsFilter
          dimension={AnalyticsDimension.Department}
          secondaryDimension={queryConfig.secondaryDimension.value}
          placeholder="All Departments"
          queryVariables={queryVariables}
          multiSelect={queryConfig.departments}
          flex={`0 0 ${filterWidth}%`}
          maxWidth={`${filterWidth}%`}
          filterHeights={filterHeights}
          setFilterHeights={setFilterHeights}
          pr={enabled.length === 1 ? "0" : "6"}
        />
      )}
      {enabledFilters.positions && (
        <AnalyticsFilter
          dimension={AnalyticsDimension.Position}
          secondaryDimension={queryConfig.secondaryDimension.value}
          placeholder="All Positions"
          queryVariables={queryVariables}
          multiSelect={queryConfig.positions}
          flex={`0 0 ${filterWidth}%`}
          maxWidth={`${filterWidth}%`}
          filterHeights={filterHeights}
          setFilterHeights={setFilterHeights}
          pr={enabled.length === 2 ? "0" : "6"}
        />
      )}
      {enabledFilters.interviewers && (
        <AnalyticsFilter
          dimension={AnalyticsDimension.Interviewer}
          secondaryDimension={queryConfig.secondaryDimension.value}
          placeholder="All Interviewers"
          queryVariables={queryVariables}
          multiSelect={queryConfig.interviewers}
          flex={`0 0 ${filterWidth}%`}
          maxWidth={`${filterWidth}%`}
          filterHeights={filterHeights}
          setFilterHeights={setFilterHeights}
          pr={enabled.length === 3 ? "0" : "6"}
        />
      )}
      {enabledFilters.stages && (
        <AnalyticsFilter
          dimension={AnalyticsDimension.JobStage}
          secondaryDimension={queryConfig.secondaryDimension.value}
          placeholder="All Interview Stages"
          queryVariables={queryVariables}
          multiSelect={queryConfig.stages}
          flex={`0 0 ${filterWidth}%`}
          maxWidth={`${filterWidth}%`}
          filterHeights={filterHeights}
          setFilterHeights={setFilterHeights}
        />
      )}
    </>
  );
};

export default AnalyticsFilters;
