import { useUserCandidateSummaryVisibilityQuery } from "../../../graphql";
import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";
import { useCandidateSummaryFullyEnabled } from "./useCandidateSummaryFullyEnabled";

export const useShowEditCandidateSummaryTemplate = (): boolean => {
  const fullyFlagEnabled = useCandidateSummaryFullyEnabled();
  const uiFlagEnabled = useFeatureFlag("candidate_summary:ui");
  const editFlagEnabled = useFeatureFlag("candidate_summary:edit");
  const { data: visible } = useUserCandidateSummaryVisibilityQuery();

  const specificallyFlagEnabled = uiFlagEnabled && editFlagEnabled;
  const flagEnabled = fullyFlagEnabled || specificallyFlagEnabled;
  const userEnabled = visible?.currentUser?.canEditCandidateSummaryTemplate;

  return Boolean(flagEnabled && userEnabled);
};
