import * as Sentry from "@sentry/browser";
import { useCallback, useState } from "react";

interface RecordingData {
  recording: boolean;
  startRecording: () => void;
  stopRecording: () => void;
}

/**
 * Supports toggling on and off recording.
 */
const useRecording = (onData: (data: Blob) => void): RecordingData => {
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );

  const startRecording = useCallback(async () => {
    if (recording) return;

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream, {
        mimeType: "audio/webm",
      });
      setMediaRecorder(recorder);

      recorder.ondataavailable = (event: BlobEvent) => {
        onData(event.data);
      };

      recorder.onerror = (event) => {
        Sentry.captureMessage("Greenhouse Candidate Params", {
          extra: {
            event: event.toString(),
          },
        });
      };

      recorder.start(); // can set a number here to stream
      setRecording(true);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [recording, onData]);

  const stopRecording = useCallback(() => {
    if (!recording || !mediaRecorder) return;

    mediaRecorder.stop();
    mediaRecorder.stream.getTracks().forEach((track) => track.stop());
    setMediaRecorder(null);
    setRecording(false);
  }, [recording, mediaRecorder]);

  return { recording, startRecording, stopRecording };
};

export default useRecording;
