import { useDisclosure } from "@chakra-ui/react";
import React, { forwardRef, useMemo, useState } from "react";
import CreatableSelect from "react-select/creatable";

import { Alert, Select } from "../../../components";
import useCurrentBreakpoint from "../../../hooks/useCurrentBreakpoint";
import useSelectTheme from "../../../hooks/useSelectTheme";
import { PositionListItemFragment, useOrgPositionsQuery } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import { AddPositionModal } from "../AddPositionModal";

const formatOption = (
  position: PositionListItemFragment
): { label: string; value: PositionListItemFragment } => ({
  label: position.displayTitle,
  value: position,
});

const smallStyles: Partial<Record<string, any>> = {
  control: (provided: Record<string, any>) => ({
    ...provided,
    fontSize: "12px",
    minHeight: "30px",
  }),
  valueContainer: (provided: Record<string, any>) => ({
    ...provided,
    padding: "2px",
  }),
  dropdownIndicator: (provided: Record<string, any>) => ({
    ...provided,
    padding: "4px",
  }),
  clearIndicator: (provided: Record<string, any>) => ({
    ...provided,
    padding: "2px",
  }),
  input: (provided: Record<string, any>) => ({
    ...provided,
    marginLeft: "8px",
  }),
  placeholder: (provided: Record<string, any>) => ({
    ...provided,
    marginLeft: "8px",
  }),
};

type PositionSelectProps = {
  positionId?: string;
  currentPositionId?: string;
  name?: string;
  disabled?: boolean;
  onSelect: (callPosition: PositionListItemFragment | undefined) => void;
  size?: "sm" | "md";
  isClearable?: boolean;
  customStyles?: Record<string, any>;
};

const PositionSelect = forwardRef<any, PositionSelectProps>(
  (
    {
      name,
      positionId,
      currentPositionId,
      disabled,
      onSelect,
      size = "md",
      isClearable = true,
      customStyles,
    },
    ref
  ) => {
    const currentBreakpoint = useCurrentBreakpoint();
    const currentUser = useCurrentUser();
    const [theme, styles] = useSelectTheme(
      size === "sm" ? smallStyles : customStyles
    );
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [title, setTitle] = useState("");
    const [position, setPosition] = useState<PositionListItemFragment>();
    const { loading, error, data } = useOrgPositionsQuery();
    const positions = data?.currentUser?.organization.positions.results;
    useMemo(() => {
      const position = positions?.find(
        (position) => position.id === positionId
      );
      setPosition(position);
    }, [positionId, positions]);

    let positionOptions:
      | { label: string; value: PositionListItemFragment }[]
      | undefined = [];
    if (currentPositionId) {
      positionOptions = positions?.reduce((acc, position) => {
        if (position.id !== currentPositionId) {
          acc.push(formatOption(position));
        }
        return acc;
      }, [] as { label: string; value: PositionListItemFragment }[]);
    } else {
      positionOptions = positions?.map(formatOption);
    }

    const props = {
      ref,
      name,
      theme,
      styles,
      isClearable,
      menuShouldBlockScroll: true,
      menuShouldScrollIntoView: true,
      menuPlacement: "auto" as const,
      isDisabled: disabled,
      isLoading: loading,
      value: position ? formatOption(position) : undefined,
      options: positionOptions,
      onChange: (option: any) => {
        const value = (
          option as {
            value: PositionListItemFragment;
          }
        )?.value;
        onSelect(value);
      },
      menuPortalTarget: document.getElementById("root"),
    };
    return (
      <>
        <AddPositionModal
          title={title}
          isOpen={isOpen}
          onClose={onClose}
          onSave={(position) => {
            onSelect(position);
            setTitle("");
            onClose();
          }}
        />
        {error ? (
          <Alert status="error" description="Error loading positions" />
        ) : currentUser.userRole?.canCreatePosition ? (
          <CreatableSelect
            aria-label="position-select"
            placeholder={
              currentBreakpoint === "base"
                ? "Select or create position..."
                : "Select or create new position..."
            }
            onCreateOption={(title) => {
              setTitle(title);
              onOpen();
            }}
            {...props}
          />
        ) : (
          <Select
            aria-label="position-select"
            placeholder="Select Position..."
            {...props}
          />
        )}
      </>
    );
  }
);

PositionSelect.displayName = "PositionSelect";
export default PositionSelect;
