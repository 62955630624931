import { LinkProps } from "@chakra-ui/react";
import React, { ReactElement } from "react";

import { Link } from "../../../components";
import { formatDuration } from "../../../utils/datetime";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { CallNoteFragment } from "../../graphql";
import MonospacedText from "../MonospacedText";
import { noteTimestampedLink } from "./utils";

type TimestampLinkProps = LinkProps & {
  note: Pick<CallNoteFragment, "type" | "time" | "callId">;
  onClickTimestamp?: (time: number) => void;
};

export const TimestampLink: React.FC<TimestampLinkProps> = ({
  note,
  onClickTimestamp,
  ...rest
}): ReactElement<any, any> => {
  const sendGAEvent = useSendGAEvent();
  return (
    <Link
      href={noteTimestampedLink(note)}
      whiteSpace="nowrap"
      fontSize="13px"
      fontWeight="medium"
      onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        sendGAEvent(
          "playback_control",
          "call_review",
          "call_notes_play",
          note.type
        );
        onClickTimestamp?.(note.time);
      }}
      {...rest}
    >
      <MonospacedText text={formatDuration(note.time)} />
    </Link>
  );
};
