import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { IoCut } from "react-icons/io5";

import { Link } from "../../../../components";
import { formatDuration } from "../../../../utils/datetime";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { TranscriptWord } from "../../../graphql";
import useHover from "../../../graphql/hooks/useHover";
import Timestamp from "../../CallNotes/Timestamp";
import MonospacedText from "../../MonospacedText";
import { Speaker } from "../../Recording/Transcript";
import ClipWord from "./ClipWord";
import {
  ClipPlayRange,
  NarrowMediaPlayer,
  SegmentWords,
  WordState,
} from "./types";
import { between, getSafeRange } from "./utils";

type ClipSegmentProps = {
  speakerLabel: string;
  player: NarrowMediaPlayer;
  setPlayRange: (fn: (prev: ClipPlayRange) => ClipPlayRange) => void;
  segment: SegmentWords;
  segmentIndex: number;
  scrollTo: (y: number) => void;
  inactive: boolean;
  onMouseOverWord: (start: number, end: number) => void;
  onSelectionEdgeDragStart: (position: "start" | "end") => void;
  dragging: boolean;

  // These attributes are only set for the active segment, to minimize rerendering
  playRange?: ClipPlayRange;
  selectionStartWord?: TranscriptWord;
  selectionEndWord?: TranscriptWord;
  activeBrowserSelection?: boolean;
};

const ClipSegment: React.FC<ClipSegmentProps> = ({
  speakerLabel,
  player,
  setPlayRange,
  segment,
  scrollTo,
  segmentIndex,
  inactive,
  playRange,
  onMouseOverWord,
  onSelectionEdgeDragStart,
  dragging,
  selectionStartWord,
  selectionEndWord,
  activeBrowserSelection,
}) => {
  const segmentWordStart = segment.words[0].startTime;
  const sendGAEvent = useSendGAEvent();
  const segmentWordEnd = segment.words[segment.words.length - 1].endTime;
  const ref = useRef<HTMLDivElement>(null);
  const hover = useHover(ref.current);
  const [readyToRender, setReadyToRender] = useState(false);
  // Stagger rendering of segments for the initial render to avoid a large
  // period of unresponsiveness.
  useEffect(() => {
    // TODO: move this check up to transcript
    if (player.elementReady) {
      setTimeout(
        () => setReadyToRender(true),
        // Render the first 4 segments instantly, then after 75ms,
        // render in batches of 50 additional segments every 50ms.
        // Derived from manual testing for balancing responsiveness with
        // rendering as soon as possible.
        segmentIndex <= 4 ? 0 : 500 + Math.floor(segmentIndex / 50) * 50
      );
    }
  }, [player.elementReady]);

  const seekAndPlay = useCallback(
    (time: number) => {
      player.seek(time);
      player.play();
    },
    [player.seek, player.play]
  );

  const onClickStartHere = useCallback(() => {
    setPlayRange((clipPlayRange) => {
      const { start: safeStart, end: safeEnd } = getSafeRange(
        { start: segmentWordStart, end: clipPlayRange.end },
        {
          start: segmentWordStart,
          end: segmentWordEnd,
        }
      );
      sendGAEvent("clip_start_here", "call_review");
      return {
        start: safeStart,
        play: Math.max(clipPlayRange.play, safeStart),
        end: safeEnd,
      };
    });
  }, [segmentWordStart, segmentWordEnd, setPlayRange]);

  const onClickEndHere = useCallback(() => {
    setPlayRange((clipPlayRange) => {
      const { start: safeStart, end: safeEnd } = getSafeRange(
        { start: clipPlayRange.start, end: segmentWordEnd },
        {
          start: segmentWordStart,
          end: segmentWordEnd,
        }
      );
      sendGAEvent("clip_end_here", "call_review");
      return {
        start: safeStart,
        play: Math.min(clipPlayRange.play, safeEnd),
        end: safeEnd,
      };
    });
  }, [segmentWordStart, segmentWordEnd, setPlayRange]);

  if (!readyToRender) {
    return null;
  }

  return (
    <Box
      position="relative"
      py="3"
      fontSize="sm"
      pt="12px"
      pb="12px"
      role="group"
      ref={ref}
      color={inactive ? "gray.500" : ""}
      data-testid={`clip-segment-${segmentIndex}`}
    >
      <Flex
        mb="2"
        pointerEvents={dragging ? "none" : undefined}
        userSelect="none"
      >
        <Speaker
          speakerLabel={speakerLabel}
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          maxWidth="120px"
        />
        <Timestamp
          mr="3"
          mt="-1px"
          minW={player.duration || 0 >= 3600 ? "56px" : "40px"}
        >
          {inactive ? (
            <Text color="gray.500">
              <MonospacedText
                text={formatDuration(Math.round(segmentWordStart))}
              />
            </Text>
          ) : (
            <Link
              onClick={() => seekAndPlay(segmentWordStart)}
              color="blue.600"
            >
              <MonospacedText
                text={formatDuration(Math.round(segmentWordStart))}
              />
            </Link>
          )}
        </Timestamp>
        <Flex
          marginLeft="auto"
          color="blue.600"
          fontSize="xs"
          fontWeight="700"
          cursor="pointer"
          display="none"
          _groupHover={{ display: "flex" }}
        >
          <Flex
            alignItems="center"
            _hover={{ bg: "blue.50", borderRadius: "6px" }}
            px="10px"
            py="6px"
            mt="-9px"
            mb="-6px"
            onClick={onClickStartHere}
          >
            <IoCut size="16" />
            <Box ml="2">
              Start <TimestampText time={segmentWordStart} />
            </Box>
          </Flex>
          <Flex
            alignItems="center"
            ml="2"
            _hover={{ bg: "blue.50", borderRadius: "6px" }}
            px="10px"
            py="6px"
            mt="-9px"
            mb="-6px"
            onClick={onClickEndHere}
          >
            <IoCut size="16" />
            <Box ml="2">
              End <TimestampText time={segmentWordEnd} />
            </Box>
          </Flex>
        </Flex>
      </Flex>
      {segment.words.map((word, index) => {
        // Calculate word state externally from ClipWord so ClipWord can be memoized in more cases
        const activeWord =
          playRange !== undefined &&
          between(playRange.play, word.startTime, word.endTime);
        // segmentDefaultIncluded is a workaround for some of the optimized prop hiding we do
        // so segments that don't need to rerender are not given irrelevant prop changes.
        // The way to detect a segment that is entirely within the clip bounds, but is not
        // one of the special segments that gets the actual playRange (the first, last, and playing segment),
        // is the combination of (1) that it is not inactive, and (2) that it didn't receive a playRange.
        const segmentDefaultIncluded = !inactive && !playRange;
        const wordIsInClip =
          (playRange !== undefined &&
            between(word.startTime, playRange.start, playRange.end)) ||
          segmentDefaultIncluded;
        let wordState: WordState = wordIsInClip ? "in-clip" : "out-of-clip";
        wordState = activeWord ? "active-word" : wordState;

        return (
          <ClipWord
            // Words in a segment never change, index is fine, and we don't have the id anyways
            // eslint-disable-next-line react/no-array-index-key
            key={`${segmentIndex}-${index}`}
            word={word.word}
            wordStartTime={word.startTime}
            wordEndTime={word.endTime}
            seekAndPlay={seekAndPlay}
            scrollTo={scrollTo}
            state={wordState}
            onMouseOver={onMouseOverWord}
            onSelectionEdgeDragStart={onSelectionEdgeDragStart}
            // The dragging prop is only used for the currently hovered segment,
            // so avoid pointless rerenders on `dragging` change
            dragging={hover ? dragging : false}
            selectionFirstWord={word === selectionStartWord}
            selectionLastWord={word === selectionEndWord}
            activeBrowserSelection={activeBrowserSelection}
          />
        );
      })}
    </Box>
  );
};

const TimestampText: React.FC<{ time: number }> = ({ time }) => {
  return (
    <Timestamp display="inline" color="blue.600">
      <MonospacedText text={formatDuration(Math.round(time))} />
    </Timestamp>
  );
};

export default React.memo(ClipSegment);
