import "./legend.css";

import { Flex, Text } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import {
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useTheme } from "../../../../components";
import colorVars from "../../../../theme/css-color-variables";
import { ReportConfig } from "../../../graphql";
import { BenchmarkLabel } from "../BenchmarkLabel";
import { dataVizColorRangesBright } from "../chart/segmentPresentation";
import { LineChartDataPoint, TopicLineChartDataPoint } from "../types";
import MyAnalyticsTooltip from "./MyAnalyticsTooltip";
import ReportTooltip from "./ReportTooltip";
import { xFormatter } from "./xFormatter";

export const bucketFormatter = (
  seconds: number,
  bucketInterval?: string,
  bucketSize?: number
): string => {
  const bucketPrefix = bucketInterval === "WEEK" ? "Week of " : "";
  return `${bucketPrefix} ${xFormatter(seconds, bucketInterval, bucketSize)}`;
};

type CartesianViewBox = {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
};

export type AnalyticsLineChartProps = {
  data: LineChartDataPoint[] | TopicLineChartDataPoint[];
  rangeDisplayValues: {
    previous: string;
    current: string;
  };
  columnsConfig?: ReportConfig[];
  bucketInterval?: string;
  bucketSize?: number;
  dataKey: string;
  compareDataKey?: string;
  yAxis?: {
    domain?: [number | string, number | string];
    ticks?: number[];
    formatter?: (value: number) => string;
    tickCount?: number;
  };
  tooltipVersion?: "report" | "myInsights";
  tooltipFooterText?: string; // Note: only used in myInsights tooltip
  tooltipFooterFormatter?: (datum: any) => string;
  benchmarkRange?: {
    upperBound: number;
    lowerBound: number;
  };
  benchmarkFormatter?: (value: number) => string;
  line?: {
    label: string;
    value: number;
  };
  showComparisonLine?: boolean;
  toggleComparisonLine?: () => void;
  aspect?: number;
  labelReferenceInLegend?: boolean;
  customActiveDot?: any;
  drawDot: boolean;
};

const AnalyticsLineChart: React.FC<AnalyticsLineChartProps> = ({
  data,
  rangeDisplayValues,
  columnsConfig,
  bucketInterval,
  bucketSize,
  dataKey,
  compareDataKey,
  yAxis,
  tooltipVersion = "report",
  tooltipFooterText,
  benchmarkRange,
  benchmarkFormatter = (value) => value.toString(),
  line,
  showComparisonLine = true,
  toggleComparisonLine,
  aspect = 2.75,
  labelReferenceInLegend,
  customActiveDot,
  drawDot,
  tooltipFooterFormatter,
}) => {
  const tickProps = {
    tick: { fontSize: "0.75rem", fill: colorVars.gray[600] },
    tickLine: false,
    tickMargin: 8,
  };

  const theme = useTheme();
  const { colors } = theme;
  const { blueBolt } = dataVizColorRangesBright;

  const renderLegendText = (value: string, entry: any): ReactNode => {
    let { color } = entry;
    const legendLabelMap = {
      [dataKey]: rangeDisplayValues.current,
      referenceLine: line?.label,
    };
    if (compareDataKey) {
      legendLabelMap[compareDataKey] = rangeDisplayValues.previous;
    }
    if (benchmarkRange) {
      legendLabelMap.benchmarkRange = "Benchmark";
    }

    let suffix = " (Click to hide)";
    if (entry.inactive) {
      color = "gray.300";
      suffix = " (Click to show) ";
    }
    const canToggle = entry.dataKey === compareDataKey && toggleComparisonLine;
    return (
      <Text
        as="span"
        color={color}
        fontSize="xs"
        pr="4"
        cursor={canToggle ? "pointer" : undefined}
        _hover={{
          color: canToggle ? "blue.600" : undefined,
        }}
      >
        {legendLabelMap[value]}
        {canToggle && suffix}
      </Text>
    );
  };

  return (
    <ResponsiveContainer width="99%" aspect={aspect} className="reverse-legend">
      <LineChart data={data} margin={{ top: 8, right: 8, left: 8, bottom: 16 }}>
        {benchmarkRange && (
          <>
            <ReferenceArea
              y1={benchmarkRange.lowerBound}
              y2={benchmarkRange.upperBound}
              strokeOpacity={0}
              fill={colors.gray[100]}
              fillOpacity={1}
              ifOverflow="extendDomain"
            >
              {!labelReferenceInLegend && (
                <Label
                  content={(props) => (
                    <LineChartBenchmarkLabel
                      formattedLowerBound={benchmarkFormatter(
                        benchmarkRange.lowerBound
                      )}
                      formattedUpperBound={benchmarkFormatter(
                        benchmarkRange.upperBound
                      )}
                      viewBox={props.viewBox as CartesianViewBox}
                    />
                  )}
                />
              )}
            </ReferenceArea>
            {labelReferenceInLegend && (
              <Line
                dataKey="benchmarkRange"
                stroke={colors.gray[300]}
                isAnimationActive={false}
                hide={!labelReferenceInLegend}
              />
            )}
          </>
        )}
        {line && (
          <>
            <ReferenceLine
              y={line.value}
              stroke={colors.green[500]}
              strokeWidth={2}
              strokeDasharray="4 4"
              transform="translate(0, -1)"
            />
            <Line
              dataKey="referenceLine"
              stroke={colors.green[500]}
              isAnimationActive={false}
            />
          </>
        )}
        <XAxis
          dataKey="x"
          type="number"
          domain={["dataMin", "dataMax"]}
          tickFormatter={(value) =>
            bucketFormatter(value, bucketInterval, bucketSize)
          }
          scale="time"
          stroke={colorVars.gray[200]}
          {...tickProps}
        />
        <YAxis
          type="number"
          domain={yAxis?.domain}
          ticks={yAxis?.ticks}
          stroke={colorVars.gray[200]}
          tickFormatter={yAxis?.formatter}
          {...tickProps}
        />
        <Tooltip
          content={(props) =>
            tooltipVersion === "report" && columnsConfig ? (
              <ReportTooltip
                columnsConfig={columnsConfig}
                bucketInterval={bucketInterval}
                bucketSize={bucketSize}
                {...props}
              />
            ) : (
              <MyAnalyticsTooltip
                bucketInterval={bucketInterval}
                bucketSize={bucketSize}
                dataKey={dataKey}
                compareDataKey={showComparisonLine ? compareDataKey : undefined}
                dataColor={blueBolt[700]}
                compareDataColor={blueBolt[300]}
                valueFormatter={
                  yAxis?.formatter || ((value) => value.toString())
                }
                footerText={tooltipFooterText}
                footerFormatter={tooltipFooterFormatter}
                {...props}
              />
            )
          }
        />
        <Legend
          formatter={renderLegendText}
          wrapperStyle={{ left: 8, bottom: 0 }}
          onClick={(e) => {
            if (e.dataKey === compareDataKey && toggleComparisonLine) {
              toggleComparisonLine();
            }
          }}
        />
        {compareDataKey && (
          <Line
            key={Math.random()} // force redraw to ensure line dots render
            strokeDasharray="4 4"
            connectNulls
            dataKey={compareDataKey}
            stroke={blueBolt[300]}
            strokeWidth={2}
            dot={drawDot}
            isAnimationActive={false}
            hide={!showComparisonLine}
          />
        )}
        <Line
          key={Math.random()} // force redraw to ensure line dots render
          connectNulls
          dataKey={dataKey}
          stroke={blueBolt[700]}
          strokeWidth={3}
          dot={drawDot}
          isAnimationActive={false}
          activeDot={customActiveDot}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const LineChartBenchmarkLabel: React.FC<{
  formattedLowerBound: string;
  formattedUpperBound: string;
  viewBox?: CartesianViewBox;
}> = ({ formattedLowerBound, formattedUpperBound, viewBox }) => {
  if (!viewBox) return null;

  return (
    <foreignObject {...viewBox} overflow="visible">
      <Flex w="100%" h="100%" justify="end">
        <BenchmarkLabel
          formattedLowerBound={formattedLowerBound}
          formattedUpperBound={formattedUpperBound}
          direction="row"
          pr="1"
          pt="1"
        />
      </Flex>
    </foreignObject>
  );
};

export default AnalyticsLineChart;
