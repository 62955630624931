import { SIGN_UP_ROUTES } from "../freemium-sign-up/routes";
import {
  CurrentUserFragment,
  useSignOutMutation,
  useUnimpersonateMutation,
} from "../graphql";

const useSignOut = ({
  currentUser,
  returnTo = "/sign-in",
}: {
  currentUser?: Pick<CurrentUserFragment, "isImpersonated"> | null;
  returnTo?: "/sign-in" | typeof SIGN_UP_ROUTES["START"];
} = {}): (() => void) => {
  const [signOut] = useSignOutMutation({
    onCompleted: () => {
      window.location.href = returnTo;
    },
  });
  const [unimpersonate] = useUnimpersonateMutation({
    onCompleted: (data) => {
      window.location.href = data.unimpersonate?.redirectTo ?? "/admin/users";
    },
    onError: () => {
      window.location.href = "/";
    },
  });

  return () => {
    if (currentUser?.isImpersonated) {
      unimpersonate();
      return;
    }
    signOut();
  };
};

export default useSignOut;
