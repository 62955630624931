import React from "react";

import { LoadingIndicator } from "../../../../../../../components";
import { CallBetaFragment } from "../../../../../../graphql";
import { AiNotesGenericError } from "../../../../../AiNotes/AiNotesErrors";
import AiNotesMessage from "./AiNotesMessage";
import AiNotesQuestionBlocks from "./AiNotesQuestionBlocks";
import { AiNotesReturn } from "./useAiNotes";

type AiNotesProps = AiNotesReturn & {
  call: Pick<CallBetaFragment, "id" | "canEdit">;
  onClickTimestamp(t: number): void;
};

const AiNotes: React.FC<AiNotesProps> = ({
  call,
  data,
  regenerate,
  copyAiNotes,
  updateAiNote,
  updateAiNoteQuestion,
  onClickTimestamp,
  createAiNoteTags,
}) => {
  const { aiNotes: questionsAndAnswers, loading, error, status } = data;
  return (
    <>
      {loading && (
        <AiNotesMessage>
          <LoadingIndicator />
        </AiNotesMessage>
      )}
      {!loading && error && <AiNotesGenericError />}
      {!loading && !error && status && questionsAndAnswers && (
        <AiNotesQuestionBlocks
          status={status}
          questionsAndAnswers={questionsAndAnswers}
          regenerate={regenerate}
          copyNotes={copyAiNotes}
          callId={call.id}
          canEdit={call.canEdit}
          updateAiNote={updateAiNote}
          updateAiNoteQuestion={updateAiNoteQuestion}
          onClickTimestamp={onClickTimestamp}
          createAiNoteTags={createAiNoteTags}
        />
      )}
    </>
  );
};

export default AiNotes;
