import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";

import { Button } from "../../../components";

interface RemoveIntegrationButtonProps {
  name: string;
  remove: () => void;
  isLoading?: boolean;
}

const RemoveIntegrationButton: React.FC<RemoveIntegrationButtonProps> = ({
  name,
  remove,
  isLoading,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);

  return (
    <>
      <Button
        variant="dangerOutline"
        size="xs"
        onClick={onOpen}
        isLoading={isLoading}
      >
        Remove
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Remove {name}
            </AlertDialogHeader>
            <AlertDialogBody>Are you sure?</AlertDialogBody>
            <AlertDialogFooter>
              <Button
                variant="outline"
                size="sm"
                ref={cancelRef}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                size="sm"
                isLoading={isLoading}
                onClick={() => {
                  remove();
                  onClose();
                }}
                ml={3}
              >
                Remove
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default RemoveIntegrationButton;
