import React, { useCallback, useEffect, useState } from "react";

import { LoadingIndicator } from "../../../components";
import { startTour } from "../../../utils/intercom";
import {
  useExternalUserViewCallMutation,
  useExternalUserViewClipMutation,
} from "../../graphql";
import { useExternalShareParams } from "../../pages/external-share/useExternalShareParams";
import { VideoDisplayMode } from "../Interview/types";
import useMediaPlayer from "../Interview/useMediaPlayer";
import VideoBeta from "../Interview/VideoBeta";
import { DetailsHeader, RecordingLayout, Transcript } from "../Recording";
import useVideoState from "../Video/useVideoState";
import ExternalRecordingSidebar, {
  ExternalRecordingTab,
} from "./ExternalRecordingSidebar";
import { ExternalRecording, isExternalUserViewCall } from "./types";

export type ExternalRecordingViewProps = {
  recording: ExternalRecording;
  isClip?: boolean;
  loading: boolean;
};

const ExternalRecordingView: React.FC<ExternalRecordingViewProps> = ({
  recording,
  isClip,
  loading,
}) => {
  const { shareId, shareType } = useExternalShareParams();

  const viewMutation = isClip
    ? useExternalUserViewClipMutation
    : useExternalUserViewCallMutation;
  const [viewRecording] = viewMutation({
    onCompleted: (data) => {
      const firstView = isExternalUserViewCall(data)
        ? data.externalUserViewCall?.firstExternalView
        : data.externalUserViewClip?.firstExternalView;
      if (firstView) {
        startTour(375437);
      }
    },
  });

  useEffect(() => {
    if (shareType !== "playlist") {
      viewRecording({ variables: { externalShareId: shareId } });
    }
  }, [shareType]);

  const mediaSrc =
    recording.streamableVideo?.url ?? recording.streamableAudio?.url;

  // Set up the media player & video
  const [mediaPlayerRef, listeners, player] = useMediaPlayer();

  // const { seek, time, play } = player;
  const { seek, play } = player;
  const seekAndPlay = useCallback(
    (t: number): void => {
      seek(t);
      play();
    },
    [seek]
  );

  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);

  const [currentTab, setCurrentTab] = useState(ExternalRecordingTab.AI_NOTES);

  let videoDisplayMode = VideoDisplayMode.NO_RECORDING;
  if (recording?.streamableAudio && !recording?.streamableVideo) {
    videoDisplayMode = VideoDisplayMode.AUDIO;
  } else if (recording?.streamableVideo) {
    videoDisplayMode = VideoDisplayMode.VIDEO;
  }

  const {
    containerRef,
    videoCallbackRef,
    fullScreenControls,
    pipControls,
    isVideoVisible,
    hideVideo,
    focusVideo,
  } = useVideoState(videoDisplayMode);

  if (loading) {
    return <LoadingIndicator h="80%" />;
  }

  return (
    <RecordingLayout
      header={
        <DetailsHeader
          candidate={recording.candidate}
          position={recording.position}
          title={recording.name}
          interviewers={recording.interviewers}
          date={recording.endTime}
          canEdit={false}
        />
      }
      video={
        <VideoBeta
          mediaPlayerRef={mediaPlayerRef}
          listeners={listeners}
          videoDisplayMode={videoDisplayMode}
          mediaSrc={mediaSrc}
          onSeek={seekAndPlay}
          duration={recording.duration}
          player={player}
          speakers={recording.speakers}
          notes={[]}
          segments={recording.transcript}
          isVideoVisible={isVideoVisible}
          containerRef={containerRef}
          videoRef={videoCallbackRef}
          hideVideo={hideVideo}
          fullScreenControls={fullScreenControls}
          pipControls={pipControls}
          isActionBarEnabled={false}
        />
      }
      transcript={
        <Transcript
          h="100%"
          transcript={recording.transcript}
          speakers={recording.speakers}
          player={player}
          autoScrollEnabled={autoScrollEnabled}
          setAutoScrollEnabled={setAutoScrollEnabled}
          isVideoCall={videoDisplayMode === VideoDisplayMode.VIDEO}
          isVideoVisible={isVideoVisible}
          hideVideo={hideVideo}
          focusVideo={focusVideo}
          isCopyEnabled={false}
          loading={false}
        />
      }
      sidebar={
        <ExternalRecordingSidebar
          recording={recording}
          currentTab={currentTab}
          onTabChange={setCurrentTab}
          player={player}
          onSeek={seekAndPlay}
          autoScrollEnabled={autoScrollEnabled}
          setAutoScrollEnabled={setAutoScrollEnabled}
          isVideoCall={videoDisplayMode === VideoDisplayMode.VIDEO}
          isVideoVisible={isVideoVisible}
          hideVideo={hideVideo}
          focusVideo={focusVideo}
        />
      }
    />
  );
};

export default ExternalRecordingView;
