import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

import { Alert, Button, useToast } from "../../../components";
import {
  CurrentUserFragment,
  useUpdateOrganizationVideoSettingsMutation,
} from "../../graphql";
import SettingsNote from "./shared/SettingsNote";
import SettingsPageContainer from "./shared/SettingsPageContainer";

interface AtsSettingsProps {
  currentUser: CurrentUserFragment;
}

export interface FormData {
  maximumCallDurationForNotetakerToRejoin: number;
}

const AtsSettings: React.FC<AtsSettingsProps> = ({ currentUser }) => {
  const toast = useToast();
  const [updateOrganizationSettings, { loading, error }] =
    useUpdateOrganizationVideoSettingsMutation({
      onCompleted: (data) => {
        if (data?.updateOrganizationVideoSettings?.currentUser) {
          toast({
            status: "success",
            title: "Organization Settings",
            description: "Success!",
          });
        }
      },
      onError: () => {
        toast({
          status: "error",
          title: "Organization Settings",
          description: "There was a problem - please try again",
        });
      },
    });

  const { maximumCallDurationForNotetakerToRejoin } = currentUser.organization;

  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      maximumCallDurationForNotetakerToRejoin:
        maximumCallDurationForNotetakerToRejoin
          ? maximumCallDurationForNotetakerToRejoin / 60
          : undefined,
    },
  });

  return (
    <SettingsPageContainer heading="Video settings">
      <form
        autoComplete="on"
        onSubmit={handleSubmit((formData) => {
          updateOrganizationSettings({ variables: formData });
        })}
      >
        {error?.graphQLErrors?.map(({ message }, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Alert key={i} status="error" description={message} />
        ))}
        <VStack spacing="8" align="start">
          <FormControl id="maximumCallDurationForNotetakerToRejoin">
            <FormLabel fontSize="lg">
              Prevent Notetaker Rejoin - Minimum Interview Duration
            </FormLabel>
            <FormHelperText as={SettingsNote} mb="4">
              BrightHire automatically attempts to rejoin Google Meet, Zoom, and
              Teams meetings if they end over 5 minutes before the scheduled end
              time. It also considers the interview duration to decide if
              rejoining is necessary. Use this input to override the default
              behavior and rejoin only if interviews are shorter than the
              specified duration (in minutes).
              {/* Duration converted to seconds in the backend */}
            </FormHelperText>
            <Input
              {...register("maximumCallDurationForNotetakerToRejoin", {
                valueAsNumber: true,
              })}
              type="number"
              min="0"
              max="60"
              placeholder="minutes"
            />
          </FormControl>
          <Button type="submit" isLoading={loading} data-testid="save">
            Save
          </Button>
        </VStack>
      </form>
    </SettingsPageContainer>
  );
};

export default AtsSettings;
