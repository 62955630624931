import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import LogRocket from "logrocket";
import React, { useEffect, useState } from "react";
import { HiOutlineSearch, HiXCircle } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";

import {
  Button,
  ExtendedLoadingMessage,
  Link,
  LoadingIndicator,
} from "../../../../components";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  ChaptersProcessingStatus,
  useCandidateCompareCandidatesQuery,
  useCandidatePositionChaptersQuery,
} from "../../../graphql";
import { useFeatureFlagForCurrentOrImpersonatedUser } from "../../../graphql/hooks/useFeatureFlag";
import CandidateCompareMatchesList from "./CandidateCompareMatchesList";
import CandidateCompareSelector from "./CandidateCompareSelector";
import CandidateMatchCompareColumn from "./CandidateMatchCompareColumn";
import { NoCandidatesToCompare, NoChaptersToCompare } from "./Icons";
import { CandidateCompareListItemFragment } from "./types";
import { useCompareTabParams } from "./useCompareTabParams";

type CompareTabProps = {
  candidateId: string;
  positionId?: string;
  candidateName: string;
};

/**
 * Wrapper for selecting a candidate to compare and showing the comparison.
 */
const CompareTab: React.FC<CompareTabProps> = ({
  candidateId,
  positionId,
  candidateName,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const sendGAEvent = useSendGAEvent();
  const compareIdParam = searchParams.get("compareToCandidateId") || "";
  const [compareCandidateId, setCompareCandidateId] =
    useState<string>(compareIdParam);

  const setCompareCandidate = (id: string): void => {
    setCompareCandidateId(id);
    searchParams.set("compareToCandidateId", id);
    setSearchParams(searchParams, { replace: true });
  };

  const positionQuery = useCandidateCompareCandidatesQuery({
    variables: { id: positionId || "" },
    skip: !positionId,
  });
  const candidatesLoading = positionQuery.loading;

  const candidates = positionQuery.data?.position?.candidates.filter(
    (c) =>
      c.callCount !== null &&
      c.callCount !== undefined &&
      c.callCount >= 0 &&
      c.id !== candidateId
  ) as CandidateCompareListItemFragment[];

  const compareCandidateName =
    candidates?.find((c) => c.id === compareCandidateId)?.fullName ||
    "Candidate 2";

  useEffect(() => {
    const noCandidatesAvailableShown =
      !!positionId &&
      !compareCandidateId &&
      !candidatesLoading &&
      !candidates?.length;

    if (noCandidatesAvailableShown) {
      sendGAEvent(
        "Off happy-path",
        "candidate_compare",
        "No candidates available"
      );
    }
  }, [positionId, compareCandidateId, candidatesLoading, candidates?.length]);

  if (!positionId) {
    return (
      <Box bg="#F5F6F780" mt="5">
        <Flex maxW="470px" direction="column" textAlign="center" m="auto">
          <NoCandidatesToCompare mt="20" />
          <Text color="gray.900" fontSize="2xl" fontWeight="semibold" mt="10">
            Unable to compare candidates
          </Text>
          <Text color="gray.600" fontSize="sm" mt="4">
            This candidate does not have an assigned position. In order to
            compare this candidate with others, select a position for this
            candidate.
          </Text>
        </Flex>
      </Box>
    );
  }

  if (!compareCandidateId) {
    if (candidatesLoading) {
      return <LoadingIndicator />;
    }

    if (!candidates?.length) {
      return (
        <Box bg="#F5F6F780" mt="5">
          <Flex maxW="470px" direction="column" textAlign="center" m="auto">
            <NoCandidatesToCompare mt="20" />
            <Text color="gray.900" fontSize="2xl" fontWeight="semibold" mt="10">
              No candidates to compare with — yet.
            </Text>
            <Text color="gray.600" fontSize="sm" mt="4">
              Come back when there are interviews with highlights for other
              candidates for the same position. You’ll be able to compare their
              highlights side by side.
            </Text>
          </Flex>
        </Box>
      );
    }

    return (
      <Flex bg="#EAF9FF4D" minHeight="500" mt={5} justifyContent="center">
        <VStack spacing={4} mt={16} width="420px" alignItems="center">
          <Text color="gray.900" fontSize="2xl" fontWeight="600">
            Find a candidate to compare with
          </Text>
          <Text
            color="gray.600"
            fontSize="sm"
            fontWeight="400"
            textAlign="center"
          >
            Find another candidate (for the same position), and we’ll display
            AI-matched highlights side-by-side for comparison.
          </Text>
          <Box pt={4}>
            <CandidateCompareSelector
              candidates={candidates}
              compareCandidateId={compareCandidateId}
              onCandidateSelect={setCompareCandidate}
              onFocus={() =>
                LogRocket.track("candidate-compare-selector-first")
              }
            />
          </Box>
        </VStack>
      </Flex>
    );
  }

  return (
    <CompareTabContent
      {...{
        candidateId,
        compareCandidateId,
        positionId,
        candidateName,
        onCandidateSelect: setCompareCandidate,
        candidates,
        compareCandidateName,
      }}
    />
  );
};

type CompareTabContentProps = {
  candidateId: string;
  candidateName: string;
  compareCandidateName: string;
  compareCandidateId: string;
  positionId: string;
  candidates?: CandidateCompareListItemFragment[];
  onCandidateSelect(id: string): void;
};

/**
 * Given selected candidates, compare them.
 */
const CompareTabContent: React.FC<CompareTabContentProps> = ({
  candidateId,
  compareCandidateId,
  positionId,
  candidateName,
  compareCandidateName,
  onCandidateSelect,
  candidates,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const sendGAEvent = useSendGAEvent();
  const [shouldPauseVideo1, setShouldPauseVideo1] = useState<boolean>(false);
  const [shouldPauseVideo2, setShouldPauseVideo2] = useState<boolean>(false);
  const [useV2Matching, setUseV2Matching] = useState<boolean>(false);
  const [v1Threshold, setV1Threshold] = useState<number>(87);
  const [v2Threshold, setV2Threshold] = useState<number>(80);
  const [aspectRatio, setAspectRatio] = useState<number>();
  const onAspectRatio = (ar: number): void => {
    if (aspectRatio === undefined || ar > aspectRatio) {
      setAspectRatio(ar);
    }
  };
  const [isSelectingCandidate, setIsSelectingCandidate] =
    useState<boolean>(false);

  // Get chapters matches for candidate
  const { data: candidateChaptersData, ...candidateChaptersQuery } =
    useCandidatePositionChaptersQuery({
      variables: { candidateId, positionId },
      pollInterval: 3000,
    });

  // Get chapters matches for comparison candidate
  const {
    data: compareCandidateChaptersData,
    ...compareCandidateChaptersQuery
  } = useCandidatePositionChaptersQuery({
    variables: { candidateId: compareCandidateId, positionId },
    pollInterval: 3000,
  });

  const candidateChapters =
    candidateChaptersData?.candidatePositionChapters.chapters || [];
  const compareCandidateChapters =
    compareCandidateChaptersData?.candidatePositionChapters.chapters || [];

  if (
    candidateChaptersData?.candidatePositionChapters.candidateChaptersStatus ===
    ChaptersProcessingStatus.Completed
  ) {
    candidateChaptersQuery.stopPolling();
  }
  if (
    compareCandidateChaptersData?.candidatePositionChapters
      .candidateChaptersStatus === ChaptersProcessingStatus.Completed
  ) {
    compareCandidateChaptersQuery.stopPolling();
  }
  const areChaptersBeingGenerated =
    candidateChaptersData?.candidatePositionChapters.candidateChaptersStatus ===
      ChaptersProcessingStatus.InProgress ||
    compareCandidateChaptersData?.candidatePositionChapters
      .candidateChaptersStatus === ChaptersProcessingStatus.InProgress;

  const currentThreshold = (useV2Matching ? v2Threshold : v1Threshold) / 100;
  const { matchID, setActiveMatchID, thresholdMatches, matchingChapters } =
    useCompareTabParams(
      candidateChapters,
      compareCandidateChapters,
      useV2Matching,
      currentThreshold
    );

  useEffect(() => {
    if (thresholdMatches.length !== 0 && thresholdMatches.length < 5) {
      sendGAEvent(
        "low_threshold_matches_count",
        "candidate_compare",
        `${thresholdMatches.length}`,
        undefined,
        {
          thresholdMatchesCount: thresholdMatches.length,
          candidateId,
          compareCandidateId,
        }
      );
    }
  }, [thresholdMatches]);

  const showLoading =
    candidateChaptersQuery.loading ||
    compareCandidateChaptersQuery.loading ||
    areChaptersBeingGenerated;

  const showError =
    candidateChaptersQuery.error || compareCandidateChaptersQuery.error;

  const noMatches = matchingChapters.length === 0;

  const showDebugControls = useFeatureFlagForCurrentOrImpersonatedUser(
    "candidate_compare:v2:matching"
  );

  useEffect(() => {
    if (!showLoading) {
      if (showError) {
        sendGAEvent(
          "Off happy-path",
          "candidate_compare",
          "Error generating chapters",
          undefined,
          {
            candidateChaptersQuery: candidateChaptersQuery.error?.message,
            compareCandidateChaptersQuery:
              compareCandidateChaptersQuery.error?.message,
          }
        );
      } else if (noMatches) {
        sendGAEvent(
          "Off happy-path",
          "candidate_compare",
          "No chapter matches"
        );
      }
    } else {
      LogRocket.track("candidate-compare-loading");
    }
  }, [showLoading, showError, noMatches]);

  if (showLoading) {
    return (
      <ExtendedLoadingMessage
        mt="20"
        text={
          areChaptersBeingGenerated
            ? ["Generating AI notes"]
            : [
                "Selecting candidate",
                "Distilling highlights",
                "Matching notes and questions",
                "Generating comparison",
              ]
        }
      />
    );
  }

  if (showError || noMatches) {
    return (
      <Box bg="#FFFCEE80" mt="5">
        <Flex maxW="470px" direction="column" textAlign="center" m="auto">
          <Text color="gray.900" fontSize="2xl" fontWeight="semibold" mt="20">
            {showError
              ? "Something went wrong"
              : "Unable to compare candidates"}
          </Text>
          <Text color="gray.600" fontSize="sm" my="4">
            {showError &&
              `We are unable to compare these two candidates. Try again later.`}
            {!showError && (
              <span>
                <Link
                  href={`/candidate/${compareCandidateId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  fontSize="sm"
                  fontWeight="unset"
                >
                  {compareCandidateName}
                </Link>
                ’s interviews do not contain highlights that could be matched
                (based on relevancy) to this candidate.
              </span>
            )}
          </Text>
          <Button
            variant="solid"
            alignSelf="center"
            size="md"
            onClick={() => onCandidateSelect("")}
            height="unset"
            borderRadius={6}
            fontWeight="500"
            _hover={{
              background: "blue.500",
            }}
            py="2"
          >
            Choose a different candidate
          </Button>

          <NoChaptersToCompare mt="10" />
        </Flex>
      </Box>
    );
  }

  const filteredMatches = searchQuery
    ? thresholdMatches.filter((match) =>
        `${match.chapter1.text} ${match.chapter2.text}`
          .toLocaleLowerCase()
          .includes(searchQuery.toLocaleLowerCase())
      )
    : thresholdMatches;
  const activeMatch =
    thresholdMatches.find((match) => match.id === matchID) ||
    matchingChapters[0];

  return (
    <Grid
      templateColumns={{ base: "1fr", md: "minmax(340px, 1fr) 3fr" }}
      mt={7}
    >
      <GridItem mr={2} pr="6" overflowY="auto" h="0" minH="100%">
        <InputGroup data-tour-id="candidate-compare-matches-search">
          <InputLeftElement pointerEvents="none" color="gray.500">
            <HiOutlineSearch size="16px" />
          </InputLeftElement>
          <Input
            placeholder={`Search ${thresholdMatches.length} highlight pairs`}
            borderColor="gray.200"
            borderRadius="2px"
            borderWidth="1px"
            fontSize="sm"
            color="gray.600"
            value={searchQuery}
            onFocus={() => {
              sendGAEvent(
                "Highlight pair search",
                "candidate_compare",
                "click"
              );
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSearchQuery(event.currentTarget.value);
              sendGAEvent(
                "Highlight pair search",
                "candidate_compare",
                "query",
                event.currentTarget.value
              );
            }}
          />

          <InputRightElement
            color="gray.500"
            visibility={searchQuery ? "visible" : "hidden"}
          >
            <Icon
              as={HiXCircle}
              boxSize="4"
              onClick={() => setSearchQuery("")}
            />
          </InputRightElement>
        </InputGroup>
        <Divider my={6} />
        {showDebugControls && (
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            pb={6}
          >
            <InputGroup size="sm" maxWidth="40%">
              <Input
                value={useV2Matching ? v2Threshold : v1Threshold}
                type="number"
                onChange={(e) => {
                  if (useV2Matching) {
                    setV2Threshold(parseInt(e.currentTarget.value));
                  } else {
                    setV1Threshold(parseInt(e.currentTarget.value));
                  }
                }}
                placeholder="Threshold"
              />
              <InputRightAddon>%</InputRightAddon>
            </InputGroup>
            <Flex alignItems="center">
              <Button
                borderTopRightRadius={0}
                borderBottomRightRadius={0}
                onClick={() => setUseV2Matching(!useV2Matching)}
                disabled={!useV2Matching}
                variant="toggle"
                size="sm"
                textTransform="unset"
              >
                v1
              </Button>
              <Button
                borderTopLeftRadius={0}
                borderBottomLeftRadius={0}
                onClick={() => setUseV2Matching(!useV2Matching)}
                disabled={useV2Matching}
                variant="toggle"
                size="sm"
                textTransform="unset"
              >
                v2
              </Button>
            </Flex>
          </Flex>
        )}
        <CandidateCompareMatchesList
          filteredMatches={filteredMatches}
          activeMatch={activeMatch}
          onMatchSelect={(id) => {
            setActiveMatchID(id);
            sendGAEvent("highlight_pair_click", "candidate_compare");
          }}
        />
      </GridItem>
      <Grid
        templateColumns="repeat(2, minmax(0, 1fr))"
        columnGap="8"
        data-tour-id="candidate-compare-match-content"
      >
        <GridItem>
          <Text
            color="gray.800"
            bg="gray.50"
            borderRadius={8}
            size="lg"
            mb={6}
            fontWeight="500"
            lineHeight="24px"
            p="4"
          >
            {candidateName}
          </Text>

          <CandidateMatchCompareColumn
            key={activeMatch.chapter1.id}
            chapter={activeMatch.chapter1}
            shouldPauseVideo={shouldPauseVideo1}
            aspectRatio={aspectRatio}
            onAspectRatio={onAspectRatio}
            setShouldPauseOtherVideo={() => {
              setShouldPauseVideo1(false);
              setShouldPauseVideo2(true);
            }}
          />
        </GridItem>
        <GridItem>
          <Flex
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            bg="gray.50"
            borderRadius={8}
            lineHeight="24px"
            px="4"
            py={isSelectingCandidate ? "2" : "3"}
            mb={6}
            fontWeight="500"
          >
            {!isSelectingCandidate && (
              <>
                <Link
                  href={`/candidate/${compareCandidateId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="lg"
                  fontWeight="unset"
                >
                  {compareCandidateName}
                </Link>
                <Button
                  variant="solid"
                  size="md"
                  onClick={() => {
                    setIsSelectingCandidate(true);
                    sendGAEvent(
                      "Change compare candidate",
                      "candidate_compare",
                      "start"
                    );
                  }}
                  m={0}
                  height="unset"
                  borderRadius={6}
                  fontWeight="500"
                  _hover={{
                    background: "blue.500",
                  }}
                  py="2"
                  data-tour-id="candidate-compare-change-candidate"
                >
                  Change
                </Button>
              </>
            )}
            {isSelectingCandidate && (
              <CandidateCompareSelector
                candidates={candidates}
                compareCandidateId=""
                shouldSelectCandidateImmediately
                onCandidateSelect={(id) => {
                  setIsSelectingCandidate(false);
                  onCandidateSelect(id);
                  sendGAEvent(
                    "Change compare candidate",
                    "candidate_compare",
                    "end"
                  );
                }}
                onBlur={() => setIsSelectingCandidate(false)}
                onFocus={() =>
                  LogRocket.track("candidate-compare-selector-change")
                }
              />
            )}
          </Flex>

          <CandidateMatchCompareColumn
            key={activeMatch.chapter2.id}
            chapter={activeMatch.chapter2}
            shouldPauseVideo={shouldPauseVideo2}
            aspectRatio={aspectRatio}
            onAspectRatio={onAspectRatio}
            setShouldPauseOtherVideo={() => {
              setShouldPauseVideo1(true);
              setShouldPauseVideo2(false);
            }}
          />
        </GridItem>
      </Grid>
    </Grid>
  );
};

export default CompareTab;
