import React, { PropsWithChildren } from "react";
import { Navigate, To } from "react-router-dom";

import { UserRole } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";

type RequireUserRoleProps = PropsWithChildren<{
  /** Required property on user role */
  // Feel free to add more values to the `Pick` as needed
  role: keyof Pick<UserRole, "canManageOrganizationSettings">;
  /** Where to route if the user does not have the require user role property */
  redirectTo: To;
}>;

/**
 * Renders `children` if the current user has the given user role property.
 * Else, redirects to `redirectTo`
 */
export const RequireUserRole: React.FC<RequireUserRoleProps> = ({
  role,
  redirectTo,
  children,
}) => {
  const currentUser = useCurrentUser();

  if (currentUser.userRole?.[role]) {
    return <>{children}</>;
  }

  return <Navigate to={redirectTo} replace />;
};

/**
 * Renders `children` if the current user can manage org settings,
 * else redirects to `redirectTo`
 */
export const RequireManageOrganizationSettings: React.FC<
  Omit<RequireUserRoleProps, "role">
> = (props) => (
  <RequireUserRole role="canManageOrganizationSettings" {...props} />
);
