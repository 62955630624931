import {
  CurrentUserFragment,
  FeatureName,
  OrganizationFeatureAccessFragment,
  OrganizationFragment,
} from "../main/graphql";
import {
  CurrentPlanUserFragment,
  PlanOrganizationFeatureAccessFragment,
} from "../plan/graphql";

export const useOrgFeatureAccess = (
  feature: FeatureName,
  organization?: OrganizationFragment
): OrganizationFeatureAccessFragment | undefined => {
  return organization?.featureAccess?.find((item) => item.name === feature);
};

export const useOrgAccessMap = (
  currentUser?: CurrentPlanUserFragment | CurrentUserFragment
):
  | Map<
      FeatureName,
      OrganizationFeatureAccessFragment | PlanOrganizationFeatureAccessFragment
    >
  | undefined => {
  const accessMap = new Map<
    FeatureName,
    OrganizationFeatureAccessFragment | PlanOrganizationFeatureAccessFragment
  >();
  currentUser?.organization.featureAccess?.forEach((item) => {
    accessMap.set(item.name, item);
  });
  return accessMap;
};
