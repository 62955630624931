import { Icon, IconProps, useTheme } from "@chakra-ui/react";
import React from "react";

type HandBookIconProps = IconProps & {
  colorScheme?: "gray";
};

/** "Tutorials" icon in [Figma](https://www.figma.com/design/uN2Pi6GfUePpsJUUZORtBU/Prism?node-id=2471-3613&t=Z7eprKJn0iFzcHoF-4) */
export const HandBookIcon: React.FC<HandBookIconProps> = ({
  colorScheme,
  ...props
}) => {
  const { colors } = useTheme();
  const palette =
    colorScheme === "gray"
      ? {
          200: colors.gray[200],
          300: colors.gray[300],
          400: colors.gray[400],
          500: colors.gray[500],
          600: colors.gray[600],
          700: colors.gray[700],
          800: colors.gray[800],
        }
      : {
          200: colors.blue[200],
          300: colors.blue[300],
          400: colors.blue[400],
          500: "#53CCFE",
          600: colors.blue[600],
          700: colors.blue[700],
          800: "#565656",
        };

  return (
    <Icon h={124} w="auto" viewBox="0 0 200 124" fill="none" {...props}>
      <path
        d="M154.876 92.3008C152.312 103.801 154.548 117.637 159.23 118.194C163.671 118.728 165.145 107.588 163.802 104.685C162.788 102.492 160.845 104.729 162.798 109.377C164.587 113.622 169.825 122.319 174.735 122.1C179.645 121.882 175.402 113.054 175.402 113.054L194.715 120.202"
        stroke={palette[700]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.56 10.5586C148.986 13.7741 148.454 19.6195 142.635 21.6653C136.868 23.6846 133.946 22.7819 129.136 27.0063C124.937 30.7012 125.308 38.1676 125.308 38.1676L139.499 41.5159C139.499 41.5159 139.552 36.228 142.662 33.6232C148.135 29.0271 154.832 31.6039 159.854 25.3587C163.68 20.5768 162.857 14.1723 162.857 14.1723L148.56 10.5586Z"
        fill="white"
      />
      <path
        d="M180.722 84.3735L168.424 91.2367C168.415 91.4019 168.071 97.2635 157.835 104.709C147.446 112.25 124.419 126.449 120.294 122.076C116.309 117.855 122.559 113.475 125.301 111.691C127.892 110.003 135.674 104.419 136.556 103.782L136.558 103.761C135.009 104.488 113.812 114.475 109.26 116.415C104.031 118.653 100.932 117.726 99.8333 115.331C98.5515 112.49 101.395 109.925 103.956 108.441C106.388 107.034 125.868 96.2133 127.745 95.1705V95.1499C125.63 96.0349 102.4 105.69 96.4128 107.785C90.1514 109.968 87.8164 107.053 87.3105 105.618C86.8046 104.184 86.7825 101.368 90.4788 99.156C93.6191 97.268 118.378 86.4488 120.581 85.4857C120.601 85.4871 120.582 85.465 120.582 85.465C118.498 86.1243 97.2461 92.7545 92.5424 93.6306C87.5893 94.5569 85.0744 91.1143 86.1084 87.8958C87.2442 84.3514 94.9599 81.6639 99.2344 79.8541C103.51 78.0443 121.486 69.8802 123.373 69.0247C125.259 68.1692 127.472 66.2325 125.333 64.3209C123.196 62.4108 115.582 58.6864 113.268 52.956C110.933 47.2256 115.989 46.7182 117.016 46.8952C119.93 47.3982 140.645 52.81 143.79 53.2613C146.934 53.7127 150.15 54.3956 153.672 52.7348C157.11 51.1093 160.748 49.2051 160.941 49.0915C160.941 49.0915 160.941 49.0915 160.962 49.0915L180.722 84.3735Z"
        fill={palette[200]}
      />
      <path
        d="M180.685 84.2871L160.958 49.1526C160.919 49.1098 160.857 49.1068 160.833 49.1673C160.556 50.5199 159.178 60.0145 165.578 70.4471C172.419 81.5642 179.456 84.0171 180.597 84.3859C180.657 84.411 180.703 84.3505 180.685 84.2871Z"
        fill={palette[500]}
      />
      <g fill={palette[400]}>
        <path
          opacity="0.2"
          d="M180.685 84.2871L160.958 49.1526C160.919 49.1098 160.857 49.1068 160.833 49.1673C160.556 50.5199 159.178 60.0145 165.578 70.4471C172.419 81.5642 179.456 84.0171 180.597 84.3859C180.657 84.411 180.703 84.3505 180.685 84.2871Z"
        />
        <path d="M113.369 48.1588C115.685 49.417 121.567 52.1826 123.379 48.3624C120.312 47.6042 117.878 47.0246 117.017 46.8756C116.401 46.7827 114.448 46.9095 113.369 48.1588Z" />
      </g>
      <g fill={palette[800]} opacity="0.2">
        <path d="M125.28 111.691L139.61 107.23L136.622 103.723C136.622 103.723 128.022 109.906 125.28 111.691Z" />
        <path d="M130.361 98.6382L127.872 95.0938C127.872 95.0938 113.273 103.205 106.751 106.845C110.202 105.739 130.361 98.6382 130.361 98.6382Z" />
        <path d="M123.794 89.4399L120.729 85.4102C120.729 85.4102 103.659 92.856 95.0039 96.9034C101.206 95.4623 123.794 89.4399 123.794 89.4399Z" />
      </g>
      <g stroke={palette[700]}>
        <path
          d="M140.024 107.21C133.388 101.776 126.477 94.836 120.141 83.9727"
          strokeLinecap="round"
        />
        <path
          d="M5.47168 61.0862C9.61053 57.4385 17.2835 52.5135 23.7086 56.6951C23.7086 56.6951 17.3882 48.4351 20.6052 44.578C24.8089 39.5261 37.1768 53.2569 35.3213 55.6154C33.4141 58.0256 29.1484 47.2404 31.3801 41.246C33.1412 36.5289 41.5472 43.6045 42.8998 47.7861"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M52.5489 7.47266C52.2111 10.0229 52.633 14.6589 57.2483 16.2814C61.8208 17.8832 64.1395 17.1664 67.9538 20.5176C71.2829 23.4469 70.9879 29.369 70.9879 29.369L59.7351 32.024C59.7351 32.024 59.6923 27.8306 57.2276 25.7656C52.8852 22.1194 47.5752 24.1638 43.5912 19.2107C40.5572 15.417 41.2106 10.3386 41.2106 10.3386L52.5489 7.47266Z"
        fill="white"
      />
      <path
        d="M106.861 78.1048L74.3465 46.153L63.5085 35.5195H63.4569L51.3416 47.8395L35.4421 63.9951L29.4609 71.8159V71.8674L73.989 120.126H74.0388L110.591 83.1662V83.1147L106.861 78.1048Z"
        fill={palette[400]}
      />
      <path
        d="M35.4415 64.043L28.1811 71.4047C25.9324 73.7068 25.9324 77.4375 28.2327 79.688L71.124 121.862C71.4816 122.222 72.0954 122.222 72.453 121.862L83.138 110.974L35.4415 64.043Z"
        fill={palette[600]}
      />
      <path
        d="M71.8911 122.683L71.176 122.02C69.0802 119.924 69.0286 116.549 71.1244 114.453L106.86 78.1055L111.308 82.5015L114.886 86.0294C115.039 86.1824 115.039 86.4884 114.886 86.6432L79.4575 122.683C77.4133 124.729 74.0901 124.779 71.8911 122.683Z"
        fill="white"
      />
    </Icon>
  );
};
