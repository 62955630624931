import { Box, ButtonProps, Flex, Text, useDisclosure } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { IoArrowRedoSharp } from "react-icons/io5";

import { AsyncModal, Button } from "../../../../components";
import { Clip, Maybe } from "../../../graphql";
import { ShareModal, useClipShareModal } from "../../ShareModal";

type ShareClipButtonProps = {
  clip: Pick<
    Clip,
    | "id"
    | "canEdit"
    | "visibility"
    | "visibleVisibilityLevels"
    | "startTime"
    | "endTime"
  >;
  callId: string;
  buttonStyle?: "button" | "menuItem";
  isModalOpen?: boolean;
  onCloseModal(): void;
  onOpenModal(): void;
  positionId?: Maybe<string> | undefined;
  /**
   * If present, `children` will be shown and launch the modal
   * on click. Otherwise, a default UI will be shown, based on
   * the value of the `buttonStyle` prop
   */
  children?: JSX.Element;
} & ButtonProps;

const ShareClipButton: React.FC<ShareClipButtonProps> = ({
  clip,
  callId,
  buttonStyle = "button",
  isModalOpen,
  onCloseModal,
  onOpenModal,
  positionId,
  children,
  ...buttonProps
}) => {
  const close = (): void => {
    onCloseModal();
  };

  const open = (): void => {
    onOpenModal();
  };

  const { onOpen, onClose, isOpen } = useDisclosure({
    isOpen: isModalOpen,
    onClose: close,
    onOpen: open,
  });

  const { getClipShareData, loading, hasSelection, ...shareModalProps } =
    useClipShareModal({ clip, onClose });

  useEffect(() => {
    if (isOpen) {
      getClipShareData();
    }
  }, [isOpen]);

  const defaultTrigger =
    buttonStyle === "menuItem" ? (
      <Flex
        flexFlow="row nowrap"
        alignItems="center"
        px="3"
        width="full"
        onClick={onOpen}
      >
        <IoArrowRedoSharp size="16px" />
        <Text ml="3" fontWeight="500" color="gray.900">
          Share Clip
        </Text>
      </Flex>
    ) : (
      <Button
        data-testid="share-button"
        size="sm"
        leftIcon={<IoArrowRedoSharp />}
        onClick={onOpen}
        {...buttonProps}
      >
        Share clip
      </Button>
    );

  const trigger = children ? (
    <Box onClick={onOpen} display="contents">
      {children}
    </Box>
  ) : (
    defaultTrigger
  );

  return (
    <>
      {trigger}
      <AsyncModal
        isOpen={isOpen}
        onClose={onClose}
        loading={loading}
        scrollBehavior={hasSelection ? "outside" : "inside"}
      >
        <ShareModal
          callId={callId}
          clipId={clip.id}
          onClose={onClose}
          positionId={positionId}
          {...shareModalProps}
        />
      </AsyncModal>
    </>
  );
};

export default ShareClipButton;
