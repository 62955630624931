import React, { SVGAttributes } from "react";

import { AshbyIcon } from "../AshbyIcon";
import GreenhouseIcon from "../GreenhouseIcon";
import LeverIcon from "../LeverIcon";
import SmartrecruitersIcon from "../SmartrecruitersIcon";

type IconProps = Pick<
  SVGAttributes<SVGSVGElement>,
  "width" | "height" | "fill"
> & {
  marginRight?: string;
  isGreenhouse?: boolean | string | number | null | undefined;
  isLever?: boolean | string | number | null | undefined;
  isAshby?: boolean | string | number | null | undefined;
  isSmartrecruiters?: boolean | string | number | null | undefined;
  fallback?: React.ReactElement;
};

export const AtsIcon: React.FC<IconProps> = ({
  isGreenhouse,
  isLever,
  isAshby,
  isSmartrecruiters,
  fill,
  width = "20px",
  height = "20px",
  marginRight = "0",
  fallback = null,
}) => {
  if (isGreenhouse) {
    return (
      <GreenhouseIcon
        width={width}
        height={height}
        fill={fill}
        marginRight={marginRight}
      />
    );
  }
  if (isLever) {
    return (
      <LeverIcon
        width={width}
        height={height}
        fill={fill}
        marginRight={marginRight}
      />
    );
  }
  if (isAshby) {
    return (
      <AshbyIcon
        width={width}
        height={height}
        fill={fill}
        marginRight={marginRight}
      />
    );
  }
  if (isSmartrecruiters) {
    return (
      <SmartrecruitersIcon
        width={width}
        height={height}
        fill={fill}
        marginRight={marginRight}
      />
    );
  }
  return fallback;
};
