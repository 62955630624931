import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";

import { Button, LoadingIndicator } from "../../../../components";
import { itemAnimation, listAnimation } from "../../../../components/Animation";
import { ActivityFeedListItemFragment } from "../../../graphql";
import ActivityFeedItem from "./ActivityFeedItem";

type ActivityFeedProps = {
  activities: ActivityFeedListItemFragment[];
  onLoadMore?(): void;
  hasNextPage?: boolean;
  loading?: boolean;
  error?: boolean;
  header?: string;
};

/**
 * The UI for activity feed, with no graphQL queries.
 *
 * Useful for testing + storybooks
 */
const ActivityFeedBase: React.FC<ActivityFeedProps> = ({
  activities,
  onLoadMore = () => undefined,
  hasNextPage = false,
  loading = false,
  error = false,
  header,
}) => {
  const hasActivities = activities.length > 0;
  const showError = !loading && error;
  const showErrorFooter = showError && hasActivities;
  const showErrorFull = showError && !hasActivities;
  const showLoadMore = !loading && !showError && hasActivities && hasNextPage;
  const showEmpty = !loading && !showError && !hasActivities;
  const hasFooterContent = loading || showError || showLoadMore || showEmpty;

  return (
    <Flex direction="column" py="4" px="2" gap="4">
      {header && (
        <Heading
          as="h3"
          px="2"
          fontSize="md"
          fontWeight="semibold"
          lineHeight="6"
          data-testid="homepage-module-title-activity-feed"
          data-tour-id="activity"
        >
          {header}
        </Heading>
      )}

      <motion.div
        initial="hidden"
        animate="visible"
        variants={listAnimation}
        key="activity-feed"
        hidden={!hasActivities}
      >
        {activities.map((item, i) => {
          return (
            <motion.div key={item.id} variants={itemAnimation}>
              <ActivityFeedItem
                item={item}
                data-testid={`activity-feed-item-${i + 1}`}
                p="2"
              />
            </motion.div>
          );
        })}
      </motion.div>

      <Box px="2" fontSize="sm" color="gray.500" hidden={!hasFooterContent}>
        {loading && <LoadingIndicator delay={0} py="2" />}
        {showErrorFooter && (
          <Text color="gray.400">
            Something went wrong. Please try again later.
          </Text>
        )}
        {showErrorFull && (
          <Text color="gray.400" mt="1">
            Something went wrong loading your results
            <br />
            <br />
            Interview shares, comments, and @mentions should appear here soon.
          </Text>
        )}
        {showLoadMore && (
          <Button
            variant="ghost"
            colorScheme="gray"
            size="sm"
            fontWeight="medium"
            onClick={onLoadMore}
          >
            Load more
          </Button>
        )}

        {showEmpty && (
          <Text color="gray.400" lineHeight="4" mt="1">
            No activity to show yet.
            <br />
            <br />
            Interview shares, comments, and @mentions will appear here.
          </Text>
        )}
      </Box>
    </Flex>
  );
};

export default ActivityFeedBase;
