import {
  Divider,
  Flex,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { transparentize } from "@chakra-ui/theme-tools";
import React, { useState } from "react";

import { Button } from "../../../components";
import MultiValueSelect from "../../../components/MultiValueSelect";
import { email as emailRegex } from "../../../utils/regex";

type ExternalShareModalProps = {
  onCancel: () => void;
  onShare: (emails: string[], message: string, title: string) => void;
  infoText?: string;
  includeTitleInput?: boolean;
  modalTitle?: string;
  shareLoading: boolean;
  externalShareDuration: number;
};

type Errors = Record<"email" | "message", string | null>;

const ExternalShareModal: React.FC<ExternalShareModalProps> = ({
  onCancel,
  onShare,
  infoText,
  includeTitleInput = false,
  modalTitle,
  shareLoading,
  externalShareDuration,
}) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  const [errors, setErrors] = useState<Errors>({
    email: null,
    message: null,
  });

  const setError = (e: Partial<Errors>): void => setErrors({ ...errors, ...e });
  const clearErrors = (key?: keyof Errors): void =>
    setError(key ? { [key]: null } : { email: null, message: null });

  const reset = (): void => {
    setEmails([]);
    setMessage("");
    setAttemptedSubmit(false);
    clearErrors();
  };

  const cancel = (): void => {
    onCancel();
    reset();
  };

  const share = (): void => {
    setAttemptedSubmit(true);

    const hasErrors = !!(errors.email || errors.message);
    const invalid = hasErrors || !emails.length;

    if (invalid) {
      return;
    }

    onShare(emails, message, title);
  };

  return (
    <>
      <ModalHeader p="6" fontWeight="medium" fontSize="xl">
        {modalTitle ?? "Share Recording Outside Org"}
      </ModalHeader>

      <ModalCloseButton top="6" right="5" />

      <Divider />

      <ModalBody pt="8">
        <Text color="gray.700" fontSize="14" mb="6">
          {infoText ||
            `The recipient will see the recording, transcript, and marked topics
            only. They will not see any notes or reactions, and cannot invite new
            users to view.`}
        </Text>

        <MultiValueSelect
          mb={includeTitleInput ? "6" : "4"}
          fontSize="sm"
          autoFocus
          value={emails}
          onAddValue={(email) => setEmails([...emails, email])}
          onValueChange={setEmails}
          onFocus={() => clearErrors("email")}
          validator={(value) => {
            const trimmed = value.trim();
            if (!value || trimmed.match(emailRegex)) {
              clearErrors("email");
              return trimmed;
            }
            setError({
              email: "Please enter a valid email",
            });
            return null;
          }}
          getOptionLabel={(s) => s}
          getOptionValue={(s) => s}
          addValueOnBlur
          placeholder="Enter email addresses"
          helpTextItem="emails"
          error={(attemptedSubmit && errors.email) || null}
        />

        {includeTitleInput && (
          <Input
            placeholder="Optional title"
            onChange={(e) => setTitle(e.target.value.trim())}
            fontSize="14"
            _placeholder={{ color: "gray.400" }}
            mb="4"
          />
        )}

        <Textarea
          placeholder="Optional message"
          onChange={({ target }) => setMessage(target.value.trim())}
          maxLength={250}
          fontSize="14"
          height="28"
          _placeholder={{ color: "gray.400" }}
          resize="none"
          mb="4"
        />

        <Flex
          alignItems="center"
          bg="gray.50"
          borderRadius="base"
          border="1px solid"
          borderColor="gray.100"
          px="4"
          py="2.5"
          background={transparentize("orange.500", 0.1) as unknown as string}
        >
          <Text fontSize="14">
            This share link will expire in {externalShareDuration || "5"} days
          </Text>
        </Flex>
      </ModalBody>

      <ModalFooter pt="0">
        <Button
          onClick={cancel}
          variant="white"
          fontSize="14"
          fontWeight="medium"
          px="3"
          mr="3"
        >
          Cancel
        </Button>

        <Button
          fontSize="14"
          fontWeight="medium"
          px="3"
          onClick={share}
          isLoading={shareLoading}
        >
          Share
        </Button>
      </ModalFooter>
    </>
  );
};

export default ExternalShareModal;
