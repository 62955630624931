import {
  BoxProps,
  Flex,
  Heading,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tag,
} from "@chakra-ui/react";
import React from "react";

const BetaTag: React.FC<
  BoxProps & {
    text?: string;
    popoverHeading?: string;
    popoverText?: string;
    popoverIcon?: any;
    showTagInPopover?: boolean;
  }
> = ({
  text = "BETA",
  popoverHeading,
  popoverText,
  popoverIcon,
  showTagInPopover = true,
  ...props
}) =>
  popoverText || popoverHeading ? (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Tag
          size="sm"
          borderRadius="full"
          color="white"
          bg="gray.300"
          cursor="default"
          transition="background .3s ease-out;"
          _hover={{ bg: "gray.400" }}
          {...props}
        >
          {text}
        </Tag>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody fontSize="sm" px={5} py={4}>
          <Flex direction="row" alignItems="center" mb="2">
            {popoverIcon && <Icon as={popoverIcon} mr={2} color="gray.400" />}
            <Heading size="xs" fontWeight="bold">
              {popoverHeading}
            </Heading>
            {showTagInPopover && (
              <Tag
                size="sm"
                borderRadius="full"
                color="white"
                bg="gray.300"
                ml="2"
              >
                Beta
              </Tag>
            )}
          </Flex>
          {popoverText}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  ) : (
    <Tag
      size="sm"
      borderRadius="full"
      color="white"
      bg="gray.300"
      cursor="default"
      transition="background .3s ease-out;"
      _hover={{ bg: "gray.400" }}
      {...props}
    >
      {text}
    </Tag>
  );

export default BetaTag;
