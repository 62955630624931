import { Center } from "@chakra-ui/react";
import React from "react";

import { LoadingIndicator } from "../../../../components";

const SkillsReportLoading: React.FC = () => {
  return (
    <Center w="100%" mt="16">
      <LoadingIndicator />
    </Center>
  );
};

export default SkillsReportLoading;
