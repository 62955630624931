import { Box, Heading, Text, Wrap, WrapItem } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";

import { Button, CenteredCardLayout, useToast } from "../../../components";
import { usePageTracker } from "../../../utils/googleAnalytics";
import { useCancelRecordingMutation } from "../../graphql";

interface CancelRecordingLayoutProps {
  children: React.ReactNode;
}
const CancelRecordingLayout: React.FC<CancelRecordingLayoutProps> = ({
  children,
}) => (
  <CenteredCardLayout>
    <Box maxW="600px">{children}</Box>
  </CenteredCardLayout>
);

const CancelRecordingPage: React.FC = () => {
  usePageTracker("zoom_chat_cancel_recording");
  const toast = useToast();
  const { slug } = useParams() as {
    slug: string;
  };
  const [cancelRecording, { data, loading }] = useCancelRecordingMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
  });

  if (data?.cancelRecording.success) {
    return (
      <CancelRecordingLayout>
        <img src="/static/images/videocam-off-sharp.svg" width="45px" />
        <Heading size="md" lineHeight="lg" my="6">
          The recording was deleted.
        </Heading>
      </CancelRecordingLayout>
    );
  }

  return (
    <CancelRecordingLayout>
      <img src="/static/images/videocam-off-sharp.svg" width="45px" />
      <Heading size="md" lineHeight="lg" my="6">
        Remove the Notetaker and delete the recording for this meeting?
      </Heading>
      <Text>This cannot be undone.</Text>
      <Wrap mt="10" spacing="4">
        <WrapItem>
          <Button
            variant="danger"
            isLoading={loading}
            onClick={() => cancelRecording({ variables: { slug } })}
          >
            Delete Recording
          </Button>
        </WrapItem>
      </Wrap>
    </CancelRecordingLayout>
  );
};

export default CancelRecordingPage;
