import {
  Box,
  Flex,
  Icon,
  Text,
  Tooltip as ChakraTooltip,
} from "@chakra-ui/react";
import React from "react";
import { HiOutlineInformationCircle } from "react-icons/hi";

import { IconButton } from "../../../../components";
import { StructuredFormattedValue } from "../utils";

export type OverviewCardProps = {
  title: string;
  tooltipLabel?: string;
  icon?: React.ElementType;
  value: StructuredFormattedValue[] | string;
  percentDifference?: string | number | null;
  children?: React.ReactNode;
};

const OverviewCard: React.FC<OverviewCardProps> = ({
  title,
  tooltipLabel,
  icon,
  value,
  percentDifference,
  children,
}) => {
  return (
    <Flex
      direction="column"
      p="6"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="lg"
    >
      <Flex direction="column" flex="1">
        <Flex align="center" ml={icon ? "7" : "0"} mb="4">
          <Text color="gray.800" fontSize="lg" fontWeight="600">
            {title}
          </Text>
          {tooltipLabel && <OverviewCardTooltip label={tooltipLabel} />}
        </Flex>
        <Flex direction="row" align="center">
          {icon && <Icon as={icon} boxSize="6" color="gray.500" mr="1" />}
          <Flex direction="row" align="baseline">
            {typeof value === "string" && (
              <OverviewCardStat structuredValue={{ value, unitSymbol: "" }} />
            )}
            {typeof value === "object" &&
              value.map((structuredValue, index) => (
                <OverviewCardStat
                  key={index} // eslint-disable-line react/no-array-index-key
                  structuredValue={structuredValue}
                />
              ))}
          </Flex>
        </Flex>
        {(typeof percentDifference === "number" ||
          typeof percentDifference === "string") && (
          <Box mt="1" ml={icon ? "7" : "0"}>
            <Text fontSize="xs" fontWeight="500" color="gray.500">
              <Text as="span" color="gray.800">
                {percentDifference}
              </Text>{" "}
              v. previous period
            </Text>
          </Box>
        )}
      </Flex>
      {children}
    </Flex>
  );
};

// TODO: No tooltip style in mocks yet
// This is borrowing styles from MetricConfig.tsx tooltip
const OverviewCardTooltip: React.FC<{ label: string }> = ({
  label,
}): JSX.Element => (
  <ChakraTooltip
    label={label}
    bg="white"
    p="2"
    borderRadius="md"
    border="1px solid"
    borderColor="gray.200"
    color="gray.800"
    boxShadow="none"
    fontSize="sm"
    shouldWrapChildren
    fontWeight="normal"
  >
    <IconButton
      aria-label="Overview card tooltip"
      variant="unstyled"
      size="xxs"
      ml="1"
      mb="0"
      color="gray.800"
      icon={<HiOutlineInformationCircle />}
    />
  </ChakraTooltip>
);

const OverviewCardStat: React.FC<{
  structuredValue: StructuredFormattedValue;
}> = ({ structuredValue }): JSX.Element => {
  return (
    <>
      <Text
        fontSize="48px"
        fontWeight="300"
        color="gray.800"
        lineHeight="48px"
        letterSpacing="-1.92px"
      >
        {structuredValue.value}
      </Text>
      <Text pl="1" pr="2">
        {structuredValue.unitSymbol}{" "}
      </Text>
    </>
  );
};
export default OverviewCard;
