import * as Sentry from "@sentry/browser";
import { useEffect, useMemo, useState } from "react";

import { Message, TalkResponse, TalkResponseContent } from "./types";

let nextId = 0;

const useTalkResponse = (
  promptId: string | undefined,
  talkBlob: Blob | null,
  messages: Message[]
): TalkResponse => {
  const [response, setResponse] = useState<TalkResponseContent>();
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    setResponse(undefined);

    const fetchData = async (): Promise<void> => {
      if (!talkBlob) {
        return;
      }

      setBusy(true);
      try {
        const formData = new FormData();
        formData.append("talkBlob", talkBlob);
        formData.append("promptId", promptId || "");
        formData.append("messages", JSON.stringify(messages));
        const response = await fetch("/ai-recruiter/talk", {
          method: "POST",
          body: formData,
        });
        if (!response.ok) {
          throw new Error("Error fetching talk responses");
        }
        if (!response.body) {
          throw new Error("No response body found");
        }

        const reader = response.body.getReader();

        let accumulate = "";
        nextId += 1;
        // eslint-disable-next-line no-constant-condition
        while (true) {
          // Disable reason: awaits must be in sequence
          // eslint-disable-next-line no-await-in-loop
          const { done, value } = await reader.read();
          if (done) break;
          // eslint-disable-next-line no-console
          console.info("[useTalkResponse] line");

          // Long messages may come across multiple reads, so accumulate them
          accumulate += new TextDecoder().decode(value);

          const eol = "}\n";
          while (accumulate.indexOf(eol) !== -1) {
            const firstNewlineIdx = accumulate.indexOf(eol) + 1;
            const lead = accumulate.slice(0, firstNewlineIdx);
            accumulate = accumulate.slice(firstNewlineIdx + 1);
            try {
              const parsed = JSON.parse(lead);
              parsed.id = nextId;
              setResponse(parsed);
              accumulate = "";
            } catch (e) {
              Sentry.captureException(e);
            }
          }
        }
        reader.releaseLock();
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        nextId += 1;
        setBusy(false);
      }
    };
    fetchData();
  }, [talkBlob]);

  const result = useMemo(() => {
    return {
      busy,
      content: response,
    };
  }, [busy, response]);
  return result;
};

export default useTalkResponse;
