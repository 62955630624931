import { Box, Heading, List, ListItem, Select, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { MdWarning } from "react-icons/md";

import {
  Button,
  ConfirmModal,
  LoadingIndicator,
  RouterLink,
  StatusWithText,
  TooltipIcon,
  useToast,
} from "../../../components";
import CalendarSelect from "../../components/CalendarSelect";
import {
  Calendar,
  CurrentUserFragment,
  useCurrentUserCalendarQuery,
  useDeleteCalendarOrgSettingsMutation,
  useUpdateCalendarOrgSettingsMutation,
} from "../../graphql";
import SettingsPageContainer from "./shared/SettingsPageContainer";

interface SharedCalendarSettingsProps {
  currentUser: CurrentUserFragment;
}

const SharedCalendarSettings: React.FC<SharedCalendarSettingsProps> = ({
  currentUser,
}) => {
  const zoomSetupComplete =
    currentUser.organization.isZoomAuthorized ||
    currentUser.organization.zoomNotetakerEnabled;
  const { googleMeetEnabled } = currentUser.organization;
  const videoMeetingEnabled = zoomSetupComplete || googleMeetEnabled;
  const { data, loading } = useCurrentUserCalendarQuery();
  if (loading) {
    return <LoadingIndicator />;
  }
  const calendars = data?.currentUser?.calendars || [];
  return (
    <SettingsPageContainer
      heading="Shared Calendars"
      subHeading="Configure shared calendars to have BrightHire automatically join scheduled interviews."
    >
      <Heading as="h3" size="sm" mb={2}>
        Video Meeting Import Settings
      </Heading>
      <StatusWithText
        passed={zoomSetupComplete}
        label={
          zoomSetupComplete
            ? "Zoom Setup Complete"
            : "Zoom Setup Incomplete, please contact your Administrator."
        }
      />
      <StatusWithText
        passed={googleMeetEnabled}
        label={
          googleMeetEnabled
            ? "Google Meet Enabled"
            : "Google Meet Not Enabled, please contact your Administrator."
        }
      />

      {videoMeetingEnabled &&
        (calendars.length < 1 ? (
          <Box mt={6}>
            No calendars synced. Sync your calendars{" "}
            <RouterLink to="/settings/calendar">here</RouterLink>.
          </Box>
        ) : (
          <>
            <Box mb={6}>
              <Text mb={2}>
                Use the settings below to have BrightHire automatically join and
                record Zoom or Google Meet interviews scheduled on shared
                calendars.
              </Text>
              <Text>
                For each shared calendar selected, you can have BrightHire
                automatically join and record:
              </Text>
              <List mt={2} ml={6}>
                <ListItem pb={2}>
                  <b>All</b>: BrightHire will join every Zoom and Google Meet
                  meeting on this calendar automatically (typically used for
                  shared interview calendars).
                </ListItem>
                <ListItem>
                  <b>Some</b>: BrightHire will join only the Zoom and Google
                  Meet meetings on this calendar with <i>#brighthire</i> in the
                  event description.
                </ListItem>
              </List>
            </Box>
            <CalendarSelectList calendars={calendars} />
          </>
        ))}
    </SettingsPageContainer>
  );
};

interface CalendarSelectListProps {
  calendars: ({
    __typename?: "Calendar" | undefined;
  } & Pick<
    Calendar,
    | "id"
    | "resourceId"
    | "name"
    | "videoMeetingImportStrategy"
    | "canSetVideoMeetingImportStrategy"
    | "primary"
  >)[];
}

const CalendarSelectList: React.FC<CalendarSelectListProps> = ({
  calendars,
}) => {
  const toast = useToast();
  const [updateCalendarOrgSettings, { loading }] =
    useUpdateCalendarOrgSettingsMutation({
      onCompleted: (data) => {
        if (data?.updateCalendarOrgSettings?.currentUser) {
          toast({
            status: "success",
            title: "Update Shared Calendar Settings",
            description: "Success!",
          });
        }
      },
      onError: () => {
        toast({
          status: "error",
          title: "Update Shared Calendar Settings",
          description: "There was a problem - please try again",
        });
      },
    });
  const calendarsWithSettings = calendars.filter(
    (calendar) => calendar.canSetVideoMeetingImportStrategy === false
  );
  return (
    <Box>
      <Box width="350px" mb={2}>
        <CalendarSelect
          calendars={calendars.filter(
            (calendar) =>
              calendar.canSetVideoMeetingImportStrategy &&
              // filter out personal calendars, this could falsely filter out other calendars though
              calendar.name !== "Holidays in United States" &&
              calendar.name !== "Birthdays"
          )}
          isLoading={loading}
          onSelect={(calendar) => {
            if (!calendar) return;
            updateCalendarOrgSettings({
              variables: {
                id: calendar.id,
                shouldImportVideoMeeting: "TAGGED",
              },
            });
          }}
          placeholder="Select a shared calendar"
        />
      </Box>
      {calendarsWithSettings.length < 1 ? (
        <Box mt={4}>
          No calendars added. Select a calendar using the dropdown above.
        </Box>
      ) : (
        <List
          display="table"
          style={{
            borderSpacing: "10px",
            borderCollapse: "separate",
            tableLayout: "fixed",
            width: "100%",
          }}
        >
          <ListItem display="table-row">
            <Box fontWeight="bold" display="table-cell">
              Name
            </Box>
            <Box display="table-cell" fontWeight="bold">
              Video Meeting Import Setting
            </Box>
            <Box display="table-cell" fontWeight="bold">
              Remove Setting
            </Box>
          </ListItem>
          {calendarsWithSettings.map((calendar) => {
            return (
              <ListItem key={calendar.id} display="table-row">
                <CalendarSettingListItem calendar={calendar} />
              </ListItem>
            );
          })}
        </List>
      )}
    </Box>
  );
};

interface CalendarSettingListItemProps {
  calendar: { __typename?: "Calendar" } & Pick<
    Calendar,
    | "id"
    | "resourceId"
    | "name"
    | "videoMeetingImportStrategy"
    | "canSetVideoMeetingImportStrategy"
  >;
}

const CalendarSettingListItem: React.FC<CalendarSettingListItemProps> = ({
  calendar,
}) => {
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [updateCalendarOrgSettings, { loading }] =
    useUpdateCalendarOrgSettingsMutation({
      onCompleted: (data) => {
        if (data?.updateCalendarOrgSettings?.currentUser) {
          toast({
            status: "success",
            title: "Update Shared Calendar Settings",
            description: "Success!",
          });
        }
      },
      onError: () => {
        toast({
          status: "error",
          title: "Update Shared Calendar Settings",
          description: "There was a problem - please try again",
        });
      },
    });
  const [
    deleteCalendarOrgSettings,
    { loading: deleteCalendarOrgSettingsLoading },
  ] = useDeleteCalendarOrgSettingsMutation({
    onCompleted: (data) => {
      if (data?.deleteCalendarOrgSettings?.currentUser) {
        toast({
          status: "success",
          title: "Delete Shared Calendar Settings",
          description: "Success!",
        });
      }
    },
    onError: () => {
      toast({
        status: "error",
        title: "Delete Shared Calendar Settings",
        description: "There was a problem - please try again",
      });
    },
  });

  const onChange = (
    event: React.SyntheticEvent<HTMLSelectElement, Event>
  ): void => {
    updateCalendarOrgSettings({
      variables: {
        id: calendar.id,
        shouldImportVideoMeeting: event.currentTarget.value,
      },
    });
  };

  return (
    <>
      <ConfirmModal
        modalBodyText="If this calendar is also synced to another BrightHire user’s account, deleting it here will revert the Video Import Settings to whatever they have selected."
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
        onConfirm={() => {
          deleteCalendarOrgSettings({
            variables: {
              id: calendar.id,
            },
          });
          setIsOpen(false);
        }}
      />
      <Box display="table-cell">
        <Box display="flex">
          {calendar.name.includes("@") && (
            <TooltipIcon
              mr={2}
              mt="2px"
              icon={MdWarning}
              color="yellow.400"
              label="It appears this might be a personal calendar, not a shared calendar. By selecting a Video Meeting Import Setting for this calendar you override individual settings set by the owner of this calendar and they will be disabled from selecting their own settings."
            />
          )}
          <Box wordBreak="break-word">{calendar.name}</Box>
        </Box>
      </Box>
      <Box display="table-cell">
        <Select
          defaultValue={calendar.videoMeetingImportStrategy}
          size="sm"
          onChange={onChange}
          isDisabled={loading}
        >
          <option value="ALL">All</option>
          <option value="TAGGED">Some</option>
        </Select>
      </Box>
      <Text display="table-cell">
        <Button
          variant="danger"
          size="sm"
          onClick={() => setIsOpen(true)}
          isLoading={deleteCalendarOrgSettingsLoading}
        >
          Delete
        </Button>
      </Text>
    </>
  );
};

export default SharedCalendarSettings;
