import {
  Box,
  BoxProps,
  Menu,
  MenuItem,
  MenuList,
  Portal,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import {
  HiDotsVertical,
  HiOutlinePencil,
  HiOutlineTrash,
} from "react-icons/hi";

import { IconButton, MenuButton } from "../../../../../../components";
import { useDeleteAiSummaryCustomTopicMutation } from "../../../../../graphql";
import { SummaryAddCustomTopic } from "./SummaryAddCustomTopic";
import { SummaryCustomTopic } from "./types";

type TopicTagProps = {
  id: string;
  text: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  onClick?(): void;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
  addCustomTopic?: (t: string, questions: string[]) => void;
  customTopic?: SummaryCustomTopic;
};

const TopicTag: React.FC<TopicTagProps & BoxProps> = ({
  id,
  text,
  isSelected = false,
  isDisabled = false,
  onClick,
  onMouseEnter,
  onMouseLeave,
  addCustomTopic,
  customTopic,
  ...styles
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteCustomTopic] = useDeleteAiSummaryCustomTopicMutation({});

  const isEnabled = !isDisabled;

  const hoverStyle = isDisabled
    ? undefined
    : {
        bg: "blue.50",
        borderColor: "blue.600",
        color: "blue.600",
      };

  return (
    <Tooltip
      placement="top"
      size="xs"
      label="We couldn't find enough content to summarize this topic"
      hidden={!customTopic || !isDisabled}
    >
      <Box
        fontSize="xs"
        lineHeight="4"
        px="1.5"
        py="0.5"
        borderRadius="3px"
        border="1px solid"
        borderColor={isSelected ? "blue.600" : "gray.300"}
        bg={isDisabled ? "gray.50" : isSelected ? "blue.50" : "white"}
        color={isDisabled ? "gray.600" : isSelected ? "blue.600" : "gray.800"}
        _hover={hoverStyle}
        cursor="pointer"
        onClick={() => {
          if (isEnabled) onClick?.();
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        {...styles}
      >
        {text}
        {addCustomTopic && customTopic && (
          <>
            <SummaryAddCustomTopic
              isOpen={isOpen}
              onClose={onClose}
              addCustomTopic={addCustomTopic}
              title={customTopic?.title}
              questions={customTopic?.questions}
            />
            <Menu placement="bottom-end">
              <MenuButton
                as={IconButton}
                display="inline-block"
                aria-label="More options"
                size="xs"
                minW="14px"
                width="14px"
                minH="14px"
                height="14px"
                variant="unstyled"
                color={
                  isDisabled ? "gray.600" : isSelected ? "blue.600" : "gray.800"
                }
                mt="-2px"
                mr="-4px"
                fontSize="12px"
                icon={<HiDotsVertical color="blue.600" />}
              />
              <Portal>
                <MenuList fontSize="xs" maxW="120px" minW="120px" data-no-dnd>
                  <MenuItem
                    fontWeight="400"
                    icon={<HiOutlinePencil fontSize="16px" />}
                    onClick={onOpen}
                    iconSpacing="8px"
                    color="gray.800"
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    icon={<HiOutlineTrash fontSize="16px" />}
                    fontWeight="400"
                    iconSpacing="8px"
                    color="red.600"
                    onClick={() => {
                      deleteCustomTopic({
                        variables: {
                          customTopicNoteId: id,
                        },
                      });
                    }}
                  >
                    Delete
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          </>
        )}
      </Box>
    </Tooltip>
  );
};

export default TopicTag;
