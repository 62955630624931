import React from "react";

import { AnalyticsDimension, MetricName } from "../../graphql";
import { ReportMetric } from "./types";

export const SCORE_METRIC: ReportMetric = {
  dataKey: "score",
  rawDataKey: "score", // no raw score
  metric: MetricName.Report,
  label: "Overall Interview Quality Score",
  description:
    "To calculate your Overall Interview Score, we average your scores evenly for multiple metrics over the selected time period. Calculated metrics: On-time starts, Talk ratio, Speaking rate, High-risk questions, Question opportunity and Agenda set.",
  tableHeaderNode: (
    <>
      Overall
      <br />
      interview quality
      <br />
      score
    </>
  ),
  formFieldKey: "", // does not appear in the form
  format: (value: number) => `${Math.round(value)}`,
};

export const REPORT_METRICS: ReportMetric[] = [
  {
    dataKey: "onTimeStarts",
    rawDataKey: "onTimeStartsRaw",
    metric: MetricName.OnTimeInterviews,
    label: "On-time Starts",
    description:
      "This score is the percentage of selected interviews that started on time. On-Time Start is defined as the interviewer speaking within five minutes of the scheduled interview start. Interviews without a scheduled start time are excluded from this report.",
    tableHeaderNode: (
      <>
        On-time
        <br />
        starts score
      </>
    ),
    formFieldKey: "onTimeStartsEnabled",
    format: (value: number) => `${Math.round(value * 100)}`,
    units: "%",
  },
  {
    dataKey: "talkRatio",
    rawDataKey: "talkRatioRaw",
    metric: MetricName.CandidateTalkRatio,
    label: "Talk Ratio",
    description:
      "This score is the percentage of selected interviews in which the candidate's talk ratio fell within the desired range of 40 - 75%.",
    tableHeaderNode: (
      <span>
        Talk ratio
        <br />
        score
      </span>
    ),
    formFieldKey: "candidateTalkRatioEnabled",
    format: (value: number) => `${Math.round(value * 100)}`,
    units: "%",
  },
  {
    dataKey: "longestMonologue",
    rawDataKey: "longestMonologueRaw",
    metric: MetricName.LongestMonologue,
    label: "Longest Monologue",
    description:
      "This score is the percentage of selected interviews in which the interviewer's longest monologue was under 2 minutes.",
    tableHeaderNode: (
      <span>
        Longest
        <br />
        monologue
        <br />
        score
      </span>
    ),
    formFieldKey: "longestMonologueEnabled",
    format: (value: number) => `${Math.round(value)}`,
    units: "secs",
  },
  {
    dataKey: "isAgendaSet",
    rawDataKey: "isAgendaSetRaw",
    metric: MetricName.AgendaSet,
    label: "Agenda Set",
    description:
      "This score is the percentage of selected interviews in which the interviewer states a structured agenda within the first 10 minutes of the interview.",
    tableHeaderNode: (
      <span>
        Agenda set
        <br />
        score
      </span>
    ),
    formFieldKey: "agendaSetEnabled",
    format: (value: number) => `${Math.round(value * 100)}`,
    units: "%",
  },
  {
    dataKey: "problematicQuestions",
    rawDataKey: "problematicQuestionsRaw",
    metric: MetricName.ProblematicQuestions,
    label: "High-risk Questions",
    description:
      "This score is the percentage of selected interviews in which the interviewer stayed compliant by avoiding discussion of protected-class information (as defined by the EEOC). A score of 80 would indicate that an interviewer mentioned protected-class information in 1 out of 5 interviews they conducted.",
    tableHeaderNode: (
      <span>
        High-risk
        <br />
        question
        <br />
        score
      </span>
    ),
    formFieldKey: "problematicQuestionsEnabled",
    format: (value: number) => `${Math.round(value * 100) / 100}`,
  },
  {
    dataKey: "candidateQuestionOpportunity",
    rawDataKey: "candidateQuestionOpportunityRaw",
    metric: MetricName.CandidateQuestionOpportunity,
    label: "Question Opportunity",
    description:
      "This score is the percentage of selected interviews in which the interviewer solicits the candidate for questions in the last 15 minutes of the interview.",
    tableHeaderNode: (
      <span>
        Question
        <br />
        opportunity
        <br />
        score
      </span>
    ),
    formFieldKey: "candidateQuestionOpportunityEnabled",
    format: (value: number) => `${Math.round(value * 100)}`,
    units: "%",
  },
  {
    dataKey: "speakingRate",
    rawDataKey: "speakingRateRaw",
    metric: MetricName.SpeakingRate,
    label: "Speaking Rate",
    description:
      "This score is the percentage of selected interviews in which the interviewer's speaking rate fell within the desired range of 140 - 191 words per minute.",
    tableHeaderNode: (
      <span>
        Speaking
        <br />
        rate score
      </span>
    ),
    formFieldKey: "speakingRateEnabled",
    format: (value: number) => `${Math.round(value)}`,
    units: "wpm",
  },
  {
    dataKey: "interactivity",
    rawDataKey: "interactivityRaw",
    metric: MetricName.Interactivity,
    label: "Interactivity",
    description:
      "This score is the percentage of selected interviews in which the interactivity score fell within the desired range of 1.12 - 1.60.",
    tableHeaderNode: (
      <span>
        Interactivity
        <br />
        score
      </span>
    ),
    formFieldKey: "interactivityEnabled",
    format: (value: number) => `${Math.round(value * 100) / 100}`,
  },
  {
    dataKey: "questionsAsked",
    rawDataKey: "questionsAskedRaw",
    metric: MetricName.QuestionsAsked,
    label: "Questions Asked",
    description:
      "This score is the percentage of selected interviews in which the interviewer asked between 9 and 20 questions.",
    tableHeaderNode: (
      <span>
        Questions
        <br />
        asked score
      </span>
    ),
    formFieldKey: "questionsAskedEnabled",
    format: (value: number) => `${Math.round(value * 100) / 100}`,
  },
  {
    dataKey: "feedbackSubmissionTime",
    rawDataKey: "feedbackSubmissionTimeRaw",
    metric: MetricName.ScorecardCompletionTime,
    label: "Feedback Submission Time",
    description:
      "This score is the percentage of submitted feedbacks that were submitted within 24 hours of the completed interview.",
    tableHeaderNode: (
      <span>
        Feedback
        <br />
        submission
        <br />
        time score
      </span>
    ),
    formFieldKey: "feedbackSubmissionTimeEnabled",
    format: (value: number) => `${Math.round(value)}`,
    units: "mins",
  },
  {
    dataKey: "feedbackSubmissionRate",
    rawDataKey: "feedbackSubmissionRateRaw",
    metric: MetricName.ScorecardCompletionRate,
    label: "Feedback Submission Rate",
    description:
      "This score is the percentage of selected interviews for which the interviewer submitted feedback within 7 days of the completed interview.",
    tableHeaderNode: (
      <span>
        Feedback
        <br />
        submission
        <br />
        rate score
      </span>
    ),
    formFieldKey: "feedbackSubmissionRateEnabled",
    format: (value: number) => `${Math.round(value * 100)}`,
    units: "%",
  },
];

export const REPORT_METRIC_MAP = REPORT_METRICS.reduce(
  (acc, metric: ReportMetric) => {
    acc[metric.dataKey] = metric;
    return acc;
  },
  {} as {
    [key: string]: ReportMetric;
  }
);

export const ATS_REQUIRED_DIMENSIONS = [
  AnalyticsDimension.ApplicationStatus,
  AnalyticsDimension.JobStage,
];

export const HCA_METRIC_NAMES = [
  MetricName.WorkLocationCoverage,
  MetricName.CompensationCoverage,
  MetricName.CareLikeFamilyCoverage,
];

export const RATING_METRIC_NAMES = [
  MetricName.Rating,
  MetricName.StrongRatingPercent,
];

export const ATS_METRIC_NAMES = [
  MetricName.ScorecardCompletionRate,
  MetricName.ScorecardCompletionTime,
  MetricName.PassRate,
];

export const NON_ATS_METRICS = REPORT_METRICS.filter(
  (metric) => ATS_METRIC_NAMES.includes(metric.metric) === false
);

export const ATS_METRICS = REPORT_METRICS.filter((metric) =>
  ATS_METRIC_NAMES.includes(metric.metric)
);

export const METRIC_RECOMMENDED_VALUES: {
  [key in MetricName]?: number;
} = {
  [MetricName.OnTimeInterviews]: 1,
  [MetricName.AgendaSet]: 1,
  [MetricName.ProblematicQuestions]: 0,
  [MetricName.ScorecardCompletionRate]: 1,
  [MetricName.CandidateQuestionOpportunity]: 1,
};

export const METRIC_ENFORCED_DOMAINS: {
  [key in MetricName]?: [number | string, number | string];
} = {
  [MetricName.OnTimeInterviews]: [0, 1],
  [MetricName.AgendaSet]: [0, 1],
  [MetricName.ProblematicQuestions]: [0, "auto"],
  [MetricName.ScorecardCompletionRate]: [0, 1],
  [MetricName.CandidateQuestionOpportunity]: [0, 1],
};

export const DEFAULT_REPORT_METRICS: MetricName[] = [
  MetricName.AgendaSet,
  MetricName.CandidateTalkRatio,
  MetricName.OnTimeInterviews,
  MetricName.LongestMonologue,
  MetricName.ProblematicQuestions,
  MetricName.CandidateQuestionOpportunity,
];
