import {
  Box,
  Flex,
  Icon,
  ListItem,
  Text,
  Tooltip,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { GoDotFill } from "react-icons/go";
import { useLocation } from "react-router-dom";

import { RouterLink } from "../../../components";
import { formatDuration } from "../../../utils/datetime";
import MonospacedText from "../MonospacedText";

type AiNote = {
  id: string;
  text: string;
  startTimes?: number[] | null;
};

type AiNoteBlockProps = {
  header: string;
  headerStartTime?: number | null;
  notes: AiNote[];
  footnoteMap: Record<number, number>;
  emptyText: string;
  onSeek(t: number): void;
};

export const ReadonlyAiNoteBlock: React.FC<AiNoteBlockProps> = ({
  header,
  headerStartTime,
  notes,
  footnoteMap,
  emptyText,
  onSeek,
}) => {
  const { search } = useLocation();
  const startTimeSearchParam = (t: number): string => {
    const searchParams = new URLSearchParams(search);
    searchParams.set("t", t.toString());
    return `?${searchParams.toString()}`;
  };

  return (
    <Box border="1px" borderColor="gray.200" borderRadius="lg" fontSize="sm">
      <Flex pl="4" pr="7" py="2.5" fontWeight="medium" gap="4">
        <Box color="gray.900" flex="1">
          {header}
        </Box>
        {typeof headerStartTime === "number" && (
          <RouterLink
            fontWeight="medium"
            to={{ search: startTimeSearchParam(headerStartTime) }}
            onClick={() => onSeek(headerStartTime)}
          >
            <MonospacedText
              text={formatDuration(Math.round(headerStartTime))}
            />
          </RouterLink>
        )}
      </Flex>
      <Box color="gray.700" mb="3">
        {notes.length === 0 ? (
          <Box px="4" py="2" color="gray.500">
            {emptyText}
          </Box>
        ) : (
          <UnorderedList listStyleType="none" ml="0">
            {notes.map((note) => (
              <ListItem key={note.id} py="2" pr="3">
                <Flex align="baseline" gap="1.5">
                  <Icon as={GoDotFill} boxSize="2" ml="4" />
                  <Box flex="1">
                    <Text as="span" mr="1">
                      {note.text}
                    </Text>
                    <Text as="span" verticalAlign="top" fontSize="xs">
                      {note.startTimes?.map((t, idx) => (
                        <React.Fragment key={`${note.id}__${t}`}>
                          {idx > 0 && " "}
                          <Tooltip label={formatDuration(Math.round(t))}>
                            <RouterLink
                              fontWeight="normal"
                              to={{ search: startTimeSearchParam(t) }}
                              onClick={() => onSeek(t)}
                            >
                              <MonospacedText
                                display="inline-block"
                                text={footnoteMap[t]?.toString() ?? ""}
                              />
                            </RouterLink>
                          </Tooltip>
                        </React.Fragment>
                      ))}
                    </Text>
                  </Box>
                </Flex>
              </ListItem>
            ))}
          </UnorderedList>
        )}
      </Box>
    </Box>
  );
};
