// cspell:ignore mozfullscreenchange msfullscreenchange
import React, { useEffect, useState } from "react";

type CrossBrowserDocument = {
  mozFullscreenElement: Element;
  msFullscreenElement: Element;
  webkitFullscreenElement: Element;
  mozFullscreen: boolean;
  webkitIsFullscreen: boolean;
  fullScreenMode: boolean;
  mozCancelFullscreen: () => Promise<void>;
  webkitExitFullscreen: () => Promise<void>;
  msExitFullscreen: () => Promise<void>;
} & Document;

type CrossBrowserElement = {
  mozRequestFullscreen: () => Promise<void>;
  webkitRequestFullscreen: () => Promise<void>;
  msRequestFullscreen: () => Promise<void>;
} & HTMLElement;

interface UseFullscreen {
  isFullscreenSupported: boolean;
  isFullscreen: boolean;
  requestFullscreen?: () => Promise<void> | undefined;
  exitFullscreen?: () => Promise<void> | undefined;
  toggleFullscreen?: () => Promise<void> | undefined;
}

export const useFullscreen = (
  ref: React.MutableRefObject<HTMLElement | null>
): UseFullscreen => {
  const crossBrowserDocument = document as CrossBrowserDocument; // determine if we are in fullscreen mode and why
  const initialState = isFullscreenElement(ref);
  const [isFullscreen, setIsFullscreen] = useState(initialState);

  // access various open fullscreen methods
  const requestFullscreen = (): Promise<void> | undefined => {
    const el = (ref?.current ||
      crossBrowserDocument.documentElement) as CrossBrowserElement;
    if (el.requestFullscreen) return el.requestFullscreen();
    if (el.mozRequestFullscreen) return el.mozRequestFullscreen();
    if (el.webkitRequestFullscreen) return el.webkitRequestFullscreen();
    if (el.msRequestFullscreen) return el.msRequestFullscreen();
  };

  // access various exit fullscreen methods
  const exitFullscreen = (): Promise<void> | undefined => {
    if (crossBrowserDocument.exitFullscreen)
      return crossBrowserDocument.exitFullscreen();
    if (crossBrowserDocument.mozCancelFullscreen)
      return crossBrowserDocument.mozCancelFullscreen();
    if (crossBrowserDocument.webkitExitFullscreen)
      return crossBrowserDocument.webkitExitFullscreen();
    if (crossBrowserDocument.msExitFullscreen)
      return crossBrowserDocument.msExitFullscreen();
  };

  useEffect(() => {
    const handleChange = (): void => {
      setIsFullscreen(isFullscreenElement(ref));
    };

    document.addEventListener("webkitfullscreenchange", handleChange, false);
    document.addEventListener("mozfullscreenchange", handleChange, false);
    document.addEventListener("msfullscreenchange", handleChange, false);
    document.addEventListener("MSFullscreenChange", handleChange, false); // IE11
    document.addEventListener("fullscreenchange", handleChange, false);

    return () => {
      document.removeEventListener("webkitfullscreenchange", handleChange);
      document.removeEventListener("mozfullscreenchange", handleChange);
      document.removeEventListener("msfullscreenchange", handleChange);
      document.removeEventListener("MSFullscreenChange", handleChange);
      document.removeEventListener("fullscreenchange", handleChange);
    };
  }, [ref]);

  const isFullscreenSupported =
    crossBrowserDocument.fullscreenElement !== undefined ||
    crossBrowserDocument.mozFullscreenElement !== undefined ||
    crossBrowserDocument.webkitFullscreenElement !== undefined ||
    crossBrowserDocument.msFullscreenElement !== undefined;

  return {
    isFullscreenSupported,
    isFullscreen,
    requestFullscreen,
    exitFullscreen,
    toggleFullscreen: isFullscreen ? exitFullscreen : requestFullscreen,
  };
};

const isFullscreenElement = (
  ref: React.MutableRefObject<HTMLElement | null>
): boolean => {
  const crossBrowserDocument = document as CrossBrowserDocument;
  if (ref?.current) {
    return Boolean(
      crossBrowserDocument.fullscreenElement === ref.current ||
        crossBrowserDocument.mozFullscreenElement === ref.current ||
        crossBrowserDocument.webkitFullscreenElement === ref.current ||
        crossBrowserDocument.msFullscreenElement === ref.current
    );
  }

  return Boolean(
    crossBrowserDocument.fullscreenElement ||
      crossBrowserDocument.mozFullscreenElement ||
      crossBrowserDocument.webkitFullscreenElement ||
      crossBrowserDocument.msFullscreenElement ||
      crossBrowserDocument.fullscreen ||
      crossBrowserDocument.mozFullscreen ||
      crossBrowserDocument.webkitIsFullscreen ||
      crossBrowserDocument.fullScreenMode
  );
};
