import { Box, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import React from "react";

import useAnalyticsContext from "../useAnalyticsContext";
import AnalyticsAdmin from "./AnalyticsAdmin";
import OverviewSectionMetrics from "./OverviewSectionMetrics";
import OverviewSectionTips from "./OverviewSectionTips";
import OverviewSectionTotals from "./OverviewSectionTotals";
import { createOverviewConfig } from "./utils";

const Overview: React.FC = () => {
  const { selectedOrgId } = useAnalyticsContext();
  const overviewConfig = createOverviewConfig(selectedOrgId);

  return (
    <Flex flexDir="column" data-testid="analytics--overview">
      {/* Header */}
      <Box
        display="flex"
        alignItems="center"
        borderBottom="1px solid"
        borderColor="gray.100"
        pb="4"
        marginBottom="8"
      >
        <Flex w="100%" justify="space-between">
          <Text fontWeight={500} fontSize="xl" color="gray.800">
            Overview — Last 30 days
          </Text>
          <AnalyticsAdmin includeDemoDataOption={false} />
        </Flex>
      </Box>
      <Grid templateColumns="repeat(3, 1fr)" gap={4}>
        <GridItem colSpan={3}>
          <OverviewSectionTotals
            selectedOrgId={selectedOrgId}
            overviewConfig={overviewConfig}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <OverviewSectionMetrics
            selectedOrgId={selectedOrgId}
            overviewConfig={overviewConfig}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <OverviewSectionTips
            selectedOrgId={selectedOrgId}
            overviewConfig={overviewConfig}
          />
        </GridItem>
      </Grid>
    </Flex>
  );
};

export default Overview;
