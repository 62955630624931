import { useCallback, useState } from "react";

export type ComparisonLineToggleReturn = {
  showIQComparisonLine: boolean;
  toggleIQComparisonLine: () => void;
  showMIComparisonLine: boolean;
  toggleMIComparisonLine: () => void;
};

const useComparisonLineToggle = (): ComparisonLineToggleReturn => {
  // Both the IQ report chart and the My Insights metric charts show a comparison line series.
  // We want them disabled by default. They can be toggled on with a click. The state is persisted to local storage.
  const { localStorage: ls } = window;
  const [showIQComparisonLine, setShowIQComparisonLine] = useState<boolean>(
    ls.getItem("bh_iq_comparison_line") === "true"
  );
  const [showMIComparisonLine, setShowMIComparisonLine] = useState<boolean>(
    ls.getItem("bh_mi_comparison_line") === "true"
  );

  const toggleIQComparisonLine = useCallback(() => {
    const newValue = !showIQComparisonLine;
    setShowIQComparisonLine(newValue);
    ls.setItem("bh_iq_comparison_line", newValue.toString());
  }, [showIQComparisonLine, ls]);

  const toggleMIComparisonLine = useCallback(() => {
    const newValue = !showMIComparisonLine;
    setShowMIComparisonLine(newValue);
    ls.setItem("bh_mi_comparison_line", newValue.toString());
  }, [showMIComparisonLine, ls]);

  return {
    showIQComparisonLine,
    toggleIQComparisonLine,
    showMIComparisonLine,
    toggleMIComparisonLine,
  };
};

export default useComparisonLineToggle;
