import {
  Box,
  Flex,
  Menu,
  MenuDivider,
  MenuList,
  StyleProps,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  IoArrowDownOutline,
  IoArrowUpCircleSharp,
  IoArrowUpOutline,
  IoTrashOutline,
} from "react-icons/io5";

import {
  IconButton,
  MenuButton,
  RouterLink,
  useTheme,
} from "../../../components";
import Avatar from "../../../components/Avatar";
import { EditableArea } from "../../../components/EditableText/EditableArea";
import { formatRelativeDate } from "../../../utils/datetime";
import BasicAlertDialog from "../../components/BasicAlertDialog";
import {
  InterviewsListRow,
  LIST_ITEM_FLEX_FULL,
} from "../../components/InterviewsList";
import { Thumbnail } from "../../components/Thumbnail";
import { menuIconProps, TrainingMenuItem } from "./TrainingMenuItem";
import { TrainingListItemType } from "./types";
import { TrainingApi } from "./useTrainingApi";
import { reorder } from "./utils";

interface TrainingListItemProps extends StyleProps {
  trainingItem: TrainingListItemType;
  listLength?: number;
  listPosition?: number;
  trainingApi: TrainingApi;
  layout?: "full" | "compact";
  launched?: boolean;
  trainingProgramId?: string;
  setAutoSaveInProgress: (autoSaveInProgress: boolean) => void;
  trainingListItems?: TrainingListItemType[];
}

const TrainingListItem: React.FC<TrainingListItemProps> = ({
  trainingItem,
  listLength,
  listPosition,
  trainingApi,
  layout = "full",
  launched = false,
  trainingProgramId,
  setAutoSaveInProgress,
  trainingListItems,
  ...rest
}) => {
  const theme = useTheme();
  const { colors } = theme;
  const [deleting, setDeleting] = useState(false);

  return (
    <>
      <VStack
        align="flex-start"
        alignItems="flex-start"
        borderBottom="gray.100"
        {...rest}
      >
        <InterviewsListRow
          listLength={listLength}
          listPosition={listPosition}
          showDivider={false}
          showPadding={false}
          fullWidth
          py={layout === "full" ? undefined : "0"}
        >
          <Flex
            flexFlow="row nowrap"
            flex={LIST_ITEM_FLEX_FULL}
            position="relative"
            pl={layout === "full" ? "10" : undefined}
            overflow="auto"
          >
            <Box mr={layout === "full" ? "6" : "4"}>
              <RouterLink
                to={trainingItem.url}
                tabIndex={-1}
                _focus={{
                  boxShadow: "none",
                }}
                isDisabled={!!trainingItem.contentRedactedAt}
                _disabled={{ color: "initial" }}
              >
                <Thumbnail
                  size="md"
                  width={layout === "full" ? undefined : 186}
                  isPlayable={
                    trainingItem.hasRecordings &&
                    !trainingItem.contentRedactedAt
                  }
                  imageUrl={trainingItem.thumbnailImageUrl}
                  audioOnly={trainingItem.audioOnly}
                  time={trainingItem.duration}
                  isClip={trainingItem.isClip}
                />
              </RouterLink>
            </Box>
            <Flex direction="column" minWidth="0" flexGrow={1}>
              {trainingItem.positionDisplayTitle && (
                <Text
                  fontSize="sm"
                  color="gray.700"
                  mb="1"
                  mt={layout === "compact" ? "4" : "0"}
                  noOfLines={1}
                >
                  {trainingItem.positionDisplayTitle}
                </Text>
              )}
              <RouterLink
                to={trainingItem.url}
                color={colors.gray[900]}
                fontWeight="500"
                _hover={{ textDecoration: "underline", color: "inherit" }}
                isDisabled={!!trainingItem.contentRedactedAt}
              >
                <Text fontSize="xl" noOfLines={1}>
                  {trainingItem.name || "Missing call name"}
                </Text>
              </RouterLink>
              <Flex alignItems="center">
                {trainingItem.interviewers?.map((user) => (
                  <Avatar
                    user={user}
                    key={user.id}
                    borderRadius="100px"
                    mr="1"
                    mt="4"
                    showTooltip
                  />
                ))}
              </Flex>
              {layout === "full" && (
                <EditableArea
                  mt="9"
                  defaultValue={trainingItem.description || ""}
                  placeholder="Add a description..."
                  onSubmit={(description: string) => {
                    if (description !== trainingItem.description) {
                      trainingApi.recording.describe(
                        trainingItem.id,
                        description
                      );
                      setAutoSaveInProgress(true);
                    }
                  }}
                />
              )}
            </Flex>
            {layout === "full" && (
              <Flex
                flexDir="row"
                ml="auto"
                alignItems="center"
                alignSelf="start"
                shrink={0}
              >
                <Text fontSize="xs" color="gray.700" flexShrink={0} pr="4">
                  {formatRelativeDate(trainingItem.contentCreatedAt)}
                </Text>
                {trainingListItems && typeof listPosition === "number" && (
                  <Menu strategy="fixed">
                    <MenuButton
                      data-testid={`training-item-menu-button-${listPosition}`}
                      as={IconButton}
                      aria-label="Options"
                      icon={<BsThreeDotsVertical />}
                      variant="ghost"
                      size="xs"
                      mr="8"
                      colorScheme="gray"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <BsThreeDotsVertical size="16px" />
                    </MenuButton>
                    <MenuList>
                      <TrainingMenuItem
                        icon={<IoArrowUpCircleSharp {...menuIconProps} />}
                        isDisabled={listPosition === 0}
                        data-testid={`training-item-menu-move-to-top-${listPosition}`}
                        onClick={() => {
                          trainingApi.recording.reorder(
                            trainingProgramId as string,
                            reorder(trainingListItems, listPosition, 0).map(
                              (item) => item.id
                            )
                          );
                        }}
                      >
                        Move to top
                      </TrainingMenuItem>
                      <TrainingMenuItem
                        icon={<IoArrowUpOutline {...menuIconProps} />}
                        isDisabled={listPosition === 0}
                        data-testid={`training-item-menu-move-up-${listPosition}`}
                        onClick={() => {
                          trainingApi.recording.reorder(
                            trainingProgramId as string,
                            reorder(
                              trainingListItems,
                              listPosition,
                              listPosition - 1
                            ).map((item) => item.id)
                          );
                        }}
                      >
                        Move up
                      </TrainingMenuItem>
                      <TrainingMenuItem
                        icon={<IoArrowDownOutline {...menuIconProps} />}
                        isDisabled={
                          listPosition === trainingListItems.length - 1
                        }
                        data-testid={`training-item-menu-move-down-${listPosition}`}
                        onClick={() => {
                          trainingApi.recording.reorder(
                            trainingProgramId as string,
                            reorder(
                              trainingListItems,
                              listPosition,
                              listPosition + 1
                            ).map((item) => item.id)
                          );
                        }}
                      >
                        Move down
                      </TrainingMenuItem>
                      <MenuDivider />
                      <TrainingMenuItem
                        icon={<IoTrashOutline {...menuIconProps} />}
                        onClick={() => setDeleting(true)}
                      >
                        Delete
                      </TrainingMenuItem>
                    </MenuList>
                  </Menu>
                )}
              </Flex>
            )}
          </Flex>
        </InterviewsListRow>
      </VStack>
      {deleting && (
        <BasicAlertDialog
          title="Remove from Training Program?"
          body={
            <>
              {launched
                ? `Removing a recording from this program will instantly remove it 
                  for all trainees in the program (in progress and marked complete). 
                  Any completed assessments for this recording will be deleted too.`
                : `Are you sure you want to remove this recording from the training
                  program?`}
            </>
          }
          confirmLabel="Remove"
          onCancel={() => setDeleting(false)}
          onConfirm={() => {
            setDeleting(false);
            trainingApi.recording.remove(trainingItem.id);
            if (setAutoSaveInProgress) {
              setAutoSaveInProgress(true);
            }
          }}
        />
      )}
    </>
  );
};

export default TrainingListItem;
