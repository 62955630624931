export default {
  baseStyle: {
    fontWeight: "semibold",
    color: "link.default",
    _focus: {
      boxShadow: "0 0 0 3px blue.400",
    },
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed",
      textDecoration: "none",
      color: "link.disabled",
    },
    _hover: {
      color: "link.hover",
    },
    _active: {
      color: "link.active",
    },
  },
  variants: {
    gray: {
      color: "gray.800",
      _hover: {
        color: "gray.800",
      },
      _active: {
        color: "gray.800",
      },
    },
    lightGray: {
      color: "gray.500",
      _hover: {
        color: "gray.500",
      },
      _active: {
        color: "gray.500",
      },
    },
    darkGray: {
      color: "gray.900",
      _hover: {
        color: "gray.900",
      },
      _active: {
        color: "gray.900",
      },
    },
    white: {
      color: "white",
      _hover: {
        color: "gray.50",
      },
      _active: {
        color: "gray.100",
      },
    },
    primary: {
      fontSize: "sm",
      fontWeight: 500,
      color: "blue.600",
      _hover: {
        color: "blue.500",
        textDecoration: "none",
      },
      _focus: {
        color: "blue.700",
      },
      _active: {
        color: "blue.400",
      },
      _disabled: {
        color: "gray.200",
      },
    },
    bright: {
      color: "blue.600",
      _hover: {
        color: "blue.700",
        textDecoration: "none",
      },
      _active: {
        color: "blue.800",
      },
    },
    icon: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "xs",
      color: "gray.500",
      fill: "gray.500",
      width: "40px",
      height: "40px",
      verticalAlign: "middle",
      borderRadius: "8px",
      _hover: {
        color: "blue.600",
        bg: "blue.100",
        textDecoration: "none",
      },
      _focus: {
        bg: "blue.200",
        color: "blue.600",
      },
      _active: {
        bg: "blue.50",
        color: "blue.600",
      },
    },
    outlineButton: {
      fontSize: "xs",
      borderWidth: "1px",
      borderColor: "blue.500",
      color: "blue.500",
      px: "2.5",
      py: "1",
      borderRadius: "4px",
      lineHeight: "1.2",
      fontWeight: 500,
      _hover: {
        color: "blue.500",
        bg: "blue.100",
        textDecoration: "none",
      },
      _focus: {
        bg: "none",
        color: "blue.500",
      },
      _active: {
        bg: "blue.50",
        color: "blue.500",
      },
    },
    primaryButton: {
      fontSize: "sm",
      display: "inline-flex",
      alignItems: "center",
      border: "1px solid",
      borderColor: "blue.600",
      backgroundColor: "blue.600",
      color: "white",
      px: "3",
      borderRadius: "0.25rem",
      verticalAlign: "middle",
      height: "8",
      fontWeight: "medium",
      lineHeight: "1.2",
      _hover: {
        color: "white",
        textDecoration: "none",
      },
      _focus: {
        color: "white",
        textDecoration: "none",
      },
      _active: {
        bg: "blue.50",
      },
    },
  },
};
