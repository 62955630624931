import { Image } from "@chakra-ui/react";
import React, { useState } from "react";

import { UpgradePage as UpgradePageBase, useToast } from "../../../components";
import { usePageTracker } from "../../../utils/googleAnalytics";
import { FeatureName, useRequestUpgradeMutation } from "../../graphql";
import { DefaultPageLayout } from "../../layouts";

type UpgradePageProps = {
  headerText: string;
  bodyText: string;
  buttonText: string;
  upgradeImageSrc: string;
  featureName?: FeatureName;
};

const UpgradePage: React.FC<UpgradePageProps> = ({
  headerText,
  bodyText,
  buttonText,
  featureName,
  upgradeImageSrc,
}) => {
  let upgradeName = "interview";
  if (featureName) {
    upgradeName = featureName;
  }
  usePageTracker(`upgrade-${upgradeName}`);
  const toast = useToast();
  const showErrorToast = (): void => {
    toast({
      status: "error",
      title:
        "There was an error with submitting the request, please try again in some time",
    });
  };
  const [successfullySubmittedRequest, setSuccessfullySubmittedRequest] =
    useState(false);

  const [requestUpgrade] = useRequestUpgradeMutation({
    onCompleted: (data) => {
      if (data?.requestUpgrade?.success) {
        setSuccessfullySubmittedRequest(true);
        toast({
          status: "success",
          title:
            "Request submitted successfully. Our support team will be in touch with you with next steps shortly",
        });
      } else {
        showErrorToast();
      }
    },
    onError: () => {
      showErrorToast();
    },
  });

  const onContactSupport = (): void => {
    requestUpgrade({ variables: { featureName: upgradeName } });
  };

  return (
    <DefaultPageLayout>
      <UpgradePageBase
        h="100%"
        px="12"
        header={headerText}
        body={bodyText}
        buttonText={buttonText}
        splash={<Image src={upgradeImageSrc} />}
        requestSubmitted={successfullySubmittedRequest}
        onClick={onContactSupport}
      />
    </DefaultPageLayout>
  );
};

export default UpgradePage;
