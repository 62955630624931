import { Box, Flex, Icon, StyleProps } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";
import { HiOutlineInformationCircle } from "react-icons/hi2";

type SettingsNoteProps = PropsWithChildren<
  StyleProps & {
    hideIcon?: true;
    variant?: "error";
  }
>;

/** Renders `children` inside a gray "info" note box */
const SettingsNote = React.forwardRef<HTMLDivElement, SettingsNoteProps>(
  ({ hideIcon, variant, children, ...styles }, ref) => {
    const isError = variant === "error";
    const showIcon = !hideIcon;
    return (
      <Flex
        bg={isError ? "red.100" : "gray.50"}
        borderRadius="md"
        p="2"
        pl={hideIcon ? 3 : undefined}
        pb="2.5"
        gap="1"
        ref={ref}
        {...styles}
      >
        {showIcon && (
          <Icon
            as={HiOutlineInformationCircle}
            boxSize="4"
            m="0.5"
            color={isError ? "red.500" : "gray.500"}
          />
        )}
        <Box fontSize="sm" color="gray.900">
          {children}
        </Box>
      </Flex>
    );
  }
);

export default SettingsNote;
