import { Flex, Text } from "@chakra-ui/react";
import React from "react";

export const TOOLTIP_DATA_ROW_COL_W = "80px";

const TooltipDataRow: React.FC<{
  label: string;
  value: string;
  compareValue?: string;
  colWidth?: string;
  bulletColor?: string;
}> = ({
  label,
  value,
  compareValue,
  colWidth = TOOLTIP_DATA_ROW_COL_W,
  bulletColor,
}) => (
  <Flex
    flexDirection="row"
    align="center"
    justifyContent="space-between"
    w="100%"
  >
    {bulletColor && (
      <Flex
        w="10px"
        h="10px"
        borderRadius="full"
        bg={bulletColor}
        mr="2"
        border="1px solid"
        borderColor="white"
      />
    )}
    <Text>{label}</Text>
    <Flex ml="auto">
      {compareValue && <Text textAlign="right">{compareValue}</Text>}
      <Text w={colWidth} textAlign="right">
        {value}
      </Text>
    </Flex>
  </Flex>
);

export default TooltipDataRow;
