import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import { useSkillReportsQuery } from "../../../graphql";
import { useAnalyticsSelectTheme } from "../useAnalyticsSelectTheme";

const SkillsReportSelect: React.FC<{
  reportId: string;
  reportTitle: string;
}> = ({ reportId, reportTitle }) => {
  const navigate = useNavigate();
  const [selectTheme, selectStyles] = useAnalyticsSelectTheme();
  const { error, data } = useSkillReportsQuery({
    fetchPolicy: "cache-and-network",
  });
  if (error)
    return (
      <Box>
        <Text color="primary">{reportTitle || "Untitled report"}</Text>
      </Box>
    );
  const reports = data?.currentUser?.organization.skillReports || [];
  const sortedReports = [...reports]
    .map((report) => ({
      value: report.id,
      label: report.title || "Untitled report",
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const {
    container: containerStyles = {},
    menu: menuStyles = {},
    ...selectStylesRest
  } = selectStyles;
  const commonSelectStyles = {
    container: (provided: Record<string, any>) => ({
      ...provided,
      flex: 1,
      minWidth: 400,
      ...containerStyles,
    }),
    menu: (provided: Record<string, any>) => ({
      ...provided,
      width: 400,
      ...menuStyles,
      fontSize: "sm",
    }),
    ...selectStylesRest,
  };

  return (
    <Select
      {...selectTheme}
      styles={commonSelectStyles}
      onChange={(newValue) => {
        if (!newValue) return;
        navigate(`/insights/skill-report/${newValue?.value}`);
      }}
      value={sortedReports.find((r) => r.value === reportId)}
      options={sortedReports}
    />
  );
};

export default SkillsReportSelect;
