import {
  Icon,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import React from "react";
import { HiOutlineCalendar } from "react-icons/hi";

export const CustomDateInput = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => (
    <Input
      boxShadow="none"
      borderColor="#ccc"
      borderRadius="base"
      height="38px"
      width="122px"
      {...props}
      ref={ref}
    />
  )
);

export const CustomDateTimeInput = React.forwardRef<
  HTMLInputElement,
  InputProps
>((props, ref) => (
  <InputGroup>
    <Input {...props} ref={ref} />
    <InputRightElement pointerEvents="none">
      <Icon as={HiOutlineCalendar} color="gray.400" />
    </InputRightElement>
  </InputGroup>
));
