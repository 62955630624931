import { Box, Divider, Flex, Stack } from "@chakra-ui/react";
import React, { useState } from "react";

import { Button } from "../Buttons";
import { KeywordResult } from "./KeywordResult";
import { KeywordHit } from "./types";

const KeywordResults: React.FC<{ hits: KeywordHit[] }> = ({ hits }) => {
  const [fullSearch, setFullSearch] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>(
    "View all conversations"
  );
  const onClick = (): void => {
    if (fullSearch) {
      setButtonText("View all conversations");
    } else {
      setButtonText("View recent conversations");
    }
    setFullSearch(!fullSearch);
  };
  return (
    <>
      <Divider />
      <Flex flexDirection="row" justifyContent="start" alignItems="center">
        <Box pt="4" pb="4" pr="4" fontSize="sm" fontWeight="bold">
          Recent Conversations
        </Box>
      </Flex>
      <Divider />
      <Stack>
        {fullSearch
          ? hits.map((hit) => <KeywordResult key={hit.objectID} hit={hit} />)
          : hits
              .slice(0, 4)
              .map((hit) => <KeywordResult key={hit.objectID} hit={hit} />)}
      </Stack>
      <Box p="4" display="flex" justifyContent="center">
        <Button
          variant="outline"
          fontSize="sm"
          size="md"
          onClick={onClick}
          justifySelf="center"
        >
          {buttonText}
        </Button>
      </Box>
    </>
  );
};

export default KeywordResults;
