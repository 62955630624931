// cspell:ignore didn
import { Text } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

import { EmptyAIIcon } from "../../../components";
import AiNotesMessage from "../Recording/SidebarTabs/Ai/Notes/NotesQA/AiNotesMessage";

export const AiNotesError: React.FC<
  PropsWithChildren<{ message: React.ReactNode; colorScheme?: "purple" }>
> = ({ message, colorScheme, children }) => (
  <AiNotesMessage>
    <EmptyAIIcon colorScheme={colorScheme} mb="5" />
    <Text align="center" maxW="360px">
      {message}
    </Text>
    {children}
  </AiNotesMessage>
);

export const AiNotesNoNotes: React.FC = () => (
  <AiNotesError
    message={
      <>
        Our current AI is built to detect interview questions and answers — we
        didn&apos;t detect any in this conversation but stay tuned for
        improvements.
      </>
    }
  />
);

export const AiNotesNoTopicNotes: React.FC = () => (
  <AiNotesError
    message={
      <>We didn&apos;t detect any mentions of this topic in the interview.</>
    }
  />
);

export const AiNotesNoTranscript: React.FC = () => (
  <AiNotesError
    colorScheme="purple"
    message={
      <>
        Hold tight! AI Notes are generated once the transcript for the interview
        is ready.
      </>
    }
  />
);

export const AiNotesNoCandidate: React.FC<{
  canUseOtherAISummaryFormats: boolean;
}> = ({ canUseOtherAISummaryFormats }) => (
  <AiNotesError
    colorScheme="purple"
    message={
      <>
        AI Notes are not available for this interview because we were unable to
        identify a candidate.
      </>
    }
  >
    {canUseOtherAISummaryFormats && (
      <Text align="center" mt={3} mb={3} maxW="360px">
        If this was not an interview, try selecting a different AI Notes format.
      </Text>
    )}
  </AiNotesError>
);

export const AiNotesStatusFailed: React.FC = () => (
  <AiNotesError
    message={
      <>
        We are experiencing some technical issues in generating AI notes at this
        moment. Please stay tuned as we work to resolve these issues.
      </>
    }
  />
);

export const AiNotesGenericError: React.FC = () => (
  <AiNotesError
    message={
      <>
        Oops! There was an error fetching AI notes. Please reload the page or
        try again later.
      </>
    }
  />
);
