import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { HiPlus } from "react-icons/hi";
import { IoPeople } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import { Button, LoadingIndicator, SearchInput } from "../../../components";
import useToast, {
  errorToast,
  successToast,
} from "../../../components/useToast/useToast";
import {
  useAddClipsToTrainingProgramMutation,
  useCreateTrainingProgramItemMutation,
  useCreateTrainingProgramMutation,
  useTrainingProgramManagementListItemsQuery,
} from "../../graphql";
import { TrainingNameModalContent } from "../../pages/training/TrainingNameModal";
import { SidebarItem } from "../Sidebar";

interface AddToTrainingProgramModal {
  onClose(): void;
  callId: string;
  clipIds?: Array<string>;
}

type ModalState = "addToTrainingProgram" | "createTrainingProgram";

const AddToTrainingProgramModal: React.FC<AddToTrainingProgramModal> = ({
  onClose,
  callId,
  clipIds,
}) => {
  // TODO: limit to managers
  const navigate = useNavigate();
  const clipId = clipIds?.length === 1 ? clipIds[0] : undefined;

  const [query, setQuery] = useState("");
  const { data, loading, error } = useTrainingProgramManagementListItemsQuery({
    fetchPolicy: "network-only",
    variables: {
      excludedCallId: callId,
      excludedClipId: clipId,
    },
  });

  const trainingProgramsWithRecordingQuery =
    useTrainingProgramManagementListItemsQuery({
      variables: { callId, clipId },
      fetchPolicy: "network-only",
    });

  const [modal, setModal] = useState<ModalState>("addToTrainingProgram");

  const [createTrainingProgramMutation] = useCreateTrainingProgramMutation();

  const [addClipsToTrainingProgramMutation] =
    useAddClipsToTrainingProgramMutation({
      onCompleted: (data) => {
        const programId = data.addClipsToTrainingProgram?.trainingProgram.id;
        if (programId) {
          successToast(
            toast,
            <>
              Playlist added to training program.{" "}
              <Button
                onClick={() => navigate(`/training/manage/${programId}`)}
                variant="link"
                color="white"
                fontSize="md"
                fontWeight="500"
                _active={{ color: "white" }}
                textDecoration="underline"
              >
                View program
              </Button>
            </>
          );
        }
      },
      onError: (err) => {
        errorToast(
          toast,
          `Error adding item to training program: ${err.message}`
        );
      },
    });

  const [createTrainingProgramItem] = useCreateTrainingProgramItemMutation({
    onCompleted: (data) => {
      const programId = data.createTrainingProgramItem?.trainingProgram.id;
      if (programId) {
        successToast(
          toast,
          <>
            {clipId ? "Clip" : "Interview"} added to training program.{" "}
            <Button
              onClick={() => navigate(`/training/manage/${programId}`)}
              variant="link"
              color="white"
              fontSize="md"
              fontWeight="500"
              _active={{ color: "white" }}
              textDecoration="underline"
            >
              View program
            </Button>
          </>
        );
      }
    },
    onError: (err) => {
      errorToast(
        toast,
        `Error adding item to training program: ${err.message}`
      );
    },
  });

  const trainingPrograms = data?.trainingProgramManagementListItems;
  const toast = useToast();

  useEffect(() => {
    if (error) {
      errorToast(toast, `Error loading training programs: ${error.message}`);
      onClose();
    }
  });

  const matchingTrainingPrograms = (trainingPrograms || []).filter((p) =>
    p.name.toLowerCase().includes(query.toLowerCase())
  );

  const onCreateProgram = (name: string, description?: string): void => {
    createTrainingProgramMutation({
      variables: {
        name,
        description,
      },
      onError: (error) => {
        errorToast(toast, `Error creating training program: ${error.message}`);
      },
      onCompleted: (data) => {
        if (data?.createTrainingProgram) {
          createTrainingProgramItem({
            variables: {
              trainingProgramId: data.createTrainingProgram.trainingProgram.id,
              callId,
              clipId,
            },
          });
        }
      },
    });
  };

  const trainingProgramsWithRecording =
    trainingProgramsWithRecordingQuery.data
      ?.trainingProgramManagementListItems || [];
  const anyLoading = loading || trainingProgramsWithRecordingQuery.loading;

  return (
    <Modal isOpen onClose={onClose} isCentered>
      <ModalOverlay>
        {modal === "addToTrainingProgram" && (
          <ModalContent
            borderRadius="8px"
            width="500px"
            maxWidth="500px"
            data-tour-id="training-add-trainee"
          >
            <ModalHeader
              px="6"
              pt="6"
              pb="6"
              fontSize="20px"
              fontWeight="500"
              borderColor="gray.100"
              borderBottomWidth="1px"
            >
              Add to a Training Program
            </ModalHeader>
            <ModalCloseButton mt="2" />
            <ModalBody
              display="flex"
              flexDir="column"
              h="370px"
              minH="370px"
              maxH="370px"
              pb="0"
              pr="0"
              pl="0"
              borderBottomRightRadius="8px"
              overflow="hidden"
            >
              {anyLoading && <LoadingIndicator />}
              {!anyLoading && (
                <>
                  <Box mt="4" pb="1" pl="8">
                    <SearchInput
                      onSearch={setQuery}
                      maxW="inherit"
                      height="2.375rem"
                      inputHeight="2.375rem"
                      fontSize="sm"
                      placeholder={`Search ${
                        trainingPrograms?.length || 0
                      } Programs`}
                      borderRadius="4px"
                      autoFocus
                      pr={3}
                      mr={8}
                    />
                  </Box>
                  <Flex overflow="auto" flexDir="column" flexGrow={1} pb="2">
                    <ContainedInOtherPrograms
                      programs={trainingProgramsWithRecording}
                      recordingType={clipId ? "clip" : "call"}
                    />
                    {matchingTrainingPrograms.length > 0 && (
                      <Box
                        ml="8"
                        mt={
                          trainingProgramsWithRecording.length > 0 ? "0" : "2"
                        }
                        mb="3"
                      >
                        <SidebarItem
                          text="New Training Program"
                          icon={HiPlus}
                          color="blue.600"
                          onClick={() => setModal("createTrainingProgram")}
                          data-tour-id="training-create-program"
                          data-testid="training-create-program"
                          maxW="436px"
                        />
                        {matchingTrainingPrograms.map((trainingProgram) => (
                          <SidebarItem
                            key={trainingProgram.id}
                            onClick={() => {
                              if (clipIds) {
                                addClipsToTrainingProgramMutation({
                                  variables: {
                                    trainingProgramId: trainingProgram.id,
                                    callId,
                                    clipIds,
                                  },
                                });
                              } else {
                                createTrainingProgramItem({
                                  variables: {
                                    trainingProgramId: trainingProgram.id,
                                    callId,
                                    clipId,
                                  },
                                });
                              }
                              onClose();
                            }}
                            text={trainingProgram.name}
                            badgeIcon={
                              trainingProgram.draft ? undefined : IoPeople
                            }
                            badge={
                              trainingProgram.draft
                                ? "Draft"
                                : trainingProgram.trainees.toString()
                            }
                            maxW="436px"
                          />
                        ))}
                      </Box>
                    )}
                    {matchingTrainingPrograms.length === 0 && (
                      <EmptyState
                        onCreateProgram={() =>
                          setModal("createTrainingProgram")
                        }
                      />
                    )}
                  </Flex>
                </>
              )}
            </ModalBody>
          </ModalContent>
        )}
        {modal === "createTrainingProgram" && (
          <TrainingNameModalContent
            mode="create"
            onClose={onClose}
            initialFocus="name"
            onSave={onCreateProgram}
          />
        )}
      </ModalOverlay>
    </Modal>
  );
};

type ContainedInOtherProgramsProps = {
  programs: {
    name: string;
  }[];
  recordingType: "call" | "clip";
};

const ContainedInOtherPrograms: React.FC<ContainedInOtherProgramsProps> = ({
  programs,
  recordingType,
}) => {
  if (programs.length === 0) {
    return null;
  }

  return (
    <Flex
      background="gray.50"
      ml="8"
      mt="2"
      mb="1"
      maxW="436px"
      minH="48px"
      alignItems="center"
      borderRadius="4px"
      color="gray.700"
      px="4"
      fontSize="14px"
      userSelect="none"
    >
      <Text color="gray.700">
        {programs.length}{" "}
        {programs.length > 1 ? "Programs contain" : "Program contains"} this{" "}
        {recordingType}.
      </Text>
      <Tooltip label={programs.map((pn) => pn.name).join("\n")}>
        <Text
          display="inline"
          fontWeight="500"
          color="gray.500"
          pl="2"
          ml="auto"
        >
          View Programs
        </Text>
      </Tooltip>
    </Flex>
  );
};

type EmptyStateProps = {
  onCreateProgram(): void;
};

const EmptyState: React.FC<EmptyStateProps> = ({ onCreateProgram }) => {
  return (
    <Flex flexDir="column" flexGrow={1} alignItems="center" mt="68px">
      <Text fontSize="sm" mb="4">
        No training programs to show here
      </Text>
      <Button
        variant="link"
        color="blue.600"
        fontSize="sm"
        fontWeight="500"
        textTransform="none"
        onClick={onCreateProgram}
      >
        Create a new training program
      </Button>
    </Flex>
  );
};
export default AddToTrainingProgramModal;
