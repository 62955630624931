import { Flex, Text } from "@chakra-ui/react";
import React from "react";

const OverviewSection: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ children, title }) => {
  return (
    <Flex direction="column">
      <Text
        mt="2"
        mb="4"
        color="gray.500"
        fontWeight="600"
        textTransform="uppercase"
        fontSize="xs"
      >
        {title}
      </Text>
      {children}
    </Flex>
  );
};

export default OverviewSection;
