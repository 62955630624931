import { useDisclosure } from "@chakra-ui/react";
import React from "react";

import {
  Button,
  ConfirmModal,
  successToast,
  useToast,
} from "../../../components";
import { OrgUsersDocument, useDeleteUsersMutation } from "../../graphql";

interface DisableUsersButtonProps {
  userIds: string[];
}

const DisableUsersButton: React.FC<DisableUsersButtonProps> = ({ userIds }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteUsers] = useDeleteUsersMutation({
    onCompleted: (data) => {
      if (data.deleteUsers?.success) {
        successToast(toast, "Users disabled.");
        onClose();
      }
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
      onClose();
    },
    refetchQueries: [OrgUsersDocument],
  });
  return (
    <>
      <Button
        size="xs"
        variant="dangerOutline"
        isDisabled={userIds.length < 1}
        onClick={onOpen}
      >
        Disable users
      </Button>
      <ConfirmModal
        modalBodyText={`Are you sure you want to disable ${userIds.length} users?`}
        isOpen={isOpen}
        onCancel={onClose}
        onConfirm={async () => {
          await deleteUsers({
            variables: {
              ids: userIds,
            },
          });
        }}
      />
    </>
  );
};

export default DisableUsersButton;
