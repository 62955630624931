import { Box, Flex, Heading, useInterval } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

import { Card, LoadingIndicator } from "../../../../components";
import { itemAnimation, listAnimation } from "../../../../components/Animation";
import useIntersectionObserver from "../../../../hooks/useIntersectionObserver";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { InterviewMenuOptions } from "../../../components/InterviewsList";
import { useCurrentUserCallsQuery } from "../../../graphql";
import CompletedInterviewsListItem from "./CompletedInterviewsListItem";
import { CompletedEmptyState, CompletedErrorState } from "./EmptyState";

const PAGE_LIMIT = 10;
const REFRESH_DELAY = 1000 * 60 * 5;

const CompletedInterviews: React.FC = () => {
  const sendGAEvent = useSendGAEvent();
  const onClickLink = (itemType: string): void =>
    sendGAEvent("Link to", "home_page", itemType, "Completed (mobile)");

  const [date] = useState<string>(new Date().toISOString().substring(0, 19));
  const sentinelRef = useRef<HTMLDivElement>(null);
  const sentinelInView = useIntersectionObserver(sentinelRef, {
    rootMargin: "20%",
  });

  const { loading, error, data, fetchMore, refetch } = useCurrentUserCallsQuery(
    {
      variables: {
        start: null,
        end: date,
        positionId: null,
        pagination: {
          limit: PAGE_LIMIT,
        },
      },
    }
  );
  const calls = data?.currentUser?.calls?.results || [];
  const pageInfo = data?.currentUser?.calls?.pageInfo || {
    hasNextPage: false,
    hasPreviousPage: false,
  };

  /**
   * Executed when the user loads a new page of results
   */
  const onLoadMore = (): void => {
    fetchMore({
      variables: {
        start: null,
        end: date,
        positionId: null,
        pagination: {
          limit: calls.length + PAGE_LIMIT,
        },
      },
    });
  };

  useEffect(() => {
    if (sentinelInView && pageInfo.hasNextPage) {
      onLoadMore();
    }
  }, [sentinelInView]);

  /**
   * Executes when it's time to refresh the contents of the list
   */
  const onRefresh = (): void => {
    refetch({
      pagination: {
        limit: Math.max(calls.length, PAGE_LIMIT),
      },
    });
  };

  // Execute onRefresh on a set timer
  useInterval(onRefresh, REFRESH_DELAY);

  return (
    <Box order={2} marginBottom={calls.length ? 12 : 20}>
      <Flex width="100%" justifyContent="space-between" mb="4">
        <Heading
          lineHeight="7"
          size="md"
          fontWeight="semibold"
          data-tour-id="completed"
        >
          Completed interviews
        </Heading>
      </Flex>
      <Card
        data-testid="completed-interviews"
        pt={0}
        pb={0}
        px={0}
        variant="transparent"
        maxW="100%"
        overflowX="auto"
      >
        {!error && (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={listAnimation}
          >
            {calls.map((call, i) => {
              return (
                <motion.div
                  variants={itemAnimation}
                  key={`completed-interviews-row-item-${i + 1}`}
                >
                  <CompletedInterviewsListItem
                    call={call}
                    key={call.id}
                    listPosition={i + 1}
                    menuOptions={[
                      InterviewMenuOptions.SHARE,
                      InterviewMenuOptions.DELETE,
                    ]}
                    onClickCandidate={() => onClickLink("Candidate")}
                    onClickPosition={() => onClickLink("Position")}
                    onClickInterview={() => onClickLink("Interview")}
                  />
                </motion.div>
              );
            })}
          </motion.div>
        )}
        <Box width="100%" mb="2" ref={sentinelRef} />
        {!loading && !error && pageInfo.hasNextPage === false && (
          <Box my={2} color="gray.500">
            No more items to show
          </Box>
        )}
        {loading && (
          <Flex width="100%" height="100%">
            <LoadingIndicator py={6} />
          </Flex>
        )}
        {!loading && !error && calls.length === 0 && <CompletedEmptyState />}
        {!loading && error && <CompletedErrorState />}
      </Card>
    </Box>
  );
};

export default CompletedInterviews;
