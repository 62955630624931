import { Flex, Input, Text } from "@chakra-ui/react";
import React, { useState } from "react";

import { Button } from "../../../components";
import { CodeVerificationResponse } from "./types";

type VerifyAccessCodeFormProps = {
  onRequest(): void;
  onVerify(verificationCode: string): void;
  codeVerificationResponse: CodeVerificationResponse;
};

export const VerifyAccessCodeForm: React.FC<VerifyAccessCodeFormProps> = ({
  onRequest,
  onVerify,
  codeVerificationResponse,
}) => {
  const [accessCode, setAccessCode] = useState("");
  return (
    <>
      <Flex maxWidth="315px" mx="auto">
        <Input
          mr="4"
          borderColor="gray.800"
          maxWidth="200px"
          borderRadius="4px"
          value={accessCode}
          onChange={(e) => {
            setAccessCode(e.target.value);
          }}
        />
        <Button
          fontSize="sm"
          fontWeight="500"
          py="10px"
          px="5"
          onClick={() => onVerify(accessCode)}
          disabled={!accessCode.length}
        >
          Continue
        </Button>
      </Flex>
      {codeVerificationResponse === "invalid" && (
        <Text fontSize="md" fontWeight="400" color="red.600" mt="4">
          Hmm, that access code isn&apos;t correct. Please try again.
        </Text>
      )}
      {codeVerificationResponse === "too_many_attempts" && (
        <Flex
          flexDir="row"
          mt="4"
          mx="auto"
          maxWidth="800px"
          justifyContent="center"
        >
          <Text mr="1" color="gray.800" opacity="0.8" fontSize="md">
            That access code can no longer be used. Please wait 10 minutes
            before
          </Text>
          <Button
            onClick={onRequest}
            fontSize="md"
            fontWeight="400"
            color="blue.600"
            variant="link"
            textTransform="none"
          >
            requesting a new one.
          </Button>
        </Flex>
      )}
      {codeVerificationResponse === "expired" && (
        <Flex
          flexDir="row"
          mt="4"
          mx="auto"
          maxWidth="600px"
          justifyContent="center"
        >
          <Text mr="2" color="gray.800" opacity="0.8" fontSize="md">
            That access code is expired.
          </Text>
          <Button
            onClick={onRequest}
            fontSize="md"
            fontWeight="400"
            color="blue.600"
            variant="link"
            textTransform="none"
          >
            Request a new one.
          </Button>
        </Flex>
      )}
    </>
  );
};
