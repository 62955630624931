import { Center, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import {
  HiOutlineChatAlt2,
  HiOutlineClock,
  HiOutlineUsers,
} from "react-icons/hi";

import { Alert as ErrorAlert, LoadingIndicator } from "../../../../components";
import {
  MetricName,
  useAnalyticsOrgInterviewTotalsQuery,
} from "../../../graphql";
import { getPercentDifferenceDisplay } from "../difference";
import { MetricConfig } from "../MetricConfig";
import { getStructuredFormatFromValue } from "../utils";
import OverviewCard from "./OverviewCard";
import OverviewSection from "./OverviewSection";
import { OverviewConfig } from "./types";

type OverviewSectionTotalsProps = {
  /* The organization ID */
  // Organization ID is set by analytics context to allow for internal org switching
  selectedOrgId: string;
  overviewConfig: OverviewConfig;
};

const OverviewSectionTotals: React.FC<OverviewSectionTotalsProps> = ({
  selectedOrgId,
  overviewConfig,
}) => {
  const { queryOptions, compareQueryOptions } = overviewConfig;
  const totalsQuery = useAnalyticsOrgInterviewTotalsQuery({
    variables: {
      requestedOrganizationId: selectedOrgId,
      dateRangeStart: queryOptions.dateRangeStart,
      dateRangeEnd: queryOptions.dateRangeEnd,
    },
  });
  const totals = totalsQuery.data?.orgInterviewTotals.values;

  const compareTotalsQuery = useAnalyticsOrgInterviewTotalsQuery({
    variables: {
      requestedOrganizationId: selectedOrgId,
      dateRangeStart: compareQueryOptions?.dateRangeStart || "",
      dateRangeEnd: compareQueryOptions?.dateRangeEnd || "",
    },
  });

  const totalInterviewsPercentDifference = getPercentDifferenceDisplay(
    totals?.totalDuration,
    compareTotalsQuery.data?.orgInterviewTotals.values?.totalDuration
  );
  const totalDurationPercentDifference = getPercentDifferenceDisplay(
    totals?.totalInterviews,
    compareTotalsQuery.data?.orgInterviewTotals.values?.totalInterviews
  );
  const totalInterviewersPercentDifference = getPercentDifferenceDisplay(
    totals?.totalInterviewers,
    compareTotalsQuery.data?.orgInterviewTotals.values?.totalInterviewers
  );

  return (
    <OverviewSection title="Overall">
      <SimpleGrid columns={1} spacing="4">
        {totalsQuery.loading && (
          <Center>
            <LoadingIndicator />
          </Center>
        )}
        {totalsQuery.error && (
          <ErrorAlert
            status="error"
            description="There was an error fetching your organization data."
          />
        )}
        {!totalsQuery.loading && !totalsQuery.error && totals && (
          <SimpleGrid columns={3} spacing="4">
            <OverviewCard
              title="Interview Count"
              icon={HiOutlineChatAlt2}
              value={totals.totalInterviews?.toString() || "—"}
              percentDifference={totalInterviewsPercentDifference}
              tooltipLabel={
                MetricConfig[MetricName.TotalInterviews].detailsPlainText
              }
            />
            <OverviewCard
              title="Interview Time"
              icon={HiOutlineClock}
              value={
                totals.totalDuration
                  ? getStructuredFormatFromValue(
                      totals.totalDuration,
                      "days-hours"
                    )
                  : "—"
              }
              percentDifference={totalDurationPercentDifference}
              tooltipLabel={
                MetricConfig[MetricName.TotalInterviewTime].detailsPlainText
              }
            />
            <OverviewCard
              title="Interviewers"
              icon={HiOutlineUsers}
              value={totals.totalInterviewers?.toString() || "—"}
              percentDifference={totalInterviewersPercentDifference}
              tooltipLabel="Total Interviewers is the count of all interviewers who conducted interviews during the selected date range. The data for this report includes all interviews over two minutes in length with all speakers labeled."
            />
          </SimpleGrid>
        )}
      </SimpleGrid>
    </OverviewSection>
  );
};

export default OverviewSectionTotals;
