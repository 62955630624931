import {
  AnalyticsBenchmarksQuery,
  AnalyticsReportMyInsightsQuery,
  ReportDataPoint,
  useAnalyticsBenchmarksQuery,
  useAnalyticsReportMyInsightsQuery,
} from "../../../graphql";
import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";
import { OverviewConfig } from "./types";

export type OverviewData = {
  baseData: ReportDataPoint[];
  comparisonData: ReportDataPoint[];
  columnsConfig: AnalyticsReportMyInsightsQuery["qualityReportTrends"]["config"];
  loading: boolean;
  error?: any;
  bucketSize: number;
  bucketInterval: string;
  benchmarks: AnalyticsBenchmarksQuery["benchmarks"]["data"];
};

/**
 * Query results and any error states related to My Insights report.
 */
const useOverviewData = (
  overviewConfig: OverviewConfig,
  selectedOrgId: string
): OverviewData => {
  const overviewEnabled = useFeatureFlag("analytics:overview");

  const dataQuery = useAnalyticsReportMyInsightsQuery({
    variables: {
      requestedOrganizationId: selectedOrgId,
      dateRangeStart: overviewConfig.queryOptions.dateRangeStart,
      dateRangeEnd: overviewConfig.queryOptions.dateRangeEnd,
      includeRawResults: true,
      positions: [],
      interviewers: [],
      departments: [],
      stages: [],
    },
    skip: !overviewEnabled,
  });

  const baseData = dataQuery.data?.qualityReportTrends?.data || [];
  const comparisonData =
    dataQuery.data?.qualityReportTrends?.comparisonData || [];

  const { bucketSize, bucketInterval } =
    dataQuery.data?.qualityReportTrends || {};

  const columnsConfig = dataQuery.data?.qualityReportTrends?.config || [];

  // benchmarks
  const analyticsBenchmarksQuery = useAnalyticsBenchmarksQuery();
  const benchmarks = analyticsBenchmarksQuery.data?.benchmarks?.data || [];

  return {
    baseData,
    comparisonData,
    columnsConfig,
    loading: dataQuery.loading,
    error: dataQuery.error,
    bucketSize: bucketSize || 0,
    bucketInterval: bucketInterval || "",
    benchmarks,
  };
};

export default useOverviewData;
