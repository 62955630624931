import { Flex } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

import { Button, ContentEditable } from "../../../../components";
import { formatDuration } from "../../../../utils/datetime";
import { CallNoteType, ShareUserFragment } from "../../../graphql";
import Timestamp from "../../CallNotes/Timestamp";
import { MentionMenuBeta } from "../../MentionMenu";

type ActionBarNoteSectionProps = {
  isOpen: boolean;
  isFullscreen: boolean;
  noteType: CallNoteType;
  onAddNote(opts: {
    text: string;
    type: CallNoteType;
    time: number;
  }): Promise<void>;
  setIsOpen(isOpen: boolean): void;
  time: number;
  shareableUsers: ShareUserFragment[] | undefined;
  shareableUsersLoading: boolean | undefined;
};

const ActionBarNoteSection: React.FC<ActionBarNoteSectionProps> = ({
  isOpen,
  isFullscreen,
  noteType,
  onAddNote,
  setIsOpen,
  time,
  shareableUsers,
  shareableUsersLoading = false,
}) => {
  const inputRef = useRef<HTMLDivElement | null>(null);
  const [noteText, setNoteText] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSendingNote, setIsSendingNote] = useState(false);
  const [noteTime, setNoteTime] = useState(time);

  useEffect(() => {
    if (isOpen) {
      setNoteTime(time);
    }
  }, [isOpen]);

  useEffect(() => {
    if (inputRef.current) {
      if (isOpen) {
        inputRef.current.focus();
      } else {
        inputRef.current.textContent = "";
      }
    }
  }, [isOpen, inputRef.current]);

  const onClose = (): void => {
    setIsOpen(false);
    setNoteText("");
  };

  const addNote = async (): Promise<void> => {
    setIsSendingNote(true);
    await onAddNote({ text: noteText, type: noteType, time: noteTime });
    setIsSendingNote(false);
    onClose();
  };

  return (
    <>
      <MentionMenuBeta
        inputRef={inputRef}
        shareableUsers={shareableUsers}
        loading={shareableUsersLoading}
        zIndex="4"
        onToggle={setMenuOpen}
      />
      {isOpen && (
        <Flex
          bg="linear-gradient(180deg, #010D17 0%, #012A4F 100%);"
          borderTopWidth="1px"
          borderTopColor="#2A343C"
          pos={isFullscreen ? "fixed" : "absolute"}
          bottom="0"
          minH={isFullscreen ? undefined : "100%"}
          w="100%"
          pt={isFullscreen ? "7" : "4"}
          px="3"
          pb={isFullscreen ? "8" : "4"}
          zIndex="3"
          justifyContent="center"
        >
          <Flex flexGrow="1" maxWidth="800px" margin="auto" alignItems="center">
            <Flex
              alignItems="center"
              bg="white"
              border="1px"
              borderColor="whiteAlpha.500"
              borderRadius="md"
              py="2"
              px="3"
              flex="1 1 70%"
              _hover={{ borderColor: "whiteAlpha.500" }}
              _focus={{ borderColor: "whiteAlpha.500" }}
            >
              <Timestamp fontWeight="semibold" color="blackAlpha.900" mr="3">
                {formatDuration(noteTime)}
              </Timestamp>

              <ContentEditable
                ref={inputRef}
                border="none"
                onKeyDown={({ key, shiftKey }) => {
                  if (key === "Escape") {
                    onClose();
                  }

                  // Submit on "Enter", unless "Shift" + "Enter" or mention menu is open
                  else if (key === "Enter" && !(shiftKey || menuOpen)) {
                    addNote();
                  }
                }}
                placeholder="Add note & tag others with @"
                flexGrow="1"
                onChange={(text) => setNoteText(text ?? "")}
                fontSize="md"
                color="black"
              />
            </Flex>

            <Flex justifyContent="end" ml="4" alignItems="center">
              <Button
                mr="3"
                disabled={!noteText || isSendingNote}
                onClick={addNote}
                variant="whiteOutline"
              >
                Add note
              </Button>
              <Button
                color="white"
                onClick={onClose}
                variant="ghost"
                size="sm"
                _active={{ bg: "red.300", color: "gray.900" }}
                _hover={{ bg: "red.200", color: "gray.800" }}
              >
                Cancel
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default ActionBarNoteSection;
