import { ForwardedRef, RefObject, useEffect, useRef } from "react";

/**
 * Use when a component needs to be wrapped in `forwardRef` and *also* needs to
 * use a `ref` internally.
 *
 * Pass the `ref` from `forwardRef` to this hook, and use the return value for
 * the internal ref
 *
 * Taken from [SO](https://stackoverflow.com/questions/73015696/whats-the-difference-between-reacts-forwardedref-and-refobject#answer-73046577)
 */
export const useForwardedRef = <T>(
  forwardedRef: ForwardedRef<T>
): RefObject<T> => {
  const innerRef = useRef<T>(null);

  useEffect(() => {
    if (!forwardedRef) return;
    if (typeof forwardedRef === "function") {
      forwardedRef(innerRef.current);
    } else {
      // eslint-disable-next-line no-param-reassign
      forwardedRef.current = innerRef.current;
    }
  }, [innerRef.current]);

  return innerRef;
};
