import { BoxProps, Flex, Text } from "@chakra-ui/react";
import React from "react";

import colorVars from "../../../theme/css-color-variables";
import { StepperStep } from "./types";

export type StepState = "active" | "incomplete" | "complete";

type StepProps = {
  step: StepperStep;
  // zIndex must be in descending order across steps so they layer correctly
  zIndex: number;
  // The first element has different text padding because it isn't offset
  // by a previous steps pointy part protruding into the next Step.
  first?: boolean;
} & BoxProps;

/**
 * A rightward pointing rectangle, used to indicate steps in a wizard workflow.
 */
const Step: React.FC<StepProps> = ({
  zIndex,
  first = false,
  step,
  ...boxProps
}) => {
  const { label, active, complete, width, onClick, available } = step;
  // SVG was taken from Figma at a base width of 232, points are shifted
  // from that base to the target width
  const baseWidth = 232;
  const widthAdjust = width - baseWidth;
  const height = 66;

  const state = stepState(active, complete);

  return (
    <Flex
      marginRight="-25px"
      zIndex={zIndex}
      width={width}
      height={height}
      position="relative"
      alignItems="center"
      cursor={available ? "pointer" : "not-allowed"}
      className="stepper-step"
      onClick={available ? onClick : undefined}
      {...boxProps}
    >
      <Text
        pl={first ? 10 : "64px"}
        fontWeight="500"
        fontSize="14px"
        color={colorMap[state]}
        userSelect="none"
      >
        {label}
      </Text>
      {/* There isn't a chakra component with a _groupHover property for svg's,
          and inline css on an element can't accomplish this, so add a style tag */}
      <style>
        {`.stepper-step:hover .stepper-step-${state} path {
          fill: ${hoverBackgroundMap[state]}
        }`}
      </style>
      <svg
        className={`stepper-step-${state}`}
        width={width}
        height="66"
        viewBox={`0 0 ${width} 66`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ position: "absolute", top: 0, left: 0, zIndex: -1 }}
      >
        <path
          d={`
        M 1 0.5
        H 0.5
        V 1
        V 65
        V 65.5
        H 1
        H ${205 + widthAdjust}
        C ${207.046 + widthAdjust} 65.5 ${208.972 + widthAdjust} 64.5367 ${
            210.2 + widthAdjust
          } 62.9
        L ${230.6 + widthAdjust} 35.7
        C ${231.8 + widthAdjust} 34.1 ${231.8 + widthAdjust} 31.9 ${
            230.6 + widthAdjust
          } 30.3
        L ${210.2 + widthAdjust} 3.1
        C ${208.972 + widthAdjust} 1.46326 ${207.046 + widthAdjust} 0.5 ${
            205 + widthAdjust
          } 0.5
        H 1
        Z`}
          fill={backgroundMap[state]}
          stroke={borderMap[state]}
        />
      </svg>
    </Flex>
  );
};

const stepState = (active: boolean, complete: boolean): StepState => {
  if (active) return "active";
  return complete ? "complete" : "incomplete";
};

const colorMap: { [K in StepState]: string } = {
  active: colorVars.blue[600],
  complete: colorVars.green[600],
  incomplete: colorVars.gray[500],
};

const borderMap: { [K in StepState]: string } = {
  active: colorVars.blue[600],
  complete: colorVars.green[600],
  incomplete: colorVars.gray[100],
};

const backgroundMap: { [K in StepState]: string } = {
  active: colorVars.blue[50],
  complete: colorVars.green[50],
  incomplete: "white",
};

const hoverBackgroundMap: { [K in StepState]: string } = {
  active: colorVars.blue[100],
  complete: colorVars.green[100],
  incomplete: colorVars.gray[50],
};

export default Step;
