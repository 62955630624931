import { Box } from "@chakra-ui/react";
import React from "react";
import { connectCurrentRefinements } from "react-instantsearch-dom";

import { Button, Card, CardTitle } from "../../../components";
import DateRange from "../../../components/Search/DateRange";
import FilterLabel from "../../../components/Search/FilterLabel";
import RangeSlider from "../../../components/Search/RangeSlider";
import Rating from "../../../components/Search/Rating";
import RefinementList from "../../../components/Search/RefinementList";
import { formatDuration } from "../../../utils/datetime";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../hooks/useCurrentUser";

const ClearRefinements = connectCurrentRefinements(({ items, refine }) => {
  const sendGAEvent = useSendGAEvent();
  return (
    <Button
      variant="outline"
      size="xs"
      position="absolute"
      top="10px"
      right="3"
      onClick={() => {
        refine(items as any[]);
        sendGAEvent("filter", "search", "clear");
      }}
      isDisabled={!items.length}
    >
      Clear
    </Button>
  );
});

const SearchFilters: React.FC = () => {
  const currentUser = useCurrentUser();
  const topicFilterEnabled = useFeatureFlag("topic_filter");
  return (
    <Card>
      <CardTitle position="relative">
        Filters <ClearRefinements />
      </CardTitle>
      <Box py={4} px="3">
        <Box mb={4}>
          <FilterLabel htmlFor="candidate.name">Candidate</FilterLabel>
          <RefinementList attribute="candidate.name" />
        </Box>
        <Box mb={4}>
          <FilterLabel htmlFor="interviewer.name">Interviewer</FilterLabel>
          <RefinementList attribute="interviewers.name" />
        </Box>
        <Box mb={4}>
          <FilterLabel htmlFor="position.display_title">Position</FilterLabel>
          <RefinementList attribute="position.display_title" />
        </Box>
        {topicFilterEnabled && (
          <Box mb={4}>
            <FilterLabel htmlFor="cues">Interview Topics</FilterLabel>
            <RefinementList attribute="cues" />
          </Box>
        )}
        <Box mb={4}>
          <FilterLabel htmlFor="stage">Stage</FilterLabel>
          <RefinementList attribute="stage" />
        </Box>
        <Box mb={4}>
          <FilterLabel htmlFor="position.client_name">Department</FilterLabel>
          <RefinementList attribute="position.client_name" />
        </Box>
        <Box mb={4}>
          <FilterLabel>Date Range</FilterLabel>
          <DateRange attribute="call_timestamp_seconds" />
        </Box>
        {currentUser.organization.showStarRatings && (
          <Box mb={4}>
            <FilterLabel>Rating</FilterLabel>
            <Rating
              attribute="rating"
              ratingDescriptions={currentUser.organization.ratingDescriptions}
            />
          </Box>
        )}
        <Box mb={6}>
          <FilterLabel>Interview Duration</FilterLabel>
          <RangeSlider
            attribute="call_duration_seconds"
            formatRange={(min: number, max: number) =>
              `${formatDuration(min)} - ${formatDuration(max)}`
            }
          />
        </Box>
        <Box mb={6}>
          <FilterLabel>Interviewer Talk Ratio</FilterLabel>
          <RangeSlider
            attribute="interviewer_talk_time_percentage"
            formatRange={(min: number, max: number) =>
              min !== max ? `${min} - ${max}%` : `${min}%`
            }
          />
        </Box>
        <Box mb={2}>
          <FilterLabel>Interviewer Longest Monologue</FilterLabel>
          <RangeSlider
            attribute="interviewer_longest_monologue_seconds"
            formatRange={(min: number, max: number) =>
              min !== max
                ? `${formatDuration(min)} - ${formatDuration(max)}`
                : formatDuration(min)
            }
          />
        </Box>
      </Box>
    </Card>
  );
};

export default SearchFilters;
