import { Text } from "@chakra-ui/react";
import React from "react";
import { HiOutlineSparkles } from "react-icons/hi";

import { Alert, MetricName } from "../../../graphql";
import useDashboardStat from "../useDashboardStat";
import { getDashboardCardValuesForMetric } from "../utils";
import DashboardCard from "./DashboardCard";
import { DashboardConfig } from "./types";

type DashboardCardOpsProps = {
  alerts: Alert[];
  dashboardConfig: DashboardConfig;
};

const DashboardCardOps: React.FC<DashboardCardOpsProps> = ({
  alerts,
  dashboardConfig,
}) => {
  const submissionRateStat = useDashboardStat(
    MetricName.ScorecardCompletionRate,
    dashboardConfig.queryOptions
  );
  const {
    metricAverageValue: submissionRateAverageValue,
    numXValues: submissionRateNumXValues,
    delta: submissionRateDelta,
  } = getDashboardCardValuesForMetric({
    dataPoints: submissionRateStat.dataPoints,
    deltaDataPoints: submissionRateStat.deltaDataPoints,
    metric: MetricName.ScorecardCompletionRate,
  });

  const submissionTimeStat = useDashboardStat(
    MetricName.ScorecardCompletionTime,
    dashboardConfig.queryOptions
  );
  const {
    metricAverageValue: submissionTimeAverageValue,
    numXValues: submissionTimeNumXValues,
    delta: submissionTimeDelta,
  } = getDashboardCardValuesForMetric({
    dataPoints: submissionTimeStat.dataPoints,
    deltaDataPoints: submissionTimeStat.deltaDataPoints,
    metric: MetricName.ScorecardCompletionTime,
  });

  const loading = submissionRateStat.loading || submissionTimeStat.loading;

  return (
    <DashboardCard
      title="Talent Operations"
      category="talent_operations"
      icon={HiOutlineSparkles}
      stats={[
        {
          title: "Feedback Submission Rate",
          values: [
            {
              value:
                submissionRateNumXValues > 0
                  ? submissionRateAverageValue
                  : undefined,
              format: "percent",
            },
          ],
          loading,
          metric: MetricName.ScorecardCompletionRate,
          delta: submissionRateDelta,
          dashboardConfig,
        },
        {
          title: "Feedback Submission Time",
          values: [
            {
              value:
                submissionTimeNumXValues > 0
                  ? submissionTimeAverageValue
                  : undefined,
              format: "days-hours",
            },
          ],
          loading,
          metric: MetricName.ScorecardCompletionTime,
          delta: submissionTimeDelta,
          dashboardConfig,
        },
      ]}
      alerts={alerts}
      dashboardConfig={dashboardConfig}
      infoContent={
        <>
          <Text mb="3">
            <Text as="b">Feedback Submission Rate</Text> is the percentage of
            time a user submits feedback after completing an interview.
          </Text>
          <Text mb="3">
            <Text as="b">Feedback Submission Time</Text> is the time between an
            interview ending and feedback being submitted, averaged over the
            selected date range.
          </Text>
        </>
      }
    />
  );
};

export default DashboardCardOps;
