import React from "react";
import { IoMdTrash } from "react-icons/io";
import { MdErrorOutline, MdHelpOutline } from "react-icons/md";

import { Link } from "../../../components";
import { EmptyStateWithIcon } from "../../../components/EmptyStates";
import config from "../../../config";
import Forbidden from "../../pages/forbidden/Forbidden";

const TEXT_COLOR = "gray.600";
const DASHBOARD_LINK = config.urlPrefix;

const CallErrorState: React.FC<{ view: string }> = ({ view }) => {
  if (view === "forbidden") {
    return (
      <Forbidden showLayout={false} forbiddenFrom="interview">
        You can request access from the owners.
      </Forbidden>
    );
  }

  if (view === "deleted") {
    return (
      <EmptyStateWithIcon
        heading="This interview has been deleted"
        icon={IoMdTrash}
        Text={() => (
          <div color={TEXT_COLOR}>
            Out of respect for the privacy of our customers and candidates,
            deleted interviews are permanently removed and cannot be restored.
            Any associated metadata, including transcripts and notes, have also
            been removed. You can still see other upcoming & completed
            interviews <Link href={DASHBOARD_LINK}>on your dashboard</Link>.
          </div>
        )}
      />
    );
  }

  if (view === "none") {
    return (
      <EmptyStateWithIcon
        heading="This interview was not found"
        icon={MdHelpOutline}
        Text={() => (
          <div color={TEXT_COLOR}>
            Looking for something else?{" "}
            <Link href={DASHBOARD_LINK}>Check out your dashboard</Link>, where
            you can see your upcoming & recent interviews, interview stats, and
            ongoing activity.
          </div>
        )}
      />
    );
  }

  return (
    <EmptyStateWithIcon
      heading="An error occurred"
      icon={MdErrorOutline}
      Text={() => (
        <div color={TEXT_COLOR}>
          Something went wrong. Please refresh your browser. Alternatively,{" "}
          <Link href={DASHBOARD_LINK}>check out your dashboard</Link>, where you
          can see your upcoming & recent interviews, interview stats, and
          ongoing activity.
        </div>
      )}
    />
  );
};

export default CallErrorState;
