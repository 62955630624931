import {
  Box,
  Center,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
} from "@chakra-ui/react";
import React from "react";

import { Button, RouterLink } from "../../../components";
import {
  TrainingProgramAnswer,
  TrainingProgramQuestion,
  TrainingProgramTraineeFragment,
} from "../../graphql";
import { GreenCheckmarkIcon } from "./icons";
import Indicator from "./Indicator";
import { TrainingProgram } from "./types";

type TrainingProgramQuestionInfo = Pick<
  TrainingProgramQuestion,
  "id" | "order" | "question"
>;
type TrainingProgramAnswerInfo = Pick<
  TrainingProgramAnswer,
  "trainingProgramQuestionId" | "trainingProgramItemId" | "answer" | "createdAt"
>;
type TrainingProgramTraineeInfo = Pick<
  TrainingProgramTraineeFragment,
  "trainingProgramAnswers" | "user" | "id"
>;

export const buildTraineeNestedRecordingRows = (
  trainingProgram: Pick<
    TrainingProgram,
    | "trainingProgramItems"
    | "trainingProgramQuestions"
    | "trainingProgramDeletedQuestions"
    | "assessmentEnabled"
  >,
  trainee: TrainingProgramTraineeInfo
): React.ReactNode[] => {
  return trainingProgram.trainingProgramItems.map((item, idx, arr) => {
    let hasCompletedTrainingItem = false;
    let addedAfterTraineeStarted = false;
    const answersForItem = trainee.trainingProgramAnswers.filter(
      (answer) => answer.trainingProgramItemId === item.id
    );

    if (trainingProgram.assessmentEnabled) {
      // considering a trainee complete if s/he has completed any question
      // because new questions may have been added after submission
      hasCompletedTrainingItem = trainingProgram.trainingProgramQuestions.some(
        (question) =>
          answersForItem.find(
            (answer) => answer.trainingProgramQuestionId === question.id
          )
      );
      addedAfterTraineeStarted =
        trainee.trainingProgramAnswers.length > 0 &&
        !trainee.trainingProgramAnswers.find(
          (pa) => pa.createdAt > item.createdAt
        );
    } else {
      hasCompletedTrainingItem = trainee.user.trainingProgramItemViews.some(
        (piv) => piv.trainingProgramItem.id === item.id
      );
      addedAfterTraineeStarted =
        trainee.user.trainingProgramItemViews.length > 0 &&
        !trainee.user.trainingProgramItemViews.find(
          (piv) => piv.createdAt > item.createdAt
        );
    }
    const stateCell = trainingProgram.assessmentEnabled ? (
      <AssessmentStateCell
        completed={hasCompletedTrainingItem}
        questions={trainingProgram.trainingProgramQuestions}
        deletedQuestions={trainingProgram.trainingProgramDeletedQuestions}
        answers={answersForItem}
      />
    ) : (
      <ViewedStateCell completed={hasCompletedTrainingItem} />
    );
    return (
      <ManageTrainingPageTableNestedRow
        itemName={item.name || "Recording"}
        hasCompletedTrainingItem={hasCompletedTrainingItem}
        isLast={idx === arr.length - 1}
        key={`${trainee.id}_${item.id}`}
        itemURL={item.url}
        addedAfterTraineeStarted={addedAfterTraineeStarted}
        isRedacted={!!item.contentRedactedAt}
      >
        {stateCell}
      </ManageTrainingPageTableNestedRow>
    );
  });
};

type AssessmentStateCellProps = {
  completed: boolean;
  questions: TrainingProgramQuestionInfo[];
  deletedQuestions: TrainingProgramQuestionInfo[];
  answers: TrainingProgramAnswerInfo[];
};

const AssessmentStateCell: React.FC<AssessmentStateCellProps> = ({
  completed,
  questions,
  deletedQuestions: allDeletedQuestions,
  answers,
}) => {
  if (!completed) {
    return <Text color="gray.500">No assessment yet</Text>;
  }

  // only show deleted questions with answers
  const deletedQuestions = allDeletedQuestions.filter((question) => {
    return !!answers.find(
      (answer) => answer.trainingProgramQuestionId === question.id
    );
  });

  const rows = (
    <Box>
      {questions.map((question, idx, arr) => {
        const answer = answers.find(
          (answer) => answer.trainingProgramQuestionId === question.id
        );
        const isNew = answers.length > 0 && !answer;
        const lastIdx = idx === arr.length - 1 && deletedQuestions.length === 0;
        return (
          <Box
            bg="white"
            mb={!lastIdx ? "2" : undefined}
            borderTop={idx && "1px"}
            borderBottom={!lastIdx ? "1px" : undefined}
            borderColor="gray.100"
            key={question.id}
          >
            <PopoverHeader p={4}>
              <Text fontWeight="500" fontSize="sm" color="gray.900">
                {question.question}
              </Text>
            </PopoverHeader>
            <PopoverBody px={4} py={3}>
              <Text
                fontWeight="400"
                fontSize="sm"
                color={answer ? "gray.700" : "gray.500"}
              >
                {answer
                  ? answer.answer
                  : isNew
                  ? "Question was added after trainee already submitted their answers."
                  : "No answer yet."}
              </Text>
            </PopoverBody>
          </Box>
        );
      })}
      {deletedQuestions.length > 0 && (
        <>
          <Box px={2} py={2} background="gray.100">
            <Text fontSize="sm" pl="2" fontWeight="500" color="gray.800">
              {`${deletedQuestions.length} Deleted Question${
                deletedQuestions.length > 1 ? "s" : ""
              }`}
            </Text>
          </Box>
          {deletedQuestions.map((question, idx, arr) => {
            const answer = answers.find(
              (answer) => answer.trainingProgramQuestionId === question.id
            );
            const lastIdx = idx === arr.length - 1;
            return (
              <Box
                mb={!lastIdx ? "2" : undefined}
                borderTop={idx && "1px"}
                borderBottom={!lastIdx ? "1px" : undefined}
                borderColor="gray.100"
                key={question.id}
              >
                <PopoverHeader p={4}>
                  <Text fontWeight="500" fontSize="sm" color="gray.900">
                    {question.question}
                  </Text>
                </PopoverHeader>
                <PopoverBody px={4} py={3}>
                  <Text fontWeight="400" fontSize="sm" color="gray.700">
                    {answer ? answer.answer : "No answer."}
                  </Text>
                </PopoverBody>
              </Box>
            );
          })}
        </>
      )}
    </Box>
  );
  return (
    <Popover isLazy placement="bottom-end" trigger="hover" autoFocus={false}>
      <PopoverTrigger>
        <Button color="blue.600" size="sm" variant="link" fontWeight="500">
          View assessment
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          maxHeight="450px"
          minWidth="380px"
          overflowY="auto"
          borderColor="gray.100"
          bg="gray.50"
        >
          {rows}
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export const ViewedStateCell: React.FC<{ completed: boolean }> = ({
  completed,
}) => {
  if (!completed) {
    return <Text color="gray.500">Not viewed yet</Text>;
  }
  return <Text color="gray.900">Viewed</Text>;
};

type ManageTrainingPageTableNestedRowProps = {
  addedAfterTraineeStarted?: boolean;
  children: React.ReactNode;
  hasCompletedTrainingItem: boolean;
  isLast: boolean;
  isRedacted?: boolean;
  itemName: string;
  itemURL?: string;
};

export const ManageTrainingPageTableNestedRow: React.FC<
  ManageTrainingPageTableNestedRowProps
> = ({
  itemName,
  hasCompletedTrainingItem,
  isLast,
  itemURL,
  children,
  addedAfterTraineeStarted,
  isRedacted,
}) => {
  const padding = 4;
  const borderProps = { borderBottom: "1px", borderColor: "gray.100" };
  const fullBorder = isLast ? borderProps : undefined;
  return (
    <Box
      as="tr"
      fontWeight="500"
      fontSize="sm"
      backgroundColor="gray.50"
      lineHeight="21px"
      key={itemName}
    >
      <Box as="td" {...fullBorder} />
      <Box
        as="td"
        ml={8}
        pb={0}
        pl={4}
        py={padding}
        opacity="0.8"
        color="gray.700"
        {...borderProps}
      >
        {isRedacted ? (
          itemName
        ) : (
          <RouterLink to={itemURL as string}>{itemName}</RouterLink>
        )}
      </Box>
      <Box as="td" {...borderProps} />
      <Box as="td" {...borderProps} />
      <Box as="td" pl={4} py={0} {...borderProps}>
        {hasCompletedTrainingItem ? (
          <Center w="5" h="5">
            <GreenCheckmarkIcon />
          </Center>
        ) : (
          addedAfterTraineeStarted && (
            <Indicator
              data-testid="new-recording-indicator"
              tooltipText="This recording was added to the program after the trainee started the program"
            />
          )
        )}
      </Box>
      <Box as="td" py={0} {...borderProps} pl={5}>
        {children}
      </Box>
    </Box>
  );
};
