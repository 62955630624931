import {
  ButtonProps,
  Icon,
  Menu,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  MenuProps,
  Tooltip,
} from "@chakra-ui/react";
import React, { forwardRef, ReactElement, useEffect, useState } from "react";
import { IoCheckmarkSharp } from "react-icons/io5";

import { useIsSmallScreen } from "../../hooks/useIsSmallScreen";
import { IconButton, MenuButton } from "../Buttons";

interface PlaybackRateMenuProps {
  playbackRate: number;
  label?: string;
  setPlaybackRate(playbackRate: number): void;
  menuProps?: Omit<MenuProps, "children">;
  menuButtonProps?: Omit<ButtonProps, "children">;
}

function isVideoPaused(playbackRate: number): boolean {
  return playbackRate === 0.0;
}

function getPlaybackRateForDisplay(playbackRate: number): number {
  return isVideoPaused(playbackRate) ? 1.0 : playbackRate;
}

const PlaybackRateMenu = forwardRef<HTMLButtonElement, PlaybackRateMenuProps>(
  (
    {
      playbackRate,
      label = "Playback Speed",
      setPlaybackRate,
      menuProps,
      menuButtonProps,
    },
    ref
  ) => {
    const isSmallScreen = useIsSmallScreen();

    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const [timerId, setTimerId] = useState<NodeJS.Timeout | undefined>(
      undefined
    );
    const openTooltip = (): void => setIsTooltipOpen(true);
    const closeTooltip = (): void => setIsTooltipOpen(false);

    useEffect(() => {
      if (isTooltipOpen) {
        setTimerId(
          setTimeout(() => {
            setIsTooltipOpen(false);
          }, 2000)
        );
      } else if (timerId) {
        clearTimeout(timerId);
      }
      return () => {
        if (timerId) clearTimeout(timerId);
      };
    }, [isTooltipOpen]);

    return (
      <Menu {...menuProps}>
        <Tooltip
          label={isSmallScreen ? undefined : label}
          isOpen={isTooltipOpen}
          onOpen={() => openTooltip()}
          onClose={() => closeTooltip()}
        >
          <MenuButton
            ref={ref}
            as={IconButton}
            aria-label={label}
            pointerEvents="all"
            fontSize="14px"
            {...menuButtonProps}
          >
            {`${getPlaybackRateForDisplay(playbackRate)}x`}
          </MenuButton>
        </Tooltip>
        <MenuList pointerEvents="all" zIndex={3}>
          <MenuOptionGroup
            type="radio"
            onChange={(value: string | string[]): void => {
              setPlaybackRate(parseFloat(value.toString()));
            }}
            defaultValue={`${getPlaybackRateForDisplay(playbackRate)}`}
            value={playbackRate.toString()}
          >
            <MenuItemOption value="1" icon={<BlueCheckIcon />}>
              1x speed
            </MenuItemOption>
            <MenuItemOption value="1.25" icon={<BlueCheckIcon />}>
              1.25x speed
            </MenuItemOption>
            <MenuItemOption value="1.5" icon={<BlueCheckIcon />}>
              1.5x speed
            </MenuItemOption>
            <MenuItemOption value="1.75" icon={<BlueCheckIcon />}>
              1.75x speed
            </MenuItemOption>
            <MenuItemOption value="2" icon={<BlueCheckIcon />}>
              2x speed
            </MenuItemOption>
            <MenuItemOption value="2.5" icon={<BlueCheckIcon />}>
              2.5x speed
            </MenuItemOption>
            <MenuItemOption value="3" icon={<BlueCheckIcon />}>
              3x speed
            </MenuItemOption>
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    );
  }
);
PlaybackRateMenu.displayName = "PlaybackRateMenu";

function BlueCheckIcon(): ReactElement {
  return <Icon as={IoCheckmarkSharp} color="blue.300" w={4} h={4} />;
}

export default React.memo(PlaybackRateMenu);
