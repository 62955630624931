import { Flex, Icon, IconProps, Tooltip } from "@chakra-ui/react";
import React from "react";
import { IoChatbox, IoFlag, IoStar } from "react-icons/io5";
import { IconType } from "react-icons/lib";

import { Button, ScissorsIcon } from "../../../../components";
import { CallNoteType } from "../../../graphql";

type ActionBarProps = {
  isVideoVisible: boolean;
  onCreateClipClick?(): void;
  openNote(type: CallNoteType): () => void;
};

const ActionBarButton: React.FC<{
  tooltip: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  mouseOverColor?: string;
  icon: IconType | ((props: IconProps) => JSX.Element);
}> = ({ tooltip, onClick, icon, mouseOverColor }) => {
  return (
    <Tooltip label={tooltip}>
      <Button
        variant="ghost"
        color="white"
        fontSize="sm"
        fontWeight="500"
        borderRadius="56"
        flex="1"
        px="1"
        py="2"
        _active={{
          bg: "rgba(255, 255, 255, 0.5)",
          color: mouseOverColor && `${mouseOverColor}.500`,
        }}
        _hover={{
          bg: "rgba(255, 255, 255, 0.3)",
          color: mouseOverColor && `${mouseOverColor}.400`,
        }}
        onClick={onClick}
      >
        <Icon as={icon} boxSize="5" />
      </Button>
    </Tooltip>
  );
};

const ActionBar: React.FC<ActionBarProps> = ({
  isVideoVisible,
  onCreateClipClick,
  openNote,
}) => {
  return (
    <Flex
      display={{ base: "none", md: "flex" }}
      position="absolute"
      right={isVideoVisible ? "2" : "4"}
      top="50%"
      transform="translateY(-50%)"
      alignItems="center"
      zIndex="3"
    >
      <Flex
        direction={isVideoVisible ? "column" : "row"}
        bg="rgba(255, 255, 255, 0.15)"
        borderRadius="3xl"
        py="1"
        px="1"
        data-tour-id="ip2-action-bar"
      >
        <ActionBarButton
          tooltip="Noteworthy moment"
          onClick={openNote(CallNoteType.Star)}
          icon={IoStar}
          mouseOverColor="yellow"
        />
        <ActionBarButton
          icon={IoFlag}
          tooltip="Potential red flag"
          onClick={openNote(CallNoteType.Flag)}
          mouseOverColor="red"
        />
        <ActionBarButton
          icon={IoChatbox}
          tooltip="Add note"
          onClick={openNote(CallNoteType.Note)}
        />
        {onCreateClipClick !== undefined && (
          <ActionBarButton
            tooltip="Create clip"
            onClick={onCreateClipClick}
            icon={ScissorsIcon}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default ActionBar;
