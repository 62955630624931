import { SortingRule } from "react-table";

import { TranscriptSegment } from "../main/graphql";

export enum Conditional {
  AND = "AND",
  OR = "OR",
}

export type CallButtonPosition = "upcoming" | "completed";

export type Segment = Pick<
  TranscriptSegment,
  "id" | "speakerTag" | "words" | "startTime" | "endTime"
> & { index?: number };

export type SearchParams = {
  searchTerm: string;
  searchIndex: number;
  searchPositions: Array<number>;
};

export type PageOptions = {
  currentPage: number;
  totalPages: number;
  handlePageNumberChange: (pageNumber: number) => void;
  onChangeSort?: (sortBy: SortingRule<Record<string, unknown>>[]) => void;
};

/**
 * Tab setup for use with URL-driven tabs
 */
export type Tab<T> = {
  /**
   * The starting path segment if you are using nested routes
   */
  startingPath?: string;
  /**
   * The path segment to be added as a URL parameter
   */
  path: string;
  /**
   * Title to display in the Tab
   */
  name: string;
  /**
   * Whether to disable the tab
   */
  disabled?: boolean;
  /**
   * Determines whether this tab should be disabled given some condition(s)
   */
  shouldBeDisabled?: (input?: T | null) => boolean;
  /**
   * Determines whether this tab should be shown at all given some condition(s)
   */
  shouldShow: (input?: T | null) => boolean;
  /**
   * The component to show in the TabPanel
   */
  Component: React.FC<any>;
  /**
   * Any additional attributes to pass into the component
   */
  attributes?: { [k: string]: any };
};

/**
 * Tab data for use with URL-driven tabs
 */
export class TabData<T> {
  private allTabs: Tab<T>[] = [];

  private currentTabs: Tab<T>[] = [];

  private currentInput: T | null | undefined = undefined;

  get tabs(): Tab<T>[] {
    return this.currentTabs;
  }

  set tabs(t: Tab<T>[]) {
    this.allTabs = t;
    this.currentTabs = t;
  }

  get input(): T | null | undefined {
    return this.currentInput;
  }

  set input(value: T | null | undefined) {
    this.currentInput = value;
    this.currentTabs = this.getVisibleTabs();
  }

  constructor(/** All tabs you may potentially require */ tabs: Tab<T>[]) {
    this.allTabs = tabs;
    this.currentTabs = tabs;
  }

  /**
   * Get tabs that should be shown based on the input, and update the disabled
   * status on each
   */
  private getVisibleTabs = (): Tab<T>[] => {
    const visibleTabs = this.allTabs
      .filter((t) => t.shouldShow(this.input))
      .map((t) => {
        const tab = t;
        if (!t.shouldBeDisabled) {
          tab.disabled = false;
          return tab;
        }
        tab.disabled = t.shouldBeDisabled(this.input);
        return tab;
      });
    return visibleTabs;
  };
}
