import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import {
  Alert,
  LoadingIndicator,
  StatusWithText,
  useToast,
} from "../../../components";
import config from "../../../config";
import {
  CurrentUserFragment,
  InstallZoomNotetakerAppJobProcessingStatus,
  useAuthorizeZoomBotMutation,
  useInstallZoomNotetakerAppStatusQuery,
} from "../../graphql";
import StyledZoomImg from "./StyleZoomImg";

interface ZoomBotRecordingAuthProps {
  currentUser: CurrentUserFragment;
  children?: React.ReactNode;
}

const ZoomBotRecordingAuth: React.FC<ZoomBotRecordingAuthProps> = ({
  currentUser,
  children,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const authCode = searchParams.get("code");
  const toast = useToast();

  const [authorizeZoomBotMutation, { loading: loadingAuthorize }] =
    useAuthorizeZoomBotMutation({
      onError: () => {
        toast({
          status: "error",
          title: "Authorizing Zoom",
          description: "There was a problem - please try again",
        });
      },
    });

  const appStatusData = useInstallZoomNotetakerAppStatusQuery();

  useEffect(() => {
    if (currentUser.userRole?.canManageIntegrationSettings) {
      if (authCode) {
        authorizeZoomBotMutation({
          variables: { authCode },
          onCompleted: () => {
            appStatusData.refetch();
            appStatusData.startPolling(1500);
          },
        });
        navigate(location.pathname, { replace: true });
      }
    }
  }, []);

  useEffect(() => {
    if (
      appStatusData?.data?.zoomBotInstallStatus &&
      [
        InstallZoomNotetakerAppJobProcessingStatus.Failed,
        InstallZoomNotetakerAppJobProcessingStatus.Completed,
      ].includes(appStatusData?.data?.zoomBotInstallStatus)
    ) {
      appStatusData.stopPolling();
    }
    if (
      appStatusData?.data?.zoomBotInstallStatus &&
      [
        InstallZoomNotetakerAppJobProcessingStatus.Dispatched,
        InstallZoomNotetakerAppJobProcessingStatus.InProgress,
      ].includes(appStatusData?.data?.zoomBotInstallStatus)
    ) {
      appStatusData.startPolling(1500);
    }
  }, [appStatusData.data]);

  if (
    loadingAuthorize ||
    appStatusData.loading ||
    (appStatusData?.data?.zoomBotInstallStatus &&
      [
        InstallZoomNotetakerAppJobProcessingStatus.Dispatched,
        InstallZoomNotetakerAppJobProcessingStatus.InProgress,
      ].includes(appStatusData?.data?.zoomBotInstallStatus))
  ) {
    return (
      <>
        {children}
        <LoadingIndicator />
      </>
    );
  }

  if (
    appStatusData?.data?.zoomBotInstallStatus ===
    InstallZoomNotetakerAppJobProcessingStatus.Completed
  ) {
    return (
      <>
        {children}
        <StatusWithText passed label="Zoom App installed" />
      </>
    );
  }

  if (config.zoomBotClientId) {
    return (
      <>
        {children}
        <Box>
          <Alert
            status="info"
            mb={4}
            description="This one-time setup saves coordinators and interviewers time by allowing BrightHire to record interviews without interruption. Zoom's recording disclaimer will always be shown to participants when joining."
          />
          <a
            href={`https://zoom.us/oauth/authorize?response_type=code&client_id=${config.zoomBotClientId}&redirect_uri=${config.urlPrefix}/settings/zoom-notetaker`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledZoomImg
              src="https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png"
              alt="Add to ZOOM"
            />
          </a>
        </Box>
      </>
    );
  }

  return null;
};

export default ZoomBotRecordingAuth;
