/* eslint-disable no-underscore-dangle */
import { Box, Flex, FlexProps } from "@chakra-ui/react";
import invariant from "invariant";
import React, { useEffect, useRef, useState } from "react";
import { InfiniteHitsProvided } from "react-instantsearch-core";
import { connectInfiniteHits } from "react-instantsearch-dom";

import { LoadingIndicator } from "../../../components/LoadingIndicator";
import SearchState from "../../../components/Search/SearchState";
import config from "../../../config";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import { SearchResult } from "./SearchResult";
import { SearchResultRefresh } from "./SearchResultRefresh";
import { SearchHit } from "./types";

const groupHits = (hits: any[]): SearchHit[] => {
  const groupedHits: SearchHit[] = [];

  hits.forEach((hit: any) => {
    if (!hit) {
      return;
    }
    // console.log(JSON.stringify(hit, null, 2));
    // Group hits by call
    // See: https://www.algolia.com/doc/api-reference/api-parameters/distinct/?language=javascript
    const lastHit =
      groupedHits.length === 0 ? null : groupedHits[groupedHits.length - 1];

    // eslint-disable-next-line camelcase
    const highlightedSegments = hit._snippetResult?.transcript_segments;
    const hitSegments = hit.transcript_segments;

    const segments: Array<{
      speaker: string;
      startTime: number;
      text: string;
    }> = [];

    if (highlightedSegments) {
      highlightedSegments.forEach((highlightedSegment: any, index: number) => {
        if (highlightedSegment.text.matchLevel !== "none") {
          const hitSegment = hitSegments[index];
          const segment = {
            speaker: hitSegment.speaker,
            startTime: hitSegment.start_offset_seconds,
            text: highlightedSegment.text.value,
          };
          segments.push(segment);
        }
      });
    }

    const hightlightedNotes = hit._highlightResult?.notes;
    const hitNotes = hit.notes;
    const notes: Array<{
      id: string;
      type: string;
      time: number;
      text: string;
    }> = [];
    if (hightlightedNotes) {
      hightlightedNotes.forEach((hightlightedNote: any, index: number) => {
        if (hightlightedNote.text.matchLevel !== "none") {
          const hitNote = hitNotes[index];
          notes.push({
            id: hitNote.note_id,
            type: hitNote.type,
            time: hitNote.time,
            text: hightlightedNote.text.value,
          });
        }
      });
    }

    if (lastHit?.callId === hit.call_id) {
      invariant(lastHit, "Missing lastHit");
      if (segments.length > 0) {
        lastHit.transcriptSegments = [
          ...lastHit.transcriptSegments,
          ...segments,
        ].slice(0, 3);
      }
      lastHit.notes = [...lastHit.notes, ...notes];
    } else {
      groupedHits.push({
        objectID: hit.objectID,
        callId: hit.call_id,
        startTime: hit.call_timestamp_seconds,
        duration: hit.call_duration_seconds,
        name: hit._highlightResult.name?.value,
        rating: hit.rating,
        stage: hit.stage,
        position: hit.position
          ? {
              id: hit.position.id,
              displayTitle: hit._highlightResult.position.display_title?.value,
            }
          : null,
        candidate: hit.candidate
          ? {
              id: hit.candidate.id,
              name: hit._highlightResult.candidate.name.value,
              email: hit._highlightResult.candidate.email?.value,
              phoneNumber: hit._highlightResult.candidate.phoneNumber?.value,
            }
          : null,
        interviewers: hit._highlightResult.interviewers?.map(
          (interviewer: any, index: number) => ({
            id: hit.interviewers[index].id,
            name: interviewer.name?.value,
            email: interviewer.email?.value,
          })
        ),
        interviewerLongestMonologueSeconds:
          hit.interviewer_longest_monologue_seconds,
        transcriptSegments: segments.slice(0, 3),
        notes,
        isOnTimeStart: hit.is_on_time_start,
        candidateTalkTimeRatio: hit.candidate_talk_time_ratio,
        adjustedCallInteractivity: hit.adjusted_call_interactivity,
        analyticsInterviewers: hit.analytics_interviewers?.map(
          (interviewer: any) => ({
            fullName: interviewer.full_name,
            longestMonologue: interviewer.longest_monologue,
            wordsPerMinute: interviewer.words_per_minute,
            countQuestions: interviewer.count_questions,
            scorecardSubmissionTime: interviewer.scorecard_submission_time,
            scorecardRating: interviewer.scorecard_rating,
            isPositiveFeedback: interviewer.is_positive_feedback,
          })
        ),
      });
    }
  });
  return groupedHits;
};

export interface SearchResultsProps {
  /**
   * Optional function to render a search result.
   * A default `<SearchResult />` component is used if omitted
   */
  render?(hit: SearchHit): JSX.Element;
  /**
   * Callback for when new search hits are rendered
   */
  onResults?(hits: SearchHit[]): void;
  /** Optional UI to show when no results found. Defaults to `null` */
  noResults?: React.ReactNode;
  gap?: FlexProps["gap"];
}

const SearchResultsBase: React.FC<
  InfiniteHitsProvided<SearchHit> & SearchResultsProps
> = ({
  hits,
  hasMore,
  gap,
  noResults = null,
  refineNext,
  render,
  onResults,
}) => {
  const [hasMoreHits, setHasMoreHits] = useState<boolean>(true);
  const sentinelRef = useRef<HTMLDivElement>(null);
  const searchPageRefreshEnabled = useFeatureFlag("search-page:refresh");
  const SearchComponent = searchPageRefreshEnabled
    ? SearchResultRefresh
    : SearchResult;

  const inView = useIntersectionObserver(sentinelRef);

  useEffect(() => {
    // Workaround for the fact that the hasMore param is unreliable
    setHasMoreHits(hasMore);
  }, [hits.length]);

  useEffect(() => {
    if (inView && hasMoreHits) {
      refineNext();
    }
  }, [inView, hasMoreHits]);

  const groupedHits = groupHits(hits);
  useEffect(() => onResults?.(groupedHits), [groupedHits]);

  return (
    <>
      {groupedHits.length ? (
        <Flex
          direction="column"
          flexWrap={searchPageRefreshEnabled ? "wrap" : "unset"}
          justifyContent={searchPageRefreshEnabled ? "flex-start" : "initial"}
          gap={gap}
        >
          {!inView && (
            <SearchState
              render={({ searching }) =>
                searching ? <LoadingIndicator py={6} delay={500} /> : null
              }
            />
          )}
          {groupedHits.map((hit) => (
            <React.Fragment key={hit.objectID}>
              {render !== undefined ? (
                render(hit)
              ) : (
                <SearchComponent hit={hit} />
              )}
            </React.Fragment>
          ))}
          <Box width="100%" ref={sentinelRef} />
          <SearchState
            render={({ searching }) =>
              searching ? <LoadingIndicator py={6} delay={150} /> : null
            }
          />
        </Flex>
      ) : (
        <SearchState
          render={({ searching }) =>
            searching ? <LoadingIndicator py={6} delay={0} /> : <>{noResults}</>
          }
        />
      )}
      {!hasMoreHits && groupedHits.length > 0 && (
        <Box
          width="100%"
          color="gray.500"
          fontSize="sm"
          py={6}
          textAlign="center"
        >
          No more results.
        </Box>
      )}
    </>
  );
};

const SearchResultsTest: React.FC<any> = ({ render, onResults }) => (
  <SearchResultsBase
    hits={[]}
    hasMore={false}
    hasPrevious={false}
    refineNext={() => undefined}
    refinePrevious={() => undefined}
    render={render}
    onResults={onResults}
  />
);

export const SearchResults =
  config.appEnv === "test"
    ? SearchResultsTest
    : connectInfiniteHits(SearchResultsBase);
