import { Box, ButtonGroup, Flex, Icon, Placement } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { FaPlay } from "react-icons/fa";
import { IoPause } from "react-icons/io5";
import { MdPause, MdPlayArrow } from "react-icons/md";

import { Button, IconButton, PlaybackRateMenu } from "../../../components";
import { secondsToHHMMSS } from "../../../utils/media";
import { MediaPlayerInterface } from "../Interview/useMediaPlayer";
import MonospacedText from "../MonospacedText";
import { AudioControls } from "./AudioControls";

type VideoControlsVariant = "highlights" | "clips";

export type VideoControlsProps = {
  isHovering: boolean;
  disabled: boolean;
  player: MediaPlayerInterface;
  buttonSize?: number;
  onClose?: () => void;
  variant: VideoControlsVariant;
};

const playbackRateMenuProps = {
  placement: "top" as Placement,
  variant: "blackAlpha",
};

const VideoControls: React.FC<VideoControlsProps> = ({
  isHovering,
  disabled,
  player,
  buttonSize = 12,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose = () => {},
  variant,
}) => {
  const {
    muted,
    playbackRate,
    volume,
    time,
    duration,
    mute,
    setVolume,
    setPlaybackRate,
  } = player;

  const timeStr = useMemo(() => secondsToHHMMSS(time), [Math.floor(time)]);
  const durationStr = useMemo(() => secondsToHHMMSS(duration), [duration]);
  const playbackRateMenuButtonProps = useMemo(
    () => ({
      isDisabled: disabled,
      variant: "translucent",
      h: 12,
      p: 0,
      w: 12,
      borderRadius: "full",
    }),
    [disabled]
  );

  return (
    <>
      <Box
        position="absolute"
        pointerEvents="none"
        opacity={{ base: isHovering ? 1 : 0, lg: 0 }}
        background="rgba(26, 32, 44, 0.6)"
        transition="opacity 0.3s ease-out"
        top={0}
        right={0}
        bottom={0}
        left={0}
      />
      <ControlButtons
        buttonSize={buttonSize}
        isHovering={isHovering}
        disabled={disabled}
        player={player}
        variant={variant}
      />
      <Flex
        position="absolute"
        pointerEvents="none"
        bottom="-1px"
        left="0"
        right="0"
        height="4.5rem"
        alignItems="flex-end"
        bg="linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%)"
      >
        <Flex
          w="100%"
          px="5"
          alignItems="center"
          justifyContent="space-between"
        >
          {variant === "highlights" && (
            <Flex flex="1" color="white" fontWeight="bold">
              {!!duration && <MonospacedText text={timeStr} />}
              &nbsp;/&nbsp;
              <MonospacedText text={durationStr} />
            </Flex>
          )}
          <Flex flex="1" justifyContent="flex-end" zIndex="12">
            <PlaybackRateMenu
              playbackRate={disabled ? 1.0 : playbackRate}
              setPlaybackRate={setPlaybackRate}
              menuProps={playbackRateMenuProps}
              menuButtonProps={playbackRateMenuButtonProps}
            />
            <AudioControls
              setVolume={setVolume}
              mute={mute}
              volume={volume}
              disabled={disabled}
              muted={muted}
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

type ControlButtonsProps = {
  buttonSize: number;
  isHovering: boolean;
  disabled: boolean;
  player: MediaPlayerInterface;
  variant: VideoControlsVariant;
};

const ControlButtons: React.FC<ControlButtonsProps> = ({
  buttonSize,
  isHovering,
  disabled,
  player: { skipBack, skipForward, pause, playing, play },
  variant,
}) => {
  const isForClip = variant === "clips";
  const clipPlayPauseSize = isForClip && !playing ? 6 : 8;
  const CONTROL_BUTTON_LG_PX = isForClip ? "40px" : `${buttonSize * 4}px`;
  const CONTROL_BUTTON_BASE_PX = `${buttonSize * (isForClip ? 10 : 6)}px`;
  const CONTROL_BUTTON_LG_BOX = isForClip
    ? clipPlayPauseSize
    : (buttonSize * 2) / 3;
  const CONTROL_BUTTON_BASE_BOX = isForClip ? 30 : buttonSize;
  const hideSkip = isForClip;
  const playIcon = isForClip ? FaPlay : MdPlayArrow;
  const pauseIcon = isForClip ? IoPause : MdPause;

  return (
    <ButtonGroup
      flex="1"
      variant="translucent"
      position="absolute"
      top={{
        base: `calc(50% - ${buttonSize * 2}px)`,
        lg: undefined,
      }}
      bottom={{ base: undefined, lg: isForClip ? "35%" : 0 }}
      alignItems={{ base: "center", lg: "flex-end" }}
      justifyContent="center"
      opacity={{ base: isHovering ? 1 : 0, lg: 1 }}
      transition="opacity 0.3s ease-out"
      pointerEvents="none"
      zIndex={5} // fix to keep controls above gradient
    >
      {!hideSkip && (
        <Button
          aria-label="Back 5 seconds"
          w={{ base: CONTROL_BUTTON_BASE_PX, lg: CONTROL_BUTTON_LG_PX }}
          h={{ base: CONTROL_BUTTON_BASE_PX, lg: CONTROL_BUTTON_LG_PX }}
          onClick={() => {
            skipBack(5);
          }}
          borderRadius="full"
          isDisabled={disabled}
          pointerEvents="all"
          fontSize={{ base: "lg", lg: "md" }}
        >
          -5s
        </Button>
      )}
      <IconButton
        aria-label={playing ? "Pause" : "Play"}
        w={{ base: CONTROL_BUTTON_BASE_PX, lg: CONTROL_BUTTON_LG_PX }}
        h={{ base: CONTROL_BUTTON_BASE_PX, lg: CONTROL_BUTTON_LG_PX }}
        // Pause icon only shows when hovering if in clip mode
        display={
          (isForClip && (isHovering || !playing)) || !isForClip
            ? undefined
            : "none"
        }
        icon={
          <Icon
            as={playing ? pauseIcon : playIcon}
            boxSize={{
              base: CONTROL_BUTTON_BASE_BOX,
              lg: CONTROL_BUTTON_LG_BOX,
            }}
            ml={isForClip && !playing ? 2 : 0}
          />
        }
        onClick={() => {
          if (playing) {
            pause();
          } else {
            play();
          }
        }}
        isRound
        isDisabled={disabled}
        pointerEvents="all"
      />

      {!hideSkip && (
        <Button
          aria-label="Forward 5 seconds"
          w={{ base: CONTROL_BUTTON_BASE_PX, lg: CONTROL_BUTTON_LG_PX }}
          h={{ base: CONTROL_BUTTON_BASE_PX, lg: CONTROL_BUTTON_LG_PX }}
          onClick={() => {
            skipForward(5);
          }}
          borderRadius="full"
          isDisabled={disabled}
          pointerEvents="all"
          fontSize={{ base: "lg", lg: "md" }}
        >
          +5s
        </Button>
      )}
    </ButtonGroup>
  );
};

export default VideoControls;
