import { ReactElement } from "react";

import { isString } from "../../utils/string";

export type RenderContent = null | undefined | false | string | ReactElement;

export const renderContentOrDefault = (
  content: RenderContent,
  DefaultContent: JSX.Element
): JSX.Element => (!content || isString(content) ? DefaultContent : content);

export const tertiaryButtonVariantProps = {
  variant: "ghost",
  fontWeight: "500",
  _disabled: {
    background: "transparent",
    color: "gray.200",
    _hover: {
      background: "gray.50",
      color: "gray.400",
    },
  },
};
export const primaryButtonGrayProps = {
  ...tertiaryButtonVariantProps,
  color: "gray.900",
  _hover: {
    bg: "blue.50",
    color: "blue.600",
  },
};
