import {
  Flex,
  Input,
  InputProps,
  SystemProps,
  useControllableState,
  useTheme,
} from "@chakra-ui/react";
import React, { ForwardedRef, forwardRef, useEffect, useState } from "react";

import { useForwardedRef } from "../../hooks/useForwardedRef";

type VerificationInputProps = Pick<
  InputProps,
  | "placeholder"
  | "autoFocus"
  | "isDisabled"
  | "onFocus"
  | "onBlur"
  | "name"
  | "gap"
  | "w"
  | "h"
> & {
  /** Number of characters in the input */
  length?: number;
  value?: string;
  readOnly?: boolean;
  /** Regex style character group, e.g. `A-Za-z0-9` */
  validChars?: string;
  /** Will hide entered characters with an asterisk */
  passwordMode?: boolean;
  /** Callback for when a character changes */
  onChange?: (value: string) => void;
  /** Callback for when all characters have been filled */
  onComplete?: (value: string) => void;
  /** Styles for a character input that has not yet been reached */
  inactiveStyle?: SystemProps;
};

/**
 * A fork of [react-verification-input](https://github.com/andreaswilli/react-verification-input)
 * that wraps Chakra components.
 */
const Component: React.FC<
  VerificationInputProps & { forwardedRef: ForwardedRef<HTMLInputElement> }
> = ({
  length = 6,
  validChars = "A-Za-z0-9",
  placeholder = "",
  autoFocus = false,
  isDisabled: isDisabledProp = false,
  readOnly,
  passwordMode,
  onChange,
  onFocus,
  onBlur,
  onComplete,
  inactiveStyle = {},
  name,
  value: propValue = "",
  forwardedRef,
  ...styles
}) => {
  const isDisabled = isDisabledProp || readOnly;
  const [value, setValue] = useControllableState({
    defaultValue: "",
    value: propValue,
    onChange,
  });
  const [isActive, setActive] = useState(autoFocus && !isDisabled);

  const inputRef = useForwardedRef(forwardedRef);

  const { colors } = useTheme();

  useEffect(() => {
    if (isDisabled) {
      setActive(false);
    }
  }, [isDisabled]);

  const handleClick = (): void => {
    inputRef.current?.focus();
  };

  const isCharacterSelected = (i: number): boolean => {
    return (
      isActive &&
      (value.length === i || (value.length === i + 1 && length === i + 1))
    );
  };

  const isCharacterInactive = (i: number): boolean =>
    isDisabled || value.length < i;

  const propsForCharacter = (i: number): SystemProps => {
    const result: SystemProps = {};
    const isInactive = isCharacterInactive(i);
    if (isInactive) {
      Object.assign(result, inactiveStyle);
    }
    const isSelected = isCharacterSelected(i);
    if (isSelected) {
      result.boxShadow = `0 0 0 1px ${colors.blue[500]}`;
      result.borderColor = "blue.500";
    }

    return result;
  };

  return (
    <Flex
      position="relative"
      onClick={() => inputRef.current?.focus()}
      cursor={
        readOnly
          ? "default"
          : isDisabled
          ? "not-allowed"
          : isActive
          ? "text"
          : undefined
      }
      gap="2"
      w={280}
      h="12"
      {...styles}
    >
      <Input
        ref={inputRef}
        isDisabled={isDisabled}
        readOnly={readOnly}
        autoComplete="one-time-code"
        variant="unstyled"
        color="transparent"
        cursor="inherit"
        _disabled={{ cursor: "inherit" }}
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        autoFocus={autoFocus}
        spellCheck={false}
        value={value}
        onChange={(event) => {
          const matchingChars =
            event.target.value.match(RegExp(`[${validChars}]`, "g")) ?? [];
          const parsedVal = matchingChars.slice(0, length).join("");

          onChange?.(parsedVal);
          setValue(parsedVal);

          if (parsedVal.length === length) {
            onComplete?.(parsedVal);
          }
        }}
        onKeyDown={(event) => {
          // do not allow to change cursor position
          if (
            new Set(["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"]).has(
              event.key
            )
          ) {
            event.preventDefault();
          }
        }}
        onFocus={(e) => {
          setActive(true);
          onFocus?.(e);
        }}
        onBlur={(e) => {
          setActive(false);
          onBlur?.(e);
        }}
        onSelect={() => {
          const val = inputRef.current?.value ?? "";
          inputRef.current?.setSelectionRange(val.length, val.length);
        }}
        type={passwordMode ? "password" : undefined}
      />
      {[...Array(length)].map((_, i) => (
        <Input
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          readOnly={readOnly}
          _readOnly={{ bg: "gray.50" }}
          _hover={isDisabled ? { pointerEvents: "none" } : {}}
          as={Flex}
          flex="1"
          align="center"
          justify="center"
          userSelect="none"
          onClick={handleClick}
          h="100%"
          {...propsForCharacter(i)}
        >
          {passwordMode && value[i] ? "*" : value[i] || placeholder}
        </Input>
      ))}
    </Flex>
  );
};

export const VerificationInput = forwardRef<
  HTMLInputElement,
  VerificationInputProps
>((props, ref) => <Component {...props} forwardedRef={ref} />);
