import { Box } from "@chakra-ui/react";
import React from "react";

const TooltipContainer: React.FC<{
  width?: string;
  children: React.ReactNode;
}> = ({ width, children }) => (
  <Box
    w={width || "340px"}
    border="1px solid"
    borderColor="gray.100"
    bg="gray.600"
    color="white"
    fontSize="xs"
    p="3"
    borderRadius="6px"
  >
    {children}
  </Box>
);

export default TooltipContainer;
