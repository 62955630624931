import {
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  useControllableState,
} from "@chakra-ui/react";
import React from "react";

import { Button } from "../Buttons";

/** This can be extended to support button-level `isDisabled` (or similar) */
type ButtonData = {
  label: string;
  as?: React.ReactNode;
};

export type SmallSegmentedButtonGroupProps = {
  /** Config for buttons */
  buttons?: ButtonData[];
  /** Label of the active button. Defaults to the first button */
  activeButton?: string;
  onChange?(label: string): void;
  groupProps?: ButtonGroupProps;
  buttonProps?: ButtonProps;
  activeButtonProps?: ButtonProps;
  buttonWidth: string;
};

/**
 * A connected button group that displays one button as "active"
 */
export const SmallSegmentedButtonGroup: React.FC<
  SmallSegmentedButtonGroupProps
> = ({
  buttons,
  onChange,
  groupProps,
  buttonProps,
  activeButtonProps,
  buttonWidth,
  ...props
}) => {
  const [activeButton, setActiveButton] = useControllableState({
    value: props.activeButton,
    defaultValue: buttons?.[0]?.label,
    onChange,
  });

  if (!buttons?.length) return null;

  return (
    <ButtonGroup
      bg="white"
      size="xs"
      variant="chalk"
      gap="0"
      spacing="0"
      {...groupProps}
    >
      {buttons.map(({ label, as, ...button }) => (
        <Button
          key={label}
          size="xs"
          border="1px solid"
          borderRight="none"
          borderRadius="0"
          borderColor="gray.200 !important"
          flex="1 0 0"
          fontWeight="400"
          color="gray.800"
          width={buttonWidth}
          isActive={label === activeButton}
          {...buttonProps}
          _active={{
            bg: "blue.50",
            color: "blue.600",
            ...activeButtonProps,
            fontWeight: "500",
          }}
          _first={{
            borderLeftRadius: "4px",
          }}
          _last={{
            borderRightRadius: "4px",
            borderRight: "1px solid",
          }}
          onClick={() => {
            onChange?.(label);
            setActiveButton(label);
          }}
        >
          {as ?? label}
        </Button>
      ))}
    </ButtonGroup>
  );
};
