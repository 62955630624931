import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

import { Button, ConfirmModal, useToast } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { useUpdateOrganizationGenderLabelsMutation } from "../../graphql";

interface FormValues {
  M: string;
  F: string;
}

const EditGenderLabelsButton: React.FC<{
  genderLabels: {
    M: string;
    F: string;
  };
}> = ({ genderLabels }) => {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();

  const [newValues, setNewValues] = React.useState<FormValues | null>(null);
  const genderLabelsModal = useDisclosure();
  const confirmUpdateModal = useDisclosure();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<FormValues>({
    defaultValues: genderLabels,
  });
  const canSubmit = isDirty && isValid;

  const [updateLabels] = useUpdateOrganizationGenderLabelsMutation({
    onCompleted: (data) => {
      const organization = data.updateOrganizationGenderLabels?.organization;
      if (organization) {
        toast({
          status: "success",
          title: "Gender labels saved",
        });
        reset({
          M: organization.genderLabelMale || "",
          F: organization.genderLabelFemale || "",
        });
        confirmUpdateModal.onClose();
      }
    },
    onError: () => {
      toast({
        status: "error",
        title: "Gender labels not saved",
        description: "There was a problem - please try again",
      });
    },
  });

  const notEmptyString = (value: string): boolean => {
    return value.trim().length > 0;
  };

  return (
    <>
      <Button
        fontSize="inherit"
        onClick={genderLabelsModal.onOpen}
        variant="link"
        color="link.default"
        fontWeight="bold"
      >
        adjust gender labels
      </Button>
      <Modal
        isOpen={genderLabelsModal.isOpen}
        onClose={() => {
          reset(genderLabels);
          genderLabelsModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Gender Labels</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form>
              <FormControl isRequired isInvalid={errors.M !== undefined} mb={4}>
                <FormLabel htmlFor="M">Inferred male</FormLabel>
                <Input
                  {...register("M", {
                    required: true,
                    maxLength: 30,
                    validate: {
                      notEmptyString,
                    },
                  })}
                />
                {errors.M?.type !== undefined && (
                  <FormErrorMessage>
                    {errors.M.type === "maxLength"
                      ? "Field is too long"
                      : errors.M.type === "required"
                      ? "Field is required"
                      : ""}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isRequired isInvalid={errors.F !== undefined} mb={4}>
                <FormLabel htmlFor="F">Inferred female</FormLabel>
                <Input
                  {...register("F", {
                    required: true,
                    maxLength: 30,
                    validate: {
                      notEmptyString,
                    },
                  })}
                />
                {errors.F?.type !== undefined && (
                  <FormErrorMessage>
                    {errors.F.type === "maxLength"
                      ? "Field is too long"
                      : errors.F.type === "required"
                      ? "Field is required"
                      : ""}
                  </FormErrorMessage>
                )}
              </FormControl>
              <Flex justifyContent="space-between" mt="10">
                <Button
                  variant="outline"
                  borderColor="transparent"
                  size="sm"
                  fontWeight="400"
                  onClick={() => {
                    genderLabelsModal.onClose();
                    reset(genderLabels);
                  }}
                >
                  Cancel
                </Button>
                <Flex alignItems="center">
                  <Button
                    type="submit"
                    variant="solid"
                    data-testid="save"
                    size="sm"
                    fontWeight="400"
                    isDisabled={!canSubmit}
                    onClick={handleSubmit((formData) => {
                      genderLabelsModal.onClose();
                      confirmUpdateModal.onOpen();
                      setNewValues(formData);
                    })}
                  >
                    Save
                  </Button>
                </Flex>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
      {confirmUpdateModal.isOpen && (
        <ConfirmModal
          isOpen
          modalTitleText="Update Gender Labels?"
          modalBodyText={
            <>
              Are you sure you want to update Insights gender labels? This will
              change how candidate gender is displayed across all reports and
              users.
            </>
          }
          onCancel={() => {
            reset(genderLabels);
            confirmUpdateModal.onClose();
          }}
          onConfirm={() => {
            sendGAEvent("gender_labels", "analytics", "update");
            const newLabelMale = (newValues?.M || "").trim();
            const newLabelFemale = (newValues?.F || "").trim();
            if (newLabelMale === "" || newLabelFemale === "") {
              return;
            }
            updateLabels({
              variables: {
                genderLabelMale: newLabelMale,
                genderLabelFemale: newLabelFemale,
              },
            });
            reset(genderLabels);
            confirmUpdateModal.onClose();
          }}
        />
      )}
    </>
  );
};

export default EditGenderLabelsButton;
