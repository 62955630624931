import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

export const EmptyVideoPlayer: React.FC<IconProps> = (props) => (
  <Icon width="92" height="93" viewBox="0 0 92 93" fill="none" {...props}>
    <path
      d="M25.4806 11.8135L76.3103 25.4333C80.0994 26.4486 82.3443 30.3368 81.329 34.1259L68.9468 80.337C67.9315 84.1261 64.0432 86.3709 60.2542 85.3557L9.42446 71.7359C5.63541 70.7206 3.39053 66.8324 4.40581 63.0433L16.788 16.8322C17.8154 13.0463 21.7036 10.8015 25.4806 11.8135Z"
      fill="#D3D8DE"
    />
    <path
      d="M28.4424 61.4625L61.3584 70.2823C63.7145 70.9136 65.1102 73.3311 64.4789 75.6872C63.8476 78.0433 61.4301 79.439 59.074 78.8077L21.8953 68.8457L23.0375 64.583C23.6688 62.2269 26.0863 60.8312 28.4424 61.4625Z"
      fill="#E5E7EA"
    />
    <path
      d="M16.0127 58.1344C18.3688 58.7657 19.7645 61.1831 19.1332 63.5392C18.5019 65.8953 16.0844 67.291 13.7283 66.6597C11.3722 66.0284 9.9765 63.611 10.6078 61.2549C11.2391 58.8988 13.6566 57.5031 16.0127 58.1344Z"
      fill="#9CA5AE"
    />
    <path
      d="M26.7939 17.8766L72.1421 30.0276C74.4982 30.6589 75.8939 33.0763 75.2626 35.4324L67.9657 62.6649C67.3344 65.021 64.9169 66.4167 62.5608 65.7854L17.2127 53.6344C14.8565 53.0031 13.4608 50.5856 14.0922 48.2295L21.3891 20.9971C22.0204 18.6409 24.4378 17.2452 26.7939 17.8766Z"
      fill="#B8BEC5"
    />
    <path
      d="M20.5781 48.1875L61.7827 59.2282"
      stroke="#67717E"
      strokeWidth="2.81416"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.4936 52.9731C29.8342 53.3323 31.2117 52.537 31.5709 51.1964C31.9301 49.8558 31.1348 48.4783 29.7942 48.1191C28.4536 47.7599 27.0761 48.5552 26.7169 49.8958C26.3577 51.2364 27.153 52.6139 28.4936 52.9731Z"
      fill="#D3D8DE"
    />
    <path
      d="M49.6156 38.8493L44.462 33.0258C44.063 32.5776 43.3766 32.5328 42.9267 32.9328C42.7603 33.0775 42.6473 33.2638 42.5935 33.4645L40.3759 41.741C40.2203 42.3216 40.567 42.9222 41.1477 43.0778C41.3484 43.1315 41.5769 43.1297 41.7747 43.0551L49.1492 40.5899C49.7205 40.4003 50.0161 39.7682 49.8232 39.2089C49.7718 39.069 49.7023 38.9485 49.6156 38.8493Z"
      fill="#393F47"
    />
    <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
      <path
        d="M73.2897 39.5566C78.3991 41.6619 84.2486 39.221 86.3549 34.1042C88.4612 28.9875 86.0269 23.1329 80.9175 21.0276C75.8081 18.9223 69.9587 21.3633 67.8524 26.48C65.7461 31.5967 68.1803 37.4513 73.2897 39.5566Z"
        fill="#9CA5AE"
      />
    </g>
    <path
      d="M74.2429 37.6426C79.3523 39.7479 85.2017 37.3069 87.308 32.1902C89.4143 27.0734 86.9801 21.2188 81.8707 19.1135C76.7613 17.0082 70.9118 19.4492 68.8055 24.5659C66.6992 29.6827 69.1335 35.5373 74.2429 37.6426Z"
      fill="#B8BEC5"
    />
    <path
      d="M79.9639 23.2577L79.9495 23.251C78.9544 22.8699 76.8202 22.7558 75.8701 25.0647C75.6905 25.5002 75.8965 25.9969 76.3322 26.1766C76.767 26.356 77.2635 26.1486 77.443 25.7132C77.9612 24.4549 79.0126 24.7252 79.3307 24.839C80.3327 25.2582 80.7337 26.0542 80.373 26.9312C80.1071 27.5753 79.7445 27.6984 78.8564 27.86C78.0631 28.0022 76.8604 28.2255 76.3335 29.6302L76.2398 29.8588C76.0603 30.2942 76.2663 30.791 76.7019 30.9707C77.1325 31.148 77.631 30.9478 77.8209 30.5161L77.9352 30.2387C78.11 29.7715 78.377 29.6847 79.1653 29.5402L79.1778 29.5398C80.0225 29.3837 81.303 29.1403 81.9459 27.5797C82.6718 25.8163 81.8587 24.0382 79.9639 23.2577Z"
      fill="#F5F6F7"
    />
    <path
      d="M76.3997 31.8451C75.9201 31.648 75.3681 31.8777 75.1697 32.3586C74.9725 32.8389 75.2024 33.3916 75.6818 33.5897C76.1688 33.7841 76.7187 33.5586 76.9118 33.0762C77.1093 32.5951 76.8799 32.0434 76.3997 31.8451Z"
      fill="#F5F6F7"
    />
  </Icon>
);
