/**
 * Error messaging used for display in the IA. Also used for messaging around
 * the Add BrightHire feature since most of the same error states apply.
 */
const errorMap: { [key: string]: MessageContent } = {
  // Zoom
  PMI: {
    primary: "BrightHire doesn’t record meetings using a ‘Personal Meeting ID’",
    secondary:
      "BrightHire Notes and Recordings are not available for this meeting type.",
  },
  RECURRING: {
    primary: "BrightHire doesn’t record recurring meetings such as this one.",
    secondary: "BrightHire is not available for this meeting type.",
  },
  INSTANT: {
    primary: "BrightHire doesn’t record instant meetings such as this one.",
    secondary: "BrightHire is not available for this meeting type.",
  },
  "Zoom disabled": {
    primary: "Zoom is not setup for your organization yet.",
    secondary:
      "BrightHire is only available on Zoom meetings – please ask your BrightHire admin.",
  },
  "Host cloud recording disabled": {
    primary: "Please ask the meeting host to enable cloud recording",
    secondary:
      "The meeting host currently has cloud recording disabled in their user settings. This must be resolved in order to record the meeting. User settings can take up to an hour to propagate after they are changed in Zoom.",
  },
  // Google Meet
  "Google Meet is disabled": {
    primary: "Google Meet is not setup for your organization.",
    secondary: "Please ask your BrightHire admin to enable Google Meet.",
  },
  // Teams
  "Microsoft Teams is disabled": {
    primary: "Microsoft Teams is not setup for your organization.",
    secondary: "Please ask your BrightHire admin to enable Microsoft Teams.",
  },
  "Recorder in waiting room": {
    primary: "Please admit the BrightHire Notetaker to this meeting.",
    secondary:
      "Once BrightHire Notetaker is let in, we'll record the meeting and load your BrightHire experience. ",
  },
  // General
  "Meeting not found": {
    primary: "There isn't a meeting we can join.",
    secondary:
      "We either couldn't find a meeting, or the meeting is owned by another organization.",
  },
  "Meeting not found - Native": {
    primary: "We couldn't find a meeting to join.",
    secondary:
      "We either weren't scheduled to join the meeting or couldn't find the meeting.",
  },
  "Invalid interviewer": {
    primary: "There isn't a meeting we can join.",
    secondary:
      "We either couldn't find a meeting, or the meeting is owned by another organization.",
  },
  "Candidate opt-out": {
    primary: "This candidate opted out of BrightHire recordings.",
    secondary:
      "This candidate requested not to be recorded, so BrightHire is not available for this user.",
  },
  "User is internal candidate": {
    primary:
      "BrightHire is available only to the person conducting the interview.",
    secondary: "You are listed as the candidate for this interview. Good luck!",
  },
};

/**
 * Returns appropriate long-form error messaging for a given short-form message
 * @param key Text string to find in the errorMap
 * @param strict Return an object with keys containing empty strings if no exact
 * matches are found
 */
export const getErrorContent = (
  key: string,
  strict = false
): MessageContent => {
  const validatedKey = Object.keys(errorMap).find(
    (k) =>
      k.toLowerCase() ===
      key.replace("Invalid meeting type: ", "").toLowerCase()
  );
  const value = validatedKey ? errorMap[validatedKey] : undefined;
  if (value) {
    return value;
  }
  if (strict) {
    return { primary: "", secondary: "" };
  }
  return {
    primary: "An unexpected error has occurred.",
    secondary: "Please reload the page or try again in a few minutes.",
  };
};

interface MessageContent {
  primary: string;
  action?: string;
  secondary: string;
  launchButtonText?: string;
  tertiary?: string;
}
export enum VideoIntegration {
  GOOGLE_MEET = "GOOGLE_MEET",
  ZOOM = "ZOOM",
  TEAMS = "TEAMS",
}
export interface Message {
  content: MessageContent;
  retry: boolean;
  error: boolean;
  videoIntegration: VideoIntegration;
  videoMeetingUrl?: string | null;
  showLaunchButton: boolean;
}
