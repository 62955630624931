import {
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  useControllableState,
} from "@chakra-ui/react";
import React from "react";

import { asArray } from "../../utils/array";
import { Button } from "../Buttons";
import { OUTLINE_STYLES, SOLID_STYLES } from "./styles";

/** This can be extended to support button-level `isDisabled` (or similar) */
type ButtonData<T extends string> = {
  label: T;
};

type SegmentedButtonBaseProps<T extends string, V extends T | T[]> = {
  /** Config for buttons */
  buttons?: ButtonData<T>[];
  /** Label of the active button(s) (controlled mode) */
  activeButton?: V;
  /** The default active button(s) (un-controlled mode) */
  defaultButton?: V;
  onChange?(label: V): void;
  /** At least one button must be selected unless `allowEmpty` is used */
  allowEmpty?: boolean;
  groupProps?: ButtonGroupProps;
  buttonProps?: ButtonProps;
  activeButtonProps?: ButtonProps;
  variant?: "solid" | "outline";
};

export type SegmentedButtonSingleProps<T extends string> =
  SegmentedButtonBaseProps<T, T> & {
    allowMultiple?: false;
  };

export type SegmentedButtonMultiProps<T extends string> =
  SegmentedButtonBaseProps<T, T[]> & {
    allowMultiple: true;
  };

export type SegmentedButtonProps<T extends string> =
  | SegmentedButtonSingleProps<T>
  | SegmentedButtonMultiProps<T>;

/**
 * A connected button group that displays one or more buttons as "active".
 *
 * To allow multiple active buttons, use the `allowMultiple` prop
 */
export const SegmentedButton = <T extends string>({
  buttons,
  activeButton,
  defaultButton,
  allowEmpty,
  allowMultiple,
  variant = "solid",
  groupProps,
  buttonProps,
  activeButtonProps,
  ...props
}: SegmentedButtonProps<T>): React.ReactElement | null => {
  const [activeButtons, setActiveButtons] = useControllableState({
    value: activeButton && asArray(activeButton),
    defaultValue: defaultButton ? asArray(defaultButton) : [],
    onChange: (val) => {
      if (allowMultiple) {
        (props as SegmentedButtonMultiProps<T>).onChange?.(val);
      } else {
        (props as SegmentedButtonSingleProps<T>).onChange?.(val[0]);
      }
    },
  });

  if (!buttons?.length) return null;

  const styles = variant === "solid" ? SOLID_STYLES : OUTLINE_STYLES;

  return (
    <ButtonGroup {...styles.groupStyles} {...groupProps}>
      {buttons.map(({ label, ...button }) => {
        const isActive = activeButtons.includes(label);

        return (
          <Button
            key={label}
            isActive={isActive}
            {...styles.buttonStyles}
            {...button}
            {...buttonProps}
            _active={{
              // eslint-disable-next-line no-underscore-dangle
              ...styles.buttonStyles._active,
              ...activeButtonProps,
            }}
            onClick={() => {
              let result: T[];

              if (!allowMultiple) {
                result = isActive ? [] : [label];
              } else {
                result = isActive
                  ? activeButtons.filter((b) => b !== label)
                  : activeButtons.concat(label);
              }

              if (result.length === 0 && !allowEmpty) return;

              setActiveButtons(result);
            }}
          >
            {label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};
