import { ButtonGroupProps, ButtonProps } from "@chakra-ui/react";

type StyleConfig = {
  groupStyles: ButtonGroupProps;
  buttonStyles: ButtonProps;
};

/** Segmented button as shown [here](https://www.figma.com/file/uN2Pi6GfUePpsJUUZORtBU/Prism?type=design&node-id=2725-5719&mode=dev) */
export const SOLID_STYLES: StyleConfig = {
  groupStyles: {
    bg: "blackAlpha.100",
    p: "1",
    borderRadius: "lg",
    size: "sm",
    variant: "chalk",
  },
  buttonStyles: {
    lineHeight: "5",
    fontWeight: "regular",
    h: "9",
    flex: "1 0 0",
    borderRadius: "md",
    color: "gray.900",
    _active: {
      bg: "white",
      fontWeight: "semibold",
    },
  },
};

/** Segmented button as shown [here](https://www.figma.com/file/uN2Pi6GfUePpsJUUZORtBU/Prism?type=design&node-id=1637-34037&mode=dev) */
export const OUTLINE_STYLES: StyleConfig = {
  groupStyles: {
    isAttached: true,
    size: "xs",
  },
  buttonStyles: {
    variant: "outline",
    color: "gray.800",
    borderColor: "gray.200",
    fontWeight: "regular",
    _active: {
      bg: "blue.50",
      color: "blue.600",
      fontWeight: "medium",
    },
    _focus: {},
  },
};
