import { SystemProps, Tooltip } from "@chakra-ui/react";
import React from "react";
import { IoCopyOutline } from "react-icons/io5";

import { IconButton, successToast, useToast } from "../../../../../components";
import { copyScores } from "../../../../../utils/clipboard/scoring";
import { useSendGAEvent } from "../../../../../utils/googleAnalytics";
import {
  CallBetaFragment,
  InterviewerScorecardFragment,
  ScorecardItem,
} from "../../../../graphql";

type CopyScoresButtonProps = {
  /** Needed for call details in copied content */
  call: Pick<CallBetaFragment, "id" | "candidate" | "position" | "startTime">;
  scorecard: Pick<
    InterviewerScorecardFragment,
    "overallScoringEnabled" | "overallScore" | "overallNotes"
  > & {
    competencies: Pick<
      ScorecardItem,
      "scoringEnabled" | "score" | "itemText" | "notes"
    >[];
  };
  /** If `true`, this component will return `null` if there is nothing to copy */
  hideIfDisabled?: boolean;
} & SystemProps;

/** Simple "Copy" icon button for a scorecard  */
const CopyScoresButton: React.FC<CopyScoresButtonProps> = ({
  call,
  scorecard,
  hideIfDisabled,
  ...styles
}) => {
  const { competencies } = scorecard;
  const toast = useToast();
  const sendGaEvent = useSendGAEvent();

  const canCopy =
    (scorecard.overallScoringEnabled && scorecard?.overallScore) ||
    competencies.some(
      (competency) => competency.scoringEnabled && competency.score
    );

  if (!canCopy && hideIfDisabled) {
    return null;
  }

  return (
    <Tooltip label="Copy scores and notes">
      <IconButton
        aria-label="Copy scores and notes"
        disabled={!canCopy}
        color="blue.600"
        bg="transparent"
        borderRadius="md"
        _hover={{ bg: "blue.50" }}
        _active={{ bg: "blue.100" }}
        _disabled={{
          color: "gray.200",
          pointerEvents: "none",
        }}
        boxSize="8"
        minW="unset"
        {...styles}
        icon={<IoCopyOutline size={20} />}
        onClick={() => {
          copyScores({
            call,
            scorecard,
            competencies,
            onComplete: () => {
              sendGaEvent("Copy notes", "scoring", "Interview sidebar");
              successToast(toast, "Scores copied to clipboard");
            },
          });
        }}
      />
    </Tooltip>
  );
};

export default CopyScoresButton;
