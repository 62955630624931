import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

type AiNotesMessageProps = {
  children: React.ReactNode;
} & BoxProps;

const AiNotesMessage: React.FC<AiNotesMessageProps> = ({
  children,
  ...boxProps
}) => (
  <Box
    data-tour-id="ai-notes-message-container"
    alignItems="center"
    display="flex"
    flexDir="column"
    h="100%"
    pt="124px"
    px="6"
    width="100%"
    {...boxProps}
  >
    {children}
  </Box>
);

export default AiNotesMessage;
