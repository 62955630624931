import { useApolloClient } from "@apollo/client";
import {
  Box,
  Checkbox,
  FormLabel,
  Heading,
  Input,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { IoCopyOutline } from "react-icons/io5";

import {
  Button,
  ConfirmModal,
  IconButton,
  Link,
  LoadingIndicator,
  useTheme,
  useToast,
} from "../../../components";
import { copy } from "../../../utils/clipboard";
import {
  CurrentUserFragment,
  RestApiSetupDocument,
  useDeleteApiTokenMutation,
  useDeleteWebhookMutation,
  useRestApiSetupQuery,
  useUpdateApiTokenMutation,
  useUpdateWebhookMutation,
} from "../../graphql";

interface RestApiSettingsProps {
  currentUser: CurrentUserFragment;
}

const RestApiSettings: React.FC<RestApiSettingsProps> = ({ currentUser }) => {
  const theme = useTheme();
  const toast = useToast();
  const restApiSetup = useRestApiSetupQuery();

  return (
    <Box>
      <Heading as="h1" size="md">
        Rest API Setup
      </Heading>
      <Box my={8}>
        <Heading as="h3" size="sm" mb={2}>
          Instructions
        </Heading>
        <Text maxW="500px" mb={2}>
          Please see the{" "}
          <Link target="_blank" href="/api/v1/docs/redoc.html">
            REST API documentation
          </Link>
          .
        </Text>
      </Box>
      <Box minW="1100px">
        <Heading as="h2" size="sm">
          API Tokens
        </Heading>
        {restApiSetup.loading ? (
          <LoadingIndicator />
        ) : restApiSetup.data?.restApiSetup?.apiTokens ? (
          <UnorderedList>
            {restApiSetup.data?.restApiSetup.apiTokens.map((t) => (
              <ListItem key={t.id} mb="4px">
                <Text
                  as="span"
                  display="inline-block"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  width="200px"
                  verticalAlign="middle"
                >
                  {t.name ?? "No name"}
                </Text>
                <Text
                  as="span"
                  display="inline-block"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  width="650px"
                  verticalAlign="middle"
                  bgColor={theme.colors.gray[100]}
                  ml="4"
                  px="4px"
                  py="4px"
                >
                  {(t.tokenPrefix ?? "").length < 15
                    ? `${t.tokenPrefix ?? ""}...`
                    : t.tokenPrefix}
                </Text>
                <IconButton
                  display="inline-block"
                  verticalAlign="middle"
                  icon={<IoCopyOutline size="sm" />}
                  aria-label="Copy token"
                  size="sm"
                  ml="4"
                  p="2"
                  onClick={() => {
                    copy(t.tokenPrefix || "");
                    toast({
                      title: "Copied to clipboard",
                    });
                  }}
                  color={theme.colors.blue[400]}
                  bg="transparent"
                  disabled={(t.tokenPrefix?.length ?? 0) < 15}
                />
                <Box as="span" display="inline-block" ml="4">
                  <UpdateAPIToken id={t.id} name={t.name} />
                </Box>
                <Box as="span" display="inline-block" ml="4">
                  <DeleteAPIToken id={t.id} />
                </Box>
              </ListItem>
            ))}
          </UnorderedList>
        ) : (
          <Text>No tokens</Text>
        )}
        <UpdateAPIToken />
      </Box>

      <Box>
        <Heading as="h2" size="sm" mt="4">
          Webhooks
        </Heading>
        {restApiSetup.loading ? (
          <LoadingIndicator />
        ) : restApiSetup.data?.restApiSetup?.webhooks ? (
          <UnorderedList>
            {restApiSetup.data?.restApiSetup.webhooks.map((w) => (
              <ListItem key={w.id} mb="4px">
                <Text
                  as="span"
                  display="inline-block"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  width="200px"
                  verticalAlign="middle"
                >
                  {w.webhookUrl}
                </Text>
                <Text
                  as="span"
                  display="inline-block"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  width="650px"
                  verticalAlign="middle"
                  bgColor={theme.colors.gray[100]}
                  ml="4"
                  px="4px"
                  py="4px"
                >
                  {(w.webhookSecret ?? "").length < 15
                    ? `${w.webhookSecret ?? ""}...`
                    : w.webhookSecret}
                </Text>
                <IconButton
                  display="inline-block"
                  verticalAlign="middle"
                  icon={<IoCopyOutline size="sm" />}
                  aria-label="Copy token"
                  size="sm"
                  ml="4"
                  p="2"
                  onClick={() => {
                    copy(w.webhookSecret || "");
                    toast({
                      title: "Copied to clipboard",
                    });
                  }}
                  color={theme.colors.blue[400]}
                  bg="transparent"
                  disabled={(w.webhookSecret?.length ?? 0) < 15}
                />
                <Box as="span" display="inline-block" ml="4">
                  <UpdateWebhook
                    id={w.id}
                    webhookUrl={w.webhookUrl}
                    interviewCompleted={w.eventInterviewCompleted}
                    candidateUpdated={w.eventCandidateUpdatedRecordingDisabled}
                  />
                </Box>
                <Box as="span" display="inline-block" ml="4">
                  <DeleteWebhook id={w.id} />
                </Box>
              </ListItem>
            ))}
          </UnorderedList>
        ) : (
          <Text>No webhooks configured</Text>
        )}
        <UpdateWebhook />
      </Box>
    </Box>
  );
};

const UpdateAPIToken: React.FC<{
  id?: string;
  name?: string | null;
}> = ({ id, name }) => {
  const toast = useToast();
  const client = useApolloClient();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const inputRef = useRef<HTMLInputElement>(null);
  const [mutate, data] = useUpdateApiTokenMutation();
  const createToken = (): void => {
    if (!inputRef?.current?.value) {
      toast({
        status: "error",
        title: "Error",
        description: "Please provide a name for the API token",
      });
      return;
    }
    mutate({
      variables: {
        id,
        name: inputRef.current.value,
      },
      onCompleted: (data, clientOptions) => {
        client.writeQuery({
          query: RestApiSetupDocument,
          data: {
            restApiSetup: data.updateApiToken?.setup,
          },
        });
        onClose();
      },
    });
  };
  return (
    <>
      <Button onClick={onOpen} size="xs" variant="link">
        {id ? "Edit" : "Create new API token"}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {id ? "Edit token" : "Create new API token"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              ref={inputRef}
              defaultValue={name ?? undefined}
              type="text"
              placeholder="Friendly name of the token"
              minWidth="350px"
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={createToken} isLoading={data.loading}>
              {id ? "Save" : "Create"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const DeleteAPIToken: React.FC<{
  id: string;
}> = ({ id }) => {
  const client = useApolloClient();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [mutate] = useDeleteApiTokenMutation();
  return (
    <>
      <Button onClick={onOpen} size="xs" variant="link" color="red">
        Delete
      </Button>
      <ConfirmModal
        modalBodyText="This will delete your API token and it will immediately stop working for any clients using it. Are you sure?"
        isOpen={isOpen}
        onCancel={() => {
          onClose();
        }}
        onConfirm={() => {
          mutate({
            variables: {
              id,
            },
            onCompleted: (data, clientOptions) => {
              client.writeQuery({
                query: RestApiSetupDocument,
                data: {
                  restApiSetup: data.deleteApiToken?.setup,
                },
              });
              onClose();
            },
          });
        }}
      />
    </>
  );
};

const UpdateWebhook: React.FC<{
  id?: string;
  webhookUrl?: string | null;
  interviewCompleted?: boolean | null;
  candidateUpdated?: boolean | null;
}> = ({ id, webhookUrl, interviewCompleted, candidateUpdated }) => {
  const toast = useToast();
  const client = useApolloClient();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const inputRef = useRef<HTMLInputElement>(null);
  const interviewCompletedRef = useRef<HTMLInputElement>(null);
  const candidateUpdatedRef = useRef<HTMLInputElement>(null);
  const [mutate, data] = useUpdateWebhookMutation();
  const createWebhook = (): void => {
    if (!inputRef?.current?.value) {
      toast({
        status: "error",
        title: "Error",
        description: "Please provide a url for the webhook",
      });
      return;
    }
    let validationFailed = true;
    try {
      const u = new URL(inputRef.current.value);
      if (u.protocol === "https:") {
        validationFailed = false;
      }
      // eslint-disable-next-line no-empty
    } catch {}
    if (validationFailed) {
      toast({
        status: "error",
        title: "Error",
        description: "URL must be a valid url, using https://",
      });
      return;
    }
    mutate({
      variables: {
        id,
        url: inputRef.current.value,
        enableInterviewCompleted:
          interviewCompletedRef?.current?.checked ?? false,
        enableCandidateUpdated: candidateUpdatedRef?.current?.checked ?? false,
      },
      onCompleted: (data, clientOptions) => {
        client.writeQuery({
          query: RestApiSetupDocument,
          data: {
            restApiSetup: data.updateWebhook?.setup,
          },
        });
        onClose();
      },
    });
  };
  return (
    <>
      <Button onClick={onOpen} size="xs" variant="link">
        {id ? "Edit" : "Create new webhook"}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {id ? "Edit webhook" : "Create new webhook"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormLabel>Webhook URL</FormLabel>
            <Input
              ref={inputRef}
              defaultValue={webhookUrl ?? undefined}
              type="text"
              placeholder="URL to send the webhook to"
              minWidth="350px"
            />
            <Text mt="8" fontWeight="500">
              Webhook events to send to this URL:
            </Text>
            <List mt="4">
              <ListItem>
                <Checkbox
                  verticalAlign="middle"
                  mr="2"
                  ref={interviewCompletedRef}
                  type="checkbox"
                  defaultChecked={interviewCompleted ?? undefined}
                />
                <Text display="inline-block">Interview Completed Event</Text>
              </ListItem>
              <ListItem mt="2">
                <Checkbox
                  verticalAlign="middle"
                  mr="2"
                  ref={candidateUpdatedRef}
                  type="checkbox"
                  defaultChecked={candidateUpdated ?? undefined}
                />
                <Text display="inline-block">
                  Candidate Recording Disabled Event
                </Text>
              </ListItem>
            </List>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={createWebhook} isLoading={data.loading}>
              {id ? "Save" : "Create"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const DeleteWebhook: React.FC<{
  id: string;
}> = ({ id }) => {
  const client = useApolloClient();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [mutate] = useDeleteWebhookMutation();
  return (
    <>
      <Button onClick={onOpen} size="xs" variant="link" color="red">
        Delete
      </Button>
      <ConfirmModal
        modalBodyText="This will delete your webhook and it will immediately stop working. Are you sure?"
        isOpen={isOpen}
        onCancel={() => {
          onClose();
        }}
        onConfirm={() => {
          mutate({
            variables: {
              id,
            },
            onCompleted: (data, clientOptions) => {
              client.writeQuery({
                query: RestApiSetupDocument,
                data: {
                  restApiSetup: data.deleteWebhook?.setup,
                },
              });
              onClose();
            },
          });
        }}
      />
    </>
  );
};

export default RestApiSettings;
