import {
  Box,
  BoxProps,
  Heading,
  HeadingProps,
  isStyleProp,
  ThemingProps,
  useStyleConfig,
} from "@chakra-ui/react";
import React from "react";

export type CardTitleProps = HeadingProps;

export const CardTitle = React.forwardRef<HTMLHeadingElement, CardTitleProps>(
  (props, ref) => (
    <Heading
      ref={ref}
      as="h2"
      fontSize="sm"
      lineHeight="44px"
      height="45px"
      m={0}
      px="6"
      py="0"
      borderBottomWidth="1px"
      {...props}
    />
  )
);

export interface CardProps extends BoxProps, ThemingProps<"Card"> {}

export const Card = React.forwardRef<
  HTMLDivElement,
  CardProps & {
    children?: React.ReactNode;
  }
>(({ variant, children, ...props }, ref) => {
  const styles = useStyleConfig("Card", { variant });

  const styleProps: CardProps = {};
  const rest: CardProps = {};

  Object.keys(props).forEach((k) => {
    const key = k as keyof typeof props;
    if (isStyleProp(key)) {
      styleProps[key] = props[key];
    } else {
      rest[key] = props[key];
    }
  });

  return (
    <Box ref={ref} sx={{ ...styles, ...styleProps }} {...rest}>
      {children}
    </Box>
  );
});
