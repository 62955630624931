// cspell:ignore Tnum
import { useEffect } from "react";

import {
  doesNumericFontHaveTnumVariant,
  getCharWidthForFont,
  isFontVariantNumericEnabled,
} from "../../utils/font";

export function useFontVariantNumericStrategy(): void {
  let strategy = window.fvStrategy;
  let charWidth = window.fvWidth;

  // If either the font or the browser won't support font-variant-numeric,
  // use spans with the default font. There are other ways to accomplish this
  // for different combinations of font/browser compatibility but none of them
  // are sufficiently "better" for the time spent as of this writing.
  const selectStrategy = (): void => {
    if (!strategy) {
      const browserEnabled = isFontVariantNumericEnabled();
      const fontEnabled = doesNumericFontHaveTnumVariant();
      charWidth = getCharWidthForFont(fontEnabled.fontName);
      if (!fontEnabled.tnum || !browserEnabled) {
        strategy = "span";
      } else {
        strategy = "fontVariantNumeric";
      }
      window.fvStrategy = strategy;
      window.fvWidth = charWidth;
    }
  };

  useEffect(() => {
    selectStrategy();
  }, []);
}
