import { Box, BoxProps, Text, TextProps } from "@chakra-ui/react";
import React from "react";

type HighlightTextProps = {
  text: string;
  keywords: string[];
  matchWholeWords?: boolean;
  highlightedStyle?: BoxProps;
} & TextProps;

const HighlightText: React.FC<HighlightTextProps> = ({
  text,
  keywords,
  matchWholeWords = false,
  highlightedStyle = { fontWeight: "bold" },
  ...rest
}) => {
  const escapeRegExp = (string: string): string => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };
  const regex = matchWholeWords
    ? new RegExp(`\\b(${keywords.map(escapeRegExp).join("|")})\\b`, "gi")
    : new RegExp(`(${keywords.map(escapeRegExp).join("|")})`, "gi");
  const parts = text.split(regex);
  return (
    <Text {...rest}>
      {parts.map((part, index) =>
        keywords.some(
          (keyword) => keyword.toLowerCase() === part.toLowerCase()
        ) ? (
          // eslint-disable-next-line react/no-array-index-key
          <Box as="span" key={index} {...highlightedStyle}>
            {part}
          </Box>
        ) : (
          part
        )
      )}
    </Text>
  );
};
export default HighlightText;
