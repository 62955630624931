import { Box, Flex, Tooltip } from "@chakra-ui/react";
import React from "react";

import {
  formatDateDurationLine,
  monthDisplayMap,
} from "../../../utils/datetime";
import { EventListItem } from "./utils";

type EventsForMonthProps = {
  item: EventListItem;
};

export const EventsForMonth: React.FC<EventsForMonthProps> = ({ item }) => {
  const { month, days } = item;
  return (
    <Box mb="3">
      <Box
        borderRadius="base"
        background="gray.50"
        textAlign="center"
        textColor="gray.800"
        fontSize="xs"
        fontWeight="medium"
        h="5"
        mb="1"
      >
        {monthDisplayMap[month]}
      </Box>
      <Flex direction="column" gap="1">
        {days.map(({ day, dayOfWeek, events }) => (
          <Flex dir="row" gap="3" pt="2" key={day}>
            <Box textAlign="center" w="8">
              <Box fontSize="xx-small" textColor="gray.600">
                {dayOfWeek}
              </Box>
              <Box textColor="gray.800" fontSize="lg">
                {day}
              </Box>
            </Box>
            <Flex direction="column" flex="1" gap="1">
              {events.map(({ importExcludeDecision, ...eventItem }) => {
                const willImport = importExcludeDecision === true;
                const willNotImport = importExcludeDecision === false;

                return (
                  <Tooltip
                    key={eventItem.id}
                    openDelay={500}
                    placement="start"
                    label={
                      willImport
                        ? "Event is set to be imported. Please note that we can only capture events with video conference links."
                        : willNotImport
                        ? "Organization-wide import rules are preventing this event from being captured by BrightHire."
                        : "This event can be imported."
                    }
                  >
                    <Box
                      py="1"
                      px="2"
                      border="1px"
                      textColor="gray.800"
                      borderColor={
                        willImport
                          ? "blue.500"
                          : willNotImport
                          ? "red.500"
                          : "gray.200"
                      }
                      backgroundColor={
                        willImport
                          ? "blue.50"
                          : willNotImport
                          ? "red.50"
                          : "white"
                      }
                    >
                      <Box fontSize="xs" fontWeight="semibold">
                        {eventItem.summary}
                      </Box>
                      <Box fontSize="xs">
                        {formatDateDurationLine(eventItem.start, eventItem.end)}
                      </Box>
                    </Box>
                  </Tooltip>
                );
              })}
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};
