import { ButtonProps, Flex } from "@chakra-ui/react";
import React from "react";

import {
  Button,
  IconButton,
  Tooltip,
  TooltipProps,
  useTheme,
} from "../../../../components";
import { AtsIcon } from "../../../../components/Icons/AtsIcon";
import CopyOutlineIcon from "../../../../components/Icons/CopyOutlineIcon";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { Ats } from "../../../graphql";

export interface CopyNotesButtonsProps extends ButtonProps {
  onCopy(): void;
  onAutofill?(): void;
  autofillEnabled: boolean;
  ats?: Ats;
}

/**
 * This component will render two buttons in the case that autofill is available,
 * and one button otherwise
 *   - When autofill is available the primary button is for
 *     autofill and there is a smaller icon button for copy notes.
 *   - When autofill is not available, copy notes is the only button
 */
const CopyNotesButtons: React.FC<CopyNotesButtonsProps> = ({
  onCopy,
  onAutofill: onAutofillProp,
  autofillEnabled,
  ats,
  ...rest
}) => {
  const { colors } = useTheme();
  const sendGAEvent = useSendGAEvent();

  const isGreenhouse = autofillEnabled && ats === Ats.Greenhouse;
  const isLever = autofillEnabled && ats === Ats.Lever;
  const isAshby = autofillEnabled && ats === Ats.Ashby;

  const onAutofill = autofillEnabled
    ? (): void => {
        sendGAEvent("autofill_scorecard", "call_review", "call_details");
        onAutofillProp?.();
      }
    : undefined;

  const primaryColor = colors.blue[600];
  const secondaryColor = colors.blue[50];
  const tertiaryColor = colors.blue[100];

  const tooltipStyles: Partial<TooltipProps> = {
    padding: "4px 12px",
    bgColor: "gray.700",
    fontSize: "14px",
  };

  return (
    <Flex alignItems="center" gap="2">
      <Button
        size="xs"
        fontSize="sm"
        fontWeight="medium"
        variant="ghost"
        colorScheme="blue"
        data-tour-id="one-click-complete-scorecard"
        ml="3"
        mr="2"
        height="8"
        leftIcon={
          <AtsIcon
            fill="currentColor"
            marginRight="0.4rem"
            width="auto"
            height="16px"
            isGreenhouse={isGreenhouse}
            isLever={isLever}
            isAshby={isAshby}
            fallback={
              <CopyOutlineIcon fill="currentColor" width="16px" height="16px" />
            }
          />
        }
        onClick={onAutofill ?? onCopy}
        {...rest}
      >
        {isGreenhouse
          ? "Complete scorecard"
          : isLever || isAshby
          ? "Complete feedback form"
          : "Copy all notes"}
      </Button>

      {/* Secondary "copy notes" icon button, only shown if autofill is available */}
      {(isGreenhouse || isLever || isAshby) && (
        <Tooltip
          id="copyAllNotes"
          placement="top"
          {...tooltipStyles}
          trigger={
            <IconButton
              icon={<CopyOutlineIcon width="16px" height="16px" />}
              {...rest}
              aria-label="Copy all notes"
              size="xs"
              p="2"
              variant="ghost"
              height="8"
              onClick={onCopy}
              color={primaryColor}
              bg="transparent"
              _hover={{ bg: secondaryColor }}
              _active={{
                bg: tertiaryColor,
              }}
            />
          }
        >
          Copy All Notes
        </Tooltip>
      )}
    </Flex>
  );
};

export default CopyNotesButtons;
