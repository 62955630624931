import { Flex, Heading } from "@chakra-ui/react";
import React from "react";

import { CenteredCardLayout } from "../../../components";
import useCurrentUser from "../../hooks/useCurrentUser";
import useSignOut from "../../hooks/useSignOut";
import SignUpForm from "./SignUpForm";

const RegisterInfoPage: React.FC = () => {
  const currentUser = useCurrentUser();
  const onSignOut = useSignOut({ currentUser });

  return (
    <CenteredCardLayout
      onSignOut={onSignOut}
      containerProps={{ alignItems: "center" }}
    >
      <Flex pt="8" px="4" flexDirection="column" minW="96">
        <Heading
          as="h1"
          fontSize="28px"
          fontWeight="normal"
          mb={4}
          alignSelf="center"
        >
          User Info
        </Heading>
        <SignUpForm />
      </Flex>
    </CenteredCardLayout>
  );
};

export default RegisterInfoPage;
