import { useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { errorToast, successToast, useToast } from "../../../../components";
import { useIsSmallScreen } from "../../../../hooks/useIsSmallScreen";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { getSmartrecruitersLink } from "../../../../utils/smartRecruiters";
import {
  CandidateAlertCandidateSubscription,
  CandidateFragment,
  CandidateLinkType,
  useAddCandidateAlertUserSubscriptionsMutation,
  useCandidateAlertLazyQuery,
  useCandidateAlertUserSubscriptionsQuery,
  useMuteCandidateAlertUserSubscriptionsMutation,
  useUpdateCandidateOptOutMutation,
} from "../../../graphql";
import { useIsExtension } from "../../../hooks/useAppEnvironmentContext";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { useCandidateAlertDisabled } from "../../CandidateAlert/useCandidateAlertDisabled";
import CandidateHeaderBase from "./CandidateHeaderBase";
import DeleteCandidateModal from "./DeleteCandidateModal";
import EditCandidateModal from "./EditCandidateModal";
import ToggleCandidateDeletionModal from "./ToggleCandidateDeletionModal";
import { useCandidatePositionOptions } from "./useCandidatePositionOptions";

const PAGE_LIMIT = 10;

type CandidateHeaderProps = {
  candidate: CandidateFragment;
  positionId?: string;
  isAskEnabled?: boolean;
  onAskClick?: () => void;
  hasCompletedInterviews?: boolean;
};

type CandidateSubscription = Pick<
  CandidateAlertCandidateSubscription,
  "mutedAt"
>;

const CandidateHeader: React.FC<CandidateHeaderProps> = ({
  candidate,
  positionId,
  isAskEnabled = false,
  onAskClick,
  hasCompletedInterviews,
}) => {
  const navigate = useNavigate();
  const sendGAEvent = useSendGAEvent();
  const location = useLocation();
  const toast = useToast();
  const currentUser = useCurrentUser();
  const isExtension = useIsExtension();
  const isSmallScreen = useIsSmallScreen();

  const sendCandidateAlertEvent = (event: string): void =>
    sendGAEvent(
      event,
      "candidate_alert",
      isExtension ? "extension" : "main app",
      undefined,
      { candidate }
    );

  const candidateId = candidate.id;

  const candidateAlertsDisabled = useCandidateAlertDisabled();

  const editCandidateModal = useDisclosure();
  const deleteCandidateModal = useDisclosure();
  const toggleCandidateDeletionModal = useDisclosure();

  const [updateCandidateOptOut] = useUpdateCandidateOptOutMutation({
    onError: () => {
      errorToast(toast, "There was a problem updating the candidate");
    },
    onCompleted(data) {
      const { candidate } = data.updateCandidateOptOut;
      if (candidate) {
        const description = candidate.recordingDisabled
          ? "Future interviews will not be recorded"
          : "Future interviews will be recorded";
        successToast(toast, description);
      }
    },
  });

  const [getAlert, candidateAlertQuery] = useCandidateAlertLazyQuery({
    notifyOnNetworkStatusChange: true,
  });
  const candidateAlertResults =
    candidateAlertQuery.data?.candidateAlert.results || [];
  const hasMoreCandidateAlerts =
    candidateAlertQuery.data?.candidateAlert.pageInfo.hasNextPage ?? false;
  const candidateAlertLoading = candidateAlertQuery.loading;

  useEffect(() => {
    if (!candidateAlertsDisabled) {
      getAlert({
        variables: {
          candidateId: candidate.id,
          pagination: {
            limit: PAGE_LIMIT,
          },
        },
      });
    }
  }, [candidateAlertsDisabled]);

  /**
   * Executed when the user loads a new page of results
   */
  const onLoadMoreAlerts = (): void => {
    candidateAlertQuery.fetchMore({
      variables: {
        candidateId,
        pagination: {
          limit: Number(candidateAlertResults.length) + PAGE_LIMIT,
        },
      },
    });
    sendCandidateAlertEvent("load_more");
  };

  const [currentAlertSubscription, setCurrentAlertSubscription] = useState<
    CandidateSubscription | undefined
  >();
  const { refetch: refetchAlertsSubscriptions } =
    useCandidateAlertUserSubscriptionsQuery({
      skip: candidateAlertsDisabled,
      onCompleted(data) {
        const subscriptions =
          data.candidateAlertUserSubscriptions.candidateSubscriptions;
        setCurrentAlertSubscription(
          subscriptions.find((sub) => sub.candidateId === candidate.id)
        );
      },
    });

  const [addSubscription] = useAddCandidateAlertUserSubscriptionsMutation({
    onCompleted: (data) => {
      if (data.addCandidateAlertUserSubscriptions) {
        successToast(
          toast,
          "You have subscribed to all future alerts for this candidate"
        );
        refetchAlertsSubscriptions();
      }
    },
  });
  const onSubscribeToAlerts = (): void => {
    addSubscription({
      variables: {
        candidateIds: [candidate.id],
        positionIds: [],
      },
    });
    sendCandidateAlertEvent("subscribe_to_alert");
  };

  const [muteSubscription] = useMuteCandidateAlertUserSubscriptionsMutation({
    onCompleted: (data) => {
      if (data.muteCandidateAlertUserSubscriptions) {
        successToast(
          toast,
          "You have muted all future alerts for this candidate"
        );
        refetchAlertsSubscriptions();
      }
    },
  });
  const onMuteAlerts = (): void => {
    muteSubscription({
      variables: {
        candidateIds: [candidate.id],
        positionIds: [],
      },
    });
    sendCandidateAlertEvent("mute_alert");
  };

  const onPositionChange = (newPositionId: string, replace: boolean): void => {
    navigate(`/candidate/${candidate.id}/position/${newPositionId}`, {
      replace,
    });
  };

  // If the URL ends with /position/none, the user manually selected the "Interviews with no position" option.
  const isNoneCase = location.pathname.endsWith("/none");
  const candidatePositionOptions = useCandidatePositionOptions(candidate);

  useEffect(() => {
    if (isNoneCase) {
      return;
    }
    const validOptions = candidatePositionOptions.filter(
      (opt) => (opt?.count || 0) > 0
    );
    if (!positionId && validOptions.length) {
      onPositionChange(validOptions[0].key, true);
    }
  }, [isNoneCase, candidatePositionOptions, positionId]);

  const canDeleteCandidates = currentUser.userRole?.canDeleteCandidates;
  const smartRecruitersLink = getSmartrecruitersLink(candidate, positionId);
  const atsOptInEnabled = currentUser.organization?.atsOptInEnabled;

  const linkedInLinks = candidate.candidateLinks.filter(
    (link) => link.type === CandidateLinkType.Linkedin
  );

  return (
    <>
      <EditCandidateModal candidate={candidate} {...editCandidateModal} />
      <DeleteCandidateModal candidate={candidate} {...deleteCandidateModal} />
      <ToggleCandidateDeletionModal
        candidate={candidate}
        {...toggleCandidateDeletionModal}
      />
      <CandidateHeaderBase
        candidate={candidate}
        positionId={positionId}
        candidatePositionOptions={candidatePositionOptions}
        onPositionChange={(pid) => onPositionChange(pid, false)}
        alerts={candidateAlertResults}
        alertsLoading={candidateAlertLoading}
        hasMoreAlerts={hasMoreCandidateAlerts}
        alertSubscription={currentAlertSubscription}
        onSubscribeToAlerts={
          !candidateAlertsDisabled ? onSubscribeToAlerts : undefined
        }
        onMuteAlerts={!candidateAlertsDisabled ? onMuteAlerts : undefined}
        onLoadMoreAlerts={onLoadMoreAlerts}
        isExtension={isExtension}
        isSmallScreen={isSmallScreen}
        atsOptInEnabled={atsOptInEnabled}
        isAskEnabled={isAskEnabled}
        hasCompletedInterviews={hasCompletedInterviews}
        onAskClick={onAskClick}
        smartRecruitersLink={smartRecruitersLink}
        linkedInLinks={linkedInLinks}
        onOpenEditCandidate={editCandidateModal.onOpen}
        onOpenDeleteCandidate={
          canDeleteCandidates ? deleteCandidateModal.onOpen : undefined
        }
        onOpenToggleCandidateDeletion={
          canDeleteCandidates ? toggleCandidateDeletionModal.onOpen : undefined
        }
        onUpdateCandidateOptOut={() =>
          updateCandidateOptOut({
            variables: {
              id: candidate.id,
              recordingDisabled: !candidate.recordingDisabled,
            },
          })
        }
      />
    </>
  );
};

export default CandidateHeader;
