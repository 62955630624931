import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

import { LoadingIndicator } from "../../../components";
import AnalyticsReportHero from "./AnalyticsReportHero";
import AnalyticsLineChart from "./line-chart/AnalyticsLineChart";
import { AnalyticsConfig } from "./types";
import useComparisonLineToggle from "./useComparisonLineToggle";
import { ReportData } from "./useReportData";
import { formatAnalyticsDateRange } from "./utils";

type AnalyticsReportSectionChartProps = {
  chart: ReportData["chart"];
  queryConfig: AnalyticsConfig;
};

const AnalyticsReportSectionChart: React.FC<
  AnalyticsReportSectionChartProps
> = ({ chart, queryConfig }) => {
  const {
    loading,
    error,
    chartData,
    columnsConfig,
    averageScore,
    comparisonAverageScore,
    totalCalls,
    numInterviewers,
    bucketInterval,
    bucketSize,
  } = chart;

  let percentDifference;
  let isPositiveDifference;

  if (
    averageScore !== null &&
    comparisonAverageScore !== null &&
    comparisonAverageScore !== 0
  ) {
    percentDifference = averageScore / comparisonAverageScore - 1;
    isPositiveDifference = percentDifference > 0;
  }

  const rangeDisplayValues = formatAnalyticsDateRange(
    queryConfig.dateRange.value
  );

  const { showIQComparisonLine, toggleIQComparisonLine } =
    useComparisonLineToggle();

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return (
      <AnalyticsReportHero>
        Oops! There was an error loading report data.
      </AnalyticsReportHero>
    );
  }

  const data = chartData.filter((d) => typeof d.datum.score === "number");

  if (data.length === 0) {
    return <AnalyticsReportHero image />;
  }

  return (
    <Flex mt="4" align="center">
      <Flex minW="240px" direction="column" mr="4" color="gray.800">
        <Box mb="4">
          {typeof averageScore === "number" && (
            <Text mb="1">
              <Box as="span" fontSize="48px" lineHeight="1" fontWeight="300">
                {Math.floor(averageScore)}
              </Box>
              <Box as="span" fontSize="md" fontWeight="600">
                /100
              </Box>
            </Text>
          )}
          {typeof percentDifference === "number" && (
            <Text fontSize="xs" fontWeight="500">
              {percentDifference === 0 ? (
                "No change"
              ) : (
                <Box
                  as="span"
                  color={isPositiveDifference ? "green.600" : "red.600"}
                >
                  {`${isPositiveDifference ? "↑" : "↓"}${Math.abs(
                    percentDifference * 100
                  ).toFixed(0)}%`}
                </Box>
              )}{" "}
              <Box as="span" color="gray.500">
                {`vs. ${rangeDisplayValues.previous.toLocaleLowerCase()}`}
              </Box>
            </Text>
          )}
        </Box>
        <Text fontSize="sm">{`${totalCalls} interviews, ${numInterviewers} interviewers`}</Text>
      </Flex>
      <Box flex="1">
        <AnalyticsLineChart
          data={chartData}
          dataKey="datum.score"
          compareDataKey="compareDatum.score"
          rangeDisplayValues={rangeDisplayValues}
          columnsConfig={columnsConfig}
          bucketInterval={bucketInterval}
          bucketSize={bucketSize}
          yAxis={{ domain: [0, 100], ticks: [0, 50, 100] }}
          showComparisonLine={showIQComparisonLine}
          toggleComparisonLine={toggleIQComparisonLine}
          // TODO: Hardcoded for now, but eventually use benchmarks V3 table
          benchmarkRange={{
            lowerBound: 76,
            upperBound: 92,
          }}
          drawDot={data.length === 1}
        />
      </Box>
    </Flex>
  );
};

export default AnalyticsReportSectionChart;
