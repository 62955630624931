export type PaginatedResult = {
  currentPageItems: any[];
  totalPages: number;
};

export const paginate = (
  array: any[],
  pageNumber: number,
  pageLimit: number
): PaginatedResult => {
  const totalItems = array.length;
  const totalPages = Math.ceil(totalItems / pageLimit);
  const startIndex = (pageNumber - 1) * pageLimit;
  const endIndex = startIndex + pageLimit;
  const currentPageItems = array.slice(startIndex, endIndex);

  return {
    currentPageItems,
    totalPages,
  };
};
