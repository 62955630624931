import { FormControl, FormErrorMessage, Input } from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

import { Button } from "../../../components";
import { email as emailRegex } from "../../../utils/regex";

interface CandidateEmailFormProps {
  email: string;
  isLoading: boolean;
  onSubmit: (email: string) => void;
}

const CandidateEmailForm: React.FC<CandidateEmailFormProps> = ({
  email,
  isLoading,
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    email: string;
  }>({ defaultValues: { email } });

  const onHandleSubmit = handleSubmit(({ email }): void => {
    onSubmit(email);
  });

  return (
    <form onSubmit={onHandleSubmit}>
      <FormControl id="email" isRequired isInvalid={errors.email !== undefined}>
        <Input
          data-testid="verification-email-input"
          {...register("email", {
            pattern: {
              value: emailRegex,
              message: "Invalid email",
            },
          })}
          placeholder="Enter your email address"
          autoFocus
        />
        {errors.email !== undefined && (
          <FormErrorMessage>{errors.email.message}</FormErrorMessage>
        )}
      </FormControl>
      <Button variant="outline" type="submit" isLoading={isLoading} mt="6">
        Send verification email
      </Button>
    </form>
  );
};

export default CandidateEmailForm;
