import { MenuDivider, MenuItem, MenuItemProps } from "@chakra-ui/react";
import React, { useState } from "react";
import { FiEdit2, FiGlobe } from "react-icons/fi";
import {
  HiOutlineLockClosed,
  HiOutlineLockOpen,
  HiOutlineTrash,
} from "react-icons/hi";

import { canManageTraining } from "../../../../utils/permissions";
import { CallBetaFragment } from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { AddToTrainingProgramIcon } from "../../AddToTrainingProgramButton/AddToTrainingProgramButton";
import AddToTrainingProgramModal from "../../AddToTrainingProgramButton/AddToTrainingProgramModal";
import DeleteCallButton from "../../CallSettingsMenu/DeleteCallButton";
import KeepCallButton from "../../CallSettingsMenu/KeepCallButton";
import UnkeepCallButton from "../../CallSettingsMenu/UnkeepCallButton";

export const menuItemProps = (
  { dangerous } = { dangerous: false }
): MenuItemProps => ({
  fontSize: "sm",
  iconSpacing: "4",
  px: "4",
  py: "2.5",
  color: dangerous ? "red.600" : "blue.600",
  _hover: dangerous
    ? { bg: "red.50", color: "red.500" }
    : { bg: "blue.50", color: "blue.500" },
});

const useCallMenuOptions = (
  call: Pick<
    CallBetaFragment,
    "id" | "canEdit" | "skipRedaction" | "canDelete" | "name"
  >,
  { onEdit, onSetLanguage }: { onEdit?(): void; onSetLanguage?(): void } = {}
): JSX.Element[] | undefined => {
  const currentUser = useCurrentUser();
  const [addToTraining, setAddToTraining] = useState(false);

  return [
    // Add to Training
    canManageTraining(currentUser) ? (
      <MenuItem
        {...menuItemProps()}
        key="add-to-training"
        icon={<AddToTrainingProgramIcon boxSize="5" />}
        onClick={() => setAddToTraining(true)}
      >
        Add to Training Program
        {addToTraining && (
          <AddToTrainingProgramModal
            callId={call.id}
            onClose={() => setAddToTraining(false)}
          />
        )}
      </MenuItem>
    ) : null,

    // Edit details
    call.canEdit ? (
      <MenuItem
        {...menuItemProps()}
        key="edit-position-button"
        icon={<FiEdit2 size="18" />}
        data-testid="edit-position-button"
        onClick={onEdit}
      >
        Update Interview Details
      </MenuItem>
    ) : null,

    // Set language
    call.canEdit ? (
      <MenuItem
        {...menuItemProps()}
        key="set-language"
        icon={<FiGlobe size="18" />}
        data-testid="set-language-button"
        onClick={onSetLanguage}
      >
        Change transcript language
      </MenuItem>
    ) : null,

    // Keep / unkeep call
    call.canDelete ? (
      call.skipRedaction ? (
        <UnkeepCallButton
          key="unkeep-call-button"
          call={call}
          icon={<HiOutlineLockOpen size="20" />}
          {...menuItemProps({ dangerous: true })}
        />
      ) : (
        <KeepCallButton
          key="keep-call-button"
          call={call}
          icon={<HiOutlineLockClosed size="20" />}
          {...menuItemProps()}
        />
      )
    ) : null,

    <MenuDivider />,

    // Delete -- always shown, but disabled if !call.canDelete
    <DeleteCallButton
      key="delete-call-button"
      call={call}
      icon={<HiOutlineTrash size="20" />}
      {...menuItemProps({ dangerous: true })}
    />,
  ].filter((el) => el !== null) as JSX.Element[];
};

export default useCallMenuOptions;
