import { Box, Flex, Text, Textarea } from "@chakra-ui/react";
import React, { FC } from "react";
import { HiOutlineTrash } from "react-icons/hi";

import { IconButton } from "../../../../components";
import { CommonGuideItemProps } from "../types";
import GuideItemLabel from "./GuideItemLabel";

const GuideItemText: FC<CommonGuideItemProps> = ({
  itemId,
  text,
  isEditing,
  canDelete,
  onEnter,
  onDelete,
  onUpdate,
}) => {
  const tooltipContent = (
    <Text>
      <Text as="span" fontWeight="600">
        Text blocks
      </Text>{" "}
      can be used to provide additional context or guidance for interviewers.
    </Text>
  );

  if (isEditing) {
    return (
      <Flex direction="row" alignItems="flex-start">
        <Box flex={1}>
          <GuideItemLabel text="Text block" tooltipContent={tooltipContent} />
          <Textarea
            autoFocus={isEditing}
            value={text}
            onChange={(e) => onUpdate(itemId, { description: e.target.value })}
            whiteSpace="pre-wrap"
            onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
              if (event.key === "Enter" && !event.shiftKey && onEnter) {
                event.preventDefault();
                onEnter();
              }
            }}
          />
        </Box>

        <IconButton
          variant="icon"
          aria-label="Delete Text block"
          color="red.400"
          icon={<HiOutlineTrash size={20} />}
          ml={6}
          disabled={!canDelete}
          onClick={onDelete}
        />
      </Flex>
    );
  }

  if (!text) {
    return (
      <Text color="gray.600" as="i" fontSize="md">
        Add text
      </Text>
    );
  }

  return (
    <Text color="gray.800" whiteSpace="pre-wrap">
      {text}
    </Text>
  );
};

export default GuideItemText;
