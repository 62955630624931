import { Box, BoxProps, ButtonProps, Flex } from "@chakra-ui/react";
import React from "react";
import { IoCopyOutline, IoCut, IoLinkSharp } from "react-icons/io5";

import { Button } from "../../../../components";
import { formatDuration } from "../../../../utils/datetime";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { CallNoteFragment, CallQuestionFragment } from "../../../graphql";
import { useCopyQuestionNotes } from "../../../hooks/useCopyNotes";
import { ClipRange } from "../../Interview/Clip/types";
import { clipDefaultEndTime } from "../../Interview/Clip/utils";
import useCopyLink from "../useCopyLink";

type CallQuestionNotesHeaderMenuProps = BoxProps & {
  callId: string;
  question: CallQuestionFragment;
  timestampNote: Pick<
    CallNoteFragment,
    "id" | "time" | "highlightStartTime" | "highlightEndTime"
  >;
  callDuration?: number | null;
  setClipRange?(clipRange: ClipRange | null, adjustByWordTime: boolean): void;
};

const CallQuestionNotesHeaderMenu: React.FC<
  CallQuestionNotesHeaderMenuProps
> = ({
  callId,
  question,
  timestampNote,
  callDuration,
  setClipRange,
  ...styles
}) => {
  const { handleCopyLink } = useCopyLink({ callId, time: timestampNote.time });

  const handleCopyNotes = useCopyQuestionNotes(question);
  const sendGAEvent = useSendGAEvent();

  const hoverButtonStyles: ButtonProps = {
    variant: "outline",
    size: "sm",
    height: "8",
    fontSize: "12px",
    colorScheme: "gray",
    borderRadius: "md",
    mr: "1",
    px: "2",
  };

  return (
    <Box position="relative" {...styles}>
      <Flex alignItems="center" position="absolute" right="0" bottom="-4px">
        <Button
          {...hoverButtonStyles}
          leftIcon={<IoLinkSharp size="14" />}
          aria-label="Copy Link"
          onClick={handleCopyLink}
        >
          Link to {formatDuration(timestampNote.time)}
        </Button>
        {setClipRange && (
          <Button
            {...hoverButtonStyles}
            leftIcon={<IoCut size="14" />}
            aria-label="Create clip"
            onClick={() => {
              if (setClipRange && timestampNote) {
                if (
                  timestampNote.highlightStartTime &&
                  timestampNote.highlightEndTime
                ) {
                  setClipRange(
                    {
                      start: timestampNote.highlightStartTime,
                      end: timestampNote.highlightEndTime,
                    },
                    false
                  );
                } else {
                  setClipRange(
                    {
                      start:
                        timestampNote.time > 15
                          ? timestampNote.time - 15
                          : timestampNote.time,
                      end: clipDefaultEndTime(callDuration, timestampNote.time),
                    },
                    true
                  );
                }
                sendGAEvent(
                  "clip_creation_start",
                  "call_review",
                  "clip_from_note"
                );
              }
            }}
          >
            Create clip
          </Button>
        )}
        <Button
          {...hoverButtonStyles}
          leftIcon={<IoCopyOutline size="14" />}
          aria-label="Copy Notes"
          onClick={handleCopyNotes}
        >
          Copy notes
        </Button>
      </Flex>
    </Box>
  );
};

export default CallQuestionNotesHeaderMenu;
