import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { HiOutlinePlus, HiOutlineTrash } from "react-icons/hi";
import { IoCopyOutline } from "react-icons/io5";

import { Button, IconButton, Link } from "../../../../../../components";
import DragHandleDots from "../../../../../../components/Icons/DragHandleDots";
import { copy } from "../../../../../../utils/clipboard";
import EditCustomTemplateSectionForm from "./EditCustomTemplateSectionForm";
import { CustomTemplateInput, CustomTemplateSectionInput } from "./types";

type EditCustomTemplateModalProps = {
  template: CustomTemplateInput | null;
  onClose: () => void;
  onDelete: (id: string) => void;
  onSave: (input: CustomTemplateInput) => void;
};

const EditCustomTemplateModal: React.FC<EditCustomTemplateModalProps> = ({
  template,
  onClose,
  onDelete,
  onSave,
}) => {
  const [templateName, setTemplateName] = useState<string>(
    template?.name || ""
  );
  const [isCreatorDefault, setIsCreatorDefault] = useState<boolean>(
    template?.isCreatorDefault || false
  );
  const [sections, setSections] = useState<CustomTemplateSectionInput[]>(
    template?.sections || []
  );

  const handleMove = useCallback(
    (fromIndex: number, toIdx: number): void => {
      if (!sections) {
        return;
      }
      const item = sections[fromIndex];
      if (!item) return;
      if (toIdx >= sections.length) return;

      let toIndex = toIdx;
      const movingDown = toIndex >= fromIndex;
      if (movingDown) toIndex += 1;

      const newList = [] as Array<CustomTemplateSectionInput>;
      for (let i = 0; i < sections.length; i += 1) {
        // eslint-disable-next-line
        if (i === fromIndex) continue;
        if (i === toIndex) {
          newList.push(sections[fromIndex]);
        }
        newList.push(sections[i]);
      }
      if (toIndex === sections.length) {
        newList.push(sections[fromIndex]);
      }
      setSections(newList);
    },
    [sections]
  );

  const onAddSection = useCallback(() => {
    const id = Math.random().toString(36).substr(2, 9);
    setSections((prev) => [...prev, { id, title: "", prompt: [""] }]);
  }, []);

  const onDeleteSection = useCallback((id: string) => {
    setSections((prev) => prev.filter((section) => section.id !== id));
  }, []);

  const updateSection = (
    index: number,
    title: string,
    prompt: string
  ): void => {
    const newSections = [...sections];
    const promptArr = [prompt];
    newSections[index] = {
      ...newSections[index],
      title,
      prompt: promptArr,
    };
    setSections(newSections);
  };

  const saveTemplate = (): void => {
    onSave({
      id: template?.id,
      name: templateName,
      isCreatorDefault,
      sections,
    });
  };

  const deleteTemplate = (id: string): void => {
    onDelete(id);
    onClose();
  };

  const copyTemplateText = (): void => {
    let templateText = "";
    sections.forEach((section) => {
      templateText += `${section.title}\n\n`;
      section.prompt.forEach((p) => {
        templateText += `${p}\n`;
      });
      templateText += `\n`;
    });
    copy(templateText);
  };

  const confirmButtonText = template ? "Save changes" : "Create template";
  return (
    <Modal isOpen onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent minWidth="580px">
        <ModalHeader
          color="gray.900"
          fontWeight="600"
          fontSize="2xl"
          lineHeight="8"
          pl="6"
          pr="72px"
          pt="6"
          pb="4"
        >
          {template ? "Edit" : "Create"} a custom template
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px="6" pt="0" pb="5">
          <Text fontWeight="400" fontSize="md" color="gray.700">
            Personalize the way your AI Notes are structured.{" "}
          </Text>
          <Link
            fontSize="sm"
            target="_blank"
            href="https://help.brighthire.ai/en/articles/9001590-custom-templates-for-ai-notes"
          >
            Learn best practices here
          </Link>
          <FormControl my="4">
            <FormLabel>Template name</FormLabel>
            <Input
              placeholder="E.g. Screening Call"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              autoFocus
            />
          </FormControl>
          <HStack>
            <Box>
              <Text fontWeight="600" fontSize="md" color="gray.800" mb="1">
                Sections
              </Text>
              <Text fontWeight="400" fontSize="sm" color="gray.700">
                Specify which sections you want to include in your AI Notes.
                Describe what you’d like the AI to summarize in this section.
              </Text>
            </Box>
            <Tooltip label="Copy template text">
              <IconButton
                data-tour-id="ai-note-copy-template-text"
                icon={<IoCopyOutline size={20} />}
                aria-label="Copy template text"
                size="sm"
                p="2"
                variant="ghost"
                flexShrink={0}
                onClick={() => {
                  copyTemplateText();
                }}
              />
            </Tooltip>
          </HStack>
          <Box>
            <DragDropContext
              onDragEnd={(result) => {
                if (!result.destination) {
                  return;
                }
                handleMove(result.source.index, result.destination.index);
              }}
            >
              <Droppable droppableId="sectionDroppable">
                {(provided) => (
                  // eslint-disable-next-line @typescript-eslint/unbound-method
                  <Box {...provided.droppableProps} ref={provided.innerRef}>
                    {sections?.map((child, index) => (
                      <Draggable
                        key={child.id}
                        draggableId={child.id}
                        index={index}
                        isDragDisabled={false}
                      >
                        {(provided) => (
                          <Flex
                            key={child.id}
                            alignItems="flex-start"
                            backgroundColor="gray.50"
                            pt={2}
                            pb={5}
                            pr={4}
                            borderRadius="8px"
                            mt={5}
                            // eslint-disable-next-line @typescript-eslint/unbound-method
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <Flex
                              {...provided.dragHandleProps}
                              height={8}
                              width={8}
                              mr={1}
                              ml={2}
                              alignItems="center"
                              justifyContent="center"
                              data-testid={`drag-handle-${index}`}
                            >
                              <DragHandleDots width={5} height={5} />
                            </Flex>
                            <EditCustomTemplateSectionForm
                              title={child.title}
                              prompt={child.prompt[0] || ""}
                              onUpdate={(title, prompt) => {
                                updateSection(index, title, prompt);
                              }}
                            />
                            <Flex
                              height={8}
                              width={8}
                              mr={2}
                              alignItems="center"
                              justifyContent="center"
                              data-testid={`drag-handle-${index}`}
                            >
                              <IconButton
                                variant="iconDanger"
                                aria-label="Delete Section"
                                backgroundColor="transparent"
                                icon={<HiOutlineTrash size={20} />}
                                ml={6}
                                onClick={() => onDeleteSection(child.id)}
                              />
                            </Flex>
                          </Flex>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
            <Button
              data-testid="Add Section"
              size="sm"
              mt={5}
              justifyContent="flex-start"
              variant="ghost"
              fontSize="sm"
              fontWeight="500"
              leftIcon={<HiOutlinePlus size="18px" />}
              onClick={onAddSection}
            >
              Add section
            </Button>
          </Box>
          <Box mt="3" py="3.5" px="3">
            <Checkbox
              isChecked={isCreatorDefault}
              onChange={(e) => setIsCreatorDefault(e.target.checked)}
            >
              <Text pl="2" fontSize="sm" color="gray.800">
                Set as default
              </Text>
            </Checkbox>
          </Box>
        </ModalBody>

        <ModalFooter
          borderTop="1px"
          borderTopColor="gray.100"
          justifyContent="space-between"
          px={6}
        >
          <Box hidden={!template}>
            <Popover trigger="hover" placement="top-start">
              <PopoverTrigger>
                <Button
                  variant="hoverDanger"
                  color="red.600"
                  borderRadius="8px"
                  leftIcon={<HiOutlineTrash />}
                  fontWeight="500"
                >
                  Delete template
                </Button>
              </PopoverTrigger>
              <PopoverContent px="4" pb="4">
                <PopoverArrow />
                <PopoverHeader
                  textAlign="center"
                  color="gray.800"
                  fontWeight="600"
                  px="0"
                  py="3"
                  fontSize="sm"
                >
                  Delete template?
                </PopoverHeader>
                <PopoverCloseButton color="gray.400" />
                <PopoverBody p="0">
                  <Text color="gray.700" mb="4" mt="3" fontSize="sm">
                    This will permanently remove all its sections and settings.
                    This action cannot be undone.
                  </Text>
                  <Button
                    variant="danger"
                    width="100%"
                    size="sm"
                    fontWeight="500"
                    onClick={() => deleteTemplate(template?.id || "")}
                  >
                    Delete
                  </Button>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>
          <Flex
            alignItems="center"
            width={!template ? "100%" : undefined}
            justifyContent={!template ? "space-between" : undefined}
          >
            <Button
              variant={template ? "outline" : "ghost"}
              mr="3"
              borderRadius="8px"
              fontWeight="500"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button fontWeight="500" borderRadius="8px" onClick={saveTemplate}>
              {confirmButtonText}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditCustomTemplateModal;
