import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

const StepperLine: React.FC<{ width: number }> = ({ width }): JSX.Element => (
  <svg
    width={width}
    height="2"
    viewBox={`0 0 ${width} 2`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d={`M0 1H${width}`} stroke="#D3D8De" strokeWidth="1.5" />
  </svg>
);

const ProgressStepper: React.FC<{
  steps: string[];
  activeStepIndex: number;
  stepBgColor: string;
}> = ({ steps, activeStepIndex, stepBgColor }) => {
  const isActive = (index: number): boolean => index === activeStepIndex;
  const isComplete = (index: number): boolean => index < activeStepIndex;
  return (
    <Flex flexDir="row" alignItems="center" position="relative">
      {steps.map((step, index) => (
        <React.Fragment key={step}>
          <Flex flexDir="column" alignItems="center" width="160px" zIndex="10">
            <Text
              color={
                isActive(index)
                  ? "white"
                  : isComplete(index)
                  ? "gray.600"
                  : "gray.400"
              }
              fontSize="xs"
              fontWeight="500"
              width="32px"
              height="32px"
              borderRadius="32px"
              borderWidth="1.5px"
              borderColor={
                isActive(index) || isComplete(index) ? "gray.500" : "gray.300"
              }
              bg={isActive(index) ? "gray.500" : stepBgColor}
              textAlign="center"
              lineHeight="29px"
              mb="1.5"
            >
              {index + 1}
            </Text>
            <Text
              color={
                isActive(index) || isComplete(index) ? "gray.600" : "gray.400"
              }
              fontSize="xs"
              fontWeight={isActive(index) ? "600" : "400"}
            >
              {step}
            </Text>
          </Flex>
        </React.Fragment>
      ))}
      <Box position="absolute" left="80px" top="16px" zIndex="5">
        <StepperLine width={(steps.length - 1) * 160} />
      </Box>
    </Flex>
  );
};

export default ProgressStepper;
