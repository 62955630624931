import {
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CreatableSelect from "react-select/creatable";

import { Alert, Button, Link, TooltipIcon } from "../../../components";
import useSelectTheme from "../../../hooks/useSelectTheme";
import { Client as ClientType, useOrgClientsQuery } from "../../graphql";
import useAddClient from "../../graphql/hooks/useAddClient";
import EditClientModal from "./EditClientModal";

type Client = Pick<ClientType, "id" | "name">;

export interface FormData {
  clientId: string;
  title: string;
}

interface PositionFormProps {
  clientId?: string;
  title?: string;
  isLoading: boolean;
  onSubmit: (formData: FormData) => void;
  onCancel: () => void;
}

const PositionForm: React.FC<PositionFormProps> = ({
  clientId = "",
  title = "",
  isLoading,
  onSubmit,
  onCancel,
}) => {
  const [theme, styles] = useSelectTheme();
  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      clientId,
      title,
    },
  });
  const [client, setClient] = useState<Client>();
  const [addClient, { loading: addClientLoading, error: addClientError }] =
    useAddClient({
      onCompleted: ({ addClient }) => {
        const client = addClient?.client;
        if (client) {
          setValue("clientId", client.id);
          setClient(client);
        }
      },
    });
  const {
    loading: clientsLoading,
    error: clientsError,
    data: clientsData,
  } = useOrgClientsQuery({
    onCompleted: ({ currentUser }) => {
      const client = currentUser?.organization.clients.find(
        (client) => client.id === clientId
      );
      setValue("clientId", client?.id ?? "");
      setClient(client);
    },
  });
  const clients = clientsData?.currentUser?.organization.clients;
  const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen: false });
  const selectValue = client
    ? { label: client.name, value: client.id }
    : undefined;

  const submitPosition = (event: React.FormEvent<HTMLFormElement>): any => {
    event.stopPropagation();
    return handleSubmit((formData): void => onSubmit(formData))(event);
  };
  return (
    <form onSubmit={submitPosition}>
      {client && (
        <EditClientModal
          client={client}
          isOpen={isOpen}
          onClose={onClose}
          onSave={(client) => {
            setValue("clientId", client.id);
            setClient(client);
          }}
        />
      )}
      {clientsError && (
        <Alert mb={6} status="error" description={clientsError.message} />
      )}
      {addClientError && (
        <Alert mb={6} status="error" description={addClientError.message} />
      )}
      <VStack spacing="8">
        <FormControl
          id="clientId"
          isRequired
          isInvalid={errors.clientId !== undefined}
        >
          <Flex justifyContent="space-between">
            <FormLabel display="flex" alignItems="center">
              Position Group
              <TooltipIcon
                ml="1"
                label="A Position Group can be a department, team, client, etc."
              />
            </FormLabel>
            {client && <Link onClick={onOpen}>Edit position group</Link>}
          </Flex>
          <Controller
            name="clientId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CreatableSelect
                placeholder="Add or select"
                aria-label="new-position-select"
                {...field}
                id="clientId"
                value={selectValue}
                theme={theme}
                styles={styles}
                onCreateOption={(name) => {
                  addClient({ variables: { name } });
                }}
                options={clients?.map((client) => ({
                  label: client.name,
                  value: client.id,
                }))}
                onChange={(selected) => {
                  const typedSelection = selected as { value?: string };
                  setValue("clientId", typedSelection.value ?? "");
                  setClient(
                    clients?.find(
                      (client) => client.id === typedSelection.value
                    )
                  );
                }}
                isLoading={clientsLoading || addClientLoading}
              />
            )}
          />
          {errors.clientId !== undefined && (
            <FormErrorMessage>Position Group is required</FormErrorMessage>
          )}
        </FormControl>
        <FormControl
          id="title"
          isRequired
          isInvalid={errors.title !== undefined}
        >
          <FormLabel>Position Title</FormLabel>
          <Input {...register("title")} defaultValue={title} maxLength={100} />
          {errors.title !== undefined && (
            <FormErrorMessage>Title is required</FormErrorMessage>
          )}
        </FormControl>
        <ButtonGroup spacing={4} alignSelf="end">
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" isLoading={isLoading}>
            Save &amp; continue
          </Button>
        </ButtonGroup>
      </VStack>
    </form>
  );
};

export default PositionForm;
