import { useHref } from "react-router-dom";

/**
 * Returns the basename of the current react router, starting (but
 * not ending) with a `/`.
 *
 * E.g.:
 *  - `""` for the main app
 *  - `"/interview-assistant"` for the IA
 *  - `"/extension"` for the extension
 */
export const useRouterBasename = (): string => {
  return useHref("/").slice(0, -1);
};
