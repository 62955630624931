import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import { Link } from "../../../../components";
import { formatDate } from "../../../../utils/datetime";
import { Candidate, Position, User } from "../../../graphql";
import { useIsExtension } from "../../../hooks/useAppEnvironmentContext";

export type MobileDetailsHeaderProps = {
  candidate?: Pick<Candidate, "id" | "fullName"> | null;
  position?: Pick<Position, "id" | "displayTitle"> | null;
  title?: string | null;
  titleSlot?: JSX.Element | null;
  date: string;
  interviewers?: Pick<User, "id" | "fullName">[];
};

const MobileDetailsHeader: React.FC<MobileDetailsHeaderProps> = ({
  candidate,
  position,
  title,
  titleSlot,
  date,
  interviewers = [],
}) => {
  const navigate = useNavigate();
  const isExtension = useIsExtension();

  return (
    <Accordion pt="4" pl="4" pb="2" pr="3" allowToggle>
      <AccordionItem
        border="none"
        role="heading"
        color="gray.900"
        fontSize="medium"
        fontWeight="medium"
      >
        {({ isExpanded }) => (
          <>
            {/* Toggle part of accordion, always shown */}
            <AccordionButton
              as="div"
              p="0"
              bg="transparent !important"
              display="flex"
            >
              <Box mr="3" data-testid="candidate-name-heading">
                {isExpanded && candidate?.id ? (
                  <Link
                    color="blue.600"
                    fontWeight="inherit"
                    href={`/candidate/${candidate.id}`}
                    target="_blank"
                    onClick={(e) => {
                      if (isExtension) {
                        // Subtle, but we want `navigate` instead of the native behavior
                        // so that the browser extension's router works as expected
                        e.preventDefault();
                        navigate(`/candidate/${candidate.id}`);
                      }
                    }}
                  >
                    {candidate.fullName || "Candidate"}
                  </Link>
                ) : (
                  candidate?.fullName ?? "Unknown Candidate"
                )}
              </Box>

              {titleSlot}

              <AccordionIcon ml="auto" boxSize={6} />
            </AccordionButton>

            {/* Body of accordion, shown when expanded */}
            <AccordionPanel px="0" pb="1" pt="4" fontSize="sm">
              {/* Position */}
              {position?.id ? (
                <Link
                  display="inline"
                  color="blue.600"
                  fontWeight="inherit"
                  href={`/position/${position.id}`}
                  target="_blank"
                >
                  {position.displayTitle}
                </Link>
              ) : (
                <Text color="gray.400" display="inline">
                  No Position
                </Text>
              )}
              &nbsp;•&nbsp;
              {title ?? "No Title"}
              &nbsp;•&nbsp;
              {formatDate(date, {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
              {interviewers?.length > 0 && (
                <>
                  &nbsp;•&nbsp;
                  {interviewers
                    .map(({ fullName }) => fullName)
                    .filter((name) => !!name)
                    .join(", ")}
                </>
              )}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default MobileDetailsHeader;
