import { Box, BoxProps } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import Select from "react-select";

import { useSendGAEvent } from "../../../utils/googleAnalytics";
import {
  AnalyticsDimension,
  FilterValue,
  useAnalyticsFilterValuesQuery,
} from "../../graphql";
import { CommonQueryVariables, MultiSelect, SelectOption } from "./types";
import { useAnalyticsSelectTheme } from "./useAnalyticsSelectTheme";

type AnalyticsFilterProps = {
  placeholder: string;
  dimension: AnalyticsDimension;
  secondaryDimension: AnalyticsDimension;
  queryVariables: CommonQueryVariables;
  multiSelect: MultiSelect;
  setLabels?: boolean;
  filterHeights?: object;
  setFilterHeights?: React.Dispatch<
    React.SetStateAction<{
      [key: string]: number;
    }>
  >;
} & BoxProps;

const AnalyticsFilter: React.FC<AnalyticsFilterProps> = ({
  dimension,
  secondaryDimension,
  placeholder,
  queryVariables,
  multiSelect,
  filterHeights,
  setFilterHeights,
  ...rest
}) => {
  const [selectTheme, selectStyles] = useAnalyticsSelectTheme();
  const filterRef = useRef();
  const sendGAEvent = useSendGAEvent();

  useEffect(() => {
    if (filterRef.current) {
      const refFilterHeight: number =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        filterRef.current?.controlRef?.getBoundingClientRect().height;

      if (setFilterHeights && filterHeights) {
        setFilterHeights((state) => {
          const newFilterHeight = { ...state };
          newFilterHeight[dimension] = refFilterHeight + 10;
          return newFilterHeight;
        });
      }
    }
  }, [JSON.stringify(multiSelect.values)]);

  const query = useAnalyticsFilterValuesQuery({
    variables: {
      ...queryVariables,
      primaryDimension: dimension,
      secondaryDimension,
    },
  });

  // Interviewer labels are needed up a layer for drillDowns.
  // A larger refactor should probably move filter state up a layer,
  // perhaps into useAnalyticsData in a map of filter => apollo query result
  useEffect(() => {
    if (multiSelect.setLabels) {
      // Clear when interviewer selection changes
      const filterValues = query.data?.filterValues.values;
      if (!filterValues) {
        multiSelect.setLabels(undefined);
      } else {
        // Set when data is loaded
        const selectedOptionIdSet = new Set(multiSelect.values);
        const selectedFilterValues = filterValues.filter((fv) =>
          selectedOptionIdSet.has(fv.value)
        );
        multiSelect.setLabels(selectedFilterValues.map((fv) => fv.label || ""));
      }
    }
  }, [query.data]);

  // While data is loading, use the prior data so the options don't vanish from the UI
  const crossFilteredOptions = convertFilterValuesToSelectOptions(
    query.loading
      ? query.previousData?.filterValues.values
      : query.data?.filterValues.values
  );

  // Filter the crossFilteredOptions down to the selectedOptions
  const selectedOptionIdSet = new Set(multiSelect.values);
  const selectedOptions = crossFilteredOptions.filter((o) =>
    selectedOptionIdSet.has(decodeURIComponent(o.value))
  );

  return (
    <Box {...rest} data-testid={`analytics--filter-${dimension.toLowerCase()}`}>
      <Select
        theme={selectTheme}
        styles={selectStyles}
        isClearable
        isSearchable
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={filterRef}
        isMulti
        placeholder={placeholder}
        isLoading={query.loading}
        value={selectedOptions}
        options={crossFilteredOptions}
        onChange={(selectedOptions) => {
          multiSelect.setValues([...selectedOptions.map((o) => o.value)]);
          sendGAEvent(
            "filter_selection",
            "analytics",
            dimension.toLocaleLowerCase(),
            selectedOptions.map((opt) => opt.value).join(", ")
          );
        }}
      />
    </Box>
  );
};

const convertFilterValuesToSelectOptions = (
  filterValues?: FilterValue[]
): SelectOption<string>[] => {
  if (!filterValues) {
    return [];
  }
  return filterValues.map((v) => ({
    value: encodeURIComponent(v.value),
    rawLabel: `${v.label || ""}`,
    label: `${v.label || ""} (${v.count ?? ""})`,
  }));
};

export default AnalyticsFilter;
