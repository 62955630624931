import { Box, Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { HiArrowDownTray } from "react-icons/hi2";
import { Navigate } from "react-router-dom";

import { IconButton, LoadingIndicator } from "../../../../components";
import { formatISODate } from "../../../../utils/datetime";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { snakeCaseToSentenceCase } from "../../../../utils/string";
import {
  useAnalyticsCandidateMotivationCountsQuery,
  useAnalyticsCandidateMotivationExamplesCsvLazyQuery,
  useAnalyticsCandidateMotivationExamplesQuery,
  UserRoleName,
} from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import AnalyticsDateSelect from "../AnalyticsDateSelect";
import AnalyticsFilters from "../AnalyticsFilters";
import AnalyticsInfoAlert from "../AnalyticsInfoAlert";
import AnalyticsInfoModal from "../AnalyticsInfoModal";
import { ReportContainer, ResultHeader } from "../AnalyticsReport";
import AnalyticsReportTableSkeleton from "../AnalyticsReportTableSkeleton";
import AnalyticsBarChart, {
  AnalyticsBarChartData,
} from "../bar-chart/AnalyticsBarChart";
import LabeledChartSelect from "../LabeledChartSelect";
import ToggleFiltersButton from "../ToggleFiltersButton";
import ToggleFiltersPanel from "../ToggleFiltersPanel";
import { CommonQueryVariables } from "../types";
import AnalyticsCandidateMotivationsTable from "./AnalyticsCandidateMotivationsTable";
import useCandidateMotivationsConfig, {
  CandidateMotivationOption,
} from "./useCandidateMotivationsConfig";

const AnalyticsCandidateMotivations: React.FC = () => {
  const sendGAEvent = useSendGAEvent();
  const [showFilters, setShowFilters] = useState(true);
  const [filterHeights, setFilterHeights] = useState<{ [key: string]: number }>(
    {
      defaultHeight: 50,
    }
  );
  const queryConfig = useCandidateMotivationsConfig();
  const currentUser = useCurrentUser();
  const isAdmin = currentUser.userRole?.name === UserRoleName.SiteAdmin;

  const queryVariables: CommonQueryVariables = {
    dateRangeStart: formatISODate(queryConfig.dateRange.value.start),
    dateRangeEnd: formatISODate(queryConfig.dateRange.value.end),
    ...queryConfig.filters,
  };

  const { data: chartResult, loading: chartLoading } =
    useAnalyticsCandidateMotivationCountsQuery({
      variables: queryVariables,
    });
  const chartData = chartResult?.candidateMotivationCounts?.counts;
  const callCount = chartResult?.candidateMotivationCounts?.totalCalls || 0;
  const candidateCount =
    chartResult?.candidateMotivationCounts?.totalCandidates || 0;
  const sampleSize = `Sample: ${callCount} interviews with ${candidateCount} candidates.`;

  const chartCounts = useMemo(() => {
    if (!chartData) return [];
    const mappedTopics = chartData
      .map((o) => {
        const { topic, count } = o;
        if (typeof count !== "number") return null;
        return {
          key: topic,
          label: snakeCaseToSentenceCase(topic),
          value: Number(count.toFixed(1)),
        };
      })
      .filter(Boolean) as AnalyticsBarChartData;
    return mappedTopics;
  }, [chartData]);

  const { loading: tableLoading, data: tableResult } =
    useAnalyticsCandidateMotivationExamplesQuery({
      variables: {
        ...queryVariables,
        ...(queryConfig.motivationTopic.value !== "ALL" && {
          motivationTopic: queryConfig.motivationTopic.value,
        }),
      },
      skip: !queryConfig.motivationTopic.value,
    });
  const tableData = tableResult?.candidateMotivationExamples?.data || [];
  const tableReady =
    !tableLoading && !!tableData.length && queryConfig.motivationTopic.value;

  useEffect(() => {
    const highestCountKey = chartCounts[0]?.key;
    // Only select a motivation topic if one is not already selected
    if (!queryConfig.motivationTopic.value) {
      queryConfig.motivationTopic.setValue(
        highestCountKey as CandidateMotivationOption
      );
    }
  }, [chartCounts]);

  const motivationSelectOptions = useMemo(() => {
    const options = chartCounts
      .filter((count) => count.value > 0)
      .map((count) => ({
        value: count.key,
        label: count.label,
      }));
    options.unshift({ value: "ALL", label: "All motivations" });
    return options;
  }, [chartCounts]);

  const [fetchCsv, { loading: csvLoading }] =
    useAnalyticsCandidateMotivationExamplesCsvLazyQuery({
      fetchPolicy: "network-only",
      onCompleted(data) {
        if (data?.candidateMotivationExamplesCsv?.url) {
          const url = data?.candidateMotivationExamplesCsv?.url;
          const downloadLink = document.createElement("a");
          downloadLink.style.display = "none";
          downloadLink.href = url;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      },
    });

  if (!isAdmin) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <Flex
        flexDir="row"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Flex flexDir="row" alignItems="baseline">
          <Text fontSize="24px" fontWeight="700" color="gray.700" pr="2">
            Candidate Motivations
          </Text>
          <AnalyticsInfoModal dangerousHTMLString="In the Candidate Motivations report, interview transcripts are analyzed to identify what candidates explicitly express interest in about your company and roles. The bar chart shows the percentage of candidates who expressed interest in each motivation category during their interviews, while the examples section shows specific quotes from candidates that demonstrate each motivation category." />
        </Flex>
        <Flex ml="auto">
          <ToggleFiltersButton
            open={showFilters}
            toggleFilters={() => {
              sendGAEvent(
                `filters_${!showFilters ? "open" : "closed"}`,
                "analytics"
              );
              setShowFilters((state) => !state);
            }}
          />
          <Flex minW="148px">
            <AnalyticsDateSelect
              state={queryConfig.dateRange.value}
              onSelect={queryConfig.dateRange.setValue}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex my="4">
        <ToggleFiltersPanel
          showFilters={showFilters}
          filterHeights={filterHeights}
          flex="1"
          mt="4"
          mb="0"
        >
          <AnalyticsFilters
            queryConfig={queryConfig}
            queryVariables={queryVariables}
            filterHeights={filterHeights}
            setFilterHeights={setFilterHeights}
          />
        </ToggleFiltersPanel>
      </Flex>
      {queryConfig.dateRange.value.start < new Date("2024-05-01") && (
        <AnalyticsInfoAlert status="info" mt="-3" mb="4">
          Data is unavailable for candidate motivations before May 1st, 2024.
        </AnalyticsInfoAlert>
      )}
      <ReportContainer>
        <ResultHeader
          headerText="Motivation Breakdown"
          captionText={
            chartLoading
              ? undefined
              : `Motivations are calculated as the percentage of candidates who discussed them. ${sampleSize}`
          }
        >
          <Text
            color="gray.600"
            fontWeight="400"
            fontSize="sm"
            whiteSpace="nowrap"
          >
            {queryConfig.dateRange.displayValue}
          </Text>
        </ResultHeader>
        <Box>
          {chartLoading && <LoadingIndicator />}
          {!chartLoading && !!chartCounts.length && (
            <AnalyticsBarChart data={chartCounts} />
          )}
        </Box>
      </ReportContainer>
      <ReportContainer mt="6">
        <Flex flexDir="row" justifyContent="space-between" alignItems="start">
          <Flex flexDir="row" alignItems="center" mr="4">
            <Text fontSize="lg" fontWeight="600" color="gray.800" mr="4">
              Motivation Examples
            </Text>
            <LabeledChartSelect
              data-testid="analytics-topic-trends--speaker-menu"
              flexDir="row"
              alignItems="center"
              label=""
              singleSelect={{
                ...queryConfig.motivationTopic,
                options: motivationSelectOptions,
                disabled: chartLoading,
              }}
              customSelectStyles={{
                container: (provided: any) => ({ ...provided, width: "250px" }),
              }}
            />
          </Flex>
          <Tooltip label="Download full CSV results">
            <IconButton
              aria-label="Download full CSV results"
              icon={<Icon as={HiArrowDownTray} boxSize="5" />}
              isLoading={csvLoading || tableLoading}
              variant="ghost"
              onClick={() => {
                sendGAEvent("download_topic_trend_results", "analytics");
                fetchCsv({
                  variables: {
                    ...queryVariables,
                    ...(queryConfig.motivationTopic.value !== "ALL" && {
                      motivationTopic: queryConfig.motivationTopic.value,
                    }),
                  },
                });
              }}
              onFocus={(e) => e.preventDefault()}
              hidden={!tableLoading && !tableData.length}
            />
          </Tooltip>
        </Flex>
        <Box mt="3" width="100%">
          {(chartLoading || tableLoading) && <AnalyticsReportTableSkeleton />}
          {tableReady && (
            <AnalyticsCandidateMotivationsTable
              tableData={tableData}
              currentMotivation={queryConfig.motivationTopic.value}
            />
          )}
        </Box>
      </ReportContainer>
    </>
  );
};

export default AnalyticsCandidateMotivations;
