import {
  Table,
  TableCellProps,
  TableContainer,
  Tbody,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { format, parseISO } from "date-fns";
import React from "react";

import { HighlightText, RouterLink } from "../../../../components";
import { snakeCaseToSentenceCase } from "../../../../utils/string";
import { CandidateMotivationExample } from "../../../graphql";
import { TdWhite, ThGray } from "../AnalyticsReportTable";
import { CandidateMotivationOption } from "./useCandidateMotivationsConfig";

const TABLE_CELL_PROPS: TableCellProps = {
  px: 4,
  minW: "100px",
  textAlign: "left",
};

type AnalyticsCandidateMotivationsTableProps = {
  tableData: CandidateMotivationExample[];
  currentMotivation: CandidateMotivationOption;
};

const AnalyticsCandidateMotivationsTable: React.FC<
  AnalyticsCandidateMotivationsTableProps
> = ({ tableData, currentMotivation }) => {
  const formatMotivations = (motivations: string): string => {
    const withoutBrackets = motivations.slice(1, -1);
    const list = withoutBrackets.split(",");
    return list
      .map((motivation) => snakeCaseToSentenceCase(motivation))
      .join(", ");
  };
  return (
    <TableContainer>
      <Table variant="simple" whiteSpace="normal">
        <Thead>
          <Tr>
            <ThWrapper width="12.5%" paddingLeft="4">
              Timestamp
            </ThWrapper>
            <ThWrapper width="12.5%">Candidate</ThWrapper>
            <ThWrapper width="12.5%">Position</ThWrapper>
            <ThWrapper width="35%">Summary</ThWrapper>
            <ThWrapper width="12.5%" paddingLeft="4">
              Link to example
            </ThWrapper>
            <ThWrapper width="15%">Motivations</ThWrapper>
          </Tr>
        </Thead>
        <Tbody>
          {tableData.map((row) => (
            <Tr key={row.exampleId} _last={{ borderColor: "transparent" }}>
              <TdWrapper paddingLeft="4">
                <Text color="gray.800" fontWeight="500">
                  {row.startTime &&
                    format(parseISO(row.startTime), "MMM dd, yyyy")}
                </Text>
              </TdWrapper>
              <TdWrapper>
                <RouterLink
                  to={`/candidate/${row.candidateId}`}
                  color="blue.600"
                  fontWeight="500"
                  fontSize="sm"
                  target="_blank"
                >
                  {row.candidateFullName || "Candidate"}
                </RouterLink>
              </TdWrapper>
              <TdWrapper>
                {row.positionId && (
                  <RouterLink
                    to={`/position/${row.positionId}`}
                    color="blue.600"
                    fontWeight="500"
                    fontSize="sm"
                    target="_blank"
                  >
                    {row.positionName || ""}
                  </RouterLink>
                )}
                {!row.positionId && ""}
              </TdWrapper>
              <TdWrapper>
                <Text>{row.summary}</Text>
              </TdWrapper>
              <TdWrapper>
                <RouterLink
                  to={`/interview/${row.callId}?t=${row.exampleStartTime}`}
                  color="blue.600"
                  fontWeight="500"
                  fontSize="sm"
                  target="_blank"
                >
                  {row.callName || "Interview"}
                </RouterLink>
              </TdWrapper>
              <TdWrapper>
                <HighlightText
                  text={formatMotivations(row.motivationTopics)}
                  keywords={[snakeCaseToSentenceCase(currentMotivation)]}
                  highlightedStyle={{ bg: "dataViz.caduceusGold.500" }}
                  matchWholeWords
                />
              </TdWrapper>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const ThWrapper: React.FC<
  {
    children: React.ReactNode;
  } & TableCellProps
> = ({ children, ...props }) => (
  <ThGray {...TABLE_CELL_PROPS} {...props}>
    {children}
  </ThGray>
);

const TdWrapper: React.FC<
  {
    children: React.ReactNode;
  } & TableCellProps
> = ({ children, ...props }) => (
  <TdWhite {...TABLE_CELL_PROPS} {...props} borderColor="inherit">
    {children}
  </TdWhite>
);

export default AnalyticsCandidateMotivationsTable;
