import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FiEdit2 } from "react-icons/fi";

import { IconButton } from "../../../components";
import { formatDate } from "../../../utils/datetime";
import BasicAlertDialog from "../../components/BasicAlertDialog";
import AddButton from "./AddButton";
import QuestionMenu from "./QuestionMenu";
import QuestionModal from "./QuestionModal";
import TabHeader, { TabHeaderText } from "./TabHeader";
import { TrainingQuestion } from "./types";
import { TrainingApi } from "./useTrainingApi";

interface ManageTrainingPageAssessmentsProps {
  trainingProgramId: string;
  trainingQuestions: TrainingQuestion[];
  trainingApi: TrainingApi;
  setAutoSaveInProgress: (autoSaveInProgress: boolean) => void;
}

const ManageTrainingPageAssessments: React.FC<
  ManageTrainingPageAssessmentsProps
> = ({
  trainingProgramId,
  trainingQuestions,
  trainingApi,
  setAutoSaveInProgress,
}) => {
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [addingQuestion, setAddingQuestion] = useState(false);
  const numQuestions = trainingQuestions.length;

  return (
    <>
      <TabHeader>
        <TabHeaderText>
          {numQuestions} Question{numQuestions !== 1 ? "s" : ""} in each
          recording
        </TabHeaderText>
      </TabHeader>
      <Box mt="6" ml="10">
        <Text fontSize="sm" color="gray.500" mb="6" maxWidth="625px">
          Note: Any changes made to the questions below will be instantly
          reflected in the training programs for all your in-progress and
          completed trainees. This will not affect the status of trainees
          already marked complete.
        </Text>
        {trainingQuestions.map((question, idx) => (
          <Flex key={question.id} mb="3" alignItems="center">
            <Flex flex="1" maxWidth="625px" mb="1" alignItems="center">
              <InputGroup>
                <Input
                  name={`question-text-${idx}`}
                  value={question.question}
                  maxWidth="625px"
                  fontSize="sm"
                  readOnly
                  _readOnly={{ bg: "gray.50" }}
                  _focus={{ borderColor: "border" }}
                  _hover={{ borderColor: "border" }}
                />
                <InputRightElement width="auto" mr="4">
                  <IconButton
                    aria-label={`Edit question ${idx + 1}`}
                    size="xs"
                    variant="ghost"
                    colorScheme="gray"
                    icon={<FiEdit2 />}
                    onClick={() => setEditingId(question.id)}
                  />
                </InputRightElement>
              </InputGroup>
              <QuestionMenu
                trainingProgramId={trainingProgramId}
                trainingApi={trainingApi}
                trainingQuestions={trainingQuestions}
                question={question}
                listPosition={idx}
                menuDisabled={trainingQuestions.length === 1}
                setAutoSaveInProgress={setAutoSaveInProgress}
                onDelete={() => {
                  setDeletingId(question.id);
                }}
              />
            </Flex>
            <Text flex="0 0 200px" mx="4" fontSize="xs" color="gray.700">
              {(() => {
                const updated = question.updatedAt !== question.createdAt;
                return `${updated ? "Updated" : "Added"} ${formatDate(
                  updated ? question.updatedAt : question.createdAt
                )}`;
              })()}
            </Text>
          </Flex>
        ))}
        <AddButton
          onClick={() => {
            setAddingQuestion(true);
          }}
          mx="0"
          mt="2"
        >
          Add a Question
        </AddButton>
        {deletingId && (
          <BasicAlertDialog
            title="Remove from Training Program?"
            body={
              <>
                Removing a question from this program will instantly remove it
                for all trainees in the program (in progress and marked
                complete). Trainees who have already answered this question will
                still be able to view the question and their submitted answers.
              </>
            }
            confirmLabel="Remove"
            onCancel={() => setDeletingId(null)}
            onConfirm={() => {
              setDeletingId(null);
              trainingApi.questions.remove(deletingId);
            }}
          />
        )}
        <QuestionModal
          isOpen={addingQuestion}
          onSave={(text: string) => {
            const maxOrder = trainingQuestions
              .map((q) => q.order)
              .reduce((prev, cur) => Math.max(prev, cur), 0);
            trainingApi.questions.add(trainingProgramId, text, maxOrder + 1);
            setAddingQuestion(false);
          }}
          onClose={() => setAddingQuestion(false)}
          mode="create"
        />
        <QuestionModal
          isOpen={!!editingId}
          initialQuestion={
            trainingQuestions.find((q) => q.id === editingId)?.question
          }
          onSave={(text: string) => {
            const question = trainingQuestions.find((q) => q.id === editingId);
            if (question && text !== question.question) {
              trainingApi.questions.update(question.id, text);
              setAutoSaveInProgress(true);
            }
            setEditingId(null);
          }}
          onClose={() => setEditingId(null)}
          mode="edit"
        />
      </Box>
    </>
  );
};

export default ManageTrainingPageAssessments;
