import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import qs from "qs";
import React, { useState } from "react";
import { FaUser } from "react-icons/fa";

import { Button } from "../../../components";
import { useRouterBasename } from "../../../hooks/useRouterBasename";
import { useIsExtension } from "../../hooks/useAppEnvironmentContext";

interface OktaAuthButtonProps {
  label: string;
}

const OktaAuthButton: React.FC<OktaAuthButtonProps> = ({ label }) => {
  const [showInput, setShowInput] = useState(false);
  const [val, setVal] = useState("");
  const isExtension = useIsExtension();
  const baseRedirectTo = useRouterBasename();
  const onSubmit = (): void => {
    if (!(val.includes(".") && val.length > 4)) return;
    if (isExtension) {
      window.open(
        new URL(
          `/saml/spi/${val}?redirect_to=authPopup`,
          window.location.origin
        ).toString(),
        "auth"
      );
    } else {
      const query = qs.parse(window.location.search.substr(1));
      const redirectTo =
        baseRedirectTo + (typeof query.from === "string" ? query.from : "/");
      window.location.replace(`/saml/spi/${val}?redirect_to=${redirectTo}`);
    }
  };
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e): void => {
    setVal(e?.target?.value);
  };

  return (
    <>
      {!showInput && (
        <Button
          leftIcon={<FaUser />}
          width="100%"
          onClick={() => {
            setShowInput(true);
          }}
          data-testid="okta-auth-button"
        >
          {label}
        </Button>
      )}
      {showInput && (
        <InputGroup size="md">
          <Input
            pr="4.5rem"
            type="text"
            placeholder="Enter your email or domain name"
            autoFocus
            value={val}
            onChange={onChange}
            onKeyDown={(key) => {
              if (key.code !== "Enter") return;
              onSubmit();
            }}
          />
          <InputRightElement width="4.5rem">
            <Button
              h="1.75rem"
              size="sm"
              onClick={onSubmit}
              isDisabled={!(val.includes(".") && val.length > 4)}
            >
              {"Login"}
            </Button>
          </InputRightElement>
        </InputGroup>
      )}
    </>
  );
};

export default OktaAuthButton;
