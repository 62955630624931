import {
  Box,
  Divider,
  Heading,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";

import { Button, useToast } from "../../../components";
import {
  PositionJbpListItemFragment,
  useUpdateAutoJoinAllNewPositionsMutation,
} from "../../graphql";
import {
  AutoJoinPositionGroupListItem,
  PositionGroupItem,
} from "./AutoJoinPositionGroupListItem";

interface AutoJoinPositionGroupPopoverProps {
  positions: Array<PositionJbpListItemFragment>;
  orgAutoJoinAllNewPositions: boolean;
}

const countJoinAllNewPositionGroups = (
  positionGroups: Map<string, PositionGroupItem>
): number => {
  let count = 0;
  positionGroups.forEach((positionGroup) => {
    if (positionGroup.autoJoinAllNewPositions === true) {
      count += 1;
    }
  });
  return count;
};

const createPositionGroups = (
  positions: Array<PositionJbpListItemFragment>
): Map<string, PositionGroupItem> => {
  const positionGroups = new Map<string, PositionGroupItem>();
  positions.forEach((position) => {
    const positionGroupName = position.client.name;
    const prevPositionGroup = positionGroups.get(positionGroupName);
    if (prevPositionGroup) {
      prevPositionGroup.positions.push(position);
    } else {
      positionGroups.set(positionGroupName, {
        id: position.client.id,
        name: position.client.name,
        autoJoinAllNewPositions: position.client.autoJoinAllNewPositions,
        positions: [position],
      });
    }
  });
  return positionGroups;
};

const AutoJoinPositionGroupPopover: React.FC<
  AutoJoinPositionGroupPopoverProps
> = ({ positions, orgAutoJoinAllNewPositions }) => {
  const toast = useToast();
  const positionGroups = useMemo(
    () => createPositionGroups(positions),
    [positions]
  );
  const joinAllNewText = orgAutoJoinAllNewPositions
    ? "All"
    : countJoinAllNewPositionGroups(positionGroups);

  const [updateAutoJoinAllNewPositions] =
    useUpdateAutoJoinAllNewPositionsMutation({
      onError: (err) => {
        toast({
          status: "error",
          title: "Error",
          description: `Failed to update setting for auto join all new positions: ${err.message}`,
        });
      },
      onCompleted: (data) => {
        let descriptionText = "Updated setting for new positions";
        if (
          data?.updateAutoJoinAllNewPositions?.positions &&
          data?.updateAutoJoinAllNewPositions?.positions.length > 0
        ) {
          if (
            data?.updateAutoJoinAllNewPositions?.positions[0].client
              .autoJoinAllNewPositions === true
          ) {
            descriptionText = `Turned "on" for new positions`;
          } else {
            descriptionText = `Turned "off" for new positions`;
          }
        }
        toast({
          title: "Success",
          description: descriptionText,
          status: "success",
        });
        // }
      },
    });

  const handleUpdateAutoJoinAllNewPositions = (
    enable: boolean,
    allPositionGroups: boolean,
    positionGroupId?: string
  ): void => {
    updateAutoJoinAllNewPositions({
      variables: { enable, allPositionGroups, positionGroupId },
    });
  };

  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <Popover
      placement="bottom-start"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <PopoverTrigger>
        <Button
          size="sm"
          variant="outline"
          fontSize="14px"
          fontWeight="normal"
          textTransform="none"
          colorScheme="blue"
          rightIcon={
            <Icon
              as={isOpen ? IoChevronUp : IoChevronDown}
              boxSize="4"
              marginLeft="4"
              display={{ base: "none", lg: "block" }}
            />
          }
        >
          Default on for&nbsp;<b>{joinAllNewText}</b>
          &nbsp;position groups
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          maxH="480"
          minW="680"
          overflowY="scroll"
          pl="3"
          pr="3"
          pt="4"
          boxShadow="elevate"
          _focus={{}}
        >
          <PopoverBody>
            <Heading as="h2" size="sm" mb={2}>
              Turn on Position Groups
            </Heading>
            <Text mb="8">
              Position Groups are mapped from your ATS or set when a new
              position is created in BrightHire.
            </Text>
            <Box mb="6">
              <Heading as="h3" size="xs" pb={3}>
                All Positions
              </Heading>
              <AutoJoinPositionGroupListItem
                id="all-positions"
                name=""
                autoJoinAllNewPositions={orgAutoJoinAllNewPositions}
                positions={positions}
                handleUpdateAutoJoinAllNewPositions={() =>
                  handleUpdateAutoJoinAllNewPositions(
                    !orgAutoJoinAllNewPositions,
                    true
                  )
                }
              />
              <Divider borderColor="gray.500" />
            </Box>
            {[...positionGroups].map((entry) => {
              const positionGroup = entry[1];
              return (
                <Box mb="6" key={`position-group-${positionGroup.id}`}>
                  <AutoJoinPositionGroupListItem
                    id={positionGroup.id}
                    name={positionGroup.name}
                    autoJoinAllNewPositions={
                      positionGroup.autoJoinAllNewPositions
                    }
                    positions={positionGroup.positions}
                    handleUpdateAutoJoinAllNewPositions={() =>
                      handleUpdateAutoJoinAllNewPositions(
                        !positionGroup.autoJoinAllNewPositions,
                        false,
                        positionGroup.id
                      )
                    }
                  />
                  <Divider />
                </Box>
              );
            })}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default AutoJoinPositionGroupPopover;
