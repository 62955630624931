import { AnalyticsDimension } from "../../../graphql";
import {
  ChartSort,
  DataPoint,
  PivotedDataPoint,
  SegmentDataPoint,
} from "../types";

/**
 * Transform data from:
 * { primaryDimension, secondaryDimension, value, count }
 *
 * to:
 * { label, y1, y2, y3, count1, count2, count3 }, etc.
 */
export const pivotData = (
  secondaryDimension: AnalyticsDimension,
  data: DataPoint[],
  primaryDimensionLabel?: string
): PivotedDataPoint[] => {
  const rowMap: { [key: string]: PivotedDataPoint } = {};
  // Group each DataPoint by rowId, by accumulating values
  // in the pivotedDataPoint.data[segment] map
  data.forEach((p) => {
    const rowId = p.dataId || "___null_placeholder___";
    const segment = p.segment || "all";
    const segmentPoint: SegmentDataPoint = {
      value: p.value,
      countDataPoints: p.countDataPoints,
      avgPerformance: p.avgPerformance,
    };

    const fallbackLabel = primaryDimensionLabel
      ? `No ${primaryDimensionLabel}`
      : "No Label";
    if (!rowMap[rowId]) {
      rowMap[rowId] = {
        dataId: rowId,
        label: p.label || fallbackLabel,
        data: {
          [segment]: segmentPoint,
        },
      };
    } else {
      rowMap[rowId].data[segment] = segmentPoint;
    }
  });
  return Object.values(rowMap);
};

export const sortPivotedData = (
  data: PivotedDataPoint[],
  sort: ChartSort
): PivotedDataPoint[] => {
  const sortableData = [...data];
  switch (sort) {
    case "highest-first":
      return sortableData.sort(
        (a, b) =>
          pivotedDataPointSortKey(b, "value") -
          pivotedDataPointSortKey(a, "value")
      );
    case "lowest-first":
      return sortableData.sort(
        (a, b) =>
          pivotedDataPointSortKey(a, "value") -
          pivotedDataPointSortKey(b, "value")
      );
    case "most-interviews-first":
      return sortableData.sort(
        (a, b) =>
          pivotedDataPointSortKey(b, "countDataPoints") -
          pivotedDataPointSortKey(a, "countDataPoints")
      );
    case "least-interviews-first":
      return sortableData.sort(
        (a, b) =>
          pivotedDataPointSortKey(a, "countDataPoints") -
          pivotedDataPointSortKey(b, "countDataPoints")
      );
    case "name-a-z":
      return sortableData.sort((a, b) =>
        (a.label || "").localeCompare(b.label || "")
      );
    case "name-z-a":
      return sortableData.sort((a, b) =>
        (b.label || "").localeCompare(a.label || "")
      );
    case "highest-performance-first":
      return sortableData.sort(
        (a, b) =>
          pivotedDataPointSortKey(b, "avgPerformance") -
          pivotedDataPointSortKey(a, "avgPerformance")
      );
    case "lowest-performance-first":
      return sortableData.sort(
        (a, b) =>
          pivotedDataPointSortKey(a, "avgPerformance") -
          pivotedDataPointSortKey(b, "avgPerformance")
      );
    default:
      throw new Error("Unexpected sort");
  }
};

const pivotedDataPointSortKey = (
  pivotedDataPoint: PivotedDataPoint,
  key: keyof SegmentDataPoint
): number => {
  const sum = sumSegmentByKey(pivotedDataPoint, key);
  return sum ?? -Infinity;
};

const sumSegmentByKey = (
  pivotedDataPoint: PivotedDataPoint,
  key: keyof SegmentDataPoint
): number | undefined => {
  const definedNumbers = Object.values(pivotedDataPoint.data)
    .map((x) => x[key])
    .filter((x) => typeof x === "number") as number[];
  if (definedNumbers.length === 0) {
    return undefined;
  }
  return definedNumbers.reduce((x, sum) => x + sum, 0);
};

/** Full transformation from raw data from API to pivoted, defined, sorted, limited data. */
export const transformData = (
  data: DataPoint[],
  secondaryDimension: AnalyticsDimension,
  sort: ChartSort,
  limit: number,
  primaryDimensionLabel?: string
): PivotedDataPoint[] => {
  const pivotedData = pivotData(
    secondaryDimension,
    data,
    primaryDimensionLabel
  );
  const definedPivotedData = pivotedData.filter(
    (p) => pivotedDataPointSortKey(p, "value") !== -Infinity
  );
  const sortedPivotedData = sortPivotedData(definedPivotedData, sort);
  return limit === -1 ? sortedPivotedData : sortedPivotedData.slice(0, limit);
};
