import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

export const ComputerIcon = (props: IconProps): JSX.Element => (
  <Icon w={120} h="auto" viewBox="0 0 120 110" fill="none" {...props}>
    <path
      d="M10.3061 69.7063L10.2969 16.3251C10.2969 12.9736 13.0329 10.238 16.4018 10.2344H103.607C106.968 10.2417 109.706 12.9754 109.708 16.3251V69.7063H10.3061Z"
      fill="#EAF9FF"
    />
    <g fill="#AEDEFF">
      <path d="M23.7574 40.668C21.8126 40.668 20.2266 39.0894 20.2266 37.1514V3.51658C20.2266 1.57311 21.8089 0 23.7537 0H57.8696C59.8126 0 61.3949 1.57311 61.3986 3.51291V37.1514C61.3986 39.0894 59.8163 40.668 57.8696 40.668H23.7574ZM23.7574 1.4356C22.6111 1.43927 21.6783 2.37066 21.6746 3.51291V37.1514C21.6746 38.2936 22.6111 39.2269 23.7574 39.2305H57.8696C59.0177 39.2269 59.9543 38.2973 59.9579 37.1514V3.51658C59.9543 2.37067 59.0177 1.4356 57.8696 1.4356H23.7574Z" />
      <path d="M77.9196 79.7732C75.9748 79.7732 74.3906 78.1946 74.3906 76.2566V42.6181C74.3906 40.6783 75.9748 39.1016 77.9196 39.1016H111.859C113.804 39.1016 115.386 40.6783 115.386 42.6181V76.2566C115.386 78.1946 113.804 79.7732 111.859 79.7732H77.9196ZM77.9196 40.5372C76.7696 40.5372 75.8331 41.4722 75.8331 42.6181V76.2566C75.8331 77.4025 76.7696 78.3321 77.9196 78.3358H111.859C113.009 78.3321 113.945 77.4025 113.945 76.2566V42.6181C113.945 41.4722 113.009 40.5372 111.859 40.5372H77.9196Z" />
      <path d="M5.65398 76.0702C3.70918 76.0702 2.125 74.4916 2.125 72.5537V50.9757C2.125 49.0377 3.70918 47.4609 5.65398 47.4609H24.9438C26.8886 47.4609 28.4709 49.0377 28.4709 50.9757V72.5537C28.4709 74.4916 26.8886 76.0702 24.9438 76.0702H5.65398ZM5.65398 48.8965C4.50402 48.8965 3.5675 49.8298 3.5675 50.9757V72.5537C3.5675 73.6996 4.50402 74.6291 5.65398 74.6328H24.9438C26.09 74.6291 27.0266 73.6996 27.0303 72.5537V50.9757C27.0266 49.8298 26.09 48.8965 24.9438 48.8965H5.65398Z" />
      <path d="M25.3782 26.3069L25.3672 4.77479L56.232 4.76562L56.2393 26.3069H25.3782Z" />
      <path d="M25.8023 33.2063C25.2117 32.5665 25.2117 31.6076 25.8023 30.9677C26.1133 30.6267 26.5604 30.4323 27.0222 30.4323C27.4417 30.4323 27.8391 30.5845 28.1482 30.8687L28.5033 31.2225L28.8437 30.8687C29.1565 30.557 29.5705 30.3828 30.0158 30.3828C30.4592 30.3828 30.8732 30.557 31.1896 30.8687C31.8318 31.5122 31.8318 32.5628 31.186 33.2063L28.496 35.8905L25.8023 33.2063Z" />
    </g>
    <g fill="#7FC6FE">
      <path d="M34.2931 110.001C32.9629 110.001 31.8828 108.923 31.8828 107.599C31.8828 106.273 32.9629 105.197 34.2931 105.197H43.8773L43.8681 82.3227C43.8681 78.5476 46.9481 75.4766 50.7365 75.4766H69.2591C73.0456 75.4766 76.1275 78.5476 76.1275 82.3227V103.16C76.1238 106.93 73.042 110.001 69.2591 110.001H34.2931Z" />
      <path d="M11.5216 90.1879C5.16836 90.1879 0 85.0377 0 78.7068V11.4683C0.00735971 5.14286 5.17572 0 11.5216 0H108.482C114.824 0.00366692 119.993 5.14652 120 11.4683V78.7068C120 85.0377 114.832 90.1879 108.478 90.1879H11.5216ZM16.4066 10.2545C13.0488 10.2545 10.3146 12.9772 10.3146 16.3233V69.6935L109.696 69.6825L109.706 16.3233C109.706 12.9772 106.97 10.2545 103.612 10.2545H16.4066Z" />
    </g>
    <path
      d="M60.0053 84.2089C57.6373 84.2089 55.7109 82.2874 55.7109 79.9277C55.7109 77.5699 57.6373 75.6484 60.0053 75.6484C62.3733 75.6484 64.2997 77.5699 64.2997 79.9277C64.2997 82.2874 62.3733 84.2089 60.0053 84.2089Z"
      fill="#0772D0"
    />
    <path
      d="M60.0071 6.49477C59.247 6.49477 58.625 5.87493 58.625 5.11939C58.625 4.35836 59.247 3.74219 60.0071 3.74219C60.7671 3.74219 61.3873 4.35836 61.3873 5.11939C61.3873 5.87493 60.7671 6.49477 60.0071 6.49477Z"
      fill="#67717E"
    />
    <path d="M76.1142 90.2188H43.8125" stroke="#0772D0" strokeWidth="4" />
  </Icon>
);
