type BaseLinkType<S extends string> = { type: S };

type HiringTeamLink = BaseLinkType<"hiringTeam"> & { positionId: string };
type DepartmentTeamLink = BaseLinkType<"departmentTeam"> & { clientId: string };
type HiringTabsPageLink = BaseLinkType<"hiringTabsPage"> & {
  page: "teams" | "team_groups";
};
type UserInfoLink = BaseLinkType<"userInfo"> & { userId: string };
type RolesAndPoliciesLink = BaseLinkType<"userRoles"> & {
  page: "user" | "team" | "policies";
};

type LinkType =
  | HiringTeamLink
  | DepartmentTeamLink
  | HiringTabsPageLink
  | UserInfoLink
  | RolesAndPoliciesLink;

/**
 * Generates the correct link for a given object
 *
 * This was born out of a need to change certain paths based on
 * a feature flag, but this can be extended for all types of links
 * to centralize their definitions
 */
const useLink = ({ type, ...rest }: LinkType): string => {
  const usersSettingsLink = (link: string): string => "/settings".concat(link);

  if (type === "hiringTeam") {
    const { positionId } = rest as HiringTeamLink;
    return usersSettingsLink(`/users/hiring/teams/info/${positionId}`);
  }

  if (type === "departmentTeam") {
    const { clientId } = rest as DepartmentTeamLink;
    return usersSettingsLink(`/users/hiring/team_groups/info/${clientId}`);
  }

  if (type === "hiringTabsPage") {
    const { page } = rest as HiringTabsPageLink;
    return usersSettingsLink(`/users/hiring/${page}`);
  }

  if (type === "userInfo") {
    const { userId } = rest as UserInfoLink;
    return usersSettingsLink(`/users/info/${userId}`);
  }

  if (type === "userRoles") {
    const { page } = rest as RolesAndPoliciesLink;
    return usersSettingsLink(`/users/roles/${page}`);
  }

  return "";
};

export default useLink;
