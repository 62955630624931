import { CustomTopicMatchRule } from "../../../graphql";

export type TopicType = "default" | "custom";

export type TopicOption = {
  label: string;
  value: string;
  type: TopicType;
  keywords: string[];
  matchRule: CustomTopicMatchRule;
};

export type TopicOptionGroup = {
  label: string;
  options: TopicOption[];
};

export enum DefaultTopic {
  Ai = "Ai",
  CompensationAndBenefits = "Compensation and benefits",
  Culture = "Culture",
  Dei = "Dei",
  Economy = "Economy",
  LayoffsAndCompanyStability = "Layoffs and company stability",
  Motivations = "Motivations",
  WorkLocation = "Work location",
}
