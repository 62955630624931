import { ButtonProps } from "@chakra-ui/react";
import React from "react";

import { Button } from "../Buttons";

export const RatingButton: React.FC<{
  link: string;
  text?: string;
  variant?: ButtonProps["variant"];
  icon: any;
  showFullWidth?: boolean;
  size?: ButtonProps["size"];
}> = ({
  link,
  text = "Add rating",
  variant = "outline",
  icon,
  showFullWidth = false,
  size = "xs",
}) => {
  return (
    <Button
      as="a"
      href={link}
      target="_blank"
      leftIcon={icon}
      variant={variant}
      colorScheme="blue"
      size={size}
      fontWeight="medium"
      width={showFullWidth ? "100%" : "auto"}
    >
      {text}
    </Button>
  );
};

export default RatingButton;
