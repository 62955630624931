import { Menu, MenuItem, MenuList, Tooltip } from "@chakra-ui/react";
import React from "react";
import { IoSettingsSharp } from "react-icons/io5";

import { IconButton, MenuButton } from "../../../components";
import { useUpdateCurrentUserShowActionItemsMutation } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";

export const CandidateAlertSettings: React.FC<{
  updateFilterActionItems: (show: boolean) => void;
}> = ({ updateFilterActionItems }) => {
  const currentUser = useCurrentUser();

  const [toggleActionItems] = useUpdateCurrentUserShowActionItemsMutation({
    onCompleted: (data) => {
      const show =
        data.updateCurrentUserShowActionItems?.currentUser
          .showCandidateActionItemAlerts;
      if (show === true || show === false) {
        updateFilterActionItems(show);
      }
    },
  });
  return (
    <Menu>
      <Tooltip label="Alerts Settings" aria-label="Alerts Settings">
        <MenuButton
          fontSize="1rem"
          as={IconButton}
          icon={<IoSettingsSharp />}
          variant="ghost"
          maxW="30px"
          maxH="30px"
          minW="30px"
        />
      </Tooltip>
      <MenuList fontSize="sm">
        <MenuItem
          onClick={() =>
            toggleActionItems({
              variables: { show: !currentUser.showCandidateActionItemAlerts },
            })
          }
        >
          {currentUser.showCandidateActionItemAlerts
            ? "Hide action items"
            : "Show action items"}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
