import { Box, Flex, StyleProps, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import MultiValueSelect from "../../../components/MultiValueSelect/MultiValueSelect";
import { EventsForMonth } from "./EventsForMonth";
import { EventListItem, useEventImportKeyword } from "./utils";

export type EventImportKeywordBaseProps = StyleProps & {
  error?: string;
  eventList: EventListItem[];
  keywords: string[];
  onAddKeyword?(keyword: string): void;
  onKeywordsChange?(keywords: string[]): void;
};

export const EventImportKeywordBase: React.FC<EventImportKeywordBaseProps> = ({
  error: errorProp,
  eventList,
  keywords,
  onAddKeyword,
  onKeywordsChange,
  ...styles
}) => {
  const [error, setError] = useState(errorProp);
  useEffect(() => setError(errorProp), [errorProp]);

  const [showSaved, setShowSaved] = useState(false);
  useEffect(() => {
    if (showSaved) {
      const timeout = setTimeout(() => setShowSaved(false), 3_000);
      return () => clearTimeout(timeout);
    }
  }, [showSaved]);

  return (
    <Flex gap="4" maxH="400" {...styles}>
      <Flex direction="column" flex="1" color="gray.600">
        <Box fontWeight="semibold" fontSize="sm" mb="2">
          Keywords or phrases that identify interviews
        </Box>
        <Box py="2" px="4" backgroundColor="gray.50" borderRadius="lg" flex="1">
          <Text fontSize="sm" mb="1">
            Be specific in choosing your keywords and phrases as BrightHire will
            join all events that contain any of them:
          </Text>
          <Text
            textAlign="right"
            color="gray.500"
            fontWeight="400"
            fontSize="sm"
            mt="-20px"
            transitionProperty="opacity"
            transitionDuration={showSaved ? "150ms" : "500ms"}
            opacity={showSaved ? 1 : 0}
          >
            Saved!
          </Text>
          <MultiValueSelect
            fontSize="sm"
            autoFocus
            value={keywords}
            onAddValue={(kw) => {
              setShowSaved(true);
              onAddKeyword?.(kw);
            }}
            onValueChange={(kws) => {
              setShowSaved(true);
              onKeywordsChange?.(kws);
            }}
            onInputChange={() => {
              setShowSaved(false);
              setError("");
            }}
            validator={(s) => {
              const v = s.trim();
              if (v.length > 3) return v;
              if (v.length > 0) {
                setError("Keywords must be at least four characters");
              }
              return null;
            }}
            getOptionLabel={(s) => s}
            getOptionValue={(s) => s}
            addValueOnBlur
            placeholder="Enter keywords or phrases"
            selectStyles={{
              control: (provided) => ({
                ...provided,
                maxHeight: "150px",
                overflowY: "auto",
              }),
              indicatorsContainer: (provided) => ({
                ...provided,
                display: "block",
              }),
              clearIndicator: (provided) => ({
                ...provided,
                position: "sticky",
                top: "0",
              }),
            }}
            helpTextComponent={
              <Box fontSize="xs" mt="1">
                {error ? (
                  <Box color="red.500">{error}</Box>
                ) : (
                  <Box color="gray.500" fontWeight="semibold">
                    Separate keywords with a comma or enter key.
                  </Box>
                )}
              </Box>
            }
          />
        </Box>
      </Flex>
      <Flex direction="column" flex="1">
        <Box color="gray.600" fontWeight="semibold" fontSize="sm" mb="2">
          Events BrightHire will join
        </Box>
        <Box
          py="2"
          pl="3"
          pr="5"
          flex="1"
          border="1px"
          borderColor="gray.200"
          borderRadius="lg"
          overflowY="scroll"
        >
          {eventList.map((item) => (
            <EventsForMonth key={item.month} item={item} />
          ))}
        </Box>
      </Flex>
    </Flex>
  );
};

export const EventImportKeyword: React.FC<StyleProps> = (styles) => {
  const { keywords, eventList, onAddKeyword, onKeywordsChange, keywordError } =
    useEventImportKeyword();

  return (
    <EventImportKeywordBase
      eventList={eventList}
      keywords={keywords}
      error={keywordError}
      onAddKeyword={onAddKeyword}
      onKeywordsChange={onKeywordsChange}
      {...styles}
    />
  );
};
