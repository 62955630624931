import { Box, Circle, Flex, Tag } from "@chakra-ui/react";
import React from "react";

import { RouterLink } from "../../../../components";
import { Alert } from "../../../graphql";

export type OverviewTipProps = {
  alert: Alert;
  linkTo: string;
  onCardClick?: (alert: Alert) => void;
};

const OverviewTip: React.FC<OverviewTipProps> = ({
  alert,
  linkTo,
  onCardClick,
}) => {
  const alertTypeMap: {
    [key: string]: string;
  } = {
    candidate_talk_time: "Candidate Talk Time", // eslint-disable-line camelcase
    count_interviewer_questions: "Interviewer Questions", // eslint-disable-line camelcase
    gender_skewed_panel: "Gender Skewed Panel", // eslint-disable-line camelcase
    is_late_start: "On-time Starts", // eslint-disable-line camelcase
    submission_delay: "Submission Delay", // eslint-disable-line camelcase
    submission_is_positive: "Submission Sentiment", // eslint-disable-line camelcase
    submission_rate: "Submission Rate", // eslint-disable-line camelcase
  };
  const alertTypeText = alertTypeMap[alert.alertType] || "Interview Tip";

  return (
    <RouterLink
      to={linkTo}
      onClick={() => onCardClick?.(alert)}
      _hover={{ textDecor: "none" }}
    >
      <Flex
        position="relative"
        flexDir="row"
        align="start"
        pt="3"
        pb="4"
        pl="3"
        pr="4"
        border="1px solid"
        borderColor="blueAlpha.300"
        borderRadius="8px"
        bg="blueAlpha.50"
        color="gray.800"
        _hover={{ bg: "blueAlpha.100" }}
      >
        <TipTag alert={alert} />
        <Flex direction="column" ml="3">
          <Box fontWeight="600" fontSize="sm">
            {alertTypeText}
          </Box>
          <Box fontWeight="400" fontSize="sm">
            {alert.message}
          </Box>
        </Flex>
        {alert.isNew && <TagIndicator />}
      </Flex>
    </RouterLink>
  );
};

const TipTag: React.FC<{
  alert: Alert;
}> = ({ alert }) => {
  return (
    <Tag
      as={Box}
      position="relative"
      size="sm"
      textTransform="uppercase"
      bg="blueAlpha.100"
      color="blue.800"
      mr="2"
      px="1"
      py="2px"
      minHeight="default"
      fontSize="9px"
      borderRadius="2px"
      mt="1"
      flexShrink="0"
    >
      {alert.alertValence === "positive" ? "Win" : "Tip"}
    </Tag>
  );
};

const TagIndicator: React.FC = () => {
  return (
    <Circle
      position="absolute"
      top="12px"
      right="12px"
      size="8px"
      bg="red.600"
    />
  );
};

export default OverviewTip;
