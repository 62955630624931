import {
  Box,
  Flex,
  Icon,
  ListItem,
  Text,
  Tooltip,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo } from "react";
import { GoDotFill } from "react-icons/go";

import {
  Button,
  EmptyAIIcon,
  IconButton,
  Link,
} from "../../../../../../components";
import CopyOutlineIcon from "../../../../../../components/Icons/CopyOutlineIcon";
import { copy } from "../../../../../../utils/clipboard";
import { formatDuration } from "../../../../../../utils/datetime";
import { useSendGAEvent } from "../../../../../../utils/googleAnalytics";
import {
  CallAiSummaryFormat,
  CallAiSummaryProcessingStatus,
  CallAiSummaryQuery,
  useUpdateCallAiSummaryFormatMutation,
} from "../../../../../graphql";
import useCurrentUser from "../../../../../hooks/useCurrentUser";
import { noteTimestampedLink } from "../../../../CallNotes/utils";
import MonospacedText from "../../../../MonospacedText";
import AiFeedback from "./NotesQA/AiFeedback";
import AiNotesLoading from "./NotesQA/AiNotesLoading";
import AiNotesMessage from "./NotesQA/AiNotesMessage";
import NotesUnavailable from "./NotesUnavailable";

type SummaryHeader = NonNullable<
  NonNullable<CallAiSummaryQuery["callAiSummary"]>["headers"]
>[0];

const NotesOtherFormats: React.FC<{
  data: CallAiSummaryQuery;
  callId: string;
  onClickTimestamp(t: number): void;
  loading: boolean;
  onRetry(): void;
  registerCopyNotes(f: () => void): void;
}> = ({
  data,
  callId,
  onClickTimestamp,
  loading,
  onRetry,
  registerCopyNotes,
}) => {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();
  const [update, updateSummaryData] = useUpdateCallAiSummaryFormatMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: "Error generating summary",
        status: "error",
        position: "top",
      });
    },
  });

  const {
    organization: { includeTimestampsInNoteOutputs },
  } = useCurrentUser();

  // Create a time-ordered mapping to footnote numbers
  const footnoteMap = useMemo(() => {
    const footnoteMap: { [key: number]: number } = {};
    const uniqueTimestamps: Set<number> = new Set();
    data.callAiSummary?.headers?.forEach((header) => {
      header.notes?.forEach((note) => {
        note.startTimes.forEach((t) => {
          uniqueTimestamps.add(t as number);
        });
      });
    });
    const orderedTimestamps = Array.from<number>(uniqueTimestamps).sort(
      (a, b) => a - b
    );
    orderedTimestamps.forEach((t: number, idx) => {
      footnoteMap[t] = idx + 1;
    });

    return footnoteMap;
  }, [data]);

  const formatHeader = useCallback(
    (header: SummaryHeader): { plain: string; html: string } | null => {
      const notes = header.notes || [];
      if (notes.length === 0) {
        return null;
      }

      let plain = `${header.text}\n\n`;
      let html = `<b>${header.text}</b><br><ul>`;

      notes.forEach((note) => {
        plain += `- ${note.text ?? ""}\n`;
        html += "<li>";
        html += `${note.text} `;
        if (includeTimestampsInNoteOutputs) {
          html += note.startTimes
            .map(
              (startTime) =>
                `[<a href="${noteTimestampedLink({
                  callId,
                  time: note.startTimes[0] ?? 0,
                })}">${footnoteMap[startTime ?? 0]}</a>]`
            )
            .join(" ");
        }
        html += "</li>";
      });
      html += "</ul><br>";
      return { plain, html };
    },
    [callId, footnoteMap]
  );
  const copyNote = useCallback(
    (header: SummaryHeader): void => {
      const result = formatHeader(header);
      if (!result) {
        return;
      }
      copy({ plain: result.plain, html: result.html });
      toast({
        title: "Section copied to clipboard",
        status: "success",
        position: "top",
      });
      sendGAEvent("ai_summary_copy_section", "call_review");
    },
    [formatHeader, toast, sendGAEvent]
  );

  const copyNotes = useCallback((): void => {
    const headers = data.callAiSummary?.headers || [];
    if (headers.length === 0) {
      return;
    }
    let plain = "";
    let html = "";

    headers.forEach((header) => {
      const result = formatHeader(header);
      if (!result) {
        return;
      }
      if (plain !== "") {
        plain += "\n";
        plain += result.plain;
      } else {
        plain += result.plain;
      }
      html += result.html;
    });
    copy({ plain, html });
    toast({
      title: "Notes copied to clipboard",
      status: "success",
      position: "top",
    });
    sendGAEvent("ai_summary_copy_all", "call_review");
  }, [data, formatHeader, toast, sendGAEvent]);

  useEffect(() => {
    registerCopyNotes(copyNotes);
  }, [registerCopyNotes, copyNotes]);

  if (
    loading ||
    (data.callAiSummary?.status !== CallAiSummaryProcessingStatus.Completed &&
      data.callAiSummary?.status !== CallAiSummaryProcessingStatus.Failed)
  ) {
    return <AiNotesLoading loadingText="Generating summary" />;
  }

  if (data.callAiSummary?.status === CallAiSummaryProcessingStatus.Failed) {
    return (
      <AiNotesMessage>
        <EmptyAIIcon mb="5" />
        <Text align="center" maxW="360px">
          Something went wrong generating your summary.
        </Text>
        {data?.callAiSummary?.format && data?.callAiSummary?.targetSpeakerTags && (
          <Button
            variant="solid"
            isLoading={updateSummaryData.loading}
            onClick={() => {
              if (
                data?.callAiSummary?.format &&
                data?.callAiSummary?.targetSpeakerTags
              ) {
                update({
                  variables: {
                    callId,
                    format: data.callAiSummary.format,
                    targetSpeakerTags: data.callAiSummary.targetSpeakerTags,
                    customTopics: data.callAiSummary.customTopics || [],
                    customTemplateId: data.callAiSummary.customTemplateId,
                  },
                }).then(() => {
                  onRetry();
                });
              }
            }}
            my={4}
          >
            Retry?
          </Button>
        )}
      </AiNotesMessage>
    );
  }
  const totalNotes = data.callAiSummary?.headers?.reduce(
    (acc, header) => acc + (header.notes?.length || 0),
    0
  );

  const isScorecardFormat =
    data.callAiSummary?.format === CallAiSummaryFormat.Scorecard;

  /**
   * Don't show NotesUnavailable for the scorecard format. Scorecard format will
   * show every guide item, with an empty state for the item if there are no notes.
   */
  if (totalNotes === 0 && !isScorecardFormat) {
    return (
      <NotesUnavailable helperText="Try selecting a different AI Notes template." />
    );
  }

  const headers = data.callAiSummary?.headers?.map((header) => {
    return (
      <Box
        mb={4}
        fontSize="sm"
        key={header.id}
        pb="12px"
        pr="12px"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="8px"
      >
        <Flex
          pl={4}
          pt="10px"
          pb="6px"
          flexDir="row"
          alignItems="center"
          position="relative"
          role="group"
          gap="2"
        >
          <Text as="span" fontWeight="medium" textColor="gray.900">
            {header.text}
          </Text>
          <Tooltip label="Copy section notes" placement="bottom-start">
            <IconButton
              size="xs"
              aria-label="Copy section notes"
              height={4}
              fontSize="sm"
              fontWeight="medium"
              variant="ghost"
              colorScheme="blue"
              ml="auto"
              icon={<CopyOutlineIcon width="16px" height="16px" />}
              onClick={() => copyNote(header)}
              visibility="hidden"
              _hover={{ bg: "transparent" }}
              _groupHover={{ visibility: "visible" }}
            />
          </Tooltip>
        </Flex>
        <Box color="gray.700">
          {!header.notes || header.notes.length === 0 ? (
            <EmptyStateNote
              errorText={
                isScorecardFormat
                  ? "No notes can be provided because this question may not have been covered or requires subjective feedback."
                  : "No results found for this topic."
              }
            />
          ) : (
            <UnorderedList listStyleType="none" ml={0}>
              {header.notes?.map((note) => {
                return (
                  <SummaryNote
                    id={note.id}
                    callId={callId}
                    onClickTimestamp={onClickTimestamp}
                    text={note.text}
                    startTimes={
                      note.startTimes.filter((t) => t !== null) as number[]
                    }
                    key={note.id}
                    footnoteMap={footnoteMap}
                  />
                );
              })}
            </UnorderedList>
          )}
        </Box>
      </Box>
    );
  });

  const showFeedback = isScorecardFormat;

  return (
    <Box
      fontSize="sm"
      px={{ base: "1px" }}
      pt={1}
      mb={showFeedback ? "75px" : undefined}
    >
      {headers}
      {showFeedback && <AiFeedback feature="scorecard" allowText />}
    </Box>
  );
};

type NoteProps = {
  id: string;
  callId: string;
  onClickTimestamp(t: number): void;
  text: string;
  startTimes: number[];
  footnoteMap: { [key: number]: number };
};

const SummaryNote: React.FC<NoteProps> = ({
  id,
  callId,
  onClickTimestamp,
  text,
  startTimes,
  footnoteMap,
}) => {
  return (
    <Box
      as={ListItem}
      key={id}
      _hover={{
        bg: "blue.50",
      }}
    >
      <Flex direction="row" align="baseline" cursor="pointer">
        <Icon as={GoDotFill} boxSize={2} ml={4} mr="6px" flexGrow="0" />
        <Text as="span" py={2}>
          <Text as="span" pr={1}>
            {text}
          </Text>
          <Text as="span" verticalAlign="top" fontSize="xs">
            {startTimes.map((startTime, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={idx}>
                {idx > 0 && " "}
                <Tooltip label={formatDuration(Math.round(startTime))}>
                  <Link
                    fontWeight="normal"
                    href={`/interview/${callId}?t=${startTime}`}
                    onClick={(e) => {
                      e.preventDefault();
                      onClickTimestamp(startTime);
                    }}
                    pt={2}
                  >
                    <MonospacedText
                      display="inline-block"
                      text={footnoteMap[startTime].toString()}
                    />
                  </Link>
                </Tooltip>
              </React.Fragment>
            ))}
          </Text>
        </Text>
      </Flex>
    </Box>
  );
};

const EmptyStateNote: React.FC<{ errorText: string }> = ({ errorText }) => {
  return (
    <Box>
      <Flex ml={4} direction="row" align="baseline">
        <Text as="span" py={2}>
          <Text as="span" pr={1} color="gray.500">
            {errorText}
          </Text>
        </Text>
      </Flex>
    </Box>
  );
};

export default NotesOtherFormats;
