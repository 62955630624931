import { Select, SelectProps } from "@chakra-ui/react";
import React from "react";

import { CandidateSummaryTemplateFragment } from "../../../graphql";
import { getTemplateDisplayName } from "./utils";

type SummaryTemplateSelectProps = {
  handleChangeTemplate: (templateId: string) => void;
  selectedTemplate: string;
  noCallsForSummary: boolean;
  templates: CandidateSummaryTemplateFragment[];
} & SelectProps;

const SummaryTemplateSelect: React.FC<SummaryTemplateSelectProps> = ({
  handleChangeTemplate,
  selectedTemplate,
  noCallsForSummary,
  templates,
  ...selectProps
}) => {
  return (
    <Select
      aria-label="Select summary template"
      size="sm"
      borderRadius="md"
      onChange={(e) => {
        handleChangeTemplate(e.target.value);
      }}
      value={selectedTemplate}
      isDisabled={noCallsForSummary}
      {...selectProps} // some are width="100%"
    >
      {templates?.map((item) => {
        return (
          <option key={item.id} value={item.id}>
            {getTemplateDisplayName(item)}
          </option>
        );
      })}
    </Select>
  );
};

export default SummaryTemplateSelect;
