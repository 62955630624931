import {
  ButtonGroup,
  Flex,
  Icon,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import invariant from "invariant";
import React, { MediaHTMLAttributes, useRef } from "react";
import {
  MdClose,
  MdExpandMore,
  MdFullscreen,
  MdPictureInPicture,
} from "react-icons/md";

import { AudioImageWide, IconButton, useTheme } from "../../../components";
import { useFullscreen } from "../../../hooks/useFullscreen";
import usePictureInPicture, {
  ExtendedHTMLVideoElement,
} from "../../../hooks/usePictureInPictureBeta";

const Video = React.forwardRef<
  ExtendedHTMLVideoElement,
  MediaHTMLAttributes<HTMLVideoElement> & {
    screenControls?: boolean;
    renderControls?: () => any;
    onCollapse?: () => void;
    videoDisplayMode?: string;
    variation?: "rounded";
    height?: string;
    maxHeight?: string;
  }
>(
  (
    {
      src,
      renderControls,
      screenControls = true,
      onCollapse,
      videoDisplayMode,
      variation,
      height,
      maxHeight,
      ...rest
    },
    ref
  ) => {
    invariant(ref instanceof Function, "invalid video ref");
    const videoRef = useRef<ExtendedHTMLVideoElement | null>(null);
    const { colors } = useTheme();

    const {
      isOpen: isHovering,
      onOpen: onMouseEnter,
      onClose: onMouseLeave,
    } = useDisclosure();
    const { isOpen, onOpen, onClose } = useDisclosure({
      defaultIsOpen: true,
      onOpen: onMouseEnter,
    });
    const {
      isPictureInPictureAvailable,
      isPictureInPictureActive,
      togglePictureInPicture,
    } = usePictureInPicture(videoRef);
    const { isFullscreenSupported, toggleFullscreen } = useFullscreen(videoRef);

    React.useEffect(() => {
      if (onCollapse) onCollapse();
    }, [isOpen]);

    return (
      <>
        {!isOpen && (
          <Tooltip label="Show Video">
            <Flex
              onClick={onOpen}
              textAlign="center"
              cursor="pointer"
              borderBottom="1px"
              borderColor="border"
              _hover={{ bg: "blue.50" }}
              align="center"
              justify="center"
              fontSize="sm"
              p="1"
            >
              <MdExpandMore />
            </Flex>
          </Tooltip>
        )}
        <Flex
          bg="black"
          position="relative"
          hidden={!isOpen}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          justifyContent="center"
          borderRadius={variation === "rounded" ? "4px 4px 0 0" : "0"}
        >
          <video
            onContextMenu={(e) => e.preventDefault()}
            ref={(element: any) => {
              videoRef.current = element;
              ref(element);
            }}
            playsInline
            controlsList="nodownload"
            src={src}
            {...rest}
            style={{
              display: videoDisplayMode === "audio" ? "none" : "block",
              height: height === undefined ? "220px" : height,
              maxHeight,
            }}
          />
          {videoDisplayMode === "audio" && (
            <AudioImageWide
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "black",
                color: colors.white,
              }}
            />
          )}
          {isHovering && screenControls && (
            <Flex
              position="absolute"
              top="2"
              left="6"
              right="6"
              zIndex="1"
              alignItems="center"
              justifyContent="space-between"
              opacity={0.8}
              pointerEvents="none"
            >
              <ButtonGroup variant="unstyled" size="sm" color="white">
                <Tooltip label="Hide">
                  <IconButton
                    aria-label="Hide video"
                    background="gray.800"
                    pointerEvents="all"
                    _hover={{ transform: "scale(1.1)" }}
                    icon={<Icon as={MdClose} boxSize="5" />}
                    onClick={onClose}
                  />
                </Tooltip>
              </ButtonGroup>
              <ButtonGroup variant="unstyled" size="sm" color="white">
                {isPictureInPictureAvailable && (
                  <Tooltip label="Picture-in-picture">
                    <IconButton
                      aria-label="Picture in picture"
                      pointerEvents="all"
                      background="gray.800"
                      _hover={{ transform: "scale(1.1)" }}
                      icon={<Icon as={MdPictureInPicture} boxSize="5" />}
                      onClick={() =>
                        togglePictureInPicture(!isPictureInPictureActive)
                      }
                    />
                  </Tooltip>
                )}
                {isFullscreenSupported && (
                  <Tooltip label="Fullscreen">
                    <IconButton
                      aria-label="Toggle fullscreen"
                      pointerEvents="all"
                      background="gray.800"
                      _hover={{ transform: "scale(1.1)" }}
                      icon={<Icon as={MdFullscreen} boxSize="5" />}
                      onClick={() => toggleFullscreen?.()}
                    />
                  </Tooltip>
                )}
              </ButtonGroup>
            </Flex>
          )}
          {renderControls?.()}
        </Flex>
      </>
    );
  }
);

export default Video;
