import { useState } from "react";

import { pluck } from "../../../utils/array";
import { getDayAbbreviation } from "../../../utils/datetime";
import {
  PrimaryCalendarEventsQuery,
  useCreateEventImportKeywordMutation,
  useCurrentUserEventImportKeywordsQuery,
  useDeleteEventImportKeywordMutation,
  usePrimaryCalendarEventsQuery,
} from "../../graphql";

type Event = PrimaryCalendarEventsQuery["primaryCalendarEvents"][0];

/** Represents the calendar events of one month */
export type EventListItem = {
  month: number;
  days: {
    day: number;
    dayOfWeek: string;
    events: Event[];
  }[];
};

type UseEventImportKeywordParams = {
  skip?: boolean;
};

type UseEventImportKeywordReturn = {
  keywords: string[];
  onAddKeyword(keyword: string): void;
  onKeywordsChange(keywords: string[]): void;
  keywordError?: string;
  eventList: EventListItem[];
};

export const useEventImportKeyword = (
  params?: UseEventImportKeywordParams
): UseEventImportKeywordReturn => {
  const skipQueries = !!params?.skip;

  const [eventList, setEventList] = useState<EventListItem[]>([]);
  const calendarEventsQuery = usePrimaryCalendarEventsQuery({
    skip: skipQueries,
    pollInterval: 5000,
    onCompleted: (data) => {
      setEventList(buildEventList(data.primaryCalendarEvents));
      if (data.primaryCalendarEvents.length > 0) {
        calendarEventsQuery.stopPolling();
      }
    },
  });

  const [keywords, setKeywords] = useState<string[]>([]);
  useCurrentUserEventImportKeywordsQuery({
    skip: skipQueries,
    onCompleted: (data) => {
      const savedKeywords = data.currentUser?.eventImportKeywords ?? [];
      setKeywords(pluck(savedKeywords, "keywordText"));
    },
  });

  const [createKeyword, { error: createKeywordError }] =
    useCreateEventImportKeywordMutation();
  const [deleteKeywords] = useDeleteEventImportKeywordMutation();

  return {
    keywords,
    eventList,
    onAddKeyword: (keyword) => {
      if (keywords.includes(keyword)) return;

      const currentKeywords = keywords;
      createKeyword({
        variables: { keywords: [keyword] },
        onError: () => {
          setKeywords(currentKeywords);
          checkEventList({
            eventList,
            keywords: currentKeywords,
            deletedKeywords: [keyword],
          });
        },
      });

      const newKeywords = [...currentKeywords, keyword];
      setKeywords(newKeywords);
      setEventList(
        checkEventList({
          eventList,
          keywords: newKeywords,
          deletedKeywords: [],
        })
      );
    },
    onKeywordsChange: (newKeywords) => {
      setKeywords(newKeywords);
      const deletedKeywords = keywords.filter((k) => !newKeywords.includes(k));
      setEventList(
        checkEventList({ eventList, keywords: newKeywords, deletedKeywords })
      );
      if (deletedKeywords.length > 0) {
        deleteKeywords({ variables: { keywords: deletedKeywords } });
      }
    },
    keywordError: createKeywordError?.message,
  };
};

/** Groups calendar events from graphQL by month and day */
export const buildEventList = (calendarEvents: Event[]): EventListItem[] => {
  const result: EventListItem[] = [];
  let currentMonth = -1;
  let currentDay = -1;

  calendarEvents.forEach((calendarEvent) => {
    const eventDate = new Date(calendarEvent.start);

    const month = eventDate.getMonth();
    if (month !== currentMonth) {
      // Begin a new month in the list
      result.push({ month, days: [] });
      currentMonth = month;
      currentDay = -1;
    }

    const { days } = result[result.length - 1];
    const day = eventDate.getDate();
    if (day !== currentDay) {
      // Begin a new day for this month
      const dayOfWeek = getDayAbbreviation(eventDate);
      days.push({ day, dayOfWeek, events: [] });
      currentDay = day;
    }

    const lastDay = days[days.length - 1];
    lastDay.events.push(calendarEvent);
  });

  return result;
};

type CheckEventKeywordsParams = {
  eventList: EventListItem[];
  keywords: string[];
  deletedKeywords: string[];
};

/** Checks `importExcludeDecision` of each event based on current keywords */
const checkEventList = ({
  eventList,
  keywords,
  deletedKeywords,
}: CheckEventKeywordsParams): EventListItem[] =>
  eventList.map(({ days, ...event }) => ({
    ...event,
    days: days.map(({ events, ...day }) => ({
      ...day,
      events: events.map((event) => {
        if (event.importExcludeDecision === false || !event.summary)
          return event;

        const eventSummary = event.summary.trim().toLowerCase();
        const matchesKeyword = (keywords: string[]): boolean =>
          keywords.some((k) => eventSummary.includes(k.trim().toLowerCase()));
        const matchesExisting = matchesKeyword(keywords);
        const matchesDeleted = matchesKeyword(deletedKeywords);

        let importExcludeDecision = event.importExcludeDecision ?? undefined;
        if (matchesDeleted) importExcludeDecision = undefined;
        if (matchesExisting) importExcludeDecision = true;

        return { ...event, importExcludeDecision };
      }),
    })),
  }));
