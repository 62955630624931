import { Box, Flex } from "@chakra-ui/react";
import React from "react";

import { SegmentedButton } from "../../../components";

type CandidateAlertFilterProps = {
  title: string;
  options: string[];
  defaultOptions?: string[];
  onChange?(options: string[]): void;
  onChange?(options: string): void;
  allowEmpty?: boolean;
  allowMultiple?: boolean;
};

export const CandidateAlertFilter: React.FC<CandidateAlertFilterProps> = ({
  title,
  options,
  defaultOptions,
  onChange,
  allowEmpty,
  allowMultiple,
}) => {
  const buttons = options.map((label) => ({ label }));

  return (
    <Flex direction="column" gap="1">
      <Box fontSize="xs" color="gray.600" lineHeight="4">
        {title}
      </Box>
      {allowMultiple && (
        <SegmentedButton
          buttons={buttons}
          variant="outline"
          onChange={onChange}
          defaultButton={defaultOptions}
          allowEmpty={allowEmpty}
          allowMultiple
        />
      )}
      {!allowMultiple && (
        <SegmentedButton
          buttons={buttons}
          variant="outline"
          onChange={onChange}
          defaultButton={defaultOptions?.[0]}
          allowEmpty={allowEmpty}
        />
      )}
    </Flex>
  );
};
