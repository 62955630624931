import React from "react";
import { Navigate, useParams } from "react-router-dom";

import { Alert, LoadingIndicator } from "../../../../components";
import {
  useAnalyticsUserLifetimeValuesQuery,
  UserRoleName,
  useUserQuery,
} from "../../../graphql";
import { useFeatureFlagForCurrentOrImpersonatedUser } from "../../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../../hooks/useCurrentUser";
import MyAnalytics from "./MyAnalytics";

const AnalyticsMyAnalytics: React.FC = () => {
  const myInsightsFlagEnabled = useFeatureFlagForCurrentOrImpersonatedUser(
    "analytics:my-insights"
  );
  const { interviewerId } = useParams() as { interviewerId: string };
  const userQuery = useUserQuery({
    variables: { id: interviewerId },
  });

  const lifetimeValuesQuery = useAnalyticsUserLifetimeValuesQuery({
    variables: { userId: interviewerId },
  });

  const currentUser = useCurrentUser();
  // TODO: Confirm access requirements
  const isSiteAdmin = currentUser.userRole?.name === UserRoleName.SiteAdmin;
  const currentUserIsInterviewer = currentUser.id === interviewerId;
  const canViewInsights = isSiteAdmin || currentUserIsInterviewer;

  if (!canViewInsights || !myInsightsFlagEnabled) {
    return <Navigate to="/" replace />;
  }

  if (userQuery.loading) {
    return <LoadingIndicator />;
  }

  if (userQuery.error) {
    return (
      <Alert
        status="error"
        description="There was an error retrieving user data"
      />
    );
  }

  const user = userQuery.data?.user;
  if (!user) return null;

  return (
    <MyAnalytics
      interviewerId={interviewerId}
      interviewer={user}
      lifetimeValuesLoading={lifetimeValuesQuery.loading}
      lifetimeValues={lifetimeValuesQuery.data?.userInterviewTotals.values}
    />
  );
};

export default AnalyticsMyAnalytics;
