import {
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import React from "react";
import { HiOutlineSearch, HiOutlineX } from "react-icons/hi";

import {
  Alert,
  Button,
  LoadingIndicator,
  PageContent,
  PageTitle,
} from "../../../components";
import useOffsetPagination from "../../../hooks/useOffsetPagination";
import { usePageTracker } from "../../../utils/googleAnalytics";
import useInterviewAssistant from "../../../utils/popup";
import { useCandidatesLazyQuery } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import DefaultLayout from "../../layouts/DefaultLayout";
import CandidatesList, { CandidateListItem } from "./CandidatesList";

const CandidatesPage: React.FC = () => {
  usePageTracker("candidates");
  const currentUser = useCurrentUser();
  const { scoringEnabled } = currentUser.organization;

  const handleNewCall = (): void => {
    openInterviewAssistant();
  };

  const [getCandidates, { loading, error }] = useCandidatesLazyQuery({
    variables: { includeScores: scoringEnabled },
    fetchPolicy: "network-only",
    onCompleted: ({ candidates }) => {
      if (candidates?.results) {
        setCandidates(candidates.results);
        setNumberOfPages(candidates.pageInfo?.totalPages || 0);
      }
    },
  });

  const {
    listItems: candidates,
    setListItems: setCandidates,
    setNumberOfPages,
    pageOptions,
    searchTerm,
    setSearchTerm,
    didFetchComplete,
  } = useOffsetPagination<CandidateListItem>(getCandidates, {
    initialSortBy: { id: "lastCall.createdAt", desc: true },
  });
  const { openInterviewAssistant } = useInterviewAssistant();

  return (
    <DefaultLayout>
      <PageContent>
        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
        >
          <PageTitle mb={{ base: 5, md: 7 }} titleText="Candidates" />
          <Flex direction={{ base: "column", sm: "row" }}>
            <InputGroup h="8">
              <InputLeftElement h="8" px="0" color="placeholder">
                <Icon as={HiOutlineSearch} boxSize="4" />
              </InputLeftElement>
              <Input
                value={searchTerm}
                fontSize="sm"
                h="8"
                pr="12"
                placeholder="Search Candidates"
                onChange={(event) => {
                  setSearchTerm(event.currentTarget.value);
                }}
              />
              <InputRightElement h="8" w="12">
                {didFetchComplete && loading ? (
                  <LoadingIndicator boxSize="4" delay={0} size="sm" />
                ) : null}
                {searchTerm && (
                  <Icon
                    as={HiOutlineX}
                    boxSize="4"
                    ml="auto"
                    mr="2"
                    onClick={() => setSearchTerm("")}
                  />
                )}
              </InputRightElement>
            </InputGroup>
            {currentUser.userRole?.canCreatePosition && (
              <Button
                size="sm"
                flexShrink={0}
                mt={[3, 0]}
                ml={[0, 3]}
                onClick={handleNewCall}
              >
                Call new candidate
              </Button>
            )}
          </Flex>
        </Flex>
        {error ? (
          <Alert
            status="error"
            title="Error loading candidates"
            description={error.message}
          />
        ) : (
          <CandidatesList
            candidates={candidates}
            pageOptions={pageOptions}
            loading={loading}
          />
        )}
      </PageContent>
    </DefaultLayout>
  );
};

export default CandidatesPage;
