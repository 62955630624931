import { ApolloError } from "@apollo/client";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Select,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

import { Alert, Button } from "../../../components";

export interface FormData {
  showJoinWithBrighthire: string;
}

interface BrowserExtensionSettingsFormProps {
  error: ApolloError | undefined;
  isLoading: boolean;
  onSubmit: (formData: FormData) => void;
  showJoinWithBrighthire: string;
}

const BrowserExtensionSettingsForm: React.FC<
  BrowserExtensionSettingsFormProps
> = ({ error, isLoading, onSubmit, showJoinWithBrighthire }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      showJoinWithBrighthire,
    },
  });

  return (
    <form
      autoComplete="on"
      onSubmit={handleSubmit((formData) => {
        onSubmit({
          ...formData,
        });
      })}
    >
      {error?.graphQLErrors?.map(({ message }, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Alert mb={6} key={i} status="error" description={message} />
      ))}
      <VStack spacing="8" align="start" maxW="400">
        <FormControl id="videoMeetingImportStrategy" mb={6}>
          <FormLabel>Calendar integration</FormLabel>
          <FormHelperText>
            This setting determines whether to show the Join with BrightHire
            button on your calendar events in Google and Outlook calendars.
            <ul style={{ paddingLeft: "20px" }}>
              <li>
                Show: show the Join with BrightHire button on your calendar
                events when specific keywords are present..
              </li>
              <li>
                Hide: never show the Join with BrightHire button on your
                calendar events.
              </li>
            </ul>
          </FormHelperText>
          <Select {...register("showJoinWithBrighthire")} mt={2}>
            <option key="SHOW" value="SHOW">
              Show
            </option>
            <option key="HIDE" value="HIDE">
              Hide
            </option>
          </Select>
          {errors.showJoinWithBrighthire !== undefined && (
            <FormErrorMessage>
              {errors.showJoinWithBrighthire.message}
            </FormErrorMessage>
          )}
        </FormControl>

        <Button type="submit" isLoading={isLoading} data-testid="save">
          Save
        </Button>
      </VStack>
    </form>
  );
};

export default BrowserExtensionSettingsForm;
