import { ApolloClient, ApolloProvider } from "@apollo/client";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import {
  CypressHistoryPatch,
  ThemedChakraProvider,
  TrackPages,
} from "./components";

interface Props {
  apolloClient: ApolloClient<any>;
  children: React.ReactNode;
  basename?: string;
}

const App: React.FC<Props> = ({ apolloClient, basename, children }) => {
  return (
    <ApolloProvider client={apolloClient}>
      <Router basename={basename}>
        <TrackPages />
        <CypressHistoryPatch />
        <ThemedChakraProvider>{children}</ThemedChakraProvider>
      </Router>
    </ApolloProvider>
  );
};

export default App;
