import { createIcon, forwardRef } from "@chakra-ui/react";
import React from "react";

const BHShareIcon = forwardRef((props, ref) => {
  const { pathProps, ...rest } = props;

  const Icon = createIcon({
    displayName: "BHShareIcon",
    viewBox: "0 0 18 16",
    path: (
      <path
        d="M16.5 7.5L9.5 1.5V5.533C5.719 5.533 1.5 7 1.5 13.5C3.469 10.312 6.5 9.5 9.5 9.5V13.5L16.5 7.5Z"
        stroke="currentColor"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        {...pathProps}
      />
    ),
  });

  return <Icon ref={ref} {...rest} />;
});

export default BHShareIcon;
