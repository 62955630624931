import { Box, Divider, Flex, Text, VStack } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { Button, useToast } from "../../../../../components";
import ProgressStepper from "../../../../../components/ProgressStepper/ProgressStepper";
import { formatISODate } from "../../../../../utils/datetime";
import { usePageTracker } from "../../../../../utils/googleAnalytics";
import { canViewAdvancedInsights } from "../../../../../utils/permissions";
import {
  useCreateSkillReportMutation,
  useRunSkillReportMutation,
} from "../../../../graphql";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import SkillsReportHeader from "../SkillsReportHeader";
import ReportBuilderFormControls from "./ReportBuilderFormControls";
import SkillsBuilderFormControls from "./SkillsBuilderFormControls";
import useSkillsReportBuilder from "./useSkillsReportBuilder";
import { MAX_SKILL_COUNT } from "./utils";

const AnalyticsSkillsReportBuilder: React.FC = () => {
  usePageTracker("skill_report_builder");
  const navigate = useNavigate();
  const toast = useToast();

  const currentUser = useCurrentUser();
  const [builderStep, setBuilderStep] = useState(0);

  const {
    selectedPositions,
    setSelectedPositions,
    candidateFilter,
    setCandidateFilter,
    interviewStages,
    setInterviewStages,
    minInterviewCount,
    setMinInterviewCount,
    dateRange,
    setDateRange,
    title,
    setTitle,
    skills,
    setSkills,
    source,
    setSource,
  } = useSkillsReportBuilder();

  const positionIds = selectedPositions?.map((position) => position.id) || [];

  const [createSkillsReport] = useCreateSkillReportMutation();
  const [runSkillsReport] = useRunSkillReportMutation();

  const generateReport = useCallback(() => {
    const interviewStageFilters = JSON.stringify(
      interviewStages.map((stage) => ({
        label: stage.label,
        value: stage.value,
      }))
    );
    const validSkills = skills.filter((skill) => skill.name.trim() !== "");
    let minimumInterviewCountFilter = minInterviewCount;
    if (candidateFilter !== "minInterviews") {
      minimumInterviewCountFilter = 1;
    }
    createSkillsReport({
      variables: {
        positionIds: selectedPositions.map((position) => position.id),
        dateRangeStart: formatISODate(dateRange.start),
        dateRangeEnd: formatISODate(dateRange.end),
        skills: validSkills.map((skill) => ({
          name: skill.name,
          description: skill.description,
        })),
        interviewStageFilters,
        minimumInterviewCountFilter,
        source,
        title: title || "",
      },
      update: (cache, { data }) => {
        const newReportId = data?.createSkillReport?.skillReport.id;
        if (!newReportId) return;
        cache.modify({
          fields: {
            skillReports(existingReports = []) {
              return [
                ...existingReports,
                { __typename: "SkillReport", id: newReportId },
              ];
            },
          },
        });
      },
      onCompleted: (data) => {
        const newReportId = data.createSkillReport?.skillReport.id;
        if (newReportId) {
          runSkillsReport({
            variables: {
              reportId: newReportId,
            },
          });
          navigate(`/insights/skill-report/${newReportId}`);
        }
      },
      onError: (error) => {
        toast({
          title: `Error`,
          description: error.message,
          status: "error",
        });
      },
    });
  }, [
    title,
    dateRange,
    selectedPositions,
    interviewStages,
    minInterviewCount,
    candidateFilter,
    source,
    skills,
  ]);

  if (!canViewAdvancedInsights(currentUser)) {
    return <Navigate to="/" replace />;
  }

  let stepOneValid =
    !!positionIds?.length && !!candidateFilter && (title || "").trim() !== "";
  if (candidateFilter === "interviewStage") {
    stepOneValid = stepOneValid && interviewStages.length > 0;
  }

  const hasAnyEmpty = skills.some((item) => item.name.trim() === "");
  const stepTwoValid =
    skills.length > 0 && skills.length < MAX_SKILL_COUNT && !hasAnyEmpty;

  return (
    <Flex
      flexDir="column"
      minH="100vh"
      bg="rgba(35, 152, 251, 0.03)"
      pb="100px"
    >
      <SkillsReportHeader />
      <Box py="8" margin="0 auto">
        <ProgressStepper
          steps={["Basics", "Skills", "Done"]}
          activeStepIndex={builderStep || 0}
          stepBgColor="#F5F9FD"
        />
      </Box>
      {builderStep === 0 && (
        <Flex>
          <StepWrapper>
            <Text fontSize="2xl" color="gray.900" fontWeight="600">
              Report builder
            </Text>
            <Divider my="6" borderColor="gray.200" />
            <VStack spacing="6">
              <Text color="gray.800" fontWeight="400">
                The{" "}
                <Text as="span" fontWeight="600">
                  Skills Coverage
                </Text>{" "}
                report lets you define skills and see how consistently your team
                is covering them across candidates for one or more positions.
              </Text>
              <ReportBuilderFormControls
                selectedPositions={selectedPositions}
                setSelectedPositions={setSelectedPositions}
                candidateFilter={candidateFilter}
                setCandidateFilter={setCandidateFilter}
                interviewStages={interviewStages}
                setInterviewStages={setInterviewStages}
                minInterviewCount={minInterviewCount}
                setMinInterviewCount={setMinInterviewCount}
                dateRange={dateRange}
                setDateRange={setDateRange}
                title={title}
                setTitle={setTitle}
                isVertical
              />
            </VStack>
            <Divider pt="10" borderColor="gray.200" />
            <Flex flexDir="row" justifyContent="space-between" mt="6">
              <Button
                ml="auto"
                variant="solid"
                size="sm"
                fontWeight="500"
                onClick={() => setBuilderStep(1)}
                disabled={!stepOneValid}
              >
                Next
              </Button>
            </Flex>
          </StepWrapper>
        </Flex>
      )}
      {builderStep === 1 && !!positionIds.length && (
        <StepWrapper>
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text fontSize="2xl" color="gray.900" fontWeight="600">
              Report builder
            </Text>
            <Button
              variant="solid"
              size="sm"
              fontWeight="500"
              disabled={!stepTwoValid}
              onClick={generateReport}
            >
              Run report
            </Button>
          </Flex>
          <Divider my="6" borderColor="gray.200" />
          <SkillsBuilderFormControls
            selectedPositions={selectedPositions}
            skills={skills}
            setSkills={setSkills}
            source={source}
            setSource={setSource}
          />
          <Divider borderColor="gray.200" />
          <Flex flexDir="row" justifyContent="space-between" mt="6">
            <Button
              variant="ghost"
              fontWeight="500"
              size="sm"
              onClick={() => setBuilderStep(0)}
            >
              Back
            </Button>
            <Button
              variant="solid"
              size="sm"
              fontWeight="500"
              onClick={generateReport}
              disabled={!stepTwoValid}
            >
              Run report
            </Button>
          </Flex>
        </StepWrapper>
      )}
    </Flex>
  );
};

const StepWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <Flex
    flexDir="column"
    bg="white"
    borderRadius="12px"
    borderColor="gray.100"
    borderWidth="1px"
    px="8"
    pt="6"
    pb="8"
    width="875px"
    margin="0 auto"
    boxShadow="0px 1px 2px 0px #0000000F, 0px 1px 3px 0px #0000001A"
  >
    {children}
  </Flex>
);

export default AnalyticsSkillsReportBuilder;
