import { GAEventDetails } from "../../utils/googleAnalytics";

type SendEventOnClickParams = {
  el: HTMLElement | null;
  componentType: "button" | "menu_button" | "icon_button" | "link";
  providedParams: GAEventDetails | undefined;
};

export const getEventValues = ({
  el,
  componentType,
  providedParams,
}: SendEventOnClickParams): GAEventDetails => {
  const defaults: GAEventDetails = {
    action: "click",
    label: el?.getAttribute("data-testid") ?? el?.innerText,
    category: "user_interaction",
    value: componentType,
  };

  return {
    ...defaults,
    ...providedParams,
  };
};
