import {
  Box,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  Select,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import {
  Button,
  Link,
  RouterLink,
  successToast,
  useToast,
} from "../../../../components";
import { isCustomError } from "../../../../utils/apollo/error";
import {
  OrgScorecardAutoDraftOnboardingRule,
  OrgScorecardAutoDraftRule,
  useUpdateOrganizationMutation,
} from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import SettingsNote from "../shared/SettingsNote";

export const ScorecardAutoDraftSettings: React.FC = () => {
  const toast = useToast();

  const currentUser = useCurrentUser();

  const [updateOrg, { loading }] = useUpdateOrganizationMutation({
    onCompleted: () =>
      successToast(toast, "Scorecard auto draft settings saved"),
    onError: (e) => {
      setError("greenhouseScorecardAutoDraftRule", {
        message: isCustomError(e)
          ? e.message
          : "There was a problem saving scorecard auto draft settings",
      });
    },
  });

  const {
    greenhouseScorecardAutoDraftRule,
    greenhouseScorecardAutoDraftOnboardingRule,
  } = currentUser.organization;

  const {
    setError,
    watch,
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      greenhouseScorecardAutoDraftRule,
      greenhouseScorecardAutoDraftOnboardingRule,
    },
  });

  const autoDraftRuleValue = watch("greenhouseScorecardAutoDraftRule");
  const autoDraftOnboardingRuleDisabled = [
    OrgScorecardAutoDraftRule.Disabled,
    OrgScorecardAutoDraftRule.ForcedEnabled,
  ].includes(autoDraftRuleValue);

  return (
    <Box
      as="form"
      onSubmit={handleSubmit((autoDraftSettings) =>
        updateOrg({ variables: autoDraftSettings })
      )}
    >
      <Divider mt="8" mb="6" />
      <Text fontSize="lg" fontWeight="semibold" mb="2">
        Auto draft scorecards
      </Text>
      <Text mb="4" fontSize="sm">
        BrightHire can automatically draft Greenhouse scorecards with interview
        notes after an interview is complete. Interviewers will always have the
        opportunity to review and edit their feedback before submitting.{" "}
        <Link
          href="https://help.brighthire.ai/en/articles/10000464-greenhouse-scorecard-auto-draft"
          target="_blank"
        >
          Learn more
        </Link>
      </Text>
      <Flex direction="column" gap="8">
        <FormControl id="greenhouseScorecardAutoDraftRule">
          <Select
            {...register("greenhouseScorecardAutoDraftRule")}
            defaultValue={greenhouseScorecardAutoDraftRule}
            isDisabled={loading}
          >
            <option value={OrgScorecardAutoDraftRule.Disabled}>Disabled</option>
            <option value={OrgScorecardAutoDraftRule.DefaultDisabled}>
              Enabled, default to off
            </option>
            <option value={OrgScorecardAutoDraftRule.DefaultEnabled}>
              Enabled, default to on
            </option>
            <option value={OrgScorecardAutoDraftRule.ForcedEnabled}>
              Force enabled for all users
            </option>
          </Select>
          {errors.greenhouseScorecardAutoDraftRule ? (
            <SettingsNote mt="4" variant="error">
              {errors.greenhouseScorecardAutoDraftRule.message}
            </SettingsNote>
          ) : loading ? (
            <Flex mt="8" mb="4" justify="center">
              <Spinner />
            </Flex>
          ) : (
            <SettingsNote mt="4">
              {autoDraftRuleValue === OrgScorecardAutoDraftRule.Disabled && (
                <>
                  This disables auto draft for all users. Users will not see
                  this feature in their settings page.
                </>
              )}
              {autoDraftRuleValue ===
                OrgScorecardAutoDraftRule.DefaultDisabled && (
                <>
                  This disables auto draft for all users by default. Users can
                  update their preference at any time in their{" "}
                  <RouterLink to="/settings/user/scorecard" target="_blank">
                    settings page
                  </RouterLink>
                  . Any user that has previously set their preference will have
                  their chosen setting preserved.
                </>
              )}
              {autoDraftRuleValue ===
                OrgScorecardAutoDraftRule.DefaultEnabled && (
                <>
                  This enables auto draft for all users by default. Users can
                  update their preference at any time in their{" "}
                  <RouterLink to="/settings/user/scorecard" target="_blank">
                    settings page
                  </RouterLink>
                  . Any user that has previously set their preference will have
                  their chosen setting preserved.
                </>
              )}
              {autoDraftRuleValue ===
                OrgScorecardAutoDraftRule.ForcedEnabled && (
                <>
                  This enables auto draft for all users. Users cannot change
                  this behavior in their settings.
                </>
              )}
            </SettingsNote>
          )}
        </FormControl>
        <FormControl
          id="greenhouseScorecardAutoDraftOnboardingRule"
          hidden={autoDraftOnboardingRuleDisabled}
        >
          <Controller
            control={control}
            name="greenhouseScorecardAutoDraftOnboardingRule"
            render={({ field }) => (
              <Checkbox
                isChecked={
                  field.value === OrgScorecardAutoDraftOnboardingRule.Show
                }
                onChange={(e) =>
                  field.onChange(
                    e.target.checked
                      ? OrgScorecardAutoDraftOnboardingRule.Show
                      : OrgScorecardAutoDraftOnboardingRule.Hide
                  )
                }
              >
                Prompt users to set preferences
              </Checkbox>
            )}
          />
          <FormHelperText>
            Current users will be prompted to choose their auto draft preference
            the next time they log in to BrightHire. New users will be prompted
            during account registration.
          </FormHelperText>
        </FormControl>
      </Flex>
      <Button type="submit" mt="8" isLoading={loading}>
        Save auto draft settings
      </Button>
    </Box>
  );
};
