import { Menu, MenuItem, MenuList, SystemProps, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { HiDotsVertical } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import { IconButton, MenuButton } from "../../../components";
import { CallListItemFragment, Maybe } from "../../graphql";
import CopyAiNotesButton from "../Candidate/CopyAiNotesButton";
import { InterviewMenuOptions } from "../InterviewsList";
import DeleteInterviewButton from "./DeleteInterviewButton";
import ShareCallButton from "./ShareCallButton";

type InterviewMenuProps = {
  call: Pick<
    CallListItemFragment,
    "id" | "canDelete" | "visibility" | "visibleVisibilityLevels"
  >;
  positionId?: Maybe<string> | undefined;
  listPosition: number;
  menuOptions?: InterviewMenuOptions[];
  buttonSize?: string;
  menuStyle?: SystemProps;
  placeMenuInside?: boolean;
} & SystemProps;

const InterviewMenu: React.FC<InterviewMenuProps> = ({
  call,
  positionId,
  listPosition = 1,
  menuOptions = [],
  buttonSize,
  ml,
  menuStyle,
  placeMenuInside,
  ...styles
}) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const navigate = useNavigate();

  if (menuOptions.length === 0) return null;

  return (
    <Menu
      autoSelect={false}
      variant="new"
      placement={placeMenuInside ? "left-start" : undefined}
      computePositionOnMount={placeMenuInside}
    >
      <MenuButton
        {...styles}
        as={IconButton}
        variant="icon"
        size={buttonSize || "md"}
        ml={ml ?? 2}
        icon={<HiDotsVertical size={20} />}
        onClick={(e) => e.stopPropagation()}
        _focus={{}}
        data-testid={`completed-interviews-item-${listPosition}-menu-button`}
      />
      <MenuList {...menuStyle}>
        {menuOptions.map((option) => {
          switch (option) {
            case InterviewMenuOptions.VIEW:
              return (
                <MenuItem
                  key={`call-${call.id}-menu-option-${option}`}
                  data-testid={`completed-interviews-item-${listPosition}-view`}
                >
                  <Text onClick={() => navigate(`/interview/${call.id}`)}>
                    View Interview
                  </Text>
                </MenuItem>
              );
            case InterviewMenuOptions.DELETE:
              if (call.canDelete) {
                return (
                  <MenuItem
                    key={`call-${call.id}-menu-option-${option}`}
                    data-testid={`completed-interviews-item-${listPosition}-delete`}
                  >
                    <DeleteInterviewButton call={call} buttonStyle="menuItem" />
                  </MenuItem>
                );
              }
              return (
                <MenuItem
                  key={`call-${call.id}-menu-option-${option}`}
                  data-testid={`completed-interviews-item-${listPosition}-delete`}
                  isDisabled
                >
                  <DeleteInterviewButton call={call} buttonStyle="menuItem" />
                </MenuItem>
              );
            case InterviewMenuOptions.SHARE:
              return (
                <MenuItem
                  key={`call-${call.id}-menu-option-${option}`}
                  data-testid={`completed-interviews-item-${listPosition}-share`}
                >
                  <ShareCallButton
                    callId={call.id}
                    positionId={positionId}
                    callVisibility={call.visibility}
                    visibilityLevels={call.visibleVisibilityLevels}
                    readOnly={false}
                    buttonStyle="menuItem"
                    isModalOpen={isShareModalOpen}
                    onCloseModal={() => setIsShareModalOpen(false)}
                    onOpenModal={() => setIsShareModalOpen(true)}
                  />
                </MenuItem>
              );
            case InterviewMenuOptions.COPY_AI_NOTES:
              return (
                <MenuItem
                  key={`call-${call.id}-menu-option-${option}`}
                  data-testid={`completed-interviews-item-${listPosition}-copy-ai-notes`}
                >
                  <CopyAiNotesButton call={call} variant="menuItem" />
                </MenuItem>
              );
            default:
              return null;
          }
        })}
      </MenuList>
    </Menu>
  );
};

export default InterviewMenu;
