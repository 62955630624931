import { useState } from "react";

import { CATEGORIES, useSendGAEvent } from "../../utils/googleAnalytics";

const useTrackOnce = (action: string, category?: CATEGORIES): (() => void) => {
  const sendGAEvent = useSendGAEvent();
  const [triggered, setTriggered] = useState(false);

  return () => {
    if (triggered) {
      return;
    }
    setTriggered(true);
    sendGAEvent(action, category);
  };
};

export default useTrackOnce;
