import {
  ToastId,
  useToast as useToastChakra,
  UseToastOptions,
} from "@chakra-ui/react";
import { ReactNode } from "react";

const defaults = {
  duration: 3000,
  position: "top",
  variant: "solid",
  isCloseable: true,
} as const;

interface toasty {
  (options: UseToastOptions): string | number | undefined;
  close: (id: ToastId) => void;
  closeAll: () => void;
  update(id: ToastId, options: Omit<UseToastOptions, "id">): void;
  isActive: (id: ToastId) => boolean | undefined;
}

const useToast = (): toasty => {
  const toast = useToastChakra();
  const toastWrapper = (
    options: UseToastOptions
  ): string | number | undefined => toast({ ...defaults, ...options });
  toastWrapper.close = toast.close;
  toastWrapper.closeAll = toast.closeAll;
  toastWrapper.update = toast.update;
  toastWrapper.isActive = toast.isActive;
  return toastWrapper;
};

export const errorToast = (
  toast: toasty,
  description: ReactNode,
  options?: Omit<UseToastOptions, "description" | "status">
): void => {
  toast({ title: "Error", description, status: "error", ...options });
};

export const successToast = (
  toast: toasty,
  description: ReactNode,
  options?: Omit<UseToastOptions, "description" | "status">
): void => {
  toast({ title: "Success", description, status: "success", ...options });
};

export default useToast;
