import * as Sentry from "@sentry/browser";
import { useEffect } from "react";

import {
  Ats,
  ExtensionScorecardItem,
  NotesForScorecardQuery,
} from "../main/graphql";
import { useSendGAEvent } from "./googleAnalytics";
import {
  AlertConfig,
  FillScorecardParams,
  FillScorecardResponse,
  getScorecardData,
  isSupportedAts as isSupportedAtsForFillScorecard,
} from "./oneClickScorecard";
import { formatNotes } from "./oneClickScorecard.deprecated";
import {
  initMessagePort,
  Message,
  MessageAction,
  MessagePayload,
  MessageResponse,
  sendExtensionMessage,
} from "./sendExtensionMessage";

export const useGoogleAnalytics = (googleAnalyticsEnabled: boolean): void => {
  const sendGAEvent = useSendGAEvent();
  const onGaEvent = (message: Message): void => {
    if (message.action === "gaEvent" && message.payload?.gaEvent) {
      const { action, eventCategory, eventLabel, value, ...params } =
        message.payload.gaEvent;
      if (!action) return;
      sendGAEvent(action, eventCategory, eventLabel, value, params);
    }
  };
  const initGoogleAnalytics = (): void => {
    initMessagePort()
      .then((messagePort) => {
        if (!messagePort.onMessage.hasListener(onGaEvent)) {
          messagePort.onMessage.addListener(onGaEvent);
        }
      })
      .catch((err) => Sentry.captureException(err));
  };
  useEffect(() => {
    if (googleAnalyticsEnabled) {
      initGoogleAnalytics();
    }
  }, [googleAnalyticsEnabled]);
};

export const getVersion = async (): Promise<string | undefined> => {
  try {
    const response = await sendExtensionMessage({ action: "getVersion" });
    return response?.version;
  } catch (e) {
    return undefined;
  }
};

const openWithAutofill = (
  url: string,
  autofill: boolean
): Promise<MessageResponse> => {
  if (autofill) window.localStorage.setItem("bh_autofill", "true");

  return openUrl(url).catch((err) => {
    window.localStorage.removeItem("bh_autofill");
    throw err;
  });
};

export const openFeedbackForm = (
  feedbackFormUrl: string,
  autofill = false
): Promise<MessageResponse> => {
  return openWithAutofill(feedbackFormUrl, autofill);
};

export const openUrl = (url: string): Promise<MessageResponse> =>
  sendExtensionMessage({ action: "openUrl", payload: { url } });

export const openIA = (
  url: string,
  windowAvailHeight: number
): Promise<MessageResponse> =>
  sendExtensionMessage({
    action: "openIA",
    payload: { url, windowAvailHeight },
  });

export const showModal = (tabId: number): Promise<MessageResponse> =>
  sendExtensionMessage({ action: "showModal", payload: { tabId } });

export const hideModal = (
  tabId: number,
  params: {
    jobDescription?: { text?: string; html?: string };
    planQuestions?: { text: string }[];
    text?: string;
  } = {}
): Promise<MessageResponse> =>
  sendExtensionMessage({
    action: "hideModal",
    payload: { tabId, ...params },
  });

export const toggleDrawer = (tabId: number): Promise<MessageResponse> =>
  sendExtensionMessage({ action: "toggleDrawer", payload: { tabId } });

export const openDrawerToast = (tabId: number): Promise<MessageResponse> =>
  sendExtensionMessage({ action: "openDrawerToast", payload: { tabId } });

export const disableDrawerToast = (tabId: number): Promise<MessageResponse> =>
  sendExtensionMessage({
    action: "disableDrawerToast",
    payload: { tabId },
  });

export const enableDrawerToast = (tabId: number): Promise<MessageResponse> =>
  sendExtensionMessage({ action: "enableDrawerToast", payload: { tabId } });

export const closeDrawerToast = (tabId: number): Promise<MessageResponse> =>
  sendExtensionMessage({ action: "closeDrawerToast", payload: { tabId } });

export const loadingComplete = (tabId: number): Promise<MessageResponse> =>
  sendExtensionMessage({
    action: "loadingComplete",
    payload: { tabId },
  });

export const showAlert = (
  tabId: number,
  alert: AlertConfig
): Promise<MessageResponse> =>
  sendExtensionMessage({
    action: "showAlert",
    payload: { tabId, alert },
  });

export const clearAlert = (tabId: number): Promise<MessageResponse> =>
  sendExtensionMessage({
    action: "clearAlert",
    payload: { tabId },
  });

export const setPostFillAlert = (
  tabId: number,
  alert: AlertConfig
): Promise<MessageResponse> =>
  sendExtensionMessage({
    action: "setPostFillAlert",
    payload: { tabId, alert },
  });

export const getScorecardItems = async (
  tabId: number
): Promise<ExtensionScorecardItem[] | null> => {
  const result = await sendExtensionMessage({
    action: "getScorecardItems",
    payload: { tabId },
  });
  return result?.scorecardItems ?? null;
};

export const fillScorecard = async (
  tabId: number,
  params: Omit<FillScorecardParams, "autofill">
): Promise<FillScorecardResponse> => {
  const { ats } = params;
  if (!isSupportedAtsForFillScorecard(ats))
    throw Error(`ATS "${ats}" not supported for fill scorecard`);

  const { localStorage: ls } = window;

  const autofill = ls.getItem("bh_autofill") === "true";

  ls.removeItem("bh_autofill");
  ls.removeItem("bh_ats");

  const scorecardData = getScorecardData({
    ...params,
    autofill,
  });

  const result = await sendExtensionMessage({
    action: "fillScorecard",
    payload: { tabId, scorecardData },
  });

  return {
    ...result?.fillScorecardResult,
    scorecardData,
  } as FillScorecardResponse;
};

export const clearScorecard = async (
  tabId: number,
  ats: Ats,
  isLoading?: boolean
): Promise<MessageResponse> => {
  if (!isSupportedAtsForFillScorecard(ats)) return;

  return sendExtensionMessage({
    action: "fillScorecard",
    payload: {
      tabId,
      scorecardData: getScorecardData({
        call: {
          id: "",
          generalNotes: [],
          questions: [],
          aiScorecardQuestionNotes: [],
          aiNotesLoading: false,
        },
        ats,
        autofill: false,
        showConfetti: false,
        latestFormatEnabled: true,
      }),
      isLoading,
    },
  });
};

/**
 * DEPRECATED, replaced by `fillScorecard` above
 */
export const sendCallNotesDeprecated = (
  tabId: number,
  callNotes: NotesForScorecardQuery["call"],
  ats: Ats,
  showConfetti: boolean,
  numCalls: number
): Promise<MessageResponse> => {
  const { localStorage: ls } = window;

  const autofill = ls.getItem("bh_autofill") === "true";

  return sendExtensionMessage({
    action: "sendCallNotes" as MessageAction,
    payload: {
      tabId,
      callNotes: formatNotes(callNotes, ats, autofill, showConfetti, numCalls),
    } as MessagePayload,
  }).then((r) => {
    ls.removeItem("bh_autofill");
    ls.removeItem("bh_ats");

    return r;
  });
};

export const setToastText = async (
  tabId: number,
  text: string,
  params?: Pick<MessagePayload, "toastNotification" | "toastHeight">
): Promise<void> => {
  await sendExtensionMessage({
    action: "setToastText",
    payload: { tabId, text, ...params },
  });
};

export const handleSignInRedirect = (params: URLSearchParams): void => {
  const tabId = Number(params.get("tabId"));
  if (!Number.isNaN(tabId)) {
    openDrawerToast(tabId);
    setToastText(tabId, "Please Sign In");
  }
};

/**
 * Returns information about the chrome extension context, if present.
 *
 * If there is a `tabId` parameter and the page is not in an iframe, this
 * will remove the parameter.
 */
export const getExtensionContext = (): {
  isExtension: boolean;
  tabId: number | null;
} => {
  const params = new URLSearchParams(window.location.search);
  const tabIdParam = params.get("tabId");
  let tabId = tabIdParam !== null ? Number(tabIdParam) : null;
  if (Number.isNaN(tabId)) {
    tabId = null;
  }

  const isInIframe = window.self !== window.top;
  const isExtension = isInIframe && tabId !== null;

  if (tabIdParam !== null && !isExtension) {
    params.delete("tabId");
    const url = new URL(window.location.href);
    url.search = params.toString();
    window.history.replaceState(window.history.state, "", url);
  }

  return { isExtension, tabId };
};
