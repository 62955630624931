/* eslint-disable camelcase */
// cspell:ignore pois
import React, { useState } from "react";
import { Configure } from "react-instantsearch-dom";

import { InstantSearch as InstantSearchBase } from "../../../components/Search";
import config from "../../../config";
import { useSearchApiKeyQuery } from "../../graphql";

interface InstantSearchProps {
  allowEmptyQuery?: boolean;
  children: React.ReactNode;
  updateUrl?: boolean;
  searchableAttributes?: string[];
}

const InstantSearch: React.FC<InstantSearchProps> = ({
  allowEmptyQuery = false,
  updateUrl = false,
  searchableAttributes,
  children,
}) => {
  const [searchApiKey, setSearchApiKey] = useState<string | undefined>();
  useSearchApiKeyQuery({
    onCompleted: (data) => {
      setSearchApiKey(data?.searchApiKey);
    },
  });

  if (!searchApiKey) {
    return null;
  }

  return (
    <InstantSearchBase
      searchApiKey={searchApiKey}
      allowEmptyQuery={allowEmptyQuery}
      updateUrl={updateUrl}
    >
      <Configure
        highlightPreTag="<mark>"
        highlightPostTag="</mark>"
        distinct={3}
        attributesToSnippet={["transcript_segments.text:40"]}
        snippetEllipsisText="…"
        restrictSearchableAttributes={
          searchableAttributes ?? [
            "name",
            "candidate.name",
            "position.display_title",
            "position.client_name",
            "interviewers.name",
            "candidate.phone_number",
            "notes.text",
            "transcript_segments.text",
            "cues",
            "stage",
            "pois",
          ]
        }
      />
      {children}
    </InstantSearchBase>
  );
};

interface InstantSearchTestProps {
  children: React.ReactNode;
}
const InstantSearchTest: React.FC<InstantSearchTestProps> = ({ children }) => (
  <>{children}</>
);

/**
 * Queries `searchApiKey` for current user and configures Algolia search params.
 * Renders `children` as-is
 */
export default config.appEnv === "test" ? InstantSearchTest : InstantSearch;
