import { Icon, IconProps, Tooltip, TooltipProps } from "@chakra-ui/react";
import React from "react";
import { HiOutlineInformationCircle } from "react-icons/hi2";

type Props = {
  tooltipProps?: TooltipProps;
  iconProps?: IconProps;
};

const SummaryTooltipIcon: React.FC<Props> = ({ tooltipProps, iconProps }) => {
  return (
    <Tooltip
      bg="white"
      p="2"
      borderRadius="md"
      border="1px solid"
      borderColor="gray.200"
      color="gray.800"
      boxShadow="none"
      fontSize="sm"
      shouldWrapChildren
      fontWeight="normal"
      placement="top"
      {...tooltipProps}
      label={
        <>
          This summary was generated using interviews visible to the hiring
          team. Topics are derived from the job description, when available.
          <br />
          <br />
          Only users with the required permissions settings can access this
          summary.
          <br />
          <br />
          As with any AI-generated content, always check for accuracy.
        </>
      }
    >
      <Icon
        mt="1px"
        boxSize={4}
        color="gray.700"
        as={HiOutlineInformationCircle}
        ml={1}
        {...iconProps}
      />
    </Tooltip>
  );
};
export default SummaryTooltipIcon;
