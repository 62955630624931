import invariant from "invariant";
import { createContext, useContext } from "react";

import { CurrentUserFragment } from "../graphql";

export const CurrentUserContext = createContext<CurrentUserFragment | null>(
  null
);

function useCurrentUser(): CurrentUserFragment {
  const currentUser = useContext(CurrentUserContext);
  invariant(
    currentUser,
    "missing currentUser in CurrentUserContext - is this component a descendant of AuthenticatedRoute?"
  );
  return currentUser;
}

export default useCurrentUser;

export const useOptionalCurrentUser = (): CurrentUserFragment | null => {
  return useContext(CurrentUserContext);
};
