import { useEffect, useRef } from "react";

import { useCustomTopicsQuery } from "../../../graphql";
import { TopicOption, TopicOptionGroup, TopicType } from "./types";
import { DEFAULT_TOPIC_OPTIONS, NEW_TOPIC_OPTION } from "./utils";

export type TopicOptionConfig = {
  previousOption?: TopicOption;
  currentOption?: TopicOption;
  options: TopicOption[];
  groups: TopicOptionGroup[];
  loading: boolean;
  refetchCustomTopics(): void;
};

/**
 * Loads and manages both static and custom topic options for the selector.
 */
const useTopicTrendsOptions = (topicValue: string): TopicOptionConfig => {
  const previousTopicRef = useRef<TopicOption>();

  const {
    data: customTopicData,
    loading,
    refetch: refetchCustomTopics,
  } = useCustomTopicsQuery();
  const customTopics =
    customTopicData?.currentUser?.organization.customTopics || [];

  let customTopicOptions: TopicOption[] = [];
  if (customTopics) {
    customTopicOptions = customTopics.map((topic) => ({
      label: topic.name,
      value: topic.id as string,
      type: "custom" as TopicType,
      keywords: JSON.parse(topic.keywords),
      matchRule: topic.matchRule,
    }));
  }

  const topicGroupOptions: TopicOptionGroup[] = [
    {
      label: "Custom Topics",
      options: [NEW_TOPIC_OPTION, ...customTopicOptions],
    },
    {
      label: "BrightHire Topics",
      options: DEFAULT_TOPIC_OPTIONS,
    },
  ];

  const allTopicOptions: TopicOption[] = [
    NEW_TOPIC_OPTION,
    ...customTopicOptions,
    ...DEFAULT_TOPIC_OPTIONS,
  ];

  useEffect(() => {
    const option = allTopicOptions.find((o) => o.value === topicValue);
    previousTopicRef.current = option;
  }, [topicValue, allTopicOptions.map((t) => t.value).join()]);

  // On first render, the current and previous options will be identical.
  // But only first render.
  const currentOption = allTopicOptions.find((o) => o.value === topicValue);
  const previousOption =
    previousTopicRef?.current?.value !== topicValue
      ? previousTopicRef.current
      : undefined;
  return {
    currentOption,
    previousOption,
    groups: topicGroupOptions,
    options: allTopicOptions,
    refetchCustomTopics,
    loading,
  };
};

export default useTopicTrendsOptions;
