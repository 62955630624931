import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";

export const useCandidateAlertDisabled = (): boolean => {
  const userEnabled = useFeatureFlag("enable:candidate:alert:user");
  const orgDisabled = useFeatureFlag("disable:candidate:alert");
  // User level enablement overrides org level disablement
  if (userEnabled === true) return false;
  if (orgDisabled === true) return true;
  return false;
};
