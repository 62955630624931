import { Flex, Icon, Text, Tooltip, VStack } from "@chakra-ui/react";
import React from "react";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { IoArrowRedoOutline } from "react-icons/io5";

import { Button, IconButton, useToast } from "../../../../components";
import { copy } from "../../../../utils/clipboard";
import { Match } from "./types";

const THRESHOLDS = {
  GREAT: 92.0,
  GOOD: 92.0,
};

const GreatComparisonTag: React.FC = () => {
  return (
    <Text
      as="span"
      fontSize="xs"
      color="green.700"
      backgroundColor="green.100"
      px={1.5}
      py={0.5}
      mb={1}
      fontWeight="400"
    >
      Great Comparison
    </Text>
  );
};

type MatchButtonProps = {
  match: Match;
  activeMatchID: string;
  onMatchSelect(id: string): void;
};

const MatchButton: React.FC<MatchButtonProps> = ({
  activeMatchID,
  match,
  onMatchSelect,
}) => {
  const isGreatComparison = match.similarity * 100 >= THRESHOLDS.GREAT;
  return (
    <Button
      variant="unstyled"
      size="xs"
      p={2}
      height="unset"
      onClick={() => onMatchSelect(match.id)}
      bg={activeMatchID === match.id ? "blue.100" : undefined}
      borderRadius="8px"
      _hover={{
        bg: "blue.50",
      }}
    >
      <Flex direction="column" alignItems="flex-start">
        {isGreatComparison && <GreatComparisonTag />}
        <Text
          as="span"
          color={activeMatchID === match.id ? "blue.800" : "gray.800"}
          fontSize="sm"
          fontWeight={activeMatchID === match.id ? "600" : "400"}
          whiteSpace="normal"
          textAlign="left"
          lineHeight="20px"
        >
          {match.chapter1.text}{" "}
          <Text as="span" color="gray.400">
            /
          </Text>{" "}
          {match.chapter2.text}
        </Text>
      </Flex>
    </Button>
  );
};

type CandidateCompareMatchesListProps = {
  filteredMatches: Match[];
  activeMatch: Match;
  onMatchSelect(id: string): void;
};

const CandidateCompareMatchesList: React.FC<
  CandidateCompareMatchesListProps
> = ({ filteredMatches, activeMatch, onMatchSelect }) => {
  const toast = useToast();
  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Flex>
            <Text fontWeight="600" color="gray.500" fontSize="xs" mr="2">
              {filteredMatches.length} matching highlight pairs
            </Text>
            <Tooltip label="Matching Highlight Pairs are determined by the content of AI notes from each candidate's interviews. Matching highlights are calculated based on the percentage match of the question asked to each candidate during the interview.">
              <Flex>
                <Icon
                  boxSize={4}
                  color="gray.500"
                  strokeWidth="1.5"
                  lineHeight="0"
                  as={HiOutlineInformationCircle}
                />
              </Flex>
            </Tooltip>
          </Flex>
          <Tooltip label="Share Comparison" openDelay={1000}>
            <IconButton
              icon={<IoArrowRedoOutline size="20" />}
              aria-label="Share Comparison"
              data-testid="share-button"
              color="blue.600"
              variant="white"
              minW="8"
              h="8"
              mr="2"
              onClick={() => {
                copy(window.location.href);
                toast({
                  title: "Comparison link copied to clipboard",
                  status: "success",
                });
              }}
            />
          </Tooltip>
        </Flex>
      </Flex>
      <VStack mt={2} spacing={1} alignItems="start">
        {filteredMatches.map((match) => (
          <MatchButton
            key={match.id}
            match={match}
            onMatchSelect={onMatchSelect}
            activeMatchID={activeMatch.id}
          />
        ))}
      </VStack>
    </>
  );
};

export default CandidateCompareMatchesList;
