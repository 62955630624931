import {
  Box,
  Flex,
  Icon,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import LogRocket from "logrocket";
import React, { MouseEvent } from "react";
import { IoLinkSharp, IoPinSharp } from "react-icons/io5";

import { Button, Link, useToast } from "../../../components";
import colors from "../../../theme/css-color-variables";
import { copy } from "../../../utils/clipboard";
import { formatDuration } from "../../../utils/datetime";
import { CallSpeakerFragment } from "../../graphql";
import { findMatchPosition, findTrimmedPosition } from "./segment";
import SpeakerMenu from "./SpeakerMenu";

export type TrackerMatchProps = {
  keyword: string;
  segment: {
    id: string;
    speakerTag: number;
    words: {
      word: string;
      startTime: number;
    }[];
  };
  seek: (time: number) => void;
  callId?: string;
  speakerMenuDisabled: boolean;
  speaker: Pick<CallSpeakerFragment, "label" | "identified" | "speakerTag">;
  clipId?: string;
};

export const TrackerMatch: React.FC<TrackerMatchProps> = ({
  segment,
  keyword,
  seek,
  callId,
  speakerMenuDisabled,
  speaker,
  clipId,
}) => {
  const toast = useToast();
  const {
    isOpen: isHovering,
    onOpen: onMouseEnter,
    onClose: onMouseLeave,
  } = useDisclosure();

  const matchPosition = findMatchPosition(segment.words, keyword);
  const trimmedPosition = findTrimmedPosition(segment.words, matchPosition);
  const trimmedWords = segment.words.slice(
    trimmedPosition.start,
    trimmedPosition.end + 1
  );

  // Unexpected, but prevents a larger error.
  if (trimmedWords.length === 0) {
    return null;
  }

  const timestamp = Math.floor(trimmedWords[0].startTime);

  const copyLink = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    const timestampedLink = `${window.location.protocol}//${window.location.host}${window.location.pathname}?t=${timestamp}`;
    copy(timestampedLink);
    toast({
      title: "Link copied to clipboard",
      status: "success",
    });
  };

  return (
    <Box
      px="3"
      py="4"
      mt="1"
      mb="1"
      cursor="pointer"
      borderRadius="4px"
      data-testid={`tracker-segment-${segment.id}`}
      _hover={{ background: "blue.50" }}
      onClick={() => {
        seek(timestamp);
        LogRocket.track("tracker-match-click");
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Flex mb="1" alignItems="center" height="21px">
        <Icon as={IoPinSharp} w="16px" h="16px" color="blue.300" />
        <Link
          pl="2"
          fontWeight="600"
          fontSize="14"
          display="inline"
          cursor="pointer"
          data-testid={`tracker-segment-link-${segment.id}`}
          _hover={{
            textDecoration: "underline",
            color: "blue.800",
          }}
        >
          {formatDuration(timestamp)}
        </Link>
        <Box
          pl="4"
          fontWeight="600"
          fontSize="14"
          display="inline-block"
          color={speaker.identified ? "gray.600" : "gray.500"}
          onClick={(e) => e.stopPropagation()}
          data-testid="tracker-speaker-menu"
        >
          <SpeakerMenu
            callId={callId}
            isDisabled={speakerMenuDisabled}
            speaker={speaker}
            clipId={clipId}
          />
        </Box>
        <Tooltip label="Link to this Moment">
          <Button
            ml="auto"
            leftIcon={<IoLinkSharp size="17" />}
            aria-label="Copy Link"
            color="blue.500"
            bg="transparent"
            height="6"
            size="sm"
            _hover={{
              bg: "blue.100",
            }}
            _active={{
              bg: "blue.200",
            }}
            onClick={copyLink}
            hidden={!isHovering}
          >
            Copy link
          </Button>
        </Tooltip>
      </Flex>
      <Box
        color="gray.700"
        display="inline"
        key={`${segment.id}`}
        data-testid={`tracker-segment-words-${segment.id}`}
      >
        {trimmedWords.map((word, trimmedWordIndex) => {
          const wordIndex = trimmedWordIndex + trimmedPosition.start;
          const isKeyword =
            wordIndex >= matchPosition.start && wordIndex <= matchPosition.end;
          // Question marks are excluded because there is a separate questions tracker
          // that only matches "?", which is unhelpful to visually highlight.
          const shouldUnderline = isKeyword && keyword !== "?";
          const isFinalKeyword = wordIndex === matchPosition.end;
          return (
            <Box display="inline" key={`${word.word}-${word.startTime}`}>
              {shouldUnderline && (
                <>
                  <Text
                    display="inline"
                    borderBottom={`2px solid ${colors.blue[300]}`}
                  >
                    {word.word}
                  </Text>
                  <Box
                    display="inline"
                    borderBottom={
                      isFinalKeyword ? "" : `2px solid ${colors.blue[300]}`
                    }
                  >
                    {" "}
                  </Box>
                </>
              )}
              {!shouldUnderline && <span>{word.word} </span>}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
