import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

export const GreenhouseIcon: React.FC<IconProps> = ({
  color,
  stroke,
  fill,
  ...props
}) => {
  return (
    <Icon
      width="2"
      height="4"
      viewBox="0 0 8 16"
      color={color || stroke || fill || "#23A47F"}
      {...props}
    >
      <path
        d="M6.83604 5.15932C6.83604 5.83521 6.55009 6.43311 6.08217 6.90103C5.56225 7.42095 4.80837 7.55092 4.80837 7.99285C4.80837 8.59075 5.77022 8.40878 6.69307 9.33163C7.30396 9.94253 7.6809 10.7484 7.6809 11.6842C7.6809 13.5299 6.19915 14.9987 4.34045 14.9987C2.48176 14.9987 1 13.5299 1 11.6855C1 10.7484 1.37694 9.94253 1.98784 9.33163C2.91069 8.40878 3.87253 8.59075 3.87253 7.99285C3.87253 7.55092 3.11865 7.42095 2.59874 6.90103C2.13081 6.43311 1.84486 5.83521 1.84486 5.13332C1.84486 3.78154 2.94968 2.68972 4.30146 2.68972C4.56142 2.68972 4.79538 2.72872 4.99034 2.72872C5.34129 2.72872 5.52326 2.57274 5.52326 2.32578C5.52326 2.18281 5.45827 2.00084 5.45827 1.80587C5.45827 1.36394 5.83521 1 6.29013 1C6.74506 1 7.109 1.37694 7.109 1.83186C7.109 2.31278 6.73206 2.53375 6.44611 2.63773C6.21214 2.71572 6.03017 2.8197 6.03017 3.05366C6.03017 3.49559 6.83604 3.92452 6.83604 5.15932ZM6.57608 11.6855C6.57608 10.3988 5.62724 9.35893 4.34045 9.35893C3.05366 9.35893 2.10482 10.3988 2.10482 11.6855C2.10482 12.9593 3.05366 14.0122 4.34045 14.0122C5.62724 14.0122 6.57608 12.958 6.57608 11.6855ZM5.80921 5.13332C5.80921 4.31446 5.14632 3.63857 4.34045 3.63857C3.53458 3.63857 2.87169 4.31446 2.87169 5.13332C2.87169 5.95219 3.53458 6.62808 4.34045 6.62808C5.14632 6.62808 5.80921 5.95219 5.80921 5.13332Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.25"
      />
    </Icon>
  );
};

export default GreenhouseIcon;
