import { ApolloError } from "@apollo/client";

export const getErrorCode = ({ graphQLErrors }: ApolloError): string | null => {
  const errorCode: string | undefined = graphQLErrors?.find(
    ({ extensions }) => extensions.code
  )?.extensions.code as string;

  return errorCode ?? null;
};

export const isCustomError = (e: ApolloError): boolean => {
  return getErrorCode(e) === "CUSTOM_ERROR";
};

type ErrorExtra = Record<string, any>;

export type ErrorInfo<T extends ErrorExtra = ErrorExtra> = {
  message: string;
  code: string | null;
  extra: T | null;
};

export const getErrorExtra = <T extends ErrorExtra = ErrorExtra>(
  error: ApolloError
): T | null => {
  const extra = error.graphQLErrors?.find(({ extensions }) => extensions.extra)
    ?.extensions.extra as T;

  return extra ?? null;
};

export const getErrorInfo = <T extends ErrorExtra = ErrorExtra>(
  error: ApolloError
): ErrorInfo<T> => {
  return {
    message: error.message,
    code: getErrorCode(error),
    extra: getErrorExtra<T>(error),
  };
};
