// Prism Button Variants
// https://www.figma.com/file/uN2Pi6GfUePpsJUUZORtBU/Prism?type=design&node-id=1247-22346&mode=design&t=T7PLRSSp7PNHvz1r-0

// Prism Tertiary Button
export const tertiaryButtonVariantProps = {
  variant: "ghost",
  fontWeight: "500",
  _disabled: {
    background: "transparent",
    color: "gray.200",
    _hover: {
      background: "gray.50",
      color: "gray.400",
    },
  },
};

// BH Plan Custom Button Variants (not in Prism)
export const primaryButtonGrayProps = {
  ...tertiaryButtonVariantProps,
  color: "gray.900",
  _hover: {
    bg: "blue.50",
    color: "blue.600",
  },
};

export const primaryButtonBlueDisabledProps = {
  background: "gray.200",
  color: "white",
  cursor: "default",
};

// TODO: migrate these to the main button.ts theme file, pending a sweep of the app
export const primaryButtonBlueProps = {
  color: "white",
  bg: "blue.600",
  fontWeight: 500,
  _hover: {
    bg: "blue.500",
    _disabled: primaryButtonBlueDisabledProps,
  },
  _active: {
    _disabled: primaryButtonBlueDisabledProps,
  },
  _focus: {
    boxShadow: "0 0 0 3px blue.400",
    _disabled: primaryButtonBlueDisabledProps,
  },
  _disabled: primaryButtonBlueDisabledProps,
};
