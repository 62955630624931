import {
  Box,
  Flex,
  Icon,
  ListItem,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { HiOutlineX } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import {
  GreenhouseRatingButton,
  LeverRatingButton,
  LoadingIndicator,
  RouterLink,
} from "../../../components";
import { formatRelativeDate } from "../../../utils/datetime";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import {
  CandidateInterviewFragment,
  useCallChaptersQuery,
} from "../../graphql";
import VideoWithThumbnail from "../Video/VideoWithThumbnail";
import { getCallName } from "./utils";

type InterviewChapterMobileProps = {
  call: CandidateInterviewFragment;
  isOpen: boolean;
  thumbnailUrl?: any; // remove this when we have highlight thumbnails backfilled
  onClose: () => void;
};

const InterviewChapterMobile: React.FC<InterviewChapterMobileProps> = ({
  call,
  isOpen,
  thumbnailUrl,
  onClose,
}) => {
  const navigate = useNavigate();
  const sendGAEvent = useSendGAEvent();
  const [selectedChapterId, setSelectedChapterId] = useState("");

  const { loading, error, data } = useCallChaptersQuery({
    variables: { callId: call.id },
  });
  if (
    data?.callChapters?.chapters &&
    data?.callChapters?.chapters.length > 0 &&
    !selectedChapterId
  ) {
    setSelectedChapterId(data?.callChapters?.chapters[0].id);
  }

  const selectedChapter =
    data?.callChapters?.chapters && data?.callChapters?.chapters.length > 0
      ? data.callChapters.chapters.find(
          (chapter) => chapter.id === selectedChapterId
        )
      : null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount size="full">
      <ModalOverlay />
      <ModalContent overflowY="scroll">
        <Box width="100%">
          {loading && <LoadingIndicator h="100vh" />}
          {error && (
            <>
              <Header onClose={onClose} />
              <Box bg="gray.50" rounded="md" px="4" py="8">
                <Text>
                  Oops! There was an error fetching topics for this interview.
                </Text>
              </Box>
            </>
          )}
          {!loading && !error && data && (
            <>
              <Header onClose={onClose} />

              <Flex px="4" mt={6} mb={4} alignItems="center">
                <Text fontSize="md" fontWeight="semibold" color="gray.800">
                  Topics ({data?.callChapters?.chapters?.length || 0})
                </Text>
              </Flex>

              {data?.callChapters?.chapters?.length === 0 && (
                <Text ml="8" color="gray.900">
                  No topics detected.
                </Text>
              )}
              {data?.callChapters?.chapters &&
                data?.callChapters?.chapters?.length > 0 && (
                  <>
                    <Select
                      defaultValue={selectedChapterId}
                      w="calc(100%-16px)"
                      mx={4}
                      my={1}
                      borderRadius="2"
                      borderColor="gray.200"
                      fontSize="sm"
                      onChange={(
                        e: React.SyntheticEvent<HTMLSelectElement, Event>
                      ) => {
                        sendGAEvent("view_interview_chapter", "candidate");
                        setSelectedChapterId(e.currentTarget.value);
                      }}
                    >
                      {data?.callChapters?.chapters.map((chapter) => (
                        <option key={chapter.id} value={chapter.id}>
                          {chapter.text}
                        </option>
                      ))}
                    </Select>
                    {selectedChapter && (
                      <Flex width="100%" direction="column" mt={4} px={4}>
                        <VideoWithThumbnail
                          streamableVideo={call.streamableVideo}
                          streamableAudio={call.streamableAudio}
                          startTime={selectedChapter.startTime}
                          thumbnailUrl={
                            selectedChapter.thumbnailUrl || thumbnailUrl
                          }
                          audioOnly={
                            !selectedChapter.thumbnailUrl && !thumbnailUrl
                          }
                        />
                        <Flex direction="column" mb={4} mt={6}>
                          <Text
                            fontWeight="semibold"
                            fontSize="sm"
                            color="gray.800"
                            mb={2}
                          >
                            {`${
                              selectedChapter.questionAndAnswer.question.includes(
                                "Candidate discussed:"
                              )
                                ? ""
                                : "Q: "
                            }${selectedChapter.questionAndAnswer.question}`}
                          </Text>
                          <UnorderedList mb={6}>
                            {selectedChapter.questionAndAnswer.answerItems.map(
                              (answer) => (
                                <ListItem
                                  key={answer.id}
                                  mb={1}
                                  fontSize="sm"
                                  color="gray.800"
                                >
                                  {answer.text}
                                </ListItem>
                              )
                            )}
                          </UnorderedList>
                          <Flex
                            bg="gray.50"
                            p={2}
                            direction="column"
                            borderRadius={8}
                            mb={4}
                          >
                            <Box
                              my={1}
                              fontWeight={600}
                              color="gray.800"
                              fontSize="14px"
                              lineHeight="20px"
                            >
                              <RouterLink
                                to={`/interview/${call.id}?t=${selectedChapter.startTime}`}
                                onClick={() => {
                                  sendGAEvent(
                                    "view_interview",
                                    "candidate",
                                    "icon_button"
                                  );
                                  navigate(
                                    `/interview/${call.id}?t=${selectedChapter.startTime}`
                                  );
                                }}
                              >
                                {getCallName(call)}
                              </RouterLink>
                            </Box>
                            <Box my={1} lineHeight="20px" fontSize="14px">
                              {call.interviewers
                                .map((i) => i.fullName)
                                .join(", ")}
                              {call?.isInterviewer && (
                                <Flex my="2">
                                  {call.greenhouseScorecardLinkWithId && (
                                    <GreenhouseRatingButton
                                      greenhouseScorecardLink={
                                        call.greenhouseScorecardLinkWithId
                                      }
                                      rating={call.greenhouseUserRating}
                                    />
                                  )}
                                  {call.greenhouseUserRating &&
                                    call.leverInterviewLinkWithId &&
                                    " "}
                                  {call.leverInterviewLinkWithId && (
                                    <LeverRatingButton
                                      leverInterviewLink={
                                        call.leverInterviewLinkWithId
                                      }
                                    />
                                  )}
                                </Flex>
                              )}
                            </Box>
                            <Box
                              my={1}
                              lineHeight="20px"
                              color="gray.600"
                              fontSize="14px"
                              fontWeight={500}
                            >
                              {formatRelativeDate(
                                call.startTime ?? call.createdAt
                              )}
                            </Box>
                          </Flex>
                        </Flex>
                      </Flex>
                    )}
                  </>
                )}
            </>
          )}
        </Box>
      </ModalContent>
    </Modal>
  );
};

const Header: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  return (
    <ModalHeader px={0} borderBottomWidth="1px" borderColor="gray.200">
      <Flex px="4" justifyContent="space-between" alignItems="center">
        <Text
          fontSize="2xl"
          fontWeight="semibold"
          verticalAlign="center"
          color="gray.800"
        >
          Interview Summary
        </Text>

        <Icon
          as={HiOutlineX}
          onClick={onClose}
          boxSize="6"
          color="gray.500"
          cursor="pointer"
        />
      </Flex>
    </ModalHeader>
  );
};

export default InterviewChapterMobile;
