import {
  ExternalCallFragment,
  ExternalClipFragment,
  ExternalUserViewCallMutation,
  ExternalUserViewClipMutation,
} from "../../graphql";

export type ExternalRecording = (
  | ExternalCallFragment
  | ExternalClipFragment
) & { transcriptLanguageCode?: string | null };

export function isExternalUserViewCall(
  data: ExternalUserViewCallMutation | ExternalUserViewClipMutation
): data is ExternalUserViewCallMutation {
  return (
    (data as ExternalUserViewCallMutation).externalUserViewCall !== undefined
  );
}

export const isExternalCall = (
  r: ExternalRecording
): r is ExternalCallFragment => {
  const typeName = "__typename";
  return r[typeName] === "ExternalCall";
};
