import { Text, TextProps } from "@chakra-ui/react";
import React from "react";

type ColumnHeaderV2Props = {
  children: React.ReactNode;
} & TextProps;

const ColumnHeaderV2: React.FC<ColumnHeaderV2Props> = ({
  children,
  ...styles
}) => {
  return (
    <Text fontSize="sm" textTransform="uppercase" color="gray.600" {...styles}>
      {children}
    </Text>
  );
};

export default ColumnHeaderV2;
