import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { IoMdClose } from "react-icons/io";

import { Button, IconButton } from "../../../components";
import SharedWithAvatar from "./SharedWithAvatar";
import { ShareUser } from "./types";

type UserShareItemProps = {
  user: ShareUser;
  primaryText: string;
  secondaryText: string;
  renewExternalShare?(): void;
  removeShare?(): void;
  index: number;
};

const UserShareItem: React.FC<UserShareItemProps> = ({
  user,
  primaryText,
  secondaryText,
  renewExternalShare,
  removeShare,
  index,
}) => {
  return (
    <Flex
      data-testid={`share[${index}]`}
      alignItems="center"
      py="2"
      pl="3"
      pr="4"
      mb="1"
      borderRadius="lg"
      _hover={{ bg: "gray.50" }}
    >
      <SharedWithAvatar user={user} w={6} h={6} fontSize="10px" />

      <Flex ml="3" flexDir="column">
        <Text mb="1px" color="gray.900" fontSize="sm">
          {primaryText}
        </Text>
        <Text color="gray.500" fontSize="xs">
          {secondaryText}
        </Text>
      </Flex>

      {renewExternalShare && (
        <Button
          mr="3"
          ml="auto"
          px="1.5"
          fontSize="sm"
          fontWeight="medium"
          height="7"
          variant="white"
          bg="transparent"
          onClick={renewExternalShare}
          textTransform="none"
        >
          Regrant access
        </Button>
      )}

      {removeShare && (
        <IconButton
          size="xs"
          data-testid={`remove-share[${index}]`}
          aria-label="Remove shared user"
          variant="none"
          icon={<IoMdClose />}
          ml={renewExternalShare ? 0 : "auto"}
          onClick={removeShare}
        />
      )}
    </Flex>
  );
};

export default UserShareItem;
