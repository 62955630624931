import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

import { Alert } from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import AnalyticsAdmin from "./AnalyticsAdmin";
import DashboardCardDEI from "./DashboardCardDEI";
import DashboardCardExperience from "./DashboardCardExperience";
import DashboardCardOps from "./DashboardCardOps";
import { DashboardConfig } from "./types";

type AnalyticsOverviewProps = {
  alerts: Alert[];
  dashboardConfig: DashboardConfig;
  summaryText?: string;
};

const AnalyticsOverview: React.FC<AnalyticsOverviewProps> = ({
  alerts,
  dashboardConfig,
  summaryText,
}) => {
  const {
    organization: { ats },
  } = useCurrentUser();

  return (
    <Flex flexDir="column" data-testid="analytics--overview">
      <Flex align="baseline" justify="space-between" mb="6">
        <Text fontSize="24px" fontWeight="700" color="gray.700" pr="2">
          Overview
        </Text>
        <AnalyticsAdmin />
      </Flex>
      <Box pb={4} minH="10" width="100%">
        <Text>{summaryText}</Text>
      </Box>
      <Flex wrap="wrap" mx="-2" data-testid="analytics--dashboard-cards">
        <Flex direction="column" flex="0 0 50%">
          <Box p={2}>
            <DashboardCardExperience
              alerts={alerts.filter(
                (a) => a.category === "candidate_experience"
              )}
              dashboardConfig={dashboardConfig}
            />
          </Box>
          <Box p={2}>
            {ats && (
              <DashboardCardOps
                alerts={alerts.filter(
                  (a) => a.category === "talent_operations"
                )}
                dashboardConfig={dashboardConfig}
              />
            )}
          </Box>
        </Flex>
        <Flex direction="column" flex="0 0 50%">
          <Box p={2}>
            <DashboardCardDEI
              alerts={alerts.filter((a) => a.category === "dei")}
              dashboardConfig={dashboardConfig}
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AnalyticsOverview;
