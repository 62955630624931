import { Box, Center, Flex, Text, Tooltip } from "@chakra-ui/react";
import React from "react";

import { ReportDataPoint } from "../../../graphql";
import { ReportMetric } from "../types";
import { getAverageMetricValue } from "../utils";

export type MyAnalyticsCardProps = {
  metric: ReportMetric;
  baseData: ReportDataPoint[];
  comparisonData: ReportDataPoint[];
  metricDisabled?: boolean;
};

const SMALL_TEXT_PROPS = {
  fontSize: "xs",
  lineHeight: "16px",
};

const MyAnalyticsCard: React.FC<MyAnalyticsCardProps> = ({
  metric,
  baseData,
  comparisonData,
  metricDisabled,
}) => {
  const avg = getAverageMetricValue(baseData, metric.rawDataKey);
  const hasAvgValue = typeof avg === "number";

  const compareAvg = getAverageMetricValue(comparisonData, metric.rawDataKey);
  const hasCompareAvgValue = typeof compareAvg === "number";

  const plusInfinity = "↑∞";
  const noChange = "No change";

  let comparisonText: string | undefined;
  if (hasAvgValue && hasCompareAvgValue) {
    if (compareAvg === avg) {
      comparisonText = noChange;
    } else if (compareAvg === 0) {
      comparisonText = plusInfinity;
    } else {
      const diff = avg - compareAvg;
      const diffPercent = (diff / compareAvg) * 100;
      const comparisonSymbol = diff > 0 ? "↑" : "↓";
      const integerDiff = Math.abs(Math.round(diffPercent));
      if (integerDiff === 0) {
        comparisonText = noChange;
      } else {
        comparisonText = `${comparisonSymbol}${integerDiff}%`;
      }
    }
  }

  const metricScore = getAverageMetricValue(baseData, metric.dataKey);
  const compareMetricScore = getAverageMetricValue(
    comparisonData,
    metric.dataKey
  );

  let comparisonColor = "gray.600";
  if (comparisonText === plusInfinity) comparisonColor = "green.500";
  if (metricScore && comparisonText !== noChange) {
    if (
      metricScore === 100 ||
      !compareMetricScore ||
      metricScore > compareMetricScore
    ) {
      comparisonColor = "green.500";
    } else if (metricScore < compareMetricScore) {
      comparisonColor = "red.500";
    }
  }

  const comparisonTooltipLabel = `Your Interview quality score for this metric has ${
    comparisonColor.includes("green") ? "improved" : "decreased"
  } in this time period as compared to the previous time period`;

  return (
    <Flex
      flexDirection="column"
      align="start"
      width="176px"
      height="180px"
      flex="0 0 176px"
      borderRadius="lg"
      p="6"
    >
      {/* Header */}
      <Box minH="32px" mb="4">
        <Text
          color="gray.500"
          textTransform="uppercase"
          fontWeight="600"
          textAlign="left"
          noOfLines={2}
          {...SMALL_TEXT_PROPS}
        >
          {metric.label}
        </Text>
      </Box>
      {/* Content */}
      {metricDisabled ? (
        <Center h="48px">
          <Text textAlign="left" mb="1">
            No ATS data available.
          </Text>
        </Center>
      ) : hasAvgValue ? (
        <Box textAlign="left">
          <Text color="gray.800" mb="1">
            <Text
              as="span"
              fontSize="48px"
              fontWeight="300"
              lineHeight="1"
              letterSpacing="-1.92px"
            >
              {metric.format ? metric.format(avg) : avg}
            </Text>
            {metric.units && (
              <Text as="span" fontSize="sm" fontWeight="600">
                {metric.units}
              </Text>
            )}
          </Text>
          {comparisonText && (
            <Tooltip
              label={comparisonTooltipLabel}
              isDisabled={comparisonText === noChange}
            >
              <Text fontWeight="500" {...SMALL_TEXT_PROPS}>
                <Text as="span" color={comparisonColor}>
                  {comparisonText}
                </Text>{" "}
                <Text as="span">v. prev. period</Text>
              </Text>
            </Tooltip>
          )}
        </Box>
      ) : (
        <Center h="48px">
          <Text mb="1">No data</Text>
        </Center>
      )}
    </Flex>
  );
};

export default MyAnalyticsCard;
