import { Box, Flex, Heading, StyleProps } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

type SettingsPageContainerProps = {
  heading?: React.ReactNode;
  subHeading?: React.ReactNode;
} & Pick<StyleProps, "maxW" | "minW" | "mt">;

/** Renders content with a default `max-width` plus a heading and a subheading */
const SettingsPageContainer: React.FC<
  PropsWithChildren<SettingsPageContainerProps>
> = ({ heading, subHeading, maxW = "576", minW, children, mt }) => (
  <Box maxW={maxW} minW={minW} mt={mt}>
    {(heading || subHeading) && (
      <Flex direction="column" gap="2" mb="7">
        {heading && (
          <Heading
            as="h2"
            fontSize="xl"
            fontWeight="semibold"
            color="gray.900"
            lineHeight="7"
          >
            {heading}
          </Heading>
        )}
        {subHeading && (
          <Heading
            as="h3"
            fontSize="sm"
            fontWeight="normal"
            color="gray.600"
            lineHeight="5"
          >
            {subHeading}
          </Heading>
        )}
      </Flex>
    )}
    {children}
  </Box>
);

export default SettingsPageContainer;
