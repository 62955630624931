export type FormatterOptions = {
  month: "short" | "numeric" | "long";
  day?: "numeric";
  year?: "numeric";
};

export const xFormatter = (
  seconds: number,
  bucketInterval?: string,
  bucketSize?: number,
  customOptions?: FormatterOptions
): string => {
  if (bucketInterval === "MONTH" && bucketSize === 3) {
    const date = new Date(seconds * 1000);
    const month = date.getUTCMonth(); // 0-11 format
    const year = date.getUTCFullYear();
    const quarter = Math.floor(month / 3) + 1;

    return `Q${quarter} ${year}`;
  }

  let options: FormatterOptions = {
    month: "short",
    day: "numeric",
  };

  if (bucketInterval === "WEEK") {
    options = {
      month: "numeric",
      day: "numeric",
    };
  }

  if (bucketInterval === "MONTH") {
    options = {
      month: "short",
      year: "numeric",
    };
  }

  if (customOptions) {
    options = customOptions;
  }

  return new Date(seconds * 1000).toLocaleDateString("en-US", options);
};
