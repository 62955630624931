import {
  AspectRatio,
  Box,
  Flex,
  ListItem,
  Text,
  UnorderedList,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

import { AudioImage, Link } from "../../../../components";
import { formatRelativeDate } from "../../../../utils/datetime";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { VideoDisplayMode } from "../../Interview";
import useMediaPlayer from "../../Interview/useMediaPlayer";
import VideoControls from "../../Interview/VideoControls";
import VideoPlayerProgressBar from "../../Interview/VideoPlayerProgressBar";
import { Thumbnail } from "../../Thumbnail";
import useVideoState from "../../Video/useVideoState";
import VideoPlayerBeta from "../../Video/VideoPlayerBeta";
import useViewHighlight from "../useViewHighlight";
import { CandidatePositionChapter } from "./types";

type CandidateMatchCompareColumnProps = {
  chapter: CandidatePositionChapter;
  shouldPauseVideo: boolean;
  aspectRatio: number | undefined;
  onAspectRatio(ar: number): void;
  setShouldPauseOtherVideo: () => void;
};

const CandidateMatchCompareColumn: React.FC<
  CandidateMatchCompareColumnProps
> = ({
  chapter,
  shouldPauseVideo,
  aspectRatio: aspectRatioProp,
  onAspectRatio,
  setShouldPauseOtherVideo,
}) => {
  const { width, height } = chapter.streamableVideo ?? {};
  const defaultAspectRatio = width && height ? width / height : 16 / 9;
  const aspectRatio = aspectRatioProp ?? defaultAspectRatio;

  useViewHighlight(chapter);
  const sendGAEvent = useSendGAEvent();

  const [mediaPlayerRef, listeners, player] = useMediaPlayer({
    onLoadedMetadata(event) {
      const { videoWidth, videoHeight } = event.target as HTMLVideoElement;
      if (videoWidth && videoHeight) {
        onAspectRatio(videoWidth / videoHeight);
      }
    },
  });

  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);
  // const [showControls, setShowControls] = useState<boolean>(false);
  const { videoCallbackRef, fullScreenControls, containerRef } = useVideoState(
    VideoDisplayMode.VIDEO
  );
  const { colors } = useTheme();

  const {
    isOpen: isHovering,
    onOpen: onMouseEnter,
    onClose: onMouseLeave,
  } = useDisclosure({
    // Disable hover tracking for mobile devices
    isOpen: isMobile ? false : undefined,
  });

  const url = chapter.streamableVideo?.url || chapter.streamableAudio?.url;
  const [mediaSrc, setMediaSrc] = useState(url);
  if (mediaSrc?.split("?")[0] !== url?.split("?")[0]) {
    setMediaSrc(url);
  }

  const { duration, seek } = player;
  useEffect(() => {
    if (duration && chapter.startTime && isVideoPlaying) {
      seek(chapter.startTime);
      player.play();
    } else {
      player.pause();
    }
  }, [duration, chapter.startTime, seek, isVideoPlaying]);

  useEffect(() => {
    if (player.playing) {
      setShouldPauseOtherVideo();
    }
  }, [player.playing]);

  useEffect(() => {
    if (shouldPauseVideo) {
      player.pause();
    }
  }, [shouldPauseVideo]);

  return (
    <Box pb={3} fontSize="sm" color="gray.800">
      {!isVideoPlaying && (
        <Thumbnail
          imageUrl={chapter.thumbnailUrl}
          audioOnly={!chapter.thumbnailUrl}
          aspectRatio={aspectRatio}
          onClick={() => {
            setIsVideoPlaying(true);
            sendGAEvent("play_highlight", "candidate_compare");
          }}
        />
      )}
      {chapter.streamableVideo && (
        <AspectRatio ratio={aspectRatio} hidden={!isVideoPlaying}>
          <Flex
            ref={containerRef}
            direction="column"
            bg="black"
            width="100%"
            borderRadius={8}
            overflow="hidden"
            position="relative"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <VideoPlayerBeta
              mediaPlayerRef={mediaPlayerRef}
              videoRef={videoCallbackRef}
              src={mediaSrc}
              height="100%"
              isVideoVisible={isVideoPlaying}
              preload="metadata"
              containerStyle={{
                borderRadius: "inherit",
              }}
              {...listeners}
            />
            {isHovering && (
              <Box
                position="absolute"
                bottom="0"
                left="2"
                right="2"
                bg={`linear-gradient(transparent, ${colors.blackAlpha[800]})`}
              >
                <VideoControls
                  player={player}
                  isVideoVisible={isVideoPlaying}
                  fullScreenControls={fullScreenControls}
                  zIndex="3"
                  h="10"
                />
                <VideoPlayerProgressBar
                  duration={duration ?? 0}
                  value={player.time}
                  seek={seek}
                  disabled={!duration}
                />
              </Box>
            )}
          </Flex>
        </AspectRatio>
      )}
      {chapter.streamableAudio && !chapter.streamableVideo && isVideoPlaying && (
        <Flex
          height="300px"
          position="relative"
          backgroundImage="/static/images/rectangle.png"
          backgroundSize="cover"
          alignItems="center"
          justifyContent="center"
        >
          <AudioImage width="100%" height="100%" style={{ color: "white" }} />
          <audio
            ref={mediaPlayerRef}
            src={chapter.streamableAudio.url}
            {...listeners}
          />
          <Box
            pos="absolute"
            bottom="0"
            width="100%"
            px="2"
            bg="linear-gradient(transparent 0%, black 50%)"
          >
            <VideoControls isVideoVisible player={player} h="10" />
            <VideoPlayerProgressBar
              duration={duration ?? 0}
              value={player.time}
              seek={seek}
              disabled={!duration}
            />
          </Box>
        </Flex>
      )}
      <Text fontWeight="semibold" fontSize="lg" mt="5">
        {chapter.text}
      </Text>
      <Text my={2}>
        {`${
          chapter.questionAndAnswer.question.includes("Candidate discussed:")
            ? ""
            : "Q: "
        }${chapter.questionAndAnswer.question}`}
      </Text>
      <UnorderedList pl={2} mb={5}>
        {chapter.questionAndAnswer.answerItems.map((answer) => (
          <ListItem key={answer.id} mb={1}>
            {answer.text}
          </ListItem>
        ))}
      </UnorderedList>
      <Flex
        borderTop="1px"
        borderTopColor="gray.200"
        py={4}
        fontSize="14px"
        fontWeight="400"
        lineHeight="20px"
        direction="column"
      >
        <Flex py={0.5}>
          {chapter.callInterviewers && (
            <Link
              href={`/interview/${chapter.callId}`}
              target="_blank"
              rel="noopener noreferrer"
              fontWeight="400"
            >
              {chapter.callName ||
                `Interview with ${chapter.callInterviewers
                  ?.map((i) => i.fullName)
                  .join(",")}`}
            </Link>
          )}
        </Flex>
        <Flex color="gray.800" py={0.5}>
          {chapter.callInterviewers?.map((i) => i.fullName).join(", ")}
        </Flex>
        <Flex color="gray.600" py={0.5}>
          {formatRelativeDate(chapter.callTime)}
        </Flex>
      </Flex>
    </Box>
  );
};

export default CandidateMatchCompareColumn;
