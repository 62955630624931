import { ButtonGroup, Center, Flex } from "@chakra-ui/react";
import React from "react";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";

import { Button, IconButton } from "../../../components";
import { generatePages } from "../../../components/Table";

type PaginationProps = {
  currentPageNumber: number;
  totalPageCount: number;
  onChangePage(value: number): void;
};

const Pagination: React.FC<PaginationProps> = ({
  currentPageNumber,
  totalPageCount,
  onChangePage,
}) => {
  return (
    <Center mt="6" mb="5" alignItems="center">
      <ButtonGroup spacing={0.5} size="sm">
        <IconButton
          aria-label="Previous Page"
          icon={<HiOutlineChevronLeft />}
          size="sm"
          color="blue.600"
          fontSize="18px"
          variant="icon"
          isDisabled={currentPageNumber === 1}
          onClick={() => {
            onChangePage(Math.max(1, currentPageNumber - 1));
          }}
        />
        {generatePages(totalPageCount, currentPageNumber).map((page, index) => {
          if (page === 0) {
            return (
              <Flex
                color="blue.600"
                background="transparent"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                ...
              </Flex>
            );
          }
          return (
            <Button
              color={page === currentPageNumber ? "gray.800" : "blue.600"}
              background={
                page === currentPageNumber ? "gray.50" : "transparent"
              }
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onClick={() => {
                onChangePage(page);
              }}
              variant="icon"
              _hover={{
                background: page === currentPageNumber ? "gray.50" : "blue.50",
                color: page === currentPageNumber ? "gray.800" : "blue.600",
                cursor: page === currentPageNumber ? "default" : "pointer",
              }}
            >
              {page}
            </Button>
          );
        })}
        <IconButton
          aria-label="Next Page"
          icon={<HiOutlineChevronRight />}
          size="sm"
          color="blue.600"
          fontSize="18px"
          variant="icon"
          disabled={currentPageNumber === totalPageCount}
          onClick={() => {
            onChangePage(Math.min(totalPageCount, currentPageNumber + 1));
          }}
        />
      </ButtonGroup>
    </Center>
  );
};

export default Pagination;
