import { ApolloError } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import { ExtraErrorData } from "@sentry/integrations";
import LogRocket from "logrocket";
import { useEffect } from "react";

import config from "../config";
import { checkCookieExistence } from "./cookie";

const toggleReplay = (): void => {
  const client: Sentry.BrowserClient | undefined =
    Sentry.getCurrentHub().getClient();
  if (!client) {
    return;
  }

  const existingReplay = client.getIntegration(Sentry.Replay);
  if (existingReplay) {
    return;
  }
  const replay = new Sentry.Replay({
    maskAllText: false,
    maskAllInputs: true,
    blockAllMedia: true,
    networkDetailAllowUrls: ["/graphql"],
  });
  client.addIntegration(replay);
};

export const useSentry = (
  isImpersonating: boolean,
  userId?: string,
  userEmail?: string
): void => {
  return useEffect(() => {
    if (isImpersonating) return;
    if (userId == null) return;

    Sentry.setUser({
      id: userId,
      username: userEmail,
      userEmail,
    });
  }, [isImpersonating, userId, userEmail]);
};

export const initSentry = (
  // https://sentry.io/organizations/brighthire/projects/brighthire-front-end/?project=1801255
  dsn = "https://0964ac02012a4b3aa8536aa797d7ac5d@o316967.ingest.sentry.io/1801255",
  ignoreErrors: Array<string> = []
): void => {
  const isImpersonating = checkCookieExistence("is_impersonating");
  if (
    config.sentryEnv &&
    ["production", "staging"].includes(config.appEnv) &&
    !isImpersonating
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.brighthire = {
      version: config.appVersion,
    };

    const integrations: any[] = [new ExtraErrorData()];

    Sentry.init({
      release: config.appVersion,
      dsn,
      environment: config.sentryEnv,
      replaysOnErrorSampleRate: config.sentryErrorSampleRate,
      replaysSessionSampleRate: config.sentrySessionSampleRate,
      integrations,
      ignoreErrors: [
        "Failed to fetch",
        "Cannot read properties of null (reading 'document')",
        "InvalidStateError: The peer connection is closed.",
        "Non-Error promise rejection captured with keys: category, code, data, handled, severity",
        "AbortError: The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22",
        /Failed to set the 'href' property on 'Location': The current window does not have permission to navigate the target frame to.+/,
        ...ignoreErrors,
      ],
      denyUrls: [
        // intercom flakiness cspell:disable-next-line
        /js\.intercomcdn\.com\/.*\.js/i,
      ],
      // // trace 20% of transactions in production
      // tracesSampleRate: config.appEnv === "production" ? 0.2 : 1.0,
      beforeSend(event, hint) {
        const update: Sentry.Event = { ...event };
        if (
          update?.exception?.values &&
          hint?.originalException instanceof ApolloError
        ) {
          const apolloError = hint.originalException;
          if (apolloError.graphQLErrors.length > 0) {
            update.exception.values = update.exception.values.map(
              (v: Sentry.Exception) => ({
                ...v,
                value:
                  typeof apolloError.graphQLErrors[0]?.extensions
                    ?.error_message === "string"
                    ? apolloError.graphQLErrors[0].extensions.error_message
                    : v.value,
              })
            );
          }
        }
        const logRocketSession = LogRocket.sessionURL;
        if (event && logRocketSession) {
          // eslint-disable-next-line no-param-reassign
          update.extra = {
            ...event.extra,
            ...{ LogRocketError: logRocketSession },
          };
        }
        return update;
      },
    });

    document.addEventListener("mousemove", toggleReplay, { once: true });
  }
};
