import useFeatureFlag from "../../../../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../../../../hooks/useCurrentUser";

export const useAskEnabled = (): boolean => {
  const currentUser = useCurrentUser();
  const orgSettingEnabled = Boolean(currentUser?.organization?.askEnabled);

  const userEnabled = useFeatureFlag("ai-tabs:ask");
  const orgEnabled = useFeatureFlag("ai-tabs:ask:for_orgs");
  const flagEnabled = userEnabled || orgEnabled;

  // Both organization setting and feature flags must be enabled
  return Boolean(orgSettingEnabled && flagEnabled);
};
