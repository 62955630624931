import { Box, Flex, Tag, Text, TextProps } from "@chakra-ui/react";
import React from "react";

import { LoadingIndicator, RouterLink } from "../../../../components";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { AnalyticsDimension, MetricName } from "../../../graphql";
import { ValueFormat } from "../MetricConfig";
import { formatValue, getStructuredFormatFromValue } from "../utils";
import { DashboardConfig, TrendType } from "./types";
import { getTrendType } from "./utils";

type DashboardCardStatProps = {
  stat: CardStat;
  dashboardConfig: DashboardConfig;
};

export type CardStat = {
  title: string;
  values: Array<CardStatValue>;
  metric: MetricName;
  benchmark?: string;
  loading?: boolean;
  segment?: AnalyticsDimension.Gender | AnalyticsDimension.None;
  dashboardConfig: DashboardConfig;
  delta?: number;
};

export type CardStatValue = {
  value: number | undefined;
  format: ValueFormat;
  label?: string;
};

const DashboardCardStat: React.FC<DashboardCardStatProps> = ({
  stat,
  dashboardConfig,
}) => {
  const sendGAEvent = useSendGAEvent();
  return (
    <Box flex="0 0 50%" px="2" py="3">
      <Box
        border="1px solid"
        borderColor="gray.100"
        borderRadius="sm"
        height="100%"
      >
        {stat.loading ? (
          <LoadingIndicator py="4" />
        ) : (
          <RouterLink
            to={`/insights/${stat.metric}?${dashboardConfig.urlDateParam}${
              stat.segment ? `&segment=${stat.segment}` : ""
            }`}
            onClick={() => {
              sendGAEvent("dashboard_stat_click", "analytics", stat.metric);
            }}
            display="block"
            height="100%"
            _hover={{ textDecor: "none" }}
          >
            <Flex
              flexDir="column"
              align="center"
              height="100%"
              py="6"
              px="2"
              _hover={{ bg: stat.loading ? "white" : "blue.50" }}
            >
              <Text
                color="blue.600"
                fontSize="sm"
                fontWeight="600"
                textAlign="center"
              >
                {stat.title}
              </Text>
              <Flex
                direction="row"
                mx="4"
                mt="1"
                justify={stat.values.length > 1 ? "space-around" : "center"}
                align="baseline"
                alignSelf="stretch"
              >
                {stat.values.map((statValue: CardStatValue, i) => {
                  if (statValue.value === undefined) {
                    return (
                      <BigText // eslint-disable-next-line react/no-array-index-key
                        key={i} // index should be ok; items aren't added/removed
                        color="gray.600"
                      >
                        —
                      </BigText>
                    );
                  }
                  const valueObj = getStructuredFormatFromValue(
                    statValue.value,
                    statValue.format
                  );
                  return (
                    <Flex
                      flexDir="column"
                      align="center"
                      m="2"
                      // eslint-disable-next-line react/no-array-index-key
                      key={i} // index should be ok; items aren't added/removed
                    >
                      <Flex direction="row">
                        {valueObj.map((v, i) => (
                          <BigText
                            // eslint-disable-next-line react/no-array-index-key
                            key={i} // index should be ok; items aren't added/removed
                          >
                            {v.value}
                            <Text as="span" fontSize="lg">
                              {`${v.unitSymbol}${
                                i + 1 < valueObj.length ? " " : ""
                              }`}
                            </Text>
                          </BigText>
                        ))}
                      </Flex>
                      {statValue.label && (
                        <Text
                          fontSize="2xs"
                          color="gray.800"
                          fontWeight="600"
                          mt="-1"
                        >
                          {statValue.label}
                        </Text>
                      )}
                    </Flex>
                  );
                })}
                {typeof stat.delta === "number" && (
                  <DeltaTag
                    delta={stat.delta}
                    type={getTrendType(stat.metric, stat.delta)}
                  />
                )}
              </Flex>
              {stat.benchmark && (
                <Text fontSize="xs" color="gray.600" fontWeight="400">
                  Benchmark: {stat.benchmark}
                </Text>
              )}
            </Flex>
          </RouterLink>
        )}
      </Box>
    </Box>
  );
};

type DeltaTagProps = { delta: number; type: TrendType };

const DeltaTag: React.FC<DeltaTagProps> = ({ delta, type }) => {
  const styleProps = ((type: TrendType) => {
    if (type === "positive") {
      return {
        bg: "#E4F0CC",
        color: "#05636B",
      };
    }
    if (type === "negative") {
      return {
        bg: "yellow.50",
        color: "yellow.800",
      };
    }
    return {
      bg: "gray.50",
      color: "gray.800",
    };
  })(type);

  const formattedDelta = formatValue(Math.abs(delta), "percent");
  return (
    <Tag size="sm" position="relative" bottom="2px" {...styleProps}>
      {formattedDelta === "0%" ? <>-</> : delta < 0 ? <>&darr;</> : <>&uarr;</>}
      {formattedDelta}
    </Tag>
  );
};

type BigTextProps = {
  children: React.ReactNode;
} & TextProps;

const BigText: React.FC<BigTextProps> = ({ children, ...props }) => (
  <Text
    fontSize="40px"
    fontWeight="600"
    lineHeight="1.4"
    display="inline"
    color="gray.800"
    {...props}
  >
    {children}
  </Text>
);

export default DashboardCardStat;
