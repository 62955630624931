import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { HiOutlineSearch, HiOutlineX } from "react-icons/hi";
import { HiOutlineFunnel } from "react-icons/hi2";

import { Button, LoadingIndicator } from "../../../components";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import { FilterOption, HighlightsFiltersProps } from "./HighlightsFilters";

export type HighlightsFiltersMobileProps = HighlightsFiltersProps & {
  isOpen: boolean;
  onClose(): void;
};

const HighlightsFiltersMobile: React.FC<HighlightsFiltersMobileProps> = ({
  isOpen,
  onClose,
  matchingHighlights,
  typeFilterOptions,
  toggleFilterHighlightType,
  interviewFilterOptions,
  toggleFilterInterview,
  interviewerFilterOptions,
  toggleFilterInterviewer,
  smartFilterOptions,
  toggleFilterSmartFilters,
  searchQuery,
  updateSearchQuery,
  clearFilters,
  smartFiltersGenerating,
}) => {
  const smartFiltersEnabled = useFeatureFlag("ai_note_tags:show");
  const numHighlights = matchingHighlights.length;

  const activeFilters = typeFilterOptions
    .concat(interviewFilterOptions)
    .concat(interviewerFilterOptions)
    .filter((o) => o.active);

  if (searchQuery) {
    activeFilters.push({
      id: "search",
      displayName: `"${searchQuery}"`,
      active: true,
      count: 0,
    });
  }

  const clearFilter = (filter: FilterOption): void => {
    if (filter.id === "search") {
      updateSearchQuery("");
    } else if (typeFilterOptions.find((f) => f.id === filter.id)) {
      toggleFilterHighlightType(filter);
    } else if (interviewFilterOptions.find((f) => f.id === filter.id)) {
      toggleFilterInterview(filter);
    } else if (interviewerFilterOptions.find((f) => f.id === filter.id)) {
      toggleFilterInterviewer(filter);
    } else if (smartFilterOptions.find((f) => f.id === filter.id)) {
      toggleFilterSmartFilters(filter);
    } else {
      throw Error("Unexpected filter applied");
    }
  };

  const borderBottom = { borderBottomWidth: "1px", borderColor: "gray.200" };

  const filters: {
    title: string;
    options: FilterOption[];
    onChange(i: FilterOption): void;
    loading?: boolean;
  }[] = [
    {
      title: "Highlight type",
      options: typeFilterOptions,
      onChange: toggleFilterHighlightType,
    },
    {
      title: "Interview titles",
      options: interviewFilterOptions,
      onChange: toggleFilterInterview,
    },
    {
      title: "Interviewers",
      options: interviewerFilterOptions,
      onChange: toggleFilterInterviewer,
    },
  ];

  // add groups if smart filters are enabled
  if (smartFiltersEnabled) {
    activeFilters.unshift(...smartFilterOptions.filter((o) => o.active));
    filters.unshift({
      title: "Interview topic",
      options: smartFilterOptions,
      onChange: toggleFilterSmartFilters,
      loading: smartFiltersGenerating,
    });
  }

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      size="sm"
      returnFocusOnClose={false}
      data-tour-id="candidate-page-highlights-filters-mobile"
    >
      <DrawerOverlay />

      <DrawerContent>
        <DrawerHeader p="0">
          <Flex px="4" h="16" alignItems="center" {...borderBottom}>
            <Icon as={HiOutlineFunnel} boxSize="6" color="gray.300" mr="auto" />

            <Icon
              as={HiOutlineX}
              onClick={onClose}
              boxSize="6"
              color="gray.500"
            />
          </Flex>

          <Box bg="gray.50" px="4" py="3" {...borderBottom}>
            <Flex justifyContent="space-between" alignItems="center">
              <Box color="gray.500" fontWeight="bold" fontSize="xs">
                {numHighlights} result{numHighlights !== 1 ? "s" : ""}
              </Box>

              <Button
                size="xs"
                variant="ghost"
                fontWeight="medium"
                fontSize="xs"
                pl="2"
                pr="2"
                onClick={clearFilters}
              >
                Clear all
              </Button>
            </Flex>

            <Flex wrap="wrap" gap="2" mt={activeFilters.length ? "2" : "0"}>
              {activeFilters.map((filter) => (
                <Flex
                  key={filter.id}
                  alignItems="center"
                  bg="white"
                  border="1px"
                  borderColor="gray.100"
                  borderRadius="sm"
                  px="1.5"
                  py="0.5"
                >
                  <Box mr="1" color="gray.700" fontSize="sm">
                    {filter.displayName}
                  </Box>

                  <Icon
                    as={HiOutlineX}
                    onClick={() => clearFilter(filter)}
                    boxSize="3"
                    color="gray.400"
                  />
                </Flex>
              ))}
            </Flex>
          </Box>
        </DrawerHeader>

        <DrawerBody p="0">
          <Box px="4" py="5">
            <InputGroup>
              <InputLeftElement pointerEvents="none" color="gray.500">
                <Icon as={HiOutlineSearch} boxSize="5" />
              </InputLeftElement>

              <Input
                placeholder="Title, interviewer, keyword"
                borderColor="gray.200"
                borderRadius="2px"
                borderWidth="1px"
                fontSize="sm"
                color="gray.600"
                value={searchQuery}
                onChange={(event) => {
                  updateSearchQuery(event.target.value);
                }}
              />

              {searchQuery && (
                <InputRightElement color="gray.500">
                  <Icon
                    as={HiOutlineX}
                    boxSize="4"
                    onClick={() => updateSearchQuery("")}
                  />
                </InputRightElement>
              )}
            </InputGroup>
          </Box>

          <Accordion allowMultiple defaultIndex={[0, 1, 2]}>
            {filters.map((filter) => (
              <AccordionItem key={filter.title}>
                <AccordionButton
                  h="16"
                  px="4"
                  color="gray.800"
                  fontWeight="medium"
                  fontSize="sm"
                  _expanded={{ fontWeight: "bold" }}
                  _hover={{ bg: "none" }}
                >
                  {filter.title}
                  <AccordionIcon ml="auto" boxSize="6" color="gray.500" />
                </AccordionButton>

                <AccordionPanel>
                  <VStack align="flex-start" spacing="5">
                    {filter.loading && <LoadingIndicator />}
                    {!filter.loading &&
                      filter.options.map((option) => (
                        <Checkbox
                          variant="gray"
                          key={option.id}
                          isChecked={option.active}
                          disabled={!option.count}
                          onChange={() => filter.onChange(option)}
                        >
                          {option.displayName} ({option.count})
                        </Checkbox>
                      ))}
                  </VStack>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default HighlightsFiltersMobile;
