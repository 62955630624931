import { Flex, Heading } from "@chakra-ui/react";
import React from "react";

import { Button } from "../../../components";
import { usePageTracker } from "../../../utils/googleAnalytics";
import CenteredContentLayout from "../../layouts/CenteredContentLayout";

const NotFound: React.FC = () => {
  usePageTracker("not_found");
  return (
    <CenteredContentLayout>
      <Flex alignItems="center" direction="column">
        <Heading pb="18px">Not found</Heading>
        {"We can't seem to find the page you're looking for."}
        <Button mt="4" onClick={() => window.history.back()}>
          Go back
        </Button>
      </Flex>
    </CenteredContentLayout>
  );
};

export default NotFound;
