import { Alert, AlertDescription, Box } from "@chakra-ui/react";
import React from "react";

import { SearchInput } from "../../../components";
import useOffsetPagination from "../../../hooks/useOffsetPagination";
import HiringTeamGroupList from "../../components/HiringTeamGroupList/HiringTeamGroupList";
import { Client, useOrgHiringTeamGroupsLazyQuery } from "../../graphql";
import SettingsPageContainer from "../settings/shared/SettingsPageContainer";

const HiringTeamGroupsPage: React.FC = () => {
  const [getClients, { loading, error }] = useOrgHiringTeamGroupsLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: ({ currentUser }) => {
      if (currentUser?.organization?.clientsPag?.results) {
        const clientsList = currentUser?.organization?.clientsPag?.results;
        setListItems(clientsList as Client[]);
        setNumberOfPages(
          currentUser?.organization?.clientsPag.pageInfo?.totalPages || 0
        );
      }
    },
  });

  const {
    listItems,
    setListItems,
    setNumberOfPages,
    pageOptions,
    searchTerm,
    setSearchTerm,
    didFetchComplete,
  } = useOffsetPagination<Client>(getClients, {
    initialSortBy: { id: "createdAt", desc: true },
  });

  if (error)
    return (
      <Alert status="error" title="Error loading teams">
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );

  return (
    <SettingsPageContainer
      maxW="fit-content"
      heading="Hiring team groups"
      subHeading="Select a hiring team group to manage members."
      mt={4}
    >
      <Box mb={2}>
        <SearchInput
          onSearch={setSearchTerm}
          defaultValue={searchTerm}
          placeholder="Search position groups"
        />
      </Box>
      {didFetchComplete ? (
        <HiringTeamGroupList
          clients={listItems}
          loading={loading}
          pageOptions={pageOptions}
          sortBy={pageOptions.sortBy}
        />
      ) : null}
    </SettingsPageContainer>
  );
};

export default HiringTeamGroupsPage;
