import {
  Box,
  Divider,
  Fade,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useCallback, useEffect } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import {
  Controller,
  useFieldArray,
  UseFieldArrayReturn,
  useForm,
  UseFormRegister,
} from "react-hook-form";
import { HiOutlinePlus, HiOutlineTrash } from "react-icons/hi";

import { Button, IconButton, useToast } from "../../../components";
import DragHandleDots from "../../../components/Icons/DragHandleDots";
import { stringToTitleCase } from "../../../utils/string";
import {
  CurrentUserFragment,
  JobDescriptionTemplateSection,
  JobDescriptionTemplateSectionType,
  PlanJobDescriptionTone,
  useUpdatePlanJobDescriptionSettingsMutation,
} from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";

const TONE_DESCRIPTIONS = {
  [PlanJobDescriptionTone.Professional]:
    "Blends professionalism, positivity and friendliness into the role descriptor.",
  [PlanJobDescriptionTone.Friendly]:
    "A conversational tone where the reader feels the connection to the hiring manager as if they were discussing the role with them.",
  [PlanJobDescriptionTone.Inspirational]:
    "An inspirational tone conveying a sense of mission and purpose behind the role.",
  [PlanJobDescriptionTone.Fun]:
    "A fun tone injects a sense of excitement, highlighting the enjoyable aspects of the role.",
  [PlanJobDescriptionTone.Visionary]:
    "A visionary tone paints a vision for the future and the role's impact on it.",
};
const TONE_EXAMPLES = {
  [PlanJobDescriptionTone.Professional]:
    "As a Business Development Manager, you'll play a key role in sculpting the growth trajectory of our company by identifying strategic partnership opportunities and fostering meaningful relationships in the industry. Our approach is hands-on and collaborative, and we're looking for someone who is adept at navigating complex negotiations with a knack for unlocking new avenues of business potential. It's a chance to expand your skills alongside a dedicated team that values insight and initiative just as much as experience.",
  [PlanJobDescriptionTone.Friendly]:
    "As a Business Development Manager, you'll play a pivotal role in steering the growth of our dynamic company. You will be at the heart of forging strategic partnerships and identifying market opportunities that align with our core values. We're looking for a forward-thinking individual who is keen on shaping the business landscape, fostering relationships, and exploring new territories with a collaborative and results-driven approach.",
  [PlanJobDescriptionTone.Inspirational]:
    "As a Business Development Manager, you'll be the architect of our strategic market growth, identifying potential opportunities and fostering robust relationships that anchor our foothold in the industry. You're not just a strategist; you are a relationship builder, crafting collaborations that are as beneficial as they are enduring. Join us and play a vital role in shaping our commercial narrative, driving our success through genuine connections and a deep understanding of market dynamics.",
  [PlanJobDescriptionTone.Fun]:
    "As a Business Development Manager, you will play a key role in shaping the growth trajectory of our company. Your skills in identifying new business opportunities and fostering relationships will be crucial as you collaborate with a dynamic team to drive strategic partnerships and expand our market presence. While the responsibilities are pivotal, it's the close-knit, supportive culture and our shared commitment to meaningful work that makes the journey rewarding.",
  [PlanJobDescriptionTone.Visionary]:
    "As a Business Development Manager, you will be at the helm of forging new paths for our company's growth, engaging directly with clients to cultivate lasting relationships and opportunities. This role is designed for someone who thrives on shaping strategies that drive sustainable expansion, and who takes pride in being the architect of partnerships that matter. We’re seeking a forward-thinker who is eager to leave a mark in our collaborative and dynamic environment, playing a pivotal role in our company’s journey and the success stories we’re yet to write.",
};

type TemplateSection = Pick<
  JobDescriptionTemplateSection,
  "id" | "sectionHeader" | "sectionText" | "type" | "order"
>;

type PlanSettingsFormProps = {
  currentUser: CurrentUserFragment;
  templateId: string;
  sections: TemplateSection[];
};

type FormValues = {
  defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
  allowPlanJobDescriptionToneOverride: boolean;
  sections: Pick<TemplateSection, "sectionHeader" | "sectionText" | "type">[];
};

const PlanSettingsForm: React.FC<PlanSettingsFormProps> = ({
  currentUser,
  templateId,
  sections,
}) => {
  const { defaultPlanJobDescriptionTone, allowPlanJobDescriptionToneOverride } =
    currentUser.organization;
  const toneSettingsEnabled = useFeatureFlag("plan:jd-tone");
  const [isDragging, setIsDragging] = React.useState(false);
  const [toneExample, setToneExample] = React.useState<string>(
    TONE_EXAMPLES[defaultPlanJobDescriptionTone]
  );
  const toast = useToast();
  const [updatePlanJobDescriptionSettingsMutation] =
    useUpdatePlanJobDescriptionSettingsMutation({
      onCompleted: (data) => {
        if (data?.updatePlanJobDescriptionSettings?.planCustomTemplate?.id) {
          toast({
            status: "success",
            title: "Settings updated",
          });
        }
      },
      onError: () => {
        toast({
          status: "error",
          title: "Job description settings",
          description: "There was a problem - please try again",
        });
      },
    });

  const { register, control, handleSubmit, watch } = useForm<FormValues>({
    defaultValues: {
      defaultPlanJobDescriptionTone,
      allowPlanJobDescriptionToneOverride,
      sections: sections.map(({ sectionHeader, sectionText, type, order }) => ({
        sectionHeader,
        sectionText,
        type,
        order,
      })),
    },
  });
  const watchTone = watch("defaultPlanJobDescriptionTone");
  const { fields, append, insert, move, remove } = useFieldArray({
    control,
    name: "sections",
  });

  const firstBodySectionIndex = fields.findIndex(
    (section) => section.type !== JobDescriptionTemplateSectionType.Custom
  );
  const bodySectionCount = fields.filter(
    (section) => section.type !== JobDescriptionTemplateSectionType.Custom
  ).length;

  const headerSections = fields.slice(0, firstBodySectionIndex);
  const bodySections = fields.slice(
    firstBodySectionIndex,
    firstBodySectionIndex + bodySectionCount
  );
  const footerSections = fields.slice(firstBodySectionIndex + bodySectionCount);
  const offset = headerSections.length + bodySections.length;

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { source, destination } = result;
      if (!destination) {
        setIsDragging(false);
        return;
      }

      // The offset accounts for the fact that the form uses an index that is
      // relative to the full list of sections (header + body + footer)
      // but the index in each droppable list is relative to its own list.

      // Form: [header: 0...x, body: x+1...y, footer: y+1...z]
      // Droppable header list: [0...x]
      // Droppable footer list: [0...z]
      const sourceFormIndex =
        source.index + (source.droppableId === "header" ? 0 : offset);
      const destinationFormIndex =
        destination.index + (destination.droppableId === "header" ? 0 : offset);

      // if we're moving an item from header to footer, we need to adjust for
      // the removed item
      let adjustment = 0;
      if (
        source.droppableId === "header" &&
        destination.droppableId === "footer"
      ) {
        adjustment = -1;
      }
      move(sourceFormIndex, destinationFormIndex + adjustment);
      setIsDragging(false);
    },
    [offset, move]
  );

  const saveChanges = handleSubmit((values) => {
    const validSections = values.sections.filter((section) =>
      section.type === JobDescriptionTemplateSectionType.Custom
        ? section.sectionHeader.length && section.sectionText.length
        : true
    );
    updatePlanJobDescriptionSettingsMutation({
      variables: {
        customTemplateId: templateId,
        sections: validSections,
        defaultPlanJobDescriptionTone: values.defaultPlanJobDescriptionTone,
        allowPlanJobDescriptionToneOverride:
          values.allowPlanJobDescriptionToneOverride,
      },
    });
  });

  useEffect(() => {
    setToneExample(TONE_EXAMPLES[watchTone]);
  }, [watchTone]);

  const toneOptions = Object.values(PlanJobDescriptionTone);
  const lastToneOption = toneOptions.slice(-1)[0];
  const toneRadioOptions = toneOptions.map((value) => (
    <Radio key={value} value={value} size="lg" spacing="3.5">
      <Flex alignItems="center" fontSize="sm" fontWeight="400" py="3.5">
        <Text color="gray.900" minWidth="10rem">
          {stringToTitleCase(value)}
        </Text>
        <Text color="gray.600">{TONE_DESCRIPTIONS[value] || ""}</Text>
      </Flex>
      {value !== lastToneOption && <Divider ml="-9" pl="9" />}
    </Radio>
  ));

  return (
    <Flex direction="column">
      <Heading as="h3" size="sm" fontWeight="600" mb={2}>
        Template
      </Heading>
      <Heading
        as="h3"
        fontSize="sm"
        fontWeight="400"
        color="gray.600"
        lineHeight="5"
        mb="6"
      >
        Configure default sections for every job description.
      </Heading>
      <form onSubmit={saveChanges}>
        <DragDropContext
          onBeforeCapture={() => setIsDragging(true)}
          onDragEnd={onDragEnd}
        >
          <Flex
            direction="column"
            align="start"
            border="1px solid"
            borderColor="gray.200"
            borderRadius="10px"
            pt="6"
            pb="4"
            px="6"
          >
            <BlockHeader>Header</BlockHeader>
            <DroppableList
              droppableId="header"
              items={headerSections}
              register={register}
              remove={remove}
              isDragging={isDragging}
              onAdd={() => {
                insert(headerSections.length, {
                  sectionHeader: "",
                  sectionText: "",
                  type: JobDescriptionTemplateSectionType.Custom,
                });
              }}
            />
            {!!headerSections.length && (
              <AddSectionButton
                onAdd={() => {
                  insert(headerSections.length, {
                    sectionHeader: "",
                    sectionText: "",
                    type: JobDescriptionTemplateSectionType.Custom,
                  });
                }}
              />
            )}
            <Divider my="6" />
            <BlockHeader>Body</BlockHeader>
            <Text
              fontSize="sm"
              fontWeight="normal"
              color="gray.600"
              lineHeight="5"
              mb="4"
            >
              Change section labels.
            </Text>
            {bodySections.map((item, index) => (
              <DefaultTemplateSection
                key={item.id}
                item={item}
                index={headerSections.length + index}
                register={register}
              />
            ))}
            <Divider my="6" />
            <BlockHeader>Footer</BlockHeader>
            <DroppableList
              droppableId="footer"
              items={footerSections}
              register={register}
              remove={remove}
              offset={headerSections.length + bodySections.length}
              isDragging={isDragging}
              onAdd={() => {
                append({
                  sectionHeader: "",
                  sectionText: "",
                  type: JobDescriptionTemplateSectionType.Custom,
                });
              }}
            />
            {!!footerSections.length && (
              <Box mt="1">
                <AddSectionButton
                  onAdd={() => {
                    append({
                      sectionHeader: "",
                      sectionText: "",
                      type: JobDescriptionTemplateSectionType.Custom,
                    });
                  }}
                />
              </Box>
            )}
          </Flex>
          <Flex direction="column" mt="8" hidden={!toneSettingsEnabled}>
            <Heading as="h3" size="sm" fontWeight="600" mb={2}>
              Content settings
            </Heading>
            <Heading
              as="h3"
              fontSize="sm"
              fontWeight="400"
              color="gray.600"
              lineHeight="5"
              mb="6"
            >
              Configure the content generated by the AI assistant.
            </Heading>
            <FormControl>
              <FormLabel>Tone</FormLabel>
              <Controller
                control={control}
                name="defaultPlanJobDescriptionTone"
                render={({ field: { onChange, value } }) => (
                  <RadioGroup value={value} onChange={onChange}>
                    <Stack direction="column" spacing={0}>
                      {toneRadioOptions}
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>
            <Box mt="4">
              <Text color="gray.700" fontWeight="400" fontSize="sm" mb="2">
                Example
              </Text>
              <Box backgroundColor="gray.50" p="4" key={toneExample}>
                <Fade
                  in={!!toneExample}
                  transition={{
                    enter: { duration: 0.25 },
                  }}
                >
                  <Text
                    m="0"
                    p="0"
                    fontSize="sm"
                    fontWeight="400"
                    color="gray.600"
                  >
                    {toneExample}
                  </Text>
                </Fade>
              </Box>
            </Box>
            <FormControl display="flex" alignItems="center" mt="9">
              <Switch
                id="allowPlanJobDescriptionToneOverride"
                defaultChecked={allowPlanJobDescriptionToneOverride}
                {...register("allowPlanJobDescriptionToneOverride")}
              />
              <FormLabel
                htmlFor="allowPlanJobDescriptionToneOverride"
                mb="0"
                ml="3"
                color="gray.900"
                fontSize="sm"
              >
                Allow users to apply a different tone to sections of a job
                description.
              </FormLabel>
            </FormControl>
          </Flex>
          <Flex mt="8">
            <Button type="submit">Save</Button>
          </Flex>
        </DragDropContext>
      </form>
    </Flex>
  );
};

const DroppableList: React.FC<{
  droppableId: string;
  items: any[];
  register: UseFormRegister<FormValues>;
  remove: UseFieldArrayReturn["remove"];
  isDragging: boolean;
  onAdd: () => void;
  offset?: number;
}> = ({
  droppableId,
  items,
  register,
  remove,
  onAdd,
  isDragging,
  offset = 0,
}) => {
  const isEmpty = items.length === 0;
  return (
    <Droppable droppableId={droppableId}>
      {(provided) => (
        <Flex
          ref={provided.innerRef}
          {...provided.droppableProps}
          direction="column"
          w="100%"
        >
          {isEmpty && (
            <Box width="100%" mt="1">
              <Flex
                position="relative"
                minHeight="4rem"
                borderRadius="8px"
                width="100%"
                // cspell:disable-next-line
                backgroundImage={`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23E5E7EAFF' stroke-width='5' stroke-dasharray='4%2c14' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");`}
              >
                {isDragging && (
                  <Text
                    color="gray.200"
                    fontSize="sm"
                    fontWeight="400"
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                  >
                    {isDragging
                      ? "Drop here"
                      : "Add a new section, or drop one here"}
                  </Text>
                )}
                {!isDragging && (
                  <Flex alignItems="center" px="4" py="3">
                    <AddSectionButton onAdd={onAdd} />
                    <Text
                      color="gray.200"
                      fontSize="sm"
                      fontWeight="400"
                      ml="2"
                    >
                      or drag one here.
                    </Text>
                  </Flex>
                )}
                {provided.placeholder}
              </Flex>
            </Box>
          )}
          {items.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps}>
                  <TemplateSection
                    key={item.id}
                    index={index + offset}
                    register={register}
                    remove={remove}
                    dragHandleProps={provided.dragHandleProps}
                  />
                </div>
              )}
            </Draggable>
          ))}
          {!isEmpty && provided.placeholder}
        </Flex>
      )}
    </Droppable>
  );
};

const DefaultTemplateSection: React.FC<{
  item: any;
  index: number;
  register: UseFormRegister<FormValues>;
}> = ({ item, index, register }) => {
  return (
    <Input required {...register(`sections.${index}.sectionHeader`)} mb="2" />
  );
};

const TemplateSection: React.FC<{
  index: number;
  register: UseFormRegister<FormValues>;
  remove: UseFieldArrayReturn["remove"];
  dragHandleProps?: any;
}> = ({ index, register, remove, dragHandleProps }) => {
  return (
    <Flex
      direction="row"
      align="start"
      w="100%"
      p="4"
      borderRadius="8px"
      background="gray.50"
      my="2"
    >
      {/* Drag handle */}
      <Flex
        height={8}
        width={8}
        pt="8px"
        mr="2"
        alignItems="center"
        justifyContent="center"
        data-testid={`drag-handle-plan-template-section-${index}`}
        {...dragHandleProps}
      >
        <DragHandleDots width={6} height={6} />
      </Flex>
      {/* Section fields */}
      <Flex flex="1" direction="column" align="center" mb="3">
        <Input
          required
          {...register(`sections.${index}.sectionHeader`)}
          mb="3"
        />
        <Textarea required {...register(`sections.${index}.sectionText`)} />
      </Flex>
      <Flex direction="row">
        <IconButton
          aria-label="remove section"
          type="button"
          colorScheme="red"
          variant="ghost"
          size="sm"
          icon={<Icon as={HiOutlineTrash} boxSize="5" />}
          onClick={() => remove(index)}
          ml="3"
        />
      </Flex>
    </Flex>
  );
};

const BlockHeader: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Heading
      as="h4"
      size="xs"
      mb={2}
      fontWeight="600"
      color="gray.600"
      textTransform="uppercase"
    >
      {children}
    </Heading>
  );
};

const AddSectionButton: React.FC<{ onAdd: () => void }> = ({ onAdd }) => {
  return (
    <Button
      aria-label="add section"
      variant="ghost"
      leftIcon={<Icon as={HiOutlinePlus} boxSize="5" />}
      onClick={onAdd}
    >
      Add section
    </Button>
  );
};

export default PlanSettingsForm;
