import {
  dateRange30DaysAgo,
  daysBefore,
  SelectDateRangeState,
} from "../../../../components/SelectDateRange/SelectDateRange";
import { formatISODate } from "../../../../utils/datetime";
import { MetricName } from "../../../graphql";
import { OverviewConfig, OverviewQueryOptions, TrendType } from "./types";

export const getTrendType = (metric: MetricName, value: number): TrendType => {
  const roundedValue = Math.round(value * 100);
  if (roundedValue === 0) {
    return "neutral";
  }
  if (metric === MetricName.ScorecardCompletionTime) {
    return roundedValue < 0 ? "positive" : "negative";
  }
  return roundedValue > 0 ? "positive" : "negative";
};

export const createOverviewQueryOptions = (
  dateRangeState: SelectDateRangeState,
  organizationId: string,
  numDaysBefore = 0
): OverviewQueryOptions => {
  return {
    organizationId,
    dateRangeStart: formatISODate(
      daysBefore(dateRangeState.start, numDaysBefore)
    ),
    dateRangeEnd: formatISODate(daysBefore(dateRangeState.end, numDaysBefore)),
  };
};

export const createOverviewConfig = (
  organizationId: string
): OverviewConfig => {
  const dateRangeState = dateRange30DaysAgo();
  const windowSize = 30;
  return {
    dateRangeState,
    queryOptions: createOverviewQueryOptions(dateRangeState, organizationId),
    compareQueryOptions: createOverviewQueryOptions(
      dateRangeState,
      organizationId,
      windowSize
    ),
    urlDateParam: "date=last_30",
  };
};
