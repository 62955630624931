import {
  Box,
  ButtonGroup,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { Button } from "../../../components";

interface HelperTextProps {
  children: React.ReactNode;
}
const HelperText: React.FC<HelperTextProps> = ({ children }) => {
  return (
    <Text fontSize="sm" fontWeight="400" color="gray.700">
      {children}
    </Text>
  );
};

type QuestionModalProps = {
  isOpen: boolean;
  onClose(): void;
  onSave(question: string): void;
  mode: "create" | "edit";
  initialQuestion?: string;
};

const QuestionModal: React.FC<QuestionModalProps> = ({
  isOpen,
  onSave,
  mode,
  initialQuestion = "",
  ...props
}) => {
  const [question, setQuestion] = useState(initialQuestion || "");

  const onClose = (): void => {
    props.onClose();
    setQuestion(initialQuestion);
  };

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader
            borderBottomWidth="1px"
            borderColor="gray.100"
            fontSize="xl"
            fontWeight="500"
            p="6"
          >
            {mode === "create" ? "New" : "Edit"} Assessment Question
          </ModalHeader>
          <ModalCloseButton top="6" />
          <ModalBody mt="4" pb="2" px="8">
            <Box mb="6">
              {mode === "create" ? (
                <HelperText>
                  New questions will be visible for all in progress and
                  previously completed trainees. Adding a new question will not
                  affect the status of trainees already marked complete.
                </HelperText>
              ) : (
                <>
                  <HelperText>
                    Edited questions will be updated for all in progress and
                    previously completed trainees. Editing questions will not
                    impact previously submitted answers.
                  </HelperText>
                  <br />
                  <HelperText>
                    We recommend only making edits to address small changes or
                    typos. If larger changes are needed delete this question and
                    add a new one.
                  </HelperText>
                </>
              )}
            </Box>
            <Input
              data-testid="assessment-question-input"
              placeholder="Add question text"
              mb="5"
              fontSize="sm"
              autoFocus
              onChange={(e) => setQuestion(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  onClose();
                  onSave(question.trim());
                }
              }}
              defaultValue={initialQuestion}
            />
          </ModalBody>
          <ModalFooter px="8">
            <ButtonGroup size="sm" spacing="2">
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                // isLoading={loading}
                isDisabled={question.trim() === ""}
                onClick={() => {
                  onClose();
                  onSave(question.trim());
                }}
              >
                {mode === "create" ? "Add" : "Save"}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default QuestionModal;
