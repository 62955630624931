import { ButtonProps } from "@chakra-ui/react";
import React from "react";
import { BsPlus } from "react-icons/bs";

import { Button } from "../../../components";

type AddButtonProps = {
  onClick: () => void;
} & ButtonProps;

const AddButton: React.FC<AddButtonProps> = ({
  onClick,
  children,
  ...rest
}) => {
  return (
    <Button
      minWidth="142px"
      fontSize="sm"
      onClick={onClick}
      leftIcon={<BsPlus size={20} />}
      variant="outline"
      mx="4"
      height="2.375rem"
      borderColor="blue.600"
      color="blue.600"
      backgroundColor="rgba(7, 114, 208, 0.1)"
      fontWeight="500"
      {...rest}
    >
      {children}
    </Button>
  );
};

export default AddButton;
