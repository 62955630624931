import { ComponentSingleStyleConfig, theme } from "@chakra-ui/react";
import { transparentize } from "@chakra-ui/theme-tools";

import colors from "../colors";
import colorVars from "../css-color-variables";
import { disabledProps } from "../disabled";

const staticProps = {
  borderColor: "transparent",
  color: "white",
  outline: "none",
  transition: "background 0.2s",
};

const outlineDisabledProps = {
  color: "gray.200",
  borderColor: "gray.200",
  cursor: "default",
  opacity: 1,
};

const ghostDisabledProps = {
  color: "gray.200",
  cursor: "default",
  opacity: 1,
};

const buttonTheme: ComponentSingleStyleConfig = {
  baseStyle: ({ variant }) => {
    // eslint-disable-next-line no-underscore-dangle
    const _disabled = ["outline", "ghost"].includes(variant)
      ? undefined
      : disabledProps;

    return {
      borderRadius: "lg",
      fontWeight: "medium",
      _hover: {
        _disabled,
      },
      _active: {
        _disabled,
      },
      _focus: {
        boxShadow: "0 0 0 3px blue.400",
        _disabled,
      },
      _disabled,
    };
  },
  variants: {
    ghost: {
      _disabled: ghostDisabledProps,
      _hover: {
        _disabled: ghostDisabledProps,
      },
      _active: {
        _disabled: ghostDisabledProps,
      },
      _focus: {
        _disabled: ghostDisabledProps,
      },
    },
    solid: ({ colorScheme }) => ({
      bg: `${colorScheme}.600`,
      _hover: {
        _disabled: disabledProps,
        bg: `${colorScheme}.500`,
      },
      _active: {
        _disabled: disabledProps,
        bg: `${colorScheme}.400`,
      },
    }),
    link: {
      color: "blue.600",
      _hover: {
        _disabled: disabledProps,
      },
    },
    linkGray: {
      color: "gray.600",
      _hover: {
        color: "blue.600",
        _disabled: disabledProps,
      },
    },
    unstyled: {
      _hover: {
        _disabled: disabledProps,
      },
    },
    hoverDanger: {
      color: "blue.600",
      _disabled: {
        color: "gray.400",
        cursor: "default",
        bg: "initial",
      },
      _hover: {
        bg: "red.50",
        color: "red.600",
        _disabled: { bg: "initial" },
      },
      _active: {
        bg: "red.100",
        _disabled: { bg: "initial" },
      },
    },
    outline: {
      bg: "white",
      color: "blue.600",
      _disabled: outlineDisabledProps,
      _hover: {
        color: "blue.500",
        _disabled: outlineDisabledProps,
      },
      _focus: {
        color: "blue.700",
        _disabled: outlineDisabledProps,
      },
      _active: {
        color: "blue.400",
        _disabled: outlineDisabledProps,
      },
    },
    icon: {
      size: "xs",
      bg: "white",
      color: "gray.500",
      _hover: {
        color: "blue.600",
        bg: "blue.50",
        _disabled: { bg: "transparent", color: "gray.200" },
      },
      _focus: {
        bg: "blue.200",
        color: "blue.600",
      },
      _active: {
        bg: "blue.100",
        color: "blue.600",
      },
      _disabled: {
        bg: "transparent",
        color: "gray.200",
      },
    },
    iconDanger: {
      size: "xs",
      bg: "white",
      color: "gray.300",
      borderRadius: "8px",
      _hover: {
        color: "red.500",
        bg: "red.50",
        _disabled: disabledProps,
      },
      _focus: {
        bg: "red.100",
        color: "red.600",
      },
      _active: {
        bg: "red.50",
        color: "red.600",
      },
    },
    whiteOutline: {
      bg: "transparent",
      borderColor: "whiteAlpha.800",
      borderWidth: "1px",
      color: "whiteAlpha.900",
      outline: "none",
      _hover: {
        bg: "white",
        color: "black",
        _disabled: {
          bg: "whiteAlpha.100",
          borderColor: "transparent",
        },
      },
      _active: {
        bg: "gray.100",
        _disabled: {
          bg: "whiteAlpha.100",
          borderColor: "transparent",
        },
      },
      _disabled: {
        bg: "transparent",
        borderColor: "transparent",
        opacity: 0.3,
      },
    },
    white: {
      bg: "white",
      color: "blue.600",
      border: "none",
      _hover: {
        bg: "blue.50",
        _disabled: disabledProps,
      },
      _active: {
        bg: "blue.100",
        _disabled: disabledProps,
      },
    },
    chalk: {
      bg: "transparent",
      borderColor: "transparent",
      color: "gray.500",
      outline: "none",
      _hover: {
        bg: "gray.50",
        _disabled: disabledProps,
      },
      _active: {
        bg: "gray.100",
        _disabled: disabledProps,
      },
    },
    translucent: {
      bg: "transparent",
      borderColor: "transparent",
      color: "white",
      outline: "none",
      transition: "background 0.2s",
      _hover: {
        bg: transparentize("white", 0.2),
        _disabled: {
          opacity: 0.3,
        },
      },
      _active: {
        bg: transparentize("white", 0.2),
        _disabled: {
          opacity: 0.3,
        },
      },
      _disabled: {
        bg: "transparent",
        opacity: 0.3,
      },
    },
    translucentDark: {
      bg: transparentize("black", 0.4),
      borderColor: "transparent",
      color: "white",
      outline: "none",
      transition: "background 0.2s",
      _hover: {
        bg: transparentize("black", 0.6),
        _disabled: {
          opacity: 0.3,
        },
      },
      _active: {
        bg: transparentize("black", 0.6),
        _disabled: {
          opacity: 0.3,
        },
      },
      _disabled: {
        bg: "transparent",
        opacity: 0.3,
      },
    },
    toggle: {
      fontWeight: "600",
      border: `1px solid ${colors.gray[100]}`,
      fontSize: "sm",
      color: "blue.700",
      backgroundColor: "transparent",
      _disabled: {
        color: "gray.800",
        backgroundColor: "gray.50",
      },
      _hover: {
        color: "blue.600",
        backgroundColor: "blue.100",
        _disabled: {
          color: "gray.800",
          backgroundColor: "gray.50",
        },
      },
    },
    pill: {
      fontWeight: "500",
      fontSize: "sm",
      py: "0",
      paddingLeft: "6px",
      paddingRight: "6px",
      size: "xs",
      border: "none",
      color: "blue.600",
      backgroundColor: "transparent",
      borderRadius: "2px",
      _selected: {
        color: "white",
        backgroundColor: "gray.400",
      },
      _disabled: {
        color: "gray.300",
        backgroundColor: "transparent",
      },
      _focus: {
        backgroundColor: "blue.200",
      },
      _active: {
        backgroundColor: "blue.50",
      },
      _hover: {
        bg: "blue.100",
        _disabled: {
          color: "gray.300",
          bg: "transparent",
        },
      },
    },
    success: (props: any): Record<string, any> => ({
      ...theme?.components?.Button?.variants?.solid({
        ...props,
        colorScheme: "green",
      }),
      _hover: {
        bg: "green.600",
        _disabled: disabledProps,
      },
    }),
    successStatic: {
      bg: "green.500",
      ...staticProps,
    },
    danger: (props: any): Record<string, any> => ({
      ...theme?.components?.Button?.variants?.solid({
        ...props,
        colorScheme: "red",
      }),
      _hover: {
        bg: "red.600",
        _disabled: disabledProps,
      },
    }),
    dangerOutline: (props: any): Record<string, any> => ({
      ...theme?.components?.Button?.variants?.outline({
        ...props,
        colorScheme: "red",
      }),
      bg: "white",
    }),
    dangerStatic: {
      bg: "red.500",
      ...staticProps,
    },
    warning: (props: any): Record<string, any> => ({
      ...theme?.components?.Button?.variants?.outline({
        ...props,
      }),
      borderColor: colorVars.orange[400],
      color: colorVars.orange[500],
      bg: "white",
      _hover: {
        bg: "alert.warning.background",
      },
      _active: {
        bg: "alert.warning.backgroundPressed",
      },
    }),
    warningStatic: {
      bg: "yellow.400",
      ...staticProps,
    },
  },
  sizes: {
    xs: {
      paddingLeft: 3,
      paddingRight: 3,
    },
    lg: {
      px: 4,
    },
  },
  defaultProps: {
    colorScheme: "blue",
  },
};

export default buttonTheme;
