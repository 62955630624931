import {
  ButtonProps,
  Icon,
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuListProps,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { HiDotsVertical } from "react-icons/hi";

import { IconButton, MenuButton } from "../../../../components";
import { useIsSmallScreen } from "../../../../hooks/useIsSmallScreen";
import DeleteInterviewButton from "../../../components/DeleteInterviewButton";
import { InterviewMenuOptions } from "../../../components/InterviewsList";
import { ShareCallButton } from "../../../components/ShareCallButton";
import { CallListItemFragment, Maybe } from "../../../graphql";

type InterviewMenuProps = {
  call: Pick<
    CallListItemFragment,
    "id" | "canDelete" | "visibility" | "visibleVisibilityLevels"
  >;
  positionId?: Maybe<string> | undefined;
  listPosition: number;
  menuOptions?: InterviewMenuOptions[];
  menuStyle?: MenuListProps;
} & ButtonProps;

const InterviewMenu: React.FC<InterviewMenuProps> = ({
  call,
  positionId,
  listPosition = 1,
  menuOptions = [],
  menuStyle,
  ...styles
}) => {
  const isSmallScreen = useIsSmallScreen();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  if (menuOptions.length === 0) return null;

  return (
    <Menu
      placement="bottom-end"
      autoSelect={false}
      computePositionOnMount={isSmallScreen}
    >
      <MenuButton
        as={IconButton}
        variant="icon"
        size="sm"
        ml={2}
        borderRadius="base"
        color="gray.500"
        icon={<Icon as={HiDotsVertical} boxSize={5} />}
        _focus={{}}
        data-testid={`completed-interviews-item-${listPosition}-menu-button`}
        {...styles}
      />
      <MenuList borderRadius="base" maxW="270px" {...menuStyle}>
        {menuOptions.map((option, i) => {
          switch (option) {
            case InterviewMenuOptions.DELETE:
              return (
                <React.Fragment key={`menu-fragment-${call.id}`}>
                  <MenuDivider key={`menu-divider-${call.id}-${i + 1}`} />
                  <MenuItem
                    key={`call-${call.id}-menu-option-${i + 1}`}
                    data-testid={`completed-interviews-item-${listPosition}-delete`}
                    isDisabled={!call.canDelete}
                    _hover={!call.canDelete ? {} : { bg: "red.50" }}
                    _active={!call.canDelete ? {} : undefined}
                  >
                    <DeleteInterviewButton call={call} buttonStyle="menuItem" />
                  </MenuItem>
                </React.Fragment>
              );

            case InterviewMenuOptions.SHARE:
              return (
                <MenuItem
                  key={`call-${call.id}-menu-option-${i + 1}`}
                  data-testid={`completed-interviews-item-${listPosition}-share`}
                >
                  <ShareCallButton
                    callId={call.id}
                    positionId={positionId}
                    callVisibility={call.visibility}
                    visibilityLevels={call.visibleVisibilityLevels}
                    readOnly={false}
                    buttonStyle="menuItem"
                    isModalOpen={isShareModalOpen}
                    onCloseModal={() => setIsShareModalOpen(false)}
                    onOpenModal={() => setIsShareModalOpen(true)}
                  />
                </MenuItem>
              );
            default:
              return null;
          }
        })}
      </MenuList>
    </Menu>
  );
};

export default InterviewMenu;
