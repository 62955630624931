import {
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  HiOutlineInformationCircle,
  HiOutlineSearch,
  HiXCircle,
} from "react-icons/hi";

import { Button } from "../../../../components";
import Popover from "../../../../components/Popover/Popover";
import { formatDate } from "../../../../utils/datetime";
import { CandidateCompareListItemFragment } from "./types";

type CandidateCompareTableProps = {
  candidates: CandidateCompareListItemFragment[] | undefined;
  onCandidateSelect(id: string): void;
};

const CandidateCompareTable: React.FC<CandidateCompareTableProps> = ({
  candidates,
  onCandidateSelect,
}) => {
  let rows = candidates?.map((c) => {
    const color = c.validCallsCount ? "black" : "gray.200";
    return (
      <Tr
        key={c.id}
        onMouseDown={(e) => e.preventDefault()}
        cursor={c.validCallsCount ? "auto" : "not-allowed"}
        onClick={() => {
          if (c.validCallsCount) {
            onCandidateSelect(c.id);
          }
        }}
        _hover={{ backgroundColor: c.validCallsCount ? "blue.50" : "gray.50" }}
      >
        <Td color={color}>{c.fullName}</Td>
        <Td textAlign="right" color={color}>
          {c.validCallsCount || 0}
        </Td>
        <Td
          textOverflow="ellipsis"
          overflow="hidden"
          maxWidth="350"
          color={color}
        >
          {c.lastCall?.name}
        </Td>
        <Td textAlign="right" color={color}>
          {formatDate(c.lastCall?.createdAt, {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
        </Td>
      </Tr>
    );
  });
  if (candidates && !rows?.length) {
    rows = [
      <Tr key="none">
        <Td color="gray.600" colSpan={4}>
          No suggestions found. Check your spelling and try again.
        </Td>
      </Tr>,
    ];
  }
  return (
    <TableContainer overflowY="unset" onMouseDown={(e) => e.preventDefault()}>
      <Table variant="popover" borderRadius="4px">
        <Thead position="sticky" top={0}>
          <Tr>
            <Th>Candidate name</Th>
            <Th textAlign="right">
              <Flex alignItems="center">
                Valid interviews
                <Tooltip label="Interviews longer than 120 seconds with 2 or more speakers">
                  <Flex>
                    <Icon
                      mt={0.5}
                      ml={1}
                      as={HiOutlineInformationCircle}
                      display="inline"
                    />
                  </Flex>
                </Tooltip>
              </Flex>
            </Th>
            <Th>Last interview name</Th>
            <Th>Last interview date</Th>
          </Tr>
        </Thead>
        <Tbody>{rows}</Tbody>
      </Table>
    </TableContainer>
  );
};

type CandidateCompareSelectorProps = {
  candidates: CandidateCompareListItemFragment[] | undefined;
  compareCandidateId: string;
  onCandidateSelect(id: string): void;
  shouldSelectCandidateImmediately?: boolean;
  onFocus?(): void;
  onBlur?(): void;
};

const CandidateCompareSelector: React.FC<CandidateCompareSelectorProps> = ({
  candidates,
  compareCandidateId,
  onCandidateSelect,
  onFocus,
  onBlur,
  shouldSelectCandidateImmediately = false,
}) => {
  const selectedCandidate = candidates?.find(
    (c) => c.id === compareCandidateId
  );
  const [selectedCandidateId, setSelectedCandidateId] = useState<string>(
    compareCandidateId || ""
  );
  const [searchQuery, setSearchQuery] = useState<string>(
    selectedCandidate?.fullName || ""
  );
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const selectedCandidate = candidates?.find(
      (c) => c.id === selectedCandidateId
    );
    setSearchQuery(selectedCandidate?.fullName || "");
  }, [candidates, selectedCandidateId]);

  const searchInput = (
    shouldSelectCandidateImmediately: boolean
  ): JSX.Element => (
    <InputGroup
      mr={shouldSelectCandidateImmediately ? 0 : 4}
      width={shouldSelectCandidateImmediately ? "100%" : "480px"}
      data-tour-id="compare-candidate-selector"
    >
      <InputLeftElement pointerEvents="none" color="gray.500">
        <HiOutlineSearch size="16px" />
      </InputLeftElement>
      <Input
        autoFocus
        placeholder="Search by candidate name"
        borderColor="gray.200"
        borderRadius="6px"
        borderWidth="1px"
        fontSize="sm"
        color="gray.900"
        value={searchQuery}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSearchQuery(event.currentTarget.value);
        }}
        onFocus={() => {
          setInputFocused(true);
          onFocus?.();
        }}
        onBlur={() => {
          setInputFocused(false);
          onBlur?.();
        }}
        ref={searchInputRef}
      />

      <InputRightElement
        color="gray.200"
        visibility={searchQuery ? "visible" : "hidden"}
      >
        <Icon
          as={HiXCircle}
          boxSize="4"
          onClick={() => {
            setSearchQuery("");
            setSelectedCandidateId("");
            searchInputRef.current?.focus();
          }}
        />
      </InputRightElement>
    </InputGroup>
  );

  const sortCandidates = (
    candidates: CandidateCompareListItemFragment[]
  ): CandidateCompareListItemFragment[] =>
    candidates.sort((a, b) => {
      if (!a.validCallsCount && !b.validCallsCount) {
        return 0;
      }
      if (!a.validCallsCount) {
        return 1;
      }
      if (!b.validCallsCount) {
        return -1;
      }
      return (b.lastCall?.createdAt || 0) - (a.lastCall?.createdAt || 0);
    });
  const filteredCandidates = useMemo(() => {
    if (!candidates) {
      return [];
    }
    let list = candidates;
    if (searchQuery.length) {
      list = candidates.filter((c) =>
        c.fullName.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())
      );
    }
    return sortCandidates(list);
  }, [searchQuery, candidates]);

  if (!candidates) {
    return <Spinner color="blue.500" />;
  }

  return (
    <Flex
      alignItems="center"
      width={shouldSelectCandidateImmediately ? "100%" : "auto"}
    >
      <Popover
        button={searchInput(shouldSelectCandidateImmediately)}
        placement="bottom-start"
        contentProps={{
          py: 0,
          maxHeight: "400px",
          overflowY: "auto",
          borderRadius: "4px",
        }}
        isOpen={inputFocused}
        closeDelay={shouldSelectCandidateImmediately ? 200 : 0}
      >
        <CandidateCompareTable
          candidates={filteredCandidates}
          onCandidateSelect={(id) => {
            setSelectedCandidateId(id);
            if (shouldSelectCandidateImmediately) {
              onCandidateSelect(id);
            }
            searchInputRef.current?.blur();
          }}
        />
      </Popover>
      {!shouldSelectCandidateImmediately && (
        <Button
          disabled={!selectedCandidateId.length}
          fontWeight="500"
          borderRadius="8px"
          onClick={() => onCandidateSelect(selectedCandidateId)}
          data-tour-id="compare-candidates-button"
        >
          Compare
        </Button>
      )}
    </Flex>
  );
};

export default CandidateCompareSelector;
