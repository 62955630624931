import { BoxProps, Flex, Icon } from "@chakra-ui/react";
import React from "react";
import { HiOutlineCheckCircle } from "react-icons/hi2";

type SuccessMessageProps = Omit<BoxProps, "size"> & {
  message?: React.ReactNode;
  size?: "md";
};

/** A simple success message with a green check mark */
export const SuccessMessage: React.FC<SuccessMessageProps> = ({
  message = "Success",
  size = "md",
  ...styles
}) => {
  return (
    <Flex
      align="center"
      gap={size === "md" ? "2" : undefined}
      py="4"
      color="gray.800"
      fontSize={size === "md" ? "sm" : undefined}
      fontWeight="semibold"
      {...styles}
    >
      <Icon
        as={HiOutlineCheckCircle}
        boxSize={size === "md" ? "10" : undefined}
        color="green.500"
      />
      {message}
    </Flex>
  );
};
