import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {} from "react-icons/io";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Button, Link, useToast } from "../../../components";
import {
  CurrentUserFragment,
  LeverOauthSetup,
  useLeverAuthOnRedirectMutation,
  useUpdateLeverOauthIntegrationSettingsMutation,
} from "../../graphql";
import LeverDeauthorizeButton from "./LeverDeauthorizeButton";
import LeverOauthButton from "./LeverOauthButton";

interface FormValues {
  useSandbox: boolean;
  syncCallGuidesFromAts: boolean;
  createSecretNotes: boolean;
}

interface LeverOauthSettingsSetupProps {
  currentUser: CurrentUserFragment;
  oauthSetupRefetch: () => void;
  leverOauthSetup?: LeverOauthSetup | null;
}

const LeverOauthSettingsSetup: React.FC<LeverOauthSettingsSetupProps> = ({
  currentUser,
  oauthSetupRefetch,
  leverOauthSetup,
}) => {
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const leverCode = searchParams.get("code");
  const leverStateToken = searchParams.get("state");
  const responseError = searchParams.get("error");
  const responseErrorDescription = searchParams.get("error_description");

  const [authLever] = useLeverAuthOnRedirectMutation({
    onError: () => {
      toast({
        status: "error",
        title: "Authorizing Lever",
        description: "There was a problem - please try again",
      });
      navigate(location.pathname, { replace: true });
    },
    onCompleted: () => {
      navigate(location.pathname, { replace: true });
    },
  });

  useEffect(() => {
    if (currentUser.userRole?.canManageIntegrationSettings) {
      if (leverStateToken) {
        authLever({
          variables: {
            leverCode,
            leverStateToken,
            responseError,
            responseErrorDescription,
          },
        });
      }
    }
  }, []);

  const [updateSettings, { loading }] =
    useUpdateLeverOauthIntegrationSettingsMutation({
      onError: (err) => {
        toast({
          title: "Error",
          description: `Failed to update settings: ${err.message}`,
          status: "error",
        });
      },
      onCompleted: () => {
        toast({
          title: "Success",
          description: `Lever settings were updated.`,
          status: "success",
        });
      },
    });
  const { register, handleSubmit, setValue } = useForm<FormValues>({});
  const onSubmit = handleSubmit((formValues) => {
    updateSettings({
      variables: {
        useSandbox: formValues.useSandbox,
        syncGuides: formValues.syncCallGuidesFromAts,
        createSecretNotes: formValues.createSecretNotes,
      },
    });
  });
  useEffect(() => {
    setValue("useSandbox", leverOauthSetup?.useSandbox ?? false);
    setValue(
      "syncCallGuidesFromAts",
      leverOauthSetup?.syncCallGuidesFromAts ?? false
    );
    setValue("createSecretNotes", leverOauthSetup?.createSecretNotes ?? false);
  }, [leverOauthSetup]);

  return (
    <>
      <Box height="2px" background="" mt="4" mb="4">
        <Divider color="gray.100" />
      </Box>
      <Heading as="h3" fontSize="md" mb={4}>
        Lever Oauth
      </Heading>
      <Box mb={4} fontSize="md">
        Please read the{" "}
        <Link href="https://help.brighthire.ai/en/articles/6119085-brighthire-x-lever-integration">
          Lever Setup Instructions
        </Link>{" "}
        before clicking the button below.
      </Box>
      {leverOauthSetup?.valid ? (
        <LeverDeauthorizeButton refetch={oauthSetupRefetch} />
      ) : (
        <LeverOauthButton />
      )}
      <Box my="4">
        <form onSubmit={onSubmit}>
          <VStack spacing="8" align="start">
            <FormControl>
              <Checkbox
                {...register("useSandbox")}
                defaultChecked={leverOauthSetup?.useSandbox}
              >
                Use Sandbox
              </Checkbox>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="syncCallGuidesFromAts" />
              <Checkbox
                {...register("syncCallGuidesFromAts")}
                defaultChecked={leverOauthSetup?.syncCallGuidesFromAts}
              >
                Sync Guides
              </Checkbox>
            </FormControl>
            <FormControl>
              <Checkbox
                {...register("createSecretNotes")}
                defaultChecked={leverOauthSetup?.createSecretNotes}
              >
                Create Secret Notes
              </Checkbox>
              <FormHelperText>
                If checked, all BrightHire notes created in Lever will only be
                visible to Lever users with Sensitive Information Privileges
                (SIP) for postings applied to candidate.
              </FormHelperText>
            </FormControl>
            <Button type="submit" isLoading={loading}>
              Save
            </Button>
          </VStack>
        </form>
      </Box>
    </>
  );
};

export default LeverOauthSettingsSetup;
