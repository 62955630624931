/* eslint-disable react/style-prop-object */
import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

/** "Empty AI Summary" icon in [Figma](https://www.figma.com/design/uN2Pi6GfUePpsJUUZORtBU/Prism?node-id=2387-202&m=dev) */
export const NoInterviewsIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon width="240" height="180" viewBox="0 0 240 180" fill="none" {...props}>
      <path
        d="M10.3581 89.7169V91.7028"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5.89777 90.7929L7.52052 92.4634"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4 95.2397H6.29455"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5.71216 99.7658L7.33491 98.0953"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.3581 100.308V98.3224"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13.6679 98.9844L12.3439 97.6605"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16.3157 95.6746H14.3298"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14.9918 91.0409L13.6678 92.3648"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M116.934 3V4.32392"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M113 7H114.324"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M116.934 10.9435V9.61963"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M120.244 6.97178H118.92"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M40.1463 58.6048V60.5906"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M34.1887 64.5624H36.1745"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M40.1463 69.8581V67.8722"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M45.442 64.5624H43.4561"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M182.636 130.784C184.218 130.784 185.501 132.067 185.501 133.65C185.501 135.233 184.218 136.516 182.636 136.516C181.054 136.516 179.771 135.233 179.771 133.65"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M195.748 104.785C197.449 104.255 200.057 102.174 200.66 99.9358C201.208 101.915 203.502 104.255 205.573 104.432C203.242 105.257 201.014 107.708 200.66 109.634C200.42 107.671 197.338 105.071 195.748 104.785Z"
        fill="#EBECEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1301 129.901C21.9809 129.636 23.2841 128.596 23.5859 127.477C23.8596 128.466 25.0066 129.636 26.0418 129.725C24.8768 130.137 23.7629 131.363 23.5859 132.326C23.4656 131.344 21.9247 130.044 21.1301 129.901Z"
        fill="#EBECEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.9192 37.8173C36.9192 39.045 35.9245 40.0393 34.6977 40.0393C33.4704 40.0393 32.4762 39.045 32.4762 37.8173C32.4762 36.5902 33.4704 35.5952 34.6977 35.5952C35.9245 35.5952 36.9192 36.5902 36.9192 37.8173Z"
        fill="#D1D8DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M236.692 33.7213C236.692 35.3486 235.374 36.6674 233.748 36.6674C232.121 36.6674 230.802 35.3486 230.802 33.7213C230.802 32.0939 232.121 30.7752 233.748 30.7752C235.374 30.7752 236.692 32.0939 236.692 33.7213Z"
        fill="#EBECEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.4369 171.315C78.4369 172.341 77.6058 173.173 76.5802 173.173C75.5551 173.173 74.7239 172.341 74.7239 171.315C74.7239 170.289 75.5551 169.458 76.5802 169.458C77.6058 169.458 78.4369 170.289 78.4369 171.315Z"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.3322 23.5418C77.3322 24.7695 76.3375 25.7639 75.1107 25.7639C73.8839 25.7639 72.8892 24.7695 72.8892 23.5418C72.8892 22.3147 73.8839 21.3198 75.1107 21.3198C76.3375 21.3198 77.3322 22.3147 77.3322 23.5418Z"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.729 160.322C208.309 160.863 206.721 160.149 206.181 158.73C205.64 157.31 206.353 155.72 207.773 155.181C209.192 154.64 210.781 155.353 211.321 156.773C211.862 158.193 211.148 159.782 209.729 160.322Z"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M137.909 17.8648C138.676 17.9835 139.17 18.6748 139.013 19.4088L136.999 28.8315C136.842 29.5655 136.094 30.0643 135.328 29.9455C134.562 29.8268 134.068 29.1355 134.225 28.4015L136.238 18.9787C136.395 18.2447 137.143 17.746 137.909 17.8648Z"
        fill="#E5E7EA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M144.419 44.6866C144.579 43.8406 145.297 43.2678 146.024 43.4071L155.349 45.1956C156.075 45.335 156.535 46.1337 156.375 46.9796C156.215 47.8255 155.497 48.3984 154.77 48.259L145.445 46.4705C144.719 46.3312 144.26 45.5325 144.419 44.6866Z"
        fill="#E5E7EA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.569 21.0688C120.199 20.6203 121.027 20.7668 121.418 21.396L126.428 29.4435C126.82 30.0726 126.626 30.9463 125.996 31.3947C125.366 31.8432 124.538 31.6968 124.147 31.0676L119.137 23.0201C118.745 22.3909 118.939 21.5173 119.569 21.0688Z"
        fill="#E5E7EA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M153.229 28.3477C153.621 28.9769 153.428 29.8505 152.798 30.299L144.74 36.0354C144.11 36.4838 143.282 36.3374 142.89 35.7082C142.499 35.079 142.692 34.2054 143.322 33.7569L151.38 28.0205C152.01 27.572 152.838 27.7185 153.229 28.3477Z"
        fill="#E5E7EA"
      />
      <path
        d="M175.223 61.4356C178.957 62.4362 181.173 66.2745 180.173 70.0088L158.808 149.743C157.885 153.187 154.329 155.226 150.864 154.297L48.2737 126.809C44.8085 125.88 42.7479 122.336 43.6707 118.892L65.0355 39.1578C66.0361 35.4236 69.8744 33.2075 73.6087 34.2081L175.223 61.4356Z"
        fill="#E5E7EA"
      />
      <path
        d="M73.1342 34.028L175.724 61.5169C179.189 62.4454 181.244 66.0102 180.315 69.4801L178.961 74.5305L63.8239 43.6795L65.1772 38.6291C66.1069 35.1593 69.669 33.0995 73.1342 34.028Z"
        fill="#D3D8DE"
      />
      <path
        d="M73.6253 42.483C74.7059 42.7726 75.8168 42.1289 76.1063 41.0484C76.3962 39.9664 75.7556 38.8551 74.675 38.5656C73.596 38.2764 72.484 38.9181 72.1941 40.0002C71.9046 41.0806 72.5463 42.1939 73.6253 42.483Z"
        fill="#F5F6F7"
      />
      <path
        d="M79.9832 44.1862C81.0622 44.4753 82.1746 43.8321 82.4641 42.7516C82.754 41.6696 82.1118 40.5579 81.0328 40.2688C79.9523 39.9792 78.8418 40.6213 78.5519 41.7033C78.2624 42.7838 78.9026 43.8967 79.9832 44.1862Z"
        fill="#F5F6F7"
      />
      <path
        d="M86.3399 45.8898C87.4205 46.1794 88.5313 45.5357 88.8208 44.4552C89.1108 43.3732 88.4701 42.2619 87.3896 41.9724C86.309 41.6828 85.1986 42.3249 84.9086 43.407C84.6191 44.4874 85.2593 45.6003 86.3399 45.8898Z"
        fill="#F5F6F7"
      />
      <path
        d="M108.437 49.6644L137.347 57.4108"
        stroke="#F5F6F7"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.7457 72.0921L109.837 78.2793C111.304 78.6725 112.179 80.1908 111.785 81.6618C111.392 83.1312 109.874 84.0084 108.407 83.6152L85.316 77.428C83.847 77.0344 82.9717 75.5161 83.3655 74.0467C83.7596 72.5757 85.2767 71.6985 86.7457 72.0921Z"
        fill="#B8BEC5"
      />
      <path
        d="M83.7907 83.1205L97.2892 86.7374C98.7582 87.131 99.6334 88.6493 99.2397 90.1187C98.8456 91.5897 97.3285 92.467 95.8594 92.0733L82.3609 88.4564C80.8919 88.0628 80.0167 86.5445 80.4108 85.0735C80.8046 83.6041 82.3217 82.7269 83.7907 83.1205Z"
        fill="#D3D8DE"
      />
      <path
        d="M106.171 89.1173L110.433 90.2593C111.902 90.653 112.778 92.1712 112.384 93.6407C111.99 95.1117 110.473 95.9889 109.004 95.5953L104.741 94.4533C103.272 94.0596 102.397 92.5414 102.791 91.0704C103.185 89.601 104.702 88.7237 106.171 89.1173Z"
        fill="#B8BEC5"
      />
      <path
        d="M80.8359 94.1476L84.1516 95.036C85.619 95.4292 86.4943 96.9475 86.1005 98.417C85.7064 99.8879 84.1893 100.765 82.7218 100.372L79.4061 99.4835C77.9371 99.0899 77.0619 97.5717 77.456 96.1007C77.8498 94.6312 79.3669 93.754 80.8359 94.1476Z"
        fill="#D3D8DE"
      />
      <path
        d="M93.0315 97.4147L112.925 102.745C114.394 103.139 115.27 104.657 114.876 106.127C114.482 107.598 112.965 108.475 111.496 108.081L91.6017 102.751C90.1342 102.357 89.259 100.839 89.6532 99.3682C90.0469 97.8988 91.564 97.0215 93.0315 97.4147Z"
        fill="#B8BEC5"
      />
      <path
        d="M121.808 105.126L138.86 109.695C140.327 110.088 141.203 111.606 140.809 113.076C140.415 114.547 138.898 115.424 137.43 115.031L120.379 110.462C118.91 110.068 118.034 108.55 118.429 107.079C118.822 105.61 120.339 104.732 121.808 105.126Z"
        fill="#F5F6F7"
      />
      <path
        d="M77.8814 105.175L88.539 108.03C90.0064 108.423 90.8817 109.942 90.4875 111.413C90.0938 112.882 88.5767 113.759 87.1092 113.366L76.4516 110.511C74.9826 110.117 74.1074 108.599 74.5011 107.129C74.8952 105.658 76.4124 104.781 77.8814 105.175Z"
        fill="#B8BEC5"
      />
      <path
        d="M97.4192 110.409L99.7884 111.044C101.256 111.437 102.131 112.956 101.737 114.427C101.343 115.896 99.8261 116.773 98.3586 116.38L95.9894 115.745C94.522 115.352 93.6467 113.834 94.0405 112.364C94.4346 110.893 95.9517 110.016 97.4192 110.409Z"
        fill="#D3D8DE"
      />
      <path
        d="M108.669 113.424L122.761 117.2C124.228 117.593 125.103 119.111 124.709 120.582C124.315 122.052 122.798 122.929 121.331 122.536L107.239 118.76C105.771 118.366 104.896 116.848 105.29 115.379C105.684 113.908 107.201 113.031 108.669 113.424Z"
        fill="#B8BEC5"
      />
      <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
        <path
          d="M168.516 87.0221C177.379 90.6741 187.526 86.4398 191.179 77.5639C194.833 68.6881 190.611 58.5322 181.747 54.8802C172.884 51.2282 162.737 55.4625 159.084 64.3384C155.43 73.2142 159.652 83.3701 168.516 87.0221Z"
          fill="#B8BEC5"
        />
      </g>
      <path
        d="M170.174 83.7068C179.038 87.3589 189.185 83.1245 192.838 74.2487C196.492 65.3728 192.269 55.2169 183.406 51.5649C174.543 47.9129 164.396 52.1472 160.742 61.0231C157.089 69.8989 161.311 80.0548 170.174 83.7068Z"
        fill="#D3D8DE"
      />
      <path
        d="M180.095 58.7561L180.07 58.7444C178.344 58.0834 174.642 57.8855 172.994 61.8907C172.682 62.646 173.039 63.5077 173.795 63.8194C174.549 64.1307 175.411 63.7709 175.722 63.0156C176.621 60.8328 178.445 61.3016 178.997 61.4991C180.735 62.2262 181.43 63.6071 180.805 65.1285C180.343 66.2456 179.714 66.4593 178.174 66.7397C176.798 66.9863 174.711 67.3736 173.797 69.8104L173.635 70.2069C173.324 70.9622 173.681 71.8239 174.437 72.1356C175.183 72.4432 176.048 72.0959 176.378 71.3471L176.576 70.8659C176.879 70.0554 177.342 69.9049 178.71 69.6542L178.731 69.6534C180.197 69.3827 182.418 68.9604 183.533 66.2533C184.792 63.1944 183.382 60.1099 180.095 58.7561Z"
        fill="#F5F6F7"
      />
      <path
        d="M173.914 73.6481C173.082 73.3061 172.124 73.7046 171.78 74.5388C171.438 75.3719 171.837 76.3308 172.668 76.6743C173.513 77.0115 174.467 76.6203 174.802 75.7836C175.145 74.949 174.747 73.9921 173.914 73.6481Z"
        fill="#F5F6F7"
      />
    </Icon>
  );
};
