import { ButtonGroup, Icon, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { HiOutlineCog8Tooth } from "react-icons/hi2";

import { IconButton } from "../../../components";
import { BHShareIcon } from "../../components";
import AnalyticsReportEditModal from "./AnalyticsReportEditModal";
import AnalyticsReportShareModal from "./AnalyticsReportShareModal";

const AnalyticsReportMenu: React.FC<{
  canEditReport: boolean;
  refetchAllData: () => void;
}> = ({ canEditReport, refetchAllData }) => {
  const {
    isOpen: isShareModalOpen,
    onOpen: openShareModal,
    onClose: closeShareModal,
  } = useDisclosure();

  const {
    isOpen: isEditModalOpen,
    onOpen: openEditModal,
    onClose: closeEditModal,
  } = useDisclosure();

  return (
    <>
      <AnalyticsReportShareModal
        isOpen={isShareModalOpen}
        onClose={closeShareModal}
      />
      {canEditReport && (
        <AnalyticsReportEditModal
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          refetchAllData={refetchAllData}
        />
      )}
      <ButtonGroup ml="3">
        {canEditReport && (
          <IconButton
            aria-label="Edit Report Configuration"
            icon={<Icon as={HiOutlineCog8Tooth} boxSize={5} />}
            variant="icon"
            color="gray.900"
            onClick={openEditModal}
          />
        )}
        <IconButton
          aria-label="Share Report"
          icon={<BHShareIcon boxSize={5} />}
          variant="icon"
          color="gray.900"
          onClick={openShareModal}
        />
      </ButtonGroup>
    </>
  );
};

export default AnalyticsReportMenu;
