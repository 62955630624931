import {
  Box,
  ButtonProps,
  Icon,
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuListProps,
  Portal,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { HiDotsVertical, HiOutlineTrash } from "react-icons/hi";
import { HiVideoCamera, HiVideoCameraSlash } from "react-icons/hi2";
import { IoCreateSharp } from "react-icons/io5";

import { IconButton, MenuButton, useToast } from "../../../../components";
import { EditScheduledInterviewModal } from "../../../components/ScheduledInterviewModal";
import {
  ScheduledInterviewListItemFragment,
  ScheduledInterviewType,
  useDeleteScheduledInterviewMutation,
} from "../../../graphql";
import { useScheduledInterviewOptOut } from "../../../graphql/hooks";
import useCurrentUser from "../../../hooks/useCurrentUser";

type ScheduledInterviewMenuProps = {
  scheduledInterview: ScheduledInterviewListItemFragment;
  menuStyle?: MenuListProps;
} & ButtonProps;

const ScheduledInterviewMenu: React.FC<ScheduledInterviewMenuProps> = ({
  scheduledInterview,
  menuStyle,
  ...styles
}) => {
  const currentUser = useCurrentUser();
  const toast = useToast();
  const {
    toggleOptOut,
    canDisableRecording,
    interviewerOptOutAllowed: showOptOut,
    currentUserRecordingDisabled,
    loading: scheduledInterviewerOptOutLoading,
  } = useScheduledInterviewOptOut({ scheduledInterview });

  const [
    deleteScheduledInterview,
    { loading: deleteScheduledInterviewLoading },
  ] = useDeleteScheduledInterviewMutation({
    update(cache, { data }) {
      const scheduledInterview =
        data?.deleteScheduledInterview?.scheduledInterview;
      if (scheduledInterview) {
        const scheduledInterviewId = cache.identify(scheduledInterview);
        if (scheduledInterviewId) {
          cache.evict({ id: scheduledInterviewId });
          cache.gc();
        }
      }
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
    onCompleted: ({ deleteScheduledInterview }) => {
      if (!deleteScheduledInterview?.scheduledInterview) return;
      toast({
        title: "Success",
        status: "success",
        description: "Interview deleted.",
      });
    },
  });

  const {
    isOpen: isEditOpen,
    onClose: onCloseEdit,
    onOpen: onOpenEdit,
  } = useDisclosure();

  const showScheduledInterviewActions =
    currentUser.organization.allowManualScheduledInterviews;
  if (!(showOptOut || showScheduledInterviewActions)) {
    return <Box w="10" />;
  }

  const canEdit =
    scheduledInterview.scheduledType === ScheduledInterviewType.Manual ||
    scheduledInterview.scheduledType === ScheduledInterviewType.Email;
  const canDelete =
    scheduledInterview.scheduledType === ScheduledInterviewType.Manual;

  return (
    <>
      {isEditOpen && (
        <EditScheduledInterviewModal
          isOpen
          onClose={onCloseEdit}
          scheduledInterview={scheduledInterview}
        />
      )}
      <Menu placement="bottom-end" autoSelect={false}>
        <MenuButton
          as={IconButton}
          variant="icon"
          size="sm"
          ml={2}
          borderRadius="base"
          color="gray.500"
          icon={<Icon as={HiDotsVertical} boxSize={5} />}
          _focus={{}}
          data-testid={`scheduled-interview-${scheduledInterview.id}-menu-button`}
          {...styles}
        />
        <Portal>
          <MenuList borderRadius="base" maxW="270px" {...menuStyle}>
            {showScheduledInterviewActions && (
              <Tooltip
                label={
                  !canEdit
                    ? "Only manually scheduled interviews can be edited"
                    : null
                }
              >
                {/* Use span as workaround for chakra disabled button tooltip issue */}
                <span>
                  <MenuItem
                    icon={<Icon boxSize="18px" as={IoCreateSharp} />}
                    isDisabled={!canEdit || deleteScheduledInterviewLoading}
                    data-testid={`scheduled-interview-${scheduledInterview.id}-menu-option-edit`}
                    onClick={onOpenEdit}
                  >
                    Edit
                  </MenuItem>
                </span>
              </Tooltip>
            )}
            {showOptOut && (
              <Tooltip
                label={
                  !canDisableRecording
                    ? "You don’t have access to change this setting"
                    : null
                }
              >
                {/* Use span as workaround for chakra disabled button tooltip issue */}
                <span>
                  <MenuItem
                    isDisabled={
                      !canDisableRecording || scheduledInterviewerOptOutLoading
                    }
                    icon={
                      <Icon
                        boxSize="18px"
                        as={
                          currentUserRecordingDisabled
                            ? HiVideoCamera
                            : HiVideoCameraSlash
                        }
                        color={
                          currentUserRecordingDisabled ? undefined : "red.500"
                        }
                      />
                    }
                    data-testid={`scheduled-interview-${scheduledInterview.id}-menu-option-opt-out`}
                    onClick={toggleOptOut}
                  >
                    {currentUserRecordingDisabled
                      ? "Opt back in to recording"
                      : "Do not record interview"}
                  </MenuItem>
                </span>
              </Tooltip>
            )}
            {showScheduledInterviewActions && (
              <>
                <MenuDivider />
                <Tooltip
                  label={
                    !canDelete
                      ? "Only manually scheduled interviews can be deleted"
                      : null
                  }
                >
                  {/* Use span as workaround for chakra disabled button tooltip issue */}
                  <span>
                    <MenuItem
                      data-testid={`scheduled-interview-${scheduledInterview.id}-menu-option-delete`}
                      color="red.500"
                      _hover={canDelete ? { bg: "red.50" } : undefined}
                      isDisabled={!canDelete || deleteScheduledInterviewLoading}
                      icon={<Icon boxSize="18px" as={HiOutlineTrash} />}
                      onClick={() => {
                        deleteScheduledInterview({
                          variables: {
                            scheduledInterviewId: scheduledInterview.id,
                          },
                        });
                      }}
                    >
                      Delete
                    </MenuItem>
                  </span>
                </Tooltip>
              </>
            )}
          </MenuList>
        </Portal>
      </Menu>
    </>
  );
};

export default ScheduledInterviewMenu;
