import { Box, BoxProps } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";

import useIntersectionObserver from "../hooks/useIntersectionObserver";

type InfiniteScrollParams = {
  onLoadMore?(): void;
  observerOptions?: IntersectionObserverInit;
};

export const InfiniteScroll: React.FC<InfiniteScrollParams & BoxProps> = ({
  onLoadMore,
  observerOptions,
  ...styles
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const shouldLoadMore = useIntersectionObserver(ref, observerOptions);

  useEffect(() => {
    if (shouldLoadMore) {
      onLoadMore?.();
    }
  }, [shouldLoadMore]);

  return <Box ref={ref} {...styles} />;
};
