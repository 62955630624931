import { useEffect, useState } from "react";

import { useCallsAiTagsStatusQuery } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";

type AiTagsGenerationParams = {
  callIds: string[];
  shouldFetchTags: boolean;
  refetchTags?: () => void;
};

type AiTagsGeneration = {
  tagsGenerating: boolean;
};

export const useAiTagsGeneration = ({
  callIds,
  shouldFetchTags,
  refetchTags,
}: AiTagsGenerationParams): AiTagsGeneration => {
  const tagsAutoGenEnabled = useFeatureFlag(
    "ai_note_tags:candidate-page-generate"
  );
  const [shouldPoll, setShouldPoll] = useState(tagsAutoGenEnabled);

  const { data: statusData } = useCallsAiTagsStatusQuery({
    variables: {
      callIds,
      backfill: true,
    },
    pollInterval: shouldPoll ? 1000 : undefined,
    skip: !shouldFetchTags || !shouldPoll || callIds.length === 0,
  });

  const statuses = statusData?.callsAiTagsStatus;

  // Tag statuses
  const allTagsGenerated = statuses
    ?.filter((status) => status?.canGenerateTags)
    .every(
      (status) =>
        status?.tagsProcessingStatus === "COMPLETED" ||
        status?.tagsProcessingStatus === "FAILED"
    );
  useEffect(() => {
    if (statuses && allTagsGenerated) {
      refetchTags?.();
    }
  }, [statuses, allTagsGenerated, refetchTags]);

  // Polling controls
  useEffect(() => {
    if (statuses && statuses.length > 0) {
      if (allTagsGenerated) {
        setShouldPoll(false);
      }
    }
  }, [statuses, allTagsGenerated]);

  return {
    tagsGenerating:
      statuses?.some(
        (status) => status?.tagsProcessingStatus === "IN_PROGRESS"
      ) ?? false,
  };
};
