import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import {
  Link,
  LoadingIndicator,
  useToast,
  ZoomSettings,
} from "../../../components";
import config from "../../../config";
import {
  CurrentUserFragment,
  useAuthorizeZoomMutation,
  useOrgZoomSettingsLazyQuery,
  useUpdateOrganizationZoomSettingsMutation,
} from "../../graphql";
import SettingsNote from "./shared/SettingsNote";
import SettingsPageContainer from "./shared/SettingsPageContainer";
import StyledZoomImg from "./StyleZoomImg";

interface ZoomSettingsProps {
  currentUser: CurrentUserFragment;
}

const ZoomOrgSettings: React.FC<ZoomSettingsProps> = ({ currentUser }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const authCode = searchParams.get("code");
  const toast = useToast();

  const [updateOrganizationSettings] =
    useUpdateOrganizationZoomSettingsMutation({
      onCompleted: (data) => {
        if (data?.updateOrganizationZoomSettings?.currentUser) {
          toast({
            status: "success",
            title: "Organization Settings",
            description: "Success!",
          });
        }
      },
      onError: () => {
        toast({
          status: "error",
          title: "Organization Settings",
          description: "There was a problem - please try again",
        });
      },
    });

  const [getOrgZoomSettings, { data, loading: loadingAccountSettings }] =
    useOrgZoomSettingsLazyQuery({
      onError: () => {
        toast({
          status: "error",
          title: "Zoom Account Settings",
          description:
            "There was a problem fetching your Zoom account settings",
        });
      },
    });
  const settingsJson = data?.currentUser?.organization.zoomSettings;

  const [authorizeZoomMutation, { loading: loadingAuthorize }] =
    useAuthorizeZoomMutation({
      onError: () => {
        toast({
          status: "error",
          title: "Authorizing Zoom",
          description: "There was a problem - please try again",
        });
      },
    });

  const { organization } = currentUser;

  useEffect(() => {
    if (currentUser.userRole?.canManageIntegrationSettings) {
      if (authCode) {
        authorizeZoomMutation({ variables: { authCode } });
        navigate(location.pathname, { replace: true });
      } else if (organization.isZoomAuthorized) {
        getOrgZoomSettings();
      }
    }
  }, []);

  if (!currentUser.userRole?.canManageIntegrationSettings) {
    return (
      <SettingsPageContainer>
        <SettingsNote>Access denied</SettingsNote>
      </SettingsPageContainer>
    );
  }

  if (currentUser.organization.zoomNotetakerEnabled) {
    return (
      <SettingsPageContainer heading="BrightHire Zoom App">
        <SettingsNote>
          You can only use one Zoom recording integration at a time. Please
          disable the Zoom notetaker to use this Zoom integration.
        </SettingsNote>
      </SettingsPageContainer>
    );
  }

  if (loadingAccountSettings || loadingAuthorize) {
    return <LoadingIndicator />;
  }

  if (organization.isZoomAuthorized) {
    if (!settingsJson) {
      return null;
    }

    return (
      <SettingsPageContainer heading="Zoom Interview Assistant app">
        <FormControl id="automaticallyDeleteZoomRecordings" mb="8">
          <Checkbox
            defaultChecked={organization.automaticallyDeleteZoomRecordings}
            onChange={(event) => {
              updateOrganizationSettings({
                variables: {
                  automaticallyDeleteZoomRecordings: event.target.checked,
                },
              });
            }}
          >
            Automatically Delete Zoom Recordings
          </Checkbox>
          <FormHelperText>
            This automatically deletes Zoom recordings from the Zoom Cloud after
            import, which will free up your organization&apos;s Zoom storage.
            Recordings will be preserved in the Brighthire app.
          </FormHelperText>
        </FormControl>
        <Divider mb={6} />
        <Box mb={8}>
          {
            "This shows whether your organization's Zoom account settings are configured correctly to work with BrightHire. See the "
          }
          <Link href="https://help.brighthire.ai/en/articles/5491706-brighthire-x-zoom-integration-setup">
            Zoom Setup Instructions
          </Link>{" "}
          for more information.
        </Box>
        <ZoomSettings showLicense={false} settingsJson={settingsJson} />
      </SettingsPageContainer>
    );
  }

  if (config.zoomClientId) {
    return (
      <SettingsPageContainer heading="BrightHire Zoom App">
        <Box mb={4}>
          Please read the{" "}
          <Link href="https://help.brighthire.ai/en/articles/5491706-brighthire-x-zoom-integration-setup">
            Zoom Setup Instructions
          </Link>{" "}
          before clicking the button below.
        </Box>
        <a
          href={`https://zoom.us/oauth/authorize?response_type=code&client_id=${config.zoomClientId}&redirect_uri=${config.urlPrefix}/settings/zoom`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledZoomImg
            src="https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png"
            alt="Add to ZOOM"
          />
        </a>
      </SettingsPageContainer>
    );
  }

  return null;
};

export default ZoomOrgSettings;
