import { LinkProps as ChakraLinkProps } from "@chakra-ui/react";
import React from "react";
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from "react-router-dom";

import { Link as ChakraLink } from "../Link";

export type RouterLinkProps = {
  isDisabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
} & ChakraLinkProps &
  Omit<ReactRouterLinkProps, "color">;

export const RouterLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
  ({ isDisabled, onClick, ...rest }, ref) => (
    <ChakraLink
      as={ReactRouterLink}
      ref={ref}
      tabIndex={isDisabled ? -1 : undefined}
      aria-disabled={isDisabled}
      onClick={isDisabled ? (event) => event.preventDefault() : onClick}
      {...rest}
    />
  )
);

RouterLink.displayName = "RouterLink";
