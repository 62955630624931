import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { Button, LoadingIndicator } from "../../../../components";
import Alert from "../../../../components/Alert/Alert";
import {
  ButtonAction,
  ButtonText,
  ButtonVariant,
} from "../../../../components/ScheduledInterviewsLaunchButton";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  AddBrightHireToInterviewsMutation,
  Maybe,
  ScheduledInterviewListItemFragment,
  useAddBrightHireToInterviewsMutation,
  useCurrentUserScheduledInterviewsNotImportedLazyQuery,
} from "../../../graphql";
import { getErrorContent } from "../../integrations/getErrorContent";

const AddBrightHireBanner: React.FC<{
  onAddBrightHire: (results: AddBrightHireToInterviewsMutation) => void;
  updateInterviewIds: string[];
  loadCount: number;
}> = ({ onAddBrightHire, loadCount, updateInterviewIds }) => {
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Maybe<string>>(null);
  const [importable, setImportable] = useState<
    ScheduledInterviewListItemFragment[]
  >([]);
  const [inProgress, setInProgress] = useState<
    ScheduledInterviewListItemFragment[]
  >([]);
  const [buttonText, setButtonText] = useState<string>("Add BrightHire");
  const [buttonAction, setButtonAction] = useState<ButtonAction>(
    ButtonAction.ADD
  );
  const [buttonVariant, setButtonVariant] = useState<ButtonVariant>(
    ButtonVariant.ADD_OUTLINE
  );
  const sendGAEvent = useSendGAEvent();

  const updateMessage = (state: "updating" | "failed" | "add", n = 0): void => {
    const plural = n !== 1;
    switch (state) {
      case "failed":
        setMessage(
          "We were not able to add BrightHire to some of your interviews. Please try again later, or reach out if you need assistance."
        );
        break;
      case "updating":
        setMessage(
          `We are completing some final checks on your ${
            plural ? "interviews" : "interview"
          }.`
        );
        break;
    }
  };

  const [getCurrentUserScheduledInterviews, { loading }] =
    useCurrentUserScheduledInterviewsNotImportedLazyQuery({
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const importables =
          data?.currentUser?.scheduledInterviewsNotImported?.filter((i) => {
            return i.canAddBrighthire && !updateInterviewIds.includes(i.id);
          }) ?? [];
        const updating =
          data?.currentUser?.scheduledInterviewsNotImported?.filter((i) => {
            return updateInterviewIds.includes(i.id);
          }) ?? [];
        setImportable(importables);
        setInProgress(updating);
        if (updating?.length) {
          setShowLoading(true);
          updateMessage("updating", updating.length);
        } else {
          setShowLoading(false);
        }
      },
    });

  // When a new banner load is requested, get a list of scheduled interviews
  useEffect(() => {
    const date = new Date().toISOString().substring(0, 19);
    if (loadCount) {
      getCurrentUserScheduledInterviews({
        variables: {
          start: date,
          end: null,
          positionId: null,
        },
      });
    }
  }, [loadCount]);

  // Queue interview(s) to have imports updated
  const [addBrightHire] = useAddBrightHireToInterviewsMutation({
    // Set the join URL for the button to launch the IA with it
    onCompleted: (interviewsData) => {
      const results = interviewsData?.addBrightHireToInterviews;
      const errors = results?.errors;
      onAddBrightHire(interviewsData);
      if (errors?.length) {
        updateMessage("failed");
        setButtonAction(ButtonAction.ERROR);
        setButtonVariant(ButtonVariant.ERROR_OUTLINE);
      } else if (results?.scheduledInterviews?.length) {
        updateMessage("updating", results.scheduledInterviews.length);
        setButtonAction(ButtonAction.UPDATING);
        setButtonText(ButtonText.UPDATING);
        setButtonVariant(ButtonVariant.UPDATING_OUTLINE);
      }
    },
    // Set error state
    onError: (err) => {
      const message = getErrorContent(err.message);
      setButtonAction(ButtonAction.ERROR);
      setButtonVariant(ButtonVariant.ERROR_OUTLINE);
      setButtonText("Operation failed");
      setMessage(`${message.primary} ${message.secondary}`);
    },
  });

  /**
   * Execute a manual button click
   */
  const addBrightHireManually = (): void => {
    setButtonAction(ButtonAction.LOADING);
    if (importable?.length) {
      const scheduledInterviewIds = importable?.map((i) => {
        return i.id;
      });
      sendGAEvent(
        "add_bh",
        "calling",
        "batch",
        scheduledInterviewIds.join(",")
      );
      addBrightHire({
        variables: {
          scheduledInterviewIds,
          source: "banner",
        },
      });
    } else {
      setButtonAction(ButtonAction.ERROR);
      setButtonVariant(ButtonVariant.ERROR_OUTLINE);
      setButtonText("Nothing to import");
    }
  };

  const trigger =
    buttonVariant === "disabled" ? (
      <Button size="xs" isDisabled data-testid="add-brighthire-banner-button">
        {buttonText}
      </Button>
    ) : (
      <Button
        size="xs"
        variant={buttonVariant}
        onClick={() => {
          if (buttonAction === ButtonAction.ADD) {
            addBrightHireManually();
          }
        }}
        data-testid="add-brighthire-banner-button"
      >
        {buttonText}
      </Button>
    );

  if (loading && !showLoading) return null;
  if (loading) {
    return (
      <>
        <Alert status="warning" mt={4} description="Loading...">
          <LoadingIndicator size="xs" />
        </Alert>
        <Box pt="3" width="100%" borderBottom="gray.100" />
      </>
    );
  }

  if (inProgress?.length) {
    return (
      <>
        <Alert status="warning" mt={4} description={message}>
          {buttonAction !== "loading" && trigger}
          {buttonAction === "loading" && <LoadingIndicator size="xs" />}
        </Alert>
        <Box
          pt="3"
          width="100%"
          borderBottom="gray.100"
          data-testid="add-brighthire-banner"
        />
      </>
    );
  }
  return null;
};

export default AddBrightHireBanner;
