import React from "react";

import { StarRating, successToast, useToast } from "../../../components";
import { StarRatingProps } from "../../../components/StarRating/StarRating";
import { formatRelativeDate } from "../../../utils/datetime";
import {
  ScorecardItem,
  useUpdateScorecardCompetencyMutation,
} from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";

type CompetencyStarRatingProps = {
  competency: Pick<ScorecardItem, "id" | "score" | "notes">;
  showNoRating?: boolean;
  shouldHideToastMessage?: boolean;
} & Omit<StarRatingProps, "ratingDescriptions">;

const CompetencyStarRating: React.FC<CompetencyStarRatingProps> = ({
  competency,
  onChange,
  showNoRating = false,
  shouldHideToastMessage = false,
  ...rest
}) => {
  const currentUser = useCurrentUser();
  const toast = useToast();
  const { competencyRatingDescriptions: ratingDescriptions } =
    currentUser.organization;
  const [updateScorecardCompetency] = useUpdateScorecardCompetencyMutation();

  return (
    <StarRating
      rating={competency.score || undefined}
      onChange={(rating) => {
        updateScorecardCompetency({
          variables: {
            scorecardCompetencyId: competency.id,
            score: rating,
            notes: competency.notes,
          },
          optimisticResponse: {
            updateScorecardCompetency: {
              __typename: "UpdateScorecardCompetency",
              scorecardItem: {
                __typename: "ScorecardItem",
                id: competency.id,
                score: rating,
                notes: competency.notes,
                updatedAt: formatRelativeDate(new Date().toISOString()),
              },
            },
          },
          onCompleted: () => {
            if (!shouldHideToastMessage) {
              successToast(toast, "Scores updated", { isClosable: true });
            }
          },
        });
        onChange?.(rating);
      }}
      ratingDescriptions={
        ratingDescriptions ?? currentUser.organization.ratingDescriptions
      }
      colors={{ default: "gray.200", filled: "blue.500", active: "blue.300" }}
      {...rest}
    />
  );
};

CompetencyStarRating.displayName = "CompetencyStarRating";

export default CompetencyStarRating;
