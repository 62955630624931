import { Box } from "@chakra-ui/react";
import React from "react";
import { SortingRule } from "react-table";

import { RouterLink, SortableTable } from "../../../components";
import { formatDatetime } from "../../../utils/datetime";
import { PageOptions } from "../../../utils/types";
import { Client } from "../../graphql";
import useLink from "../../hooks/useLink";

interface HiringTeamGroupListProps {
  clients: Client[] | any;
  pageOptions?: PageOptions;
  loading?: boolean;
  sortBy: SortingRule<string>;
}

const HiringTeamGroupList: React.FC<HiringTeamGroupListProps> = ({
  clients,
  sortBy,
  pageOptions,
  loading,
}) => {
  return (
    <Box mb="8">
      <SortableTable<Client>
        columns={[
          {
            Header: "Hiring team group",
            accessor: "name",
            Cell: ({
              row: { original: client },
            }: {
              row: { original: Client };
            }) => {
              const hiringTeamLink = useLink({
                type: "departmentTeam",
                clientId: client.id,
              });
              return <RouterLink to={hiringTeamLink}>{client.name}</RouterLink>;
            },
          },
          {
            Header: "Members",
            accessor: "clientMembershipsCount",
          },
          {
            Header: "Positions",
            accessor: "positionsCount",
          },
          {
            Header: "Date added",
            accessor: "createdAt",
            Cell: ({
              row: { original: client },
            }: {
              row: { original: Client };
            }) => (
              <Box whiteSpace="nowrap">{formatDatetime(client.createdAt)}</Box>
            ),
          },
        ]}
        data={clients}
        initialSort={sortBy}
        manualPagination
        pageOptions={pageOptions}
        loading={loading}
      />
    </Box>
  );
};

export default HiringTeamGroupList;
