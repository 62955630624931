import { Box, BoxProps, Flex, Icon, Tag, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IconType } from "react-icons/lib";
import { useLocation, useNavigate } from "react-router-dom";

import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { canViewIQReport } from "../../../utils/permissions";
import Sidebar, { SidebarItem } from "../../components/Sidebar";
import { AnalyticsDimension, AtsDataState, MetricName } from "../../graphql";
import { useFeatureFlagForCurrentOrImpersonatedUser } from "../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../hooks/useCurrentUser";
import { MetricConfig } from "./MetricConfig";
import useSidebarConfig, { NavMetricGroup } from "./useSidebarConfig";

// nav state helpers
const setSearchSegment = (
  search: string,
  value: AnalyticsDimension
): string => {
  const params = new URLSearchParams(search);
  params.set("segment", value);
  return params.toString();
};
const getSearchSegment = (search: string): string | null => {
  const segment = new URLSearchParams(search).get("segment");
  return segment;
};

export type AnalyticsSidebarProps = {
  path: MetricName;
  atsDataState: AtsDataState;
} & BoxProps;

const AnalyticsSidebar: React.FC<AnalyticsSidebarProps> = ({
  path,
  atsDataState,
  ...rest
}) => {
  const user = useCurrentUser();

  const canViewReport = canViewIQReport(user);
  const reportFlagEnabled = useFeatureFlagForCurrentOrImpersonatedUser(
    "analytics:quality-report"
  );
  const iqReportEnabled = canViewReport && reportFlagEnabled;
  const navigate = useNavigate();
  const location = useLocation();
  const sendGAEvent = useSendGAEvent();
  const [navState, setNavState] = useState({
    path,
    segment:
      getSearchSegment(window.location.search) || AnalyticsDimension.None,
  });
  useEffect(() => {
    setNavState({
      path,
      segment:
        getSearchSegment(window.location.search) || AnalyticsDimension.None,
    });
  }, [path]);

  const navMetricGroups: NavMetricGroup[] = useSidebarConfig(atsDataState);

  const accordionDefaultIndices: number[] = [];
  navMetricGroups.forEach((group: NavMetricGroup, idx: number) => {
    accordionDefaultIndices.push(idx);
  });

  useEffect(() => {
    sendGAEvent("metric_view", "analytics", path.toLowerCase());
  }, [path]);

  return (
    <Sidebar data-tour-id="analytics-sidebar" borderWidth="0px" {...rest}>
      {navMetricGroups.map((metricGroup, idx) => (
        <Box
          key={metricGroup.title}
          borderBottom={
            idx !== navMetricGroups.length - 1 ? "1px solid" : undefined
          }
          borderColor="gray.200"
          mb="3"
          pb="3"
          pt={idx === 0 ? "3" : 0}
        >
          <>
            <SectionHeader
              title={metricGroup.title}
              icon={metricGroup.icon}
              badge={metricGroup.badge}
              isExpanded
            />
            <Box
              data-testid={`analytics-sidebar-panel-${metricGroup.title
                .toLowerCase()
                .replace(" ", "-")}`}
            >
              {metricGroup.title === "Overall Metrics" && iqReportEnabled && (
                <SidebarItem
                  text="Interview Quality"
                  active={location.pathname.includes("report")}
                  px="6"
                  onClick={() => {
                    navigate({
                      pathname: `/insights/report`,
                      search: window.location.search,
                    });
                  }}
                />
              )}
              {metricGroup.metrics.map((metric) => {
                const segment =
                  metricGroup.defaultSegmentation || AnalyticsDimension.None;
                const active = navState.path === metric;

                return (
                  <MetricSidebarItem
                    key={`${metric}${
                      metricGroup.defaultSegmentation ? "_segment" : "_none"
                    }`}
                    metric={metric}
                    active={active}
                    onClick={() => {
                      setNavState({
                        path: metric,
                        segment,
                      });
                      navigate({
                        pathname: `/insights/${metric.toLowerCase()}`,
                        search: setSearchSegment(
                          window.location.search,
                          segment
                        ),
                      });
                    }}
                  />
                );
              })}
            </Box>
          </>
        </Box>
      ))}
    </Sidebar>
  );
};

type MetricSidebarItemProps = {
  metric: MetricName;
  active: boolean;
  onClick: () => void;
};

const MetricSidebarItem: React.FC<MetricSidebarItemProps> = ({
  metric,
  active,
  onClick,
}) => {
  const { displayName } = MetricConfig[metric];
  return (
    <SidebarItem
      text={displayName}
      onClick={onClick}
      active={active}
      data-tour-id={`analytics-${metric.toLocaleLowerCase()}`}
      px={6}
      fontWeight="500"
      fontSize="sm"
      borderRadius="0"
    />
  );
};

export const SectionHeader: React.FC<{
  title: string;
  icon: IconType;
  badge?: string;
  isExpanded: boolean;
}> = ({ title, icon, badge }) => {
  return (
    <Box
      role="heading"
      mx="6"
      fontSize="sm"
      color="gray.400"
      data-tour-id={`analytics-${title.toLowerCase()}`}
      height="40px"
      lineHeight="40px"
    >
      <Flex flex="1" alignItems="center" justifyContent="flex-start">
        <Icon as={icon} mr="1.5" />
        <Text fontWeight="500" fontSize="xs" textTransform="uppercase">
          {title}
        </Text>
        {badge && (
          <Tag size="sm" ml="auto" borderRadius="full">
            {badge}
          </Tag>
        )}
      </Flex>
    </Box>
  );
};

export default AnalyticsSidebar;
