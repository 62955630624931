import React, { ForwardedRef, forwardRef, useRef } from "react";
import Select, { Props as SelectProps, SelectInstance } from "react-select";

/**
 * A thin wrapper around react-select to fix the issue of
 * pressing "Enter" when no matching options submitting
 * the enclosing form
 *
 * - https://github.com/JedWatson/react-select/discussions/4475
 * - https://github.com/JedWatson/react-select/issues/3823
 */
const BugFixedSelect = <TOption, IsMulti extends boolean>(
  props: SelectProps<TOption, IsMulti>,
  ref: ForwardedRef<SelectInstance<TOption, IsMulti>>
): React.ReactElement => {
  const localRef = useRef<SelectInstance<TOption, IsMulti> | null>(null);

  return (
    <Select<TOption, IsMulti>
      {...props}
      ref={(el) => {
        localRef.current = el;

        if (isMutableRefObject(ref)) {
          // eslint-disable-next-line no-param-reassign
          ref.current = el;
        } else {
          ref?.(el);
        }
      }}
      onKeyDown={(e) => {
        const focusedOption = localRef.current?.state.focusedOption;
        // Normally, pressing "Enter" will select the focused option.
        // But if there is no focused option, pressing "Enter" will
        // submit the enclosing form——which we'd like to prevent
        if (e.key === "Enter" && !focusedOption) {
          localRef.current?.blur();
          e.preventDefault();
        }

        props.onKeyDown?.(e);
      }}
    />
  );
};

export default forwardRef(BugFixedSelect) as <
  TOption,
  IsMulti extends boolean = false
>(
  props: SelectProps<TOption, IsMulti> & {
    ref: ForwardedRef<SelectInstance<TOption, IsMulti>>;
  }
) => React.ReactElement;

const isMutableRefObject = <T,>(ref: any): ref is React.MutableRefObject<T> => {
  return !!ref && Object.hasOwn(ref, "current");
};
