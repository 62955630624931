import { Textarea, TextareaProps } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";

export const ExpandableTextArea = React.forwardRef<
  HTMLTextAreaElement,
  TextareaProps & { disabled?: boolean }
>((props, ref) => {
  const [height, setHeight] = useState("2rem");
  const onInput = useCallback((e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // If the user manually changes the height of the text box, that
    // will take precedence over this and this will no longer change the
    // element's height.

    // To take control back you would need to remove the height element
    // from the actual <textarea>. The browser adds an inline
    // margin-top:0;margin-bottom:0;height:Npx; to the element.
    const t = e.target as HTMLTextAreaElement;
    setHeight(`${t.scrollHeight}px`);
  }, []);
  return (
    <Textarea
      ref={ref}
      {...props}
      overflowY="hidden"
      overflow="auto"
      height={height}
      onInput={onInput}
    />
  );
});
