import React from "react";

import { dateRange30DaysAgo } from "../../../../components/SelectDateRange/SelectDateRange";
import { formatISODate } from "../../../../utils/datetime";
import { MetricName, useAnalyticsAlertsQuery } from "../../../graphql";
import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";
import useAnalyticsContext from "../useAnalyticsContext";
import useDashboardStat from "../useDashboardStat";
import { calculateMetricStats, getMockAlerts } from "../utils";
import AnalyticsOverview from "./AnalyticsOverview";

const AnalyticsOverviewWrapper: React.FC = () => {
  const { selectedOrgId, showBHDemoDashboard } = useAnalyticsContext();

  const mockDataEnabled = useFeatureFlag("internal:analytics:demo-data");
  const showBHDemoData =
    mockDataEnabled &&
    showBHDemoDashboard &&
    selectedOrgId === "35bb5f6b-2733-464b-a561-35b4c941e824";

  const { data } = useAnalyticsAlertsQuery({
    skip: showBHDemoData,
    variables: { requestedOrganizationId: selectedOrgId },
  });

  let dashboardConfig = {
    queryOptions: {
      dateRangeStart: formatISODate(dateRange30DaysAgo().start),
      dateRangeEnd: formatISODate(dateRange30DaysAgo().end),
      organizationId: selectedOrgId,
    },
    urlDateParam: "date=last_30",
    summaryClause: "last 30 days",
  };

  if (showBHDemoData) {
    dashboardConfig = {
      queryOptions: {
        dateRangeStart: "2021-01-01",
        dateRangeEnd: "2022-05-31",
        organizationId: selectedOrgId,
      },
      urlDateParam: "datemin=2021-01-01&datemax=2022-05-31",
      summaryClause: "year 2021-2022",
    };
  }

  const apiAlerts = data?.alerts?.alerts || [];
  const alerts = showBHDemoData ? getMockAlerts() : apiAlerts;

  const totalInterviewsStat = useDashboardStat(
    MetricName.TotalInterviews,
    dashboardConfig.queryOptions
  );
  const { totalInterviews, numXValues: totalInterviewers } =
    calculateMetricStats(
      totalInterviewsStat.dataPoints || [],
      MetricName.TotalInterviews
    );

  const summaryText = totalInterviewsStat.dataPoints
    ? `In the ${dashboardConfig.summaryClause}, our ${totalInterviewers} interviewers
          conducted ${totalInterviews} interviews.`
    : "";

  return (
    <AnalyticsOverview
      alerts={alerts}
      dashboardConfig={dashboardConfig}
      summaryText={summaryText}
    />
  );
};

export default AnalyticsOverviewWrapper;
