/* eslint-disable @typescript-eslint/ban-types */
import {
  Box,
  BoxProps,
  ButtonGroup,
  Center,
  Flex,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import React, { useEffect, useMemo } from "react";
import {
  MdChevronLeft,
  MdChevronRight,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from "react-icons/md";
import {
  Column,
  Row,
  SortByFn,
  SortingRule,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

import { PageOptions } from "../../utils/types";
import { Button, IconButton } from "../Buttons";
import { Card } from "../Card";
import { LoadingIndicator } from "../LoadingIndicator";

const StyledTable = styled("table")`
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
`;

const range = (start: number, end: number): Array<number> => {
  return Array.from(Array(end - start + 1), (_, i) => i + start);
};

/*
Returns an array of maxLength (or less) page numbers
where a 0 in the returned array denotes a gap in the series
on the basis of which seperator can be inserted
*/

export const generatePages = (
  totalPages: number,
  page: number
): Array<number> => {
  const MAX_LENGTH = 5;
  const MAX_CONSECUTIVE_PAGES = 3;
  const leftWidth =
    page > MAX_CONSECUTIVE_PAGES && page <= totalPages - MAX_CONSECUTIVE_PAGES
      ? 1
      : 0;
  const rightWidth = page <= totalPages - MAX_CONSECUTIVE_PAGES ? 1 : 0;

  // no breaks in list
  if (totalPages <= MAX_LENGTH) {
    return range(1, totalPages);
  }
  if (page === MAX_CONSECUTIVE_PAGES) {
    return range(1, MAX_LENGTH - MAX_CONSECUTIVE_PAGES + 2).concat(
      0,
      totalPages
    );
  }
  if (page === totalPages - MAX_CONSECUTIVE_PAGES + 1) {
    return range(1, 1).concat(
      0,
      range(totalPages - MAX_CONSECUTIVE_PAGES, totalPages)
    );
  }
  // no break on left of page
  if (page < MAX_LENGTH - MAX_CONSECUTIVE_PAGES + 1) {
    return range(1, MAX_LENGTH - MAX_CONSECUTIVE_PAGES + 1).concat(
      0,
      totalPages
    );
  }
  // no break on right of page
  if (page > totalPages - MAX_CONSECUTIVE_PAGES + 1) {
    return range(1, 1).concat(
      0,
      range(totalPages - MAX_CONSECUTIVE_PAGES + 1, totalPages)
    );
  }
  // Breaks on both sides
  return range(1, 1).concat(
    0,
    range(page - leftWidth, page + rightWidth),
    0,
    totalPages
  );
};

const TH: React.FC<BoxProps> = (props) => (
  <Box
    as="th"
    textAlign="left"
    fontSize="sm"
    fontWeight="500"
    color="gray.500"
    backgroundColor="gray.50"
    px={4}
    py={2}
    {...props}
  />
);

const HOVER_CSS_PROP = {
  borderRadius: "4px",
  background: "blue.600",
  color: "white",
};

const TR: React.FC<BoxProps> = (props) => (
  <Box
    as="tr"
    borderTop="1px"
    borderBottom="1px"
    borderColor="border"
    {...props}
  />
);

const TD: React.FC<BoxProps> = (props) => (
  <Box as="td" fontSize="sm" lineHeight="19px" px={4} py={3} {...props} />
);

export interface TableColumn {
  Header: string;
  id?: string;
  accessor?: string;
  Cell?: (row: any) => JSX.Element;
}

export interface TableProps {
  columns: Array<TableColumn>;
  data: Array<object>;
}

const renderCell = (
  column: TableColumn,
  row: { [key: string]: any }
): JSX.Element => {
  if (column.Cell) {
    return column.Cell(row);
  }
  if (column.accessor) {
    return row[column.accessor];
  }
  throw new Error("missing Table column Cell or accessor");
};

export const Table: React.FC<TableProps> = ({ columns, data }) => {
  return (
    <Card width="fit-content">
      <StyledTable>
        <thead>
          <tr>
            {columns.map((column, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <TH key={i}>{column.Header}</TH>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <TR key={i}>
              {columns.map((column) => (
                <TD key={column.id ?? column.accessor}>
                  {renderCell(column, row)}
                </TD>
              ))}
            </TR>
          ))}
        </tbody>
      </StyledTable>
    </Card>
  );
};

interface IndeterminateCheckboxProps {
  indeterminate?: boolean;
}

const IndeterminateCheckbox: React.FC<IndeterminateCheckboxProps> = ({
  indeterminate,
  ...rest
}) => {
  const defaultRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (defaultRef?.current) {
      defaultRef.current.indeterminate = indeterminate ?? false;
    }
  }, [indeterminate]);

  return <input type="checkbox" ref={defaultRef} {...rest} />;
};

export type SortableTableColumn<Type extends Object> = Column<Type> & {
  sortType?: string | SortByFn<Type>;
  show?: boolean;
  /**
   * Set to `false` to skip rendering empty cells. Currently only
   * supported in compact view
   */
  showEmpty?: boolean;
  sortDescFirst?: boolean;
  disableSortBy?: boolean;
  headerHelperText?: string;
};

export interface SortableTableProps<Type extends object> {
  columns: Array<SortableTableColumn<Type>>;
  data: Array<Type>;
  initialSort: SortingRule<string>;
  renderSelectedRowActions?: (rows: Row<Type>[]) => React.ReactNode;
  manualPagination?: boolean;
  pageOptions?: PageOptions;
  loading?: boolean;
  autoResetSortBy?: boolean;
  width?: string;
  checkboxColumnWidth?: string;
  disableCheckboxes?: boolean;
  includeSpacerColumn?: boolean;
  columnWidths?: Record<number, any>;
  disablePaddingOnColumns?: string[];
  /**
   * Whether to use a compact display without table headers.
   * Typically this prop would be based on a calculation of screen size
   */
  shouldShowCompactView?: boolean;
  disableSort?: boolean;
  cardVariant?: string;
}

export function SortableTable<Type extends {}>(
  props: SortableTableProps<Type>
): JSX.Element {
  const {
    columns,
    data,
    initialSort,
    renderSelectedRowActions,
    checkboxColumnWidth,
    manualPagination = false,
    pageOptions,
    columnWidths,
    loading = false,
    autoResetSortBy = true,
    width = "fit-content",
    disableSort = false,
    disableCheckboxes = false,
    includeSpacerColumn = false,
    shouldShowCompactView = false,
    cardVariant = "white",
    disablePaddingOnColumns,
  } = props;
  const filteredColumns = useMemo(
    () => columns.filter((column) => column.show !== false),
    [columns]
  );
  const manualSortBy =
    disableSort || (manualPagination && pageOptions !== undefined);
  const {
    getTableProps,
    getTableBodyProps,
    headers,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { sortBy },
  } = useTable<Type>(
    {
      columns: filteredColumns,
      data,
      disableMultiSort: true,
      disableSortRemove: true,
      manualPagination,
      manualSortBy,
      initialState: {
        sortBy: [initialSort],
      },
      autoResetSortBy,
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      if (renderSelectedRowActions && !disableCheckboxes) {
        hooks.columns.push(() => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({
              getToggleAllRowsSelectedProps,
            }: {
              getToggleAllRowsSelectedProps: () => IndeterminateCheckboxProps;
            }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }: { row: Row<Type> }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...filteredColumns,
        ]);
      }
    }
  );
  useEffect(() => {
    if (manualSortBy) {
      pageOptions?.onChangeSort?.(sortBy);
    }
  }, [sortBy]);

  return (
    <Card
      width={width}
      variant={cardVariant}
      overflowX={width === "min-content" ? "scroll" : "hidden"}
      borderWidth={1}
      borderTopWidth={shouldShowCompactView ? 0 : 1}
      borderColor="border"
      borderStyle="solid"
    >
      {loading ? (
        <Flex position="fixed" zIndex={2} top="50%" left="50%">
          <LoadingIndicator delay={0} />
        </Flex>
      ) : null}
      {renderSelectedRowActions && (
        <Flex
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          px={2}
          minHeight={10}
          borderBottom="1px"
          borderColor="border"
        >
          {renderSelectedRowActions(selectedFlatRows)}
        </Flex>
      )}
      <StyledTable {...getTableProps()}>
        {!shouldShowCompactView && (
          <thead>
            <tr>
              {headers.map((column, i) => {
                return (
                  <TH
                    {...column.getHeaderProps(
                      column.canSort
                        ? column.getSortByToggleProps({
                            // eslint-disable-next-line
                            // @ts-ignore
                            title: column.headerHelperText
                              ? undefined
                              : "Change sort",
                          }) // hover tooltip text
                        : undefined
                    )}
                    width={columnWidths ? columnWidths[i] : null}
                  >
                    <Tooltip
                      // useTable hook does not return our modified SortableTableColumn type for headers
                      //
                      // eslint-disable-next-line
                      // @ts-ignore
                      label={column.headerHelperText}
                      visibility={
                        // eslint-disable-next-line
                        // @ts-ignore
                        column.headerHelperText ? "visible" : "hidden"
                      }
                    >
                      <Flex>
                        <>
                          {column.render("Header")}
                          {column.canSort && (
                            <Icon
                              ml={1}
                              h="5"
                              w="5"
                              as={
                                column.isSortedDesc
                                  ? MdKeyboardArrowDown
                                  : MdKeyboardArrowUp
                              }
                              color={
                                column.isSorted ? "inherit" : "transparent"
                              }
                            />
                          )}
                        </>
                      </Flex>
                    </Tooltip>
                  </TH>
                );
              })}
              {includeSpacerColumn && <TH />}
            </tr>
          </thead>
        )}
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TR {...row.getRowProps()}>
                {shouldShowCompactView ? (
                  <TD
                    pt="3"
                    pb="4"
                    px="4"
                    display="flex"
                    flexDirection="column"
                    gap="2"
                  >
                    {row.cells.map((cell) => {
                      const column = cell.column as SortableTableColumn<Type>;
                      if (column.showEmpty === false && !cell.value) {
                        return null;
                      }

                      return (
                        <Box
                          fontSize="sm"
                          lineHeight="5"
                          color="gray.900"
                          _last={{ color: "gray.600" }}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </Box>
                      );
                    })}
                  </TD>
                ) : (
                  row.cells.map((cell, idx) => {
                    const props = cell.getCellProps();
                    if (
                      idx === 0 &&
                      renderSelectedRowActions &&
                      !disableCheckboxes &&
                      checkboxColumnWidth
                    ) {
                      if (!props.style) props.style = {};
                      props.style.width = checkboxColumnWidth;
                    }
                    if (disablePaddingOnColumns?.includes(cell.column.id)) {
                      return (
                        <Box
                          as="td"
                          fontSize="sm"
                          lineHeight="19px"
                          p={0}
                          {...props}
                        >
                          {cell.render("Cell")}
                        </Box>
                      );
                    }
                    return <TD {...props}>{cell.render("Cell")}</TD>;
                  })
                )}
              </TR>
            );
          })}
          {includeSpacerColumn && <TR />}
        </tbody>
      </StyledTable>
      {manualPagination && pageOptions && pageOptions.totalPages > 1 ? (
        <Center mt={4}>
          <Flex pb={5} mt={2}>
            <IconButton
              aria-label="Previous Page"
              icon={<MdChevronLeft />}
              size="sm"
              color="gray"
              background="white"
              fontSize="24px"
              mr={1}
              isDisabled={pageOptions.currentPage === 1}
              _disabled={{
                cursor: "default",
                background: "white",
                color: "gray.300",
                _hover: { background: "white", color: "gray.300" },
              }}
              _hover={HOVER_CSS_PROP}
              onClick={() => {
                pageOptions.handlePageNumberChange(
                  Math.max(1, pageOptions.currentPage - 1)
                );
              }}
            />
            <ButtonGroup spacing={1} size="sm">
              {generatePages(
                pageOptions.totalPages,
                pageOptions.currentPage
              ).map((page, index) => {
                if (page === 0) {
                  return (
                    <Flex
                      color="gray"
                      background="white"
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      pt={1}
                    >
                      ...
                    </Flex>
                  );
                }
                return (
                  <Button
                    color={
                      page === pageOptions.currentPage ? "link.default" : "gray"
                    }
                    background="white"
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    onClick={() => {
                      pageOptions.handlePageNumberChange(page);
                    }}
                    borderBottom="2px"
                    borderRadius={0}
                    _hover={HOVER_CSS_PROP}
                    borderBottomColor={
                      page === pageOptions.currentPage
                        ? "link.default"
                        : "transparent"
                    }
                  >
                    {page}
                  </Button>
                );
              })}
            </ButtonGroup>
            <IconButton
              aria-label="Next Page"
              icon={<MdChevronRight />}
              size="sm"
              color="gray"
              background="white"
              ml={1}
              fontSize="24px"
              disabled={pageOptions.currentPage === pageOptions.totalPages}
              _disabled={{
                cursor: "default",
                background: "white",
                color: "gray.300",
                _hover: { background: "white", color: "gray.300" },
              }}
              _hover={HOVER_CSS_PROP}
              onClick={() => {
                pageOptions.handlePageNumberChange(
                  Math.min(pageOptions.totalPages, pageOptions.currentPage + 1)
                );
              }}
            />
          </Flex>
        </Center>
      ) : null}
    </Card>
  );
}
