import { Flex } from "@chakra-ui/react";
import React from "react";

import { SelectDateRange, useTheme } from "../../../components";
import { SelectDateRangeState } from "../../../components/SelectDateRange/SelectDateRange";
import { useAnalyticsSelectTheme } from "./useAnalyticsSelectTheme";

type AnalyticsDateSelectProps = {
  state: SelectDateRangeState;
  onSelect: (newValue: SelectDateRangeState | undefined) => void;
};

const AnalyticsDateSelect: React.FC<AnalyticsDateSelectProps> = ({
  state,
  onSelect,
}) => {
  const { colors } = useTheme();
  const [selectTheme, selectStyles] = useAnalyticsSelectTheme();

  return (
    <Flex minW="148px">
      <SelectDateRange
        state={state}
        onSelect={onSelect}
        includeLabel={false}
        selectStyles={selectStyles}
        selectTheme={selectTheme}
        rangeStyleProps={{ wrap: "nowrap" }}
        dateInputStyleProps={{
          fontSize: "14px",
          color: colors.gray[900],
          borderColor: colors.gray[100],
        }}
        gaCategory="analytics"
      />
    </Flex>
  );
};

export default AnalyticsDateSelect;
