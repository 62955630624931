import {
  AnalyticsTopicSpeakerType,
  CustomTopicMatchRule,
} from "../../../graphql";
import { DefaultTopic, TopicOption } from "./types";

export const formatTopicName = (topic: DefaultTopic): string => {
  if (topic === DefaultTopic.Ai || topic === DefaultTopic.Dei) {
    return topic.toLocaleUpperCase();
  }
  return topic;
};

export const NEW_TOPIC_OPTION: TopicOption = {
  label: "New topic",
  value: "new_topic",
  type: "custom",
  keywords: [],
  matchRule: CustomTopicMatchRule.Any,
};

// cspell:disable
export const TOPIC_KEYWORD_MAP: Record<DefaultTopic, string[]> = {
  [DefaultTopic.Ai]: [
    "ai",
    "ml",
    "artificial intelligence",
    "machine learning",
    "large language model",
    "llm",
    "llms",
    "chatgpt",
    "chat gpt",
    "openai",
    "open ai",
    "anthropic",
    "gpt",
    "claude",
    "large language models",
  ],
  [DefaultTopic.CompensationAndBenefits]: [
    "salary",
    "comp",
    "compensation",
    "equity",
    "benefits",
    "shares",
    "bonus",
    "base salary",
    "stock options",
    "equity shares",
    "equity grant",
    "equity grants",
    "stock option",
    "stock grant",
    "common stock",
    "restricted stock",
    "RSU",
    "RSUs",
    "performance bonus",
    "performance bonuses",
    "signing bonus",
    "signing bonuses",
    "sign on bonus",
    "sign on bonuses",
    "annual bonus",
    "annual bonuses",
    "quarterly bonus",
    "quarterly bonuses",
    "health benefits",
    "retirement benefits",
    "401k",
    "health plan",
    "health insurance",
    "dental insurance",
    "parental leave",
    "pension plan",
    "life insurance",
    "wellness benefits",
    "maternity leave",
    "paternity leave",
  ],
  [DefaultTopic.Culture]: ["mission", "values", "culture"],
  [DefaultTopic.Dei]: [
    "equity",
    "diversity",
    "diverse",
    "inclusivity",
    "inclusive",
    "dei",
  ],
  [DefaultTopic.Economy]: [
    "macro economic",
    "economy",
    "macroeconomic",
    "unemployment rate",
    "employment rate",
    "economic growth",
    "recession",
    "inflation",
    "deflation",
    "economic indicators",
    "fiscal policy",
    "fed policy",
    "interest rates",
    "GDP",
    "labor market",
    "stock market",
  ],
  [DefaultTopic.LayoffsAndCompanyStability]: [
    "laid off",
    "layoff",
    "layoffs",
    "cash burn",
    "profitability",
    "funding",
    "profitable",
    "profit",
    "runway",
    "cash flow positive",
    "cashflow positive",
  ],
  [DefaultTopic.Motivations]: [
    "goals",
    "career growth",
    "career development",
    "long term",
    "next role",
    "looking for",
    "hoping for",
    "in my next",
    "in your next",
    "in my next role",
  ],
  [DefaultTopic.WorkLocation]: [
    "in the office",
    "into the office",
    "remote",
    "remotely",
    "to the office",
    "remote work",
    "flexible work",
    "work from home",
    "hybrid",
    "return to office",
    "in office",
  ],
};

export const DEFAULT_TOPIC_OPTIONS: TopicOption[] = Object.entries(
  TOPIC_KEYWORD_MAP
).map(([topicValue, keywords]) => ({
  label: formatTopicName(topicValue as DefaultTopic),
  value: topicValue,
  keywords,
  type: "default",
  matchRule: CustomTopicMatchRule.Any,
}));

export const SPEAKER_OPTIONS = [
  {
    label: "Candidates and Interviewers",
    value: AnalyticsTopicSpeakerType.All,
  },
  {
    label: "Only candidates",
    value: AnalyticsTopicSpeakerType.Candidate,
  },
  {
    label: "Only interviewers",
    value: AnalyticsTopicSpeakerType.Interviewer,
  },
];
