import {
  Box,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";

import { Button, LeverIcon, useTheme, useToast } from "../../../components";
import { useDeauthorizeLeverMutation } from "../../graphql";

interface LeverDeauthorizeButtonProps {
  refetch: () => void;
  buttonProps?: ButtonProps;
}

const LeverDeauthorizeButton: React.FC<LeverDeauthorizeButtonProps> = ({
  refetch,
  buttonProps,
}) => {
  const theme = useTheme();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deauthorizeLever, { loading }] = useDeauthorizeLeverMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
    onCompleted: (data) => {
      if (data.deauthorizeLever?.success) {
        refetch();
        toast({
          title: "Success",
          description: "Removed Lever integration",
          status: "success",
        });
      }
    },
  });

  return (
    <Box>
      <Button
        onClick={onOpen}
        leftIcon={
          <LeverIcon width={18} height={18} fill={theme.colors.red[400]} />
        }
        aria-label="Lever"
        borderRadius="base"
        variant="outline"
        color={theme.colors.red[400]}
        {...buttonProps}
      >
        Deauthorize lever
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Deauthorize Lever</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to deauthorize Lever? We will no longer be
            able to import users, candidates, positions, call guides, or
            scheduled interviews. This could cause issues for interviews that
            are already scheduled using Lever.
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              onClick={() => deauthorizeLever()}
              backgroundColor={theme.colors.red[400]}
              isLoading={loading}
            >
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default LeverDeauthorizeButton;
