import { Flex, StyleProps, Text } from "@chakra-ui/react";
import React from "react";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";

import { Button } from "../../../components";
import { Playlist } from "../../graphql";

type PlaylistHeaderProps = StyleProps & {
  next: undefined | (() => void);
  prev: undefined | (() => void);
  loading: boolean;
  currentIdx: number;
  playlist: Pick<Playlist, "title" | "clipCount">;
};

const PlaylistHeaderButton: typeof Button = (props) => {
  return (
    <Button
      py="2"
      px="3"
      variant="solid"
      fontSize="sm"
      fontWeight="500"
      textTransform="none"
      {...props}
    />
  );
};

const PlaylistHeader: React.FC<PlaylistHeaderProps> = ({
  playlist,
  next,
  prev,
  currentIdx,
  loading,
  ...styles
}) => {
  return (
    <Flex
      alignItems="center"
      bg="white"
      py="4"
      borderBottom="1px solid"
      borderColor="gray.100"
      {...styles}
    >
      <Text fontSize="2xl" fontWeight="bold" mr="auto">
        {playlist.title || "Shared Playlist"}
      </Text>

      <Text color="gray.600" fontSize="sm" mr="6">
        {`${currentIdx + 1} of ${playlist.clipCount || 1}`}
      </Text>

      <PlaylistHeaderButton
        mr="2"
        onClick={prev}
        disabled={loading || !prev}
        data-testid="playlist-go-previous"
      >
        <HiOutlineChevronLeft size={22} />
        Prev
      </PlaylistHeaderButton>

      <PlaylistHeaderButton
        onClick={next}
        disabled={loading || !next}
        data-testid="playlist-go-next"
      >
        Next
        <HiOutlineChevronRight size={22} />
      </PlaylistHeaderButton>
    </Flex>
  );
};

export default PlaylistHeader;
