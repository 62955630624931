import {
  Box,
  Flex,
  Icon,
  Spinner,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { HiOutlineX } from "react-icons/hi";
import { HiOutlineSparkles } from "react-icons/hi2";

import {
  Avatar,
  Button,
  ExpandableTextArea,
  IconButton,
  Link,
} from "../../../../components";
import { CopyIcon } from "../../../../components/Icons/CopyIcon";
import { copy } from "../../../../utils/clipboard";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { useIsExtension } from "../../../hooks/useAppEnvironmentContext";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { AskChatRole } from "../../Ask/useAskChat";
import useAskChatJson from "../../Ask/useAskChatJson";
import MonospacedText from "../../MonospacedText";
import AskHeader from "./AskHeader";

const suggestedQuestions = [
  "Do they have offers from other companies?",
  "What compensation terms have been discussed?",
  "What is their current job search status?",
];

export const CandidateAskV2: React.FC<{
  candidateId: string;
  positionId: string | null;
  onClose: () => void;
  hidden?: boolean;
}> = ({ candidateId, positionId, onClose, hidden = false }) => {
  const [userInput, setUserInput] = useState("");
  const [delayedHidden, setDelayedHidden] = useState(hidden);
  const citationMap = useRef(new Map<string, number>());
  const isExtension = useIsExtension();
  const sendGAEvent = useSendGAEvent();

  const {
    chat,
    addUserMessage,
    busy: chatBusy,
    reset,
  } = useAskChatJson(candidateId, positionId);

  const fullReset = (): void => {
    reset();
    setUserInput("");
    citationMap.current.clear();
  };

  // Delay hiding the component to allow the exit animation to play
  useEffect(() => {
    if (hidden && !delayedHidden) {
      setTimeout(() => {
        setDelayedHidden(hidden);
      }, 200);
    } else {
      setDelayedHidden(hidden);
    }
  }, [hidden, delayedHidden]);

  const scrollRef = useRef<HTMLDivElement>(null);

  const handleSubmit = (): void => {
    addUserMessage(userInput, userInput);
    setUserInput("");
  };

  useEffect(() => {
    const scroll = scrollRef.current;
    if (scroll) {
      scroll.scrollTop = scroll.scrollHeight;
    }
  }, [chat.messages[chat.messages.length - 1]]);

  const ref = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (ref.current && !chatBusy) {
      ref.current.focus();
    }
  }, [ref.current, chatBusy, delayedHidden]);

  const onTextAreaKeyDown = (
    e: React.KeyboardEvent<HTMLTextAreaElement>
  ): void => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendGAEvent("ask_submit", "candidate");
      handleSubmit();
    }
  };
  // Hide to block tabbing and save on render time
  if (delayedHidden) {
    return null;
  }

  return (
    <Flex
      flexDir="column"
      height="100%"
      width="450px"
      justifyContent="space-between"
      pt={4}
      pb={6}
      px={6}
      borderLeft={isExtension ? undefined : "1px solid"}
      borderColor={isExtension ? undefined : "gray.200"}
      boxShadow={isExtension ? undefined : "7px 7px 28px 0px #0000001F"}
    >
      <Flex
        flexDirection="row"
        pt={2}
        pb={3}
        mb={4}
        borderBottomColor="gray.200"
        borderBottomWidth="1px"
        alignItems="center"
      >
        <AskHeader />
        <IconButton
          variant="icon"
          aria-label="Close"
          size="sm"
          ml="auto"
          mr="0px"
          icon={<HiOutlineX strokeWidth="1.5px" size={20} />}
          onClick={() => {
            onClose();
          }}
        />
      </Flex>

      <Box ref={scrollRef} mt="auto" overflow="auto">
        <BotMessage
          key="static-message"
          text={
            "Ask me anything about the candidate and I'll answer using all interviews you have access to."
          }
          citationMap={citationMap.current}
        />
        {chat.messages.map((message, i) => {
          if (message.role === AskChatRole.Assistant) {
            return (
              <BotMessage
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                text={message.userContent}
                citationMap={citationMap.current}
              />
            );
          }
          // eslint-disable-next-line react/no-array-index-key
          return <UserMessage key={i} text={message.userContent} />;
        })}
      </Box>
      <Flex
        flexDir="column"
        borderTop="1px solid"
        borderColor="gray.200"
        pt={4}
        mt={4}
      >
        {!chatBusy && chat.messages.length < 1 && (
          <Flex flexDir="column" gap={2} mb={4}>
            {suggestedQuestions.map((question) => (
              <SuggestedQuestion
                key={question}
                text={question}
                onClick={() => {
                  addUserMessage(question, question);
                  setUserInput("");
                }}
              />
            ))}
          </Flex>
        )}
        <form
          onSubmit={(e) => {
            sendGAEvent("ask_submit", "candidate");
            e.preventDefault();
            handleSubmit();
          }}
        >
          <ExpandableTextArea
            ref={ref}
            fontSize="sm"
            resize="none"
            onChange={(e) => setUserInput(e.target.value)}
            disabled={chatBusy}
            placeholder="Your question...."
            value={userInput}
            onKeyDown={onTextAreaKeyDown}
            maxHeight="110px"
          />
          <Flex mt={1} justifyContent="space-between">
            <Button
              size="xs"
              onClick={() => {
                sendGAEvent("ask_start_over", "candidate");
                setUserInput("");
                fullReset();
                if (ref.current) {
                  ref.current.focus();
                }
              }}
              variant="link"
              disabled={chatBusy || chat.messages.length < 1}
              _disabled={{
                color: "gray.200",
                background: "none",
              }}
              _hover={{
                textDecoration: "none",
              }}
              visibility={chat.messages.length < 1 ? "hidden" : "visible"}
            >
              Start over
            </Button>
            <Link />
            <Text fontSize="xs" color="gray.500" fontWeight="500" mt="1px">
              Press enter to submit
            </Text>
          </Flex>
        </form>
      </Flex>
    </Flex>
  );
};

const BotMessage: React.FC<{
  text?: string;
  citationMap: Map<string, number>;
}> = ({ text, citationMap }) => {
  const sendGAEvent = useSendGAEvent();
  const toast = useToast();
  const isJson = text?.startsWith('{"notes":');

  return (
    <Flex flexDir="row" alignItems="flex-start" mt="24px" mr={2}>
      <Flex
        textColor="gray.800"
        fontSize="sm"
        whiteSpace="pre-wrap"
        p={2}
        borderRadius="md"
        bg="#FAF5FF"
        mr={2}
      >
        <Flex flexDir="row" width="100%">
          <Icon
            boxSize={6}
            color="purple.450"
            as={HiOutlineSparkles}
            p={1}
            flexShrink="0"
            mr={2}
          />
          {!isJson && text && <Box pt="2px">{text && text}</Box>}
          {isJson && (
            <MessageContentWithCitations
              text={text || ""}
              citationMap={citationMap}
            />
          )}
          {!text && (
            <Box pt="4px">
              <Spinner color="purple.300" size="sm" />
            </Box>
          )}
        </Flex>
      </Flex>
      <Box flexShrink="0" ml="auto">
        <Tooltip label="Copy to clipboard" placement="bottom-start">
          <IconButton
            aria-label="Copy response"
            icon={<CopyIcon fontSize="20px" />}
            variant="ghost"
            color="blue.600"
            onClick={() => {
              sendGAEvent("ask_copy_response", "candidate");
              copy({
                plain: botMessageToText(text),
                html: botMessageToHtml(text, citationMap),
              });
              toast({
                title: "Response copied to clipboard",
                status: "success",
              });
            }}
          />
        </Tooltip>
      </Box>
    </Flex>
  );
};

/** All fields are optional because of partial type streaming */
type CitedNotes = {
  notes?: {
    note?: string;
    note_citations?: {
      call_id?: string;
      timestamp?: string;
    }[];
  }[];
};

// Parsing does not need to be robust since all fields are optional and checked on render
const parseToCitedNotes = (text: string | undefined): CitedNotes | null => {
  try {
    return JSON.parse(text || "");
  } catch (e) {
    return null;
  }
};

const botMessageToText = (text: string | undefined): string => {
  const notesMessage = parseToCitedNotes(text);
  if (notesMessage === null) {
    return text || "";
  }
  if (!notesMessage.notes) {
    return "";
  }
  return notesMessage.notes
    .map((note) => {
      return note.note;
    })
    .join("\n\n");
};

const botMessageToHtml = (
  text: string | undefined,
  citationMap: Map<string, number>
): string => {
  const notesMessage = parseToCitedNotes(text);
  if (notesMessage === null) {
    return text || "";
  }

  if (!text || text.trim() === "") {
    return "";
  }
  try {
    if (!notesMessage.notes) {
      return "";
    }
    return notesMessage.notes
      .map((note) => {
        if (!note.note) {
          return "";
        }
        const citationText = (note.note_citations || [])
          .map((citation) => {
            if (!citation.call_id || !citation.timestamp) {
              return "";
            }
            const citationKey = `${citation.call_id}-${citation.timestamp}`;
            const citationLabel =
              citationMap.get(citationKey)?.toString() || "";
            return `[<a href="https://app.brighthire.ai/interview/${citation.call_id}?t=${citation.timestamp}">${citationLabel}</a>]`;
          })
          .join(" ");
        return `${note.note} ${citationText}`;
      })
      .join("<br><br>");
  } catch (e) {
    return text;
  }
};

const MessageContentWithCitations: React.FC<{
  text: string;
  citationMap: Map<string, number>;
}> = ({ text, citationMap }) => {
  const sendGAEvent = useSendGAEvent();
  const [latestValidJsonText, setLatestValidJsonText] = useState<string | null>(
    null
  );
  useEffect(() => {
    try {
      JSON.parse(text);
      setLatestValidJsonText(text);
    } catch (e) {
      // Skip. Rarely a partial server message will not be parseable, but either the
      // previous or next message will be.
    }
  });
  if (latestValidJsonText === null) {
    return null;
  }
  const response = JSON.parse(latestValidJsonText);
  return (
    <Flex flexDir="column">
      {response.notes.map((note: any, noteIndex: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box pb="1rem" key={noteIndex}>
          {note.note}
          {(note.note_citations || []).map(
            (citation: any, citationIndex: number) => {
              const citationKey = `${citation.call_id}-${citation.timestamp}`;
              if (!citationMap.has(citationKey)) {
                citationMap.set(citationKey, citationMap.size + 1);
              }
              const citationNumber = citationMap.get(citationKey);
              return (
                <Link
                  // eslint-disable-next-line react/no-array-index-key
                  key={citationIndex}
                  fontWeight="normal"
                  href={`/interview/${citation.call_id}?t=${citation.timestamp}`}
                  target="_blank"
                  onClick={() => {
                    sendGAEvent("ask_click_citation", "candidate");
                  }}
                >
                  <MonospacedText
                    display="inline-block"
                    verticalAlign="super"
                    fontSize="0.6rem"
                    text={citationNumber?.toString() || ""}
                    marginRight="0.25rem"
                  />
                </Link>
              );
            }
          )}
        </Box>
      ))}
    </Flex>
  );
};

const UserMessage: React.FC<{
  text: string;
}> = ({ text }) => {
  const currentUser = useCurrentUser();
  return (
    <Flex
      mt="24px"
      ml={10}
      mr={2}
      textColor="gray.800"
      fontSize="sm"
      whiteSpace="pre-wrap"
      p={2}
      pl={3}
      borderRadius="md"
      bg="gray.50"
    >
      <Flex flexDir="row" width="100%">
        <Box pt="2px">{text}</Box>
        <Box pl={2} width={8} flexShrink="0" ml="auto">
          <Avatar user={currentUser} />
        </Box>
      </Flex>
    </Flex>
  );
};

const SuggestedQuestion: React.FC<{
  text: string;
  onClick: (question: string) => void;
}> = ({ text, onClick }) => {
  const sendGAEvent = useSendGAEvent();
  return (
    <Button
      size="sm"
      variant="outline"
      width="auto"
      color="purple.500"
      _hover={{
        bg: "purple.50",
      }}
      _active={{
        bg: "purple.100",
      }}
      _focus={{
        color: "purple.600",
      }}
      onClick={() => {
        sendGAEvent("ask_click_suggested_question", "candidate", text);
        onClick(text);
      }}
    >
      {text}
    </Button>
  );
};
