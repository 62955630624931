import { Box, Flex, Icon } from "@chakra-ui/react";
import invariant from "invariant";
import React from "react";
import { FiSearch } from "react-icons/fi";
import Select, { components, ControlProps } from "react-select";

import useSelectTheme from "../../hooks/useSelectTheme";
import Avatar from "../Avatar";

export type ShareableUser = {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  fullName: string;
  email: string;
  profilePicUrl?: string | null;
};

interface ShareableUserSelectProps {
  shareableUsers: ShareableUser[];
  selectedUsers: ShareableUser[];
  onSelection: (s: ShareableUser[]) => void;
}

type ShareOption = { label: string; value: ShareableUser };

const Control: React.FC<ControlProps<ShareOption, true>> = ({
  children,
  ...props
}) => {
  const { hasValue } = props;

  return (
    <components.Control {...props}>
      {!hasValue && (
        <Icon as={FiSearch} w="5" h="5" color="gray.500" ml="4" mr="1" />
      )}
      {children}
    </components.Control>
  );
};

const getUserValue = (user: ShareableUser): ShareOption => ({
  label: `${user.fullName} - ${user.email}`,
  value: user,
});

const formatOptionLabel: React.FC<ShareOption> = ({ label, value }) => (
  <Flex>
    <Avatar name={label} borderRadius="100%" user={value} mr="3" />
    <Box>{label}</Box>
  </Flex>
);

const ShareableUserSelect: React.FC<ShareableUserSelectProps> = ({
  shareableUsers,
  selectedUsers,
  onSelection,
}) => {
  const [selectTheme, selectStyles] = useSelectTheme();
  return (
    <Select
      aria-label="share-select-input"
      data-testid="share-select"
      theme={selectTheme}
      styles={selectStyles}
      isClearable
      components={{
        DropdownIndicator: null,
        Control,
      }}
      isMulti
      placeholder="Invite a colleague to view"
      autoFocus
      formatOptionLabel={formatOptionLabel}
      value={selectedUsers.map(getUserValue)}
      options={shareableUsers.map(getUserValue)}
      onChange={(selectedOptions) => {
        if (!selectedOptions) {
          onSelection([]);
          return;
        }
        invariant(Array.isArray(selectedOptions), "Invalid selectedOption");
        onSelection(selectedOptions.map(({ value }) => value));
      }}
    />
  );
};

export default ShareableUserSelect;
