import {
  InterviewImportDecisionReason,
  ScheduledInterviewListItemFragment,
} from "../main/graphql";

/** Explains an `InterviewImportDecisionReason` to the user */
export const getUserFriendlyExcludeText = (
  importReason: InterviewImportDecisionReason | null | undefined
): string => {
  switch (importReason) {
    case InterviewImportDecisionReason.Exclude: {
      return "One of the interviewers is an excluded user for your organization.";
    }
    case InterviewImportDecisionReason.ExcludeStage: {
      return "The interview stage is excluded for your organization.";
    }
    case InterviewImportDecisionReason.OptOutCandidate: {
      return "Candidate opted-out from being recorded.";
    }
    case InterviewImportDecisionReason.OptOutInterviewer: {
      return "An interviewer opted-out from being recorded.";
    }
  }
  return "Your organization's rules prevent this event from being captured by BrightHire.";
};

export const getScheduledInterviewDisplayName = (
  scheduledInterview: Pick<
    ScheduledInterviewListItemFragment,
    "name" | "ghJobStageName" | "candidate"
  >
): string => {
  if (scheduledInterview.name) {
    return scheduledInterview.name;
  }

  const candidateName = scheduledInterview.candidate?.fullName;
  const stageName = scheduledInterview.ghJobStageName;

  if (candidateName) {
    if (stageName) {
      return `${stageName} - ${candidateName}`;
    }
    return `Interview with ${candidateName}`;
  }

  return stageName ?? "Interview";
};
