import { Box, Flex, FlexProps } from "@chakra-ui/react";
import React from "react";

import { ExpandableText, LoadingIndicator } from "../../../../components";
import { CallNoteFragment } from "../../../graphql";
import { TimestampLink } from "../TimestampLink";

type CallQuestionNoteTitleProps = Omit<FlexProps, "bg"> & {
  title: string;
  onClickTimestamp?: (time: number) => void;
  timestampNote?: Pick<CallNoteFragment, "type" | "time" | "callId">;
  /**
   * Whether to show a loading indicator in place of the timestamp.
   * `timestampNote` takes precedence if present
   */
  showLoading?: boolean;
  /**
   * Must specify a background using a CSS literal (not a chakra value
   * like "blue.600"). This is passed to the expandable text component
   */
  bg: string;
};

const CallQuestionNoteTitle: React.FC<CallQuestionNoteTitleProps> = ({
  title,
  timestampNote,
  onClickTimestamp,
  showLoading,
  bg,
  ...rest
}) => (
  <Flex px="5" py="3" fontWeight="500" fontSize="14px" bg={bg} {...rest}>
    {onClickTimestamp && (
      <Flex alignSelf="stretch" alignItems="center" minW="14" pr="1">
        {timestampNote ? (
          <TimestampLink
            onClickTimestamp={onClickTimestamp}
            note={timestampNote}
          />
        ) : showLoading ? (
          <LoadingIndicator size="md" delay={0} />
        ) : null}
      </Flex>
    )}
    <Box flex="1">
      <ExpandableText
        title={title}
        color="gray.800"
        fontWeight="medium"
        maskBg={bg}
      />
    </Box>
  </Flex>
);

export default CallQuestionNoteTitle;
