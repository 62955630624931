export const getPercentDifference = (
  currValue?: number | null,
  priorValue?: number | null
): number | null | string => {
  if (typeof currValue !== "number" || typeof priorValue !== "number") {
    return null;
  }
  if (priorValue === 0) {
    return "∞";
  }
  return ((currValue - priorValue) / priorValue) * 100;
};

export const getPercentDifferenceDisplay = (
  currValue?: number | null,
  priorValue?: number | null
): string => {
  const percentDifference = getPercentDifference(currValue, priorValue);
  if (percentDifference === "∞") {
    return "↑∞";
  }
  if (typeof percentDifference === "number") {
    return `${percentDifference > 0 ? "↑" : "↓"}${Math.abs(
      percentDifference
    ).toFixed(0)}%`;
  }
  return "—";
};
