import { Text, useToast } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import Modal from "../../../components/Modal/Modal";
import { useDeleteSkillReportMutation } from "../../../graphql";

interface DeleteSkillsReportModalProps {
  isOpen: boolean;
  onClose: () => void;
  reportId: string;
}

const DeleteSkillsReportModal: React.FC<DeleteSkillsReportModalProps> = ({
  isOpen,
  onClose,
  reportId,
}) => {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();
  const navigate = useNavigate();

  const [deleteSkillReport, { loading }] = useDeleteSkillReportMutation({
    update(cache, { data }) {
      if (data?.deleteSkillReport?.success) {
        cache.evict({
          id: cache.identify({ __typename: "SkillReport", id: reportId }),
        });
        cache.gc();
      }
    },
    onError: () =>
      toast({ status: "error", title: "Error deleting skill report" }),
    onCompleted: (data) => {
      if (data.deleteSkillReport?.success) {
        toast({
          title: `Skill report deleted!`,
          status: "success",
          duration: 3000,
          isClosable: false,
          position: "top",
        });
        navigate("/insights/skill-report/all");
      }
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      isLoading={loading}
      isDestructive
      headerText="Are you sure you want to delete this skill report?"
      completeButtonText="Delete skill report"
      onComplete={() => {
        sendGAEvent("delete_skill_report", "analytics", reportId);
        deleteSkillReport({ variables: { reportId } });
      }}
      onClose={onClose}
    >
      <Text color="gray.800" fontSize="sm">
        This will permanently delete this skill report. This cannot be undone.
      </Text>
    </Modal>
  );
};

export default DeleteSkillsReportModal;
