import { Text, TextProps } from "@chakra-ui/react";
import React from "react";

type ColumnHeaderProps = {
  children: React.ReactNode;
} & TextProps;

const ColumnHeader: React.FC<ColumnHeaderProps> = ({
  children,
  ...TextProps
}) => {
  return (
    <Text
      fontSize="xs"
      fontWeight="medium"
      color="gray.500"
      textTransform="uppercase"
      {...TextProps}
    >
      {children}
    </Text>
  );
};

export default ColumnHeader;
