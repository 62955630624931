import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { HiOutlineExclamationTriangle } from "react-icons/hi2";

import { Button } from "../Buttons";

const NoAccess: React.FC<{
  buttonConfig?: {
    text: string;
    onClick: () => void;
  };
}> = ({ buttonConfig }) => (
  <Flex h="100%" alignItems="center" justifyContent="space-around">
    <Box textAlign="center">
      <Icon
        as={HiOutlineExclamationTriangle}
        color="gray.300"
        boxSize="96px"
        mb="4"
      />
      <Text color="gray.800" fontSize="sm">
        You don&apos;t currently have permission to access this page.
        <br />
        Please reach out to your account administrator.
      </Text>
      {buttonConfig && (
        <Button onClick={buttonConfig.onClick} mt="12">
          {buttonConfig.text}
        </Button>
      )}
    </Box>
  </Flex>
);

export default NoAccess;
