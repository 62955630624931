import { Flex, HStack, Icon, Image, Text } from "@chakra-ui/react";
import { transparentize } from "@chakra-ui/theme-tools";
import React from "react";
import { IoHourglassOutline } from "react-icons/io5";
import { MdChevronRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import { Link } from "../../../components";
import { daysRemaining } from "../../../utils/external-shares";
import { PlaylistHeader } from "../../components/PlaylistHeader";
import { PlaylistNavigationInfo } from "../../graphql";
import { useExternalShareParams } from "./useExternalShareParams";

type PlaylistNavigationInfoPlaylist = Pick<
  PlaylistNavigationInfo["playlist"],
  "title" | "clipCount"
>;

const ExternalHeaderLogo: React.FC = () => (
  <HStack>
    <Image
      src="/static/images/logo_wordmark_black.svg"
      width="130"
      mr={{ base: undefined, sm: "8" }}
    />
    <Link
      href="https://brighthire.ai"
      target="_blank"
      bg={transparentize("blue.600", 0.1) as unknown as string}
      color="blue.600"
      fontWeight="500"
      fontSize="sm"
      borderRadius="4px"
      py="1"
      px="3"
      display={{
        base: "none",
        sm: "flex",
      }}
      alignItems="center"
    >
      Learn more about BrightHire
      <Icon as={MdChevronRight} boxSize="5" ml="3" />
    </Link>
  </HStack>
);

type ExternalSharePageHeaderProps = {
  sharedByEmail?: string;
  shareDaysRemaining?: number;
  playlistNavigationInfo?:
    | (Pick<PlaylistNavigationInfo, "nextClipUrl" | "previousClipUrl"> & {
        playlist: PlaylistNavigationInfoPlaylist;
      })
    | null;
};

export const ExternalSharePageHeader: React.FC<
  ExternalSharePageHeaderProps
> = ({ sharedByEmail, shareDaysRemaining, playlistNavigationInfo }) => {
  const { index } = useExternalShareParams();
  const navigate = useNavigate();

  return (
    <>
      <Flex
        as="header"
        alignItems="center"
        justifyContent="space-between"
        direction={{
          base: "column",
          sm: "row",
        }}
        px="10"
        pt="10px"
        pb={{
          base: "3",
          sm: "10px",
        }}
        bg="white"
        borderBottom={{
          base: "1px solid",
        }}
        borderBottomColor={{
          base: "gray.100",
        }}
      >
        <ExternalHeaderLogo />
        <Flex alignItems="center">
          <HStack
            color="orange.500"
            mr={{
              base: "0",
              sm: "6",
            }}
            mt={{
              base: "3",
              sm: "0",
            }}
          >
            {shareDaysRemaining && (
              <>
                <Icon as={IoHourglassOutline} boxSize="18" />
                <Text fontWeight="500" fontSize="sm">
                  {daysRemaining(shareDaysRemaining)}
                </Text>
              </>
            )}
          </HStack>
          <Text
            display={{ base: "none", sm: "block" }}
            fontWeight="500"
            fontSize="sm"
          >
            {sharedByEmail ? `Shared by ${sharedByEmail}` : ""}
          </Text>
        </Flex>
      </Flex>
      {playlistNavigationInfo && (
        <PlaylistHeader
          next={
            playlistNavigationInfo?.nextClipUrl
              ? () => navigate(String(playlistNavigationInfo.nextClipUrl))
              : undefined
          }
          prev={
            playlistNavigationInfo?.previousClipUrl
              ? () => navigate(String(playlistNavigationInfo.previousClipUrl))
              : undefined
          }
          loading={false}
          currentIdx={index || 0}
          playlist={playlistNavigationInfo.playlist}
          px="pagePadding"
        />
      )}
    </>
  );
};
