import { Box, Center, Image, Text } from "@chakra-ui/react";
import React from "react";

const AnalyticsReportHero: React.FC<{
  image?: boolean;
  children?: React.ReactNode;
}> = ({ image, children }) => {
  return (
    <Center minH="125px">
      {image && (
        <Image src="/static/images/analytics_no_report_data.svg" mr="4" />
      )}
      <Box
        color="gray.600"
        fontWeight="600"
        fontSize="lg"
        textAlign={image ? undefined : "center"}
      >
        {children || (
          <Text>
            No data available.
            <br />
            Change your date and filter selections.
          </Text>
        )}
      </Box>
    </Center>
  );
};

export default AnalyticsReportHero;
