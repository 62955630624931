import {
  FormControl,
  FormLabel,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Switch,
} from "@chakra-ui/react";
import React from "react";
import { IoSettingsSharp } from "react-icons/io5";
import Select from "react-select";

import { Button } from "../../../../components";
import { useInternalOrganizationsListQuery } from "../../../graphql";
import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useAnalyticsContext from "../useAnalyticsContext";

const AnalyticsAdmin: React.FC<{
  includeDemoDataOption?: boolean;
}> = ({ includeDemoDataOption = true }) => {
  const crossOrgEnabled = useFeatureFlag("internal:analytics:cross-org-access");
  const mockDataEnabled = useFeatureFlag("internal:analytics:demo-data");

  const currentUser = useCurrentUser();
  const currentUserIsBHUser =
    currentUser.organization.id === "35bb5f6b-2733-464b-a561-35b4c941e824";
  const currentUserIsBHAdmin =
    !!currentUser.internalUserRole && currentUserIsBHUser;

  const {
    selectedOrgId,
    setSelectedOrgId,
    showBHDemoDashboard,
    setShowBHDemoDashboard,
  } = useAnalyticsContext();

  const { loading, error, data } = useInternalOrganizationsListQuery({
    skip: !currentUserIsBHAdmin,
  });
  const options = [...(data?.internalOrganizationsList ?? [])]
    .sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    })
    .map((o) => ({ value: o.id, label: o.name }));

  const handleUpdateDemoData = (): void => {
    setShowBHDemoDashboard(!showBHDemoDashboard);
  };

  if (!currentUserIsBHUser) {
    return null;
  }

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          size="sm"
          aria-label="Analytics admin"
          leftIcon={<IoSettingsSharp />}
          variant="outline"
          colorScheme="gray"
        >
          Admin
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Analytics Admin</PopoverHeader>
        <PopoverBody>
          {currentUserIsBHAdmin && (
            <FormControl mb="4" isDisabled={!crossOrgEnabled}>
              <FormLabel htmlFor="org-switch">Select org</FormLabel>
              <Select
                id="org-switch"
                isDisabled={loading || !!error || !crossOrgEnabled}
                value={options.filter((o) => o.value === selectedOrgId)}
                options={options}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setSelectedOrgId(selectedOption.value);
                  }
                }}
              />
            </FormControl>
          )}
          {includeDemoDataOption && (
            <FormControl mb="4" isDisabled={!mockDataEnabled}>
              <FormLabel htmlFor="demo-data">
                Use demo data for BrightHire?
              </FormLabel>
              <Switch
                id="demo-data"
                isChecked={showBHDemoDashboard}
                onChange={handleUpdateDemoData}
              />
            </FormControl>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default AnalyticsAdmin;
