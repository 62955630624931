import {
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import invariant from "invariant";
import React, { useRef } from "react";
import { useForm } from "react-hook-form";

import { Button, useToast } from "../../../components";
import { useSignInByEmailMutation } from "../../graphql";

interface SignInByEmailFormProps {
  email: string | undefined;
  onVerificationCodeSent: (verificationInfo: { email: string }) => void;
}

const SignInByEmailForm: React.FC<SignInByEmailFormProps> = ({
  email,
  onVerificationCodeSent,
}) => {
  const toast = useToast();
  const verificationInfo = useRef<{
    email: string;
  } | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    email: string;
  }>({
    defaultValues: {
      email: email ?? "",
    },
  });

  const [signIn, { loading }] = useSignInByEmailMutation({
    onCompleted: (data) => {
      if (!data?.signInByEmail?.verificationSent) {
        return;
      }
      invariant(verificationInfo.current, "Missing verificationInfo");
      onVerificationCodeSent(verificationInfo.current);
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
        duration: 30000,
      });
    },
  });

  const onSubmit = handleSubmit(({ email }): void => {
    verificationInfo.current = { email };
    signIn({
      variables: { email },
    });
  });

  return (
    <form onSubmit={onSubmit} autoComplete="on">
      <Flex>
        <FormControl
          id="email"
          w="96"
          isRequired
          isInvalid={errors.email !== undefined}
        >
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            {...register("email", {
              required: "Email is required",
            })}
            aria-describedby="email-helper-text"
            placeholder="email@domain.com"
            data-testid="input-email"
          />
          {errors.email !== undefined && (
            <FormErrorMessage>{errors.email.message}</FormErrorMessage>
          )}
        </FormControl>
      </Flex>
      <Flex>
        <ButtonGroup spacing={4} mx="auto">
          <Button type="submit" mt={6} mx="auto" isLoading={loading}>
            Submit
          </Button>
        </ButtonGroup>
      </Flex>
    </form>
  );
};

export default SignInByEmailForm;
