import { CandidateSummaryTemplateFragment } from "../../../graphql";
import { callTimestampedLink } from "../../CallNotes/utils";
import { SUBSECTION_NO_CONTENT } from "./messages";
import { Topic } from "./types";

export const encodeToAnchor = (typePrefix: string, str: string): string => {
  const unescapedAnchor = `${typePrefix}-${str}`;
  return encodeURIComponent(unescapedAnchor.trim().replace(/\s+/g, "-"));
};

export const formatTopic = (
  topic: Topic,
  citationMap: { [key: string]: number }
): { plain: string; html: string } => {
  let plain = "";
  let html = "";

  const subtopics = topic.subTopics;

  plain += `${topic.name.toUpperCase()}\n`;
  html += `<h2>${topic.name}</h2><br>`;
  subtopics.forEach((subtopic) => {
    plain += `\n- ${subtopic.name}\n\n`;
    html += `<h4>${subtopic.name}</h4>`;
    subtopic.notes.forEach((note) => {
      if (note.text !== "") {
        plain += `${note.text}\n`;
        html += `${note.text} `;
        const citationLine = note.citations
          .map((citation) => {
            return `[<a href="${callTimestampedLink(
              citation.callId,
              citation.time
            )}">${citationMap[citation.id].toString()}</a>]`;
          })
          .join(" ");
        html += `${citationLine}<br>`;
      } else {
        plain += `(${SUBSECTION_NO_CONTENT})\n`;
        html += `<i>(${SUBSECTION_NO_CONTENT})</i><br>`;
      }
    });
  });

  return { plain, html };
};

export const getTemplateDisplayName = (
  template: CandidateSummaryTemplateFragment
): string =>
  `${template.name}${template.isPositionDefault ? " (default)" : ""}`;

export const getEditTemplateUrl = (
  templateId: string,
  candidateId: string,
  positionId: string | null
): string => {
  const positionParam = positionId ? `&positionId=${positionId}` : "";
  return `/debrief/${templateId}/edit?candidateId=${candidateId}${positionParam}`;
};

export const placeholderProps = {
  color: "gray.400",
};
