import {
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { HiOutlinePlus, HiOutlineTrash, HiXCircle } from "react-icons/hi";

import { Button, IconButton, TooltipIcon } from "../../../../../../components";

const QuestionInput: React.FC<{
  idx: number;
  totalQuestions: number;
  questionValue: string;
  handleOnChange: (idx: number, value: string) => void;
  handleRemoveQuestion: (idx: number) => void;
}> = ({
  idx,
  questionValue,
  handleOnChange,
  handleRemoveQuestion,
  totalQuestions,
}) => {
  const questionInputRef = useRef<HTMLInputElement>(null);
  return (
    <HStack alignItems="center" mb={3}>
      <InputGroup>
        <Input
          placeholder={
            idx === 0
              ? "E.g. Is the candidate open to relocation for this position?"
              : "E.g. When would the candidate be able to relocate?"
          }
          _placeholder={{ color: "gray.400" }}
          value={questionValue}
          onChange={(e) => {
            handleOnChange(idx, e.target.value);
          }}
          ref={questionInputRef}
        />
        <InputRightElement>
          <TooltipIcon
            color="gray.200"
            label="Clear question text"
            _hover={{
              color: "gray.100",
            }}
            icon={HiXCircle}
            boxSize="5"
            onClick={() => {
              handleOnChange(idx, "");
              questionInputRef.current?.focus();
            }}
          />
        </InputRightElement>
      </InputGroup>
      <IconButton
        aria-label="Delete question"
        variant="ghost"
        isDisabled={totalQuestions < 2}
        icon={<HiOutlineTrash />}
        onClick={() => {
          handleRemoveQuestion(idx);
        }}
        fontSize="20px"
        color="red.500"
        colorScheme="red.500"
      />
    </HStack>
  );
};

export const SummaryAddCustomTopic: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  addCustomTopic: (t: string, questions: string[]) => void;
  title?: string;
  questions?: string[];
}> = ({ addCustomTopic, isOpen, onClose, title, questions }) => {
  const [topicTitle, setTopicTitle] = useState(title || "");
  const [questionValues, setQuestionValues] = useState<Array<string>>(
    questions ? [...questions] : [""]
  );
  const titleInputRef = useRef<HTMLInputElement>(null);

  const handleUpdateQuestionValues = (idx: number, value: string): void => {
    setQuestionValues((prevValues) => {
      // eslint-disable-next-line no-param-reassign
      prevValues[idx] = value;
      return [...prevValues];
    });
  };

  const handleClose = (): void => {
    setTopicTitle(title || "");
    setQuestionValues(questions ? [...questions] : [""]);
    onClose();
  };

  const handleAddQuestion = (): void => {
    setQuestionValues((prevValues) => [...prevValues, ""]);
  };

  const handleRemoveQuestion = (idx: number): void => {
    setQuestionValues((prevValues) => {
      prevValues.splice(idx, 1);
      return [...prevValues];
    });
  };

  return (
    <Modal isOpen={isOpen} size="xl" onClose={handleClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Edit topic</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={1} textColor="gray.500">
              Topic name
            </Text>
            <InputGroup mb={3}>
              <Input
                placeholder="E.g. Relocation"
                value={topicTitle}
                onChange={(e) => {
                  setTopicTitle(e.target.value);
                }}
                mb={2}
                ref={titleInputRef}
                _placeholder={{ color: "gray.400" }}
              />
              <InputRightElement>
                <TooltipIcon
                  color="gray.200"
                  label="Clear question text"
                  _hover={{
                    color: "gray.100",
                  }}
                  icon={HiXCircle}
                  boxSize="5"
                  onClick={() => {
                    setTopicTitle("");
                    titleInputRef.current?.focus();
                  }}
                />
              </InputRightElement>
            </InputGroup>
            <Text mb={1}>What should we cover for this topic?</Text>
            <Text mb={4} textColor="gray.500">
              Below, specify questions BrightHire should try to answer when
              covering this topic. For the best result, make sure the questions
              are related to the topic of interest and as specific as possible.
            </Text>
            <Text mb={1} textColor="gray.500">
              Questions
            </Text>
            {questionValues.map((value, idx) => (
              <QuestionInput
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                idx={idx}
                totalQuestions={questionValues.length}
                questionValue={value}
                handleOnChange={handleUpdateQuestionValues}
                handleRemoveQuestion={handleRemoveQuestion}
              />
            ))}
            <Button size="sm" onClick={handleAddQuestion} variant="ghost">
              {<Icon as={HiOutlinePlus} boxSize="5" paddingRight={2} />} Add
              Question
            </Button>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" onClick={handleClose} variant="ghost">
              Cancel
            </Button>
            <Spacer />
            <Button
              size="sm"
              isDisabled={
                topicTitle === "" ||
                (questionValues.length > 0 && questionValues[0] === "")
              }
              onClick={() => {
                if (topicTitle !== "") {
                  addCustomTopic(
                    topicTitle,
                    questionValues.filter((q) => {
                      if (q === "") {
                        return false;
                      }
                      return true;
                    })
                  );
                }
                handleClose();
              }}
            >
              Save changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
