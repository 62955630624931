import {
  HiOutlineChartBar,
  HiOutlineChatAlt2,
  HiOutlineSparkles,
  HiOutlineThumbUp,
} from "react-icons/hi";
import { IconType } from "react-icons/lib";

import { useOrgAccessMap } from "../../../hooks/useOrgFeatureAccess";
import { canViewOrgInsights } from "../../../utils/permissions";
import {
  AnalyticsDimension,
  AtsDataState,
  FeatureName,
  MetricName,
} from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../hooks/useCurrentUser";

export type NavMetricGroup = {
  title: string;
  metrics: MetricName[];
  icon: IconType;
  enabled: boolean;
  badge?: string;
  defaultSegmentation?: AnalyticsDimension;
};

const useSidebarConfig = (atsDataState: AtsDataState): any => {
  const currentUser = useCurrentUser();
  const accessMap = useOrgAccessMap(currentUser);
  const experimentalEnabled = useFeatureFlag("analytics:experimental");
  const llmMetricsEnabled =
    useFeatureFlag("analytics:llm-call-metrics") &&
    accessMap?.get(FeatureName.LlmCallMetrics)?.isVisible;
  const feedbackMetricsEnabled = atsDataState !== AtsDataState.AtsNotSupported;

  const metricGroups: NavMetricGroup[] = [];

  const interviewActivityMetrics = [
    llmMetricsEnabled ? MetricName.AgendaSet : null,
    MetricName.CandidateTalkRatio,
    MetricName.Interactivity,
    MetricName.LongestMonologue,
    MetricName.OnTimeInterviews,
    feedbackMetricsEnabled ? MetricName.PassRate : null,
    llmMetricsEnabled ? MetricName.ProblematicQuestions : null,
    llmMetricsEnabled ? MetricName.CandidateQuestionOpportunity : null,
    MetricName.QuestionsAsked,
    MetricName.SpeakingRate,
  ].filter(Boolean) as MetricName[];

  const overallMetrics = [
    MetricName.TotalInterviews,
    MetricName.TotalInterviewTime,
  ];
  const interviewFeedbackMetrics = [
    MetricName.ScorecardCompletionRate,
    MetricName.ScorecardCompletionTime,
  ];
  const experimentalMetrics = [
    MetricName.TopicTrends,
    MetricName.QuestionTrends,
  ];

  if (canViewOrgInsights(currentUser)) {
    metricGroups.unshift(
      {
        title: "Overall Metrics",
        metrics: overallMetrics,
        icon: HiOutlineChartBar,
        enabled: true,
      },
      {
        title: "Interview Activity",
        metrics: interviewActivityMetrics,
        icon: HiOutlineChatAlt2,
        enabled: true,
      },
      {
        title: "Interview Feedback",
        metrics: interviewFeedbackMetrics,
        icon: HiOutlineThumbUp,
        enabled: feedbackMetricsEnabled,
      },
      {
        title: "Experimental",
        metrics: experimentalMetrics,
        icon: HiOutlineSparkles,
        enabled: experimentalEnabled,
      }
    );
  }

  const enabledNavMetricGroups = metricGroups.filter((g) => g.enabled);

  return enabledNavMetricGroups;
};

export default useSidebarConfig;
