import {
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

import { Button, Link } from "../../../components";

export type FormData = {
  title: string;
  url: string;
};

interface VideoFormFormProps {
  url: string;
  isLoading: boolean;
  onSubmit: (formData: FormData) => void;
  onCancel: () => void;
}

export const VideoForm: React.FC<VideoFormFormProps> = ({
  url = "",
  isLoading,
  onSubmit,
  onCancel,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  return (
    <>
      <Text color="placeholder" size="sm" mb="4">
        Create your video at{" "}
        <Link href="https://www.loom.com/" target="_blank">
          https://www.loom.com
        </Link>
      </Text>
      <form onSubmit={handleSubmit(onSubmit)} id="video">
        <VStack spacing="8">
          <FormControl
            id="url"
            mb={3}
            isRequired
            isInvalid={errors.title !== undefined}
          >
            <FormLabel>Loom Share Link</FormLabel>
            <FormHelperText mb={4}>
              Only publicly visible links are supported
            </FormHelperText>
            <Input
              {...register("url")}
              placeholder="https://www.loom.com/share/abc123"
              maxLength={100}
            />
            {errors.url !== undefined && (
              <FormErrorMessage>{errors.url.message}</FormErrorMessage>
            )}
          </FormControl>
          <ButtonGroup spacing={4} alignSelf="end">
            <Button variant="outline" onClick={onCancel}>
              Cancel
            </Button>
            <Button form="video" type="submit" isLoading={isLoading}>
              Save
            </Button>
          </ButtonGroup>
        </VStack>
      </form>
    </>
  );
};
