import {
  Box,
  Flex,
  Icon,
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import * as Sentry from "@sentry/browser";
import LogRocket from "logrocket";
import React, { useEffect, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { HiOutlineVideoCamera } from "react-icons/hi";

import {
  EditableText,
  errorToast,
  IconButton,
  LoadingIndicator,
  MenuButton,
  successToast,
  useToast,
} from "../../../components";
import useDebounce from "../../../hooks/useDebounce";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { formatDate, formatDurationShort } from "../../../utils/datetime";
import { BHShareIcon } from "../../components";
import { AddToTrainingProgramIcon } from "../../components/AddToTrainingProgramButton/AddToTrainingProgramButton";
import AddToTrainingProgramModal from "../../components/AddToTrainingProgramButton/AddToTrainingProgramModal";
import { SharePlaylistModal } from "../../components/SharePlaylistModal";
import {
  usePlaylistQuery,
  useRemoveClipFromPlaylistMutation,
  useRenamePlaylistMutation,
  useReorderPlaylistClipsMutation,
} from "../../graphql";
import DeletePlaylist from "./DeletePlaylist";
import EmptyState from "./EmptyState";
import ManagePlaylistItems from "./ManagePlaylistItems";

type ManagePlaylistProps = {
  playlistId: string;
  goToPlaylistManagementHome(): void;
  onPlaylistError(msg: string): void;
};

const ManagePlaylist: React.FC<ManagePlaylistProps> = ({
  playlistId,
  goToPlaylistManagementHome,
  onPlaylistError,
}) => {
  const toast = useToast();
  const addToTrainingProgramModal = useDisclosure();
  const { windowWidth } = useWindowDimensions();
  const debouncedWindowWidth = useDebounce(windowWidth, 100);

  const [displayTitle, setDisplayTitle] = useState("");
  const titleRef = useRef<HTMLDivElement>(null);

  const { data, loading, error } = usePlaylistQuery({
    variables: { playlistId },
    onError(err) {
      Sentry.captureException(err);
      LogRocket.track("playlist-management-error", { error: err.message });
      onPlaylistError(err.message);
    },
    onCompleted(data) {
      const title = data.playlist?.title;
      if (title) {
        setDisplayTitle(title);
      }
    },
  });

  const [playlist, call] = [data?.playlist, data?.playlist?.call];

  useEffect(() => {
    if (titleRef.current && playlist?.title) {
      const children = [...titleRef.current.children] as HTMLElement[];
      const title = children.find((el) => el.innerText === playlist.title);
      const titleInput = titleRef.current.querySelector("input");

      if (title && titleInput) {
        titleInput.style.width = `${title.getBoundingClientRect().width}px`;
      }
    }
  }, [titleRef.current, playlist?.title, debouncedWindowWidth]);

  const [reorderClips] = useReorderPlaylistClipsMutation({
    onError(err) {
      Sentry.captureException(err);
      errorToast(toast, "There was a problem updating this playlist");
    },
    onCompleted(data) {
      if (data.reorderPlaylistClips) {
        successToast(toast, "Playlist updated", { duration: 1_500 });
      }
    },
  });

  const [removeClip] = useRemoveClipFromPlaylistMutation({
    onError(err) {
      Sentry.captureException(err);
      errorToast(
        toast,
        err.message ?? "There was a problem removing this clip"
      );
    },
    onCompleted(data) {
      if (data.removeClipFromPlaylist) {
        successToast(toast, "Clip removed", { duration: 1_500 });
      }
    },
  });

  const [renamePlaylist] = useRenamePlaylistMutation({
    onError(err) {
      Sentry.captureException(err);
      errorToast(
        toast,
        err.message ?? "There was a problem renaming this playlist"
      );
    },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return null;
  }

  if (!playlist) {
    return <EmptyState text="We can't find that playlist" />;
  }

  return (
    <>
      <Box pb="6" mb="6" borderBottom="1px solid" borderBottomColor="gray.100">
        <Flex alignItems="center" mb="4">
          <EditableText
            ref={titleRef}
            showIcon="hover"
            variant="blue"
            hideFocus
            placeholder="Playlist Title"
            value={displayTitle}
            selectAllOnFocus
            onChange={setDisplayTitle}
            onSubmit={(val) => {
              const title = val.trim();
              if (!title) {
                errorToast(toast, "Playlist name cannot be blank");
                setDisplayTitle(playlist.title ?? "");
              } else if (title !== playlist.title) {
                renamePlaylist({ variables: { playlistId, title } });
              }
            }}
            color="gray.800"
            fontWeight="bold"
            fontSize="2xl"
            flex="1"
            minW="0"
            mr="4"
            previewProps={{ whiteSpace: "nowrap" }}
          />

          <SharePlaylistModal
            trigger={
              <IconButton
                ml="auto"
                variant="white"
                size="sm"
                icon={<BHShareIcon width={4} height={4} />}
                aria-label="Share playlist"
              />
            }
            playlistId={playlistId}
            callId={call?.id}
          />
          <Menu variant="new" autoSelect={false}>
            <MenuButton
              as={IconButton}
              ml="4"
              variant="white"
              size="sm"
              icon={<BsThreeDotsVertical size={20} />}
              onClick={(e) => e.stopPropagation()}
            />
            <MenuList minWidth="220px">
              <MenuItem onClick={addToTrainingProgramModal.onOpen} pb="16px">
                <AddToTrainingProgramIcon boxSize="18" mr="2" />
                Add to training program
              </MenuItem>
              <MenuDivider />
              <DeletePlaylist
                playlistId={playlistId}
                goToPlaylistManagementHome={goToPlaylistManagementHome}
              />
            </MenuList>
          </Menu>
          {addToTrainingProgramModal.isOpen && (
            <AddToTrainingProgramModal
              onClose={addToTrainingProgramModal.onClose}
              callId={call?.id}
              clipIds={playlist.clips.map((clip) => clip.id)}
            />
          )}
        </Flex>

        <Flex alignItems="center" whiteSpace="nowrap">
          {call?.name && (
            <Flex
              as="a"
              alignItems="center"
              borderRadius="lg"
              color="blue.600"
              bg="blue.50"
              fontSize="xs"
              px="2"
              py="1px"
              mr="2"
              gap="1"
              href={`/interview/${call.id}`}
              target="_blank"
              overflow="hidden"
            >
              <Icon
                boxSize="4"
                strokeWidth="1"
                flexShrink={0}
                as={styled(HiOutlineVideoCamera)`
                  path {
                    stroke-width: 1.5;
                  }
                `}
              />
              <Box overflowX="hidden" textOverflow="ellipsis">
                {call?.name}
              </Box>
            </Flex>
          )}

          <Text fontSize="sm" color="gray.800">
            {formatDate(playlist.createdAt, {
              dateStyle: "medium",
              timeStyle: undefined,
            })}
            <Text as="span" color="gray.400">{` • `}</Text>
            {playlist.clipCount} clip{playlist.clipCount !== 1 ? "s" : ""}{" "}
            {playlist.duration && (
              <Text as="span" color="gray.600">
                ({formatDurationShort(playlist.duration)})
              </Text>
            )}
          </Text>
        </Flex>
      </Box>

      <ManagePlaylistItems
        playlistId={playlistId}
        clips={playlist.clips}
        clipCount={playlist.clipCount}
        onReorderClips={(clipIds) =>
          reorderClips({ variables: { playlistId, clipIds } })
        }
        onRemoveClip={(clipId) =>
          removeClip({ variables: { playlistId, clipId } })
        }
      />
    </>
  );
};

export default ManagePlaylist;
