import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

import { Button } from "../Buttons";

interface MetricsCalculationModalProps {
  currentCalculationModal: string | null;
  onClose: () => void;
}

const MetricsCalculationModal: React.FC<MetricsCalculationModalProps> = ({
  currentCalculationModal,
  onClose,
}) => {
  return (
    <Modal isOpen={currentCalculationModal !== null} onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>{currentCalculationModal}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {currentCalculationModal === "Talk Ratio" && (
              <Box mb={3}>
                Talk Ratio is the interviewer&apos;s speaking duration divided
                by total interview talk time, averaged across all of their
                interviews.
              </Box>
            )}
            {currentCalculationModal === "Longest Monologue" && (
              <Box mb={3}>
                Longest Monologue averages the interviewer&apos;s longest
                speaking segment from each of their interviews.
              </Box>
            )}
            {currentCalculationModal === "Speaking Rate" && (
              <Box mb={3}>
                Speaking Rate is the relative words per minute of the
                interviewer versus all BrightHire interviewers during the prior
                three months. A Speaking Rate of 4 means the interview’s words
                per minute value is in the 30th to 40th percentile.
              </Box>
            )}
            {currentCalculationModal === "Interactivity" && (
              <Box>
                Interactivity is the relative count of “back-and-forths” that
                happened during the interview compared to all interviews across
                the platform from the prior three months. An interactivity of 6
                means that the interview is in the 50th to 60th percentile of
                these interviews.
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default MetricsCalculationModal;
