import { useOrgGenderLabelsQuery, UserRolePermission } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../hooks/useCurrentUser";
import { defaultSegmentLabels } from "./chart/segmentPresentation";

export type GenderLabels = {
  loading: boolean;
  canUpdateGenderLabels: boolean;
  genderLabels: {
    M: string;
    F: string;
  };
};

/**
 * Query results and any error states related to analytics
 */
const useGenderLabels = (): GenderLabels => {
  const currentUser = useCurrentUser();

  const genderLabelsEnabled = useFeatureFlag("analytics:custom-gender-labels");
  const canUpdateGenderLabels =
    genderLabelsEnabled &&
    !!currentUser?.userRole?.permissions.includes(
      UserRolePermission.ManageOrganizationSettings
    );

  const { data, loading } = useOrgGenderLabelsQuery({
    skip: !genderLabelsEnabled,
  });

  return {
    loading,
    canUpdateGenderLabels,
    genderLabels: {
      M:
        data?.currentUser?.organization.genderLabelMale ||
        defaultSegmentLabels.M,
      F:
        data?.currentUser?.organization.genderLabelFemale ||
        defaultSegmentLabels.F,
    },
  };
};

export default useGenderLabels;
