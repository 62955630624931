import { useLayoutEffect, useState } from "react";

import { useIsExtension } from "../main/hooks/useAppEnvironmentContext";
import { useIsSmallScreen } from "./useIsSmallScreen";

export type WindowDimensions = {
  windowWidth: string;
  windowHeight: string;
  /*
   * layoutHeight represents the height of the window - the height of the header
   */
  layoutHeight: string;
  headerHeight: string;
  /*
   * Percentage functions take a percentage value X(0-100) and returns X% of
   * the window value.
   *
   * 60vh === percentHeight(60)
   */
  percentHeight: (percent: number) => string;
  percentWidth: (percent: number) => string;
  absoluteLayoutHeight: number;
  absoluteHeaderHeight: number;
};

export const getWindowDimensions = (headerHeight: number): WindowDimensions => {
  const { innerWidth, innerHeight } = window;
  const width = Math.floor(innerWidth);
  const height = Math.floor(innerHeight);
  const layout = Math.floor(innerHeight) - headerHeight;

  return {
    windowWidth: `${width}px`,
    windowHeight: `${height}px`,
    layoutHeight: `${layout}px`,
    headerHeight: `${headerHeight}px`,
    percentHeight: (percent: number) =>
      `${Math.floor(percent * 0.01 * height)}px`,
    percentWidth: (percent: number) =>
      `${Math.floor(percent * 0.01 * width)}px`,
    absoluteLayoutHeight: layout,
    absoluteHeaderHeight: headerHeight,
  };
};

const useWindowDimensions = (): WindowDimensions => {
  const isSmallScreen = useIsSmallScreen();
  const isExtension = useIsExtension();
  const sidebarNavShown = !isSmallScreen;
  const headerHeight = isExtension ? 49 : sidebarNavShown ? 0 : 48;

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(headerHeight)
  );

  useLayoutEffect(() => {
    const handleResize = (): void => {
      setWindowDimensions(getWindowDimensions(headerHeight));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [headerHeight]);

  return windowDimensions;
};

export default useWindowDimensions;
