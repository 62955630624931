import { useCallback } from "react";

import { useToast } from "../../components";
import { pluck } from "../../utils/array";
import { formatDetails, formatNote, formatTimestamps } from "../../utils/call";
import { copy, CopyData } from "../../utils/clipboard";
import { useSendGAEvent } from "../../utils/googleAnalytics";
import { notesListNoteTypes } from "../components/CallNotes/utils";
import {
  CallBetaFragment,
  CallNoteFragment,
  CallNoteType,
  CallQuestionFragment,
  GuideItemType,
  ScorecardItemNotesFragment,
} from "../graphql";
import useCurrentUser from "./useCurrentUser";

type CopyNotesBetaCall = Pick<CallBetaFragment, "id" | "startTime"> & {
  candidate?: { fullName: string } | null;
  position?: { displayTitle: string } | null;
};

type ScorecardItemFields =
  | "itemText"
  | "questionNotes"
  | "type"
  | "marked"
  | "markedTime";

type CopyScorecardItem = Pick<
  ScorecardItemNotesFragment,
  ScorecardItemFields
> & {
  childItems?: Pick<ScorecardItemNotesFragment, ScorecardItemFields>[];
};

function getScorecardItemNotes(
  item: CopyScorecardItem,
  callId: string,
  includeTimestamps: boolean
): CopyData {
  const notesAndReactions = item.questionNotes.filter((n) =>
    notesListNoteTypes.includes(n.type)
  );
  const markedAt = item.marked ? item.markedTime : null;

  let plain = "";
  let html = "";

  const childHasNotes = item.childItems?.some(
    (item) => item.questionNotes.length
  );
  const showTimestamp = markedAt && includeTimestamps;
  if (showTimestamp || notesAndReactions.length > 0 || childHasNotes) {
    if (item.itemText) {
      const text =
        item.type === GuideItemType.Competency
          ? `${item.itemText}:`
          : item.itemText;
      plain = plain.concat(`\n${text}`);
      html = html.concat(`<br>${text}`);
    }

    if (showTimestamp) {
      const timestamp = { time: markedAt, callId };
      plain = plain.concat(`\n${formatTimestamps([timestamp])}`);
      html = html.concat(`<br>${formatTimestamps([timestamp], true)}`);
    }

    plain = plain.concat(
      notesAndReactions
        .map((n) => formatNote(n))
        .join("")
        .concat("\n")
    );
    html = html.concat(
      notesAndReactions
        .map((n) => formatNote(n, true, includeTimestamps))
        .join("")
        .concat("<br>")
    );
  }

  return { html, plain };
}

function getNestedScorecardItemNotes(
  item: CopyScorecardItem,
  callId: string,
  includeTimestamps: boolean
): CopyData[] {
  const { html, plain } = getScorecardItemNotes(
    item,
    callId,
    includeTimestamps
  );

  let childCopyData: CopyData[] = [];
  if (item.childItems?.length) {
    childCopyData = item.childItems.map((childItem) =>
      getScorecardItemNotes(childItem, callId, includeTimestamps)
    );
  }
  return [{ html, plain }, ...childCopyData];
}

function getQuestionNotes(
  question: CallQuestionFragment,
  includeTimestamps: boolean
): CopyData {
  const notesAndReactions = question.questionNotes.filter((n) =>
    notesListNoteTypes.includes(n.type)
  );
  const timestamps = question.questionNotes.filter(
    (n) => n.type === CallNoteType.Cue
  );

  let plain = "";
  let html = "";

  const showTimestamps = timestamps.length > 0 && includeTimestamps;
  if (showTimestamps || notesAndReactions.length > 0) {
    if (question.description) {
      plain = plain.concat(`\n${question.description}`);
      html = html.concat(`<br>${question.description}`);
    }

    if (showTimestamps) {
      plain = plain.concat(`\n${formatTimestamps(timestamps)}`);
      html = html.concat(`<br>${formatTimestamps(timestamps, true)}`);
    }

    plain = plain.concat(
      notesAndReactions
        .map((n) => formatNote(n))
        .join("")
        .concat("\n")
    );
    html = html.concat(
      notesAndReactions
        .map((n) => formatNote(n, true, includeTimestamps))
        .join("")
        .concat("<br>")
    );
  }

  return { html, plain };
}

export function useCopyScorecardItemNotes(
  item: CopyScorecardItem,
  callId: string
): () => void {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();

  const {
    organization: { includeTimestampsInNoteOutputs },
  } = useCurrentUser();

  return useCallback(() => {
    const allNotes = getNestedScorecardItemNotes(
      item,
      callId,
      includeTimestampsInNoteOutputs
    );
    const flatNotes = ([] as CopyData[]).concat(...allNotes);

    const plain = pluck(flatNotes, "plain").join("");
    const html = pluck(flatNotes, "html").join("");

    sendGAEvent("copy_question_notes", "call_review");
    copy({ plain, html });
    toast({
      title: "Notes copied to clipboard",
      status: "success",
    });
  }, [item]);
}

export function useCopyQuestionNotes(
  question: CallQuestionFragment
): () => void {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();

  const {
    organization: { includeTimestampsInNoteOutputs },
  } = useCurrentUser();

  return useCallback(() => {
    const text = getQuestionNotes(question, includeTimestampsInNoteOutputs);
    sendGAEvent("copy_question_notes", "call_review");
    copy(text);
    toast({
      title: "Notes copied to clipboard",
      status: "success",
    });
  }, [question]);
}

export function useCopyNotes(args: {
  call: CopyNotesBetaCall;
  generalNotes: CallNoteFragment[];
  /** Used for calls with iaVersion < 3 */
  questions?: CallQuestionFragment[];
  /** Used for calls with iaVersion = 3 */
  scorecardItemNotes?: CopyScorecardItem[];
}): () => void {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();
  const { call, generalNotes, questions, scorecardItemNotes } = args;
  const callId = call.id;

  const {
    organization: { includeTimestampsInNoteOutputs },
  } = useCurrentUser();

  function getCallNotes(): CopyData {
    let plain = `${formatDetails(call, { withoutURL: true })}\n\n`;
    let html = `${formatDetails(call, {
      html: true,
      withoutURL: !includeTimestampsInNoteOutputs,
    })}<br><br>`;

    if (generalNotes.length) {
      plain += `General Notes${generalNotes
        .map((n) => formatNote(n))
        .join("")}\n`;

      html += `General Notes${generalNotes
        .map((n) => formatNote(n, true, includeTimestampsInNoteOutputs))
        .join("")}<br>`;
    }

    if (questions?.length) {
      const questionNotes = questions.map((q) =>
        getQuestionNotes(q, includeTimestampsInNoteOutputs)
      );

      plain += pluck(questionNotes, "plain").join("");
      html += pluck(questionNotes, "html").join("");
    }

    if (scorecardItemNotes?.length) {
      const filteredItems = scorecardItemNotes.filter(
        (item) => item.type !== GuideItemType.Text
      );
      const nestedScorecardItemNotes = filteredItems.map((item) =>
        getNestedScorecardItemNotes(
          item,
          callId,
          includeTimestampsInNoteOutputs
        )
      );
      const flatScorecardItemNotes = ([] as CopyData[]).concat(
        ...nestedScorecardItemNotes
      );

      plain += pluck(flatScorecardItemNotes, "plain").join("");
      html += pluck(flatScorecardItemNotes, "html").join("");
    }

    return { html, plain };
  }

  return useCallback(() => {
    sendGAEvent("copy_notes", "call_review");
    const text = getCallNotes();
    copy(text);
    toast({
      title: "Notes copied to clipboard",
      status: "success",
    });
  }, [call, generalNotes, questions, scorecardItemNotes]);
}
