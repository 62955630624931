import { Box, Text, useToast } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { LoadingIndicator } from "../../../../../components";
import { formatDetails } from "../../../../../utils/call";
import { copy } from "../../../../../utils/clipboard";
import { useSendGAEvent } from "../../../../../utils/googleAnalytics";
import { formatSegment } from "../../../../../utils/transcript";
import {
  ClipRecordingStatus,
  useAuditLogItemExistsQuery,
  useClipBetaQuery,
} from "../../../../graphql";
import { RecordingLayout, Transcript } from "../../../Recording";
import useVideoState from "../../../Video/useVideoState";
import { useCallErrorView } from "../../beta/useCallErrorView";
import { VideoDisplayMode } from "../../types";
import useMediaPlayer from "../../useMediaPlayer";
import useStartTime from "../../useStartTime";
import ClipErrorState from "../ClipErrorState";
import ClipDetailsHeader from "./ClipDetailsHeader";
import ClipSidebar, { ClipTab } from "./ClipSidebar";
import ClipVideo from "./ClipVideo";
import useClipTranscript from "./useClipTranscript";

const ClipViewBeta: React.FC = () => {
  const { callId, clipId } = useParams() as { callId: string; clipId: string };
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();

  // Check the audit log for the given item and see what state to display
  const { data: auditData, loading: auditLoading } = useAuditLogItemExistsQuery(
    {
      variables: { id: clipId, name: "delete_clip", property: "clip_id" },
    }
  );

  const { loading, error, data, stopPolling } = useClipBetaQuery({
    variables: { clipId },
    pollInterval: 5000,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const clip = data?.clip;

  const errorView = useCallErrorView({
    error,
    clip,
    auditLogItem: auditData?.auditLogItemExists,
  });

  const mediaSrc = clip?.streamableVideo?.url ?? clip?.streamableAudio?.url;

  // Set up the media player & video
  const [mediaPlayerRef, listeners, player] = useMediaPlayer();

  const { seek, play, duration, canPlay } = player;
  const seekAndPlay = useCallback(
    (t: number): void => {
      seek(t);
      play();
    },
    [seek]
  );

  const startTime = useStartTime(duration);

  useEffect(() => {
    if (canPlay) {
      seek(startTime);
    }
  }, [canPlay, startTime, seek]);

  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);
  const transcript = useClipTranscript({ clipId, callId });

  const [currentTab, setCurrentTab] = useState(ClipTab.NOTES);

  // Stop polling if the clip is processing or has streamable audio,
  // unless still clipping
  const clipInProgress =
    clip?.recordingStatus === ClipRecordingStatus.InProgress;
  if (!clipInProgress && (clip?.streamableAudio || clip?.streamableVideo)) {
    stopPolling();
  }

  const onCopyTranscript = useCallback((): void => {
    if (!clip) {
      return;
    }
    sendGAEvent("copy_transcript", "clip_review");
    const callDetails = formatDetails(
      {
        ...clip,
        startTime: `${clip.startTime}`,
      },
      {
        customURL: window.location.href,
      }
    );
    const speakerLabels = clip.speakers.map((speaker) => speaker.label);
    const transcriptText =
      transcript.transcript
        ?.map((segment) => formatSegment(speakerLabels, segment))
        .reduce((previous, current) => `${previous}\n\n${current}`, "") ?? "";
    const text = `${callDetails}\n\n${transcriptText}`;
    copy(text);
    toast({
      title: "Transcript copied to clipboard",
      status: "success",
    });
  }, [clip, transcript, toast]);

  let videoDisplayMode = VideoDisplayMode.NO_RECORDING;
  if (clip?.streamableAudio && !clip?.streamableVideo) {
    videoDisplayMode = VideoDisplayMode.AUDIO;
  } else if (clip?.streamableVideo) {
    videoDisplayMode = VideoDisplayMode.VIDEO;
  } else if (clipInProgress) {
    videoDisplayMode = VideoDisplayMode.PROCESSING;
  }

  const {
    containerRef,
    videoCallbackRef,
    fullScreenControls,
    pipControls,
    isVideoVisible,
    hideVideo,
    focusVideo,
  } = useVideoState(videoDisplayMode);

  // If the call is loading or we're still figuring out if it was deleted
  // or never existed etc, show loading indicator
  if (loading || auditLoading || transcript.loading) {
    return (
      <Box textAlign="center" m="auto" py="10vh">
        <LoadingIndicator h="auto" mb="4" />
        <Text>
          We are preparing your clip. <br />
          This page will refresh when the clip is ready.
        </Text>
      </Box>
    );
  }

  // If something went wrong loading the call or there is no call at all,
  // set up the appropriate view and don't try to get new data
  if (errorView || !clip) {
    stopPolling();

    return <ClipErrorState view={errorView} />;
  }

  return (
    <RecordingLayout
      header={<ClipDetailsHeader clip={clip} callId={callId} />}
      video={
        <ClipVideo
          mediaSrc={mediaSrc}
          clip={clip}
          callId={callId}
          listeners={listeners}
          onSeek={seekAndPlay}
          mediaPlayerRef={mediaPlayerRef}
          videoDisplayMode={videoDisplayMode}
          player={player}
          isVideoVisible={isVideoVisible}
          containerRef={containerRef}
          videoRef={videoCallbackRef}
          fullScreenControls={fullScreenControls}
          pipControls={pipControls}
          hideVideo={hideVideo}
        />
      }
      transcript={
        <Transcript
          h="100%"
          {...transcript}
          speakers={clip.speakers}
          player={player}
          autoScrollEnabled={autoScrollEnabled}
          setAutoScrollEnabled={setAutoScrollEnabled}
          isVideoCall={videoDisplayMode === VideoDisplayMode.VIDEO}
          isVideoVisible={isVideoVisible}
          hideVideo={hideVideo}
          focusVideo={focusVideo}
          onCopyTranscript={onCopyTranscript}
        />
      }
      sidebar={
        <ClipSidebar
          clip={clip}
          callId={callId}
          currentTab={currentTab}
          onTabChange={setCurrentTab}
          player={player}
          onSeek={seekAndPlay}
          autoScrollEnabled={autoScrollEnabled}
          setAutoScrollEnabled={setAutoScrollEnabled}
          isVideoCall={videoDisplayMode === VideoDisplayMode.VIDEO}
          isVideoVisible={isVideoVisible}
          hideVideo={hideVideo}
          focusVideo={focusVideo}
        />
      }
    />
  );
};

export default ClipViewBeta;
