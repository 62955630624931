import { FormControl, Select, Tooltip } from "@chakra-ui/react";
import React from "react";

import { useToast } from "../../../components";
import { useSetClientMembershipsMutation } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";

interface ClientPositionRoleSelectProps {
  user: {
    id: string;
  };
  client: {
    id: string;
  };
  roleId: string;
}

const ClientPositionRoleSelect: React.FC<ClientPositionRoleSelectProps> = ({
  user,
  client,
  roleId,
}) => {
  const currentUser = useCurrentUser();
  const toast = useToast();
  const { positionRoles } = currentUser.organization;

  const [setClientMemberships] = useSetClientMembershipsMutation({
    onCompleted: (data) => {
      if (data.setClientMemberships) {
        toast({
          title: "Success",
          description: "Updated role",
          status: "success",
        });
      }
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
  });

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setClientMemberships({
      variables: {
        users: [
          {
            userId: user.id,
            clientId: client.id,
            positionRoleId: e.target.value,
          },
        ],
      },
    });
  };

  const enabled =
    currentUser.userRole?.canManageHiringTeam || user.id !== currentUser.id;

  const positionValue = positionRoles?.find((r) => r.id === roleId);

  return (
    <FormControl id="position-role-select">
      <Tooltip openDelay={250}>
        <Select
          size="sm"
          w="max-content"
          onChange={onChange}
          value={positionValue?.id}
          defaultValue={roleId}
          disabled={!enabled}
          data-testid="position-role-select"
        >
          {positionRoles?.map((r) => (
            <option key={r.id} value={r.id}>
              {r.formattedName}
            </option>
          ))}
        </Select>
      </Tooltip>
    </FormControl>
  );
};

export default ClientPositionRoleSelect;
