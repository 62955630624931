import { useEffect, useRef, useState } from "react";

/**
 * Play a list of base64 encoded audio files.
 */
const usePlayQueue = (audios: string[]): void => {
  const [queuePosition, setQueuePosition] = useState(0);
  const audioPlayer = useRef(new Audio());

  const playNextAudio = (): void => {
    if (queuePosition >= audios.length) {
      return;
    }
    if (!audioPlayer.current.paused) {
      return;
    }

    // const decoded = Buffer.from(audios[queuePosition], "base64");
    const blob = new Blob(
      [Uint8Array.from(atob(audios[queuePosition]), (c) => c.charCodeAt(0))],
      { type: "audio/mpeg" }
    );
    const url = URL.createObjectURL(blob);
    audioPlayer.current.src = url;
    audioPlayer.current.play();
    // eslint-disable-next-line no-console
    console.info("[usePlayQueue]", queuePosition);
  };

  useEffect(() => {
    audioPlayer.current.addEventListener("ended", () => {
      setQueuePosition((prevPosition) => prevPosition + 1);
    });

    return () => {
      audioPlayer.current.removeEventListener("ended", playNextAudio);
    };
  }, []);

  useEffect(() => {
    playNextAudio();
  }, [queuePosition, audios.length]);
};

export default usePlayQueue;
